import { isNumber } from 'lodash';

// Libs
import history from "@biuwer/core/src/history";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import utilsLib from '@biuwer/common/src/libs/utils-lib';
import { getMultiFieldTranslation } from "@biuwer/common/src/libs/i18n-lib";

// Space constants
const SPACES_CONTEXT = "spaces_context";
const SPACE_CONTENT_CONTEXT = "space_context";
const getSpaceContext = (spaceId) => `${SPACE_CONTENT_CONTEXT}_${spaceId}`;
const SPACE_TAB_IDS = {
    PAGES: "pages",
    CARDS: "cards",
    COLLECTIONS: "collections"
};
const SPACE = {
    ROUTE: "/spaces",
    FIELD: {
        ID: "_id",
        NAME: "name",
        DESCRIPTION: "desc",
        PROFILES: "profiles",
        PROFILE_LEVEL: "profile_level",
        OWNER: "owner",
        TYPE: "space_type",
        PAGES: "pages",
        CARDS: "cards",
        COLLECTIONS: "collections",
        NUM_PAGES: "stats.pages",
        NUM_CARDS: "stats.cards",
        NUM_COLLECTIONS: "stats.collections",
        TRANSLATIONS: "i18n"
    },
    TYPE: {
        PERSONAL: "personal",
        SHARED: "shared"
    },
    ACTION: {
        CREATE: "core.space.createOne",
        UPDATE: "core.space.updateOne",
        UPDATE_PROFILES: "core.space.updateProfiles",
        DELETE: "core.space.deleteOne"
    },
};

// React router helpers
const getSpaceRoute = (space, spaceContentType) => `${SPACE.ROUTE}/${getSpaceId(space)}${spaceContentType ? `/${spaceContentType}` : ""}`;
const pushSpaceRoute = (space, spaceContentType) => {
    history.push(getSpaceRoute(space, spaceContentType));
}

// Basic getters
const getSpaceField = (space, field) => space && field && space.getIn(field.split("."));
const getListLength = (list) => list ? list.size : 0;

// Field getters
const getSpaceId = (space) => getSpaceField(space, SPACE.FIELD.ID);
const getSpaceType = (space) => getSpaceField(space, SPACE.FIELD.TYPE);
const getSpaceName = (space) => getSpaceField(space, SPACE.FIELD.NAME);
const getSpaceDescription = (space) => getSpaceField(space, SPACE.FIELD.DESCRIPTION);
const getSpaceProfileLevel = (space) => getSpaceField(space, SPACE.FIELD.PROFILE_LEVEL);
const getSpaceProfiles = (space) => getSpaceField(space, SPACE.FIELD.PROFILES);
const getSpaceOwner = (space) => getSpaceField(space, SPACE.FIELD.OWNER);
const getSpacePages = (space) => getSpaceField(space, SPACE.FIELD.PAGES);
const getSpaceCards = (space) => getSpaceField(space, SPACE.FIELD.CARDS);
const getSpaceCollections = (space) => getSpaceField(space, SPACE.FIELD.COLLECTIONS);
const getSpaceNumPages = (space) => getSpaceField(space, SPACE.FIELD.NUM_PAGES);
const getSpaceNumCards = (space) => getSpaceField(space, SPACE.FIELD.NUM_CARDS);
const getSpaceNumCollections = (space) => getSpaceField(space, SPACE.FIELD.NUM_COLLECTIONS);
const getSpaceTranslations = (space) => getSpaceField(space, SPACE.FIELD.TRANSLATIONS);

// Basic checks
const isSameSpaceId = (spaceId, spaceId2) => Number(spaceId) === Number(spaceId2);

// Getters
const getSpaceById = (spaces, spaceId) => spaces && spaces.find(space => isSameSpaceId(getSpaceId(space), spaceId));
const getSpaceByPosition = (spaces, position) => spaces && spaces.get(position);
const getPersonalSpace = (spaces) => spaces && spaces.find(space => getSpaceType(space) === SPACE.TYPE.PERSONAL);
const getSharedSpaces = (spaces) => spaces && spaces.filter(space => getSpaceType(space) === SPACE.TYPE.SHARED);
const getNumSpacePages = (space) => getListLength(getSpacePages(space));
const getNumSpaceCards = (space) => getListLength(getSpaceCards(space));
const getNumSpaceCollections = (space) => getListLength(getSpaceCollections(space));

// Check Functions
const isSelectedSpace = (space, spaceId) => isSameSpaceId(getSpaceId(space), spaceId);
const isDisabledSpaceAction = (space, action, checkLevel) => {
    const profileLevel = getSpaceProfileLevel(space);
    return !space || (!Auth.isAuthorized(action) && !(isNumber(profileLevel) && profileLevel <= checkLevel));
};
const createSpaceIsDisabled = () => !Auth.isAuthorized(SPACE.ACTION.CREATE);

// Add isFavorite flag to spaces
const addIsFavoriteToSpaces = (userFavoriteSpaces, spaces) => {
    return spaces.map(space => space.merge({ isFavorite: userFavoriteSpaces.includes(getSpaceId(space)) }));
};
const getSpaceIsFavorite = (space) => getSpaceField(space, "isFavorite");

const sortSpaces = (spaces, sortField, sortDirection, favoritesFirst) => {
    return utilsLib.sortContentListItems(spaces, sortField, sortDirection, favoritesFirst, getSpaceIsFavorite);
};

/**
 * Function to translate space contents
 * @param {Array} content Array of space contents to translate (cards, pages, collections)
 * @param {String} currentContentLanguage current content language
 */
const getTranslatedContent = (content, currentContentLanguage) => {

    return content.map(item => {
        const originalName = item.get("name");
        const originalDesc = item.get("desc");
        const originalDescription = item.get("description");
        const translations = item.get("i18n")?.toJS();

        if (translations?.length > 0) {
            const translatedFields = getMultiFieldTranslation({
                fields: ["name", "desc", "description"],
                language: currentContentLanguage,
                translations,
                defaultValues: {
                    name: originalName,
                    desc: item.get("desc"),
                    description: item.get("description"),
                }
            });
            item = item
                .set("name", translatedFields.name)
                .set("desc", translatedFields.desc)
                .set("description", translatedFields.description);
        }

        // Add not translated name, desc and description to show original values on dialog settings inputs
        return item
            .set("original_name", originalName)
            .set("original_desc", originalDesc)
            .set("original_description", originalDescription);
    });
}

export default {
    SPACE,
    SPACES_CONTEXT,
    SPACE_CONTENT_CONTEXT,
    getSpaceContext,
    SPACE_TAB_IDS,
    pushSpaceRoute,
    getSpaceById,
    getSpaceByPosition,
    getSpaceField,
    getSpaceId,
    getSpaceName,
    getSpaceType,
    getSpaceDescription,
    getSpaceProfileLevel,
    getSpaceProfiles,
    getSpaceOwner,
    getSpaceRoute,
    getSpacePages,
    getSpaceCards,
    getSpaceCollections,
    getSpaceNumPages,
    getSpaceNumCards,
    getSpaceNumCollections,
    getSpaceTranslations,
    isSelectedSpace,
    isDisabledSpaceAction,
    createSpaceIsDisabled,
    getPersonalSpace,
    getSharedSpaces,
    getNumSpacePages,
    getNumSpaceCards,
    getNumSpaceCollections,
    addIsFavoriteToSpaces,
    getSpaceIsFavorite,
    sortSpaces,
    getTranslatedContent
}