import { combineReducers } from 'redux';

// Biuwer Share reducers
import authTokens from "./auth-tokens/auth-tokens-reducer";
import resourceTokens from "./resource-tokens/resource-tokens-reducer";
import resource from "./resource/resource-reducers";

export default combineReducers({
    authTokens,
    resourceTokens,
    resource
});