import React, { Component } from 'react';

export default class NotificationMessage extends Component {
    constructor(props) {
        super(props);

        this.access = this.access.bind(this);
        this.goTo = this.goTo.bind(this);
    }

    access() {
        this.props.access();
    }

    goTo(path) {
        this.props.goTo(path);
    }

    render() {
        const { t } = this.props;
        let styleClass = (this.props.messageType === 'error') ? 'text-center text-danger mb15' : 'text-center text-success mb15';

        let footer = this.props.messageType === 'error' ? (
            <div className="row">
                <div className="col">
                    <div className="pull-right">
                        <button
                            key='cancel-button'
                            type="button"
                            className="btn btn-sm btn-white ml15"
                            onClick={this.goTo.bind(this, '/signin')}
                        >
                            {t('common.goBackLabel')}
                        </button>
                    </div>
                </div>
            </div>
        ) : (
            <div className="row">
                <div className="col">
                    <div className="pull-right">
                        <button
                            key="submit-button"
                            type="button"
                            className="btn btn-sm btn-success"
                            onClick={this.access}
                        >
                            {t('auth.resetPassword.accessButton')}
                        </button>
                    </div>
                </div>
            </div>
        );

        const styles = {
            title: {
                fontSize: 16,
                textTransform: 'uppercase',
                fontWeight: 300,
                color: '#3e2723'
            }
        };

        let title = '';
        if (this.props.otp) {
            title = t('auth.oneTimeForm.headerTitle');
        } else if (this.props.reset) {
            title = t('auth.resetPassword.headerTitle');
        } else if (this.props.welcome) {
            title = t('auth.welcomeUser.headerTitle');
        }

        return (
            <div className="animated fadeIn">
                <div className="ibox-content">
                    <div className="row">
                        <div className="col-12 text-center mt15">
                            <span style={styles.title}>{title}</span>
                        </div>
                    </div>

                    <hr className="hr-normal" />

                    <div className={styleClass}>{this.props.message}</div>
                    {footer}
                </div>
            </div>
        );
    }
}