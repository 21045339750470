
// Default query for users actions
export const GROUPS_ROLES_USER_DETAIL = `
    _id
    email
    name
    surnames
    phone
    full_name
    organization1
    organizations {
        organization { 
            _id
            name 
        }
        status
        last_seen_at
    }
    org_status
    type
    status
    created
    updated
    groups {
        _id
        name
    }
    roles {
        _id
        name
    }
    last_seen_at
`;

// Default query for save user profile and change default organization
export const PROFILE_USER_DETAIL = `
    _id
    email
    name
    surnames
    phone
    full_name
    default_organization {
        _id
        name
    }
    organizations {
        organization { 
            _id 
            name
        }
        status
        last_seen_at
    }
    favorites {
        spaces
        cards
        pages
        collections
    }
    org_status
    type
    status
    created
    updated
    last_seen_at
    settings {
        language
    }
`;

// Minimal query for users list (delete dialog, add users to roles/groups, object permissions dialog, etc)
export const BASIC_USER_DETAIL = `
    _id
    email
    name
    surnames
    phone
    full_name
    type
    organization1
    status
    org_status
`;

// Minimal query for users list with roles and groups
export const BASIC_USER_DETAIL_WITH_GROUPS_AND_ROLES = `
    _id
    email
    name
    phone
    surnames
    full_name
    type
    organization1
    groups {
        _id
        name
    }
    roles {
        _id
        name
    }
`;

// Default query for user detail page
export const EXTENDED_USER_DETAIL = `
    _id
    email
    name
    phone
    surnames
    full_name
    org_status
    organization1
    organizations {
        organization { 
            _id 
        }
        status
        last_seen_at
    }
    last_seen_at
    type
    status
    created
    updated
    auth_tokens {
        _id
        valid_from
        valid_to
        status
    }
    groups {
        _id
        name
        desc
        status
        group_type
    }
    roles {
        _id
        name
        desc
        status
        role_type
    }
`;

// Query for instance admin users page
export const INSTANCE_ADMIN_USERS_PAGE = `
    _id
    email
    name
    phone
    surnames
    full_name
    organization1
    organizations {
        organization { 
            _id
            name 
        }
        status
        last_seen_at
    }
    org_status
    type
    status
    created
    updated
    groups {
        _id
        name
    }
    roles {
        _id
        name
    }
    last_seen_organization
`;

// Query for instance admin new organization page
export const USERS__INSTANCE_ADMIN_NEW_ORGANIZATION_PAGE = `
    _id
    full_name
`;

// Default query for users actions
export const INSTANCE_USER_ORGANIZATIONS_DIALOG = `
    _id
    organizations {
        organization { 
            _id
            name 
            status
        }
        type
        status
        last_seen_at
    }
    default_organization {
        _id
    }
    status
`;

export const USER_FORM_DETAIL = `
        _id
        email
        name
        phone
        surnames
        full_name
        organizations {
            organization { _id }
            status
            last_seen_at
        }
        last_seen_at
        type
        status
        created
        updated
        auth_tokens {
            _id
            valid_from
            valid_to
            status
        }
        groups {
            _id
            name
            desc
            status
            group_type
        }
        roles {
            _id
            name
        }`;