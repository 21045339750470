import { isNumber, cloneDeep, isNil, sum, mean, min, max } from 'lodash';
import { List } from "immutable";

// Libs
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import indexedDB from '@biuwer/core/src/indexed-db';
import { BIUWER_APP_NAME } from "@biuwer/core/src/constants";

export const EMAIL_REGEX = /.+@.+\..+/;

export const isBiuwerApp = () => Auth.getAuthApp() === BIUWER_APP_NAME;

const isAuthorized = (profileLevel, action, checkLevel) => {
    return Auth.isAuthorized(action) || (isNumber(profileLevel) && profileLevel <= checkLevel);
};

const sortContentListItems = (listItems, sortField, sortDirection, favoritesFirst, getItemIsFavorite) => {

    const getItemField = (item, field) => item && field && item.getIn(field.split("."));
    const sortListItems = (list, sortField, sortDirection) => {
        const newList = cloneDeep(list);
        return newList.sort((a,b) => {
            const aValue = getItemField(a, sortField);
            const bValue = getItemField(b, sortField);
            return sortDirection === 'asc'
                ? aValue && aValue.localeCompare(bValue)
                : aValue && -aValue.localeCompare(bValue);
        });
    };

    let favoriteValuesList = List([]);
    let restValuesList = listItems;

    // Separate favorite items and sort them
    if (favoritesFirst) {
        favoriteValuesList = listItems.filter(item => !!getItemIsFavorite(item));
        favoriteValuesList = sortListItems(favoriteValuesList, sortField, sortDirection);
        restValuesList = listItems.filter(item => !getItemIsFavorite(item));
    }

    // Separate null item values
    const nullValuesList = restValuesList.filter(item => isNil(getItemField(item, sortField)));
    let validValuesList = restValuesList.filter(item => !isNil(getItemField(item, sortField)));

    // Sort valid item values
    validValuesList = sortListItems(validValuesList, sortField, sortDirection);

    // Return all sorted items
    return favoriteValuesList.concat(validValuesList, nullValuesList);
};

const cardIconsByCardType = {
    KPI: "array-numeric",
    Chart: "chart",
    VerticalTable: "th",
    CrossTable: "pivot-table",
    Map: "map-marker",
    Custom: "code",
    RichText: "new-text-box"
};

export const cleanCachedData = async () => {

    // Clear IndexedDB
    await indexedDB.clearDB();

}

// Math const
const PI = Math.PI;

/**
 * Check if input value is numeric
 * @param input
 * @returns {boolean}
 */
export const isNumeric = (input) => {
    // eslint-disable-next-line
    return (input - 0) == input && ('' + input).trim().length > 0;
};

/**
 * function to calculate degrees in radians
 * @param {number} degrees
 * @return {number}
 */
function toRadians(degrees) {
    return degrees * (PI / 180);
}

/**
 * Function to convert radians to degrees
 * @param {number} radians
 * @return {number}
 */
export function toDegrees(radians) {
    return radians * (180 / PI);
}

/**
 * Limit `value` between `min` and `max`
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @private
 */
export function _limitValue(value, min, max) {
    return Math.max(min, Math.min(max, value));
}

/**
 * Obtains the with and height from a given text.
 * @param text
 * @param fontSize
 * @param fontFamily
 * @param rotation, text angle rotation
 * @return {{fontFamily: string, width: (number|number), height: number}}
 */
export function getTextMeasures(text, fontSize = 12, fontFamily = 'Exo', rotation = 0) {
    let canvas = getTextMeasures.canvas || (getTextMeasures.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = `${fontSize}px ${fontFamily}`;
    let metrics = context.measureText(text);
    const angle = toRadians(rotation);
    const cosRotation = Math.cos(angle);
    const sinRotation = Math.sin(angle);
    let labelWidth = rotation ? (metrics.width * cosRotation) + (fontSize * sinRotation) : metrics.width;
    let labelHeight = rotation ? (metrics.width * sinRotation) + (fontSize * cosRotation) : fontSize;
    return { width: labelWidth, height: labelHeight, fontFamily: fontFamily };
}

/**
 * Function to calculate measures from a given text width
 * @param {Number} textWidth
 * @param {Number} fontSize
 * @param {Number} rotation
 * @return {{width: (number|*), height: (number|number)}}
 */
export function getMeasuresFromTextWidthRotation(textWidth, fontSize = 12, rotation = 0) {
    const angle = toRadians(rotation);
    const cosRotation = Math.cos(angle);
    const sinRotation = Math.sin(angle);
    let labelWidth = rotation ? (textWidth * cosRotation) + (fontSize * sinRotation) : textWidth;
    let labelHeight = rotation ? (textWidth * sinRotation) + (fontSize * cosRotation) : fontSize;
    return { width: labelWidth, height: labelHeight }
}

/**
 * Aux function to clean extension from file name
 * @param {String} fileName
 * @returns
 */
export const getFileNameWithoutExtension = (fileName) => {
    return fileName.split('.').slice(0, -1).join('.');
};

/**
* Function that generates a mongoDB Object id
*/
export function mongoObjectId() {
    let timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
        return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
}

/**
 * Function to calculate aggregation operation for a number array
 * @param {Object} params
 * @param {Array<Number>} params.data Number array
 * @param {String} params.operation Operation (sum, min, max...)
 * @return {Number} total of operation
 */
export function calculateValueAggregation({data, operation}) {
    if (data?.length > 0) {
        switch (operation) {
            case "sum":
                return sum(data);
            case "avg":
                return mean(data);
            case "count":
                return data.length;
            case "countDistinct":
                return new Set(data).size;
            case "min":
                return min(data);
            case "max":
                return max(data);
            default:
                return data[0];
        }
    } else {
        return 0;
    }
}

/**
 * Replace accents from given string
 * @param {String} stringValue
 * @returns {String}
 */
export function replaceAccentCharacters(stringValue = "") {
    return stringValue
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
}

export default {
    EMAIL_REGEX,
    isBiuwerApp,
    cleanCachedData,
    isNumeric,
    getTextMeasures,
    toDegrees,
    _limitValue,
    getMeasuresFromTextWidthRotation,
    isAuthorized,
    sortContentListItems,
    cardIconsByCardType,
    mongoObjectId,
    calculateValueAggregation,
    replaceAccentCharacters
}