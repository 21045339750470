import { useCallback } from "react"
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// Actions
import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

// Libs
import { getPaymentError } from './payments.lib'

export default function usePayments() {

    const stripe = useStripe();
    const elements = useElements();

    const { t, i18n } = useTranslation();

    const { addNotification } = useReduxDispatch();

    const confirmPaymentIntent = useCallback(async (clientSecret, paymentMethodId) => await stripe.confirmCardPayment(clientSecret, { payment_method: paymentMethodId }), [stripe]);
    const confirmSetupIntent = useCallback(async (clientSecret) => await stripe.confirmCardSetup(clientSecret), [stripe]);
    const createPaymentMethod = useCallback(async () => {
        const cardElement = elements.getElement(CardElement);
        return await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
        });
    }, [elements, stripe]);

    const handlePaymentIntentSuccess = useCallback(() => {
        addNotification({
            styleType: 'success',
            message: t('payment.creditCardSuccess'),
            notificationKey: 'payment'
        });
    }, [i18n.language]);
    const handlePaymentIntentError = useCallback((error, setError) => {
        const errorCode = getPaymentError(error);

        addNotification({
            styleType: 'error',
            message: t(`errors:payments.${errorCode}`),
            notificationKey: 'payment'
        });

        setError?.(errorCode)
    }, [i18n.language]);
    const handleSetupIntentError = useCallback((error, setError) => {
        setError(t(`errors:payments.${getPaymentError(error)}`))
    }, [i18n.language]);

    return {
        stripe,
        elements,
        createPaymentMethod,
        confirmSetupIntent,
        confirmPaymentIntent,
        handleSetupIntentError,
        handlePaymentIntentError,
        handlePaymentIntentSuccess
    };
};

const useReduxDispatch = () => {
    const dispatch = useDispatch();

    return {
        dispatch,
        addNotification: (notification) => {
            dispatch(addNotification(notification));
        }
    };
};
