import gqlRequest from "@biuwer/core/src/graphql-request";

/*
 * Oauth action types
 */
export const FULL_OAUTH_INITIALIZE = 'FULL_OAUTH_INITIALIZE';
export const OAUTH_INITIALIZE = 'OAUTH_INITIALIZE';

export const OAUTH_URL_FETCH_REQUEST = 'OAUTH_URL_FETCH_REQUEST';
export const OAUTH_URL_FETCH_SUCCESS = 'OAUTH_URL_FETCH_SUCCESS';
export const OAUTH_URL_FETCH_ERROR = 'OAUTH_URL_FETCH_ERROR';

export const OAUTH_CREATE_FETCH_REQUEST = 'OAUTH_CREATE_FETCH_REQUEST';
export const OAUTH_CREATE_FETCH_SUCCESS = 'OAUTH_CREATE_FETCH_SUCCESS';
export const OAUTH_CREATE_FETCH_ERROR = 'OAUTH_CREATE_FETCH_ERROR';

export const OAUTH_GET_FETCH_REQUEST = 'OAUTH_GET_FETCH_REQUEST';
export const OAUTH_GET_FETCH_SUCCESS = 'OAUTH_GET_FETCH_SUCCESS';
export const OAUTH_GET_FETCH_ERROR = 'OAUTH_GET_FETCH_ERROR';



/**
 * Initialize Full OAUTH
 */
export function initializeFullOauth() {
    return {
        type: FULL_OAUTH_INITIALIZE
    };
}

/**
 * Initialize OAUTH
 */
export function initializeOauth() {
    return {
        type: OAUTH_INITIALIZE
    };
}

/*
 * Get Oauth Oauth values action
 *!/*/
export function getOauthUrl(provider, service) {
    return async dispatch => {
        try {

            dispatch(oauthGetUrlFetchRequest());

            const resBody = await gqlRequest({
                queryGql: ``,
                queryName: "getOAuthUrl",
                variables: [{
                    type: "GetOAuthUrlInput!",
                    name: "getOAuthUrlInput",
                    data: {
                        provider: provider,
                        service: service
                    }
                }],
            });

            dispatch(oauthGetUrlFetchSuccess(resBody));

        } catch (err) {
            dispatch(oauthGetUrlFetchError(err.message || err));
        }
    };
}

function oauthGetUrlFetchRequest() {
    return {
        type: OAUTH_URL_FETCH_REQUEST,
        isFetching: true,
        error: false
    };
}

function oauthGetUrlFetchSuccess (payload) {
    return {
        type: OAUTH_URL_FETCH_SUCCESS,
        isFetching: false,
        error: false,
        payload: payload
    };
}

function oauthGetUrlFetchError(errorMessage) {
    return {
        type: OAUTH_URL_FETCH_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

/*
 * Get Oauth Oauth values action
 *!/*/
export function createOauth(stateParamValue, codeParamValue) {
    return async dispatch => {
        try {

            dispatch(oauthCreateFetchRequest());

            const resBody = await gqlRequest({
                queryType: "mutation",
                queryName: "createOAuth",
                variables: [{
                    type: "CreateOAuthInput!",
                    name: "createOAuthInput",
                    data: {
                        stateParamValue: stateParamValue,
                        codeParamValue: codeParamValue
                    }
                }],
            });

            dispatch(oauthCreateFetchSuccess(resBody));

        } catch (err) {
            dispatch(oauthCreateFetchError(err.message || err));
        }
    };
}

function oauthCreateFetchRequest() {
    return {
        type: OAUTH_CREATE_FETCH_REQUEST,
        isFetching: true,
        error: false
    };
}

function oauthCreateFetchSuccess (payload) {
    return {
        type: OAUTH_CREATE_FETCH_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function oauthCreateFetchError(errorMessage) {
    return {
        type: OAUTH_CREATE_FETCH_ERROR,
        isFetching: false,
        issue: true,
        issuePayload: errorMessage
    };
}


/*
 * Get Oauth Oauth values action
 *!/*/
export function getOauthByHash(hash) {

    return async dispatch => {
        try {

            dispatch(oauthGetFetchRequest());

            const resBody = await gqlRequest({
                queryGql: ``,
                queryName: "getOAuth",
                variables: [{
                    type: "GetOAuthInput!",
                    name: "getOAuthInput",
                    data: {
                        hash: hash
                    }
                }],
            });

            dispatch(oauthGetFetchSuccess(resBody));

        } catch (err) {
            dispatch(oauthGetFetchError(err.message || err));
        }
    };
}

function oauthGetFetchRequest() {
    return {
        type: OAUTH_GET_FETCH_REQUEST,
        isFetching: true,
        error: false
    };
}

function oauthGetFetchSuccess (payload) {
    return {
        type: OAUTH_GET_FETCH_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function oauthGetFetchError(errorMessage) {
    return {
        type: OAUTH_GET_FETCH_ERROR,
        isFetching: false,
        issue: true,
        issuePayload: errorMessage
    };
}

export const getOauth = (oauthId) => {
    return async dispatch => {
        try {

            dispatch(oauthGetFetchRequest());

            const resBody = await gqlRequest({
                queryGql: ``,
                queryName: "getOAuth",
                variables: [{
                    type: "GetOAuthInput!",
                    name: "getOAuthInput",
                    data: {
                        oauthId: oauthId
                    }
                }],
            });

            dispatch(oauthGetFetchSuccess(resBody));

        } catch (err) {
            dispatch(oauthGetFetchError(err.message || err));
        }
    };
};