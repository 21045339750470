import { Map, fromJS } from 'immutable';

import {
    ORDERS_INITIALIZE, ORDER_DETAIL_INITIALIZE, ORDER_LIST_INITIALIZE,
    ORDERS_FETCH_REQUEST, ORDERS_FETCH_SUCCESS, ORDERS_FETCH_ERROR,
    ORDER_FETCH_REQUEST, ORDER_FETCH_SUCCESS, ORDER_FETCH_ERROR,
    ORDER_CREATE_REQUEST, ORDER_CREATE_SUCCESS, ORDER_CREATE_ERROR,
    ORDER_CHANGE_STATUS_REQUEST, ORDER_CHANGE_STATUS_SUCCESS, ORDER_CHANGE_STATUS_ERROR,
    ORDER_CANCEL_REQUEST, ORDER_CANCEL_SUCCESS, ORDER_CANCEL_ERROR
} from './orders-actions';

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

/**
 * Orders Reducer
 */

const orders = (state = initialState, action) => {
    let newState, index = -1, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || `primary`}`;
    const detail = `detail_${(action && action.context) || `primary`}`;

    switch (action.type) {

        // Initialize
        case ORDERS_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));
            return newState;

        case ORDER_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;

        case ORDER_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;

        // Orders Fetch (list)
        case ORDERS_FETCH_REQUEST:

            newState = state;

            // Orders fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case ORDERS_FETCH_SUCCESS:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case ORDERS_FETCH_ERROR:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;


        // Order Fetch (detail)
        case ORDER_FETCH_REQUEST:

            newState = state;

            // Order fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);
            if (state.getIn([detail, 'error'])) newState = newState.removeIn([detail, 'error']);
            if (state.getIn([detail, 'errorMessage'])) newState = newState.removeIn([detail, 'errorMessage']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case ORDER_FETCH_SUCCESS:

            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;

        case ORDER_FETCH_ERROR:

            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;

        case ORDER_CREATE_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case ORDER_CREATE_SUCCESS:
            // Update payload with new created order
            detailUpdatedPayload = fromJS(action.payload.order);
            const paymentIntent = fromJS(action.payload.payment_intent)

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: detailUpdatedPayload,
                    paymentIntent
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload,
                        paymentIntent
                    }));
            }
            return newState;
        case ORDER_CREATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;

        case ORDER_CHANGE_STATUS_REQUEST:
        case ORDER_CANCEL_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case ORDER_CHANGE_STATUS_SUCCESS:
        case ORDER_CANCEL_SUCCESS:
            // Update payload with updated order
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the order index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            error: action.error,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }
            return newState;
        case ORDER_CHANGE_STATUS_ERROR:
        case ORDER_CANCEL_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;

        default:
            return state;
    }
};

export default orders;
