/**
 * Moment Initialization
 *
 */

import moment from 'moment';

export default (defaultLocale) => {
    moment.locale(defaultLocale);
};
