import { Map, fromJS } from 'immutable';

import {
    SECURITY_INITIALIZE,
    UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_ERROR,
    CHANGE_OWNER_REQUEST, CHANGE_OWNER_SUCCESS, CHANGE_OWNER_ERROR
} from './security-actions';

const initialState = Map({});

export default function security(state = initialState, action) {
    let newState;

    switch (action.type) {
        case SECURITY_INITIALIZE:
            return initialState;
        case UPDATE_PROFILE_REQUEST:
        case CHANGE_OWNER_REQUEST:
            newState = state
                .remove('payload')
                .remove('isFetching')
                .remove('error')
                .remove('issuePayload')
                .set('isFetching', action.isFetching);
            return newState;
        case UPDATE_PROFILE_SUCCESS:
        case CHANGE_OWNER_SUCCESS:
            newState = state
                .set('isFetching', action.isFetching)
                .set('error', action.error)
                .set('issuePayload', fromJS(action.issuePayload))
                .set('payload', fromJS(action.payload));
            return newState;
        case UPDATE_PROFILE_ERROR:
        case CHANGE_OWNER_ERROR:
            newState = state
                .set('isFetching', action.isFetching)
                .set('error', action.error)
                .set('issuePayload', fromJS(action.issuePayload));
            return newState;
        default:
            return state;
    }
}