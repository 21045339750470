import request from "@biuwer/common/src/libs/superagent";

import Auth from "@biuwer/redux/src/system/auth/auth-lib";

/*
 * Geography action types
 */

export const GEOGRAPHY_INITIALIZE = 'GEOGRAPHY_INITIALIZE';

export const GEOGRAPHY_COUNTRIES_FETCH_REQUEST = 'GEOGRAPHY_COUNTRIES_FETCH_REQUEST';
export const GEOGRAPHY_COUNTRIES_FETCH_SUCCESS = 'GEOGRAPHY_COUNTRIES_FETCH_SUCCESS';
export const GEOGRAPHY_COUNTRIES_FETCH_ERROR = 'GEOGRAPHY_COUNTRIES_FETCH_ERROR';

export const GEOGRAPHY_PROVINCES_FETCH_REQUEST = 'GEOGRAPHY_PROVINCES_FETCH_REQUEST';
export const GEOGRAPHY_PROVINCES_FETCH_SUCCESS = 'GEOGRAPHY_PROVINCES_FETCH_SUCCESS';
export const GEOGRAPHY_PROVINCES_FETCH_ERROR = 'GEOGRAPHY_PROVINCES_FETCH_ERROR';

export const GEOGRAPHY_TOWNS_FETCH_REQUEST = 'GEOGRAPHY_TOWNS_FETCH_REQUEST';
export const GEOGRAPHY_TOWNS_FETCH_SUCCESS = 'GEOGRAPHY_TOWNS_FETCH_SUCCESS';
export const GEOGRAPHY_TOWNS_FETCH_ERROR = 'GEOGRAPHY_TOWNS_FETCH_ERROR';

/*
 * Geography action creators
 */

/**
 * Initialize Geography
 */
export function initializeGeography() {
    return {
        type: GEOGRAPHY_INITIALIZE
    };
}

/**
 * Get Countries
 */
export function getCountries() {
    return dispatch => {
        let token = Auth.getLocalJwt();
        dispatch(countriesFetchRequest());

        return request
            .get('/api/geographies/countries/')
            .set('Authorization', 'Bearer ' + token)
            .on('response', (response) => Auth.checkResponse(response))
            .end((err, res) =>{
                if (err) {
                    dispatch(countriesFetchError('Error on getting Countries data from server'));
                } else if(res.body && res.body.status === 1) {
                    dispatch(countriesFetchSuccess(res.body.payload));
                }
            });
    };
}

function countriesFetchRequest() {
    return {
        type: GEOGRAPHY_COUNTRIES_FETCH_REQUEST,
        isFetching: true
    };
}

function countriesFetchSuccess (payload) {
    return {
        type: GEOGRAPHY_COUNTRIES_FETCH_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function countriesFetchError(error) {
    return {
        type: GEOGRAPHY_COUNTRIES_FETCH_ERROR,
        isFetching: false,
        error: true
    };
}

/**
 * Get Provinces
 * @param countryCode
 */
export function getProvinces(countryCode) {
    return dispatch => {
        let token = Auth.getLocalJwt();
        dispatch(provincesFetchRequest());

        return request
            .get('/api/geographies/countries/' + countryCode + '/provinces')
            .set('Authorization', 'Bearer ' + token)
            .on('response', (response) => Auth.checkResponse(response))
            .end((err, res) =>{
                if (err) {
                    dispatch(provincesFetchError('Error on getting Provinces data from server'));
                } else if(res.body && res.body.status === 1) {
                    dispatch(provincesFetchSuccess(res.body.payload));
                }
            });
    };
}

function provincesFetchRequest() {
    return {
        type: GEOGRAPHY_PROVINCES_FETCH_REQUEST,
        isFetching: true
    };
}

function provincesFetchSuccess (payload) {
    return {
        type: GEOGRAPHY_PROVINCES_FETCH_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function provincesFetchError(error) {
    return {
        type: GEOGRAPHY_PROVINCES_FETCH_ERROR,
        isFetching: false,
        error: true
    };
}

/**
 * Get Towns
 * @param countryCode
 * @param provinceCode
 */
export function getTowns(countryCode, provinceCode) {
    return dispatch => {
        let token = Auth.getLocalJwt();
        dispatch(townsFetchRequest());

        return request
            .get('/api/geographies/countries/' + countryCode + '/provinces/' + provinceCode + '/towns')
            .set('Authorization', 'Bearer ' + token)
            .on('response', (response) => Auth.checkResponse(response))
            .end((err, res) =>{
                if (err) {
                    dispatch(townsFetchError('Error on getting Towns data from server'));
                } else if(res.body && res.body.status === 1) {
                    dispatch(townsFetchSuccess(res.body.payload));
                }
            });
    };
}

function townsFetchRequest() {
    return {
        type: GEOGRAPHY_TOWNS_FETCH_REQUEST,
        isFetching: true
    };
}

function townsFetchSuccess (payload) {
    return {
        type: GEOGRAPHY_TOWNS_FETCH_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function townsFetchError(error) {
    return {
        type: GEOGRAPHY_TOWNS_FETCH_ERROR,
        isFetching: false,
        error: true
    };
}
