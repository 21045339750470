import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
// import i18n from '@biuwer/core/src/i18n';

import {addNotification} from "@biuwer/redux/src/system/notifications/notifications-actions";
import { defaultContext } from "@biuwer/redux/src/config/constants";
import { COUNTRY_VAT_DETAIL } from './countries-gql';

export const COUNTRIES_INITIALIZE = 'COUNTRIES_INITIALIZE';
export const COUNTRIES_LIST_INITIALIZE = 'COUNTRIES_LIST_INITIALIZE';
export const COUNTRIES_DETAIL_INITIALIZE = 'COUNTRIES_DETAIL_INITIALIZE';

export const COUNTRIES_FETCH_REQUEST = 'COUNTRIES_FETCH_REQUEST';
export const COUNTRIES_FETCH_SUCCESS = 'COUNTRIES_FETCH_SUCCESS';
export const COUNTRIES_FETCH_ERROR = 'COUNTRIES_FETCH_ERROR';

export const COUNTRY_FETCH_REQUEST = 'COUNTRY_FETCH_REQUEST';
export const COUNTRY_FETCH_SUCCESS = 'COUNTRY_FETCH_SUCCESS';
export const COUNTRY_FETCH_ERROR = 'COUNTRY_FETCH_ERROR';

// Generic Skeleton for every action
const generateSkeleton = (type, body, context = defaultContext, showNotification = true) => {
    let skeleton = { type }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false,
                context: context
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                context: context
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body,
                context: context
            };
            break;
        default:
            break;
    }
    // switch (type) {
    //     case COUNTRY_FETCH_ERROR:
    //     case COUNTRIES_FETCH_ERROR:
    //         notification = {
    //             styleType: 'error',
    //             message: i18n.t('notifications.error')
    //         };
    //         break;
    //     default:
    //         break;
    // }

    return (dispatch) => {

        dispatch(skeleton);

        if (showNotification && notification) {
            dispatch(addNotification(notification));
        }
    }
};

/**
 * Initialize Organizations
 */
export function initializeCountries() {
    return {
        type: COUNTRIES_INITIALIZE
    };
}

/**
 * Initialize Organizations
 */
export function initializeListCountries() {
    return {
        type: COUNTRIES_LIST_INITIALIZE
    };
}

/**
 * Initialize Organizations
 */
export function initializeDetailCountries() {
    return {
        type: COUNTRIES_DETAIL_INITIALIZE
    };
}

/**
 * Get organizations from server (read)
 *
 */
export function getCountries(query = {}, context = defaultContext, gql = COUNTRY_VAT_DETAIL) {
    return async dispatch => {
        try {
            const graphQuery = {
                query: `query($query: QueryCountryInput!) {
                    readCountries(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(COUNTRIES_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(graphQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(COUNTRIES_FETCH_ERROR, 'Error on getting countries from server', context));
                } else if (res.body && res.body.data && res.body.data.readCountries) {
                    dispatch(generateSkeleton(COUNTRIES_FETCH_SUCCESS, res.body.data.readCountries, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(COUNTRIES_FETCH_ERROR, e, context));
        }
    };
}


/**
 * Get organization info from server (readOne)
 *
 */
export function getCountry (code, context = defaultContext, gql = COUNTRY_VAT_DETAIL) {
    return async dispatch => {

        try {

            const token = Auth.getLocalJwt();
            const query = {
                query: `query($code: String!) {
                    readCountry(code: $code) {
                        ${gql}
                    }
                }`,
                variables: {
                    code: code
                }
            };

            dispatch(generateSkeleton(COUNTRY_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(COUNTRY_FETCH_ERROR, 'Error on getting country from server', context));
                } else if (res.body && res.body.data && res.body.data.readCountry) {
                    dispatch(generateSkeleton(COUNTRY_FETCH_SUCCESS, res.body.data.readCountry, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(COUNTRY_FETCH_ERROR, e, context));
        }
    };
}