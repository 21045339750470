
// Libs
import gqlRequest from '@biuwer/core/src/graphql-request';
import i18n from '@biuwer/core/src/i18n';
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import { defaultContext } from "@biuwer/redux/src/config/constants";
import history from '@biuwer/core/src/history';

// Actions
import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

// GQL queries
import { THEMES_LIST, THEME_DETAIL } from './themes-gql';

/**
 * Themes action types
 */

export const THEMES_INITIALIZE = 'THEMES_INITIALIZE';
export const THEME_DETAIL_INITIALIZE = 'THEME_DETAIL_INITIALIZE';
export const THEME_LIST_INITIALIZE = 'THEME_LIST_INITIALIZE';

export const THEMES_FETCH_REQUEST = 'THEMES_FETCH_REQUEST';
export const THEMES_FETCH_SUCCESS = 'THEMES_FETCH_SUCCESS';
export const THEMES_FETCH_ERROR = 'THEMES_FETCH_ERROR';

export const THEME_FETCH_REQUEST = 'THEME_FETCH_REQUEST';
export const THEME_FETCH_SUCCESS = 'THEME_FETCH_SUCCESS';
export const THEME_FETCH_ERROR = 'THEME_FETCH_ERROR';

export const THEME_UPDATE_REQUEST = 'THEME_UPDATE_REQUEST';
export const THEME_UPDATE_SUCCESS = 'THEME_UPDATE_SUCCESS';
export const THEME_UPDATE_ERROR = 'THEME_UPDATE_ERROR';

export const THEME_CREATE_REQUEST = 'THEME_CREATE_REQUEST';
export const THEME_CREATE_SUCCESS = 'THEME_CREATE_SUCCESS';
export const THEMES_CREATE_SUCCESS = 'THEMES_CREATE_SUCCESS';
export const THEME_CREATE_ERROR = 'THEME_CREATE_ERROR';

export const THEME_DELETE_REQUEST = 'THEME_DELETE_REQUEST';
export const THEME_DELETE_SUCCESS = 'THEME_DELETE_SUCCESS';
export const THEME_DELETE_ERROR = 'THEME_DELETE_ERROR';

// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, showNotification = true) => {
    let skeleton = { type, context }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.statusCode,
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body
            };

            if(body.status === 2) {
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:
            break;
    }

    switch(type) {
        case THEME_CREATE_SUCCESS:
            skeleton.created = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', { name: i18n.t('admin.organization.themes.title'), context: 'male', count: 1 })
            };

            setTimeout(() => {
                history.push("/admin/organization/themes");
            }, 1000);

            break;
        case THEME_UPDATE_SUCCESS:
            skeleton.updated = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('admin.organization.themes.title'), context: 'male', count: 1 })
            };

            setTimeout(() => {
                history.push("/admin/organization/themes");
            }, 1000);

            break;
        case THEME_DELETE_SUCCESS:
            skeleton.deleted = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', { name: i18n.t('admin.organization.themes.title'), context: 'male', count: 1 })
            };

            break;
        case THEME_FETCH_ERROR:
            skeleton.issuePayload = {
                ...skeleton.issuePayload,
                // code: extraArgs.code
            };
            break;
        case THEME_CREATE_ERROR:
        case THEME_UPDATE_ERROR:
        case THEME_DELETE_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    };
};

/*
 * Action creators
 */

/**
 * Initialize themes
 */
export const initializeThemes = (context = defaultContext) => ({
    type: THEMES_INITIALIZE,
    context
});

export const initializeThemeDetail = (context = defaultContext) => ({
    type: THEME_DETAIL_INITIALIZE,
    context
});

export const initializeThemeList = (context = defaultContext) => ({
    type: THEME_LIST_INITIALIZE,
    context
});

export const getThemes = (context = defaultContext, gql = THEMES_LIST) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(THEMES_FETCH_REQUEST, null, context));

        try {
            const themes = await gqlRequest({
                queryName: "readThemes",
                queryGql: gql,
                token: Auth.getLocalJwt()
            });

            dispatch(generateSkeleton(THEMES_FETCH_SUCCESS, themes, context));
        } catch (err) {
            dispatch(generateSkeleton(THEMES_FETCH_ERROR, err, context));
        }
    };
};

export const getTheme = (themeId, context = defaultContext, gql = THEME_DETAIL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(THEME_FETCH_REQUEST, null, context));

        try {
            const theme = await gqlRequest({
                queryName: "readTheme",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "String!",
                    name: "_id",
                    data: themeId
                }]
            });

            dispatch(generateSkeleton(THEME_FETCH_SUCCESS, theme, context));
        } catch (err) {
            dispatch(generateSkeleton(THEME_FETCH_ERROR, err, context));
        }
    }
};

export const createTheme = (theme, context = defaultContext, gql = THEME_DETAIL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(THEME_CREATE_REQUEST, null, context));

        try {
            const newTheme = await gqlRequest({
                queryType: "mutation",
                queryName: "createTheme",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "NewThemeInput!",
                    name: "theme",
                    data: theme
                }]
            });
            dispatch(generateSkeleton(THEME_CREATE_SUCCESS, newTheme, context));
        } catch (err) {
            dispatch(generateSkeleton(THEME_CREATE_ERROR, err, context));
        }
    }
};

export const updateTheme = (theme, context = defaultContext, gql = THEME_DETAIL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(THEME_UPDATE_REQUEST, null, context));

        try {
            const updatedTheme = await gqlRequest({
                queryType: "mutation",
                queryName: "updateTheme",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "ThemeInput!",
                    name: "theme",
                    data: theme
                }]
            });
            dispatch(generateSkeleton(THEME_UPDATE_SUCCESS, updatedTheme, context));
        } catch (err) {
            dispatch(generateSkeleton(THEME_UPDATE_ERROR, err, context));
        }
    };
};

export const deleteTheme = (themeId, context = defaultContext, gql = THEME_DETAIL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(THEME_DELETE_REQUEST, null, context));

        try {
            const deletedTheme = await gqlRequest({
                queryType: "mutation",
                queryName: "deleteTheme",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "String!",
                    name: "_id",
                    data: themeId
                }]
            });
            dispatch(generateSkeleton(THEME_DELETE_SUCCESS, deletedTheme, context));
        } catch (err) {
            dispatch(generateSkeleton(THEME_DELETE_ERROR, err, context));
        }
    };
};