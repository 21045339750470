
// Libs
import i18n from "@biuwer/core/src/i18n";
import gqlRequest from "@biuwer/core/src/graphql-request";
import { defaultContext } from "@biuwer/redux/src/config/constants";

// Actions
import {addNotification} from "@biuwer/redux/src/system/notifications/notifications-actions";

/*
 * System Users action types
 */

export const SECURITY_INITIALIZE = 'SECURITY_INITIALIZE';

export const CHANGE_OWNER_REQUEST = 'CHANGE_OWNER_REQUEST';
export const CHANGE_OWNER_SUCCESS = 'CHANGE_OWNER_SUCCESS';
export const CHANGE_OWNER_ERROR = 'CHANGE_OWNER_ERROR';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

const generateSkeleton = (type, body, context = defaultContext, extraArgs) => {
    let skeleton = { type, context };

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)){
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.issuePayload ? body.issuePayload.code : body.payload, // Backward compatibility with old error handling
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body.payload
            };

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:

            break;
    }

    switch(type){
        case UPDATE_PROFILE_REQUEST:
            break;
        case CHANGE_OWNER_REQUEST:
            break;
        case UPDATE_PROFILE_SUCCESS:
        case CHANGE_OWNER_SUCCESS:
            skeleton.objectType = extraArgs.objectType;
            break;
        case UPDATE_PROFILE_ERROR:
            break;
        case CHANGE_OWNER_ERROR:
            break;
        default:
            break;
    }

    return skeleton;
};

/*
 * Action creators
 */

/**
 * Initialize Security
 */
export function initializeSecurity(context = defaultContext) {
    return {
        type: SECURITY_INITIALIZE,
        context
    };
}

/**
 * Update profile action
 */
export function updateProfiles(objectType, objectIds, profiles, context = defaultContext) {
    return async dispatch => {
        try {

            dispatch(generateSkeleton(UPDATE_PROFILE_REQUEST, null, context, { objectIds: objectIds }));

            const resBody = await gqlRequest({
                queryType: "mutation",
                queryName: "updateProfiles",
                variables: [{
                    type: "UpdateProfilesInput!",
                    name: "updateProfilesInput",
                    data: {
                        objectType: objectType,
                        objectIds: objectIds,
                        profiles: profiles
                    }
                }]
            });

            dispatch(generateSkeleton(UPDATE_PROFILE_SUCCESS, resBody, context,{ objectType: objectType }));
            dispatch(addNotification({
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('auth.accessSettingsLabel'), context: 'female', count: 1 })
            }));

        } catch (err) {
            dispatch(generateSkeleton(UPDATE_PROFILE_ERROR, err, context,{ objectType: objectType }));
            dispatch(addNotification({
                styleType: 'error',
                message: i18n.t('notifications.error')
            }));
        }
    };
}

/**
 * Change owner action
 */
export function changeOwner(objectType, objectIds, own, context = defaultContext) {
    return async dispatch => {
        try {

            dispatch(generateSkeleton(CHANGE_OWNER_REQUEST, null, context,{ objectIds: objectIds }));

            const resBody = await gqlRequest({
                queryType: "mutation",
                queryName: "changeOwner",
                variables: [{
                    type: "ChangeOwnerInput!",
                    name: "changeOwnerInput",
                    data: {
                        objectType: objectType,
                        objectIds: objectIds,
                        own: own
                    }
                }]
            });

            dispatch(generateSkeleton(CHANGE_OWNER_SUCCESS, resBody, context, { objectType: objectType }));

        } catch (err) {
            dispatch(generateSkeleton(CHANGE_OWNER_ERROR, err, context, { objectType: objectType }));
        }
    };
}