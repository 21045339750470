import React, { Component } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { withTranslation } from "react-i18next";
import {cloneDeep} from "lodash";
import i18n from '@biuwer/core/src/i18n';
import moment from "moment";
import numeral from "numeral";

// Components
import TextInput from '@biuwer/common/src/components/text-input';
import PasswordStrength from "@biuwer/common/src/components/password-strength"

// Actions
import { signupConfirmation } from "@biuwer/redux/src/system/auth/signup-actions";
import { validateResetPassword as checkToken } from "@biuwer/redux/src/system/auth/password-actions";

// Libs
import Auth from "@biuwer/redux/src/system/auth/auth-lib";

class SignupConfirmation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            signupToken: '',
            userPassword: '',
            validatePassword: '',
            errorMessage: '',
            canSubmit: false
        };

        this.onChangeTextField = this.onChangeTextField.bind(this);
        this.handlePressEnter = this.handlePressEnter.bind(this);
    }

    UNSAFE_componentWillMount() {
        // Redirect to root path if user is logged in successfully
        if(this.props.isLoggedIn) {
            this.props.history.push('/');
        }

        // Get token and language
        const newState = cloneDeep(this.state);
        const signupParams = new URLSearchParams(this.props.location.search);

        this.onChangeLanguage(signupParams.get('language') || 'en');
        newState.signupToken = this.props.match.params.signupToken || '';
        newState.orgName = signupParams.get('orgName');

        this.setState(newState);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // Redirect to root path if user is logged in successfully
        if(nextProps.isLoggedIn) {
            this.props.history.push('/');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Auto login
        if (this.props.autoLogInToken !== prevProps.autoLogInToken && !!this.props.autoLogInToken) {
            this.props.dispatch(checkToken(this.props.autoLogInToken));
        }
    }

    static canSubmit(state) {
        return state.signupToken !== '' &&
            !!Auth.passwordRegExp.complete.exec(state.userPassword) &&
            state.userPassword === state.validatePassword
            ;
    }

    onChangeTextField(e) {
        const t = this.props.t;
        let tempStateVar = {
            ...this.state,
            [e.target.name]: e.target.value
        };

        if (e.target.name === 'userPassword') {
            tempStateVar.validPassword = !!Auth.passwordRegExp.complete.exec(e.target.value);
        }

        this.setState({
            ...tempStateVar,
            canSubmit: SignupConfirmation.canSubmit(tempStateVar)
        }, () => {
            let { userPassword, validatePassword } = this.state;

            if (userPassword && !tempStateVar.validPassword) {
                this.setState({errorMessage: t('admin.users.checkPasswordRequirementsMessage')});
            } else if (userPassword !== validatePassword) {
                this.setState({errorMessage: t('admin.users.notMatchingPasswordsMessage')});
            } else {
                this.setState({errorMessage: ''});
            }
        });
    }

    handlePressEnter(e) {
        const ENTER = 13;
        if(e.keyCode === ENTER && SignupConfirmation.canSubmit(this.state)) {
            this.signupConfirmation();
        }
    }

    signupConfirmation = () => {
        this.props.dispatch(signupConfirmation(this.state.signupToken, window.btoa(this.state.userPassword)));
    };

    onChangeLanguage = (language) => {
        if (language === 'en' || language === 'es') {
            if (language !== i18n.language) {
                i18n.changeLanguage(language);
                moment.locale(language);
                numeral.locale(language);
            }
        }

        return language;
    };

    render() {
        const t = this.props.t;
        let formBody;

        if (this.props.signupIsFetching) {
            formBody = (
                <div className="animated fadeIn">
                    <h4 className="p-t-25 p-b-25 text-center">{t('auth.signupConfirmation.headerFinishTitle')}</h4>
                    <span>{t('auth.signupConfirmation.processingSignup')}</span>
                    <div className="progress">
                        <div className="progress-bar-indeterminate" />
                    </div>
                </div>
            );
        }
        else if (this.props.signupErrorMessage) {
              formBody = (<div className="animated fadeIn"><h2>{this.props.signupErrorMessage}</h2></div>);
        }
        else {
            formBody = (
                <div className="animated fadeIn">
                    <h4 className="p-t-25 text-center">{t('auth.signupConfirmation.welcome')}</h4>
                    <h5 className="p-t-25 text-center">{t('auth.signupConfirmation.welcomeInfo')}</h5>
                    <h3 className="text-center font-weight-bold">{this.state.orgName}</h3>
                    <h5 className="text-center">{t('auth.signupConfirmation.welcomeInfo2')}</h5>
                    <h5 className="p-t-25 text-center">{t('auth.signupConfirmation.headerTitle')}</h5>
                    <div id="form-login" className="p-t-15">
                        <TextInput
                            formStyling="default"
                            type="password"
                            name="userPassword"
                            labelText={t('auth.passwordLabel')}
                            value={this.state.userPassword}
                            onChange={this.onChangeTextField}
                            onKeyDown={this.handlePressEnter}
                        />
                        <TextInput
                            formStyling="default"
                            type="password"
                            name="validatePassword"
                            labelText={t('auth.validatePasswordLabel')}
                            value={this.state.validatePassword}
                            onChange={this.onChangeTextField}
                            onKeyDown={this.handlePressEnter}
                            errorText={this.state.errorMessage}
                        />
                        <PasswordStrength password={this.state.userPassword} validPassword={this.state.validPassword} t={t} />
                        <button
                            className="btn btn-info btn-sm m-t-10"
                            type="button"
                            onClick={this.signupConfirmation}
                            disabled={!this.state.canSubmit}
                        >
                            {t('auth.signupConfirmation.submitButton').toUpperCase()}
                        </button>
                    </div>
                </div>
            );
        }

        return formBody;

    }
}

function mapStateToProps(state, ownProps) {
    const { t } = ownProps;

    // Reducers
    const sessionReducer = state.system.auth.session;
    const signupReducer = state.system.auth.signup;

    // Session
    const isLoggedIn = sessionReducer.get('isLoggedIn');

    // Signup
    const signupIsFetching = signupReducer.get('signupIsFetching');
    const autoLogInToken = signupReducer.get('autoLogInToken');
    const errorSignup = signupReducer.get('errorSignup') || Immutable.Map({});

    // Look for error code in 18n strings
    let signupErrorMessage = errorSignup.size > 0 ? t(`errors.${errorSignup.get('code')}`) : '';

    return {
        isLoggedIn,
        signupIsFetching,
        autoLogInToken,
        signupErrorMessage
    }
}

export default withTranslation()(connect(mapStateToProps)(SignupConfirmation));
