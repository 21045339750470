import { Component } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { withTranslation } from "react-i18next";
import {cloneDeep} from "lodash";
import Helmet from "react-helmet";

// Actions
import { signupRequest, signupCleanError} from "@biuwer/redux/src/system/auth/signup-actions";
import { initLanguage } from "@biuwer/redux/src/system/auth/session-actions";

// Components
import SignupForm from './signup-form.jsx';
import SignupEmailSent from './signup-email-sent';

// Libs
import HelmetLib from "@biuwer/common/src/libs/helmet-lib.js";

class Signup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            screen: 'signupRequest',
            language: props.lng,
        };

        this.signupRequest = this.signupRequest.bind(this);
        this.cleanErrorMessageSignupForm = this.cleanErrorMessageSignupForm.bind(this);
        this.toggleScreen = this.toggleScreen.bind(this);
    }

    UNSAFE_componentWillMount() {
        initLanguage();
        // Redirect to root path if user is logged in successfully
        if(this.props.isLoggedIn) {
            this.props.history.push('/');
        }

        // Get URL params and save in state
        const newState = cloneDeep(this.state);

        this.setState(newState);

    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        // Redirect to root path if user is logged in successfully
        if(nextProps.isLoggedIn) {
            this.props.history.push('/');
        }

        if (!!nextProps.userEmail) {
            this.toggleScreen('signupEmailSent')
        }
    }

    signupRequest(signup) {
        this.props.dispatch(signupRequest({
            user_name:  signup.userName,
            user_surname:  signup.userSurname,
            user_email:  signup.userEmail,
            organization_name: signup.organizationName,
            phone: signup.phone,
            organization_settings: signup.organizationSettings,
            language:signup.organizationSettings.language,
            accept_terms_and_privacy_policy: signup.acceptTermsAndPrivacyPolicy,
            send_marketing_mail: signup.sendMarketingMail
        }));
    }

    // Error Message component event for close/hide the error in Signin Form
    cleanErrorMessageSignupForm() {
        this.props.dispatch(signupCleanError());
    }

    toggleScreen(screen) {
        if(screen) {
            this.setState({
                screen: screen
            });
        }
    }

    render() {
        let formBody;
        const { t } = this.props;

        switch(this.state.screen){
            case 'signupRequest':
                formBody = (
                    <SignupForm
                        toggleScreen={this.toggleScreen}
                        signupRequest={this.signupRequest}
                        error={this.props.signupErrorMessage}
                        cleanErrorMessageSignupForm={this.cleanErrorMessageSignupForm}
                        t={t}
                        location={this.props.location}
                    />
                );
                break;
            case 'signupEmailSent':
                formBody = (
                    <SignupEmailSent
                        toggleScreen={this.toggleScreen}
                        signupRequest={this.signupRequest}
                        error={this.props.signupErrorMessage}
                        cleanErrorMessageSignupForm={this.cleanErrorMessageSignupForm}
                        t={t}
                        userEmail={this.props.userEmail}
                    />
                );
                break;
            default:
                formBody = null;
                break;
        }

        return (
            <>
                <Helmet
                    title={HelmetLib.generatePageTitle(t('auth.signupForm.signup'))}
                />
                {formBody}
            </>
        );

    }
}

function mapStateToProps(state, ownProps) {
    const { t } = ownProps;

    // Reducers
    const sessionReducer = state.system.auth.session;
    const signupReducer = state.system.auth.signup;

    // Session
    const isLoggedIn = sessionReducer.get('isLoggedIn');

    // Signup
    const userEmail = signupReducer.get('userEmail');
    const errorSignup = signupReducer.get('errorSignup') || Immutable.Map({});

    // Look for error code in 18n strings
    let signupErrorMessage = errorSignup.size > 0 ? t(`errors.${errorSignup.get('code')}`) : '';

    return {
        isLoggedIn,
        userEmail,
        signupErrorMessage
    }
}

export default withTranslation()(connect(mapStateToProps)(Signup));
