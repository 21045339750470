export const EXECUTIONS_LIST_PAGE = `
    _id
    organization { 
        _id 
        name
    }
    job {
        _id
        name
    }
    status
    agent {
        name
    }
    start_date
    end_date
    execution_time
    progress
    error
`;

export const EXECUTION_DETAIL_PAGE = `
    _id
    is_container
    job {
        _id
        name
    }
    status
    agent {
        name
    }
    start_date
    end_date
    execution_time
    steps {
        step_id
        name
        start_date
        end_date
        execution_time
        status
        steps {
            step_id
            name
            start_date
            end_date
            execution_time
            status
            error
        }
        error
    }
    progress
`;

export const JOB_EXECUTION = `
    _id
`;

export const DATA_CONNECTION_EXECUTION = `
    _id
    status
    start_date
    end_date
    progress
    error
`;