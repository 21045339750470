const CAN_DELETE_GQL = `
    canDelete
    dependencies
    objectId
`;

const TRACEABILITY_GQL = `
    dependencies
    objectId
`;

export {
    CAN_DELETE_GQL,
    TRACEABILITY_GQL
}