export const AUTH_TOKENS_DETAIL = `
    _id
    user {
        _id
        email
        full_name
    }
    valid_from
    valid_to
    status
`;