import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { isEmail } from 'validator';
import Select from 'react-select';
import moment from 'moment-timezone';
import { Trans } from 'react-i18next';

// Components
import CheckInput from "@biuwer/common/src/components/check-input"
import TextInput from "@biuwer/common/src/components/text-input"
import InfoMessage from "@biuwer/common/src/components/info-message"

//Libs
import { biuwerSingleValueWindowedComponents, defaultBiuwerStyles, defaultBiuwerTheme} from "@biuwer/common/src/libs/react-select-lib";
import i18n from '@biuwer/core/src/i18n';
import chatLib from '@biuwer/common/src/libs/chat-lib';

class SignupForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userName: '',
            userSurname: '',
            userEmail: '',
            organizationName: '',
            phone: '',
            acceptTermsAndPrivacyPolicy: false,
            sendMarketingMail: false,
            canSubmit: false,
            createDemoContent: true,
            language: props && props.location && props.location.search.indexOf('language=es') >= 0 ? 'es' : 'en'
        };

        this.signupRequest = this.signupRequest.bind(this);
        this.onCloseErrorMessage = this.onCloseErrorMessage.bind(this);
        this.onChangeTextField = this.onChangeTextField.bind(this);
        this.handlePressEnter = this.handlePressEnter.bind(this);
    }

    UNSAFE_componentWillMount() {
        // Clean errors before mount the component
        this.props.cleanErrorMessageSignupForm();
    }

    signupRequest() {
        this.props.signupRequest({
            userName: this.state.userName,
            userSurname: this.state.userSurname,
            userEmail: this.state.userEmail,
            organizationName: this.state.organizationName,
            phone: this.state.phone,
            organizationSettings: {
                language: this.state.language,
                currency: this.state.language === 'es' ? 'eur' : 'usd',
                date_format: this.state.language === 'es' ? 'DD/MM/YYYY' : 'MM-DD-YYYY',
                time_zone: moment.tz.guess(true),
                number_format: {
                    decimal: this.state.language === 'es' ? ',' : '.',
                    thousands: this.state.language === 'es' ? '.' : ','
                },
                show_demo_space: this.state.createDemoContent
            },
            acceptTermsAndPrivacyPolicy: this.state.acceptTermsAndPrivacyPolicy,
            sendMarketingMail: this.state.sendMarketingMail
        });
    }

    // Error Message component event for close/hide the error
    onCloseErrorMessage() {
        this.props.cleanErrorMessageSignupForm();
    }

    static canSubmit(state) {
        return state.userName !== '' &&
            isEmail(state.userEmail, { allow_utf8_local_part: false }) &&
            state.organizationName !== '' &&
            state.phone !== '' &&
            state.acceptTermsAndPrivacyPolicy
        ;
    }

    onChangeTextField(e) {
        let tempStateVar = {
            ...this.state,
            [e.target.name]: e.target.value
        };

        this.setState({
            [e.target.name]: e.target.value,
            canSubmit: SignupForm.canSubmit(tempStateVar)
        });
    }

    onChangeCheckInput(name) {
        let tempStateVar = {
            ...this.state,
            [name]: !this.state[name]
        };

        this.setState({
            [name]: !this.state[name],
            canSubmit: SignupForm.canSubmit(tempStateVar)
        });
    }

    handlePressEnter(e) {
        const ENTER = 13;
        if(e.keyCode === ENTER && SignupForm.canSubmit(this.state)) {
           this.signupRequest();
        }
    }

    render() {
        const { t } = this.props;

        // Language options
        const languageOptions = [
            { label: t('admin.organization.language.en'), value: 'en' },
            { label: t('admin.organization.language.es'), value: 'es' }
        ];

        let urlTerms = '', urlPrivacy = '';
        switch (i18n.language) {
            case 'es':
                urlTerms = 'https://biuwer.com/es/legal/terminos-de-servicio/';
                urlPrivacy = 'https://biuwer.com/es/legal/politica-de-privacidad/';
                break;
            case 'en':
            default:
                urlTerms = 'https://biuwer.com/en/legal/terms-of-service/';
                urlPrivacy = 'https://biuwer.com/en/legal/privacy-policy/';
                break;
        }
        if (i18n.language !== this.state.language) {
            switch (this.state.language) {
                case 'es':
                    urlTerms = 'https://biuwer.com/es/legal/terminos-de-servicio/';
                    urlPrivacy = 'https://biuwer.com/es/legal/politica-de-privacidad/';
                    break;
                case 'en':
                default:
                    urlTerms = 'https://biuwer.com/en/legal/terms-of-service/';
                    urlPrivacy = 'https://biuwer.com/en/legal/privacy-policy/';
                    break;
            }
        }

        return (
            <div className="animated fadeIn">
                <h4 className="p-t-25 text-center">{t('auth.signupForm.headerTitle')}</h4>
                <div id="form-login" className="pv15">
                        <div className="row">
                            <div className="col-6">
                                <TextInput
                                    formStyling="default"
                                    type="text"
                                    name="userName"
                                    labelText={t('auth.userNameLabel')}
                                    placeholder={t('auth.userNameLabel')}
                                    value={this.state.userName}
                                    onChange={this.onChangeTextField}
                                    onKeyDown={this.handlePressEnter}
                                />
                            </div>
                            <div className="col-6">
                                <TextInput
                                    formStyling="default"
                                    type="text"
                                    name="userSurname"
                                    labelText={t('auth.userSurnameLabel')}
                                    placeholder={t('auth.userSurnameLabel')}
                                    value={this.state.userSurname}
                                    onChange={this.onChangeTextField}
                                    onKeyDown={this.handlePressEnter}
                                />
                            </div>
                        </div>

                        <TextInput
                            formStyling="default"
                            type="text"
                            name="userEmail"
                            labelText={t('auth.emailLabel')}
                            placeholder={t('auth.emailLabel')}
                            value={this.state.userEmail}
                            onChange={this.onChangeTextField}
                            onKeyDown={this.handlePressEnter}
                        />

                        <TextInput
                            formStyling="default"
                            type="text"
                            name="organizationName"
                            labelText={t('auth.organizationNameLabel')}
                            placeholder={t('auth.organizationNameLabel')}
                            value={this.state.organizationName}
                            onChange={this.onChangeTextField}
                            onKeyDown={this.handlePressEnter}
                        />

                        <TextInput
                            formStyling="default"
                            type="text"
                            name="phone"
                            labelText={t('auth.phoneLabel')}
                            placeholder={t('auth.phoneLabel')}
                            value={this.state.phone}
                            onChange={this.onChangeTextField}
                            onKeyDown={this.handlePressEnter}
                        />

                        <div className="row mb10">
                            <div className="col-md-12">
                                <div className="form-group-default">
                                    <div className="mb5" style={{ fontWeight: 500 }}>
                                        {t("admin.organization.languageLabel")}
                                    </div>
                                    <Select
                                        id="attributeSelector"
                                        value={languageOptions.filter(opt => opt.value === this.state.language )}
                                        options={languageOptions}
                                        onChange={(valueSelected) => {
                                            this.setState({
                                                language: valueSelected.value,
                                            });
                                        }}
                                        getOptionLabel={(option) => option.label}
                                        getOptionValue={(option) => option.value}
                                        isClearable={false}
                                        isSearchable={true}
                                        theme={defaultBiuwerTheme}
                                        styles={{
                                            ...defaultBiuwerStyles,
                                            control: style => ({ ...style, border: 'none' }),
                                            indicatorSeparator: style => ({ ...style, display: 'none' })
                                        }}
                                        components={biuwerSingleValueWindowedComponents}
                                        menuPortalTarget={document.body}
                                    />
                                </div>
                            </div>
                        </div>

                    <div className="row">
                        <div className="col-md-12">
                            <InfoMessage
                                onCloseErrorMessage={this.onCloseErrorMessage}
                                message={this.props.error}
                                type="danger"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <CheckInput
                                type="checkbox"
                                value="createDemoContent"
                                className="fs-12 fit-content"
                                labelText={t('auth.signupForm.createDemoContent')}
                                checked={this.state.createDemoContent}
                                onChange={(value)=> this.onChangeCheckInput(value)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <CheckInput
                                type="checkbox"
                                value="acceptTermsAndPrivacyPolicy"
                                className="fs-12 fit-content"
                                checked={this.state.acceptTermsAndPrivacyPolicy}
                                onChange={(value)=> this.onChangeCheckInput(value)}
                            >
                                <Trans
                                    i18nKey="auth.signupForm.termsAndPrivacyMessage"
                                    components={[
                                        <a target="_blank" rel="noopener noreferrer" href={urlTerms} className="bold">placeholder</a>,
                                        <a target="_blank" rel="noopener noreferrer" href={urlPrivacy} className="bold">placeholder</a>
                                    ]}
                                />
                            </CheckInput>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <CheckInput
                                type="checkbox"
                                value="sendMarketingMail"
                                className="fs-12 fit-content"
                                labelText={t('auth.signupForm.marketingMessage')}
                                checked={this.state.sendMarketingMail}
                                onChange={(value)=> this.onChangeCheckInput(value)}
                            />
                        </div>
                    </div>
                    <button
                        className="btn btn-info btn-sm m-t-10"
                        type="button"
                        onClick={this.signupRequest}
                        disabled={!this.state.canSubmit}
                    >
                        {t('auth.signupForm.submitButton').toUpperCase()}
                    </button>
                    <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
                        <div className="col-12">
                            <Link to="/signin" className="span-link text-info small">
                                {t('auth.signupForm.signinLabel')}
                            </Link>
                        </div>
                    </div>
                </div>
                <div>
                    <span onClick={chatLib.toggleChat} className="span-link text-info small">
                        {t('auth.signinForm.helpChat')}
                    </span>
                </div>
            </div>
        )
    }
}

export default SignupForm;
