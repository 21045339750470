import { Map, fromJS } from 'immutable';

import {
    JOBS_FETCH_REQUEST, JOBS_FETCH_SUCCESS, JOBS_FETCH_ERROR,
    JOB_INITIALIZE, JOBS_LIST_INITIALIZE, JOB_DETAIL_INITIALIZE,
    JOB_FETCH_REQUEST, JOB_FETCH_SUCCESS, JOB_FETCH_ERROR,
    JOB_CREATE_REQUEST, JOB_CREATE_SUCCESS, JOB_CREATE_ERROR,
    JOB_UPDATE_REQUEST, JOB_UPDATE_SUCCESS, JOB_UPDATE_ERROR,
    JOB_DELETE_REQUEST, JOB_DELETE_SUCCESS, JOB_DELETE_ERROR,
    JOB_DUPLICATE_REQUEST, JOB_DUPLICATE_SUCCESS, JOB_DUPLICATE_ERROR,
    JOB_EXECUTE_REQUEST, JOB_EXECUTE_SUCCESS, JOB_EXECUTE_ERROR,
    SCHEDULE_CREATE_REQUEST, SCHEDULE_CREATE_SUCCESS, SCHEDULE_CREATE_ERROR,
    SCHEDULE_UPDATE_REQUEST, SCHEDULE_UPDATE_SUCCESS, SCHEDULE_UPDATE_ERROR,
    SCHEDULE_DELETE_REQUEST, SCHEDULE_DELETE_SUCCESS, SCHEDULE_DELETE_ERROR
} from './jobs-actions.js';

import {
    STEP_CREATE_SUCCESS, STEP_DELETE_SUCCESS
} from '../steps/steps-actions.js';

import { defaultContext } from "@biuwer/redux/src/config/constants";

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

/**
 * Agents Reducers
 */

export default (state = initialState, action) =>{

    let newState, index, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || defaultContext}`;
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch (action.type) {

        // Necessary to initialize a specific agent state
        case JOB_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));

            return newState;
        case JOB_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;
        case JOBS_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;
        case JOBS_FETCH_REQUEST:

            newState = state;

            // Jobs fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case JOBS_FETCH_SUCCESS:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case JOBS_FETCH_ERROR:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case JOB_FETCH_REQUEST:

            newState = state;

            // Job fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case JOB_FETCH_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case JOB_FETCH_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case JOB_CREATE_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case JOB_CREATE_SUCCESS:

            // Update payload with new created agent
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        issue: action.issue,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case JOB_CREATE_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case JOB_UPDATE_REQUEST:
        case SCHEDULE_CREATE_REQUEST:
        case SCHEDULE_UPDATE_REQUEST:
        case SCHEDULE_DELETE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case JOB_UPDATE_SUCCESS:
        case SCHEDULE_CREATE_SUCCESS:
        case SCHEDULE_UPDATE_SUCCESS:
        case SCHEDULE_DELETE_SUCCESS:

            // Update payload with updated user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the agent index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            issue: action.issue,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case JOB_UPDATE_ERROR:
        case SCHEDULE_CREATE_ERROR:
        case SCHEDULE_UPDATE_ERROR:
        case SCHEDULE_DELETE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case JOB_DELETE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case JOB_DELETE_SUCCESS:

            // Update payload with deleted agent
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    isFetching: action.isFetching,
                    issue: action.issue,
                    deleted: action.deleted
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the agent index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                            isFetching: action.isFetching,
                            issue: action.issue,
                            deleted: action.deleted
                        });
                }
            }

            return newState;
        case JOB_DELETE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case JOB_DUPLICATE_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case JOB_DUPLICATE_SUCCESS:

            // Update payload with new duplicated job
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    duplicated: action.duplicated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        issue: action.issue,
                        duplicated: action.duplicated,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case JOB_DUPLICATE_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;

        case JOB_EXECUTE_REQUEST:

            // Jobs fetch request only use "list" options
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case JOB_EXECUTE_SUCCESS:

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    executed: action.executed
                }));

            return newState;
        case JOB_EXECUTE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;


        case STEP_CREATE_SUCCESS:

            // When a step is created, update job state detail with the new step
            detailUpdatedPayload = state.getIn([detail, 'payload']);
            detailUpdatedPayload = detailUpdatedPayload.updateIn(['dag', 'nodes'], arr => arr.push(fromJS({ value: { _id: action.payload._id } })))

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload
                }));

            return newState;

        case STEP_DELETE_SUCCESS:

            // When a step is deleted, update job state detail without the deleted step
            detailUpdatedPayload = state.getIn([detail, 'payload']);

            let stepIndex = detailUpdatedPayload.getIn(['dag', 'nodes']).findIndex(step => step.getIn(['value', '_id']) === action.payload._id);
            if (stepIndex >= 0) {
                detailUpdatedPayload = detailUpdatedPayload.deleteIn(['dag', 'nodes', stepIndex]);
            }

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload
                }));
            return newState;
        default:
            return state;
    }
}
