import { Map, fromJS } from 'immutable';

/*
* Signup Reducer
* */
import {
    // Signup request
    SIGNUP_REQUEST_SUCCESS, SIGNUP_REQUEST_ERROR, SIGNUP_CLEAN_ERROR,
    // Signup confirmation
    SIGNUP_CONFIRMATION_REQUEST, SIGNUP_CONFIRMATION_SUCCESS, SIGNUP_CONFIRMATION_ERROR
} from './signup-actions';

const initialState = Map({});

function signup(state = initialState, action) {
    switch (action.type) {

        // Signup Request
        case SIGNUP_REQUEST_SUCCESS:
            return state.merge({
                userEmail: action.userEmail
            });
        case SIGNUP_REQUEST_ERROR:
            return state.merge({
                errorSignup: fromJS(action.error)
            });
        case SIGNUP_CLEAN_ERROR:
            return state.merge({
                errorSignup: ''
            });

        // Signup Confirmation
        case SIGNUP_CONFIRMATION_REQUEST:
            return state.merge({
                signupIsFetching: true
            });
        case SIGNUP_CONFIRMATION_SUCCESS:
            return state.merge({
                autoLogInToken: action.autoLogInToken,
                signupIsFetching: false
            });
        case SIGNUP_CONFIRMATION_ERROR:
            return state.merge({
                errorSignup: fromJS(action.error),
                signupIsFetching: false
            });

        default:
            return state;
    }
}

export default signup;