import { PRODUCTS_DETAIL } from "../products/products-gql";
import { PRICE_LISTS_DETAIL } from "../price-lists/price-lists-gql";
import { EXTENDED_ORGANIZATION_ORDER_CREATE } from "../../system/organizations/organizations-gql";
import { PAYMENT_INTENT } from "../payments/payments-gql"

export const ORDERS_DETAIL = `
    _id
    order_number
    organization {
        _id
    }
    generated_by
    status
    created
    user_created
    updated
    user_updated
    billing_cycle_start_at
    billing_interval
    currency
    products {
        product {
            ${PRODUCTS_DETAIL}
        }
        price_list {
            ${PRICE_LISTS_DETAIL}
        }
        quantity
        price
    }
    amount
    business_name
    address {
        country
        province_name
        town_name
        postal_code
        formatted_address
    }
    business_purchase
    tax_exempt
    tax_id {
        type
        value
    }
    tax_rate
    additional_notes
    payment_method
    payment_link
    card_id
    order_additional_notes
    stripe_details {
        payment_intent_id
        status
        client_secret
        card {
            brand
            last4
        }
    }
`;

export const CREATE_ORDER = `
    order {
        ${ORDERS_DETAIL}
    }
    organization {
        ${EXTENDED_ORGANIZATION_ORDER_CREATE}
    }
    payment_intent {
        ${PAYMENT_INTENT}
    }
`;

export const INSTANCE_ADMIN_ORDER_LIST = `
    _id
    order_number
    organization {
        _id
        name
    }
    status
    created
    currency
    amount
    taxBase
    taxAmount
    payment_method
`;

export const PAYMENT_LINK_ORDER = `
    _id
    order_number
    organization
    status
    created
    currency
    amount
    payment_method
    payment_link
    card_id
    stripe_details {
        payment_intent_id
        status
        client_secret
        card {
            brand
            last4
        }
    }
    period {
        start
        end
    }
`