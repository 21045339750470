import React from 'react';
import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import History from "@biuwer/core/src/history";
import i18n from '@biuwer/core/src/i18n';
import { defaultContext } from "@biuwer/redux/src/config/constants";
import gqlRequest from '@biuwer/core/src/graphql-request';

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";
import moment from 'moment';

import { createContentFromTemplate } from '../../system/templates/templates-actions';

import { DATA_CONNECTIONS_LIST_DETAIL, DATA_CONNECTIONS_DETAIL, TEST_DATA_CONNECTION, GOOGLE_ANALYTICS_METADATA, GOOGLE_SHEETS_METADATA, GOOGLE_SHEET_METADATA, REVERSE_ENGINEERING } from './data-connections-gql'

/*
 * Datamodel action types
 */

export const DATA_CONNECTIONS_FETCH_REQUEST = 'DATA_CONNECTIONS_FETCH_REQUEST';
export const DATA_CONNECTIONS_FETCH_SUCCESS = 'DATA_CONNECTIONS_FETCH_SUCCESS';
export const DATA_CONNECTIONS_FETCH_ERROR = 'DATA_CONNECTIONS_FETCH_ERROR';

export const DATA_CONNECTIONS_INITIALIZE = 'DATA_CONNECTIONS_INITIALIZE';
export const DATA_CONNECTION_DETAIL_INITIALIZE = 'DATA_CONNECTION_DETAIL_INITIALIZE';
export const DATA_CONNECTION_LIST_INITIALIZE = 'DATA_CONNECTION_LIST_INITIALIZE';

export const REVERSE_ENGINEERING_INITIALIZE = 'REVERSE_ENGINEERING_INITIALIZE';
export const DATA_CONNECTION_TEST_INITIALIZE = 'DATA_CONNECTION_TEST_INITIALIZE';

export const DATA_CONNECTION_FETCH_REQUEST = 'DATA_CONNECTION_FETCH_REQUEST';
export const DATA_CONNECTION_FETCH_SUCCESS = 'DATA_CONNECTION_FETCH_SUCCESS';
export const DATA_CONNECTION_FETCH_ERROR = 'DATA_CONNECTION_FETCH_ERROR';

export const DATA_CONNECTION_TEST_FETCH_REQUEST = 'DATA_CONNECTION_TEST_FETCH_REQUEST';
export const DATA_CONNECTION_TEST_FETCH_SUCCESS = 'DATA_CONNECTION_TEST_FETCH_SUCCESS';
export const DATA_CONNECTION_TEST_FETCH_ERROR = 'DATA_CONNECTION_TEST_FETCH_ERROR';

export const DATA_CONNECTION_CREATE_REQUEST = 'DATA_CONNECTION_CREATE_REQUEST';
export const DATA_CONNECTION_CREATE_SUCCESS = 'DATA_CONNECTION_CREATE_SUCCESS';
export const DATA_CONNECTION_CREATE_ERROR = 'DATA_CONNECTION_CREATE_ERROR';

export const DATA_CONNECTION_UPDATE_REQUEST = 'DATA_CONNECTION_UPDATE_REQUEST';
export const DATA_CONNECTION_UPDATE_SUCCESS = 'DATA_CONNECTION_UPDATE_SUCCESS';
export const DATA_CONNECTION_UPDATE_ERROR = 'DATA_CONNECTION_UPDATE_ERROR';

export const DATA_CONNECTION_DELETE_REQUEST = 'DATA_CONNECTION_DELETE_REQUEST';
export const DATA_CONNECTION_DELETE_SUCCESS = 'DATA_CONNECTION_DELETE_SUCCESS';
export const DATA_CONNECTION_DELETE_ERROR = 'DATA_CONNECTION_DELETE_ERROR';

export const DATA_CONNECTION_DUPLICATE_REQUEST = 'DATA_CONNECTION_DUPLICATE_REQUEST';
export const DATA_CONNECTION_DUPLICATE_SUCCESS = 'DATA_CONNECTION_DUPLICATE_SUCCESS';
export const DATA_CONNECTION_DUPLICATE_ERROR = 'DATA_CONNECTION_DUPLICATE_ERROR';

export const DATA_CONNECTION_CHANGE_STATUS_REQUEST = 'DATA_CONNECTION_CHANGE_STATUS_REQUEST';
export const DATA_CONNECTION_CHANGE_STATUS_SUCCESS = 'DATA_CONNECTION_CHANGE_STATUS_SUCCESS';
export const DATA_CONNECTION_CHANGE_STATUS_ERROR = 'DATA_CONNECTION_CHANGE_STATUS_ERROR';

export const DATA_CONNECTION_REVERSE_ENGINEERING_REQUEST = 'DATA_CONNECTION_REVERSE_ENGINEERING_REQUEST';
export const DATA_CONNECTION_REVERSE_ENGINEERING_SUCCESS = 'DATA_CONNECTION_REVERSE_ENGINEERING_SUCCESS';
export const DATA_CONNECTION_REVERSE_ENGINEERING_ERROR = 'DATA_CONNECTION_REVERSE_ENGINEERING_ERROR';

export const DATA_CONNECTION_CONTAINERS_REQUEST = 'DATA_CONNECTION_CONTAINERS_REQUEST';
export const DATA_CONNECTION_CONTAINERS_SUCCESS = 'DATA_CONNECTION_CONTAINERS_SUCCESS';
export const DATA_CONNECTION_CONTAINERS_ERROR = 'DATA_CONNECTION_CONTAINERS_ERROR';

export const OAUTH_DATA_REQUEST = 'OAUTH_DATA_REQUEST';
export const OAUTH_DATA_SUCCESS = 'OAUTH_DATA_SUCCESS';
export const OAUTH_DATA_ERROR = 'OAUTH_DATA_ERROR';
export const OAUTH_DATA_INITIALIZE = 'OAUTH_DATA_INITIALIZE';

export const dataConnectionActionsContext = 'dataConnectionsActions';

const createApplicationConnectionNotification = (nextExecution) => {
    const connectionCreated = i18n.t('notifications.createSuccess', { name: i18n.t('dataConnections.connectionLabel'), context: 'female', count: 1 });
    let dataMessage, startDate;

    if (moment(nextExecution.start_date).isAfter(moment(), 'minute')) {
        const organization = Auth.getCurrentOrganization();
        const dateFormat = organization.settings && organization.settings.date_format;

        // format start date
        if (dateFormat) {
            startDate = moment(nextExecution.start_date).format(`${dateFormat} HH:mm`);
        } else {
            startDate = moment(nextExecution.start_date).format('DD/MM/YYYY HH:mm');
        }

        dataMessage = i18n.t('notifications.nextExecution', { date: startDate });
    } else {
        dataMessage = i18n.t('notifications.currentExecution');
    }

    return (
        <div key={"notification"} style={{display: "flex", flexDirection: "column"}}>
            <div key={"connection-created"} style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px"
            }}>
                <span>{connectionCreated}</span>
            </div>
            <div key={"dataMessage"} style={{
                display: "flex",
                alignItems: "center"
            }}>
                <span>{dataMessage}</span>
            </div>
        </div>
    );
};

// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, showNotification = true) => {
    let skeleton = { type, context }, notification;
    const history = History.getHistory();

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.issuePayload ? body.issuePayload.code : body.statusCode, // Backward compatibility with old error handling
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: false,
                payload: body
            };

            break;
        default:
            break;
    }

    switch(type){
        case DATA_CONNECTION_CREATE_SUCCESS:
            skeleton.created = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', { name: i18n.t('dataConnections.connectionLabel'), context: 'female', count: 1 })
            };
            // message to new application connection
            if (skeleton.payload.category && skeleton.payload.category === "applications") {
                const message = createApplicationConnectionNotification(skeleton.payload.next_execution);
                notification = {
                    styleType: 'success',
                    message: message,
                    timeout: 0
                };
            }
            if (history.location.pathname === '/data-center/connection-catalog/new') {
                setTimeout(() => {
                    const locationState = history.location.state || {};
                    history.push(locationState.prev || '/data-center/connections');
                }, 2000);
            }
            break;
        case DATA_CONNECTION_UPDATE_SUCCESS:
        case DATA_CONNECTION_CHANGE_STATUS_SUCCESS:
            skeleton.updated = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('dataConnections.connectionLabel'), context: 'female', count: 1 })
            };
            if (history.location.pathname === `/data-center/connections/${body._id}/edit`) {
                setTimeout(() => {
                    const locationState = history.location.state || {};
                    history.push(locationState.prev || `/data-center/connections`);
                }, 2000);
            }
            break;
        case DATA_CONNECTION_DELETE_SUCCESS:
            skeleton.deleted = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', { name: i18n.t('dataConnections.connectionLabel'), context: 'female', count: 1 })
            };
            break;
        case DATA_CONNECTION_DUPLICATE_SUCCESS:
            skeleton.created = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.duplicateSuccess', { name: i18n.t('dataConnections.connectionLabel'), context: 'female', count: 1 })
            };
            break;
        case DATA_CONNECTION_CREATE_ERROR:
        case DATA_CONNECTION_UPDATE_ERROR:
        case DATA_CONNECTION_DELETE_ERROR:
        case DATA_CONNECTION_DUPLICATE_ERROR:
        case DATA_CONNECTION_CHANGE_STATUS_ERROR:
        case DATA_CONNECTIONS_FETCH_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        // In this case does not show notifications
        case DATA_CONNECTION_FETCH_ERROR:
            break;
        case DATA_CONNECTION_TEST_FETCH_REQUEST:
            notification = {
                styleType: 'custom',
                style: {
                    color: 'blue'
                },
                notificationKey: 'test-connection',
                message: i18n.t('dataConnections.connectionRequest')
            };
            break;
        case DATA_CONNECTION_TEST_FETCH_SUCCESS:
            notification = {
                styleType: 'success',
                notificationKey: 'test-connection',
                message: i18n.t('dataConnections.connectionSuccess')
            };
            break;
        case DATA_CONNECTION_TEST_FETCH_ERROR:
            notification = {
                styleType: 'error',
                notificationKey: 'test-connection',
                message: body?.message ? (
                    <div>
                        <div>{i18n.t('dataConnections.connectionError')}</div>
                        <div>{body.message}</div>
                    </div>
                ) : i18n.t('dataConnections.connectionError')
            };
            break;
        case OAUTH_DATA_REQUEST:
            break;
        case OAUTH_DATA_SUCCESS:
            break;
        case OAUTH_DATA_ERROR:
            break;
        default:

            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    }
};

/*
 * Action creators
 */

/**
 * Initialize data connections
 */
export const initializeDataConnections = (context = defaultContext) => {
    return {
        type: DATA_CONNECTIONS_INITIALIZE,
        context: context
    };
};

/**
 * Initialize data connection list context
 */
export const initializeDataConnectionList = (context = defaultContext) => {
    return {
        type: DATA_CONNECTION_LIST_INITIALIZE,
        context: context
    };
};

/**
 * Initialize data connection detail context
 */

export const initializeDataConnectionDetail = (context = defaultContext) => {
    return {
        type: DATA_CONNECTION_DETAIL_INITIALIZE,
        context: context
    };
};

/**
 * Initialize Test Data Connection
 */
export const initializeTestDataConnection = (context = dataConnectionActionsContext) => {
    return {
        type: DATA_CONNECTION_TEST_INITIALIZE,
        context: context
    }
};

/**
 * Initialize Reverse Engineering
 */
export function initializeReverseEngineering(context = dataConnectionActionsContext) {
    return {
        type: REVERSE_ENGINEERING_INITIALIZE,
        context: context
    };
}

/**
 * Get data connections action
 * @param query
 * @param context
 * @param gql
 */
export function getDataConnections(query = {}, context = defaultContext, gql = DATA_CONNECTIONS_LIST_DETAIL) {

    return async (dispatch) => {
        try {

            const gqlQuery = {
                query: `query($query: QueryDataConnectionInput!) {
                    readDataConnections(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(DATA_CONNECTIONS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .set("app", Auth.getAuthApp())
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body && res.body.data && res.body.data.readDataConnections) {
                    dispatch(generateSkeleton(DATA_CONNECTIONS_FETCH_SUCCESS, res.body.data.readDataConnections, context));
                }
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(DATA_CONNECTIONS_FETCH_ERROR, res.body, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(DATA_CONNECTIONS_FETCH_ERROR, err, context));
        }
    };
}

/**
 * Get data connection action
 * @param dataConnectionId
 * @param context
 * @param gql
 */
export function getDataConnection(dataConnectionId, context = defaultContext, gql = DATA_CONNECTIONS_DETAIL) {

    return async (dispatch) => {
        try {
            dispatch(generateSkeleton(DATA_CONNECTION_FETCH_REQUEST, null, context));

            const dataConnection = await gqlRequest({
                queryName: "readDataConnection",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: dataConnectionId
                }]
            });

            dispatch(generateSkeleton(DATA_CONNECTION_FETCH_SUCCESS, dataConnection, context));
        } catch (err) {
            dispatch(generateSkeleton(DATA_CONNECTION_FETCH_ERROR, err, context));
        }
    };
}

/**
 * Test data connection action
 * @param dataConnection
 * @param context
 */
export function testDataConnection(dataConnection, context = dataConnectionActionsContext) {

    return async (dispatch) => {
        try {

            const connection = {
                name: dataConnection.name,
                type: dataConnection.type,
                provider: dataConnection.provider,
                details: dataConnection.details,
                driver: dataConnection.driver,
                data_connection_type: Number((dataConnection.data_connection_type && dataConnection.data_connection_type._id) || dataConnection.data_connection_type),
                ssh_tunnel: dataConnection.ssh_tunnel
            };

            dispatch(generateSkeleton(DATA_CONNECTION_TEST_FETCH_REQUEST, null, context));

            const testConnectionResult = await gqlRequest({
                queryName: "testDataConnection",
                queryGql: TEST_DATA_CONNECTION,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "DataConnectionExecuteActionInput!",
                    name: "dataConnection",
                    data: connection
                }]
            });

            dispatch(generateSkeleton(DATA_CONNECTION_TEST_FETCH_SUCCESS, testConnectionResult, context));
        } catch (err) {
            dispatch(generateSkeleton(DATA_CONNECTION_TEST_FETCH_ERROR, err, context));
        }
    };
}

export const initializeOauthData = (context = dataConnectionActionsContext) => {
    return {
        type: OAUTH_DATA_INITIALIZE,
        context
    };
};

export const getGoogleAnalyticsMetadata = (dataConnection, context = dataConnectionActionsContext) => {
    return async (dispatch) => {
        try {
            dispatch(generateSkeleton(OAUTH_DATA_REQUEST, null, context));

            const googleAnalyticsMetadataResult = await gqlRequest({
                queryName: "getGoogleAnalyticsMetadata",
                queryGql: GOOGLE_ANALYTICS_METADATA,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "DataConnectionExecuteActionInput!",
                    name: "dataConnection",
                    data: dataConnection
                }]
            });

            dispatch(generateSkeleton(OAUTH_DATA_SUCCESS, googleAnalyticsMetadataResult, context));
        } catch (err) {
            dispatch(generateSkeleton(OAUTH_DATA_ERROR, err, context));
        }
    };
};

export const getGoogleSheetsMetadata = (dataConnection, name = null, context = dataConnectionActionsContext) => {
    return async (dispatch) => {
        try {
            dispatch(generateSkeleton(OAUTH_DATA_REQUEST, null, context));

            const googleSheets = await gqlRequest({
                queryName: "getGoogleSheetsMetadata",
                queryGql: GOOGLE_SHEETS_METADATA,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "DataConnectionExecuteActionInput!",
                    name: "dataConnection",
                    data: dataConnection
                }, {
                    type: "String",
                    name: "name",
                    data: name
                }]
            });

            dispatch(generateSkeleton(OAUTH_DATA_SUCCESS, googleSheets, context));
        } catch (err) {
            dispatch(generateSkeleton(OAUTH_DATA_ERROR, err, context));
        }
    };
};

export const getGoogleSheetMetadata = (dataConnection, context = dataConnectionActionsContext) => {
    return async (dispatch) => {
        try {
            dispatch(generateSkeleton(OAUTH_DATA_REQUEST, null, context));

            const googleSheet = await gqlRequest({
                queryName: "getGoogleSheetMetadata",
                queryGql: GOOGLE_SHEET_METADATA,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "DataConnectionExecuteActionInput!",
                    name: "dataConnection",
                    data: dataConnection
                }]
            });

            dispatch(generateSkeleton(OAUTH_DATA_SUCCESS, googleSheet, context));
        } catch (err) {
            dispatch(generateSkeleton(OAUTH_DATA_ERROR, err, context));
        }
    };
};

export const getGoogleSheetData = (dataConnection, context = dataConnectionActionsContext) => {
    return async dispatch => {
        try {
            dispatch(generateSkeleton(OAUTH_DATA_REQUEST, null, context));

            const googleSheetData = await gqlRequest({
                queryName: "getGoogleSheetData",
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "DataConnectionExecuteActionInput!",
                    name: "dataConnection",
                    data: dataConnection
                }]
            });

            dispatch(generateSkeleton(OAUTH_DATA_SUCCESS, googleSheetData, context));
        } catch (err) {
            dispatch(generateSkeleton(OAUTH_DATA_ERROR, err, context));
        }
    };
};

export const getNotionData = (dataConnection, context = dataConnectionActionsContext) => {
    return async dispatch => {
        try {
            dispatch(generateSkeleton(OAUTH_DATA_REQUEST, null, context));

            const notionData = await gqlRequest({
                queryName: "getNotionData",
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "DataConnectionExecuteActionInput!",
                    name: "dataConnection",
                    data: dataConnection
                }]
            });

            dispatch(generateSkeleton(OAUTH_DATA_SUCCESS, notionData, context));
        } catch (err) {
            dispatch(generateSkeleton(OAUTH_DATA_ERROR, err, context));
        }
    };
};

export const getAPIRestData = (dataConnection, context = dataConnectionActionsContext) => {
    return async dispatch => {
        try {
            dispatch(generateSkeleton(OAUTH_DATA_REQUEST, null, context));

            const apiData = await gqlRequest({
                queryName: "getAPIRestData",
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "DataConnectionExecuteActionInput!",
                    name: "dataConnection",
                    data: dataConnection
                }]
            });

            dispatch(generateSkeleton(OAUTH_DATA_SUCCESS, apiData, context));
        } catch (err) {
            dispatch(generateSkeleton(OAUTH_DATA_ERROR, err, context));
        }
    };
};

/**
 * Create data connection action
 * @param dataConnection
 * @param context
 * @param gql
 */
export function createDataConnection(dataConnection, context = defaultContext, gql = DATA_CONNECTIONS_DETAIL) {

    return async (dispatch) => {
        try {
            dispatch(generateSkeleton(DATA_CONNECTION_CREATE_REQUEST, null, context));

            let contentTemplateId;
            if (dataConnection.content_template) {
                contentTemplateId = dataConnection.content_template;
                delete(dataConnection.content_template);
            }

            const createdDataConnection = await gqlRequest({
                queryName: "createDataConnection",
                queryType: "mutation",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "DataConnectionInput!",
                    name: "dataConnection",
                    data: dataConnection
                }]
            });

            dispatch(generateSkeleton(DATA_CONNECTION_CREATE_SUCCESS, createdDataConnection, context));
            contentTemplateId && dispatch(createContentFromTemplate(contentTemplateId));
        } catch (err) {
            dispatch(generateSkeleton(DATA_CONNECTION_CREATE_ERROR, err, context));
        }
    };
}

/**
 * Update data connection action
 * @param dataConnection
 * @param context
 * @param gql
 */
export function updateDataConnection(dataConnection, context = defaultContext, gql = DATA_CONNECTIONS_DETAIL) {

    return async (dispatch) => {
        try {
            const dataConnectionId = dataConnection._id;
            const connection = {
                schedule_for_managed_datasets: dataConnection.schedule_for_managed_datasets,
                schedule: dataConnection.schedule,
                data_connection_type: dataConnection.data_connection_type,
                details: dataConnection.details,
                name: dataConnection.name,
                desc: dataConnection.desc,
                is_source: dataConnection.is_source,
                is_destination: dataConnection.is_destination,
                type: dataConnection.type,
                driver: dataConnection.driver,
                provider: dataConnection.provider,
                ssh_tunnel: dataConnection.ssh_tunnel,
                time_zone: dataConnection.time_zone
            };

            dispatch(generateSkeleton(DATA_CONNECTION_UPDATE_REQUEST, null, context));

            const updatedDataConnection = await gqlRequest({
                queryName: "updateDataConnection",
                queryType: "mutation",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: dataConnectionId
                }, {
                    type: "DataConnectionInput!",
                    name: "dataConnection",
                    data: connection
                }]
            });

            dispatch(generateSkeleton(DATA_CONNECTION_UPDATE_SUCCESS, updatedDataConnection, context, true));
        } catch (err) {
            dispatch(generateSkeleton(DATA_CONNECTION_UPDATE_ERROR, err, context));
        }
    };
}

/**
 * Delete data connection action
 * @param dataConnectionId
 * @param context
 * @param gql
 */
export function deleteConnection(dataConnectionId, context = defaultContext, gql = DATA_CONNECTIONS_DETAIL) {

    return async (dispatch) => {

        try {
            dispatch(generateSkeleton(DATA_CONNECTION_DELETE_REQUEST));

            const deletedDataConnection = await gqlRequest({
                queryType: "mutation",
                queryName: "deleteDataConnection",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: dataConnectionId
                }]
            });

            dispatch(generateSkeleton(DATA_CONNECTION_DELETE_SUCCESS, deletedDataConnection, context));
        } catch (err) {
            dispatch(generateSkeleton(DATA_CONNECTION_DELETE_ERROR, err, context));
        }
    };
}

/**
 * Delete data connection action
 * @param dataConnectionId
 * @param context
 * @param gql
 */
export function duplicateDataConnection(dataConnectionId, context = defaultContext, gql = DATA_CONNECTIONS_DETAIL) {

    return async (dispatch) => {

        try {
            dispatch(generateSkeleton(DATA_CONNECTION_DUPLICATE_REQUEST));

            const duplicatedDataConnection = await gqlRequest({
                queryType: "mutation",
                queryName: "duplicateDataConnection",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: dataConnectionId
                }]
            });

            dispatch(generateSkeleton(DATA_CONNECTION_DUPLICATE_SUCCESS, duplicatedDataConnection, context));
        } catch (err) {
            dispatch(generateSkeleton(DATA_CONNECTION_DUPLICATE_ERROR, err, context));
        }
    };
}

export const changeConnectionStatus = (dataConnectionId, status, context = defaultContext, gql = DATA_CONNECTIONS_DETAIL) => {
    return async (dispatch) => {

        try {
            dispatch(generateSkeleton(DATA_CONNECTION_CHANGE_STATUS_REQUEST));

            const updatedDataConnection = await gqlRequest({
                queryType: "mutation",
                queryName: "changeDataConnectionStatus",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: dataConnectionId
                }, {
                    type: "String!",
                    name: "status",
                    data: status
                }]
            });

            dispatch(generateSkeleton(DATA_CONNECTION_CHANGE_STATUS_SUCCESS, updatedDataConnection, context));
        } catch (err) {
            dispatch(generateSkeleton(DATA_CONNECTION_CHANGE_STATUS_ERROR, err, context));
        }
    };
};

/**
 * Get reverse engineering for a data connection
 * @param dataConnectionId
 * @param objectMetadata
 * @param context
 */
export function getReverseEngineering(dataConnectionId, objectMetadata = {}, context = dataConnectionActionsContext) {
    return async (dispatch) => {
        try {
            dispatch(generateSkeleton(DATA_CONNECTION_REVERSE_ENGINEERING_REQUEST, null, context));

            const reverseEngineeringData = await gqlRequest({
                queryName: "reverseEngineering",
                queryGql: REVERSE_ENGINEERING,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: dataConnectionId
                }, {
                    type: "JSONObject",
                    name: "objectMetaData",
                    data: objectMetadata
                }]
            });

            dispatch(generateSkeleton(DATA_CONNECTION_REVERSE_ENGINEERING_SUCCESS, reverseEngineeringData, context));
        } catch (err) {
            dispatch(generateSkeleton(DATA_CONNECTION_REVERSE_ENGINEERING_ERROR, err, context));
        }
    };
}

/**
 * Get containers for a data connection
 * @param dataConnectionId
 * @param context
 */
export function getContainers(dataConnectionId, context = dataConnectionActionsContext) {
    return async (dispatch) => {
        try {

            dispatch(generateSkeleton(DATA_CONNECTION_CONTAINERS_REQUEST, null, context));

            const containers = await gqlRequest({
                queryName: "getContainers",
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: dataConnectionId
                }]
            });

            dispatch(generateSkeleton(DATA_CONNECTION_CONTAINERS_SUCCESS, containers, context));
        } catch (err) {
            dispatch(generateSkeleton(DATA_CONNECTION_CONTAINERS_ERROR, err, context));
        }
    };
}

export function getFacebookAdAccounts(dataConnection, context = dataConnectionActionsContext) {
    return async (dispatch) => {

        try {
            dispatch(generateSkeleton(OAUTH_DATA_REQUEST, null, context));

            const facebookAdAccounts = await gqlRequest({
                queryName: "getFacebookAdAccounts",
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "DataConnectionExecuteActionInput!",
                    name: "dataConnection",
                    data: dataConnection
                }]
            });

            dispatch(generateSkeleton(OAUTH_DATA_SUCCESS, facebookAdAccounts, context));
        } catch (err) {
            dispatch(generateSkeleton(OAUTH_DATA_ERROR, err, context));
        }
    };
}