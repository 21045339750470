import { Map, fromJS } from 'immutable';

/**
 * System Roles Reducer
 */

import {
    ROLES_FETCH_REQUEST, ROLES_FETCH_SUCCESS, ROLES_FETCH_ERROR,
    ROLES_INITIALIZE, ROLE_DETAIL_INITIALIZE, ROLES_LIST_INITIALIZE,
    ROLE_FETCH_REQUEST, ROLE_FETCH_SUCCESS, ROLE_FETCH_ERROR,
    ROLE_UPDATE_REQUEST, ROLE_UPDATE_SUCCESS, ROLE_UPDATE_ERROR,
    ROLE_CHANGE_STATUS_REQUEST, ROLE_CHANGE_STATUS_SUCCESS, ROLE_CHANGE_STATUS_ERROR,
    ROLE_CREATE_REQUEST, ROLE_CREATE_SUCCESS, ROLE_CREATE_ERROR,
    ROLE_DELETE_REQUEST, ROLE_DELETE_SUCCESS, ROLE_DELETE_ERROR,
    ROLE_ADD_USERS_REQUEST, ROLE_ADD_USERS_SUCCESS, ROLE_ADD_USERS_ERROR,
    ROLE_DELETE_USERS_REQUEST, ROLE_DELETE_USERS_SUCCESS, ROLE_DELETE_USERS_ERROR,
    ROLE_ADD_GROUPS_REQUEST, ROLE_ADD_GROUPS_SUCCESS, ROLE_ADD_GROUPS_ERROR,
    ROLE_DELETE_GROUPS_REQUEST, ROLE_DELETE_GROUPS_SUCCESS, ROLE_DELETE_GROUPS_ERROR
} from './roles-actions.js';

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

const roles = (state = initialState, action) => {
    let newState, index = -1, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || `primary`}`;
    const detail = `detail_${(action && action.context) || `primary`}`;

    switch (action.type) {

        // Necessary to initialize roles state prior to create a new roles
        case ROLES_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));
            return newState;
        case ROLES_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;
        case ROLE_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;
        case ROLES_FETCH_REQUEST:

            newState = state;

            // Roles fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case ROLE_FETCH_REQUEST:

            newState = state;

            // Role fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case ROLES_FETCH_SUCCESS:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case ROLE_FETCH_SUCCESS:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case ROLES_FETCH_ERROR:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;
        case ROLE_FETCH_ERROR:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage,
                issue: action.issue,
                issuePayload: fromJS(action.issuePayload),
            }));
            return newState;
        case ROLE_CREATE_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case ROLE_CREATE_SUCCESS:

            // Update payload with new created role
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case ROLE_CREATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;
        case ROLE_UPDATE_REQUEST:
        case ROLE_CHANGE_STATUS_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case ROLE_UPDATE_SUCCESS:
        case ROLE_CHANGE_STATUS_SUCCESS:

            // Update payload with updated role
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the role index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            error: action.error,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case ROLE_UPDATE_ERROR:
        case ROLE_CHANGE_STATUS_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;

        case ROLE_DELETE_REQUEST:
            newState = state
                .mergeIn([detail], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;
        case ROLE_DELETE_SUCCESS:

            // Update payload with deleted role
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        deleted: action.deleted
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the role index to be deleted inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                            delete: Map({
                                isFetching: action.isFetching,
                                error: action.error,
                                deleted: action.deleted
                            })
                        });
                }
            }
            return newState;
        case ROLE_DELETE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;
        case ROLE_ADD_USERS_REQUEST:

            newState = state;

            if (state.getIn([detail, 'addUsersToRole'])) newState = newState.removeIn([detail, 'addUsersToRole']);
            if (state.getIn([list, 'addUsersToRole'])) newState = newState.removeIn([list, 'addUsersToRole']);

            newState = newState
                .mergeIn([detail], Map({
                    addUsersToRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    addUsersToRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;
        case ROLE_ADD_USERS_SUCCESS:

            // Add users
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    addUsersToRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the role index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            payload: listUpdatedPayload,
                            addRolesToUser: Map({
                                isFetching: action.isFetching,
                                error: action.error
                            })
                        }));
                }
            }

            return newState;
        case ROLE_ADD_USERS_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    addUsersToRole: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    addUsersToRole: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;
        case ROLE_DELETE_USERS_REQUEST:

            newState = state;

            if (state.getIn([detail, 'deleteUsersFromRole'])) newState = newState.removeIn([detail, 'deleteUsersFromRole']);
            if (state.getIn([list, 'deleteUsersFromRole'])) newState = newState.removeIn([list, 'deleteUsersFromRole']);

            newState = newState
                .mergeIn([detail], Map({
                    deleteUsersFromRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    deleteUsersFromRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;
        case ROLE_DELETE_USERS_SUCCESS:

            // Delete users
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    deleteUsersFromRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));
                listUpdatedPayload = listUpdatedPayload.sortBy( item => item.get('created') );

                newState = newState
                    .mergeIn([list], Map({
                        payload: listUpdatedPayload,
                        deleteUsersFromRole: Map({
                            isFetching: action.isFetching,
                            error: action.error
                        })
                    }));
            }

            return newState;
        case ROLE_DELETE_USERS_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    deleteUsersFromRole: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    deleteUsersFromRole: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;
        case ROLE_ADD_GROUPS_REQUEST:

            newState = state;

            if (state.getIn([detail, 'addGroupsToRole'])) newState = newState.removeIn([detail, 'addGroupsToRole']);
            if (state.getIn([list, 'addGroupsToRole'])) newState = newState.removeIn([list, 'addGroupsToRole']);

            newState = newState
                .mergeIn([detail], Map({
                    addGroupsToRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    addGroupsToRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;
        case ROLE_ADD_GROUPS_SUCCESS:

            // Add groups
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    addGroupsToRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));
                listUpdatedPayload = listUpdatedPayload.sortBy( item => item.get('created') );

                newState = newState
                    .mergeIn([list], Map({
                        payload: listUpdatedPayload,
                        addGroupsToRole: Map({
                            isFetching: action.isFetching,
                            error: action.error
                        })
                    }));
            }

            return newState;
        case ROLE_ADD_GROUPS_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    addGroupsToRole: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    addGroupsToRole: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;
        case ROLE_DELETE_GROUPS_REQUEST:

            newState = state;

            if (state.getIn([detail, 'deleteGroupsFromRole'])) newState = newState.removeIn([detail, 'deleteGroupsFromRole']);
            if (state.getIn([list, 'deleteGroupsFromRole'])) newState = newState.removeIn([list, 'deleteGroupsFromRole']);

            newState = newState
                .mergeIn([detail], Map({
                    deleteGroupsFromRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    deleteGroupsFromRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;
        case ROLE_DELETE_GROUPS_SUCCESS:

            // Delete groups
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    deleteGroupsFromRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));
                listUpdatedPayload = listUpdatedPayload.sortBy( item => item.get('created') );

                newState = newState
                    .mergeIn([list], Map({
                        payload: listUpdatedPayload,
                        deleteGroupsFromRole: Map({
                            isFetching: action.isFetching,
                            error: action.error
                        })
                    }));
            }

            return newState;
        case ROLE_DELETE_GROUPS_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    deleteGroupsFromRole: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    deleteGroupsFromRole: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;
        default:
            return state;
    }
};

export default roles;
