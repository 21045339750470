import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import History from "@biuwer/core/src/history";
import i18n from '@biuwer/core/src/i18n';

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { USERS_ROLES_GROUP_DETAIL } from './groups-gql';
import { defaultContext } from "@biuwer/redux/src/config/constants";

/*
 * System Groups action types
 */

export const GROUPS_FETCH_REQUEST = 'GROUPS_FETCH_REQUEST';
export const GROUPS_FETCH_SUCCESS = 'GROUPS_FETCH_SUCCESS';
export const GROUPS_FETCH_ERROR = 'GROUPS_FETCH_ERROR';

export const GROUPS_INITIALIZE = 'GROUPS_INITIALIZE';
export const GROUP_DETAIL_INITIALIZE = 'GROUP_DETAIL_INITIALIZE';
export const GROUPS_LIST_INITIALIZE = 'GROUPS_LIST_INITIALIZE';

export const GROUP_FETCH_REQUEST = 'GROUP_FETCH_REQUEST';
export const GROUP_FETCH_SUCCESS = 'GROUP_FETCH_SUCCESS';
export const GROUP_FETCH_ERROR = 'GROUP_FETCH_ERROR';

export const GROUP_UPDATE_REQUEST = 'GROUP_UPDATE_REQUEST';
export const GROUP_UPDATE_SUCCESS = 'GROUP_UPDATE_SUCCESS';
export const GROUP_UPDATE_ERROR = 'GROUP_UPDATE_ERROR';

export const GROUP_CHANGE_STATUS_REQUEST = 'GROUP_CHANGE_STATUS_REQUEST';
export const GROUP_CHANGE_STATUS_SUCCESS = 'GROUP_CHANGE_STATUS_SUCCESS';
export const GROUP_CHANGE_STATUS_ERROR = 'GROUP_CHANGE_STATUS_ERROR';

export const GROUP_CREATE_REQUEST = 'GROUP_CREATE_REQUEST';
export const GROUP_CREATE_SUCCESS = 'GROUP_CREATE_SUCCESS';
export const GROUP_CREATE_ERROR = 'GROUP_CREATE_ERROR';

export const GROUP_DELETE_REQUEST = 'GROUP_DELETE_REQUEST';
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS';
export const GROUP_DELETE_ERROR = 'GROUP_DELETE_ERROR';

export const GROUP_ADD_USERS_REQUEST = 'GROUP_ADD_USERS_REQUEST';
export const GROUP_ADD_USERS_SUCCESS = 'GROUP_ADD_USERS_SUCCESS';
export const GROUP_ADD_USERS_ERROR = 'GROUP_ADD_USERS_ERROR';

export const GROUP_DELETE_USERS_REQUEST = 'GROUP_DELETE_USERS_REQUEST';
export const GROUP_DELETE_USERS_SUCCESS = 'GROUP_DELETE_USERS_SUCCESS';
export const GROUP_DELETE_USERS_ERROR = 'GROUP_DELETE_USERS_ERROR';

export const GROUP_ADD_ROLES_REQUEST = 'GROUP_ADD_ROLES_REQUEST';
export const GROUP_ADD_ROLES_SUCCESS = 'GROUP_ADD_ROLES_SUCCESS';
export const GROUP_ADD_ROLES_ERROR = 'GROUP_ADD_ROLES_ERROR';

export const GROUP_DELETE_ROLES_REQUEST = 'GROUP_DELETE_ROLES_REQUEST';
export const GROUP_DELETE_ROLES_SUCCESS = 'GROUP_DELETE_ROLES_SUCCESS';
export const GROUP_DELETE_ROLES_ERROR = 'GROUP_DELETE_ROLES_ERROR';

// Generic Skeleton for every action
const generateSkeleton = (type, body, context = defaultContext, showNotification = true) => {
    let skeleton = { type }, notification;
    const history = History.getHistory();

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false,
                context: context
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                context: context,
                issuePayload: {
                    status: body.code,
                    code: body.statusCode,
                    message: body.message
                },
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body,
                context: context
            };
            break;
        default:
            break;
    }
    switch (type) {
        case GROUP_CREATE_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', {
                    name: i18n.t('admin.groups.title'),
                    context: 'male',
                    count: 1
                })
            };
            if (history.location.pathname === '/admin/groups/new') {
                setTimeout(() => {
                    const locationState = history.location.state || {};
                    history.push(locationState.prev || '/admin/groups');
                }, 2000);
            }
            break;
        case GROUP_UPDATE_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', {
                    name: i18n.t('admin.groups.title'),
                    context: 'male',
                    count: 1
                })
            };
            if (history.location.pathname === `/admin/groups/${body._id}/edit`) {
                setTimeout(() => {
                    const locationState = history.location.state || {};
                    history.push(locationState.prev || `/admin/groups/${body._id}`);
                }, 2000);
            }
            break;
        case GROUP_DELETE_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', {
                    name: i18n.t('admin.groups.title'),
                    context: 'male',
                    count: 1
                })
            };
            if (history.location.pathname === `/admin/groups/${body._id}`) {
                const locationState = history.location.state || {};
                history.push(locationState.prev || `/admin/groups`);
            }
            break;
        case GROUP_ADD_ROLES_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.addSuccess', {
                    name: i18n.t('admin.roles.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case GROUP_DELETE_ROLES_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.unassignSuccess', {
                    name: i18n.t('admin.roles.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case GROUP_ADD_USERS_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.addSuccess', {
                    name: i18n.t('admin.users.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case GROUP_DELETE_USERS_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.unassignSuccess', {
                    name: i18n.t('admin.users.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case GROUP_CHANGE_STATUS_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', {
                    name: i18n.t('common.statusLabel'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case GROUP_CREATE_ERROR:
        case GROUP_UPDATE_ERROR:
        case GROUP_CHANGE_STATUS_ERROR:
        case GROUPS_FETCH_ERROR:
        case GROUP_DELETE_ERROR:
        case GROUP_ADD_USERS_ERROR:
        case GROUP_DELETE_USERS_ERROR:
        case GROUP_ADD_ROLES_ERROR:
        case GROUP_DELETE_ROLES_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    }
};

/**
 * System Groups: Initialize groups action
 */
export function initializeGroups() {
    return {
        type: GROUPS_INITIALIZE
    };
}


/**
 * System Groups: Initialize user detail action
 */
export function initializeGroupDetail() {
    return {
        type: GROUP_DETAIL_INITIALIZE
    };
}

/**
 * System Groups: Initialize users list action
 */
export function initializeGroupsList() {
    return {
        type: GROUPS_LIST_INITIALIZE
    };
}

/**
 * System Groups: Get Groups List for the requester's organization
 * @param query
 * @param context
 * @param gql
 */
export function getGroupsList(query = {}, context = defaultContext, gql = USERS_ROLES_GROUP_DETAIL) {
    return async dispatch => {

        try {
            const gqlQuery = {
                query: `query($query: QueryGroupInput!) {
                    readGroups(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(GROUPS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(GROUPS_FETCH_ERROR, 'Error getting System Groups data from server', context));
                } else if (res.body && res.body.data && res.body.data.readGroups) {
                    dispatch(generateSkeleton(GROUPS_FETCH_SUCCESS, res.body.data.readGroups, context));
                }
            }
        } catch (error) {
            dispatch(generateSkeleton(GROUPS_FETCH_ERROR, error, context));
        }
    };
}

/**
 * Get Groups List for the requester's organization with minimal data
 * @param context
 * @param gql
 */
export function listGroups(context = defaultContext, gql = USERS_ROLES_GROUP_DETAIL) {
    return async dispatch => {
        try {

            const query = {
                query: `query {
                    listGroups {
                        ${gql}
                    }
                }`
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(GROUPS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(GROUPS_FETCH_ERROR, 'Error getting System Groups data from server', context));
                } else if (res.body && res.body.data && res.body.data.listGroups) {
                    dispatch(generateSkeleton(GROUPS_FETCH_SUCCESS, res.body.data.listGroups, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(GROUPS_FETCH_ERROR, err, context));
        }
    };
}

/**
 * System Groups: Create groups action
 * @param groupObject
 * @param context
 * @param gql
 */
export function createGroup(groupObject, context = defaultContext, gql = USERS_ROLES_GROUP_DETAIL) {
    return async dispatch => {

        try {
            const query = {
                query: `mutation($groupObject: NewGroupInput!) {
                    createGroup(group: $groupObject) {
                        ${gql}
                    }
                }`,
                variables: {
                    groupObject
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(GROUP_CREATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(GROUP_CREATE_ERROR, res.body.errors[0], context));
                } else if(res.body && res.body.data && res.body.data.createGroup) {

                    dispatch(generateSkeleton(GROUP_CREATE_SUCCESS, res.body.data.createGroup, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(GROUP_CREATE_ERROR, err, context));
        }
    };
}

/**
 * System Groups: Get groups action
 * @param groupId
 * @param context
 * @param gql
 */
export function getGroup(groupId, context = defaultContext, gql = USERS_ROLES_GROUP_DETAIL) {
    return async dispatch => {

        try {
            const query = {
                query: `query($groupId: Float!) {
                    readGroup(_id: $groupId) {
                        ${gql}
                    }
                }`,
                variables: {
                    groupId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(GROUP_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(GROUP_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readGroup) {
                    dispatch(generateSkeleton(GROUP_FETCH_SUCCESS, res.body.data.readGroup, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(GROUP_FETCH_ERROR, err, context));
        }
    };
}

/**
 * System Groups: Update groups action
 * @param group
 * @param showNotification
 * @param context
 * @param gql
 */
export function updateGroup(group, showNotification = true, context = defaultContext, gql = USERS_ROLES_GROUP_DETAIL) {
    return async dispatch => {

        try {
            let groupId = group._id;
            delete(group._id);
            delete(group.type);

            const query = {
                query: `mutation($groupId: Float!, $group: UpdateGroupInput!) {
                    updateGroup(_id: $groupId, group: $group) {
                        ${gql}
                    }
                }`,
                variables: {
                    groupId,
                    group
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(GROUP_UPDATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {

                    dispatch(generateSkeleton(GROUP_UPDATE_ERROR, 'Error updating System Group', context));
                } else if (res.body && res.body.data && res.body.data.updateGroup) {

                    dispatch(generateSkeleton(GROUP_UPDATE_SUCCESS, res.body.data.updateGroup, context, showNotification));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(GROUP_UPDATE_ERROR, e, context));
        }
    };
}

/**
 * System Groups: Change groups status action
 * @param groupId
 * @param status
 * @param context
 * @param gql
 */
export function changeGroupStatus(groupId, status, context = defaultContext, gql = USERS_ROLES_GROUP_DETAIL) {
    return async dispatch => {

        try {
            const query = {
                query: `mutation($groupId: Float!, $status: String!) {
                    changeGroupStatus(_id: $groupId, status: $status) {
                        ${gql}
                    }
                }`,
                variables: {
                    groupId,
                    status
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(GROUP_CHANGE_STATUS_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {

                    dispatch(generateSkeleton(GROUP_CHANGE_STATUS_ERROR, 'Error on changing Group status from server', context));
                } else if (res.body && res.body.data && res.body.data.changeGroupStatus) {

                    dispatch(generateSkeleton(GROUP_CHANGE_STATUS_SUCCESS, res.body.data.changeGroupStatus, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(GROUP_CHANGE_STATUS_ERROR, e, context));
        }
    };
}

/**
 * System Groups: Delete groups action
 * @param groupId
 * @param context
 * @param gql
 */
export function deleteGroup(groupId, context = defaultContext, gql = USERS_ROLES_GROUP_DETAIL) {
    return async dispatch => {

        try {
            const query = {
                query: `mutation($groupId: Float!) {
                    deleteGroup(_id: $groupId) {
                        ${gql}
                    }
                }`,
                variables: {
                    groupId,
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(GROUP_DELETE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {

                    dispatch(generateSkeleton(GROUP_DELETE_ERROR, 'Error deleting System Group', context));
                } else if (res.body && res.body.data && res.body.data.deleteGroup) {

                    dispatch(generateSkeleton(GROUP_DELETE_SUCCESS, res.body.data.deleteGroup, context));
                }

            }
        } catch (e) {
            dispatch(generateSkeleton(GROUP_DELETE_ERROR, e, context));
        }
    };
}

/**
 * System Roles: Add users to groups action
 * @param groupId
 * @param users
 * @param context
 * @param gql
 */
export function addUsersToGroup(groupId, users, context = defaultContext, gql = USERS_ROLES_GROUP_DETAIL) {
    return async dispatch => {

        try {
            const query = {
                query: `mutation($groupId: Float!, $users: [Float!]!) {
                    addUsersToGroup(_id: $groupId, users: $users) {
                        ${gql}
                    }
                }`,
                variables: {
                    groupId,
                    users
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(GROUP_ADD_USERS_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(GROUP_ADD_USERS_ERROR, 'Error adding users to Group', context));
                } else if (res.body && res.body.data && res.body.data.addUsersToGroup) {
                    dispatch(generateSkeleton(GROUP_ADD_USERS_SUCCESS, res.body.data.addUsersToGroup, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(GROUP_ADD_USERS_ERROR, e, context));
        }
    };
}

/**
 * System Groups: Delete users from group action
 * @param groupId
 * @param users
 * @param context
 * @param gql
 */
export function deleteUsersFromGroup(groupId, users, context = defaultContext, gql = USERS_ROLES_GROUP_DETAIL) {
    return async dispatch => {

        try {
            const query = {
                query: `mutation($groupId: Float!, $users: [Float!]!) {
                    deleteUsersFromGroup(_id: $groupId, users: $users) {
                        ${gql}
                    }
                }`,
                variables: {
                    groupId,
                    users
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(GROUP_DELETE_USERS_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(GROUP_DELETE_USERS_ERROR, 'Error deleting users from Group', context));
                } else if (res.body && res.body.data && res.body.data.deleteUsersFromGroup) {
                    dispatch(generateSkeleton(GROUP_DELETE_USERS_SUCCESS, res.body.data.deleteUsersFromGroup, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(GROUP_DELETE_USERS_ERROR, e, context));
        }
    };
}

/**
 * System Roles: Add roles to groups action
 * @param groupId
 * @param roles
 * @param context
 * @param gql
 */
export function addRolesToGroup(groupId, roles, context = defaultContext, gql = USERS_ROLES_GROUP_DETAIL) {
    return async dispatch => {

        try {

            const query = {
                query: `mutation($groupId: Float!, $roles: [Float!]!) {
                    addGroupToRoles(_id: $groupId, roles: $roles) {
                        ${gql}
                    }
                }`,
                variables: {
                    groupId,
                    roles
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(GROUP_ADD_ROLES_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(GROUP_ADD_ROLES_ERROR, 'Error adding roles to Group', context));
                } else if (res.body && res.body.data && res.body.data.addGroupToRoles) {
                    dispatch(generateSkeleton(GROUP_ADD_ROLES_SUCCESS, res.body.data.addGroupToRoles, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(GROUP_ADD_ROLES_ERROR, e, context));
        }
    };
}

/**
 * System Groups: Delete roles from group action
 * @param groupId
 * @param roles
 * @param context
 * @param gql
 */
export function deleteRolesFromGroup(groupId, roles, context = defaultContext, gql = USERS_ROLES_GROUP_DETAIL) {
    return async dispatch => {

        try {
            const query = {
                query: `mutation($groupId: Float!, $roles: [Float!]!) {
                    deleteGroupFromRoles(_id: $groupId, roles: $roles) {
                        ${gql}
                    }
                }`,
                variables: {
                    groupId,
                    roles
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(GROUP_DELETE_ROLES_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(GROUP_DELETE_ROLES_ERROR, 'Error deleting roles from Group', context));
                } else if (res.body && res.body.data && res.body.data.deleteGroupFromRoles) {
                    dispatch(generateSkeleton(GROUP_DELETE_ROLES_SUCCESS, res.body.data.deleteGroupFromRoles, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(GROUP_DELETE_ROLES_ERROR, e, context));
        }
    };
}