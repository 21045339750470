import { PRICE_LISTS_DETAIL } from "@biuwer/redux/src/billing/price-lists/price-lists-gql";

export const ORGANIZATION_PRIMARY_CONTACT_FULL = `
    _id
    full_name
    name
    surnames
    email
    phone
`;

export const ORGANIZATION_PRIMARY_CONTACT_MINIMAL = `
    full_name
    email
`;

export const ORGANIZATION_TRIAL = `
    status
    start_at
    end_at
`;

export const ORGANIZATION_BILLING_CYCLE_DISCOUNT = `
    _id
    start
    end
    promo_code {
        code
        coupon {
            amount_off
            percent_off
            duration
            num_repetitions
            interval
            desc_i18n {
                lang
                value
            }
        }
    }
    organization
`;

export const ORGANIZATION_CURRENT_SUBSCRIPTION = `
    started_at
    products {
        product {
            _id
            name
            features {
                feature
                has_quantity
                quantity
            }
            i18n {
                language
                name
            }
            product_type
            related_products {
                product_id
                quantity
            }
        }
        price_list {
                ${PRICE_LISTS_DETAIL}
        }
        quantity
        price
    }
`;

export const ORGANIZATIONS_CUSTOM_STYLES = `
    enabled
    font
    filigree_color
    primary_button {
        color
        text {
            fontFamily
            fontSize
            fontWeight
            fontStyle
            textAlign
            textDecoration
            color
        }
    }
    secondary_button {
        color
        text {
            fontFamily
            fontSize
            fontWeight
            fontStyle
            textAlign
            textDecoration
            color
        }
    }
    header {
        title {
            fontFamily
            fontSize
            fontWeight
            fontStyle
            textAlign
            textDecoration
            color
        }
        desc {
            fontFamily
            fontSize
            fontWeight
            fontStyle
            textAlign
            textDecoration
            color
        }
    }
    cards {
        title {
            fontFamily
            fontSize
            fontWeight
            fontStyle
            textAlign
            textDecoration
            color
        }
        content {
            fontFamily
            fontSize
            fontWeight
            fontStyle
            textAlign
            textDecoration
            color
        }
    }
    collections {
        tabs {
            fontFamily
            fontSize
            fontWeight
            fontStyle
            textAlign
            textDecoration
            color
        }
        tabs_background_color
        active_tab_color
    }
`;

// Default query for organizations actions
export const ORGANIZATION_DETAIL = `
    _id
    name
    status
    created
    updated
`;

// Minimal query for organizations list
export const BASIC_ORGANIZATION_DETAIL = `
    _id
    name
    status
`;

// Query for organization detail and overview pages
export const EXTENDED_ORGANIZATION_DETAIL = `
    _id
    name
    status
    created
    updated
    settings {
        language
        default_theme
    }
    primary_contact {
        ${ORGANIZATION_PRIMARY_CONTACT_FULL}
    }
    trial {
        ${ORGANIZATION_TRIAL}
    }
    billing {
        business_name
        business_purchase
        charges_approved
        charges_approved_at
        billing_start_at
        billing_cycle_start_at
        current_cycle_discount {
            ${ORGANIZATION_BILLING_CYCLE_DISCOUNT}
        }
        next_cycle_discount {
            ${ORGANIZATION_BILLING_CYCLE_DISCOUNT}
        }
        starting_payment_date
        currency
        billing_interval
        current_subscription {
            ${ORGANIZATION_CURRENT_SUBSCRIPTION}
        }
        billing_contacts {
            type
            user_id
            name
            email
        }
        address {
            country
            formatted_address
            province_name
            postal_code
            town_name
        }
        tax_id {
            value
            validation {
                status
            }
        }
        tax_rate
        additional_notes
        balance
        default_payment_method
        default_card_id
        payment_cards {
            stripe_card_id
            brand
            country
            exp_month
            exp_year
            last4
        }
    }
    automatic_billing
    apply_feature_wall
`;

// Query for create orders
export const EXTENDED_ORGANIZATION_ORDER_CREATE = `
    _id
    name
    status
    accumulated_features {
        feature
        has_quantity
        quantity
    }
    created
    updated
    settings {
        language
    }
    primary_contact {
        ${ORGANIZATION_PRIMARY_CONTACT_FULL}
    }
    trial {
        ${ORGANIZATION_TRIAL}
    }
    billing {
        business_name
        business_purchase
        charges_approved
        charges_approved_at
        billing_start_at
        billing_cycle_start_at
        current_cycle_discount {
            ${ORGANIZATION_BILLING_CYCLE_DISCOUNT}
        }
        next_cycle_discount {
            ${ORGANIZATION_BILLING_CYCLE_DISCOUNT}
        }
        starting_payment_date
        currency
        billing_interval
        current_subscription {
            ${ORGANIZATION_CURRENT_SUBSCRIPTION}
        }
        billing_contacts {
            type
            user_id
            name
            email
        }
        address {
            country
            formatted_address
            province_name
            postal_code
            town_name
        }
        tax_id {
            value
            validation {
                status
            }
        }
        tax_rate
        additional_notes
        balance
        default_payment_method
        default_card_id
        payment_cards {
            stripe_card_id
            brand
            country
            exp_month
            exp_year
            last4
        }
    }
    automatic_billing
    apply_feature_wall
`;

// Query for instance-admin organizations page
export const INSTANCE_ADMIN_ORGANIZATIONS_PAGE = `
    _id
    name
    status
    created
    updated
    primary_contact {
        ${ORGANIZATION_PRIMARY_CONTACT_MINIMAL}
    }
    trial {
        ${ORGANIZATION_TRIAL}
    }
    settings {
        language
    }
    automatic_billing
    apply_feature_wall
`;

// Query for regional settings page
export const GENERAL_SETTINGS_PAGE = `
    _id
    name
    status
    settings {
        language
        default_theme
        number_format {
            decimal
            thousands
        }
        custom_styles {
            ${ORGANIZATIONS_CUSTOM_STYLES}
        }
        date_format
        time_zone
        currency
        show_intro_content
        show_demo_space
        multi_language {
            enabled
            use_browser_language
            languages {
                language
                number_format {
                    decimal
                    thousands
                }
                date_format
                time_zone
                currency
            }
        }
    }
`;

// Query for instance admin organization
export const ORGANIZATION_INSTANCE_ADMIN = `
    _id
    name
    status
    automatic_billing
    apply_feature_wall
`;