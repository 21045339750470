export const CUSTOM_MESSAGE_DETAIL = `
    no_data {
        status
        text
        format
        icon {
            icon
            position
            size
            color
        }
    }
    access_denied {
        status
        text
        format
        icon {
            icon
            position
            size
            color
        }
        link1 {
            text
            format
            link
        }
        link2 {
            text
            format
            link
        }
    }
    error_querying {
        status
        text
        format
        icon {
            icon
            position
            size
            color
        }
    }
    application_error {
        status
        text
        format
        icon {
            icon
            position
            size
            color
        }
        text2 {
            text
            format
        }
        link1 {
            text
            format
            link
        }
        link2 {
            text
            format
            link
        }
    }
    i18n {
        language
        field
        value
    }
`;