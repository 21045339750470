import { Map, fromJS } from 'immutable';

/*
 * Password Reducers
 *
 */
import {
    // Validate Reset password
    CHECK_TOKEN_REQUEST, CHECK_TOKEN_SUCCESS, CHECK_TOKEN_ERROR,
    // Reset password
    RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR,
    // Forgot password (and OTP)
    FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_CLEAN_ERROR,
    // Change password (from profile)
    CHANGE_PASSWORD_INITIAL_STATE, CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_ERROR,
    // Welcome user
    WELCOME_REQUEST, WELCOME_SUCCESS, WELCOME_ERROR
} from './password-actions';

const initialState = Map({});

export function checkToken(state = initialState, action) {
    switch (action.type) {

        // Validate reset token
        case CHECK_TOKEN_REQUEST:
            return state
                .remove('payload')
                .merge({
                isFetching: true,
                success: false,
                error: false
            });
        case CHECK_TOKEN_SUCCESS:
            return state.merge({
                isFetching: false,
                success: true,
                error: false,
                payload: fromJS(action.payload)
            });
        case CHECK_TOKEN_ERROR:
            return state.merge({
                isFetching: false,
                success: false,
                error: true,
                errorMessage: action.errorMessage,
                errorCode: action.errorCode
            });
            
        default:
            return state;
    }
}

export function resetPassword(state = initialState, action) {
    switch (action.type) {

        // Reset password
        case RESET_PASSWORD_REQUEST:
            return state.merge({
                isFetching: true,
                success: false,
                error: false
            });
        case RESET_PASSWORD_SUCCESS:
            return state.merge({
                isFetching: false,
                success: true,
                error: false
            });
        case RESET_PASSWORD_ERROR:
            return state.merge({
                isFetching: false,
                success: false,
                error: true,
                errorMessage: action.errorMessage,
                errorCode: action.errorCode
            });
            
        default:
            return state;
    }
}

export function forgotPassword(state = initialState, action) {
    switch (action.type) {

        // Forgot password
        case FORGOT_PASSWORD_REQUEST:
            return state.merge({
                isFetching: true,
                success: false,
                error: false
            });
        case FORGOT_PASSWORD_SUCCESS:
            return state.merge({
                isFetching: false,
                success: true,
                error: false
            });
        case FORGOT_PASSWORD_ERROR:
            return state.merge({
                isFetching: false,
                success: false,
                error: true,
                errorMessage: action.errorMessage,
                errorCode: action.errorCode
            });
        case FORGOT_PASSWORD_CLEAN_ERROR:
            return state.merge({
                isFetching: false,
                success: false,
                error: false,
                errorMessage: '',
                errorCode: 0
            });

        default:
            return state;
    }
}

export function changePassword(state = initialState, action) {
    switch (action.type) {

        // Change password
        case CHANGE_PASSWORD_INITIAL_STATE:
            return state.merge({
                success: false,
                error: false
            });
        case CHANGE_PASSWORD_REQUEST:
            return state.merge({
                success: false,
                error: false
            });
        case CHANGE_PASSWORD_SUCCESS:
            return state.merge({
                success: true
            });
        case CHANGE_PASSWORD_ERROR:
            return state.merge({
                error: true,
                errorMessage: action.errorMessage,
                errorCode: action.errorCode
            });

        default:
            return state;
    }
}

export function welcomeUser(state = initialState, action) {
    switch (action.type) {

        // Welcome
        case WELCOME_REQUEST:
            return state.merge({
                isFetching: true,
                success: false,
                error: false
            });
        case WELCOME_ERROR:
            return state.merge({
                isFetching: false,
                success: false,
                error: true,
                errorMessage: action.errorMessage,
                errorCode: action.errorCode
            });

        default:
            return state;
    }
}