import { Map, fromJS } from 'immutable';

import {
    PAYMENTS_INITIALIZE, PAYMENT_LIST_INITIALIZE, PAYMENT_DETAIL_INITIALIZE,
    PAYMENT_CREATE_REQUEST, PAYMENT_CREATE_SUCCESS, PAYMENT_CREATE_ERROR,
    PAYMENT_UPDATE_REQUEST, PAYMENT_UPDATE_SUCCESS, PAYMENT_UPDATE_ERROR,
    PAYMENT_DELETE_REQUEST, PAYMENT_DELETE_SUCCESS, PAYMENT_DELETE_ERROR,
    PAYMENT_DEFAULT_REQUEST, PAYMENT_DEFAULT_SUCCESS, PAYMENT_DEFAULT_ERROR,
    PAYMENT_SETUP_REQUEST, PAYMENT_SETUP_SUCCESS, PAYMENT_SETUP_ERROR,
    PAYMENT_PROCESS_REQUEST, PAYMENT_PROCESS_SUCCESS, PAYMENT_PROCESS_ERROR
} from './payments-actions';

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

const payments = (state = initialState, action) => {
    let newState;

    // Get list and detail context
    const list = `list_${(action && action.context) || `primary`}`;
    const detail = `detail_${(action && action.context) || `primary`}`;

    switch(action.type) {
        case PAYMENTS_INITIALIZE:
            return initialState;
        case PAYMENT_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;
        case PAYMENT_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;
        case PAYMENT_CREATE_REQUEST:
            newState = state
                .removeIn([detail, 'errorMessage'])
                .removeIn([detail, 'error'])
                .removeIn([detail, 'created'])
                .removeIn([detail, 'payload'])
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .removeIn([list, 'errorMessage'])
                .removeIn([list, 'error'])
                .removeIn([list, 'created'])
                .removeIn([list, 'payload'])
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case PAYMENT_CREATE_SUCCESS:

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created
                }));
            return newState;
        case PAYMENT_CREATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;
        case PAYMENT_UPDATE_REQUEST:
        case PAYMENT_DEFAULT_REQUEST:
            newState = state
                .removeIn([detail, 'errorMessage'])
                .removeIn([detail, 'error'])
                .removeIn([detail, 'created'])
                .removeIn([detail, 'updated'])
                .removeIn([detail, 'payload'])
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .removeIn([list, 'errorMessage'])
                .removeIn([list, 'error'])
                .removeIn([list, 'created'])
                .removeIn([detail, 'updated'])
                .removeIn([list, 'payload'])
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case PAYMENT_UPDATE_SUCCESS:
        case PAYMENT_DEFAULT_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated
                }));
            return newState;
        case PAYMENT_UPDATE_ERROR:
        case PAYMENT_DEFAULT_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    updated: false
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    updated: false
                }));
            return newState;
        case PAYMENT_DELETE_REQUEST:
            newState = state
                .removeIn([detail, 'errorMessage'])
                .removeIn([detail, 'error'])
                .removeIn([detail, 'created'])
                .removeIn([detail, 'updated'])
                .removeIn([detail, 'deleted'])
                .removeIn([detail, 'payload'])
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .removeIn([list, 'errorMessage'])
                .removeIn([list, 'error'])
                .removeIn([list, 'created'])
                .removeIn([detail, 'updated'])
                .removeIn([detail, 'deleted'])
                .removeIn([list, 'payload'])
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case PAYMENT_DELETE_SUCCESS:

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    deleted: action.deleted
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    deleted: action.deleted
                }));
            return newState;
        case PAYMENT_DELETE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    deleted: false
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    deleted: false
                }));
            return newState;
        case PAYMENT_SETUP_REQUEST:
            newState = state
                .removeIn([detail, 'errorMessage'])
                .removeIn([detail, 'error'])
                .removeIn([detail, 'created'])
                .removeIn([detail, 'payload'])
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .removeIn([list, 'errorMessage'])
                .removeIn([list, 'error'])
                .removeIn([list, 'created'])
                .removeIn([list, 'payload'])
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case PAYMENT_SETUP_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: fromJS(action.payload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: fromJS(action.payload)
                }));
            return newState;
        case PAYMENT_SETUP_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;
        case PAYMENT_PROCESS_REQUEST:
            newState = state
                .removeIn([detail, 'errorMessage'])
                .removeIn([detail, 'error'])
                .removeIn([detail, 'created'])
                .removeIn([detail, 'payload'])
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .removeIn([list, 'errorMessage'])
                .removeIn([list, 'error'])
                .removeIn([list, 'created'])
                .removeIn([list, 'payload'])
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case PAYMENT_PROCESS_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: fromJS(action.payload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: fromJS(action.payload)
                }));
            return newState;
        case PAYMENT_PROCESS_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;
        default:
            return state;
    }
};

export default payments;