import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import i18n from '@biuwer/core/src/i18n';

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { GROUPS_USERS_ROLE_DETAIL } from './roles-gql';
import { defaultContext } from "@biuwer/redux/src/config/constants";

/*
 * System Roles action types
 */
export const ROLES_FETCH_REQUEST = 'ROLES_FETCH_REQUEST';
export const ROLES_FETCH_SUCCESS = 'ROLES_FETCH_SUCCESS';
export const ROLES_FETCH_ERROR = 'ROLES_FETCH_ERROR';

export const ROLES_INITIALIZE = 'ROLES_INITIALIZE';
export const ROLE_DETAIL_INITIALIZE = 'ROLE_DETAIL_INITIALIZE';
export const ROLES_LIST_INITIALIZE = 'ROLES_LIST_INITIALIZE';

export const ROLE_FETCH_REQUEST = 'ROLE_FETCH_REQUEST';
export const ROLE_FETCH_SUCCESS = 'ROLE_FETCH_SUCCESS';
export const ROLE_FETCH_ERROR = 'ROLE_FETCH_ERROR';

export const ROLE_UPDATE_REQUEST = 'ROLE_UPDATE_REQUEST';
export const ROLE_UPDATE_SUCCESS = 'ROLE_UPDATE_SUCCESS';
export const ROLE_UPDATE_ERROR = 'ROLE_UPDATE_ERROR';

export const ROLE_CHANGE_STATUS_REQUEST = 'ROLE_CHANGE_STATUS_REQUEST';
export const ROLE_CHANGE_STATUS_SUCCESS = 'ROLE_CHANGE_STATUS_SUCCESS';
export const ROLE_CHANGE_STATUS_ERROR = 'ROLE_CHANGE_STATUS_ERROR';

export const ROLE_CREATE_REQUEST = 'ROLE_CREATE_REQUEST';
export const ROLE_CREATE_SUCCESS = 'ROLE_CREATE_SUCCESS';
export const ROLE_CREATE_ERROR = 'ROLE_CREATE_ERROR';

export const ROLE_DELETE_REQUEST = 'ROLE_DELETE_REQUEST';
export const ROLE_DELETE_SUCCESS = 'ROLE_DELETE_SUCCESS';
export const ROLE_DELETE_ERROR = 'ROLE_DELETE_ERROR';

export const ROLE_ADD_USERS_REQUEST = 'ROLE_ADD_USERS_REQUEST';
export const ROLE_ADD_USERS_SUCCESS = 'ROLE_ADD_USERS_SUCCESS';
export const ROLE_ADD_USERS_ERROR = 'ROLE_ADD_USERS_ERROR';

export const ROLE_DELETE_USERS_REQUEST = 'ROLE_DELETE_USERS_REQUEST';
export const ROLE_DELETE_USERS_SUCCESS = 'ROLE_DELETE_USERS_SUCCESS';
export const ROLE_DELETE_USERS_ERROR = 'ROLE_DELETE_USERS_ERROR';

export const ROLE_ADD_GROUPS_REQUEST = 'ROLE_ADD_GROUPS_REQUEST';
export const ROLE_ADD_GROUPS_SUCCESS = 'ROLE_ADD_GROUPS_SUCCESS';
export const ROLE_ADD_GROUPS_ERROR = 'ROLE_ADD_GROUPS_ERROR';

export const ROLE_DELETE_GROUPS_REQUEST = 'ROLE_DELETE_GROUPS_REQUEST';
export const ROLE_DELETE_GROUPS_SUCCESS = 'ROLE_DELETE_GROUPS_SUCCESS';
export const ROLE_DELETE_GROUPS_ERROR = 'ROLE_DELETE_GROUPS_ERROR';

// Generic Skeleton for every action
const generateSkeleton = (type, body, context = defaultContext, showNotification = true) => {
    let skeleton = { type }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false,
                context: context
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                context: context,
                issuePayload: {
                    status: body.code,
                    code: body.statusCode,
                    message: body.message
                },
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body,
                context: context
            };
            break;
        default:
            break;
    }
    switch(type) {
        case ROLE_CREATE_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', {
                    name: i18n.t('admin.roles.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case ROLE_UPDATE_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', {
                    name: i18n.t('admin.roles.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case ROLE_CHANGE_STATUS_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', {
                    name: i18n.t('common.statusLabel'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case ROLE_DELETE_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', {
                    name: i18n.t('admin.roles.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case ROLE_ADD_USERS_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.addSuccess', {
                    name: i18n.t('admin.users.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case ROLE_DELETE_USERS_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.unassignSuccess', {
                    name: i18n.t('admin.users.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case ROLE_ADD_GROUPS_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.addSuccess', {
                    name: i18n.t('admin.groups.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case ROLE_DELETE_GROUPS_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.unassignSuccess', {
                    name: i18n.t('admin.groups.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case ROLES_FETCH_ERROR:
        case ROLE_UPDATE_ERROR:
        case ROLE_CHANGE_STATUS_ERROR:
        case ROLE_CREATE_ERROR:
        case ROLE_DELETE_ERROR:
        case ROLE_ADD_USERS_ERROR:
        case ROLE_DELETE_USERS_ERROR:
        case ROLE_ADD_GROUPS_ERROR:
        case ROLE_DELETE_GROUPS_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (showNotification && notification) {
            dispatch(addNotification(notification));
        }
    }
};

/**
 * System Roles: Initialize roles action
 */
export function initializeRoles(context = defaultContext) {
    return {
        type: ROLES_INITIALIZE,
        context
    };
}

/**
 * System Role: Initialize role detail action
 */
export function initializeRoleDetail(context = defaultContext) {
    return {
        type: ROLE_DETAIL_INITIALIZE,
        context
    };
}

/**
 * System Roles: Initialize roles list action
 */
export function initializeRolesList(context = defaultContext) {
    return {
        type: ROLES_LIST_INITIALIZE,
        context
    };
}

/**
 * System Roles: Get Roles List for the requester's organization
 * @param query
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function getRolesList(query = {}, context = defaultContext, gql = GROUPS_USERS_ROLE_DETAIL) {
    return async dispatch => {
        try {
            const graphQuery = {
                query: `query($query: QueryRoleInput!) {
                    readRoles(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(ROLES_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(graphQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ROLES_FETCH_ERROR, 'Error getting System Roles data from server', context));
                } else if (res.body && res.body.data && res.body.data.readRoles) {
                    dispatch(generateSkeleton(ROLES_FETCH_SUCCESS, res.body.data.readRoles, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(ROLES_FETCH_ERROR, e, context));
        }
    };
}

/**
 * System Roles: Create roles action
 * @param roleObject - object with all needed info to create a role
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function createRole(roleObject, context = defaultContext, gql = GROUPS_USERS_ROLE_DETAIL) {
    return async dispatch => {

        try {
            const query = {
                query: `mutation($role: RoleInput!) {
                    createRole (role: $role){
                        ${gql}
                    }
                }`,
                variables: {
                    role: roleObject
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(ROLE_CREATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ROLE_CREATE_ERROR, 'Error creating role', context));
                } else if (res.body && res.body.data && res.body.data.createRole) {
                    dispatch(generateSkeleton(ROLE_CREATE_SUCCESS, res.body.data.createRole, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(ROLE_CREATE_ERROR, e, context));
        }
    };
}

/**
 * System Roles: Get role action
 * @param roleId - role to obtain
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function getRole(roleId, context = defaultContext, gql = GROUPS_USERS_ROLE_DETAIL) {
    return async dispatch => {
        try {

            const query = {
                query: `query($roleId: Float!) {
                    readRole(_id: $roleId) {
                        ${gql}
                    }
                }`,
                variables: {
                    roleId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(ROLE_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ROLE_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readRole) {
                    dispatch(generateSkeleton(ROLE_FETCH_SUCCESS, res.body.data.readRole, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(ROLE_FETCH_ERROR, e, context));
        }
    };
}

/**
 * System Roles: Update roles action
 * @param roleObject - role to update
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function updateRole(roleObject, context = defaultContext, gql = GROUPS_USERS_ROLE_DETAIL) {
    return async dispatch => {
        try {

            const query = {
                query: `mutation($roleId: Float!, $role: Role!) {
                    updateRole(_id: $roleId, role: $role) {
                        ${gql}
                    }
                }`,
                variables: {
                    roleId: roleObject._id,
                    role: roleObject
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(ROLE_UPDATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ROLE_UPDATE_ERROR, 'Error updating System Role', context));
                } else if (res.body && res.body.data && res.body.data.updateRole) {
                    dispatch(generateSkeleton(ROLE_UPDATE_SUCCESS, res.body.data.updateRole, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(ROLE_UPDATE_ERROR, e, context));
        }
    }
}

/**
 * System Roles: Change roles status action
 * @param roleId - role Id to update
 * @param status - new role status
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function changeRoleStatus(roleId, status, context = defaultContext, gql = GROUPS_USERS_ROLE_DETAIL) {
    return async dispatch => {
        try {
            const query = {
                query: `mutation($roleId: Float!, $status: String!) {
                    changeRoleStatus(_id: $roleId, status: $status) {
                        ${gql}
                    }
                }`,
                variables: {
                    roleId: roleId,
                    status
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(ROLE_CHANGE_STATUS_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ROLE_CHANGE_STATUS_ERROR, 'Error updating System Role', context));
                } else if (res.body && res.body.data && res.body.data.changeRoleStatus) {
                    dispatch(generateSkeleton(ROLE_CHANGE_STATUS_SUCCESS, res.body.data.changeRoleStatus, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(ROLE_CHANGE_STATUS_ERROR, e, context));
        }
    };
}

/**
 * System Roles: Delete roles action
 * @param roleId - role Id to delete
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function deleteRole(roleId, context = defaultContext, gql = GROUPS_USERS_ROLE_DETAIL) {
    return async dispatch => {
        try {
            const query = {
                query: `mutation($roleId: Float!) {
                    deleteRole(_id: $roleId) {
                        ${gql}
                    }
                }`,
                variables: {
                    roleId: roleId,
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(ROLE_DELETE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ROLE_DELETE_ERROR, 'Error deleting System Role', context));
                } else if (res.body && res.body.data && res.body.data.deleteRole) {
                    dispatch(generateSkeleton(ROLE_DELETE_SUCCESS, res.body.data.deleteRole, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(ROLE_CHANGE_STATUS_ERROR, e, context));
        }
    };
}

/**
 * System Roles: Add users to roles action
 * @param roleId Role id
 * @param users Array of users ids
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function addUsersToRole(roleId, users, context = defaultContext, gql = GROUPS_USERS_ROLE_DETAIL) {
    return async dispatch => {

        try {

            const query = {
                query: `mutation($roleId: Float!, $users: [Float!]!) {
                    addUsersToRole(_id: $roleId, users: $users) {
                        ${gql}
                    }
                }`,
                variables: {
                    roleId,
                    users: users,
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(ROLE_ADD_USERS_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ROLE_ADD_USERS_ERROR, 'Error adding users to role', context));
                } else if (res.body && res.body.data && res.body.data.addUsersToRole) {
                    dispatch(generateSkeleton(ROLE_ADD_USERS_SUCCESS, res.body.data.addUsersToRole, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(ROLE_ADD_USERS_ERROR, e, context));
        }
    };
}

/**
 * System Roles: Delete users from role action
 * @param roleId Role id
 * @param users Array of users ids
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function deleteUsersFromRole(roleId, users, context = defaultContext, gql = GROUPS_USERS_ROLE_DETAIL) {
    return async dispatch => {
        try {

            const query = {
                query: `mutation($roleId: Float!, $users: [Float!]!) {
                    deleteUsersFromRole(_id: $roleId, users: $users) {
                        ${gql}
                    }
                }`,
                variables: {
                    roleId,
                    users: users
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(ROLE_DELETE_USERS_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ROLE_DELETE_USERS_ERROR, 'Error deleting users from role', context));
                } else if (res.body && res.body.data && res.body.data.deleteUsersFromRole) {
                    dispatch(generateSkeleton(ROLE_DELETE_USERS_SUCCESS, res.body.data.deleteUsersFromRole, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(ROLE_DELETE_USERS_ERROR, e, context));
        }
    };
}

/**
 * System Roles: Add groups to roles action
 * @param roleId Role id
 * @param groups Array of groups ids
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function addGroupsToRole(roleId, groups, context = defaultContext, gql = GROUPS_USERS_ROLE_DETAIL) {
    return async dispatch => {
        try {

            const query = {
                query: `mutation($roleId: Float!, $groups: [Float!]!) {
                    addGroupsToRole(_id: $roleId, groups: $groups) {
                        ${gql}
                    }
                }`,
                variables: {
                    roleId,
                    groups: groups,
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(ROLE_ADD_GROUPS_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ROLE_ADD_GROUPS_ERROR, 'Error adding groups to role', context));
                } else if (res.body && res.body.data && res.body.data.addGroupsToRole) {
                    dispatch(generateSkeleton(ROLE_ADD_GROUPS_SUCCESS, res.body.data.addGroupsToRole, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(ROLE_ADD_GROUPS_ERROR, e, context));
        }
    };
}

/**
 * System Roles: Delete groups frome role action
 * @param roleId Role id
 * @param groups Array of groups ids
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function deleteGroupsFromRole(roleId, groups, context = defaultContext, gql = GROUPS_USERS_ROLE_DETAIL) {
    return async dispatch => {
        try {

            const query = {
                query: `mutation($roleId: Float!, $groups: [Float!]!) {
                    deleteGroupsFromRole(_id: $roleId, groups: $groups) {
                        ${gql}
                    }
                }`,
                variables: {
                    roleId,
                    groups: groups,
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(ROLE_ADD_GROUPS_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ROLE_DELETE_GROUPS_ERROR, 'Error deleting groups from user', context));
                } else if (res.body && res.body.data && res.body.data.deleteGroupsFromRole) {

                    dispatch(generateSkeleton(ROLE_DELETE_GROUPS_SUCCESS, res.body.data.deleteGroupsFromRole, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(ROLE_DELETE_GROUPS_ERROR, e, context));
        }
    };
}
