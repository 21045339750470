import request from "@biuwer/common/src/libs/superagent";

import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import { CREATE_ORDER, ORDERS_DETAIL } from './orders-gql';
import { defaultContext } from "@biuwer/redux/src/config/constants";
import i18n from '@biuwer/core/src/i18n';
import {addNotification} from "@biuwer/redux/src/system/notifications/notifications-actions";
import { saveDoc } from "@biuwer/redux/src/data/content/content-lib";

/*
 * Orders action types
 */
export const ORDERS_INITIALIZE = 'ORDERS_INITIALIZE';
export const ORDER_DETAIL_INITIALIZE = 'ORDER_DETAIL_INITIALIZE';
export const ORDER_LIST_INITIALIZE = 'ORDER_LIST_INITIALIZE';

export const ORDERS_FETCH_REQUEST = 'ORDERS_FETCH_REQUEST';
export const ORDERS_FETCH_SUCCESS = 'ORDERS_FETCH_SUCCESS';
export const ORDERS_FETCH_ERROR = 'ORDERS_FETCH_ERROR';

export const ORDER_FETCH_REQUEST = 'ORDER_FETCH_REQUEST';
export const ORDER_FETCH_SUCCESS = 'ORDER_FETCH_SUCCESS';
export const ORDER_FETCH_ERROR = 'ORDER_FETCH_ERROR';

export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_ERROR = 'ORDER_CREATE_ERROR';

export const ORDER_CHANGE_STATUS_REQUEST = 'ORDER_CHANGE_STATUS_REQUEST';
export const ORDER_CHANGE_STATUS_SUCCESS = 'ORDER_CHANGE_STATUS_SUCCESS';
export const ORDER_CHANGE_STATUS_ERROR = 'ORDER_CHANGE_STATUS_ERROR';

export const ORDER_CANCEL_REQUEST = 'ORDER_CANCEL_REQUEST';
export const ORDER_CANCEL_SUCCESS = 'ORDER_CANCEL_SUCCESS';
export const ORDER_CANCEL_ERROR = 'ORDER_CANCEL_ERROR';

// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, showNotification = true) => {
    let skeleton = { type }, notification;

    switch (type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false,
                context: context
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                error: true,
                context: context
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body,
                context: context
            };
            break;
        default:
            break;
    }

    switch(type){
        case ORDER_FETCH_ERROR:
            skeleton.errorMessage = body ?? {};
            break;
        case ORDER_CREATE_SUCCESS:
            skeleton.created = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', {
                    name: i18n.t('billing:orders.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    };
};


/**
 * Initialize Orders
 */
export function initializeOrders(context = defaultContext) {
    return {
        type: ORDERS_INITIALIZE,
        context
    };
}

/**
 * Initialize order detail action
 */
export function initializeOrdersDetail(context = defaultContext) {
    return {
        type: ORDER_DETAIL_INITIALIZE,
        context
    };
}

/**
 * Initialize order list action
 */
export function initializeUsersList(context = defaultContext) {
    return {
        type: ORDER_LIST_INITIALIZE,
        context
    };
}

/**
 * getOrder: Reads the requested order
 * @param orderId - Id belonging to the requested order
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function getOrder(orderId, context  = 'primary', gql = ORDERS_DETAIL) {

    return async dispatch => {
        try {

            const query = {
                query: `query($orderId: String!) {
                    readOrder(orderId: $orderId) {
                        ${gql}
                    }
                }`,
                variables: {
                    orderId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(ORDER_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ORDER_FETCH_ERROR, 'Error getting order data from server', context));
                } else if (res.body && res.body.data && res.body.data.readOrder) {
                    dispatch(generateSkeleton(ORDER_FETCH_SUCCESS, res.body.data.readOrder, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(ORDER_FETCH_ERROR, err, context));
        }
    }
}

/**
 * queryOrder - Reads multiple orders that satisfies given dataQuery
 * @param query - Query to use as a filter when reading orders
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function queryOrders(query = {}, context  = 'primary', gql = ORDERS_DETAIL) {

    return async dispatch => {
        try {

            const graphQuery = {
                query: `query($query: QueryOrdersInput!) {
                    queryOrders(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(ORDERS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(graphQuery)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ORDERS_FETCH_ERROR, 'Error getting orders data from server', context));
                } else if (res.body && res.body.data && res.body.data.queryOrders) {
                    dispatch(generateSkeleton(ORDERS_FETCH_SUCCESS, res.body.data.queryOrders, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(ORDERS_FETCH_ERROR, err, context));
        }
    };
}

/**
 * System Orders: Create orders action
 * @param newOrderMetadata - object with all needed info to create a order
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function createOrder(newOrderMetadata, context = defaultContext, gql = CREATE_ORDER) {
    return async dispatch => {

        try {
            const query = {
                query: `mutation($newOrderMetadata: JSONObject) {
                    createOrder (newOrderMetadata: $newOrderMetadata){
                        ${gql}
                    }
                }`,
                variables: {
                    newOrderMetadata: newOrderMetadata
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(ORDER_CREATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ORDER_CREATE_ERROR, 'Error creating order', context));
                } else if (res.body && res.body.data && res.body.data.createOrder) {
                    dispatch(generateSkeleton(ORDER_CREATE_SUCCESS, res.body.data.createOrder, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(ORDER_CREATE_ERROR, e, context));
        }
    };
}

/**
 * System Orders: Change order status action
 * @param orderId - order Id to update
 * @param status - new order status
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function changeOrderStatus(orderId, status, context = defaultContext, gql = ORDERS_DETAIL) {
    return async dispatch => {
        try {
            const query = {
                query: `mutation($orderId: Float!, $status: String!) {
                    changeOrderStatus(_id: $orderId, status: $status) {
                        ${gql}
                    }
                }`,
                variables: {
                    orderId: orderId,
                    status
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(ORDER_CHANGE_STATUS_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ORDER_CHANGE_STATUS_ERROR, 'Error updating System Order', context));
                } else if (res.body && res.body.data && res.body.data.changeOrderStatus) {
                    dispatch(generateSkeleton(ORDER_CHANGE_STATUS_SUCCESS, res.body.data.changeOrderStatus, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(ORDER_CHANGE_STATUS_ERROR, e, context));
        }
    };
}

/**
 * System Orders: Download Order action
 * @param orderId - order Id to download in pdf
 * @param context - structure to store data in redux
 */
export function downloadOrder(orderId, context = defaultContext) {
    return async dispatch => {
        try {
            const query = {
                query: `query($orderId: Float!) {
                    downloadOrder(_id: $orderId) {
                        file
                        name
                    }
                }`,
                variables: {
                    orderId: orderId
                }
            };

            const token = Auth.getLocalJwt();

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                } else if (res.body && res.body.data && res.body.data.downloadOrder) {
                    await saveDoc(res.body.data.downloadOrder.file, 'pdf', res.body.data.downloadOrder.name || 'unnamed.pdf');
                }
            }
        } catch (e) {
        }
    };
}

export const cancelOrder = (orderId, context = defaultContext, gql = ORDERS_DETAIL) => {
    return async dispatch => {
        try {
            const gqlQuery = {
                query: `mutation($orderId: Float!) {
                    cancelOrder(_id: $orderId) {
                        ${gql}
                    }
                }`,
                variables: {
                    orderId
                }
            };

            const token = Auth.getLocalJwt();

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ORDER_CANCEL_ERROR, 'Error while cancelling order', context));
                } else if (res.body && res.body.data && res.body.data.cancelOrder) {
                    dispatch(generateSkeleton(ORDER_CANCEL_SUCCESS, res.body.data.cancelOrder, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(ORDER_CANCEL_ERROR, err, context));
        }
    };
};

export function getOrderByPaymentLink(paymentLinkId, context = defaultContext, gql = ORDERS_DETAIL) {
    return async (dispatch) => {
        try {
            const gqlQuery = {
                query: `query($id: String!) {
                    getPaymentLinkOrder(paymentLinkId: $id) {
                        ${gql}
                    }
                }`,
                variables: {
                    id: paymentLinkId
                }
            }

            dispatch(generateSkeleton(ORDER_FETCH_REQUEST, null, context))

            const res = await request
                .post("/api/gql")
                .send(gqlQuery)

            if (res) {
                if (res?.body?.errors?.length > 0) {
                    dispatch(generateSkeleton(ORDER_FETCH_ERROR, res.body.errors[0], context))
                } else if (res?.body?.data?.getPaymentLinkOrder) {
                    dispatch(generateSkeleton(ORDER_FETCH_SUCCESS, res.body.data.getPaymentLinkOrder, context))
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(ORDER_FETCH_ERROR, err, context))
        }
    }
}