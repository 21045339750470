import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

//Components
import TextInput from '@biuwer/common/src/components/text-input';
import PasswordStrength from '@biuwer/common/src/components/password-strength';

// Libs
import Auth from "@biuwer/redux/src/system/auth/auth-lib";

const WelcomeForm = ({ password, setPassword, repeatPassword, setRepeatPassword, onSubmit }) => {

    const { t } = useTranslation();

    const [validPassword, setValidPassword] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] = useState('');

    const validatePasswords = (passwordA, passwordB) => {
        if(!passwordA || !Auth.passwordRegExp.complete.exec(passwordA) || !passwordB) {
            return false;
        }
        return passwordA === passwordB;
    };

    useEffect(() =>{
        const valid = !!Auth.passwordRegExp.complete.exec(password);
        setValidPassword(valid);
        if (password && !valid) {
            setPasswordErrorMessage(t('admin.users.checkPasswordRequirementsMessage'));
        } else if (password !== repeatPassword) {
            setPasswordErrorMessage('');
            setRepeatPasswordErrorMessage(t('admin.users.notMatchingPasswordsMessage'));
            setRepeatPasswordErrorMessage(t('admin.users.notMatchingPasswordsMessage'));
        } else {
            setPasswordErrorMessage('');
            setRepeatPasswordErrorMessage('');
        }
    }, [password, repeatPassword]);

    return (
        <div className="animated fadeIn">
            <div id="form-login" className="p-t-15">
                <TextInput
                    formStyling="default"
                    type="password"
                    name="newPassword"
                    labelText={t('auth.resetPassword.passwordLabel')}
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                    errorText={passwordErrorMessage}
                />
                <TextInput
                    formStyling="default"
                    type="password"
                    name="repeatPassword"
                    labelText={t('auth.resetPassword.reEnterPasswordLabel')}
                    value={repeatPassword}
                    onChange={event => setRepeatPassword(event.target.value)}
                    errorText={repeatPasswordErrorMessage}
                />
                <PasswordStrength password={password} validPassword={validPassword} t={t} />
                <div>
                    <button
                        key="submit-button"
                        type="button"
                        className="btn btn-sm btn-info  ml15 mt5"
                        onClick={onSubmit}
                        disabled={!validatePasswords(password, repeatPassword)}
                    >
                        {t('auth.signinForm.submitButton').toUpperCase()}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WelcomeForm;