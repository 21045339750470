export default {
    "introduction": "A pricing plan for every need",
    "most_popular": "Most Popular!",
    "currentPlan": "Current Plan",
    "selectedPlan": "Selected Plan",
    "selectPlan": "Select Plan",
    "additionalProducts": "Complete your plan",
    "selectedProduct": "Selected Product",
    "selectProduct": "Select Product",
    "per_month": "per month",
    "per_year": "per year",
    "contact_us": "Contact us",
    "try_for_free": "Try for free!",
    "free_trial": "Free 30-days trial",
    "no_credit_card": "No credit card required",
    "unlimited": "Unlimited",
    "choose_billing_period": "Choose your billing period",
    "monthly": "Monthly",
    "annual": "Annual",
    "monthly_reward": "All our plans include a free month!",
    "annual_reward": "Get 2 months free!",
    "choose_currency": "Choose your currency",
    "limitExceeded": "The limit has been exceeded in some of the features included in the plan, adjust the necessary amount in the \"Complete your plan\" section",
    "plans": {
        "header_desc": "Manage your Biuwer Plan to addapt it to your needs.",
        "feature_intro": "Includes the following features:",
        "basic": {
            "name": "Basic",
            "best_for": "Start analyzing and making decisions with data. For small companies and startups.",
            "feature_intro": "All our plans features, including:"
        },
        "standard": {
            "name": "Standard",
            "best_for": "More features and database connectors to grow with data. For mid size companies.",
            "feature_intro": "All Basic features plus:"
        },
        "professional": {
            "name": "Professional",
            "best_for": "Data analytics for larger companies with external embedding of data.",
            "feature_intro": "All Standard features plus:"
        }
    },
    "features": {
        "upload_data_file": "Upload data from CSV/Excel",
        "export_data_file": "Export to CSV, Excel, PDF",
        "page_card_filters": "Page and Card filters",
        "favorites": "Favorite content",
        "rbac": "Group and Role based access",
        "reverse_engineering": "Reverse engineering",
        "calculated_fields": "Calculated fields",
        "dependent_filters": "Dependent filters",
        "embedding": "External data embedding",
        "custom_fields": "Custom fields",
        "advanced_content_management": "Content management",
        "row_access_level": "Row access level",
        "white_labeling": "White labeling",
        "dedicated_server": "Dedicated server",
        "api_access": "API access",
        "priority_support": "Priority support"
    },
    "products": {
        "advanced_user": "Advanced Users",
        "basic_user": "Basic Users",
        "data_connection": "Data Connections",
        "data_usage": "Data Usage"
    },
    "included": {
        "temporarilyFree": "For a limited time, you have unlimited data usage for free."
    }
};