import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';

let Types = {
    CELL: 'cell'
};

// Drag origin functions
let cellSource = {
    beginDrag(props) {
        return { id: props.id };
    },

    canDrag(props) {
        return props.canDrag;
    }
};


// Drag target functions
let cellTarget = {
    drop(props, monitor) {

        let draggedId = monitor.getItem().id;

        if (draggedId !== props.id) {
            props.moveCell(draggedId, props.id);
        }
    },

    hover(props, monitor) {

        let draggedId = monitor.getItem().id;

        if (draggedId !== props.id) {
            props.hoverCell(draggedId, props.id);
        }
    }
};


let sourceCollect = function(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    };
};

let targetCollect = function(connect) {
    return {
        connectDropTarget: connect.dropTarget()
    };
};

// Returns a draggable cell whith the render specified in this.pros.cellContent
class DraggableCell extends React.Component {

    render () {

        let connectDragSource = this.props.connectDragSource;
        let connectDropTarget = this.props.connectDropTarget;

        return connectDragSource(connectDropTarget(
            this.props.cellContent
        ));
    }
}

let source = new DragSource(Types.CELL, cellSource, sourceCollect) (DraggableCell);
let row = new DropTarget(Types.CELL, cellTarget, targetCollect) (source);

export default row;