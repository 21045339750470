import { Map } from 'immutable';

/**
 * Sidebar-left and Drawer menus Reducer
 */
import { SET_DRAWER_IS_OPEN, SET_CHANGE_ORG_DIALOG } from './menus-actions';

const initialState = Map({});

const menus = (state = initialState, action) => {
    let newState;
    switch (action.type) {        
        case SET_DRAWER_IS_OPEN:
            newState = state
                .merge({
                    drawerIsOpen: action.isOpen
                });
            return newState;
        case SET_CHANGE_ORG_DIALOG:
            newState = state
                .merge({
                    showChangeOrgDialog: action.isOpen
                });
            return newState;
        default:
            return state;
    }
};

export default menus;