import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import i18n from "@biuwer/core/src/i18n";
import { defaultContext } from "@biuwer/redux/src/config/constants";

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { AGENTS_LIST_DETAIL } from './agents-gql';

/*
 * Agent action types
 */

export const AGENT_INITIALIZE = 'AGENT_INITIALIZE';
export const AGENT_DETAIL_INITIALIZE = 'AGENT_DETAIL_INITIALIZE';
export const AGENTS_LIST_INITIALIZE = 'AGENTS_LIST_INITIALIZE';

export const AGENTS_FETCH_REQUEST = 'AGENTS_FETCH_REQUEST';
export const AGENTS_FETCH_SUCCESS = 'AGENTS_FETCH_SUCCESS';
export const AGENTS_FETCH_ERROR = 'AGENTS_FETCH_ERROR';

export const AGENT_FETCH_REQUEST = 'AGENT_FETCH_REQUEST';
export const AGENT_FETCH_SUCCESS = 'AGENT_FETCH_SUCCESS';
export const AGENT_FETCH_ERROR = 'AGENT_FETCH_ERROR';

export const AGENT_CREATE_REQUEST = 'AGENT_CREATE_REQUEST';
export const AGENT_CREATE_SUCCESS = 'AGENT_CREATE_SUCCESS';
export const AGENT_CREATE_ERROR = 'AGENT_CREATE_ERROR';

export const AGENT_UPDATE_REQUEST = 'AGENT_UPDATE_REQUEST';
export const AGENT_UPDATE_SUCCESS = 'AGENT_UPDATE_SUCCESS';
export const AGENT_UPDATE_ERROR = 'AGENT_UPDATE_ERROR';

export const AGENT_DELETE_REQUEST = 'AGENT_DELETE_REQUEST';
export const AGENT_DELETE_SUCCESS = 'AGENT_DELETE_SUCCESS';
export const AGENT_DELETE_ERROR = 'AGENT_DELETE_ERROR';


// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, showNotification = true, extraArgs) => {
    let skeleton = { type, context }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)){
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.issuePayload ? body.issuePayload.code : body.payload, // Backward compatibility with old error handling
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body.payload
            };

            if (type === 'AGENT_UPDATE_SUCCESS') {
                skeleton.updated = true;
            }

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:

            break;
    }

    switch(type){
        case AGENT_UPDATE_SUCCESS:
            skeleton.payload = body;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('dataPrep.agents.title'), context: 'male', count: 1 })
            };
            break;
        case AGENT_CREATE_SUCCESS:
            skeleton.payload = body;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', { name: i18n.t('dataPrep.agents.title'), context: 'male', count: 1 })
            };
            break;
        case AGENT_DELETE_SUCCESS:
            skeleton.payload = body;
            skeleton.deleted = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', { name: i18n.t('dataPrep.agents.title'), context: 'male', count: 1 })
            };
            break;
        case AGENT_FETCH_SUCCESS:
        case AGENTS_FETCH_SUCCESS:
            skeleton.payload = body;
            break;
        case AGENT_CREATE_ERROR:
        case AGENT_UPDATE_ERROR:
        case AGENT_DELETE_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    }
};

/**
 * Get Agents action
 */

export function getAgents(query = {}, context = defaultContext, gql = AGENTS_LIST_DETAIL) {

    return async (dispatch) => {
        try {
            const gqlQuery = {
                query: `query($query: QueryAgentsInput!) {
                    queryAgents(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query: query
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(AGENTS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(AGENTS_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.queryAgents) {
                    dispatch(generateSkeleton(AGENTS_FETCH_SUCCESS, res.body.data.queryAgents, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(AGENTS_FETCH_ERROR, err, context));
        }
    };
}

/**
 * Get Shared Agents action
 */

export function getSharedAgents(query = {}, context = defaultContext, gql = AGENTS_LIST_DETAIL) {

    return async (dispatch) => {
        try {
            const gqlQuery = {
                query: `query($query: QueryAgentsInput!) {
                    getSharedAgents(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query: query
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(AGENTS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(AGENTS_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.getSharedAgents) {
                    dispatch(generateSkeleton(AGENTS_FETCH_SUCCESS, res.body.data.getSharedAgents, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(AGENTS_FETCH_ERROR, err, context));
        }
    };
}

/**
 * Initialize Agent
 */
export const initializeAgent = (context = defaultContext) => {
    return {
        type: AGENT_INITIALIZE,
        context: context
    };
};

/**
 * Initialize Agent Detail
 */
export const initializeAgentDetail = (context = defaultContext) => {
    return {
        type: AGENT_DETAIL_INITIALIZE,
        context: context
    };
};
/**
 * Initialize Agents List
 */
export const initializeAgentsList = (context = defaultContext) => {
    return {
        type: AGENTS_LIST_INITIALIZE,
        context: context
    };
};

/**
 * Get Agent action
 */

export function getAgent(agentId, context = defaultContext, gql = AGENTS_LIST_DETAIL) {

    return async (dispatch) => {

        try {

            const gqlQuery = {
                query: `query($id: Float!) {
                    readAgent(_id: $id) {
                        ${gql}
                    }
                }`,
                variables: {
                    id: Number(agentId)
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(AGENT_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(AGENT_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readAgent) {
                    dispatch(generateSkeleton(AGENT_FETCH_SUCCESS, res.body.data.readAgent, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(AGENT_FETCH_ERROR), err, context);
        }
    };
}

/**
 * Create agent action
 * @param agentData
 * @param context
 * @param gql
 */
export function createAgent(agentData, context = defaultContext, gql = AGENTS_LIST_DETAIL) {

    return async (dispatch) => {

        try {

            const agent = {
                name: agentData.name,
                desc: agentData.desc,
                use_type: agentData.use_type,
                max_idle_time: agentData.max_idle_time || 150000
            };
            const agentId = agentData._id || undefined;

            const gqlQuery = {
                query: `mutation($agent: AgentInput!, $agentId: Float) {
                    createAgent(agent: $agent, agentId: $agentId) {
                        ${gql}
                    }
                }`,
                variables: {
                    agent: agent,
                    agentId: agentId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(AGENT_CREATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(AGENT_CREATE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.createAgent) {
                    dispatch(generateSkeleton(AGENT_CREATE_SUCCESS, res.body.data.createAgent, context, true));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(AGENT_CREATE_ERROR, err, context, true));
        }
    };
}

/**
 * Update agent action
 * @param agentData
 * @param context
 * @param gql
 */
export function updateAgent(agentData, context, gql = AGENTS_LIST_DETAIL) {

    return async (dispatch) => {
        try {

            const agent = {
                ...agentData,
                _id: undefined
            };

            const gqlQuery = {
                query: `mutation($id: Float!, $agent: AgentInput!) {
                    updateAgent(_id: $id, agent: $agent) {
                        ${gql}
                    }
                }`,
                variables: {
                    id: agentData._id,
                    agent: agent
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(AGENT_UPDATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(AGENT_UPDATE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.updateAgent) {
                    dispatch(generateSkeleton(AGENT_UPDATE_SUCCESS, res.body.data.updateAgent, context, true));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(AGENT_UPDATE_ERROR, err, context));
        }
    };
}

/**
 * Delete agent action
 * @param agentId
 * @param context
 * @param gql
 */
export function deleteAgent(agentId, context = defaultContext, gql = AGENTS_LIST_DETAIL) {

    return async (dispatch) => {
        try {

            const gqlQuery = {
                query: `mutation($id: Float!) {
                    deleteAgent(_id: $id) {
                        ${gql}
                    }
                }`,
                variables: {
                    id: Number(agentId)
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(AGENT_DELETE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(AGENT_DELETE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.deleteAgent) {
                    dispatch(generateSkeleton(AGENT_DELETE_SUCCESS, res.body.data.deleteAgent, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(AGENT_DELETE_ERROR, err, context));
        }
    };
}