import request from "@biuwer/common/src/libs/superagent";

import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import { PRODUCTS_DETAIL } from './products-gql';

/*
 * Products action types
 */
export const PRODUCTS_INITIALIZE = 'PRODUCTS_INITIALIZE';
export const PRODUCT_DETAIL_INITIALIZE = 'PRODUCT_DETAIL_INITIALIZE';
export const PRODUCT_LIST_INITIALIZE = 'PRODUCT_LIST_INITIALIZE';

export const PRODUCTS_FETCH_REQUEST = 'PRODUCTS_FETCH_REQUEST';
export const PRODUCTS_FETCH_SUCCESS = 'PRODUCTS_FETCH_SUCCESS';
export const PRODUCTS_FETCH_ERROR = 'PRODUCTS_FETCH_ERROR';

export const PRODUCT_FETCH_REQUEST = 'PRODUCT_FETCH_REQUEST';
export const PRODUCT_FETCH_SUCCESS = 'PRODUCT_FETCH_SUCCESS';
export const PRODUCT_FETCH_ERROR = 'PRODUCT_FETCH_ERROR';

// Generic Returns Skeleton
const generateSkeleton = (type, body, context = 'primary') => {
    let skeleton = { type };

    switch (type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false,
                context: context
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                context: context
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body,
                context: context
            };
            break;
        default:
            break;
    }

    switch(type){
        case PRODUCT_FETCH_ERROR:
            skeleton.issuePayload = {
                ...skeleton.issuePayload || {},
                // code: extraArgs.code
            };
            break;
        default:
            break;
    }

    return skeleton;
};


/**
 * Initialize Products
 */
export function initializeProducts() {
    return {
        type: PRODUCTS_INITIALIZE
    };
}

/**
 * Initialize product detail action
 */
export function initializeProductsDetail() {
    return {
        type: PRODUCT_DETAIL_INITIALIZE
    };
}

/**
 * Initialize product list action
 */
export function initializeUsersList() {
    return {
        type: PRODUCT_LIST_INITIALIZE
    };
}

/**
 * getProduct: Reads the requested product
 * @param productId - Id belonging to the requested product
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function getProduct(productId, context  = 'primary', gql = PRODUCTS_DETAIL) {

    return async dispatch => {
        try {

            const query = {
                query: `query($productId: String!) {
                    readProduct(productId: $productId) {
                        ${gql}
                    }
                }`,
                variables: {
                    productId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(PRODUCT_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(PRODUCT_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readProduct) {
                    dispatch(generateSkeleton(PRODUCT_FETCH_SUCCESS, res.body.data.readProduct, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(PRODUCT_FETCH_ERROR, err, context));
        }
    }
}

/**
 * queryProduct - Reads multiple products that satisfies given dataQuery
 * @param query - Query to use as a filter when reading products
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function queryProducts(query = {}, context  = 'primary', gql = PRODUCTS_DETAIL) {

    return async dispatch => {
        try {

            const graphQuery = {
                query: `query($query: QueryProductInput!) {
                    queryProducts(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(PRODUCTS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(graphQuery)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(PRODUCTS_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.queryProducts) {
                    dispatch(generateSkeleton(PRODUCTS_FETCH_SUCCESS, res.body.data.queryProducts, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(PRODUCTS_FETCH_ERROR, err, context));
        }
    };
}
