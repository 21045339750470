// Libs
import gqlRequest from '@biuwer/core/src/graphql-request';
import Auth from "./auth-lib";
import chatLib from "@biuwer/common/src/libs/chat-lib";

// Spaces
import { initializeSpaces, getAllSpaces, newSpacesCreated } from "@biuwer/redux/src/system/spaces/spaces-actions";
import { NEW_SPACES_LIST } from "@biuwer/redux/src/system/spaces/spaces-gql";
import spacesLib from "@biuwer/biuwer/src/spaces/spaces-lib";

// Session actions
import { signinSuccess, setCurrentOrganizationFromAuth, getUserPermissions, getSessionData, checkLanguage } from "@biuwer/redux/src/system/auth/session-actions";

// GraphQL schemas
import { AUTH_USER } from "@biuwer/redux/src/system/auth/auth-gql";

// External Actions
export const CHECK_TOKEN_REQUEST = 'CHECK_TOKEN_REQUEST';
export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS';
export const CHECK_TOKEN_ERROR = 'CHECK_TOKEN_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_CLEAN_ERROR = 'FORGOT_PASSWORD_CLEAN_ERROR';

export const CHANGE_PASSWORD_INITIAL_STATE = 'CHANGE_PASSWORD_INITIAL_STATE';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const WELCOME_REQUEST = 'WELCOME_REQUEST';
export const WELCOME_SUCCESS = 'WELCOME_SUCCESS';
export const WELCOME_ERROR = 'WELCOME_ERROR';

/**
 * Check Reset Token
 * Check if the token (url access token) is valid
 * @param {object} token
 */
 export function validateResetPassword(token) {
    return async dispatch => {
        try {

            if (token === '') return;

            dispatch({
                type: CHECK_TOKEN_REQUEST
            });

            const user = await gqlRequest({
                queryGql: AUTH_USER,
                queryType: "mutation",
                queryName: "validateResetPassword",
                variables: [{
                    type: "ValidateResetPasswordInput!",
                    name: "validateResetPasswordInput",
                    data: { userToken: token }
                }]
            });

            // If the response have a JWT authorization header proceed with complete the login.
            // This case is valid for the One Time Login route. (/otp:token)
            // For reset password route (/reset/:token) the api will not send the authorization header.

            if (!!Auth.getLocalJwt() && user && user.status === 'active') {

                // Save user in Auth
                Auth.setUser(user);

                const currentOrganization = Auth.getCurrentOrganization();

                // Dispatch user and current organization to redux
                dispatch(signinSuccess(user, currentOrganization));

                // Dispatch getCurrentOrganization after signin. When a user load the app as a logged off user the current organization is set to null.
                dispatch(setCurrentOrganizationFromAuth());

                // Get user permissions from server
                dispatch(getUserPermissions());

                // Get Spaces
                dispatch(initializeSpaces(spacesLib.SPACES_CONTEXT));
                dispatch(getAllSpaces(spacesLib.SPACES_CONTEXT, NEW_SPACES_LIST));
                dispatch( newSpacesCreated(spacesLib.SPACES_CONTEXT, NEW_SPACES_LIST));

                // Identify user in crisp
                chatLib.identifyUserInCrisp();

            }

            dispatch({
                type: CHECK_TOKEN_SUCCESS,
                payload: user
            });

        } catch (err) {
            dispatch({
                type: CHECK_TOKEN_ERROR,
                errorMessage: err.message,
                errorCode: err.code
            });
        }
    };
}

/**
 * Reset Password
 * Resets the user password based on a valid token
 * @param {String} newPassword
 * @param {String} verifyPassword
 * @param {String} token
 * @param {Number} userId
 * @param {String} userEmail
 */
export function resetPassword(newPassword, verifyPassword, token, userId, userEmail) {
    return async dispatch => {
        try {

            dispatch({
                type: RESET_PASSWORD_REQUEST
            });

            if (token === '') return;

            // Send resetPassword input data and returns user
            const user = await gqlRequest({
                queryGql: AUTH_USER,
                queryType: "mutation",
                queryName: "resetPassword",
                variables: [{
                    type: "ResetPasswordInput!",
                    name: "resetPasswordInput",
                    data: {
                        _id: Number(userId),
                        email: userEmail,
                        newPassword: newPassword,
                        verifyPassword: verifyPassword,
                        userToken: token
                    }
                }]
            });

            // Logs the user in
            // Follow the same process as a user when logs in

            // Save user in Auth
            Auth.setUser(user);

            // Signin in redux
            const currentOrganization = Auth.getCurrentOrganization();
            dispatch(signinSuccess(user, currentOrganization));

            // Dispatch getSessionData after signin
            dispatch(getSessionData());

            dispatch({
                type: RESET_PASSWORD_SUCCESS
            });

        } catch (err) {
            dispatch({
                type: RESET_PASSWORD_ERROR,
                errorMessage: err.message,
                errorCode: err.code
            });
        }
    };
}

/**
 * Forgot Password
 * Request the api an email for reseting the password (forgot password)
 * @param {String} email
 */
export function forgotPassword(email) {
    return async dispatch => {
        try {

            dispatch(forgotPasswordRequest());

            if (!email || email === "") return;

            // Send email to request forgot password email
            await gqlRequest({
                queryType: "mutation",
                queryName: "forgotPassword",
                variables: [{
                    type: "ForgotPasswordInput!",
                    name: "forgotPasswordInput",
                    data: {
                        email: email
                    }
                }]
            });

            dispatch(forgotPasswordSuccess());

        } catch (err) {
            dispatch(forgotPasswordError(err));
        }
    };
}
function forgotPasswordRequest() {
    return {
        type: FORGOT_PASSWORD_REQUEST
    };
}
function forgotPasswordSuccess() {
    return {
        type: FORGOT_PASSWORD_SUCCESS
    };
}
function forgotPasswordError(error) {
    return {
        type: FORGOT_PASSWORD_ERROR,
        errorMessage: error.message,
        errorCode: error.code
    };
}
export function forgotPasswordCleanError() {
    return {
        type: FORGOT_PASSWORD_CLEAN_ERROR
    };
}

/**
 * One Time Login
 * Request the api an email for access the application (one time valid access)
 * We use the same action dispatchers for one time login form.
 * @param {String} email
 */
export function oneTimeLogin(email) {
    return async dispatch => {
        try {

            dispatch(forgotPasswordRequest());

            if (!email || email === "") return;

            // Send email to request one time password email
            await gqlRequest({
                queryType: "mutation",
                queryName: "oneTimePassword",
                variables: [{
                    type: "OneTimePasswordInput!",
                    name: "oneTimePasswordInput",
                    data: {
                        email: email
                    }
                }]
            });

            dispatch(forgotPasswordSuccess());

        } catch (err) {
            dispatch(forgotPasswordError(err));
        }
    };
}

/**
 * Change Password
 * @param {object} passwordDetails
 *      passwordDetails.currentPassword: The current user password
 *      passwordDetails.newPassword: The new password that will change the user password
 *      passwordDetails.verifyPassword: A secodn new password for verifying that both are the same
 */
export function changePassword(passwordDetails) {
    return async dispatch => {
        try {

            dispatch({
                type: CHANGE_PASSWORD_REQUEST
            });

            if (!passwordDetails || typeof passwordDetails !== "object") return;

            // PasswordDetails destructure
            const { currentPassword, newPassword, verifyPassword } = passwordDetails;

            // Send change password input object
            await gqlRequest({
                queryGql: AUTH_USER,
                queryType: "mutation",
                queryName: "changePassword",
                variables: [{
                    type: "String!",
                    name: "currentPassword",
                    data: currentPassword
                }, {
                    type: "String!",
                    name: "newPassword",
                    data: newPassword
                }, {
                    type: "String!",
                    name: "verifyPassword",
                    data: verifyPassword
                }]
            });

            dispatch({
                type: CHANGE_PASSWORD_SUCCESS
            });

        } catch (err) {
            dispatch({
                type: CHANGE_PASSWORD_ERROR,
                errorMessage: err.message,
                errorCode: err.code
            });
        }
    };
}
export function changePasswordInitialState() {
    return {
        type: CHANGE_PASSWORD_INITIAL_STATE
    };
}

/**
 * Welcome User
 * Submit first access to an organization
 * Only users with status pending must send password and resetPassword params
 * @param token
 * @param orgId
 * @param password
 * @param repeatPassword
 */
export const welcomeUser = (token, orgId, password, repeatPassword) => {
    return async dispatch => {
        try {

            dispatch({
                type: WELCOME_REQUEST
            });

            if (!token) return;

            // Send welcome input object
            const user = await gqlRequest({
                queryGql: AUTH_USER,
                queryType: "mutation",
                queryName: "welcome",
                variables: [{
                    type: "WelcomeInput!",
                    name: "welcomeInput",
                    data: {
                        userToken: token,
                        password: password,
                        repeatPassword: repeatPassword,
                        orgId: Number(orgId)
                    }
                }]
            });

            // Follow the same process as a user when logs in

            // Save user in Auth
            Auth.setUser(user);

            const currentOrganization = Auth.getCurrentOrganization();

            // Check language from user and current organization
            checkLanguage(user.settings.language, !!currentOrganization.settings && currentOrganization.settings.language);

            // Save in redux
            dispatch(signinSuccess(user, currentOrganization));

            // Dispatch getCurrentOrganization after signin. When a user load the app as a logged off user the current organization is set to null.
            dispatch(setCurrentOrganizationFromAuth());

            // Get user permissions from server
            dispatch(getUserPermissions());

            // Identify user in crisp
            if(window.$crisp) {
                window.$crisp.push(["set", "user:email", [user.email] ]);
                window.$crisp.push(["set", "user:nickname", [user.full_name] ]);
                window.$crisp.push(["set", "user:company", [currentOrganization.name] ]);
            }

        } catch (err) {
            dispatch({
                type: WELCOME_ERROR,
                errorMessage: err.message,
                errorCode: err.code
            });
        }
    };
};