import { Map, fromJS } from 'immutable';

import {
    DATA_CONNECTIONS_FETCH_REQUEST, DATA_CONNECTIONS_FETCH_SUCCESS, DATA_CONNECTIONS_FETCH_ERROR,
    DATA_CONNECTION_FETCH_REQUEST, DATA_CONNECTION_FETCH_SUCCESS, DATA_CONNECTION_FETCH_ERROR,
    DATA_CONNECTION_TEST_FETCH_REQUEST, DATA_CONNECTION_TEST_FETCH_SUCCESS, DATA_CONNECTION_TEST_FETCH_ERROR,
    DATA_CONNECTION_CREATE_REQUEST, DATA_CONNECTION_CREATE_SUCCESS, DATA_CONNECTION_CREATE_ERROR,
    DATA_CONNECTION_UPDATE_REQUEST, DATA_CONNECTION_UPDATE_SUCCESS, DATA_CONNECTION_UPDATE_ERROR,
    DATA_CONNECTION_CHANGE_STATUS_REQUEST, DATA_CONNECTION_CHANGE_STATUS_SUCCESS, DATA_CONNECTION_CHANGE_STATUS_ERROR,
    DATA_CONNECTION_DELETE_REQUEST, DATA_CONNECTION_DELETE_SUCCESS, DATA_CONNECTION_DELETE_ERROR,
    DATA_CONNECTION_DUPLICATE_REQUEST, DATA_CONNECTION_DUPLICATE_SUCCESS, DATA_CONNECTION_DUPLICATE_ERROR,
    DATA_CONNECTIONS_INITIALIZE, DATA_CONNECTION_LIST_INITIALIZE, DATA_CONNECTION_DETAIL_INITIALIZE, REVERSE_ENGINEERING_INITIALIZE,
    DATA_CONNECTION_REVERSE_ENGINEERING_REQUEST, DATA_CONNECTION_REVERSE_ENGINEERING_SUCCESS, DATA_CONNECTION_REVERSE_ENGINEERING_ERROR,
    OAUTH_DATA_REQUEST, OAUTH_DATA_SUCCESS, OAUTH_DATA_ERROR, OAUTH_DATA_INITIALIZE,
    DATA_CONNECTION_CONTAINERS_REQUEST, DATA_CONNECTION_CONTAINERS_SUCCESS, DATA_CONNECTION_CONTAINERS_ERROR
} from './data-connections-actions';

import {
    UPDATE_PROFILE_SUCCESS, CHANGE_OWNER_SUCCESS
} from "@biuwer/redux/src/system/security/security-actions";

// Libs
import { defaultContext } from "@biuwer/redux/src/config/constants";

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

/**
 * Data connections Reducers
 */

export default function dataConnections(state = initialState, action) {
    let newState, index, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || defaultContext}`;
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch(action.type) {
        case DATA_CONNECTIONS_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));

            return newState;
        case DATA_CONNECTION_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));

            return newState;
        case DATA_CONNECTION_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));

            return newState;
        case REVERSE_ENGINEERING_INITIALIZE:

            newState = state
                .removeIn([detail, "reverseEngineering"])
                .removeIn([detail, "reverseEngineeringIsFetching"])
                .removeIn([detail, "containers"])
                .removeIn([detail, "containersIsFetching"]);

            return newState;
        case OAUTH_DATA_INITIALIZE:
            newState = state
                .removeIn([detail, 'deleted'])
                .removeIn([detail, 'created'])
                .removeIn([detail, 'updated'])
                .setIn([detail, 'oauthData'], Map({}));

            return newState;
        case OAUTH_DATA_REQUEST:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    oauthData: Map({})
                }));

            return newState;
        case OAUTH_DATA_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    oauthData: fromJS(action.payload)
                }));
            return newState;
        case OAUTH_DATA_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    authData: fromJS(action.payload)
                }));
            return newState;
        case DATA_CONNECTIONS_FETCH_REQUEST:

            newState = state;

            // Data connections fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case DATA_CONNECTIONS_FETCH_SUCCESS:

            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case DATA_CONNECTIONS_FETCH_ERROR:

            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;
        case DATA_CONNECTION_FETCH_REQUEST:

            newState = state;

            // Data connection fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case DATA_CONNECTION_FETCH_SUCCESS:

            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));

            return newState;
        case DATA_CONNECTION_FETCH_ERROR:

            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage,
                issue: action.issue,
                issuePayload: fromJS(action.issuePayload)
            }));

            return newState;

        case DATA_CONNECTION_TEST_FETCH_REQUEST:
            newState = state
                .remove('test')
                .remove('testIsFetching')
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    testIsFetching: action.isFetching
                }));

            return newState;
        case DATA_CONNECTION_TEST_FETCH_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    testIsFetching: action.isFetching,
                    issue: action.issue,
                    test: fromJS(action.payload)
                }));
            return newState;
        case DATA_CONNECTION_TEST_FETCH_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    testIsFetching: action.isFetching,
                    issue: action.issue,
                    test: fromJS(action.issuePayload)
                }));

            return newState;
        case DATA_CONNECTION_CREATE_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case DATA_CONNECTION_CREATE_SUCCESS:

            // Update payload with new created data connection
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case DATA_CONNECTION_CREATE_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;
        case DATA_CONNECTION_UPDATE_REQUEST:
        case DATA_CONNECTION_CHANGE_STATUS_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case DATA_CONNECTION_UPDATE_SUCCESS:
        case DATA_CONNECTION_CHANGE_STATUS_SUCCESS:

            // Update payload with updated user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            error: action.error,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case DATA_CONNECTION_UPDATE_ERROR:
        case DATA_CONNECTION_CHANGE_STATUS_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;
        case DATA_CONNECTION_DELETE_REQUEST:

            newState = state;

            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));

            return newState;
        case DATA_CONNECTION_DELETE_SUCCESS:

            // Update payload with deleted data connection
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    deleted: action.deleted,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], {
                            isFetching: action.isFetching,
                            error: action.error,
                            deleted: action.deleted,
                            payload: listUpdatedPayload
                        });
                }
            }

            return newState;
        case DATA_CONNECTION_DELETE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    deleted: action.deleted
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    deleted: action.deleted
                }));

            return newState;

        case DATA_CONNECTION_DUPLICATE_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case DATA_CONNECTION_DUPLICATE_SUCCESS:

            // Update payload with new created data connection
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case DATA_CONNECTION_DUPLICATE_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;

        case DATA_CONNECTION_REVERSE_ENGINEERING_REQUEST:
            newState = state
                .removeIn([...detail, 'reverseEngineering'])
                .mergeIn([detail], {
                    reverseEngineeringIsFetching: action.isFetching,
                    issue: action.issue
                });

            return newState;
        case DATA_CONNECTION_REVERSE_ENGINEERING_SUCCESS:
            newState = state
                .mergeIn([detail], {
                    reverseEngineeringIsFetching: action.isFetching,
                    issue: action.issue,
                    reverseEngineering: fromJS(action.payload),
                    reverseEngineeringFailed: false
                });
            return newState;
        case DATA_CONNECTION_REVERSE_ENGINEERING_ERROR:
            newState = state
                .mergeIn([detail], {
                    reverseEngineeringIsFetching: action.isFetching,
                    issue: action.issue,
                    reverseEngineering: fromJS(action.issuePayload),
                    reverseEngineeringFailed: true
                });

            return newState;
        case DATA_CONNECTION_CONTAINERS_REQUEST:

            newState = state
                .removeIn([...detail, 'containers'])
                .removeIn([...detail, 'reverseEngineering'])
                .mergeIn([detail], {
                    containersIsFetching: action.isFetching,
                    issue: action.issue
                });

            // newState = state
            //     .remove('containers')
            //     .remove('reverseEngineering')
            //     .merge({
            //         containersIsFetching: action.isFetching,
            //         issue: action.issue
            //     });

            return newState;
        case DATA_CONNECTION_CONTAINERS_SUCCESS:
            newState = state
                .mergeIn([detail], {
                    containersIsFetching: action.isFetching,
                    issue: action.issue,
                    containers: fromJS(action.payload)
                });
            return newState;
        case DATA_CONNECTION_CONTAINERS_ERROR:
            newState = state
                .mergeIn([detail], {
                    containersIsFetching: action.isFetching,
                    issue: action.issue,
                    containers: fromJS(action.issuePayload)
                });

            return newState;
        case UPDATE_PROFILE_SUCCESS:
        case CHANGE_OWNER_SUCCESS:
            newState = state;

            // Check objectType before to check if proceed to update reducer data
            if (action.objectType === 'dataConnection' && action.payload && action.payload.length > 0) {
                action.payload.forEach(object => {

                    if (String(state.getIn([detail, 'payload', '_id'])) === String(object._id)) {

                        // If payload is an Immutable Map and has the same _id that the action given object info
                        // Check permissions (platform and object profile_level) before update
                        if (object.readOneAuthorization || (!!object.profile_level && object.profile_level <= 4)) {

                            //Enough permissions to read, object updated
                            newState = newState.setIn([detail, 'payload', 'profiles'], fromJS(object.profiles));
                            newState = newState.setIn([detail, 'payload', 'profile_level'], object.profile_level);
                            newState = newState.setIn([detail, 'payload', 'owner'], fromJS(object.owner));
                        } else {

                            // Not enough permissions, delete payload from reducer
                            newState = state.setIn([detail, 'payload'], null);
                        }
                    }

                    if (state.getIn([list, 'payload'])) {
                        let index = state.getIn([list, 'payload']).findIndex(connection => String(connection.get('_id')) === String(object._id));
                        if (object.changed && index !== -1) {

                            // Check permissions of the object profiles updated and platform permissions
                            if (object.readAuthorization || (!!object.profile_level && object.profile_level <= 4)) {

                                // Enough permissions to read, profiles and profile_level is updated
                                newState = newState.setIn([list, 'payload', index, 'profiles'], fromJS(object.profiles));
                                newState = newState.setIn([list, 'payload', index, 'profile_level'], object.profile_level);
                                newState = newState.setIn([list, 'payload', index, 'owner'], fromJS(object.owner));
                            } else {

                                // Not enough permissions after profiles update, exclude object from List
                                newState = state.setIn([list, 'payload'], newState.getIn([list, 'payload']).filter(connection => String(connection.get('_id')) !== String(object._id)));
                            }
                        }
                    }
                });
            }

            // // Check objectType before to check if proceed to update reducer data
            // if (action.objectType === 'dataConnection' && action.payload && action.payload.length > 0) {
            //     action.payload.forEach(object => {
            //
            //         // Check if payload is an Immutable List (array of values)
            //         if (List.isList(state.get('payload'))) {
            //             let index = state.get('payload').findIndex(page => page.get('_id') === object._id);
            //             if (object.changed && index !== -1) {
            //
            //                 // Check permissions of the object profiles updated and platform permissions
            //                 if (Auth.isAuthorized('core.dataConnection.query') || (!!object.profile_level && object.profile_level <= 4)) {
            //
            //                     // Enough permissions to read, profiles and profile_level is updated
            //                     newState = state.setIn(['payload', index, 'profiles'], fromJS(object.profiles));
            //                     newState = newState.setIn(['payload', index, 'profile_level'], object.profile_level);
            //                     newState = newState.setIn(['payload', index, 'owner'], fromJS(object.owner));
            //                 } else {
            //
            //                     // Not enough permissions after profiles update, exclude object from List
            //                     newState = state.set('payload', state.get('payload').filter(page => page.get('_id') !== object._id));
            //                 }
            //             }
            //         } else if (Map.isMap(state.get('payload')) && state.getIn(['payload', '_id']) === object._id) {
            //
            //             // If payload is an Immutable Map and has the same _id that the action given object info
            //             // Check permissions (platform and object profile_level) before update
            //             if (Auth.isAuthorized('core.dataConnection.readOne') || (!!object.profile_level && object.profile_level <= 4)) {
            //
            //                 //Enough permissions to read, object updated
            //                 newState = state.setIn(['payload', 'profiles'], fromJS(object.profiles));
            //                 newState = newState.setIn(['payload', 'profile_level'], object.profile_level);
            //                 newState = newState.setIn(['payload', 'owner'], fromJS(object.owner));
            //             } else {
            //
            //                 // Not enough permissions, delete payload from reducer
            //                 newState = state.set('payload', null);
            //             }
            //         } else {
            //             newState = state;
            //         }
            //     });
            // } else {
            //     newState = state;
            // }
            return newState;
        default:
            return state;
    }
}