import React, { Component } from 'react';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Actions
import { validateResetPassword as checkToken, resetPassword } from "@biuwer/redux/src/system/auth/password-actions";
import { initLanguage } from "@biuwer/redux/src/system/auth/session-actions";

// Components
import ResetPasswordForm from'./reset-password-form.jsx';
import NotificationMessage from '@biuwer/common/src/components/notification-message.jsx';

// Libs
import HelmetLib from "@biuwer/common/src/libs/helmet-lib";

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notificationMessage: null,
            messageType: '',
            token: null,
            userId: null,
            userEmail: null,
            otp: false
        };

        this.resetPassword = this.resetPassword.bind(this);
        this.access = this.access.bind(this);
        this.goTo = this.goTo.bind(this);
    }

    UNSAFE_componentWillMount() {
        initLanguage();

        let token = this.props.match.params.resetToken || null;

        if(token) {
            this.props.dispatch(checkToken(token));
        }

        // Check active route
        if(this.props.location.pathname === '/reset/' + token) {

            // Reset route
            this.setState({
                token: token,
                otp: false
            });
        } else if(this.props.location.pathname === '/otp/' + token) {

            // One time Login route
            this.setState({
                token: token,
                otp: true
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { t } = this.props;
        if(nextProps.checkTokenSuccess && this.state.otp) {
            this.setState({
                notificationMessage: t('auth.resetPassword.otpConfirmationMessage'),
                messageType: "success"
            });
        }

        if(nextProps.checkTokenError) {

            this.setState({
                notificationMessage: nextProps.checkTokenErrorMessage,
                messageType: "error"
            });
        }

        if(nextProps.resetPasswordSuccess) {
            this.setState({
                notificationMessage: t('auth.resetPassword.confirmationMessage'),
                messageType: "success"
            });
        }

        if(nextProps.resetPasswordError) {
            this.setState({
                notificationMessage: nextProps.resetPasswordErrorMessage,
                messageType: "error"
            });
        }
    }

    // Reset password action passed via props to Reset password form component
    resetPassword(newPassword, verifyPassword, token, userId, userEmail) {
        this.props.dispatch(resetPassword(newPassword, verifyPassword, token, userId, userEmail));
    }

    // Access to the app. Pass to component via props
    access(){
        this.props.history.push('/');
    }

    goTo(path) {
        this.props.history.push(path);
    }

    render() {
        const { t } = this.props;

        let content = null,
            notificationMessage = (
                <NotificationMessage
                    message={this.state.notificationMessage}
                    messageType={this.state.messageType}
                    otp={this.state.otp}
                    reset={true}
                    access={this.access}
                    goTo={this.goTo}
                    t={this.props.t}
                />
            );

        if(!this.props.checkTokenSuccess && !this.props.isLoggedIn){
            if(this.state.notificationMessage) {
                content = notificationMessage;
            } else {
                content = null;
            }
        } else {
            if(this.state.notificationMessage) {
                content = notificationMessage;
            } else {
                if(this.state.otp) {
                    content = notificationMessage;
                } else {
                    content = (
                        <ResetPasswordForm
                            token={this.state.token}
                            user={this.props.user}
                            success={this.props.resetPasswordSuccess}
                            resetPassword={this.resetPassword}
                            goTo={this.goTo}
                            t={this.props.t}
                        />
                    );
                }
            }
        }

        return (
            <>
                <Helmet
                    title={HelmetLib.generatePageTitle([!!this.state.otp ? t('auth.resetPassword.otpTitle') : t('auth.resetPassword.headerTitle')])}
                />
                {content}
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {

    const { t } = ownProps;

    // Reducers
    const sessionReducer = state.system.auth.session;
    const checkTokenReducer = state.system.auth.checkToken;
    const resetPasswordReducer = state.system.auth.resetPassword;

    // Session
    const isLoggedIn = sessionReducer.get('isLoggedIn');
    const userLoggedIn = sessionReducer.get('user') || Immutable.Map({});


    // Check Token
    const checkTokenIsFetching = checkTokenReducer.get('isFetching'),
        checkTokenError = checkTokenReducer.get('error'),
        checkTokenErrorCode = checkTokenReducer.get('errorCode'),
        checkTokenSuccess = checkTokenReducer.get('success'),
        checkTokenPayload = checkTokenReducer.get('payload') || Immutable.Map({});

    // Look for error code in 18n strings
    let checkTokenErrorMessage = checkTokenError ? t(`errors.${checkTokenErrorCode}`) : '';

    // Reset Password
    const resetPasswordIsFetching = resetPasswordReducer.get('isFetching'),
        resetPasswordError = resetPasswordReducer.get('error'),
        resetPasswordErrorCode = resetPasswordReducer.get('errorCode'),
        resetPasswordSuccess = resetPasswordReducer.get('success');

    // Look for error code in 18n strings
    let resetPasswordErrorMessage = resetPasswordError ? t(`errors.${resetPasswordErrorCode}`) : '';


    // Return via props
    let user = isLoggedIn ?  userLoggedIn : checkTokenPayload;

    return {
        isLoggedIn, user,
        checkTokenIsFetching, checkTokenSuccess, checkTokenError, checkTokenErrorMessage,
        resetPasswordIsFetching, resetPasswordSuccess, resetPasswordError, resetPasswordErrorMessage
    }
}

export default withTranslation()(connect(mapStateToProps)(ResetPassword));
