/**
 * Modal Dialog wrapper for atlaskit modal-dialog component (v8.0.7)
 * Props are the same that the official atlaskit modal component
 * See doc here:
 *
 * https://bitbucket.org/atlassian/atlaskit-mk-2/src/6ce8f6e6622682d22d761f9711ba1cec157b9806/packages/core/modal-dialog/?at=%40atlaskit%2Fmodal-dialog%408.0.7
 * https://atlaskit.atlassian.com/packages/core/modal-dialog
 * */
import React, { memo } from 'react';
import Modal, { ModalTransition, ModalHeader, ModalFooter, ModalBody } from '@atlaskit/modal-dialog';

export default function ModalDialog(props) {
    const { isOpen, children, width, footerHeight = 15, headingClassName = "bw-default-font" } = props;
    const modalComponents = props.components || {};

    const modalWidth = width === 'xl-large' ? '100%' : width;

    const dialogProps = {
        ...{ autoFocus: false },
        ...props,
        width: modalWidth,
        components: undefined,
        isOpen: undefined,
        children: undefined,
        heading: undefined
    };

    return (
        <ModalTransition>
            {isOpen && (
                <Modal
                    {...dialogProps}
                >
                    <HeaderDialog Component={modalComponents.Header} heading={props.heading} headingClassName={headingClassName} />
                    {children && (
                        <ModalBody>
                            {children}
                        </ModalBody>
                    )}
                    <FooterDialog Component={modalComponents.Footer} footerHeight={footerHeight} />
                </Modal>
            )}
        </ModalTransition>
    );
};

const FooterDialog = memo(function ({ Component, footerHeight }) {
    return Component ? (
        <ModalFooter>
            <Component />
        </ModalFooter>
    ) : (
        <div style={{ height: footerHeight }} />
    );
});

const HeaderDialog = memo(function ({ Component, heading, headingClassName }) {
    return Component || heading ? (
        <ModalHeader>
            {Component ?
                <Component /> :
                <div className={headingClassName} style={{ fontSize: 20, fontWeight: 500 }}>{heading}</div>
            }
        </ModalHeader>
    ) : (
        <div style={{ height: 15 }} />
    );
});