export default {
    "introduction": "Un plan de precios para cada necesidad",
    "most_popular": "¡Más Popular!",
    "currentPlan": "Plan Actual",
    "selectedPlan": "Plan Seleccionado",
    "selectPlan": "Selecciona Plan",
    "additionalProducts": "Completa tu plan",
    "selectedProduct": "Producto Seleccionado",
    "selectProduct": "Selecciona Producto",
    "per_month": "por mes",
    "per_year": "por año",
    "contact_us": "Contáctanos",
    "try_for_free": "¡Prueba gratis!",
    "free_trial": "Prueba gratuita de 30 días",
    "no_credit_card": "No se requiere tarjeta de crédito",
    "unlimited": "Ilimitado",
    "choose_billing_period": "Elige tu periodo de facturación",
    "monthly": "Mensual",
    "annual": "Anual",
    "monthly_reward": "¡Todos nuestros planes incluyen un mes gratis!",
    "annual_reward": "¡Obtén 2 meses gratis!",
    "choose_currency": "Elige tu moneda",
    "limitExceeded": "Se ha excedido el límite en alguna de las caracterísitcas que incluye el plan, ajuste la cantidad necesaria en la sección \"Completa tu plan\"",
    "plans": {
        "header_desc": "Elige el plan de Biuwer que mejor se adapte a tus necesidades.",
        "feature_intro": "Incluye las siguientes funcionalidades:",
        "basic": {
            "name": "Básico",
            "best_for": "Comienza a analizar y tomar decisiones con datos. Para pequeñas empresas y startups.",
            "feature_intro": "Incluye las funcionalidades básicas:"
        },
        "standard": {
            "name": "Estándar",
            "best_for": "Más funcionalidades y conectores para crecer con datos. Para empresas medianas.",
            "feature_intro": "Características Básicas más:"
        },
        "professional": {
            "name": "Profesional",
            "best_for": "Análisis de datos para empresas más grandes con incrustación de datos externa.",
            "feature_intro": "Características Estándar más:"
        }
    },
    "features": {
        "upload_data_file": "Sube datos desde CSV/Excel",
        "export_data_file": "Exporta a CSV, Excel, PDF",
        "page_card_filters": "Filtros de Página y Tarjeta",
        "favorites": "Contenido Favorito",
        "rbac": "Seguridad con Grupos y Roles",
        "reverse_engineering": "Ingeniería Inversa",
        "calculated_fields": "Campos Calculados",
        "dependent_filters": "Filtros Dependientes",
        "embedding": "Incrustación de datos externa",
        "custom_fields": "Campos Personalizados",
        "advanced_content_management": "Gestión de contenido",
        "row_access_level": "Acceso a nivel de fila",
        "white_labeling": "Marca blanca",
        "dedicated_server": "Servidor dedicado",
        "api_access": "Acceso al API",
        "priority_support": "Soporte prioritario"
    },
    "products": {
        "advanced_user": "Usuarios Avanzados",
        "basic_user": "Usuarios Básicos",
        "data_connection": "Conexiones de Datos",
        "data_usage": "Uso de Datos"
    },
    "included": {
        "temporarilyFree": "Por tiempo limitado, tienes uso ilimitado de datos de forma gratuita."
    }
};