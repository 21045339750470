export const MAP_CATALOG_DETAIL = `
    _id
    name
    organization
    builtin
    property_fields
    matching_fields {
        field
        meaning
    }
    i18n {
        language
        label
    }
    available_points_2d
`;

export const MAP_CATALOG_LIST = `
    _id
    name
    matching_fields {
        field
        meaning
    }
    available_points_2d
`;

export const MAP_CATALOG_FULL_DETAIL = `
    _id
    name
    organization
    builtin
    property_fields
    matching_fields {
        field
        meaning
    }
    i18n {
        language
        label
    }
    arcs
    bbox
    objects
    transform {
        scale
        translate
    }
    type
    available_points_2d
`;