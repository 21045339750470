import request from "@biuwer/common/src/libs/superagent";

import Auth from "@biuwer/redux/src/system/auth/auth-lib";

/*
 * Pages action types
 */
export const PAGE_STRUCTURES_INITIALIZE = 'PAGE_STRUCTURE_INITIALIZE';

export const PAGE_STRUCTURES_FETCH_REQUEST = 'PAGE_STRUCTURES_FETCH_REQUEST';
export const PAGE_STRUCTURES_FETCH_SUCCESS = 'PAGE_STRUCTURES_FETCH_SUCCESS';
export const PAGE_STRUCTURES_FETCH_ERROR = 'PAGE_STRUCTURES_FETCH_ERROR';

export const PAGE_STRUCTURE_FETCH_REQUEST = 'PAGE_STRUCTURE_FETCH_REQUEST';
export const PAGE_STRUCTURE_FETCH_SUCCESS = 'PAGE_STRUCTURE_FETCH_SUCCESS';
export const PAGE_STRUCTURE_FETCH_ERROR = 'PAGE_STRUCTURE_FETCH_ERROR';

export const PAGE_STRUCTURE_UPDATE_REQUEST = 'PAGE_STRUCTURE_UPDATE_REQUEST';
export const PAGE_STRUCTURE_UPDATE_SUCCESS = 'PAGE_STRUCTURE_UPDATE_SUCCESS';
export const PAGE_STRUCTURE_UPDATE_ERROR = 'PAGE_STRUCTURE_UPDATE_ERROR';

export const PAGE_STRUCTURE_CREATE_REQUEST = 'PAGE_STRUCTURES_CREATE_REQUEST';
export const PAGE_STRUCTURE_CREATE_SUCCESS = 'PAGE_STRUCTURES_CREATE_SUCCESS';
export const PAGE_STRUCTURE_CREATE_ERROR = 'PAGE_STRUCTURES_CREATE_ERROR';

export const PAGE_STRUCTURE_DELETE_REQUEST = 'PAGE_STRUCTURES_DELETE_REQUEST';
export const PAGE_STRUCTURE_DELETE_SUCCESS = 'PAGE_STRUCTURES_DELETE_SUCCESS';
export const PAGE_STRUCTURE_DELETE_ERROR = 'PAGE_STRUCTURES_DELETE_ERROR';


// Generic Returns Skeleton
const generateSkeleton = (type, body, extraArgs) => {
    let skeleton = {
        type
    };

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)){
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.issuePayload ? body.issuePayload.code : body.payload, // Backward compatibility with old error handling
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body.payload
            };

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            switch(type){
                case PAGE_STRUCTURE_CREATE_SUCCESS:
                    skeleton.created = true;

                    break;
                case PAGE_STRUCTURE_UPDATE_SUCCESS:
                    skeleton.updated = true;

                    break;
                case PAGE_STRUCTURE_DELETE_SUCCESS:
                    skeleton.deleted = true;

                    break;
                default:

                    break;
            }

            break;
        default:

            break;
    }

    return skeleton;
};

/*
 * Action creators
 */

/**
 * Initialize spaces
 */
export function initializePagesStructure() {
    return {
        type: PAGE_STRUCTURES_INITIALIZE
    };
}

/**
 * Get Pages action
 */
export function queryPagesStructures(query) {
    return dispatch => {
        let token = Auth.getLocalJwt();
        dispatch(generateSkeleton(PAGE_STRUCTURES_FETCH_REQUEST));


        dispatch(generateSkeleton(PAGE_STRUCTURES_FETCH_SUCCESS, { payload: pageStructures }));

        // TODO DELETE PAGES STRUCTURES ENDPOINTS WHEN DEVELOP-1005 MERGES
        // return request
        //     .post('/api/pageStructures/query')
        //     .set('Authorization', 'Bearer ' + token)
        //     .send({ query })
        //     .on('response', (response) => Auth.checkResponse(response))
        //     .end(function(err, res) {
        //         if (err) {
        //             dispatch(generateSkeleton(PAGE_STRUCTURES_FETCH_ERROR, res.body));
        //         } else if(res.body && res.body.status === 1) {
        //             dispatch(generateSkeleton(PAGE_STRUCTURES_FETCH_SUCCESS, res.body));
        //         }
        //     });
    };
}

/**
 * Get pageStructure action
 * @param pagesStructureId
 */

export function getPagesStructure(pagesStructureId) {
    return dispatch => {
        let token = Auth.getLocalJwt();
        dispatch(generateSkeleton(PAGE_STRUCTURE_FETCH_REQUEST));

        return request
            .get('/api/pageStructures/' + pagesStructureId)
            .set('Authorization', 'Bearer ' + token)
            .on('response', (response) => Auth.checkResponse(response))
            .end((err, res) =>{
                if (err) {
                    dispatch(generateSkeleton(PAGE_STRUCTURE_FETCH_ERROR, res.body));
                } else if ((res.body && res.body.status === 1) || res.body.status === 2) {
                    dispatch(generateSkeleton(PAGE_STRUCTURE_FETCH_SUCCESS, res.body));
                }
            });
    };
}

// TODO PROVISIONAL PAGE STRUCTURES, DELETE WHEN DEVELOP-1005 MERGES
const pageStructures = [
    {
        name: 'structure1',
        structure_img: 'structure1.png',
        structure: [
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 12,
                                xs: 12
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        name: 'structure2',
        structure_img: 'structure2.png',
        structure: [
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 12,
                                xs: 12
                            }
                        }
                    }
                ]
            },
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 12,
                                xs: 12
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        name: 'structure3',
        structure_img: 'structure3.png',
        structure: [
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 6,
                                xs: 12
                            }
                        }
                    },
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 6,
                                xs: 12
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        name: 'structure4',
        structure_img: 'structure4.png',
        structure: [
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 6,
                                xs: 12
                            }
                        }
                    },
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 6,
                                xs: 12
                            }
                        }
                    }
                ]
            },
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 12,
                                xs: 12
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        name: 'structure5',
        structure_img: 'structure5.png',
        structure: [
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 4,
                                xs: 12
                            }
                        }
                    },
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 8,
                                xs: 12
                            }
                        }
                    }
                ]
            },
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 4,
                                xs: 12
                            }
                        }
                    },
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 8,
                                xs: 12
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        name: 'structure6',
        structure_img: 'structure6.png',
        structure: [
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 8,
                                xs: 12
                            }
                        }
                    },
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 4,
                                xs: 12
                            }
                        }
                    }
                ]
            },
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 8,
                                xs: 12
                            }
                        }
                    },
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 4,
                                xs: 12
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        name: 'structure7',
        structure_img: 'structure7.png',
        structure: [
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 12,
                                xs: 12
                            }
                        }
                    }
                ]
            },
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 6,
                                xs: 12
                            }
                        }
                    },
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 6,
                                xs: 12
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        name: 'structure8',
        structure_img: 'structure8.png',
        structure: [
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 6,
                                xs: 12
                            }
                        }
                    },
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 6,
                                xs: 12
                            }
                        }
                    }
                ]
            },
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 6,
                                xs: 12
                            }
                        }
                    },
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 6,
                                xs: 12
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        name: 'structure9',
        structure_img: 'structure9.png',
        structure: [
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 4,
                                xs: 12
                            }
                        }
                    },
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 4,
                                xs: 12
                            }
                        }
                    },
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 4,
                                xs: 12
                            }
                        }
                    }
                ]
            },
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 12,
                                xs: 12
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        name: 'structure10',
        structure_img: 'structure10.png',
        structure: [
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 12,
                                xs: 12
                            }
                        }
                    }
                ]
            },
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 4,
                                xs: 12
                            }
                        }
                    },
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 4,
                                xs: 12
                            }
                        }
                    },
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 4,
                                xs: 12
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        name: 'structure11',
        structure_img: 'structure11.png',
        structure: [
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 4,
                                xs: 12
                            }
                        }
                    },
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 8,
                                xs: 12
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        name: 'structure12',
        structure_img: 'structure12.png',
        structure: [
            {
                columns: [
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 8,
                                xs: 12
                            }
                        }
                    },
                    {
                        cards: [],
                        spec: {
                            width: {
                                md: 4,
                                xs: 12
                            }
                        }
                    }
                ]
            }
        ]
    }
];