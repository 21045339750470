import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";

/*
 * Check VIES action types
 */

export const CHECK_VIES_REQUEST = 'CHECK_VIES_REQUEST';
export const CHECK_VIES_SUCCESS = 'CHECK_VIES_SUCCESS';
export const CHECK_VIES_ERROR = 'CHECK_VIES_ERROR';

// Generic Skeleton for every action
const generateSkeleton = (type, body) => {
    let skeleton = { type };

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };
            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true
            };
            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body
            };
            break;
        default:
            break;
    }

    return (dispatch) => {
        dispatch(skeleton);
    }
};

// export function checkVies({ formState = {}, formNextState = {} }) {
export function checkVies(countryCode, vatNumber) {
    return async dispatch => {
        try {

            const query = {
                query: `query($countryCode: String!, $vatNumber: String!) {
                    checkVies(countryCode: $countryCode, vatNumber: $vatNumber) {
                        valid
                        name
                        address
                    }
                }`,
                variables: {
                    countryCode,
                    vatNumber: vatNumber || ' '
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(CHECK_VIES_REQUEST, null));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(CHECK_VIES_ERROR, res.body.errors[0]));
                }
                else if (res.body && res.body.data) {
                    const checkViesResponse = res.body.data.checkVies;
                    dispatch(generateSkeleton(CHECK_VIES_SUCCESS, {
                        status: checkViesResponse && checkViesResponse.valid ? 'verified' : 'unverified',
                        verified_name: checkViesResponse && checkViesResponse.name,
                        verified_address: checkViesResponse && checkViesResponse.address
                    }));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(CHECK_VIES_ERROR, e));
        }
    };
}
