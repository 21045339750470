export default {
    "orders": {
        "title": "Pedido",
        "title_plural": "Pedidos"
    },
    "plans": {
        "currentPlan": "Plan actual",
        "currentBalance": "Balance actual",
        "balanceWarning": "Su balance actual es negativo, debe de ponerse al día con los pagos para poder volver a realizar otro pedido",
        "legacyPlanWarning": {
            "text1": "Tu organización está en un plan antiguo.",
            "text2": "Todavía puede usarlo, pero tendrá que cambiar a uno de los siguientes planes para usar nuevas funciones y tener límites actualizados."
        },
        "billed": "Facturado",
        "monthly": "Mensualmente",
        "yearly": "Anualmente",
        "purchase": "Comprar",
        "perMonth": "por mes",
        "perYear": "por año"
    },
    "checkout": {
        "title": "Confirmar pedido",
        "organizationDetailsBlock": {
            "title": "Detalles de la Organización"
        },
        "paymentMethodBlock": {
            "title": "Método de pago",
            "generalDescription1": "Al actualizar la suscripción, crearemos un documento de pedido que será enviado por email al contacto principal de la organización y a las direcciones adicionales indicadas en este pedido.",
            "generalDescription2": "También puedes consultar los datos del pedido en el apartado Pedidos y Facturas del área de administración.",
            "creditCard": {
                "title": "Tarjeta",
                "notAllowedDescription": "Para pagar mediante tarjeta es necesario que selecciones un periodo de facturación mensual.",
                "notAllowedButton": "Cambiar a Mensual"
            },
            "directDebit": {
                "title": "Domiciliación",
                "description": "Además te enviaremos el documento de Mandato SEPA completado para que puedas indicar los datos bancarios con los que realizar los adeudos mensuales. Por favor, completa este documento y devuélvenoslo siguiendo las instrucciones que te indiquemos.",
                "notAllowedDescription": "Para pagar mediante domiciliación bancaria es necesario que selecciones un periodo de facturación mensual.",
                "notAllowedButton": "Cambiar a Mensual"
            },
            "wireTransfer": {
                "title": "Transferencia",
                "description": "Los datos de cuenta bancaria, el importe total, los impuestos que apliquen y los plazos para realizar la transferencia bancaria, aparecen en el propio pedido generado.",
                "notAllowedDescription": "Para pagar mediante transferencia bancaria es necesario que selecciones un periodo de facturación anual.",
                "notAllowedButton": "Cambiar a Anual"
            }
        },
        "orderAdditionalDetailsBlock": {
            "title": "Detalles adicionales del pedido",
            "sendNotificationEmailTo": "Enviar email de notificación a",
            "orderAdditionalNotes": "Notas adicionales del pedido"
        },
        "subscriptionBlock": {
            "chooseBillingPeriod": "Elige tu periodo de facturación",
            "billingPeriod": "Periodo de facturación",
            "monthly": "Mensual",
            "annual": "Anual",
            "month": "mes",
            "year": "año",
            "currentPeriod": "Periodo actual",
            "currentSubscription": "Tu Suscripción hasta ahora",
            "totalCurrentSubscription": "Total hasta ahora",
            "subscriptionUpdates": "Modificaciones en tu suscripción",
            "totalSubscriptionUpdates": "Total hoy",
            "currentBalance": "Balance actual",
            "currentBalanceTooltip": {
                "p1": "El importe mostrado indica el balance económico actual de tu Organización en Biuwer. Lo más habitual es que el balance sea 0.",
                "p2": "Una cantidad negativa implica que hay al menos un pedido pendiente de pago.",
                "p3": "Una cantidad positiva implica que hay un saldo a favor de tu Organización que se utilizará en el siguiente pedido."
            },
            "dueTodayTooltip": {
                "p1": "El importe mostrado se calcula como una prorrata según la fecha actual respecto del periodo de facturación actual y los productos contratados.",
                "p2": "Una cantidad positiva implica una disminución del balance de tu Organización y el pago inmediato del importe mostrado.",
                "p3": "Una cantidad negativa implica un aumento del balance de tu Organización."
            },
            "newBalance": "Nuevo Balance",
            "newBalanceTooltip": {
                "p1": "El importe mostrado se calcula sumando al Balance actual el importe total hoy.",
                "p2": "Una cantidad positiva implica que hay un saldo a favor de tu Organización que se utilizará en el siguiente pedido.",
                "p3": "Una cantidad negativa implica un saldo a pagar por los productos contratados."
            },
            "newSubscription": "Así queda tu suscripción",
            "totalNewSubscription": "Nuevo Total",
            "createSubscription": "Crear suscripción",
            "updateSubscription": "Actualizar suscripción",
            "units": "Unidades",
            "taxBase": "Base imponible"
        },
        "promoCode": "Código promocional",
        "promoCodeError": "El código promocional introducido no es válido",
        "intervalError": "El código promocional introducido no se puede aplicar con el periodo de facturación actual",
    },
    "orderHistoryPage": {
        "orderOptions": {
            "payOrder": "Pagar pedido",
            "downloadOrder": "Descargar pedido (PDF)",
            "downloadInvoice": "Descargas factura (PDF)",
            "changeStatusToPaid": "Cambiar estado a pagado",
            "cancelOrder": "Cancelar pedido",
            "payNow": "Pagar ahora"
        },
        "sortOptions": {
            "created": "Fecha pedido",
            "status": "Estado pedido"
        },
        "orderCard": {
            "orderNumber": "Pedido # <0>{{orderNumber}}</0>",
            "orderAmount": "Total: <0>{{amount}}</0>",
            "orderCreated": "Fecha pedido: <0>{{created}}</0>",
            "orderStatus": "Pedido {{status}}",
            "invoiceStatus": "Factura {{status}}",
            "paymentMethod": "Método de pago: <0>{{method}}</0>",
            "orderPeriod": "Periodo: <0>{{period}}</0>",
            "amount": "Importe: <0>{{amount}}</0>",
            "currency": "Moneda: <0>{{currency}}</0>",
            "orderNumberLabel": "Número de pedido: <0>{{orderNumber}}</0>"
        },
        "manualPaymentWarning": "Requiere pago manual",
        "orderNumber": "Nº pedido",
        "paymentMethod": "Método de pago",
        "orderCreated": "Fecha pedido",
        "amount": "Total",
        "taxAmount": "IVA",
        "taxBase": "Base"
    }
};