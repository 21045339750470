import { Map, fromJS } from 'immutable';

import {
    FULL_OAUTH_INITIALIZE, OAUTH_INITIALIZE,
    OAUTH_URL_FETCH_REQUEST, OAUTH_URL_FETCH_SUCCESS, OAUTH_URL_FETCH_ERROR,
    OAUTH_CREATE_FETCH_REQUEST, OAUTH_CREATE_FETCH_SUCCESS, OAUTH_CREATE_FETCH_ERROR,
    OAUTH_GET_FETCH_REQUEST, OAUTH_GET_FETCH_SUCCESS, OAUTH_GET_FETCH_ERROR
} from './oauth-actions.js';


const initialState = Map({});

/**
 * Oauth Reducers
 */

export default function oauth(state = initialState, action) {

    let newState;

    switch (action.type) {
        case FULL_OAUTH_INITIALIZE:
            return initialState;
        case OAUTH_INITIALIZE:
            newState = state
                .remove('oauth')
                .remove('oauthIssuePayload')
                .remove('createIssuePayload')
                .remove('issue');
            return newState;
        case OAUTH_URL_FETCH_REQUEST:
            newState = state
                .remove('url')
                .remove('oauthHash')
                .merge({
                    isFetching: action.isFetching,
                    error: action.error
                });
            return newState;
        case OAUTH_URL_FETCH_SUCCESS:
            newState = state
                .merge({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    url: fromJS(action.payload.oauthUrl),
                    oauthHash: fromJS(action.payload.hash)
                });

            if (action.issue && action.issuePayload.status === 2) {
                newState = newState
                    .merge({
                        issuePayload: action.issuePayload
                    });
            }

            return newState;
        case OAUTH_URL_FETCH_ERROR:
            newState = state;
            return newState;


        case OAUTH_CREATE_FETCH_REQUEST:
            newState = state
                .remove('oauth')
                .remove('created')
                .remove('createIssuePayload')
                .merge({
                    isFetching: action.isFetching,
                    issue: action.issue
                });

            return newState;
        case OAUTH_CREATE_FETCH_SUCCESS:
            newState = state
                .merge({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    oauth:  fromJS(action.payload)
                });

            if (action.issue && action.issuePayload.status === 2) {
                newState = newState
                    .merge({
                        issuePayload: action.issuePayload
                    });
            }

            return newState;
        case OAUTH_CREATE_FETCH_ERROR:
            newState = state
                .merge({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    createIssuePayload: action.issuePayload
                });

            return newState;

        case OAUTH_GET_FETCH_REQUEST:
            newState = state
                .remove('oauth')
                .remove('created')
                .remove('issuePayload')
                .merge({
                    isFetching: action.isFetching,
                    issue: action.issue
                });

            return newState;
        case OAUTH_GET_FETCH_SUCCESS:
            newState = state
                .merge({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    oauth:  fromJS(action.payload)
                });

            if (action.issue && action.issuePayload.status === 2) {
                newState = newState
                    .merge({
                        issuePayload: action.issuePayload
                    });
            }

            return newState;
        case OAUTH_GET_FETCH_ERROR:
            newState = state
                .merge({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    oauthIssuePayload: action.issuePayload
                });

            return newState;
        default:
            return state;
    }
}
