export const AGENTS_LIST_DETAIL = `
    _id
    name
    desc
    use_type
    organization {
        _id
        name
    }
    last_connected
    max_idle_time
    status
`;

export const AGENTS_LIST = `
    _id
    name
`;