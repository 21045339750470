import React from 'react';

import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import History from "@biuwer/core/src/history";
import i18n from '@biuwer/core/src/i18n';

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import {
    GROUPS_ROLES_USER_DETAIL,
    BASIC_USER_DETAIL,
    INSTANCE_USER_ORGANIZATIONS_DIALOG
} from './users-gql';
import { defaultContext } from "@biuwer/redux/src/config/constants";

// Spaces
import { initializeSpaces, getAllSpaces, newSpacesCreated } from "@biuwer/redux/src/system/spaces/spaces-actions";
import { NEW_SPACES_LIST } from "@biuwer/redux/src/system/spaces/spaces-gql";
import spacesLib from "@biuwer/biuwer/src/spaces/spaces-lib";

/*
 * System Users action types
 */

export const USER_INITIALIZE = 'USER_INITIALIZE';
export const USER_DETAIL_INITIALIZE = 'USER_DETAIL_INITIALIZE';
export const USERS_LIST_INITIALIZE = 'USERS_LIST_INITIALIZE';

export const USERS_FETCH_REQUEST = 'USERS_FETCH_REQUEST';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const USERS_FETCH_ERROR = 'USERS_FETCH_ERROR';

export const USER_FETCH_REQUEST = 'USER_FETCH_REQUEST';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_ERROR = 'USER_FETCH_ERROR';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';

export const USER_SET_DEFAULT_ORGANIZATION_REQUEST = 'USER_SET_DEFAULT_ORGANIZATION_REQUEST';
export const USER_SET_DEFAULT_ORGANIZATION_SUCCESS = 'USER_SET_DEFAULT_ORGANIZATION_SUCCESS';
export const USER_SET_DEFAULT_ORGANIZATION_ERROR = 'USER_SET_DEFAULT_ORGANIZATION_ERROR';

export const USER_CHANGE_STATUS_REQUEST = 'USER_CHANGE_STATUS_REQUEST';
export const USER_CHANGE_STATUS_SUCCESS = 'USER_CHANGE_STATUS_SUCCESS';
export const USER_CHANGE_STATUS_ERROR = 'USER_CHANGE_STATUS_ERROR';

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_ERROR = 'USER_CREATE_ERROR';

export const USER_DELETE_FROM_ORGANIZATION_REQUEST = 'USER_DELETE_FROM_ORGANIZATION_REQUEST';
export const USER_DELETE_FROM_ORGANIZATION_SUCCESS = 'USER_DELETE_FROM_ORGANIZATION_SUCCESS';
export const USER_DELETE_FROM_ORGANIZATION_ERROR = 'USER_DELETE_FROM_ORGANIZATION_ERROR';

export const SEND_WELCOME_EMAIL_REQUEST = 'SEND_WELCOME_EMAIL_REQUEST';
export const SEND_WELCOME_EMAIL_SUCCESS = 'SEND_WELCOME_EMAIL_SUCCESS';
export const SEND_WELCOME_EMAIL_ERROR = 'SEND_WELCOME_EMAIL_ERROR';

export const CHECK_USER_EXIST_INITIALIZE = 'CHECK_USER_EXIST_INITIALIZE';
export const CHECK_USER_EXIST_REQUEST = 'CHECK_USER_EXIST_REQUEST';
export const CHECK_USER_EXIST_SUCCESS = 'CHECK_USER_EXIST_SUCCESS';
export const CHECK_USER_EXIST_ERROR = 'CHECK_USER_EXIST_ERROR';

export const USER_ADD_ORGANIZATION_REQUEST = 'USER_ADD_ORGANIZATION_REQUEST';
export const USER_ADD_ORGANIZATION_SUCCESS = 'USER_ADD_ORGANIZATION_SUCCESS';
export const USER_ADD_ORGANIZATION_ERROR = 'USER_ADD_ORGANIZATION_ERROR';

export const USER_ADD_GROUPS_REQUEST = 'USER_ADD_GROUPS_REQUEST';
export const USER_ADD_GROUPS_SUCCESS = 'USER_ADD_GROUPS_SUCCESS';
export const USER_ADD_GROUPS_ERROR = 'USER_ADD_GROUPS_ERROR';

export const USER_DELETE_GROUPS_REQUEST = 'USER_DELETE_GROUPS_REQUEST';
export const USER_DELETE_GROUPS_SUCCESS = 'USER_DELETE_GROUPS_SUCCESS';
export const USER_DELETE_GROUPS_ERROR = 'USER_DELETE_GROUPS_ERROR';

export const USER_ADD_ROLES_REQUEST = 'USER_ADD_ROLES_REQUEST';
export const USER_ADD_ROLES_SUCCESS = 'USER_ADD_ROLES_SUCCESS';
export const USER_ADD_ROLES_ERROR = 'USER_ADD_ROLES_ERROR';

export const USER_DELETE_ROLES_REQUEST = 'USER_DELETE_ROLES_REQUEST';
export const USER_DELETE_ROLES_SUCCESS = 'USER_DELETE_ROLES_SUCCESS';
export const USER_DELETE_ROLES_ERROR = 'USER_DELETE_ROLES_ERROR';

export const USER_CHANGE_ORG_STATUS_REQUEST = 'USER_CHANGE_ORG_STATUS_REQUEST';
export const USER_CHANGE_ORG_STATUS_SUCCESS = 'USER_CHANGE_ORG_STATUS_SUCCESS';
export const USER_CHANGE_ORG_STATUS_ERROR = 'USER_CHANGE_ORG_STATUS_ERROR';

export const SWITCH_USER_TO_ADVANCED_REQUEST = 'SWITCH_USER_TO_ADVANCED_REQUEST';
export const SWITCH_USER_TO_ADVANCED_SUCCESS = 'SWITCH_USER_TO_ADVANCED_SUCCESS';
export const SWITCH_USER_TO_ADVANCED_ERROR = 'SWITCH_USER_TO_ADVANCED_ERROR';

export const SWITCH_USER_TO_BASIC_REQUEST = 'SWITCH_USER_TO_BASIC_REQUEST';
export const SWITCH_USER_TO_BASIC_SUCCESS = 'SWITCH_USER_TO_BASIC_SUCCESS';
export const SWITCH_USER_TO_BASIC_ERROR = 'SWITCH_USER_TO_BASIC_ERROR';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';

// Generic Skeleton for every action
const generateSkeleton = (type, body, context = defaultContext, showNotification = true) => {
    let skeleton = { type }, notification;

    const history = History.getHistory();

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false,
                context: context
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.code,
                    code: body.statusCode,
                    message: body.message
                },
                context: context
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body,
                context: context
            };
            break;
        default:
            break;
    }
    switch (type) {
        case USER_CREATE_SUCCESS:
            notification = {
                styleType: 'custom',
                style: {
                    color: 'green'
                },
                message: (
                    <div>
                        <h5 key="userH5" style={{ color: '#ffffff' }}>
                            { i18n.t('notifications.createSuccess', {
                                name: i18n.t('admin.users.title'), context: 'male', count: 1
                            })}
                        </h5>
                        <ul key="userUl">
                            <li key="userName">
                                {i18n.t('admin.users.nameLabel')}: <strong key="userNameStrong">{ `${body.name} ${body.surnames}` }</strong>
                            </li>
                            <li key="userEmail" style={{ color: '#ffffff' }}>
                                {i18n.t('admin.users.emailLabel')}: <strong key="userEmailStrong">{ body.email }</strong>
                            </li>
                        </ul>
                    </div>
                ),
                timeout: 0
            };
            if (history.location.pathname === '/admin/users/new') {
                setTimeout(() => {
                    const locationState = history.location.state || {};
                    history.push(locationState.prev || '/admin/users');
                }, 2000);
            }
            break;
        case USER_ADD_ORGANIZATION_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.addSuccess', {
                    name: i18n.t('admin.users.title'),
                    context: 'male',
                    count: 1
                })
            };
            if (history.location.pathname === '/admin/users/new') {
                setTimeout(() => {
                    const locationState = history.location.state || {};
                    history.push(locationState.prev || '/admin/users');
                }, 2000);
            }
            break;
        case USER_UPDATE_SUCCESS:
            if (showNotification) {
                notification = {
                    styleType: 'success',
                    message: i18n.t('notifications.updateSuccess', {
                        name: i18n.t('admin.users.title'),
                        context: 'male',
                        count: 1
                    })
                };
            }
            if (history.location.pathname === `/admin/users/${body._id}/edit`) {
                setTimeout(() => {
                    const locationState = history.location.state || {};
                    history.push(locationState.prev || `/admin/users/${body._id}`);
                }, 2000);
            }
            break;
        case USER_SET_DEFAULT_ORGANIZATION_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', {
                    name: i18n.t('admin.organization.defaultLabel'),
                    context: 'female',
                    count: 1
                })
            };
            break;
        case USER_ADD_GROUPS_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.addSuccess', {
                    name: i18n.t('admin.groups.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case USER_ADD_ROLES_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.addSuccess', {
                    name: i18n.t('admin.roles.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case USER_DELETE_GROUPS_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.unassignSuccess', {
                    name: i18n.t('admin.groups.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case USER_DELETE_ROLES_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.unassignSuccess', {
                    name: i18n.t('admin.roles.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case USER_DELETE_FROM_ORGANIZATION_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', {
                    name: i18n.t('admin.users.title'),
                    context: 'male',
                    count: 1
                })
            };
            if (history.location.pathname === `/admin/users/${body._id}`) {
                history.push(`/admin/users`);
            }
            break;
        case USER_CHANGE_STATUS_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', {
                    name: i18n.t('common.statusLabel'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case USER_CHANGE_ORG_STATUS_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', {
                    name: i18n.t('common.statusLabel'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case SEND_WELCOME_EMAIL_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.sendWelcomeEmailSuccess'),
                notificationKey: 'welcomeEmail'
            };
            break;
        case SEND_WELCOME_EMAIL_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t(body.code ? `errors.${body.code}` : 'notifications.sendWelcomeEmailError'),
                notificationKey: 'welcomeEmail'
            };
            break;
        case SWITCH_USER_TO_ADVANCED_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.switchedToAdvancedSuccess', { name: body.full_name })
            };
            break;
        case SWITCH_USER_TO_BASIC_SUCCESS:
            notification = {
                styleType: "success",
                message: i18n.t('notifications.switchedToBasicSuccess', { name: body.full_name })
            }
            break
        case USER_DELETE_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', {
                    name: i18n.t('admin.users.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case USER_DELETE_FROM_ORGANIZATION_ERROR:
        case USER_CREATE_ERROR:
        case USER_UPDATE_ERROR:
        case USER_SET_DEFAULT_ORGANIZATION_ERROR:
        case USER_CHANGE_STATUS_ERROR:
        case USER_ADD_ORGANIZATION_ERROR:
        case USER_CHANGE_ORG_STATUS_ERROR:
        case USERS_FETCH_ERROR:
        case USER_DELETE_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (showNotification && notification) {
            dispatch(addNotification(notification));
        }
    }
};

/**
 * System Users: Initialize users action
 */
export function initializeUser(context = defaultContext) {
    return {
        type: USER_INITIALIZE,
        context
    };
}

/**
 * System Users: Initialize user detail action
 */
export function initializeUserDetail(context = defaultContext) {
    return {
        type: USER_DETAIL_INITIALIZE,
        context
    };
}

/**
 * System Users: Initialize users list action
 */
export function initializeUsersList(context = defaultContext) {
    return {
        type: USERS_LIST_INITIALIZE,
        context
    };
}

/**
 * System Users: Get Users List for the requester's organization
 * @param query
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export const getUsersList = (query = {}, context  = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            const graphQuery = {
                query: `query($query: QueryUserInput!) {
                    readUsers(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USERS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(graphQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(USERS_FETCH_ERROR, 'Error getting System Users data from server', context));
                } else if (res.body && res.body.data && res.body.data.readUsers) {
                    dispatch(generateSkeleton(USERS_FETCH_SUCCESS, res.body.data.readUsers, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(USERS_FETCH_ERROR, e, context));
        }
    };
};

/**
 * Get Users List for the requester's organization with minimal data
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export const listUsers = (context  = defaultContext, gql = BASIC_USER_DETAIL) => {
    return async dispatch => {
        try {

            const query = {
                query: `query {
                    listUsers {
                        ${gql}
                    }
                }`
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USERS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(USERS_FETCH_ERROR, 'Error getting System Users data from server', context));
                } else if (res.body && res.body.data && res.body.data.listUsers) {
                    dispatch(generateSkeleton(USERS_FETCH_SUCCESS, res.body.data.listUsers, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(USERS_FETCH_ERROR, e, context));
        }
    };
};

/**
 * System Users: Get All Users List action (gets all Users for the whole instance)
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export const getAllUsers = (context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            const query = {
                query: `query {
                    readAllUsers {
                        ${gql}
                    }
                }`
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USERS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(USERS_FETCH_ERROR, 'Error getting System Users data from server', context));
                } else if (res.body && res.body.data && res.body.data.readAllUsers) {
                    dispatch(generateSkeleton(USERS_FETCH_SUCCESS, res.body.data.readAllUsers, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(USERS_FETCH_ERROR, e, context));
        }
    };
};

/**
 * System Users: Create user action
 * @param userObject - Object with all necessary information to create a user
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export const createUser = (userObject, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            const query = {
                query: `mutation($userObject: NewUserInput!) {
                    createUser(user: $userObject) {
                        ${gql}
                    }
                }`,
                variables: {
                    userObject
                }
            };


            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USER_CREATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(USER_CREATE_ERROR, res.body.errors[0], context));
                } else if(res.body && res.body.data && res.body.data.createUser) {

                    dispatch(generateSkeleton(USER_CREATE_SUCCESS, res.body.data.createUser, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(USER_CREATE_ERROR, e, context));
        }
    };
};

/**
 * System Users: Get users action
 * @param userId - user to obtain
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export const getUser = (userId, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            const query = {
                query: `query($userId: Float!) {
                    readUser(_id: $userId) {
                        ${gql}
                    }
                }`,
                variables: {
                    userId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USER_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(USER_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readUser) {
                    dispatch(generateSkeleton(USER_FETCH_SUCCESS, res.body.data.readUser, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(USER_FETCH_ERROR, e, context));
        }
    };
};

/**
 * System Users: Update users action
 * @param user - Object, user to update
 * @param showNotification - Boolean to show notification after updating user (or not)
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export const updateUser = (user, showNotification = true, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            // Extract user id and delete it from user object
            let userId = user._id;
            user._id = undefined;

            const query = {
                query: `mutation($userId: Float!, $user: UpdateUserInput!) {
                    updateUser(_id: $userId, user: $user) {
                        ${gql}
                    }
                }`,
                variables: {
                    userId,
                    user
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USER_UPDATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(USER_UPDATE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.updateUser) {

                    dispatch(generateSkeleton(USER_UPDATE_SUCCESS, res.body.data.updateUser, context, showNotification));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(USER_UPDATE_ERROR, e, context));
        }
    };
};

/**
 * Set User Default Organization
 *
 * @param {number} orgId Organization id for setting as default organization
 * @param {number} userId User Id to set the default organization
 * @param {string} context redux context
 * @param {string} gql graphQL query
 */
export function setUserDefaultOrg(orgId, userId, context = defaultContext, gql = INSTANCE_USER_ORGANIZATIONS_DIALOG) {
    return async dispatch => {
        try {
            let token = Auth.getLocalJwt();

            if (orgId && userId) {

                const query = {
                    query: `mutation($userId: Float!, $orgId: Float!) {
                    changeDefaultOrg(_id: $userId, defaultOrg: $orgId) {
                        ${gql}
                    }
                }`,
                    variables: {
                        userId,
                        orgId
                    }
                };

                dispatch(generateSkeleton(USER_SET_DEFAULT_ORGANIZATION_REQUEST, null, context));

                const res = await request
                    .post('/api/gql/')
                    .send(query)
                    .set('Authorization', `Bearer ${token}`)
                    .on('response', (response) => Auth.checkResponse(response));

                if (res) {
                    if (res.body.errors && res.body.errors.length > 0) {
                        dispatch(generateSkeleton(USER_SET_DEFAULT_ORGANIZATION_ERROR, res.body.errors[0], context));
                    } else if (res.body && res.body.data && res.body.data.changeDefaultOrg) {

                        dispatch(generateSkeleton(USER_SET_DEFAULT_ORGANIZATION_SUCCESS, res.body.data.changeDefaultOrg, context));
                    }
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(USER_SET_DEFAULT_ORGANIZATION_ERROR, e, context));
        }
    };
}

/**
 * System Users: Change users status action
 * @param userId - User to change status
 * @param status - New status to assign to user
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export const changeUserStatus = (userId, status, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            const query = {
                query: `mutation($userId: Float!, $status: String!) {
                    changeUserStatus(_id: $userId, status: $status) {
                        ${gql}
                    }
                }`,
                variables: {
                    userId,
                    status
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USER_CHANGE_STATUS_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {

                    dispatch(generateSkeleton(USER_CHANGE_STATUS_ERROR, 'Error on getting System User data from server', context));
                } else if (res.body && res.body.data && res.body.data.changeUserStatus) {

                    dispatch(generateSkeleton(USER_CHANGE_STATUS_SUCCESS, res.body.data.changeUserStatus, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(USER_CHANGE_STATUS_ERROR, e, context));
        }
    };
};

/**
 * System Users: Delete users action
 * @param userId - User to delete
 * @param newOwnershipUser - User who will inherit the deleted user's content
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export const deleteUserFromOrganization = (userId, newOwnershipUser, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            const query = {
                query: `mutation($userId: Float!, $newOwnershipUser: Float!) {
                    deleteOrgFromUser(_id: $userId, newOwnerId: $newOwnershipUser) {
                        ${gql}
                    }
                }`,
                variables: {
                    userId,
                    newOwnershipUser
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USER_DELETE_FROM_ORGANIZATION_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {

                    dispatch(generateSkeleton(USER_DELETE_FROM_ORGANIZATION_ERROR, 'Error deleting user from organization', context));
                } else if (res.body && res.body.data && res.body.data.deleteOrgFromUser) {

                    dispatch(generateSkeleton(USER_DELETE_FROM_ORGANIZATION_SUCCESS, res.body.data.deleteOrgFromUser, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(USER_DELETE_FROM_ORGANIZATION_ERROR, e, context));
        }
    };
};

/**
 * Send welcome email to activation pending user
 * @param userId - User to send welcome email
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export const sendWelcomeEmail = (userId, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            const query = {
                query: `query($userId: Float!) {
                    sendWelcomeEmail(_id: $userId) {
                        message
                    }
                }`,
                variables: {
                    userId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(SEND_WELCOME_EMAIL_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(SEND_WELCOME_EMAIL_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.sendWelcomeEmail) {
                    dispatch(generateSkeleton(SEND_WELCOME_EMAIL_SUCCESS, res.body.data.sendWelcomeEmail, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(SEND_WELCOME_EMAIL_ERROR, e, context));
        }
    };
};

/**
 * Check if the user already exists in the server
 * @param email - Email to check against server
 * @param context - structure to store data in redux
 */
export const checkUserExist = (email, context = defaultContext) => {
    return async dispatch => {
        try {

            const query = {
                query: `query($email: String!) {
                    checkUserExists(email: $email) {
                        _id
                        exist
                        existInOrganization
                        status
                    }
                }`,
                variables: {
                    email
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(CHECK_USER_EXIST_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(CHECK_USER_EXIST_ERROR, 'Error checking if user exists', context));
                } else if (res.body && res.body.data && res.body.data.checkUserExists) {
                    dispatch(generateSkeleton(CHECK_USER_EXIST_SUCCESS, res.body.data.checkUserExists, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(CHECK_USER_EXIST_ERROR, e, context));
        }
    };
};

export function checkUserExistInitialize() {
    return {
        type: CHECK_USER_EXIST_INITIALIZE,
        isFetching: false,
        error: false,
        errorMessage: ''
    };
}

/**
 * Changes the organization status in a given user
 * @param userId - User to change organization status
 * @param status - New status to apply
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export const changeOrgStatusFromUser = (userId, status, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            const query = {
                query: `mutation($userId: Float!, $status: String!) {
                    changeUserOrgStatus(_id: $userId, status: $status) {
                        ${gql}
                    }
                }`,
                variables: {
                    userId,
                    status
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USER_CHANGE_ORG_STATUS_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {

                    dispatch(generateSkeleton(USER_CHANGE_ORG_STATUS_ERROR, 'Error changing organization status to user', context));
                } else if (res.body && res.body.data && res.body.data.changeUserOrgStatus) {

                    dispatch(generateSkeleton(USER_CHANGE_ORG_STATUS_SUCCESS, res.body.data.changeUserOrgStatus, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(USER_CHANGE_ORG_STATUS_ERROR, e, context));
        }
    };
};

/**
 * Adds organization to user. The organization to be added is the active organization.
 * @param userId - User to add in the active organization
 * @param userType
 * @param sendWelcomeEmail - Flag to send welcome email if user is not yet validated
 * @param status
 * @param password
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export const addOrganizationToUser = (userId, userType, sendWelcomeEmail, status, password, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            const query = {
                query: `mutation($userId: Float!, $type: String!, $sendWelcomeEmail: Boolean!, $status: String, $password: String) {
                    addOrgToUser(_id: $userId, type: $type, sendWelcomeEmail: $sendWelcomeEmail, status: $status, password: $password) {
                        ${gql}
                    }
                }`,
                variables: {
                    userId,
                    type: userType,
                    sendWelcomeEmail,
                    status,
                    password
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USER_ADD_ORGANIZATION_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {

                    dispatch(generateSkeleton(USER_ADD_ORGANIZATION_ERROR, 'Error adding organization to user', context));
                } else if (res.body && res.body.data && res.body.data.addOrgToUser) {

                    dispatch(generateSkeleton(USER_ADD_ORGANIZATION_SUCCESS, res.body.data.addOrgToUser, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(USER_ADD_ORGANIZATION_ERROR, e, context));
        }
    };
};

/**
 * System Users: Add groups to users action
 * @param userId - User to add groups to
 * @param groups - Array of groups ids to assign to user
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export const addGroupsToUser = (userId, groups, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            const query = {
                query: `mutation($userId: Float!, $groups: [Float]) {
                    addUserToGroups(_id: $userId, groups: $groups) {
                        ${gql}
                    }
                }`,
                variables: {
                    userId,
                    groups
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USER_ADD_GROUPS_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(USER_ADD_GROUPS_ERROR, 'Error adding groups to User', context));
                } else if (res.body && res.body.data && res.body.data.addUserToGroups) {
                    dispatch(generateSkeleton(USER_ADD_GROUPS_SUCCESS, res.body.data.addUserToGroups, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(USER_ADD_GROUPS_ERROR, e, context));
        }
    };
};

/**
 * System Users: Delete groups from user action
 * @param userId - User to delete groups
 * @param groups - Array of groups ids to delete from user
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export const deleteGroupsFromUser = (userId, groups, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            const query = {
                query: `mutation($userId: Float!, $groups: [Float]) {
                    deleteUserFromGroups(_id: $userId, groups: $groups) {
                        ${gql}
                    }
                }`,
                variables: {
                    userId,
                    groups
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USER_DELETE_GROUPS_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(USER_DELETE_GROUPS_ERROR, 'Error deleting groups from User', context));
                } else if (res.body && res.body.data && res.body.data.deleteUserFromGroups) {
                    dispatch(generateSkeleton(USER_DELETE_GROUPS_SUCCESS, res.body.data.deleteUserFromGroups, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(USER_DELETE_GROUPS_ERROR, e, context));
        }
    };
};

/**
 * System Users: Add roles to users action
 * @param userId - User to add roles to
 * @param roles - Array of roles ids to assign to user
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export const addRolesToUser = (userId, roles, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            const query = {
                query: `mutation($userId: Float!, $roles: [Float]) {
                    addUserToRoles(_id: $userId, roles: $roles) {
                        ${gql}
                    }
                }`,
                variables: {
                    userId,
                    roles
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USER_ADD_ROLES_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(USER_ADD_ROLES_ERROR, 'Error adding roles to User', context));
                } else if (res.body && res.body.data && res.body.data.addUserToRoles) {
                    dispatch(generateSkeleton(USER_ADD_ROLES_SUCCESS, res.body.data.addUserToRoles, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(USER_ADD_ROLES_ERROR, e, context));
        }
    };
};

/**
 * System Users: Delete roles from user action
 * @param userId - User to delete roles
 * @param roles - Array of roles ids to delete from user
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export const deleteRolesFromUser = (userId, roles, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            const query = {
                query: `mutation($userId: Float!, $roles: [Float]) {
                    deleteUserFromRoles(_id: $userId, roles: $roles) {
                        ${gql}
                    }
                }`,
                variables: {
                    userId,
                    roles
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USER_DELETE_ROLES_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(USER_DELETE_ROLES_ERROR, 'Error adding roles to User', context));
                } else if (res.body && res.body.data && res.body.data.deleteUserFromRoles) {
                    dispatch(generateSkeleton(USER_DELETE_ROLES_SUCCESS, res.body.data.deleteUserFromRoles, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(USER_DELETE_ROLES_ERROR, e, context));
        }
    };
};


/**
 * Switches a user from basic to advanced
 * @param {Number} userId User Id
 * @param {String} context Structure to store data in redux
 * @param {String} gql Query to execute
 */
export const switchUserToAdvanced = (userId, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            const query = {
                query: `mutation($userId: Float!) {
                    switchUserToAdvanced(_id: $userId) {
                        ${gql}
                    }
                }`,
                variables: {
                    userId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(SWITCH_USER_TO_ADVANCED_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(SWITCH_USER_TO_ADVANCED_ERROR, 'Error switching User from basic to advanced', context));
                } else if (res.body && res.body.data && res.body.data.switchUserToAdvanced) {
                    dispatch(generateSkeleton(SWITCH_USER_TO_ADVANCED_SUCCESS, res.body.data.switchUserToAdvanced, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(SWITCH_USER_TO_ADVANCED_ERROR, e, context));
        }
    };
};

/**
 * Switches a user from advanced to basic
 * @param {Number} userId User Id
 * @param newOwnershipUser User who will inherit the downgraded user's content
 * @param {String} context Structure to store data in redux
 * @param {String} gql Query to execute
 */
export const switchUserToBasic = (userId, newOwnershipUser, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {
            const query = {
                query: `mutation($userId: Float!, $newOwnershipUser: Float!) {
                    switchUserToBasic(_id: $userId, newOwnerId: $newOwnershipUser) {
                        ${gql}
                    }
                }`,
                variables: {
                    userId,
                    newOwnershipUser
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(SWITCH_USER_TO_BASIC_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {

                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(SWITCH_USER_TO_BASIC_ERROR, 'Error switching User from advanced to basic', context));
                } else if (res.body && res.body.data && res.body.data.switchUserToBasic) {
                    dispatch(generateSkeleton(SWITCH_USER_TO_BASIC_SUCCESS, res.body.data.switchUserToBasic, context));

                    dispatch(initializeSpaces(spacesLib.SPACES_CONTEXT));
                    dispatch(getAllSpaces(spacesLib.SPACES_CONTEXT, NEW_SPACES_LIST));
                    dispatch(newSpacesCreated(spacesLib.SPACES_CONTEXT, NEW_SPACES_LIST));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(SWITCH_USER_TO_BASIC_ERROR, err, context))
        }
    }
}

/**
 *
 * @param {Number} userId
 * @param {String} context
 * @param {String} gql
 * @returns
 */
export const activatePendingUser = (userId, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {
            const gqlQuery = {
                query: `mutation($userId: Float!) {
                    activatePendingUser(_id: $userId) {
                        ${gql}
                    }
                }`,
                variables: {
                    userId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USER_CHANGE_STATUS_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(USER_CHANGE_STATUS_ERROR, 'Error activating user', context));
                } else if (res.body && res.body.data && res.body.data.activatePendingUser) {
                    dispatch(generateSkeleton(USER_CHANGE_STATUS_SUCCESS, res.body.data.activatePendingUser, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(USER_CHANGE_STATUS_ERROR, err, context));
        }
    };
};

/**
 *
 * @param {Number} userId
 * @param {String} context
 * @param {String} gql
 * @returns
 */
export const deleteUser = (userId, context = defaultContext, gql = GROUPS_ROLES_USER_DETAIL) => {
    return async dispatch => {
        try {

            const gqlQuery = {
                query: `mutation($userId: Float!) {
                    deleteUser(_id: $userId) {
                        ${gql}
                    }
                }`,
                variables: {
                    userId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(USER_DELETE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(USER_DELETE_ERROR, 'Error deleting user', context));
                } else if (res.body && res.body.data && res.body.data.deleteUser) {
                    dispatch(generateSkeleton(USER_DELETE_SUCCESS, res.body.data.deleteUser, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(USER_DELETE_ERROR, err, context));
        }
    };
};