import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

import { stripeKey } from "./payments.lib";

const stripePromise = loadStripe(stripeKey, { apiVersion: process.env.REACT_APP_STRIPE_API_VERSION });

const StripeWrapper = ({ children }) => {

    const { i18n } = useTranslation();

    return (
        <Elements stripe={stripePromise} options={{ locale: i18n.language }}>
            {children}
        </Elements>
    );
};

export default StripeWrapper;