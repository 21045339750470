import { isEqual, extend, cloneDeep } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';

// Libs
import subscriptionsClient from '@biuwer/core/src/subscriptions-client';
import gqlRequest from '@biuwer/core/src/graphql-request';
import i18n from '@biuwer/core/src/i18n';
import { segmentIdentify, segmentTrack } from "@biuwer/core/src/segment";
import utilsLib from '@biuwer/common/src/libs/utils-lib';
import chatLib from '@biuwer/common/src/libs/chat-lib';
import { getLanguageToTranslate } from '@biuwer/common/src/libs/i18n-lib';

// Selectors
import { sessionUserSelector } from "@biuwer/redux/src/system/auth/auth-selectors";

// Auth
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import History from "@biuwer/core/src/history";
import { AUTH_USER, ORGANIZATION_STATS } from "@biuwer/redux/src/system/auth/auth-gql";

// System Variables
import { SESSION_TOKEN } from "@biuwer/redux/src/system/system-variables/system-variables-lib";
import { deleteSystemVariable } from "@biuwer/redux/src/system/system-variables/system-variables-actions";

// Notifications
import { initializeNotificationArea } from "@biuwer/redux/src/system/notification-area/notification-area-actions";
import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

// Spaces
import { initializeSpaces, getAllSpaces, newSpacesCreated, removeAllSpaces } from "@biuwer/redux/src/system/spaces/spaces-actions";
import { NEW_SPACES_LIST } from "@biuwer/redux/src/system/spaces/spaces-gql";
import spacesLib from "@biuwer/biuwer/src/spaces/spaces-lib";

// Organization
import { getCustomMessage } from "@biuwer/redux/src/system/organization-custom-messages/custom-messages-actions";

// Import biuwer constants
import {
    LANGUAGE
} from "@biuwer/core/src/constants";

// External Actions
export const REMOVE_SESSION_DATA = 'REMOVE_SESSION_DATA';

export const SET_IS_LOGGED_IN = 'IS_LOGGED_IN';
export const GET_USER_FROM_LOCAL_STORAGE = 'GET_USER_FROM_LOCAL_STORAGE';
export const UPDATE_USER_TO_REDUX = 'UPDATE_USER_TO_REDUX';
export const GET_MASQUERADE_USER_FROM_LOCAL_STORAGE = 'GET_MASQUERADE_USER_FROM_LOCAL_STORAGE';
export const GET_CURRENT_ORGANIZATION = 'GET_CURRENT_ORGANIZATION';

export const SET_DEFAULT_ORGANIZATION_REQUEST = 'SET_DEFAULT_ORGANIZATION_REQUEST';
export const SET_DEFAULT_ORGANIZATION_SUCCESS = 'SET_DEFAULT_ORGANIZATION_SUCCESS';
export const SET_DEFAULT_ORGANIZATION_ERROR = 'SET_DEFAULT_ORGANIZATION_ERROR';

export const SAVE_PROFILE_REQUEST = 'SAVE_PROFILE_REQUEST';
export const SAVE_PROFILE_SUCCESS = 'SAVE_PROFILE_SUCCESS';
export const SAVE_PROFILE_ERROR = 'SAVE_PROFILE_ERROR';

export const GET_USER_PERMISSIONS_REQUEST = 'GET_USER_PERMISSIONS_REQUEST';
export const GET_USER_PERMISSIONS_SUCCESS = 'GET_USER_PERMISSIONS_SUCCESS';
export const GET_USER_PERMISSIONS_ERROR = 'GET_USER_PERMISSIONS_ERROR';

export const GET_ORGANIZATION_STATS_REQUEST = "GET_ORGANIZATION_STATS_REQUEST";
export const GET_ORGANIZATION_STATS_SUCCESS = "GET_ORGANIZATION_STATS_SUCCESS";
export const GET_ORGANIZATION_STATS_ERROR = "GET_ORGANIZATION_STATS_ERROR";
export const NEW_ORGANIZATION_STATS = "NEW_ORGANIZATION_STATS";

export const SWITCH_ORGANIZATION_REQUEST = 'SWITCH_ORGANIZATION_REQUEST';
export const SWITCH_ORGANIZATION_SUCCESS = 'SWITCH_ORGANIZATION_SUCCESS';
export const SWITCH_ORGANIZATION_ERROR = 'SWITCH_ORGANIZATION_ERROR';

export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';
export const SIGNIN_CLEAN_ERROR = 'SIGNIN_CLEAN_ERROR';

export const MASQUERADE_USER_REQUEST = 'MASQUERADE_USER_REQUEST';
export const MASQUERADE_USER_SUCCESS = 'MASQUERADE_USER_SUCCESS';
export const MASQUERADE_USER_ERROR = 'MASQUERADE_USER_ERROR';
export const RECOVER_MASQUERADE_USER = 'RECOVER_MASQUERADE_USER';

export const SWITCH_CONTENT_LANGUAGE_REQUEST = "SWITCH_CONTENT_LANGUAGE_REQUEST";
export const SWITCH_CONTENT_LANGUAGE_SUCCESS = "SWITCH_CONTENT_LANGUAGE_SUCCESS";
export const SWITCH_CONTENT_LANGUAGE_ERROR = "SWITCH_CONTENT_LANGUAGE_ERROR";

export const SIGNOUT = 'SIGNOUT';

// Variable to save jwtValidationInterval
let jwtValidationInterval = null;

// Variable function to unsubscribe organization stats
let unsubscribeOrganizationStats = () => {};

// Setters from Auth Instance
const setIsLoggedInFromAuth = () => ({
    type: SET_IS_LOGGED_IN,
    isLoggedIn: Auth.isLoggedIn()
});
const setUserFromAuth = () => ({
    type: GET_USER_FROM_LOCAL_STORAGE,
    user: Auth.getUser()
});
export const setCurrentOrganizationFromAuth = () => ({
    type: GET_CURRENT_ORGANIZATION,
    payload: Auth.getCurrentOrganization()
});
function setMasqueradeUserFromAuth() {
    const masqueradeUser = Auth.getMasqueradeUser();
    const masqueradeLocalJwtToken = Auth.getMasqueradeLocalJwt();
    const masqueradeUserPath = Auth.getMasqueradeUserPath();
    return {
        type: GET_MASQUERADE_USER_FROM_LOCAL_STORAGE,
        masquerading: !!masqueradeUser && !!masqueradeLocalJwtToken && !!masqueradeUserPath,
        masqueradePayload: {
            user: masqueradeUser,
            token: masqueradeLocalJwtToken,
            path: masqueradeUserPath
        }
    };
};

// Check whether JWT has expired and user must be redirected to log in
// Execute periodically while user is logged in
function startJWTValidation(dispatch) {
    const JWT_VALIDATION_INTERVAL = 30000;
    jwtValidationInterval = setInterval(() => {
        const userIsLoggedIn = Auth.isLoggedIn();
        if (!userIsLoggedIn) {
            clearInterval(jwtValidationInterval);
            dispatch(signout());
        }
    }, JWT_VALIDATION_INTERVAL);
}

// Clear JWT validation interval
function stopJWTValidation() {
    clearInterval(jwtValidationInterval);
    jwtValidationInterval = null;
}

// Unsuscrible Organization Stats and initialize session reducer
function removeSessionData() {
    unsubscribeOrganizationStats();
    return {
        type: REMOVE_SESSION_DATA
    };
}


export function initLanguage(){
    // Create instance
    const urlParams = new URLSearchParams(window.location.search);

    // Get url value
    const language = urlParams.get('language');

    // Key of local storage
    const languageFromLocalStorage = Auth.getItemFromLocalStorage(LANGUAGE);

    const navigatorLanguage = window.navigator.language.split('-')[0]

    // Logic of language
    if (languageFromLocalStorage) {
        i18n.changeLanguage(languageFromLocalStorage)
    } else if (language && (["es", "en"].includes(language))) {
        i18n.changeLanguage(language)
    } else if (["es", "en"].includes(navigatorLanguage)) {
        i18n.changeLanguage(navigatorLanguage)
    }
}


/**
 * Check language from user and current organization
 * @param {String} userLang User language
 * @param {String} organizationLang User language
 */
export function checkLanguage(userLang, organizationLang) {
    function checkLang(language) {
        if (language !== i18n.language) {
            i18n.changeLanguage(language);
            moment.locale(language);
            numeral.locale(language);
        }
    }
    if (userLang !== '*') checkLang(userLang);
    else {
        if (!organizationLang) {
            const currentOrganization = Auth.getCurrentOrganization();
            organizationLang = currentOrganization && currentOrganization.settings && currentOrganization.settings.language;
        }
        checkLang(organizationLang || i18n.language);
    }
}

// Signin
export function signin(credentials) {
    return async dispatch => {
        try {

            // Check credentials
            if (credentials.username === "" || credentials.password === "") return;

            // Login on server
            const user = await gqlRequest({
                queryGql: AUTH_USER,
                queryType: "mutation",
                queryName: "signin",
                variables: [{
                    type: "SigninDataInput!",
                    name: "signinDataInput",
                    data: {
                        email: credentials.username,
                        password: credentials.password
                    }
                }]
            });

            // Save user in Auth
            Auth.setUser(user);

            // Check language from user and current organization
            const currentOrganization = Auth.getCurrentOrganization();
            checkLanguage(user?.settings?.language, currentOrganization?.settings?.language);

            dispatch(switchContentLanguage(getLanguageToTranslate()))

            Auth.setLanguage(user?.settings, currentOrganization?.settings);

            // Get Organization stats
            if (utilsLib.isBiuwerApp()) {
                dispatch(getOrganizationStats(currentOrganization && currentOrganization._id));
                dispatch(organizationStatsSubscription(currentOrganization && currentOrganization._id));
            }

            // Get organization custom message
            dispatch(getCustomMessage());

            // Get Spaces
            if (utilsLib.isBiuwerApp()) {
                dispatch(initializeSpaces(spacesLib.SPACES_CONTEXT));
                dispatch(getAllSpaces(spacesLib.SPACES_CONTEXT, NEW_SPACES_LIST));
                dispatch(newSpacesCreated(spacesLib.SPACES_CONTEXT, NEW_SPACES_LIST));
            }

            // Identify user in segment
            segmentIdentify(user);

            // Track signin success
            segmentTrack('biuwer_signin', { email: user.email, lang: i18n.language });

            // Dispatch user and current organization to redux
            dispatch(signinSuccess(user, currentOrganization));

            // Dispatch getCurrentOrganization after signin. When a user load the app as a logged off user the current organization is set to null.
            dispatch(setCurrentOrganizationFromAuth());

            // Get user permissions from server
            dispatch(getUserPermissions());

            // Start JWT token validation
            startJWTValidation(dispatch);

            // Identify user in crisp
            chatLib.identifyUserInCrisp();

        } catch (err) {
            dispatch({
                type: SIGNIN_ERROR,
                error: err
            });
        }
    };
}
/** Function to save in redux user and current organization
 * @param {Object} user logged user
 * @param {Object} currentOrganization current organization of logged user
 * @returns {Object} object with action type of login success and passed user and current organization
 */
export function signinSuccess(user, currentOrganization) {
    return {
        type: SIGNIN_SUCCESS,
        user,
        currentOrganization
    }
}
export function loginCleanError() {
    return {
        type: SIGNIN_CLEAN_ERROR
    };
}

// Logout
export function signout() {
    return async (dispatch, getState) => {

        const state = getState();
        const masqueradeUserState = state.system.auth.session.get('masqueradeUser');
        const token = masqueradeUserState?.get('masquerading')
            ? masqueradeUserState.getIn(['masqueradePayload', 'token'])
            : Auth.getLocalJwt();

        // Logout on server
        await gqlRequest({
            queryType: "mutation",
            queryName: "signout",
            token: token
        });

        // Logout on client
        await clientSignout(dispatch)

    };
}
export async function clientSignout(dispatch) {

    const history = History.getHistory();

    // Track signout
    segmentTrack('biuwer_signout');

    // Clean cached data
    await utilsLib.cleanCachedData();

    // Removes user and authToken from LocalStorage
    Auth.signout();
    stopJWTValidation();

    dispatch({
        type: SIGNOUT
    });
    dispatch(removeSessionData());
    dispatch(removeAllSpaces());
    dispatch(deleteSystemVariable(SESSION_TOKEN));
    dispatch(initializeNotificationArea());
    utilsLib.isBiuwerApp() && history.push('/signin');
}

/**
 * Update user object in Auth instance and session reducer
 * @param {Object} user
 * @returns {Object}
 */
function updateUser(user) {
    Auth.setUser(user);
    return {
        type: UPDATE_USER_TO_REDUX,
        user
    };
}

/**
 * Organization statistics
 * @param {Number} orgId
 * @returns {Function}
 * */
function getOrganizationStats(orgId) {
    return async dispatch => {
        try {

            dispatch({
                type: GET_ORGANIZATION_STATS_REQUEST
            });

            const organizationStats = await gqlRequest({
                queryName: "readOrganizationStats",
                queryGql: ORGANIZATION_STATS,
                variables: [{
                    type: "Float!",
                    name: "organization",
                    data: orgId
                }]
            });

            dispatch({
                type: GET_ORGANIZATION_STATS_SUCCESS,
                organizationStats: organizationStats
            });

        } catch (err) {
            dispatch({
                type: GET_ORGANIZATION_STATS_ERROR,
                errorMessage: err
            });
        }
    };
}

/**
 * Organization stats subscription
 * @param {Number} orgId
 * @returns {Function}
 */
function organizationStatsSubscription(orgId) {
    return async dispatch => {
        await new Promise((resolve, reject) => {
            unsubscribeOrganizationStats = subscriptionsClient.subscribe({
                query: `subscription($organization: Float!) {
                        organizationStatsSubscription(organization: $organization) {
                            ${ORGANIZATION_STATS}
                        }
                    }`,
                variables: {
                    organization: orgId
                }
            }, {
                next: (data) => dispatch({
                    type: NEW_ORGANIZATION_STATS,
                    organizationStats: data.data.organizationStatsSubscription
                }),
                error: reject,
                complete: resolve,
            });
        });
    }
}

// Get Session Properties
export function getSessionData(gql = AUTH_USER) {
    return async dispatch => {

        // Set reducer with data from Auth
        dispatch(setIsLoggedInFromAuth());
        dispatch(setUserFromAuth());
        dispatch(setCurrentOrganizationFromAuth());
        if (utilsLib.isBiuwerApp()) dispatch(setMasqueradeUserFromAuth());

        if (!Auth.isLoggedIn()) return;

        // If user is logged-in then retrieve user permissions from server
        dispatch(getUserPermissions());

        dispatch(switchContentLanguage(getLanguageToTranslate()))

        if (utilsLib.isBiuwerApp()) {

            // Start JWT token validation
            startJWTValidation(dispatch);

            // Read own user
            const user = await gqlRequest({
                queryName: "readOwn",
                queryGql: gql
            });

            // If user data changes, update it
            if (!isEqual(Auth.getUser(), user)) dispatch(updateUser(user));

            // Check language from user and current organization
            const currentOrganization = Auth.getCurrentOrganization();
            checkLanguage(user && user.settings && user.settings.language, currentOrganization && currentOrganization.settings && currentOrganization.settings.language);

            // Get Organization stats
            unsubscribeOrganizationStats();
            dispatch(getOrganizationStats(currentOrganization && currentOrganization._id));
            dispatch(organizationStatsSubscription(currentOrganization && currentOrganization._id));

            // Get Spaces
            dispatch(initializeSpaces(spacesLib.SPACES_CONTEXT));
            dispatch(getAllSpaces(spacesLib.SPACES_CONTEXT, NEW_SPACES_LIST));
            dispatch(newSpacesCreated(spacesLib.SPACES_CONTEXT, NEW_SPACES_LIST));
        }
    };
}

// Get user permissions from the backend
export function getUserPermissions() {
    return async dispatch => {
        try {

            dispatch({
                type: GET_USER_PERMISSIONS_REQUEST
            });

            // Get user permissions from server
            const permissions = await gqlRequest({
                queryName: "readUserPermissions"
            });

            dispatch({
                type: GET_USER_PERMISSIONS_SUCCESS,
                permissions: permissions
            });

        } catch (err) {
            dispatch({
                type: GET_USER_PERMISSIONS_ERROR,
                errorMessage: err
            });
        }
    };
}

/**
 * Set Default Organization
 * @param {number} orgId Organization id for setting as default organization
 * @param {number} userId User Id to set the default organization
 * @param {string} gql graphQL query
 */
export function setDefaultOrg(orgId, userId, gql = AUTH_USER) {
    return async dispatch => {
        try {

            dispatch({
                type: SET_DEFAULT_ORGANIZATION_REQUEST
            });

            // Set default organization in server, returns updated user
            const user = await gqlRequest({
                queryGql: gql,
                queryType: "mutation",
                queryName: "changeDefaultOrg",
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: Number(userId)
                }, {
                    type: "Float!",
                    name: "defaultOrg",
                    data: Number(orgId)
                }]
            });

            // Same as update user
            Auth.setUser(user);
            dispatch({
                type: SET_DEFAULT_ORGANIZATION_SUCCESS,
                payload: user
            });

        } catch (err) {
            dispatch({
                type: SET_DEFAULT_ORGANIZATION_ERROR,
                error: err
            });
        }
    };
}

/**
 * Save Profile
 * @param {object} user
 * @param {boolean} updateFavorites
 * @param {string} gql graphQL query
 */
export function saveProfile(newUser, updateFavorites = false, gql = AUTH_USER) {
    return async dispatch => {
        try {

            dispatch({
                type: SAVE_PROFILE_REQUEST
            });

            // Extract new user id and delete it from user object
            const userId = cloneDeep(newUser._id);
            newUser._id = undefined;

            // Send udpated user to server and return it
            const updateUser = await gqlRequest({
                queryGql: gql,
                queryType: "mutation",
                queryName: "updateUser",
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: Number(userId)
                }, {
                    type: "UpdateUserInput!",
                    name: "user",
                    data: newUser
                }]
            });

            // Removing data that has not been updated - Organizations, roles, groups are not updated, so the user's localStorage object must not be changed
            delete(updateUser.organizations);
            delete(updateUser.roles);
            delete(updateUser.groups);

            // Recover user from Auth instance
            const user = Auth.getUser();

            // Extend user with updated user
            const userUpdated = extend(user, updateUser);

            // Check language
            const currentOrganization = Auth.getCurrentOrganization();
            checkLanguage(userUpdated?.settings?.language, currentOrganization?.settings?.language);

            // Same as update user
            Auth.setUser(userUpdated);
            Auth.setLanguage(userUpdated?.settings, currentOrganization?.settings);
            dispatch(switchContentLanguage(getLanguageToTranslate()))

            dispatch({
                type: SAVE_PROFILE_SUCCESS,
                payload: userUpdated
            });

            // Dispatch notification on update favorites
            if (updateFavorites) {
                dispatch(addNotification({
                    styleType: 'success',
                    message: i18n.t('notifications.favoritesUpdated')
                }));
            }

            // Identify user in crisp
            chatLib.identifyUserInCrisp();

        } catch (err) {
            dispatch({
                type: SAVE_PROFILE_ERROR,
                error: err
            });
        }
    };
}

// Switch Organization
export function switchOrganization(orgId) {
    return async dispatch => {
        try {

            // Recover user
            const user = await gqlRequest({
                queryGql: AUTH_USER,
                queryType: "mutation",
                queryName: "switchOrg",
                variables: [{
                    type: "SwitchOrgInput!",
                    name: "switchOrgInput",
                    data: { organizationId: orgId }
                }]
            });

            // Save user in Auth
            Auth.setUser(user);
            dispatch(switchContentLanguage(getLanguageToTranslate()))

            const currentOrganization = Auth.getCurrentOrganization();
            dispatch({
                type: SWITCH_ORGANIZATION_SUCCESS,
                payload: currentOrganization
            });

            // Force redirect to home path and refresh all app
            window.location.href='/';

            // Identify user in crisp
            chatLib.identifyUserInCrisp();

        } catch (err) {
            dispatch({
                type: SWITCH_ORGANIZATION_ERROR,
                error: 'Error when switching organization'
            })
        }
    };
}

/**
 * Masquerade user
 * @param {string} userId to masquerade in
 * @param organization
 * @param path
 * @param redirectPath
 */
export function masqueradeUser(userId, organization, path, redirectPath = '/user/profile') {
    return async (dispatch, getState) => {
        try {
            const history = History.getHistory();
            const token = Auth.getLocalJwt();
            const state = getState();
            const user = state.system.auth.session.get('user').toJS();

            if (!token) return;
            dispatch({
                type: MASQUERADE_USER_REQUEST
            });

            // Recover masquerade user
            const masqueradeUser = await gqlRequest({
                queryGql: AUTH_USER,
                queryType: "mutation",
                queryName: "masqueradeUser",
                variables: [{
                    type: "MasqueradeUserInput!",
                    name: "masqueradeUserInput",
                    data: {
                        userId: userId,
                        organizationId: organization
                    }
                }]
            });

            // Save user in auth
            Auth.setUser(masqueradeUser);

            // Clean cached data
            await utilsLib.cleanCachedData();

            // Save into localStorage masquerade user and token
            Auth.setMasqueradeUser(user);
            Auth.setMasqueradeUserJwtToken(token);
            Auth.setMasqueradeUserPath(path);

            const currentOrganization = Auth.getCurrentOrganization();

            // Dispatch masquerade user and current organization to redux
            dispatch(signinSuccess(masqueradeUser, currentOrganization));

            // Dispatch getCurrentOrganization after signin. When a user load the app as a logged off user the current organization is set to null.
            dispatch(setCurrentOrganizationFromAuth());

            // Get session data
            dispatch(getSessionData());

            // Identify user in crisp
            chatLib.identifyUserInCrisp()

            dispatch({
                type: MASQUERADE_USER_SUCCESS,
                masqueradePayload: {
                    user: user,
                    token: token,
                    path: path
                }
            });

            // Redirect
            history.push(redirectPath);

        } catch (err) {
            dispatch({
                type: MASQUERADE_USER_ERROR,
                isFetching: false,
                error: true,
                errorMessage: 'Error masquerading user'
            })
        }
    };
}

/**
 * Recover masquerade user from redux
 */
export function recoverMasqueradeUser() {
    return async (dispatch, getState) => {

        const history = History.getHistory();
        const state = getState();
        const masqueradeUserState = state.system.auth.session.get('masqueradeUser');

        if (!masqueradeUserState || !masqueradeUserState.get('masquerading')) return;

        const user = masqueradeUserState.getIn(['masqueradePayload', 'user']);
        const token = masqueradeUserState.getIn(['masqueradePayload', 'token']);
        const path = masqueradeUserState.getIn(['masqueradePayload', 'path']);

        if (!user || !token) return;

        // Save the jwt token and user into local store
        Auth.setLocalJwt(token);
        Auth.setUser(user);

        dispatch(getSessionData());

        dispatch({
            type: RECOVER_MASQUERADE_USER
        });

        // Remove from localStorage masquerade user and token
        Auth.deleteMasqueradeData();

        // Clean cached data
        await utilsLib.cleanCachedData();

        // Identify user in crisp
        chatLib.identifyUserInCrisp()

        // Redirect to path after recover masquerade user
        history.push(path);

    };
}

export const useSessionActions = () => {

    const dispatch = useDispatch();
    const sessionUser = useSelector(sessionUserSelector);

    // Helper functions
    const getNewUserFavorites = (user) => {
        let newFavorites = user && cloneDeep(user.get("favorites"));
        return newFavorites && newFavorites.toJS();
    };
    const updateUserFavorites = (user, newUserFavorites) => user && newUserFavorites && saveProfile({ _id: user.get("_id"), favorites: newUserFavorites }, true);

    return {
        addFavorite: (id, favoriteArrayName) => {
            const newFavorites = getNewUserFavorites(sessionUser);
            newFavorites[favoriteArrayName].push(id);
            dispatch(updateUserFavorites(sessionUser, newFavorites));
        },
        removeFavorite: (id, favoriteArrayName) => {
            const newFavorites = getNewUserFavorites(sessionUser);
            const favoriteIndex = newFavorites[favoriteArrayName].findIndex(favoriteId => favoriteId === id);
            if (favoriteIndex === -1) return;
            newFavorites[favoriteArrayName].splice(favoriteIndex, 1);
            dispatch(updateUserFavorites(sessionUser, newFavorites));
        }
    };

};

// Switch content language
export function switchContentLanguage(lang) {
    return async dispatch => {
        dispatch({
            type: SWITCH_CONTENT_LANGUAGE_SUCCESS,
            payload: lang
        });
    };
}
