import i18n from '@biuwer/core/src/i18n';
import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import { defaultContext } from "@biuwer/redux/src/config/constants";
import gqlRequest from '@biuwer/core/src/graphql-request';

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { DATA_CONNECTIONS_TYPES_LIST_DETAIL } from './data-connection-types-gql';

/*
 * Datamodel action types
 */

export const DATA_CONNECTION_TYPES_INITIALIZE = 'DATA_CONNECTION_TYPES_INITIALIZE';
export const DATA_CONNECTION_TYPES_INITIALIZE_LIST = 'DATA_CONNECTION_TYPES_INITIALIZE_LIST';
export const DATA_CONNECTION_TYPES_INITIALIZE_DETAIL = 'DATA_CONNECTION_TYPES_INITIALIZE_DETAIL';

export const DATA_CONNECTION_TYPES_FETCH_REQUEST = 'DATA_CONNECTION_TYPES_FETCH_REQUEST';
export const DATA_CONNECTION_TYPES_FETCH_SUCCESS = 'DATA_CONNECTION_TYPES_FETCH_SUCCESS';
export const DATA_CONNECTION_TYPES_FETCH_ERROR = 'DATA_CONNECTION_TYPES_FETCH_ERROR';

export const DATA_CONNECTION_TYPE_FETCH_REQUEST = 'DATA_CONNECTION_TYPE_FETCH_REQUEST';
export const DATA_CONNECTION_TYPE_FETCH_SUCCESS = 'DATA_CONNECTION_TYPE_FETCH_SUCCESS';
export const DATA_CONNECTION_TYPE_FETCH_ERROR = 'DATA_CONNECTION_TYPE_FETCH_ERROR';


// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, showNotification = true) => {
    let skeleton = { type, context }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)){
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.issuePayload ? body.issuePayload.code : body.payload, // Backward compatibility with old error handling
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body
            };

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:

            break;
    }

    switch (type) {
        case DATA_CONNECTION_TYPES_FETCH_ERROR:
        case DATA_CONNECTION_TYPE_FETCH_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    }
};

/*
 * Action creators
 */

/**
 * Initialize data connection types
 */
export const initializeDataConnectionTypes = (context = defaultContext) => {
    return {
        type: DATA_CONNECTION_TYPES_INITIALIZE,
        context: context
    };
};

export const initializeDataConnectionTypesList = (context = defaultContext) => {
    return {
        type: DATA_CONNECTION_TYPES_INITIALIZE_LIST,
        context: context
    };
};

export const initializeDataConnectionTypesDetail = (context = defaultContext) => {
    return {
        type: DATA_CONNECTION_TYPES_INITIALIZE,
        context: context
    };
};

/**
 * Get data connection types action
 */
export const getDataConnectionTypes = (query = {}, context = defaultContext, gql = DATA_CONNECTIONS_TYPES_LIST_DETAIL) => {

    return async (dispatch) => {

        try {

            const gqlQuery = {
                query: `query($query: QueryDataConnectionTypeInput!) {
                    readDataConnectionTypes(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query: query
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(DATA_CONNECTION_TYPES_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set("app", Auth.getAuthApp())
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body && res.body.data && res.body.data.readDataConnectionTypes) {
                    dispatch(generateSkeleton(DATA_CONNECTION_TYPES_FETCH_SUCCESS, res.body.data.readDataConnectionTypes, context));
                }
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(DATA_CONNECTION_TYPES_FETCH_ERROR, res.body.errors[0], context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(DATA_CONNECTION_TYPES_FETCH_ERROR, err, context));

        }
    };
};

export const getDataConnectionType = (dataConnectionTypeId, context = defaultContext, gql = DATA_CONNECTIONS_TYPES_LIST_DETAIL) => {
    return async (dispatch) => {

        try {
            dispatch(generateSkeleton(DATA_CONNECTION_TYPE_FETCH_REQUEST, null, context));

            const dataConnectionType = await gqlRequest({
                queryName: "readDataConnectionType",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: dataConnectionTypeId
                }]
            });

            dispatch(generateSkeleton(DATA_CONNECTION_TYPE_FETCH_SUCCESS, dataConnectionType, context));
        } catch(err) {
            dispatch(generateSkeleton(DATA_CONNECTION_TYPE_FETCH_ERROR, err, context));
        }
    };
};