export default {
    "payments": {
        "declined": "Your card has been declined",
        "incomplete_card": "Please, fill your card's data",
        "incomplete_cvc": "Your card's security code is incomplete",
        "incomplete_expiry": "Your card's expiration date is incomplete",
        "incomplete_number": "Your card number is incomplete",
        "expired_card": "Your card has expired",
        "card_declined_card_not_supported": "This card is not supported",
        "invalid_brand": "This card brand is not supported",
        "incorrect_cvc": "Your card's security code is incorrect",
        "incorrect_number": "Your card's number is incorrect",
        "invalid_cvc": "Your card's security code is invalid",
        "invalid_expiry_month": "Your card's expiration date in invalid",
        "invalid_expiry_month_past": "Your card's expiration date is in the past",
        "invalid_expiry_year": "Your card's expiration year is invalid",
        "invalid_expiry_year_past": "Your card's expiration year is in the past",
        "invalid_number": "Your card's number is invalid",
        "unexpected": "An unexpected error has occurred",
        "authentication_failure": "We couldn't authenticate the payment card",
        "payment_intent_authentication_failure": "We couldn't authenticate the payment card",
        "setup_intent_authentication_failure": "We couldn't authenticate the payment card",
        "insufficient_funds": "Card has not enough funds"
    },
    "unknownError": "An error has occurred. Contact support or your primary contact at Biuwer",
    "errorMessage": "Error message",
    "5": "Requested email does not exists",
    "6": "Invalid password",
    "7": "User information provided is not enough. Contact your administrator",
    "8": "Error generating token. Contact your administrator",
    "120": "User is not active. Contact your administrator",
    "121": "Email field is mandatory",
    "122": "Access request has expired or does not exist. Request a new password please",
    "123": "You must provide user ID and email to change the password",
    "124": "Passwords don't match",
    "125": "User is not logged in",
    "126": "Insert a new password please",
    "127": "User not found",
    "128": "Current password is invalid",
    "130": "Processing error. Contact your administrator",
    "11000": "{{varName}} is in use",
    "10000007": "User organization is not active or does not exist",
    "queryDataError": "Error querying data",
    "USER_EXISTS": "User already registered. Contact your administrator",
    "SIGNUP_TOKEN_EXPIRED_NOT_EXIST": "Expired or invalid token",
    "DATASET_FIELD_DEPENDENCIES": "One of the unselected fields from dataset is being used",
    "EMAIL_DOMAIN_NOT_VALID": "Email domain not valid",
    "REQUEST_TOO_RECENT": "This request has been made recently. If you want to repeat it, try in a few minutes",
    "ORDER_ALREADY_PAID": "Order has been already paid",
    "UNABLE_RECOVER_ORDER": "Order not found",
    "ErrorBoundary": {
        "title": "Ooops! Something has gone wrong",
        "message": "Please try again. If you need help, do not hesitate to contact us through the online chat."
    },
}