import Dexie from "dexie";
import uuid from "uuid/v1";

// Libs
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import gqlRequest from "./graphql-request";

// Graphql
import { MAP_CATALOG_FULL_DETAIL } from "@biuwer/redux/src/maps/map-catalog/map-catalog-gql";

// IndexedDB instance
let instance = null;

class IndexedDB {

    constructor() {

        // Table names
        this.DATAMODEL_QUERY_CARDS = "datamodelQueryCards";
        this.DATAMODEL_QUERY_CARD_QUERIES = "datamodelQueryCardQueries";
        this.DATAMODEL_QUERY_CARD_QUERIES_DATA = "datamodelQueryCardQueriesData";
        this.DATAMODEL_QUERY_CARD_QUERIES_DATAWARNINGS = "datamodelQueryCardQueriesDataWarnings";
        this.DATAMODEL_QUERY_CARD_QUERIES_DATAERRORS = "datamodelQueryCardQueriesDataErrors";
        this.MAP_CATALOG = "mapCatalog";

        // Time to live
        // TODO: Add logic to modify TTL based on organization/user config
        this.TTL = 30 * 60 * 1000;

        if(!instance){
            instance = this;
        }
        return instance;
    }

    // Method to initialize indexedDB
    async initDB(dbName = "BiuwerDB") {

        // Delete previous DB version
        // this.db = new Dexie(dbName);
        this.db = new Dexie(`${dbName}_211007`);
        await this.db.delete();
        this.db = new Dexie(`${dbName}_biuwer-1221_v1`);
        await this.db.delete();
        this.db = new Dexie(`${dbName}_biuwer-1256_v1`);
        await this.db.delete();
        this.db = new Dexie(`${dbName}_3_5_0`);
        await this.db.delete();

        // Create actual DB with release date version
        this.db = new Dexie(`${dbName}_3_6_0`);

        // Set tables
        // this.db.version(1).stores({ datamodelQueryCards: "id" });
        this.db.version(1).stores({
            [this.DATAMODEL_QUERY_CARDS]: "id, [collectionId+pageId], [collectionId+pageId+cardId]",
            [this.DATAMODEL_QUERY_CARD_QUERIES]: "id, [datamodelQueryCardId+isActive], [datamodelQueryCardId+queryIds+refQueryIds+datamodel+fields+filters+options+format]",
            // [this.DATAMODEL_QUERY_CARD_QUERIES]: "id, [datamodelQueryCardId+isActive], [datamodelQueryCardId+datamodel+fields+filters+options+format+dataCardQueries]",
            [this.DATAMODEL_QUERY_CARD_QUERIES_DATA]: "id, datamodelQueryCardQueryId",
            [this.DATAMODEL_QUERY_CARD_QUERIES_DATAWARNINGS]: "id, datamodelQueryCardQueryId",
            [this.DATAMODEL_QUERY_CARD_QUERIES_DATAERRORS]: "id, datamodelQueryCardQueryId",
            [this.MAP_CATALOG]: "id, mapCatalogId",
        });

        // Clear tables when loads app
        await this.clearDB();

        return true;

    }

    /** GET DATA METHODS */

    async getDatamodelQueryCard(datamodelQueryCardFilters = {}) {
        return await this.db[this.DATAMODEL_QUERY_CARDS].get(datamodelQueryCardFilters);
    }

    async getDatamodelQueryCards(datamodelQueryCardFilters = {}) {
        return await this.db[this.DATAMODEL_QUERY_CARDS].where(datamodelQueryCardFilters).toArray() || [];
    }

    async getDatamodelQueryCardQuery(datamodelQueryCardQueryFilters = {}) {
        let datamodelQueryCardQuery = await this.db[this.DATAMODEL_QUERY_CARD_QUERIES].get(datamodelQueryCardQueryFilters);

        // Clear data when ttl is over
        if (!!datamodelQueryCardQuery && (datamodelQueryCardQuery.timestamp + this.TTL) < Date.now()) {
            await this.clearDatamodelQueryCardQuery(datamodelQueryCardQuery.id);
            await this.clearDatamodelQueryCardQueryData(datamodelQueryCardQuery.id);
            await this.clearDatamodelQueryCardQueryDataWarnings(datamodelQueryCardQuery.id);
            await this.clearDatamodelQueryCardQueryDataErrors(datamodelQueryCardQuery.id);
            datamodelQueryCardQuery = undefined;
        }

        return datamodelQueryCardQuery;
    }

    async getDatamodelQueryCardQueries(datamodelQueryCards) {
        return await this.db[this.DATAMODEL_QUERY_CARD_QUERIES]
            .where("[datamodelQueryCardId+isActive]")
            .anyOf(datamodelQueryCards.map(datamodelQueryCard => ([datamodelQueryCard.id, "true"])))
            .toArray() || [];
    }

    async getDatamodelQueryCardQueryFull(datamodelQueryCardQueryFilters) {
        const datamodelQueryCardQuery = await this.getDatamodelQueryCardQuery(datamodelQueryCardQueryFilters);
        if (!datamodelQueryCardQuery) return undefined;
        const datamodelQueryCardQueryData = await this.db[this.DATAMODEL_QUERY_CARD_QUERIES_DATA].get({ datamodelQueryCardQueryId: datamodelQueryCardQuery.id }) || {};
        const datamodelQueryCardQueryDataWarnings = await this.db[this.DATAMODEL_QUERY_CARD_QUERIES_DATAWARNINGS].get({ datamodelQueryCardQueryId: datamodelQueryCardQuery.id }) || {};
        const datamodelQueryCardQueryDataErrors = await this.db[this.DATAMODEL_QUERY_CARD_QUERIES_DATAERRORS].get({ datamodelQueryCardQueryId: datamodelQueryCardQuery.id }) || {};
        return {
            ...datamodelQueryCardQuery,
            data: datamodelQueryCardQueryData.data,
            dataWarings: datamodelQueryCardQueryDataWarnings.dataWarnings,
            dataErrors: datamodelQueryCardQueryDataErrors.dataErrors
        }
    }

    async getDatamodelQueryCardQueriesData(datamodelQueryCardFilters = {}) {
        const datamodelQueryCards = await this.getDatamodelQueryCards(datamodelQueryCardFilters);
        const datamodelQueryCardQueries = await this.getDatamodelQueryCardQueries(datamodelQueryCards);
        const datamodelQueryCardQueriesData = await this.db[this.DATAMODEL_QUERY_CARD_QUERIES_DATA].where("datamodelQueryCardQueryId").anyOf(datamodelQueryCardQueries.map(cardQuery => cardQuery.id)).toArray() || []
        return datamodelQueryCardQueriesData.map(cardQueryData => {
            const datamodelQueryCardQuery = datamodelQueryCardQueries.find(cardQuery => cardQuery.id === cardQueryData.datamodelQueryCardQueryId) || {};
            const datamodelQueryCard = datamodelQueryCards.find(card => card.id === datamodelQueryCardQuery.datamodelQueryCardId) || {};
            return {
                ...cardQueryData,
                cardId: datamodelQueryCard.cardId
            }
        });
    }

    async getDatamodelQueryCardQueriesDataWarnings(datamodelQueryCardFilters = {}) {
        const datamodelQueryCards = await this.getDatamodelQueryCards(datamodelQueryCardFilters);
        const datamodelQueryCardQueries = await this.getDatamodelQueryCardQueries(datamodelQueryCards);
        const datamodelQueryCardQueriesDataWarnings = await this.db[this.DATAMODEL_QUERY_CARD_QUERIES_DATAWARNINGS].where("datamodelQueryCardQueryId").anyOf(datamodelQueryCardQueries.map(cardQuery => cardQuery.id)).toArray() || []
        return datamodelQueryCardQueriesDataWarnings.map(cardQueryDataWarnings => {
            const datamodelQueryCardQuery = datamodelQueryCardQueries.find(cardQuery => cardQuery.id === cardQueryDataWarnings.datamodelQueryCardQueryId) || {};
            const datamodelQueryCard = datamodelQueryCards.find(card => card.id === datamodelQueryCardQuery.datamodelQueryCardId) || {};
            return {
                ...cardQueryDataWarnings,
                cardId: datamodelQueryCard.cardId
            }
        });
    }

    async getMapCatalog(mapCatalogId) {
        return await this.db[this.MAP_CATALOG].get({ mapCatalogId });
    }

    /** ADD DATA METHODS */

    async addRowToTable(tableName, rowContent) {
        return await this.db[tableName].add({ id: uuid(), ...rowContent });
    }

    async addDatamodelQueryCard(datamodelQueryCard) {
        return await this.addRowToTable(this.DATAMODEL_QUERY_CARDS, datamodelQueryCard);
    }

    async addDatamodelQueryCardQuery(datamodelQueryCardQuery) {
        return await this.addRowToTable(this.DATAMODEL_QUERY_CARD_QUERIES, datamodelQueryCardQuery);
    }

    async addDatamodelQueryCardQueryData(datamodelQueryCardQueryData) {
        return await this.addRowToTable(this.DATAMODEL_QUERY_CARD_QUERIES_DATA, datamodelQueryCardQueryData);
    }

    async addDatamodelQueryCardQueryDataWarnings(datamodelQueryCardQueryDataWarnings) {
        return await this.addRowToTable(this.DATAMODEL_QUERY_CARD_QUERIES_DATAWARNINGS, datamodelQueryCardQueryDataWarnings);
    }

    async addDatamodelQueryCardQueryDataErrors(datamodelQueryCardQueryDataErrors) {
        return await this.addRowToTable(this.DATAMODEL_QUERY_CARD_QUERIES_DATAERRORS, datamodelQueryCardQueryDataErrors);
    }

    async addMapCatalog(mapCatalog) {
        return await this.addRowToTable(this.MAP_CATALOG, mapCatalog);
    }

    /** UPDATE DATA METHODS */

    async updateDatamodelQueryCardQuery(datamodelQueryCardQueryId, rowContent) {
        return await this.db[this.DATAMODEL_QUERY_CARD_QUERIES].update(datamodelQueryCardQueryId, rowContent);
    }

    async setDatamodelQueryCardQueriesInactive(datamodelQueryCardId) {
        return await this.db[this.DATAMODEL_QUERY_CARD_QUERIES].where({ datamodelQueryCardId }).modify({ isActive: "false" });
    }

    /** DELETE DATA METHODS */

    async clearDB() {
        await this.clearDatamodelQueryCardQueries();
        await this.clearDatamodelQueryCardQueriesData();
        await this.clearDatamodelQueryCardQueriesDataWarnings();
        await this.clearDatamodelQueryCardQueriesDataErrors();
        await this.clearMapCatalog();
    }

    async clearTable(tableName) {
        return await this.db[tableName].clear();
    }

    async clearDatamodelQueryCardQueries() {
        return await this.clearTable(this.DATAMODEL_QUERY_CARD_QUERIES);
    }

    async clearDatamodelQueryCardQueriesData() {
        return await this.clearTable(this.DATAMODEL_QUERY_CARD_QUERIES_DATA);
    }

    async clearDatamodelQueryCardQueriesDataWarnings() {
        return await this.clearTable(this.DATAMODEL_QUERY_CARD_QUERIES_DATAWARNINGS);
    }

    async clearDatamodelQueryCardQueriesDataErrors() {
        return await this.clearTable(this.DATAMODEL_QUERY_CARD_QUERIES_DATAERRORS);
    }

    async clearMapCatalog() {
        return await this.clearTable(this.MAP_CATALOG);
    }

    async clearDatamodelQueryCardQuery(datamodelQueryCardQueryId) {
        return await this.db[this.DATAMODEL_QUERY_CARD_QUERIES].where({ id: datamodelQueryCardQueryId }).delete();
    }

    async clearDatamodelQueryCardQueryData(datamodelQueryCardQueryId) {
        return await this.db[this.DATAMODEL_QUERY_CARD_QUERIES_DATA].where({ datamodelQueryCardQueryId: datamodelQueryCardQueryId }).delete();
    }

    async clearDatamodelQueryCardQueryDataWarnings(datamodelQueryCardQueryId) {
        return await this.db[this.DATAMODEL_QUERY_CARD_QUERIES_DATAWARNINGS].where({ datamodelQueryCardQueryId: datamodelQueryCardQueryId }).delete();
    }

    async clearDatamodelQueryCardQueryDataErrors(datamodelQueryCardQueryId) {
        return await this.db[this.DATAMODEL_QUERY_CARD_QUERIES_DATAERRORS].where({ datamodelQueryCardQueryId: datamodelQueryCardQueryId }).delete();
    }

    // Method to get data from server
    async fetchDatamodelQueryCard(datamodelQueryCardFilters, datamodelQueryCardQueryFilters, origin) {

        const parsedDatamodelQueryCardFilters = Object.entries(datamodelQueryCardFilters).reduce((acc, [key, value]) => {
            acc[key] = value === "null" ? null : value;
            return acc;
        }, {});

        // Get datamodelQueryCard
        const datamodelQueryCard = await this.getDatamodelQueryCard(datamodelQueryCardFilters);
        const datamodelQueryCardId = (datamodelQueryCard && datamodelQueryCard.id) || await this.addDatamodelQueryCard(datamodelQueryCardFilters);

        // Set isActive false to all other card queries
        await this.setDatamodelQueryCardQueriesInactive(datamodelQueryCardId);

        // Add datamodelQueryCardQuery
        const datamodelQueryCardQueryId = await this.addDatamodelQueryCardQuery({
            datamodelQueryCardId: datamodelQueryCardId,
            ...datamodelQueryCardQueryFilters,
            isActive: "true",
            timestamp: Date.now()
        });

        // Get data from server
        try {
            const token = Auth.getLocalJwt();
            const { data, dataWarnings, dataErrors } = await gqlRequest({
                queryName: "queryDatamodelCard",
                token: token,
                variables: [{
                    type: "DataQueryDatamodelCardInput!",
                    name: "datamodelCardInput",
                    data: {
                        ...parsedDatamodelQueryCardFilters,
                        ...datamodelQueryCardQueryFilters,
                        origin
                    }
                }]
            });

            await this.addDatamodelQueryCardQueryData({ datamodelQueryCardQueryId, data: data });
            await this.addDatamodelQueryCardQueryDataWarnings({ datamodelQueryCardQueryId, dataWarnings: dataWarnings });
            await this.addDatamodelQueryCardQueryDataErrors({ datamodelQueryCardQueryId, dataErrors: dataErrors });

        } catch (err) {
            await this.updateDatamodelQueryCardQuery(datamodelQueryCardQueryId, { error: err });
        }

        return await this.getDatamodelQueryCardQueryFull(datamodelQueryCardQueryId);
    }

    // Method to get map catalogs from server
    async fetchMapCatalogs(mapCatalogIds) {

        // Get data from server
        try {
            const token = Auth.getLocalJwt();
            const res = await gqlRequest({
                queryName: "queryMapCatalog",
                queryGql: MAP_CATALOG_FULL_DETAIL,
                token: token,
                variables: [{
                    type: "QueryMapCatalogInput!",
                    name: "query",
                    data: { _id: { $in: mapCatalogIds } }
                }]
            });

            if (Array.isArray(res)) {
                for (let mapCatalog of res) {
                    await this.addMapCatalog({ mapCatalogId: mapCatalog._id, data: mapCatalog });
                }
            }
        } catch (err) {
            // TODO
        }
    }

}

export default new IndexedDB();