import { Fragment, useCallback } from "react";
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import classnames from 'classnames';
import { red } from 'material-colors/dist/colors';
import uuid from 'uuid/v1';

export default function TextInput({
    type = 'text',
    name,
    className,
    style,
    labelStyle,
    inputStyle,
    labelText,
    value,
    errorText,
    helpText,
    disabled = false,
    formStyling = 'traditional',
    required = false,
    ignoreFormGroupClass = false,
    inputClassName,
    min,
    max,
    placeholder,
    disableAutofill = false,
    onChange = () => null,
    onKeyDown = () => null,
    onBlur = () => null,
    addons = [],
    autoFocus
}) {

    const textInputId = uuid();

    const onChangeCallback = useCallback((event) => {
        if (type !== "number") return onChange(event)

        let value = event.target.value
        if (min != null && Number(min) > Number(value)) value = min
        if (max != null && Number(max) < Number(value)) value = max
        event.target.value = value
        onChange(event)
    }, [min, max, type, onChange])

    // Styles
    const styles = {
        root: merge({}, style),
        input: merge({}, inputStyle),
        label: merge({}, labelStyle),
        required: {
            color: red[500]
        }
    };

    // Elements
    const labelElement = labelText && (
        <label style={styles.label}>
            {labelText}
            {required && formStyling === 'traditional' && <span style={styles.required}>*</span>}
        </label>
    );

    const errorElement = errorText && <span className="help-block text-danger fs11">{errorText}</span>;
    const helpElement = helpText && <span className="help-block fs11">{helpText}</span>;

    // Class names
    const rootClassNames = classnames(
        className,
        { "form-group": !ignoreFormGroupClass },
        { "form-group-default": formStyling === "default" },
        { "required": required }
    );

    return (
        <div className={rootClassNames} style={styles.root}>
            {labelElement}
            <div className='d-flex align-items-center relative'>
                <input
                    id={textInputId}
                    data-lpignore={disableAutofill}
                    type={type}
                    name={name}
                    className={classnames("form-control", inputClassName)}
                    style={styles.input}
                    value={value}
                    onChange={onChangeCallback}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    disabled={disabled}
                    placeholder={placeholder || " "}
                    min={min}
                    max={max}
                    autoFocus={autoFocus}
                />
                {addons.map((addon, index) => (
                    <Fragment key={index}>
                        {addon.component}
                    </Fragment>
                ))}
            </div>
            {helpElement}
            {errorElement}
        </div>
    );
};

TextInput.propTypes = {
    /**
     * The style type of the input form. (default --> label inside or traditional --> label outside)
     */
    formStyling: PropTypes.oneOf(['default', 'traditional']),

    /**
     * The input type.
     */
    type: PropTypes.oneOf(['text', 'password', 'number']),

    /**
     * The input name.
     */
    name: PropTypes.string,

    /**
     * The css class name of the root element.
     */
    className: PropTypes.string,

    /**
     * Boolean prop indicating if the form is required.
     */
    required: PropTypes.bool,

    /**
     * Override the inline-styles of the root element.
     */
    style: PropTypes.object,

    /**
     * Override the inline-styles of the label element.
     */
    labelStyle: PropTypes.object,

    /**
     * Override the inline-styles of the input element.
     */
    inputStyle: PropTypes.object,

    /**
     * The content to use for label text.
     */
    labelText: PropTypes.string,

    /**
     * Callback function that is fired when the input's value changes.
     * Signature:
     *  function(event: object) => void
     *  event: Change event targeting the text field.
     */
    onChange: PropTypes.func,

    /**
     * Callback function that is fired when the input receives a key down event.
     * Signature:
     *  function(event: object) => void
     *  event: Key event targeting the text field.
     */
    onKeyDown: PropTypes.func,

    /**
     * The value of the text input.
     */
    value: PropTypes.string,

    /**
     * The error text content to display.
     */
    errorText: PropTypes.string,

    /**
     * The help text content to display.
     */
    helpText: PropTypes.string,

    /**
     * Add logic to disable autofill from browser extensions like LastPass.
     */
    disableAutofill: PropTypes.bool,

    /**
     * Boolean prop to disable the input.
     */
    disabled: PropTypes.bool,

    /**
     * Placeholder text for the input.
     */
    placeholder: PropTypes.string,

    /**
     * Minimum value for the input (for types that support it).
     */
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Maximum value for the input (for types that support it).
     */
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Ignore form group class.
     */
    ignoreFormGroupClass: PropTypes.bool,

    /**
     * CSS class for the input element.
     */
    inputClassName: PropTypes.string,

    /**
     * Callback function that is fired when the input loses focus.
     * Signature:
     *  function(event: object) => void
     *  event: Blur event targeting the text field.
     */
    onBlur: PropTypes.func,
};
