import { Map, fromJS } from 'immutable';

import {
    PRODUCTS_INITIALIZE, PRODUCT_DETAIL_INITIALIZE, PRODUCT_LIST_INITIALIZE,
    PRODUCTS_FETCH_REQUEST, PRODUCTS_FETCH_SUCCESS, PRODUCTS_FETCH_ERROR,
    PRODUCT_FETCH_REQUEST, PRODUCT_FETCH_SUCCESS, PRODUCT_FETCH_ERROR
} from './products-actions';

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

/**
 * Products Reducer
 */

const products = (state = initialState, action) => {
    let newState;

    // Get list and detail context
    const list = `list_${(action && action.context) || `primary`}`;
    const detail = `detail_${(action && action.context) || `primary`}`;

    switch (action.type) {

        // Initialize
        case PRODUCTS_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));
            return newState;

        case PRODUCT_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;

        case PRODUCT_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;

        // Products Fetch (list)
        case PRODUCTS_FETCH_REQUEST:

            newState = state;

            // Products fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case PRODUCTS_FETCH_SUCCESS:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case PRODUCTS_FETCH_ERROR:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;


        // Product Fetch (detail)
        case PRODUCT_FETCH_REQUEST:

            newState = state;

            // Product fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case PRODUCT_FETCH_SUCCESS:

            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;

        case PRODUCT_FETCH_ERROR:

            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;

        default:
            return state;
    }
};

export default products;
