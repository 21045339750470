import request from "@biuwer/common/src/libs/superagent";

import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import { PROMO_CODE_DETAIL } from './promo-codes-gql';

/*
 * Products action types
 */
export const PROMO_CODES_INITIALIZE = 'PROMO_CODES_INITIALIZE';
export const PROMO_CODE_DETAIL_INITIALIZE = 'PROMO_CODE_DETAIL_INITIALIZE';
export const PROMO_CODE_LIST_INITIALIZE = 'PROMO_CODE_LIST_INITIALIZE';

export const PROMO_CODES_FETCH_REQUEST = 'PROMO_CODES_FETCH_REQUEST';
export const PROMO_CODES_FETCH_SUCCESS = 'PROMO_CODES_FETCH_SUCCESS';
export const PROMO_CODES_FETCH_ERROR = 'PROMO_CODES_FETCH_ERROR';

export const PROMO_CODE_FETCH_REQUEST = 'PROMO_CODE_FETCH_REQUEST';
export const PROMO_CODE_FETCH_SUCCESS = 'PROMO_CODE_FETCH_SUCCESS';
export const PROMO_CODE_FETCH_ERROR = 'PROMO_CODE_FETCH_ERROR';

// Generic Returns Skeleton
const generateSkeleton = (type, body, context = 'primary') => {
    let skeleton = { type };

    switch (type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false,
                context: context
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                error: body?.statusCode,
                errorMessage: body?.message,
                context: context
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body,
                context: context
            };
            break;
        default:
            break;
    }

    switch(type){
        case PROMO_CODE_FETCH_ERROR:
            skeleton.issuePayload = {
                ...skeleton.issuePayload || {},
                // code: extraArgs.code
            };
            break;
        default:
            break;
    }

    return skeleton;
};


/**
 * Initialize promo codes action
 */
export function initializePromoCodes(context) {
    return {
        type: PROMO_CODES_INITIALIZE,
        context: context,
    };
}

/**
 * Initialize promo code detail action
 */
export function initializePromoCodeDetail(context) {
    return {
        type: PROMO_CODE_DETAIL_INITIALIZE,
        context: context,
    };
}

/**
 * Initialize promo code detail action
 */
export function initializePromoCodeList(context) {
    return {
        type: PROMO_CODE_LIST_INITIALIZE,
        context: context,
    };
}

/**
 * getValidPromoCode: Reads the requested promo code if its valid
 * @param code - Promo code
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function getValidPromoCode(code, context  = 'primary', gql = PROMO_CODE_DETAIL) {

    return async dispatch => {
        try {

            const query = {
                query: `query($code: String!) {
                    readValidPromoCode(code: $code) {
                        ${gql}
                    }
                }`,
                variables: {
                    code
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(PROMO_CODE_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(PROMO_CODE_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readValidPromoCode) {
                    dispatch(generateSkeleton(PROMO_CODE_FETCH_SUCCESS, res.body.data.readValidPromoCode, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(PROMO_CODE_FETCH_ERROR, err, context));
        }
    }
}
