import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from "@blueprintjs/core"
import CustomButton from "./custom-button"

const InfoMessage = ({ type, message, onCloseErrorMessage = () => {} }) => {

    if (!message) return null

    return (
        <div className={`alert alert-${type} d-flex flex-row align-items-center`}>
            <Icon icon="info-sign" className="p-r-10" iconSize={15} />
            <span className="d-flex flex-1">{message}</span>
            <CustomButton
                onClick={onCloseErrorMessage}
                style={{ height: 15, margin: 0, padding: 0, border: "none", backgroundColor: "transparent" }}
            >
                <Icon icon="cross" iconSize={15} className={`alert-${type}`} />
            </CustomButton>
        </div>
    )
};

InfoMessage.propTypes = {
    message: PropTypes.string,
    type: PropTypes.string,
    onCloseErrorMessage: PropTypes.func
};

export default InfoMessage;