const datasetQueryMatchFn = (datasetQueryQuery, filters) => {
    return String(datasetQueryQuery.fields) === String(filters.fields) &&
        String(datasetQueryQuery.filters) === String(filters.filters) &&
        String(datasetQueryQuery.options) === String(filters.options) &&
        String(datasetQueryQuery.format) === String(filters.format) &&
        String(datasetQueryQuery.dataCardQueries) === String(filters.dataCardQueries);
};

const getDatasetQuery = (queries = [], filters = {}) => {
    return queries?.length > 0 && queries?.find(query => datasetQueryMatchFn(query, filters));
};

const getDatasetQueryIndex = (queries = [], filters = {}) => {
    return queries?.findIndex(query => datasetQueryMatchFn(query, filters));
};

export {
    getDatasetQuery,
    getDatasetQueryIndex
}