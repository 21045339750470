import { Map, fromJS } from 'immutable';

/**
 * System MapCatalog Reducer
 */

import {
    MAP_CATALOG_INITIALIZE, MAP_CATALOG_DETAIL_INITIALIZE, MAP_CATALOG_LIST_INITIALIZE,
    MAP_CATALOG_DETAIL_FETCH_REQUEST, MAP_CATALOG_DETAIL_FETCH_SUCCESS, MAP_CATALOG_DETAIL_FETCH_ERROR,
    MAP_CATALOG_LIST_FETCH_REQUEST, MAP_CATALOG_LIST_FETCH_SUCCESS, MAP_CATALOG_LIST_FETCH_ERROR,
    MAP_CATALOG_CREATE_REQUEST, MAP_CATALOG_CREATE_SUCCESS, MAP_CATALOG_CREATE_ERROR
} from './map-catalog-actions.js';

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

const mapCatalog = (state = initialState, action) => {
    let newState, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || `primary`}`;
    const detail = `detail_${(action && action.context) || `primary`}`;

    switch (action.type) {

        // Necessary to initialize roles state prior to create a new roles
        case MAP_CATALOG_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));
            return newState;
        case MAP_CATALOG_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;
        case MAP_CATALOG_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;

        // Map Catalog Detail fetch
        case MAP_CATALOG_DETAIL_FETCH_REQUEST:

            newState = state;

            // MapCatalog detail fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case MAP_CATALOG_DETAIL_FETCH_SUCCESS:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case MAP_CATALOG_DETAIL_FETCH_ERROR:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;

        // Map Catalog list fetch
        case MAP_CATALOG_LIST_FETCH_REQUEST:

            newState = state;

            // MapCatalog list fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case MAP_CATALOG_LIST_FETCH_SUCCESS:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case MAP_CATALOG_LIST_FETCH_ERROR:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;

        // Map Catalog Create
        case MAP_CATALOG_CREATE_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case MAP_CATALOG_CREATE_SUCCESS:

            // Update payload with new created map catalog
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case MAP_CATALOG_CREATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;

        default:
            return state;
    }
};

export default mapCatalog;
