import React, { useState, useEffect } from 'react';
import { Icon } from '@blueprintjs/core';

/**
 * Component to render a image from an external url
 * @param {String} imageUrl 
 * @param {String} altText
 * @param {String} nullValue
 * @param {String} align image align (left, center, right)
 * @returns 
 */
const ExternalImageDisplay = ({ imageUrl, altText, nullValue, align }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);
    const [currentImageUrl, setCurrentImageUrl] = useState(imageUrl ?? nullValue);
    const [showSecondImage, setShowSecondImage] = useState(false);

    const handleLoad = () => {
        setIsLoaded(true);
    };

    const handleError = () => {
        setIsError(true);
        if (imageUrl !== nullValue) {
            setCurrentImageUrl(nullValue);
            setShowSecondImage(true);
        }
    };

    useEffect(() => {
        setIsLoaded(false);
        setIsError(false);
        setCurrentImageUrl(imageUrl ?? nullValue);
        setShowSecondImage(false);
    }, [imageUrl]);

    const imageStyles = {
        display: isLoaded ? 'block' : 'none', 
        maxHeight: '100%', 
        maxWidth: '100%',
        margin: 'auto auto auto 0'
    };

    if (align && align === 'center') imageStyles.margin = 'auto';
    if (align && align === 'right') imageStyles.margin = 'auto 0 auto auto';

    let errorIcon = null;
    if (isError && currentImageUrl === imageUrl && !showSecondImage || isError && !currentImageUrl || !imageUrl && !nullValue) {
        errorIcon = <Icon icon={"disable"} style={{height: '100%', margin: imageStyles.margin}} className={"external-image-icon"} />;
    }

    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                width: '100%'
            }}
        >
            {errorIcon}
            {showSecondImage && nullValue && (
                <img
                    src={nullValue}
                    alt={altText}
                    onLoad={handleLoad}
                    onError={handleError}
                    style={imageStyles}
                />
            )}
            {!showSecondImage && (
                <img
                    src={currentImageUrl}
                    alt={altText}
                    onLoad={handleLoad}
                    onError={handleError}
                    style={imageStyles}
                />
            )}
        </div>
    );
};

export default ExternalImageDisplay;
