import React from 'react';
import moment from 'moment';
import i18n from '@biuwer/core/src/i18n';
import numeral from 'numeral';
import { cloneDeep, merge, isNil, isNumber } from 'lodash';

import DraggableCell from "@biuwer/common/src/components/draggableCell"
import ExternalImageDisplay from "@biuwer/common/src/components/external-image-display"
import ResizableCell from "@biuwer/common/src/components/resizable-cell"

import { formatNullValue } from "./null-values-lib";
import { parseObjectValueToString } from './data-parser-lib';

/**
 * Render basic cell (1 cell, 1 value)
 * @param key
 * @param style
 * @param cellContent
 * @param func
 */

// Default cell styles
let simpleElementCellStyle = {
    textAlign: 'right',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    borderColor: '#e0e0e0'
};

// Default cell element styles

let inputStyle = {
    border: 'none',
    borderBottom: '1px solid #bbb',
    padding: 8,
    outline: 0,
    right: 0,
    left: 0,
    top: 0,
    textAlign: 'right',
    width: '90%',
    marginLeft: 4,
    marginRight: 4,
    backgroundColor: 'transparent'
};


/**
 * Cell renderer 1: simple element rendering for view mode grids
 * @param key
 * @param style
 * @param elements
 * @param functions
 */
export function cellRenderer1 ({ key, style, elements, functions }){

    let customCellStyle, cellContent;

    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle.padding = '0.5em';
    customCellStyle = merge(customCellStyle, style);

    cellContent = elements.map((element, index) => {
        let elementContent;

        // Return element based on data type
        if (isNil(element.value)) {
            elementContent = formatNullValue(element.null_values, element.null_custom_value);
        } else {
            switch(element.type) {
                case 'date':
                case 'datetime':
                    if (element.hasOwnProperty('value') && element.field_type === 'dimension') {
                        elementContent = moment(element.value, element.date_mask || '').format(element.format || '');
                    } else {
                        elementContent = element.value;
                    }
                    break;
                case 'text':
                case 'string':
                    elementContent = parseObjectValueToString(element.value);
                    break;
                case 'number':

                    if (element && element.numberType && element.numberType === 'Currency') {
                        if (element.signPosition && element.signPosition === 'after') {
                            elementContent = `${numeral(element.value).format(element.format || '').replace(' $', '')} ${element.currencySign}`;
                        } else {
                            elementContent = element.hasOwnProperty('value') ? `${element.currencySign} ${numeral(element.value).format(element.format || '').replace(' $', '')}` : 0;
                        }
                    } else {
                        elementContent = element.hasOwnProperty('value') && isNumber(element.value) ? numeral(element.value).format(element.format || '') : null;
                    }

                    break;
                case 'pct':
                    elementContent = element.hasOwnProperty('value') ? numeral(element.value * 100).format(element.format || '') : 0;
                    break;
                case 'function':
                    elementContent = element.value();
                    break;
                case 'boolean':
                    elementContent = element.value ? i18n.t('common.yes') : i18n.t('common.no');
                    break;
                default:
                    break;
            }
        }


        element.style = {
            ...element.style,
            pageBreakInside: 'avoid',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        };

        if (element.imageUrl && (element.value || (!element.value && element.null_values === 'custom'))) {
            return (
                <div
                    key={key + index}
                    style={{height: '100%', ...element.style || {}}}
                    title={elementContent}
                >
                    <ExternalImageDisplay
                        imageUrl={element.value}
                        nullValue={element.null_values === 'custom' ? element.null_custom_value : ''}
                        altText={element.value}
                        align={customCellStyle?.textAlign}
                    />
                </div>
            );
        } else {
            return (
                <div
                    key={key + index}
                    style={element.style || {}}
                    title={elementContent}
                >
                    {elementContent}
                </div>
            );
        }
    });

    return (
        <div
            key={key}
            style={customCellStyle}
        >
            {cellContent}
        </div>
    );
}

/**
 * cellRendererNavigation: simple element rendering for navigation cells
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param onClick
 * @param rowIndex
 * @param columnIndex
 */
export function cellRendererNavigation ({ key, style, elements, functions, onClick, rowIndex, columnIndex }){

    let customCellStyle, cellContent, href = '';

    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle.padding = '0.5em';
    customCellStyle = merge(customCellStyle, style);

    cellContent = elements.map((element, index) => {
        let elementContent;

        // Return element based on data type
        switch(element.type) {
            case 'date':
            case 'datetime':
                if (element.hasOwnProperty('value') && element.field_type === 'dimension') {
                    elementContent = moment(element.value, element.date_mask || '').format(element.format || '');
                } else {
                    elementContent = element.value;
                }
                break;
            case 'text':
            case 'string':
                elementContent = parseObjectValueToString(element.value);
                break;
            case 'number':

                if (element && element.numberType && element.numberType === 'Currency') {
                    if (element.signPosition && element.signPosition === 'after') {
                        elementContent = `${numeral(element.value).format(element.format || '').replace(' $', '')} ${element.currencySign}`;
                    } else {
                        elementContent = element.hasOwnProperty('value') ? `${element.currencySign} ${numeral(element.value).format(element.format || '').replace(' $', '')}` : 0;
                    }
                } else {
                    elementContent = element.hasOwnProperty('value') ? numeral(element.value).format(element.format || '') : 0;
                }

                break;
            case 'pct':
                elementContent = element.hasOwnProperty('value') ? numeral(element.value * 100).format(element.format || '') : 0;
                break;
            case 'function':
                elementContent = element.value();
                break;
            case 'boolean':
                elementContent = element.value ? i18n.t('common.yes') : i18n.t('common.no');
                break;
            default:
                break;
        }

        element.style = {
            ...element.style,
            pageBreakInside: 'avoid',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        };

        // TODO RJC: Ampliar con el resto de acciones disponibles
        // Actions
        if (element && element.actions && element.actions.length > 0) {
            element.actions.forEach((action, actionIndex) => {
                switch (action.action_type) {

                    case 'url_link':

                        let urlMeta;
                        element.drillThroughConfig = element.drillThroughConfig.map((drillThroughConfig) => {
                            urlMeta = {
                                queryMeta: elements[0].rowQueryPositions || element.drillThroughConfig,
                                systemVariables: element.systemVariables,
                                from: elements[0].rowQueryPositions ? "leftGrid" : "mainGrid"
                            }
                            return {
                                ...drillThroughConfig,
                                url_link: urlMeta
                            };
                        });

                        if (element.imageUrl) {
                            elementContent = (
                                <div
                                    key={key + index}
                                    style={{ height: '100%', ...element.style || {} }}
                                    title={elementContent}
                                    onClick={() => { onClick(rowIndex, columnIndex, null, element.drillThroughConfig, element.actions) }}
                                >
                                    {element.value || element.null_values === 'custom'
                                        ? <ExternalImageDisplay
                                            imageUrl={element.value}
                                            nullValue={element.null_values === 'custom' ? element.null_custom_value : ''}
                                            altText={href}
                                            align={customCellStyle?.textAlign} />
                                        : elementContent
                                    }
                                </div>
                            );
                        } else {
                            elementContent = (
                                <div
                                    key={key + index}
                                    style={element.style || {}}
                                    title={elementContent}
                                    onClick={() => { onClick(rowIndex, columnIndex, null, element.drillThroughConfig, element.actions) }}
                                >
                                    {elementContent}
                                </div>
                            );
                        }
                        break;

                    case 'card_navigation':
                    case 'page_navigation':
                    case 'card_popup':

                        if (element.imageUrl) {
                            elementContent = (
                                <div
                                    key={key + index}
                                    title={elementContent}
                                    style={element.style ? { ...element.style, cursor: 'pointer', height: '100%' } : { cursor: 'pointer', height: '100%' }}
                                    onClick={() => { onClick(rowIndex, columnIndex, null, element.drillThroughConfig, element.actions) }}
                                >
                                    {element.value || element.null_values === 'custom'
                                        ? <ExternalImageDisplay
                                            imageUrl={element.value}
                                            nullValue={element.null_values === 'custom' ? element.null_custom_value : ''}
                                            altText={element.value}
                                            align={customCellStyle?.textAlign} />
                                        : elementContent
                                    }
                                </div>
                            );
                        } else {
                            elementContent = (
                                <div
                                    key={key + index}
                                    title={elementContent}
                                    style={element.style ? { ...element.style, cursor: 'pointer' } : { cursor: 'pointer' }}
                                    onClick={() => { onClick(rowIndex, columnIndex, null, element.drillThroughConfig, element.actions) }}
                                >
                                    {elementContent}
                                </div>
                            );
                        }
                        break;

                    default:
                        break;
                }
            });
        }

        return (
            <div
                key={key + index}
                style={{height: '100%', ...element.style || {}}}
                title={elementContent}
            >
                {elementContent}
            </div>
        );
    });

    delete(customCellStyle.overflowX);
    delete(customCellStyle.overflowY);

    return (
        <div
            key={key}
            style={{ ...customCellStyle, overflow: 'hidden', cursor: 'pointer' }}
        >
            {cellContent}
        </div>
    );
}

/**
 * Cell renderer click: simple element rendering for view mode grids with on click event
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param onClick
 * @param rowIndex
 * @param columnIndex
 */
export function cellRendererClick({ key, style, elements, functions, onClick, rowIndex, columnIndex }){

    let customCellStyle, cellContent, icon = '', dataField;

    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle.padding = '0.5em';
    customCellStyle = merge(customCellStyle, style);

    cellContent = elements.map((element, index) => {
        let elementContent;
        dataField = element.dataField;
        icon = element.icon || '';

        // Return element based on data type
        if (isNil(element.value)) {
            elementContent = formatNullValue(element.null_values, element.null_custom_value);
        } else {
            switch(element.type) {
                case 'date':
                case 'datetime':
                    elementContent = element.hasOwnProperty('value') ? moment(element.value).format(element.format || '') : '';
                    break;
                case 'text':
                case 'string':
                    elementContent = parseObjectValueToString(element.value);
                    break;
                case 'number':
                    elementContent = element.hasOwnProperty('value') ? numeral(element.value).format(element.format || '') : 0;
                    break;
                case 'pct':
                    elementContent = element.hasOwnProperty('value') ? numeral(element.value * 100).format(element.format || '') : 0;
                    break;
                case 'function':
                    elementContent = element.value();
                    break;
                case 'boolean':
                    elementContent = element.value ? i18n.t('common.yes') : i18n.t('common.no');
                    break;
                default:
                    break;
            }
        }

        return (
            <div
                key={key + index}
                style={element.style || {}}
            >
                {elementContent}
            </div>
        );
    });

    let iconDiv = null;
    if (icon === 'asc') {
        iconDiv = (
            <i className="material-icons" style={{ height: 20, width: 20 }}>arrow_drop_up</i>
        );
    } else if (icon === 'desc') {
        iconDiv = (
            <i className="material-icons" style={{ height: 20, width: 20 }}>arrow_drop_down</i>
        );
    } else if (icon === 'custom') {
        iconDiv = (
            <i className="material-icons"
               style={{
                   height: 20,
                   width: 20,
                   fontSize: 18,
                   marginRight: customCellStyle.textAlign === 'right' ? 5 : 0,
                   marginLeft: customCellStyle.textAlign !== 'right' ? 5 : 0
               }}
            >
                format_line_spacing
            </i>
        );
    }

    let justifyContentValue;
    switch (customCellStyle.textAlign) {
        case 'left':
            justifyContentValue = 'flex-start';
            break;
        case 'right':
            justifyContentValue = 'flex-end';
            break;
        case 'center':
            justifyContentValue = 'center';
            break;
        default:
            justifyContentValue = 'flex-start';
    }

    return (
        <div
            key={key}
            style={customCellStyle}
            onClick={(e) => { onClick(rowIndex, columnIndex, dataField)}}
        >
            {justifyContentValue === 'flex-end' ? (
                <div style={{ display: 'flex', justifyContent: justifyContentValue, alignItems: 'center' }}>
                    {iconDiv}
                    {cellContent}
                </div>
            ) : (
                <div style={{ display: 'flex', justifyContent: justifyContentValue, alignItems: 'center' }}>
                    {cellContent}
                    {iconDiv}
                </div>
            )}
        </div>
    );
}

/**
 * Cell renderer Input: input element rendering for edit modes
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param onChange
 * @param onBlur
 * @param rowIndex
 * @param columnIndex
 */
export function cellRendererInput ({ key, style, elements, functions, onChange, onBlur, rowIndex, columnIndex }){

    let customCellStyle, cellContent;
    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle = merge(customCellStyle, style);

    let customInputStyle = Object.assign({}, inputStyle);
    customInputStyle.height = customCellStyle - 4 || 28;

    cellContent = elements.map((element, index) => {
        let elementContent = element.value;

        return (
            <div
                key={key + index}
                style={element.style || {}}
            >
                <input
                    style={customInputStyle}
                    onChange={(e)=>{ onChange(e.target.value, rowIndex, columnIndex)}}
                    onBlur={(e)=>{ onBlur(e.target.value, rowIndex, columnIndex)}}
                    value={elementContent}
                />
            </div>
        );
    });

    return (
        <div
            key={key}
            style={customCellStyle}
        >
            {cellContent}
        </div>
    );
}


/**
 * Cell renderer Input: input element rendering for edit modes
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param onChange
 * @param onBlur
 * @param rowIndex
 * @param columnIndex
 */
export function cellRendererInputText ({ key, style, elements, functions, onChange, onBlur, rowIndex, columnIndex }){

    let customCellStyle, cellContent;
    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle = merge(customCellStyle, style);

    cellContent = elements.map((element, index) => {
        let elementContent = element.value;

        if (index === 0) {
            let customInputStyle = Object.assign({}, inputStyle);
            customInputStyle.height = 25;
            return (
                <div
                    key={key + index}
                    style={element.style || {}}
                >
                    <input
                        style={customInputStyle}
                        onChange={(e)=>{ onChange(e.target.value, rowIndex, columnIndex)}}
                        onBlur={(e)=>{ onBlur(e.target.value, rowIndex, columnIndex)}}
                        value={elementContent}
                    />
                </div>
            );
        } else {

            switch(element.type) {
                case 'date':
                case 'datetime':
                    elementContent = element.hasOwnProperty('value') ? moment(element.value).format(element.format || '') : '';
                    break;
                case 'text':
                case 'string':
                    elementContent = parseObjectValueToString(element.value);
                    break;
                case 'number':
                    elementContent = element.hasOwnProperty('value') ? numeral(element.value).format(element.format || '') : 0;
                    break;
                case 'function':
                    elementContent = element.value();
                    break;
                case 'boolean':
                    elementContent = element.value ? i18n.t('common.yes') : i18n.t('common.no');
                    break;
                default:
                    break;
            }

            let elementStyle = Object.assign({}, element.style);
            elementStyle.paddingLeft = '0.5em';
            elementStyle.paddingRight = '0.5em';

            return (
                <div
                    key={key + index}
                    style={elementStyle || {}}
                >
                    {elementContent}
                </div>
            );
        }
    });

    return (
        <div
            key={key}
            style={customCellStyle}
        >
            {cellContent}
        </div>
    );
}

/**
 * Cell renderer Mixed Input: input element rendering for edit modes with a simple view element
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param onChange
 * @param onBlur
 * @param rowIndex
 * @param columnIndex
 */
export function cellRendererMixedInput ({ key, style, elements, functions, onChange, onBlur, rowIndex, columnIndex }){

    let customCellStyle, cellContent;
    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle = merge(customCellStyle, style);

    let customInputStyle = Object.assign({}, inputStyle);
    customInputStyle.height = customCellStyle - 4 || 28;
    customInputStyle.width = '50%';

    cellContent = elements.map((element, index) => {
        let elementEditableContent = element.value.editable, elementViewableContent = element.value.viewable;

        return (
            <div
                key={key + index}
                style={element.style || {}}
            >
                <input
                    style={customInputStyle}
                    onChange={(e) => { onChange(e.target.value, rowIndex, columnIndex)}}
                    onBlur={(e) => { onBlur(e.target.value, rowIndex, columnIndex)}}
                    value={elementEditableContent}
                />
                <div style={{display: 'inline-block'}}>
                    {elementViewableContent}
                </div>
            </div>
        );
    });

    return (
        <div
            key={key}
            style={customCellStyle}
        >
            {cellContent}
        </div>
    );
}

/**
 * Cell renderer Mixed Input: input element rendering for edit modes with a simple view element
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param onChange
 * @param onBlur
 * @param onFocus
 * @param rowIndex
 * @param columnIndex
 */
export function cellRendererMixedInputWithOnBlur ({ key, style, elements, functions, onChange, onBlur, onFocus, rowIndex, columnIndex }){

    let customCellStyle, cellContent;
    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle = merge(customCellStyle, style);

    let customInputStyle = Object.assign({}, inputStyle);
    customInputStyle.height = customCellStyle - 4 || 28;
    customInputStyle.width = '74%';
    // customInputStyle.borderBottom = 'none';
    customInputStyle.marginRight = 0;
    customInputStyle.padding = 4;

    cellContent = elements.map((element, index) => {
        let elementEditableContent = element.value.editable, elementViewableContent = element.value.viewable;

        return (
            <div
                key={key + index}
                style={element.style || {}}
            >
                <input
                    style={customInputStyle}
                    onChange={(e) => { onChange(e.target.value, rowIndex, columnIndex)}}
                    onBlur={(e) => { onBlur(e.target.value, rowIndex, columnIndex)}}
                    onFocus={(e) => { onFocus(e.target.value, rowIndex, columnIndex)}}
                    value={elementEditableContent}
                />
                <div style={{display: 'inline-block', marginRight: 5}}>
                    {elementViewableContent}
                </div>
            </div>
        );
    });

    return (
        <div
            key={key}
            style={customCellStyle}
        >
            {cellContent}
        </div>
    );
}

/**
 * Cell Renderer Delete Row: renders a simple cell with an icon button for delete
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param onClick
 * @param rowIndex
 * @param columnIndex
 */
export function cellRendererDeleteRow ({ key, style, elements, functions, onClick, rowIndex, columnIndex }){

    let customCellStyle, cellContent;
    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle = merge(customCellStyle, style);
    customCellStyle.padding = '0.5em';
    customCellStyle.display = 'flex';
    customCellStyle.flexDirection = 'row';
    customCellStyle.justifyContent = 'space-between';

    cellContent = elements.map((element, index) => {
        let elementContent;

        // Return element based on data type
        switch(element.type) {
            case 'date':
            case 'datetime':
                elementContent = element.hasOwnProperty('value') ? moment(element.value).format(element.format || '') : '';
                break;
            case 'text':
            case 'string':
                elementContent = element.value;
                break;
            case 'number':
                elementContent = element.hasOwnProperty('value') ? numeral(element.value).format(element.format || '') : 0;
                break;
            case 'function':
                elementContent = element.value();
                break;
            case 'boolean':
                elementContent = element.value ? i18n.t('common.yes') : i18n.t('common.no');
                break;
            default:
                break;
        }

        return (
            <div
                key={key + index}
                style={element.style || {}}
            >
                {elementContent}
            </div>
        );
    });

    return (
        <div
            key={key}
            style={customCellStyle}
        >
            {cellContent}
            <div>
                <i className="material-icons"
                   style={{ fontSize: 20, cursor: 'pointer' }}
                   onClick={()=>{ onClick(rowIndex, elements)}}
                >
                    highlight_off
                </i>
            </div>
        </div>
    );
}


/**
 * Cell Renderer Parent: simple rendering for a parent row
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param onClick
 * @param rowIndex
 * @param columnIndex
 */
export function cellRendererParent ({ key, style, elements, functions, onClick, rowIndex, columnIndex }){

    let customCellStyle, cellContent, icon;

    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle = merge(customCellStyle, style);
    customCellStyle.padding = '0.5em';
    customCellStyle.display = 'flex';
    customCellStyle.flexDirection = 'row';
    customCellStyle.justifyContent = 'space-between';
    customCellStyle.alignItems = 'center';

    let value = [];
    let elementsData = [];
    let isOpen = true;

    cellContent = elements.map((element, index) => {
        let elementContent;
        icon = element.icon;

        // Return element based on data type
        switch(element.type) {
            case 'date':
            case 'datetime':
                elementContent = element.hasOwnProperty('value') ? moment(element.value).format(element.format || '') : '';
                break;
            case 'text':
            case 'string':
                elementContent = parseObjectValueToString(element.value);
                break;
            case 'number':
                elementContent = element.hasOwnProperty('value') ? numeral(element.value).format(element.format || '') : 0;
                break;
            case 'function':
                elementContent = element.value();
                break;
            case 'boolean':
                elementContent = element.value ? i18n.t('common.yes') : i18n.t('common.no');
                break;
            default:
                break;
        }

        value.push(element.value);
        elementsData.push(element);
        isOpen = element.open;

        return (
            <div
                key={key + index}
                style={element.style || {}}
            >
                {elementContent}
            </div>
        );
    });

    return (
        <div
            key={key}
            style={customCellStyle}
        >
            {cellContent}
            <div>
                {icon ? (
                    <i className="material-icons"
                       style={{ fontSize: 20, cursor: 'pointer' }}
                       onClick={()=>{ onClick(rowIndex, 'parent - function', value, elementsData)}}
                    >
                        {icon}
                    </i>
                ) : null}
                <i className="material-icons"
                   style={{ fontSize: 20, cursor: 'pointer' }}
                   onClick={()=>{ onClick(rowIndex, 'parent - collapse', value, elementsData)}}
                >
                    {isOpen ? (
                        <span>
                            keyboard_arrow_up
                        </span>
                    ) : (
                        <span>
                            keyboard_arrow_down
                        </span>
                    )}
                </i>
            </div>
        </div>
    );
}


/**
 * Cell Renderer Child: simple render for child rows
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param onClick
 * @param rowIndex
 * @param columnIndex
 */
export function cellRendererChild ({ key, style, elements, functions, onClick, rowIndex, columnIndex }){

    let customCellStyle, cellContent, icon;
    let value = [];

    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle = merge(customCellStyle, style);
    customCellStyle.paddingTop = '0.5em';
    customCellStyle.paddingBottom = '0.5em';
    customCellStyle.paddingRight = '15px';
    customCellStyle.paddingLeft = '15px';
    customCellStyle.display = 'flex';
    customCellStyle.flexDirection = 'row';
    customCellStyle.justifyContent = 'space-between';
    customCellStyle.alignItems = 'center';

    cellContent = elements.map((element, index) => {
        let elementContent;
        icon = element.icon;

        // Return element based on data type
        switch(element.type) {
            case 'date':
            case 'datetime':
                elementContent = element.hasOwnProperty('value') ? moment(element.value).format(element.format || '') : '';
                break;
            case 'text':
            case 'string':
                elementContent = parseObjectValueToString(element.value);
                break;
            case 'number':
                elementContent = element.hasOwnProperty('value') ? numeral(element.value).format(element.format || '') : 0;
                break;
            case 'function':
                elementContent = element.value();
                break;
            case 'boolean':
                elementContent = element.value ? i18n.t('common.yes') : i18n.t('common.no');
                break;
            default:
                break;
        }

        value.push(element.value);

        return (
            <div
                key={key + index}
                style={element.style || {}}
            >
                {elementContent}
            </div>
        );
    });

    return (
        <div
            key={key}
            style={customCellStyle}
        >
            {cellContent}
            {icon ? (
                <div>
                    <i className="material-icons"
                       style={{ fontSize: 20, cursor: 'pointer' }}
                       onClick={()=>{ onClick(rowIndex, 'child', value)}}
                    >
                        {icon}
                    </i>
                </div>
            ) : null}
        </div>
    );
}

/**
 * Cell renderer Sort: simple element rendering for view mode grids
 * @param key
 * @param style
 * @param elements
 * @param functions
 */
export function cellRendererSort ({ key, style, elements, functions, onClick, rowIndex, columnIndex }){

    let customCellStyle, cellContent, icon;

    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle = merge(customCellStyle, style);
    customCellStyle.padding = '0.5em';
    customCellStyle.display = 'flex';
    customCellStyle.flexDirection = 'row';
    customCellStyle.justifyContent = 'space-between';
    customCellStyle.alignItems = 'center';
    customCellStyle.cursor = 'pointer';

    cellContent = elements.map((element, index) => {
        let elementContent;
        icon = element.icon;

        // Return element based on data type
        switch(element.type) {
            case 'date':
            case 'datetime':
                elementContent = element.hasOwnProperty('value') ? moment(element.value).format(element.format || '') : '';
                break;
            case 'text':
            case 'string':
                elementContent = parseObjectValueToString(element.value);
                break;
            case 'number':
                elementContent = element.hasOwnProperty('value') ? numeral(element.value).format(element.format || '') : 0;
                break;
            case 'function':
                elementContent = element.value();
                break;
            case 'boolean':
                elementContent = element.value ? i18n.t('common.yes') : i18n.t('common.no');
                break;
            default:
                break;
        }

        element.style = {
            ...element.style,
            pageBreakInside: 'avoid',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        };

        return (
            <div
                key={key + index}
                style={element.style || {}}
                title={elementContent}
            >
                {elementContent}
            </div>
        );
    });

    return (
        <div
            key={key}
            style={customCellStyle}
            onClick={()=>{ onClick(rowIndex, columnIndex)}}
        >
            {cellContent}
            {icon ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div>
                        <i className="material-icons"
                           style={{ fontSize: 24, cursor: 'pointer', paddingLeft: 5 }}
                        >
                            {icon}
                        </i>
                    </div>
                </div>
            ) : null}
        </div>
    );
}


/**
 * Cell Renderer Double icon
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param onClick
 * @param rowIndex
 * @param columnIndex
 */
export function cellRendererDoubleIcon ({ key, style, elements, functions, onClick, rowIndex, columnIndex }){

    let customCellStyle, cellContent, icon1, icon2;

    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle = merge(customCellStyle, style);
    customCellStyle.padding = '0.5em';
    customCellStyle.display = 'flex';
    customCellStyle.flexDirection = 'row';
    customCellStyle.justifyContent = 'space-between';
    customCellStyle.alignItems = 'center';

    let value = [];

    cellContent = elements.map((element, index) => {
        let elementContent;
        icon1 = element.icon1;
        icon2 = element.icon2;

        // Return element based on data type
        switch(element.type) {
            case 'date':
            case 'datetime':
                elementContent = element.hasOwnProperty('value') ? moment(element.value).format(element.format || '') : '';
                break;
            case 'text':
            case 'string':
                elementContent = parseObjectValueToString(element.value);
                break;
            case 'number':
                elementContent = element.hasOwnProperty('value') ? numeral(element.value).format(element.format || '') : 0;
                break;
            case 'function':
                elementContent = element.value();
                break;
            case 'boolean':
                elementContent = element.value ? i18n.t('common.yes') : i18n.t('common.no');
                break;
            default:
                break;
        }

        value.push(element.value);

        return (
            <div
                key={key + index}
                style={element.style || {}}
            >
                {elementContent}
            </div>
        );
    });

    return (
        <div
            key={key}
            style={customCellStyle}
        >
            {cellContent}
            <div>
                {icon1 ? (
                    <i className="material-icons"
                       style={{ fontSize: 20, cursor: 'pointer' }}
                       onClick={()=>{ onClick(rowIndex, 'icon1', value)}}
                    >
                        {icon1}
                    </i>
                ) : null}
                {icon2 ? (
                    <i className="material-icons"
                       style={{ fontSize: 20, cursor: 'pointer', paddingLeft: 10 }}
                       onClick={()=>{ onClick(rowIndex, 'icon2', value)}}
                    >
                        {icon2}
                    </i>
                ) : null}
            </div>
        </div>
    );
}

/**
 * Cell renderer Mixed Input: input element rendering for edit modes with a simple view element
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param onChange
 * @param onBlur
 * @param rowIndex
 * @param columnIndex
 * @param onClick
 */
export function cellRendererInputButton ({ key, style, elements, functions, onChange, onBlur, rowIndex, columnIndex, onClick }){

    let customCellStyle, cellContent;
    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle = merge(customCellStyle, style);
    customCellStyle.padding = '0.5em';

    let customInputStyle = Object.assign({}, inputStyle);
    customInputStyle.height = 18;
    customInputStyle.width = customCellStyle.width - 30 || 50;
    customInputStyle.padding = 0;

    cellContent = elements.map((element, index) => {

        let elementContent = element.value;

        return (
            <div
                key={key + index}
                style={element.style || {}}
            >
                <div style={{ display: 'flex' }}>
                    <input
                        style={customInputStyle}
                        onChange={(e)=>{ onChange(e.target.value, rowIndex, columnIndex)}}
                        onBlur={(e)=>{ onBlur(e.target.value, rowIndex, columnIndex)}}
                        value={elementContent}
                    />
                    <span>%</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                        key="editButton"
                        type="button"
                        className="btn btn-sm bg-success"
                        onClick={()=>{ onClick(rowIndex, 'applyButton', elementContent)}}
                        disabled={elementContent === ''}
                        style={{
                            color: '#FAFAFA',
                            fontWeight: 500,
                            height: customInputStyle.height,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginTop: 2,
                            width: customCellStyle.width - 10,
                            lineHeight: 0.5
                        }}
                    >
                        {i18n.t('common.applyLabel')}
                    </button>
                </div>
            </div>
        );
    });

    return (
        <div
            key={key}
            style={customCellStyle}
        >
            {cellContent}
        </div>
    );
}

/**
 * Cell renderer Mixed Input: input element rendering for edit modes with a simple view element
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param onChange
 * @param onBlur
 * @param rowIndex
 * @param columnIndex
 * @param onClick
 */
export function cellRendererInputValue ({ key, style, elements, functions, onChange, onBlur, rowIndex, columnIndex, onClick }){

    let customCellStyle;
    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle = merge(customCellStyle, style);
    customCellStyle.padding = '0.5em';

    let customInputStyle = Object.assign({}, inputStyle);
    customInputStyle.height = 18;
    customInputStyle.width = customCellStyle.width - 30 || 50;
    customInputStyle.padding = 0;

    let element1, element2;

    element1 = (
        <div key={key + 'element1'}
             style={elements[0].style || {}}
        >
            <div style={{ display: 'flex' }}>
                <input
                    style={customInputStyle}
                    onChange={(e)=>{ onChange(e.target.value, rowIndex, columnIndex)}}
                    onBlur={(e)=>{ onBlur(e.target.value, rowIndex, columnIndex)}}
                    value={elements[0].value}
                />
                <span>%</span>
            </div>
        </div>
    );

    element2 = (
        <div key={key + 'element2'}
             style={elements[1].style || {}}
        >
            {numeral(elements[1].value).format(elements[1].format || '')}
        </div>
    );

    return (
        <div
            key={key}
            style={customCellStyle}
        >
            <div>
                {element1}
                {element2}
            </div>
        </div>
    );
}

/**
 * Cell Renderer Double icon
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param onClick
 * @param rowIndex
 * @param columnIndex
 */
export function cellRendererIcons ({ key, style, elements, functions, onClick, rowIndex, columnIndex }){

    let customCellStyle, icon1, icon2, cellStyle = {};

    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle = merge(customCellStyle, style);

    let value = [];
    let week = '';
    let manager = '';
    let producer = '';
    let crop = '';
    let cropId = '';
    let isParent = false;

    elements.map((element, index) => {
        icon1 = element.icon1;
        icon2 = element.icon2;
        week = element.adjustedWeek;
        manager = element.manager;
        producer = element.producer;
        crop = element.crop;
        cropId = element.cropId;
        isParent = element.isParent;
        value.push(element.value);
        cellStyle = Object.assign({}, element.style);

        return null;
    });

    return (
        <div
            key={key}
            style={customCellStyle}
        >
            <div style={cellStyle}>
                <div style={{ width: 20 }}>
                    <i className="material-icons"
                       style={{ fontSize: 20, cursor: icon1 === 'done' && !isParent ? 'default' : 'pointer' }}
                       onClick={()=>{ onClick(rowIndex, 'icon1', value, icon1, week, manager, producer, crop, cropId, isParent)}}
                    >
                        {icon1}
                    </i>
                </div>
                <div style={{ width: 30 }}>
                    <i className="material-icons"
                       style={{ fontSize: 20, cursor: 'pointer', paddingLeft: 10 }}
                       onClick={()=>{ onClick(rowIndex, 'icon2', value, icon2, week, manager, producer, crop, cropId, isParent)}}
                    >
                        {icon2}
                    </i>
                </div>
            </div>
        </div>
    );
}


/**
 * Cell renderer Input 2: input element rendering for edit modes with a simple view element
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param onChange
 * @param onBlur
 * @param rowIndex
 * @param columnIndex
 * @param onClick
 */
export function cellRendererInput2 ({ key, style, elements, functions, onChange, onBlur, rowIndex, columnIndex, onClick }){

    let element1;
    let magnitude = elements[0] && elements[0].magnitude ? elements[0].magnitude : false;

    let customCellStyle;
    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle = merge(customCellStyle, style);
    customCellStyle.padding = '0.5em';

    let customInputStyle = Object.assign({}, inputStyle);
    customInputStyle.height = 18;
    customInputStyle.width = magnitude ? customCellStyle.width - 30 : customCellStyle.width - 10;
    customInputStyle.padding = 0;

    element1 = (
        <div key={`${key}element1`}
             style={elements[0].style || {}}
        >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <input
                    style={customInputStyle}
                    onChange={(e)=>{ onChange(e.target.value, rowIndex, columnIndex, elements[0].adjustedWeek, elements[0].manager, elements[0].producer, elements[0].crop, elements[0].cropId)}}
                    onBlur={(e)=>{ onBlur(e.target.value, rowIndex, columnIndex, elements[0].adjustedWeek, elements[0].manager, elements[0].producer, elements[0].crop, elements[0].cropId)}}
                    value={elements[0].value}
                />
                {magnitude ? (
                    <span>
                        %
                    </span>
                ) : null}
            </div>
        </div>
    );

    return (
        <div
            key={key}
            style={customCellStyle}
        >
            <div>
                {element1}
            </div>
        </div>
    );
}


/**
 * DraggableCellRenderer: input element rendering for edit modes with a simple view element
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param rowIndex
 * @param moveCell
 */
export function draggableCellRenderer ({ key, style, elements, rowIndex, functions, moveCell }){

    let customCellStyle, cellContent, icon, canDrag = true;

    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle.padding = '0.5em';
    customCellStyle = merge(customCellStyle, style);

    cellContent = elements.map((element, index) => {
        let elementContent;
        icon = element.icon;
        canDrag = element.canDrag;

        // Return element based on data type
        switch(element.type) {
            case 'date':
            case 'datetime':
                elementContent = element.hasOwnProperty('value') ? moment(element.value).format(element.format || '') : '';
                break;
            case 'text':
            case 'string':
                elementContent = parseObjectValueToString(element.value);
                break;
            case 'number':
                elementContent = element.hasOwnProperty('value') ? numeral(element.value).format(element.format || '') : 0;
                break;
            case 'function':
                elementContent = element.value();
                break;
            case 'boolean':
                elementContent = element.value ? i18n.t('common.yes') : i18n.t('common.no');
                break;
            default:
                break;
        }

        return (
            <div
                key={key + index}
                style={element.style || {}}
            >
                {elementContent}
                {icon ? (
                    <div style={{ height: 20 }}>
                        <i className="material-icons"
                           style={{ fontSize: 20 }}
                        >
                            {icon}
                        </i>
                    </div>
                ) : null}
            </div>
        );
    });

    customCellStyle.cursor = canDrag ? 'move' : 'default';

    // Returns custom draggable cell. Check if hoverCell function is needed in the future
    return (
        <DraggableCell
            key={key}
            id={rowIndex}
            canDrag={canDrag}
            moveCell={(originalKey)=>{ moveCell(rowIndex, originalKey)}}
            hoverCell={()=> {}}
            cellContent={(
                <div
                    key={key}
                    style={customCellStyle}
                >
                    {cellContent}
                </div>
            )}
        />
    );
}

/**
 * resizableCellRenderer
 * @param key
 * @param style
 * @param elements
 * @param rowIndex
 * @param columnIndex
 * @param functions
 * @param onResize
 */
export function resizableCellRenderer ({ key, style, elements, rowIndex, columnIndex, functions, onResize }){

    let customCellStyle, cellContent, axis = 'x';

    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle.padding = '0.5em';
    customCellStyle = merge(customCellStyle, style);

    cellContent = elements.map((element, index) => {
        let elementContent;

        // Return element based on data type
        switch(element.type) {
            case 'date':
            case 'datetime':
                elementContent = element.hasOwnProperty('value') ? moment(element.value).format(element.format || '') : '';
                break;
            case 'text':
            case 'string':
                elementContent = parseObjectValueToString(element.value);
                break;
            case 'number':
                elementContent = element.hasOwnProperty('value') ? numeral(element.value).format(element.format || '') : 0;
                break;
            case 'function':
                elementContent = element.value();
                break;
            case 'boolean':
                elementContent = element.value ? i18n.t('common.yes') : i18n.t('common.no');
                break;
            default:
                break;
        }

        return (
            <div
                key={key + index}
                style={element.style || {}}
            >
                {elementContent}
            </div>
        );
    });

    let top = customCellStyle.top;
    let left = customCellStyle.left;

    let modifiedCellStyle = cloneDeep(customCellStyle);
    modifiedCellStyle.top = 0;
    modifiedCellStyle.left = 0;

    return (
        <div
            key={`parent${key}`}
            style={{}}>
            <ResizableCell
                key={key}
                id={rowIndex}
                top={top}
                left={left}
                handleSize={[customCellStyle.width, customCellStyle.height]}
                width={customCellStyle.width}
                height={customCellStyle.height}
                axis={axis}
                onResize={(e, data)=> { onResize(e, columnIndex, data)}}
                cellContent={(
                    <div
                        key={key}
                        style={modifiedCellStyle}
                    >
                        {cellContent}
                    </div>
                )}
            />
        </div>
    );
}


/**
 * resizableCellRenderer
 * @param key
 * @param style
 * @param elements
 * @param functions
 * @param onClick
 * @param rowIndex
 * @param columnIndex
 * @param onResize
 */
export function resizableCellRendererClick ({ key, style, elements, functions, onClick, rowIndex, columnIndex, onResize }){

    let customCellStyle, cellContent, axis = 'x', icon = '', dataField;

    customCellStyle = functions.skipPredefinedStyle ? {} : Object.assign({}, simpleElementCellStyle);
    customCellStyle.padding = '0.5em';
    customCellStyle = merge(customCellStyle, style);

    cellContent = elements.map((element, index) => {
        let elementContent;
        icon = element.icon || '';
        dataField = element.dataField;

        // Return element based on data type
        switch(element.type) {
            case 'date':
            case 'datetime':
                elementContent = element.hasOwnProperty('value') ? moment(element.value).format(element.format || '') : '';
                break;
            case 'text':
            case 'string':
                elementContent = parseObjectValueToString(element.value);
                break;
            case 'number':
                elementContent = element.hasOwnProperty('value') ? numeral(element.value).format(element.format || '') : 0;
                break;
            case 'function':
                elementContent = element.value();
                break;
            case 'boolean':
                elementContent = element.value ? i18n.t('common.yes') : i18n.t('common.no');
                break;
            default:
                break;
        }

        return (
            <div
                key={key + index}
                style={element.style || {}}
            >
                {elementContent}
            </div>
        );
    });

    let iconDiv = null;
    if (icon === 'asc') {
        iconDiv = (
            <i className="material-icons" style={{ height: 20, width: 20 }}>arrow_drop_up</i>
        )
    } else if (icon === 'desc') {
        iconDiv = (
            <i className="material-icons" style={{ height: 20, width: 20 }}>arrow_drop_down</i>
        )
    }

    let justifyContentValue;
    switch (customCellStyle.textAlign) {
        case 'left':
            justifyContentValue = 'flex-start';
            break;
        case 'right':
            justifyContentValue = 'flex-end';
            break;
        case 'center':
            justifyContentValue = 'center';
            break;
        default:
            justifyContentValue = 'flex-start';
    }

    let top = customCellStyle.top;
    let left = customCellStyle.left;

    let modifiedCellStyle = cloneDeep(customCellStyle);
    modifiedCellStyle.top = 0;
    modifiedCellStyle.left = 0;

    return (
        <div
            key={`parent${key}`}
            style={{}}>
            <ResizableCell
                key={key}
                id={rowIndex}
                top={top}
                left={left}
                handleSize={[customCellStyle.width, customCellStyle.height]}
                width={customCellStyle.width}
                height={customCellStyle.height}
                axis={axis}
                onResize={(e, data)=> { onResize(e, columnIndex, data)}}
                cellContent={(
                    <div
                        key={key}
                        style={modifiedCellStyle}
                        onClick={(e) => { onClick(rowIndex, columnIndex, dataField)}}
                    >
                        <div style={{ display: 'flex', justifyContent: justifyContentValue }}>
                            {iconDiv}
                            {cellContent}
                        </div>
                    </div>
                )}
            />
        </div>
    );
}


/**
 * Cell renderer 1: simple element rendering for view mode grids
 * @param key
 * @param style
 * @param elements
 * @param functions
 */
export function cellRendererNull ({ key, style, elements, functions }){

    let customCellStyle = {}, cellContent = null;

    return (
        <div
            key={key}
            style={customCellStyle}
        >
            {cellContent}
        </div>
    );
}