import request from "@biuwer/common/src/libs/superagent";
import i18n from '@biuwer/core/src/i18n';

import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";
import { AUTH_TOKENS_DETAIL } from './auth-tokens-gql';

/*
 * Auth Tokens action types
 */
export const AUTH_TOKENS_INITIALIZE = 'AUTH_TOKENS_INITIALIZE';
export const AUTH_TOKEN_DETAIL_INITIALIZE = 'AUTH_TOKEN_DETAIL_INITIALIZE';
export const AUTH_TOKEN_LIST_INITIALIZE = 'AUTH_TOKEN_LIST_INITIALIZE';

export const AUTH_TOKENS_FETCH_REQUEST = 'AUTH_TOKENS_FETCH_REQUEST';
export const AUTH_TOKENS_FETCH_SUCCESS = 'AUTH_TOKENS_FETCH_SUCCESS';
export const AUTH_TOKENS_FETCH_ERROR = 'AUTH_TOKENS_FETCH_ERROR';

export const AUTH_TOKEN_FETCH_REQUEST = 'AUTH_TOKEN_FETCH_REQUEST';
export const AUTH_TOKEN_FETCH_SUCCESS = 'AUTH_TOKEN_FETCH_SUCCESS';
export const AUTH_TOKEN_FETCH_ERROR = 'AUTH_TOKEN_FETCH_ERROR';

export const AUTH_TOKEN_CREATE_REQUEST = 'AUTH_TOKEN_CREATE_REQUEST';
export const AUTH_TOKEN_CREATE_SUCCESS = 'AUTH_TOKEN_CREATE_SUCCESS';
export const AUTH_TOKEN_CREATE_ERROR = 'AUTH_TOKEN_CREATE_ERROR';

export const AUTH_TOKEN_UPDATE_REQUEST = 'AUTH_TOKEN_UPDATE_REQUEST';
export const AUTH_TOKEN_UPDATE_SUCCESS = 'AUTH_TOKEN_UPDATE_SUCCESS';
export const AUTH_TOKEN_UPDATE_ERROR = 'AUTH_TOKEN_UPDATE_ERROR';

export const AUTH_TOKEN_DELETE_REQUEST = 'AUTH_TOKEN_DELETE_REQUEST';
export const AUTH_TOKEN_DELETE_SUCCESS = 'AUTH_TOKEN_DELETE_SUCCESS';
export const AUTH_TOKEN_DELETE_ERROR = 'AUTH_TOKEN_DELETE_ERROR';

// Generic Returns Skeleton
const generateSkeleton = (type, body, context = 'primary') => {
    let skeleton = { type };

    switch (type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false,
                context: context
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                context: context
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body,
                context: context
            };
            break;
        default:
            break;
    }

    switch(type){
        case AUTH_TOKEN_CREATE_SUCCESS:
            skeleton.created = true;
            break;
        case AUTH_TOKEN_UPDATE_SUCCESS:
            skeleton.updated = true;
            break;
        case AUTH_TOKEN_DELETE_SUCCESS:
            skeleton.deleted = true;
            break;
        case AUTH_TOKEN_FETCH_ERROR:
            skeleton.issuePayload = {
                ...skeleton.issuePayload || {},
                // code: extraArgs.code
            };
            break;
        default:
            break;
    }

    return skeleton;
};


/**
 * Initialize Auth Tokens
 */
export function initializeAuthTokens() {
    return {
        type: AUTH_TOKENS_INITIALIZE
    };
}

/**
 * Initialize auth token detail action
 */
export function initializeAuthTokensDetail() {
    return {
        type: AUTH_TOKEN_DETAIL_INITIALIZE
    };
}

/**
 * Initialize auth token list action
 */
export function initializeAuthTokensList() {
    return {
        type: AUTH_TOKEN_LIST_INITIALIZE
    };
}

/**
 * getAuthToken: Reads the requested auth token
 * @param authTokenId - Id belonging to the requested auth token
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function getAuthToken(authTokenId, context  = 'primary', gql = AUTH_TOKENS_DETAIL) {

    return async dispatch => {
        try {

            const query = {
                query: `query($authTokenId: String!) {
                    readAuthToken(authTokenId: $authTokenId) {
                        ${gql}
                    }
                }`,
                variables: {
                    authTokenId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(AUTH_TOKEN_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(AUTH_TOKEN_FETCH_ERROR, 'Error getting auth token data from server', context));
                } else if (res.body && res.body.data && res.body.data.readAuthToken) {
                    dispatch(generateSkeleton(AUTH_TOKEN_FETCH_SUCCESS, res.body.data.readAuthToken, context));
                }
            }


        } catch (err) {
            dispatch(generateSkeleton(AUTH_TOKEN_FETCH_ERROR, err, context));
        }
    }
}

/**
 * queryAuthToken - Reads multiple auth tokens that satisfies given dataQuery
 * @param query - Query to use as a filter when reading auth tokens
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function queryAuthTokens(query = {}, context  = 'primary', gql = AUTH_TOKENS_DETAIL) {

    return async dispatch => {
        try {

            const graphQuery = {
                query: `query($query: QueryAuthTokenInput!) {
                    queryAuthTokens(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(AUTH_TOKENS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(graphQuery)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(AUTH_TOKENS_FETCH_ERROR, 'Error getting auth tokens data from server', context));
                } else if (res.body && res.body.data && res.body.data.queryAuthTokens) {
                    dispatch(generateSkeleton(AUTH_TOKENS_FETCH_SUCCESS, res.body.data.queryAuthTokens, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(AUTH_TOKENS_FETCH_ERROR, err, context));
        }
    };
}

/**
 * createAuthToken - Creates a new auth token
 * @param authTokenObject - Object with the necessary information to create a new auth token
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function createAuthToken(authTokenObject, context = 'primary', gql = AUTH_TOKENS_DETAIL){

    return async dispatch => {
        try {

            const query = {
                query: `mutation($authTokenObject: AuthTokenInput!) {
                    createAuthToken(authToken: $authTokenObject) {
                        ${gql}
                    }
                }`,
                variables: {
                    authTokenObject
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(AUTH_TOKEN_CREATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(AUTH_TOKEN_CREATE_ERROR, 'Error creating auth token', context));
                    dispatch(addNotification({
                        styleType: 'error',
                        message: i18n.t('notifications.error')
                    }));
                } else if (res.body && res.body.data && res.body.data.createAuthToken) {
                    dispatch(generateSkeleton(AUTH_TOKEN_CREATE_SUCCESS, res.body.data.createAuthToken, context));
                    dispatch(addNotification({
                        styleType: 'success',
                        message: i18n.t('notifications.createSuccess', { name: i18n.t('authTokens.title'), context: 'male', count: 1 })
                    }));
                }
            }


        } catch (err) {
            dispatch(generateSkeleton(AUTH_TOKEN_CREATE_ERROR, err, context));
        }
    };
}

/**
 * updateAuthToken - Creates a new auth token
 * @param authTokenId - Id of the auth token to update
 * @param authTokenObject - Object with the neccesary information to create a new auth token
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function updateAuthToken(authTokenId, authTokenObject, context = 'primary', gql = AUTH_TOKENS_DETAIL) {

    return async dispatch => {
        try {

            const query = {
                query: `mutation($authTokenId: String!, $authTokenObject: AuthTokenInput!) {
                    updateAuthToken(authTokenId: $authTokenId, authToken: $authTokenObject) {
                        ${gql}
                    }
                }`,
                variables: {
                    authTokenId,
                    authTokenObject
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(AUTH_TOKEN_UPDATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(AUTH_TOKEN_UPDATE_ERROR, 'Error updating auth token', context));
                    dispatch(addNotification({
                        styleType: 'error',
                        message: i18n.t('notifications.error')
                    }));
                } else if (res.body && res.body.data && res.body.data.updateAuthToken) {
                    dispatch(generateSkeleton(AUTH_TOKEN_UPDATE_SUCCESS, res.body.data.updateAuthToken, context));
                    dispatch(addNotification({
                        styleType: 'success',
                        message: i18n.t('notifications.updateSuccess', { name: i18n.t('authTokens.title'), context: 'male', count: 1 })
                    }));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(AUTH_TOKEN_UPDATE_ERROR, err, context));
        }
    };
}

/**
 * deleteAuthToken - Creates a new auth token
 * @param authTokenId - Id of the auth token to delete
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function deleteAuthToken(authTokenId, context = 'primary', gql = AUTH_TOKENS_DETAIL){

    return async dispatch => {
        try {

            const query = {
                query: `mutation($authTokenId: String!) {
                    deleteAuthToken(authTokenId: $authTokenId) {
                        ${gql}
                    }
                }`,
                variables: {
                    authTokenId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(AUTH_TOKEN_DELETE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(AUTH_TOKEN_DELETE_ERROR, 'Error deleting auth token', context));
                    dispatch(addNotification({
                        styleType: 'error',
                        message: i18n.t('notifications.error')
                    }));
                } else if (res.body && res.body.data && res.body.data.deleteAuthToken) {
                    dispatch(generateSkeleton(AUTH_TOKEN_DELETE_SUCCESS, res.body.data.deleteAuthToken, context));
                    dispatch(addNotification({
                        styleType: 'success',
                        message: i18n.t('notifications.deleteSuccess', { name: i18n.t('authTokens.title'), context: 'male', count: 1 })
                    }));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(AUTH_TOKEN_DELETE_ERROR, err, context));
        }
    };
}
