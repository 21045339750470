import { decodeJwt } from "jose";
import moment from 'moment';
import { find } from "lodash";

// Import biuwer constants
import {
    BIUWER_APP_NAME,
    BIUWER_SHARE_APP_NAME,
    BIUWER_APP_PREFIX,
    BIUWER_SHARE_APP_PREFIX,
    AUTH_TOKEN_NAME,
    USER_NAME,
    LANGUAGE,
    MASQUERADE_USER_AUTH_TOKEN_NAME,
    MASQUERADE_USER_NAME,
    MASQUERADE_USER_PATH_NAME
} from "@biuwer/core/src/constants";

// Libs
import { SESSION_TOKEN } from "@biuwer/redux/src/system/system-variables/system-variables-lib";

// Actions
import { clientSignout }  from "@biuwer/redux/src/system/auth/session-actions";
import { setSystemVariable } from "@biuwer/redux/src/system/system-variables/system-variables-actions";

class Auth {

    // App name
    initApp(app = BIUWER_APP_NAME) {
        this.app = app;
        this.cache = new Map();
    }

    // Save redux store in Auth instance for internal access
    init(store) {
        this.store = store;
    }

    // Helpers

    /**
     * Returns localStorage key with app prefix and specified variable name string
     * @param {String} varName
     * @returns {String}
     */
    formatLocalStorageKey(varName) {
        return {
            [BIUWER_APP_NAME]: `${BIUWER_APP_PREFIX}.${varName}`,
            [BIUWER_SHARE_APP_NAME]: `${BIUWER_SHARE_APP_PREFIX}.${varName}`
        }[this.app] || `${BIUWER_APP_PREFIX}.${varName}`;
    }


    // LocalStorage CRUD methods

    /**
     * Returns item from localStorage
     * @param {String} varName
     * @returns {String | undefined}
     */
    getItemFromLocalStorage(varName) {
        return localStorage.getItem(this.formatLocalStorageKey(varName));
    }

    getItemFromLocalMemory(varName) {
        return this.cache.get(this.formatLocalStorageKey(varName));
    }

    getItem(varName) {
        return this.app === BIUWER_APP_NAME ?
            this.getItemFromLocalStorage(varName) :
            this.getItemFromLocalMemory(varName);
    }

    /**
     * Fetch JWT Token from LocalStorage. Returns the token (well or malformed - its not validated in this process)
     * @returns {String | null}
     */
    getLocalJwt() {
        return this.getItem(AUTH_TOKEN_NAME) || null;
    }

    /**
     * Fetch masquerade JWT Token from LocalStorage. Returns the token (well or malformed - its not validated in this process)
     * @returns {String | null}
     */
    getMasqueradeLocalJwt() {
        return this.getItem(MASQUERADE_USER_AUTH_TOKEN_NAME);
    }

    /**
     * Returns the user object. When a user is logged in the user object must be stored in localStorage
     * @returns {Object | undefined}
     */
    getUser() {
        const user = this.getItem(USER_NAME)
        return user ? JSON.parse(user) : undefined;
    }

    /**
     * Returns the masquerade user object. When a masquerade user is logged in the masquerade user object must be stored in localStorage
     * @returns {Object | undefined}
     */
    getMasqueradeUser() {
        const masqueradeUser = this.getItem(MASQUERADE_USER_NAME);
        return masqueradeUser ? JSON.parse(masqueradeUser) : undefined;
    }

    /**
     * Returns the masquerade user path.
     * @returns {String | null}
     */
    getMasqueradeUserPath() {
        return this.getItem(MASQUERADE_USER_PATH_NAME)
    }

    /**
     * Return the current organization Id of the user. It is fetched from the JWT token.
     * @return {Number | null}
     */
    getOrganizationId() {
        const token = this.getLocalJwt();
        if (this.isTokenExpired(token)) return null;
        const decoded = decodeJwt(token);
        return decoded.organization;
    }

    /**
     * Return the current (active) organization name or null.
     * @returns {Object | null}
     */
    getOrganizationInfo() {
        const user = this.getUser();
        if(user && this.getOrganizationId()) {
            const org = find(user.organizations.map(organization => organization.organization), { '_id': this.getOrganizationId()});
            if(org) {
                return {
                    _id: org._id,
                    name: org.name,
                    settings: org.settings,
                    status: org.status,
                    trial: org.trial,
                    accumulated_features: org.accumulated_features,
                    automatic_billing: org.automatic_billing,
                    apply_feature_wall: org.apply_feature_wall
                };
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    /**
     * Return the current (active) user's organization (object: _id, name) or null.
     * @returns {Object | null}
     */
    getCurrentOrganization() {
        let currentOrganization,
            name = this.getOrganizationInfo() ? this.getOrganizationInfo().name : null,
            settings = this.getOrganizationInfo() ? this.getOrganizationInfo().settings : null,
            status = this.getOrganizationInfo() ? this.getOrganizationInfo().status : null,
            trial = this.getOrganizationInfo() ? this.getOrganizationInfo().trial : null,
            accumulated_features = this.getOrganizationInfo() ? this.getOrganizationInfo().accumulated_features : null,
            apply_feature_wall = this.getOrganizationInfo() ? this.getOrganizationInfo().apply_feature_wall : null,
            automatic_billing = this.getOrganizationInfo() ? this.getOrganizationInfo().automatic_billing : null;

        if (this.getOrganizationId() && name) {
            currentOrganization = {
                _id: this.getOrganizationId(),
                name: name,
                settings: settings,
                status: status,
                trial: trial,
                accumulated_features: accumulated_features,
                apply_feature_wall: apply_feature_wall,
                automatic_billing: automatic_billing
            };
            return currentOrganization;
        } else {
            return null;
        }
    }

    /**
     * Set item to localStorage
     * @param {String} varName
     * @param {String} varData
     */
    setItemToLocalStorage(varName, varData) {
        localStorage.setItem(this.formatLocalStorageKey(varName), varData);
    }

    setItemToLocalMemory(varName, varData) {
        this.cache.set(this.formatLocalStorageKey(varName), varData);
    }

    setItem(varName, varData) {
        this.app === BIUWER_APP_NAME ?
            this.setItemToLocalStorage(varName, varData) :
            this.setItemToLocalMemory(varName, varData);
    }

    /**
     * Set the user object
     * @param {Object} user
     */
    setUser(user) {
        this.setItem(USER_NAME, JSON.stringify(user));
    }

    /**
     * Set user language
     * @param {Object} userSettings
     * @param {Object} organizationSettings
     */
     setLanguage(userSettings, organizationSettings) {
        let language = userSettings.language;
        if (language === '*') language = organizationSettings.language;
        if (language == null) language = this.getItem(LANGUAGE);
        this.setItem(LANGUAGE, language);
    }

    /**
     * Set local jwt token
     * @param {Object} user
     */
    setLocalJwt(token) {
        this.setItem(AUTH_TOKEN_NAME, token);
    }

    /**
     * Set the masquerade user object
     * @param {Object} user
     */
    setMasqueradeUser(user) {
        this.setItem(MASQUERADE_USER_NAME, JSON.stringify(user));
    }

    /**
     * Set the masquerade user jwt token
     * @param {Object} token
     */
    setMasqueradeUserJwtToken(token) {
        this.setItem(MASQUERADE_USER_AUTH_TOKEN_NAME, token);
    }

    /**
     * Set the masquerade user path
     * @param {Object} path
     */
    setMasqueradeUserPath(path) {
        this.setItem(MASQUERADE_USER_PATH_NAME, path);
    }

    /**
     * Delete item from localStorage
     * @param {String} varName
     */
    deleteItemFromLocalStorage(varName) {
        localStorage.removeItem(this.formatLocalStorageKey(varName));
    }

    deleteItemFromLocalMemory(varName) {
        this.cache.delete(this.formatLocalStorageKey(varName));
    }

    deleteItem(varName) {
        this.app === BIUWER_APP_NAME ?
            this.deleteItemFromLocalStorage(varName) :
            this.deleteItemFromLocalStorage(varName);
    }

    /**
     * Delete local jwt token
     */
    deleteLocalJwt() {
        this.deleteItem(AUTH_TOKEN_NAME);
    }

    /**
     * Delete the user object
     */
    deleteUser() {
        this.deleteItem(USER_NAME);
    }

    /**
     * Delete masquerade user
     */
    deleteMasqueradeUser() {
        this.deleteItem(MASQUERADE_USER_NAME);
    }

    /**
     * Delete masquerade local jwt token
     */
    deleteMasqueradeLocalJwt() {
        this.deleteItem(MASQUERADE_USER_AUTH_TOKEN_NAME);
    }

    /**
     * Delete masquerade user path
     */
    deleteMasqueradeUserPath() {
        this.deleteItem(MASQUERADE_USER_PATH_NAME);
    }

    // Method to delete all masquerade data
    deleteMasqueradeData() {
        this.deleteMasqueradeUser();
        this.deleteMasqueradeLocalJwt();
        this.deleteMasqueradeUserPath();
    }

    // Other methods

    /**
     * Check if the JWT Token is expired. Returns true if the token is expired or not decoded properly (malformed), false otherwise.
     * @param {String} token
     * @returns {Boolean}
     */
    isTokenExpired(token) {
        if (!token) return true;
        // const decoded = jwt.decode(token);
        const decoded = decodeJwt(token);
        if (!decoded) return true;
        const tokenExp = decoded.exp;
        const now = moment().unix().valueOf();
        if (tokenExp - now <= 0) return true;
        return false;
    }

    /**
     * Logout removes user, authToken, masquerade user, masquerade authToken and masquerade user path from LocalStorage
     */
    signout() {
        this.deleteUser();
        this.deleteLocalJwt();
        this.deleteMasqueradeUser();
        this.deleteMasqueradeLocalJwt();
        this.deleteMasqueradeUserPath();
    }

    /**
     * Returns true or false if the user is Loggedin or not and save it in redux
     * @returns {Boolean}
     */
    isLoggedIn() {

        // Get isLoggedIn
        const token = this.getLocalJwt();
        const isLoggedIn = !!token && !!this.getUser() && !this.isTokenExpired(token);

        // Return isLoggedIn
        return isLoggedIn;
    }

    /**
     * Check if the user is allowed to execute the permission
     * @param permissionId - Can be a string (single permission) or an array of strings (multiple permissions)
     * @param state
     * @returns {Boolean}
     */
    isAuthorized(permissionId, state = null) {
        if (!state) state = this.store.getState();
        let perms = state.system.auth && state.system.auth.session && state.system.auth.session.toJS() && state.system.auth.session.toJS().permissions ? state.system.auth.session.toJS().permissions.payload : null;

        if (!perms || (Object.keys(perms).length === 0 && perms.constructor === Object)) {
            return false;
        }

        // A single permission transform in array
        if (typeof permissionId === 'string') {
            permissionId = [permissionId];
        }

        // Array of permissions
        if (permissionId instanceof Array) {
            /**
             * Local function to obtain deep object value based on path ('field.subfield')
             * @param obj - Object to find in
             * @param path - The path to look at the object and extract the value
             * @returns The object value in the path passed via params. If not found returns undefined
             */
            function deepValue(obj, path) {
                let paths = path.split('.'),
                    current = obj;

                if (obj === undefined) {
                    return undefined;
                }

                for (let i = 0; i < paths.length; i++) {
                    if (current[paths[i]] === undefined) {
                        return undefined;
                    } else {
                        current = current[paths[i]];
                    }
                }
                return current;
            }
            return permissionId.some((permission) => !!deepValue(perms, permission));
        } else {
            return false;
        }
    }

    /**
     * Fetch JWT Token from headers
     * @param {String} authHeader String composed by type of Authentication and the token. Example: Bearer <token>
     * @returns {String | null}
     */
    getHeaderJwt(authHeader) {
        if (!authHeader) return null;
        const part = authHeader.split(' ');
        if (!part || part.length !== 2 || part[0] !== "Bearer" || typeof part[1] !== "string") return null;
        return part[1];
    }

    /**
     * When a request of superagent is made, we check the response to save the token to localStorage
     */
    checkResponse(response) {
        if (response.status === 401) {
            clientSignout(this.store.dispatch);
        } else {
            let token = this.getLocalJwt(),
                newToken = this.getHeaderJwt(response.headers.authorization);
            if (newToken && (newToken !== token)) {
                this.setLocalJwt(newToken);
                this.store.dispatch(setSystemVariable(SESSION_TOKEN, newToken));
            }
        }
    }

    getAuthApp() {
        return this.app;
    }

    /**
     * Regular expressions to check complete and specific password strength requirements
     * */
    passwordRegExp = {
        complete: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$[\]€\-_!%#@*?~=(){}<>.,])(?=.{7,})/),
        specific: {
            minus: new RegExp(/^(?=.*[a-z])/),
            mayus: new RegExp(/^(?=.*[A-Z])/),
            number: new RegExp(/^(?=.*[0-9])/),
            special: new RegExp(/^(?=.*[$[\]€\-_!%#@*?~=(){}<>.,])/),
            length: new RegExp(/^(?=.{7,})/)
        }
    };

}

export default new Auth();