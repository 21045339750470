
// Default query for roles actions
export const GROUPS_USERS_ROLE_DETAIL = `
    _id
    name
    role_type
    status
    created
    updated
    groups {
        _id
        name
    }
    users {
        _id
        name
        organization1
    }
`;

// Default query for role detail page
export const ROLE_DETAIL_PROFILE = `
    _id
    name
    desc
    status
    created
    role_type
    users {
        _id
        name
        full_name
        email
        type
        status
        org_status
        organization1
    }
    groups {
        _id
        name
        desc
        status
        group_type
    }
`;

// Basic role for roles list (add roles to users/groups, etc ...)
export const BASIC_ROLE_DETAIL = `
    _id
    name
    role_type
    users {
        _id
        organization1
    }
    groups {
        _id
    }
`;