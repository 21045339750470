import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import History from "@biuwer/core/src/history";
import gqlRequest from '@biuwer/core/src/graphql-request';
import i18n from "@biuwer/core/src/i18n";
import { defaultContext } from "@biuwer/redux/src/config/constants";

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { DATASETS_LIST_DETAIL, DATASETS_DETAIL, MANAGED_DATASET_EXECUTIONS } from './datasets-gql';
import utilsLib from '@biuwer/common/src/libs/utils-lib';

/*
 * Dataset action types
 */

export const DATASET_INITIALIZE = 'DATASET_INITIALIZE';
export const DATASET_LIST_INITIALIZE = 'DATASET_LIST_INITIALIZE';
export const DATASET_DETAIL_INITIALIZE = 'DATASET_DETAIL_INITIALIZE';

export const DATASETS_FETCH_REQUEST = 'DATASETS_FETCH_REQUEST';
export const DATASETS_FETCH_SUCCESS = 'DATASETS_FETCH_SUCCESS';
export const DATASETS_FETCH_ERROR = 'DATASETS_FETCH_ERROR';

export const DATASET_FETCH_REQUEST = 'DATASET_FETCH_REQUEST';
export const DATASET_FETCH_SUCCESS = 'DATASET_FETCH_SUCCESS';
export const DATASET_FETCH_ERROR = 'DATASET_FETCH_ERROR';

export const DATASET_CREATE_REQUEST = 'DATASET_CREATE_REQUEST';
export const DATASET_CREATE_SUCCESS = 'DATASET_CREATE_SUCCESS';
export const DATASET_CREATE_ERROR = 'DATASET_CREATE_ERROR';

export const DATASET_UPDATE_REQUEST = 'DATASET_UPDATE_REQUEST';
export const DATASET_UPDATE_SUCCESS = 'DATASET_UPDATE_SUCCESS';
export const DATASET_UPDATE_ERROR = 'DATASET_UPDATE_ERROR';

export const DATASET_DELETE_REQUEST = 'DATASET_DELETE_REQUEST';
export const DATASET_DELETE_SUCCESS = 'DATASET_DELETE_SUCCESS';
export const DATASET_DELETE_ERROR = 'DATASET_DELETE_ERROR';

export const DATASET_QUERY_REQUEST = 'DATASET_QUERY_REQUEST';
export const DATASET_QUERY_SUCCESS = 'DATASET_QUERY_SUCCESS';
export const DATASET_QUERY_ERROR = 'DATASET_QUERY_ERROR';

export const DATASET_IMPORT_REQUEST = 'DATASET_IMPORT_REQUEST';
export const DATASET_IMPORT_SUCCESS = 'DATASET_IMPORT_SUCCESS';
export const DATASET_IMPORT_ERROR = 'DATASET_IMPORT_ERROR';

export const DATASET_VIEW_TEST_REQUEST = 'DATASET_VIEW_TEST_REQUEST';
export const DATASET_VIEW_TEST_SUCCESS = 'DATASET_VIEW_TEST_SUCCESS';
export const DATASET_VIEW_TEST_ERROR = 'DATASET_VIEW_TEST_ERROR';

export const MANAGED_DATASET_EXECUTIONS_REQUEST = 'MANAGED_DATASET_EXECUTIONS_REQUEST';
export const MANAGED_DATASET_EXECUTIONS_SUCCESS = 'MANAGED_DATASET_EXECUTIONS_SUCCESS';
export const MANAGED_DATASET_EXECUTIONS_ERROR = 'MANAGED_DATASET_EXECUTIONS_ERROR';

// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, showNotification = true, extraArgs) => {
    let skeleton = { type, context }, notification;
    const history = History.getHistory();

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)){
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body?.status || body?.statusCode,
                    code: body?.issuePayload ? body?.issuePayload?.code : body?.statusCode, // Backward compatibility with old error handling
                    message: body?.message || body?.errorMessage
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body.payload
            };

            if (type === 'DATASET_UPDATE_SUCCESS') {
                skeleton.updated = true;
            }

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:

            break;
    }

    switch(type){
        case DATASET_IMPORT_SUCCESS:
            skeleton.payload = body;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.importSuccess', { name: i18n.t('datasets.datasetLabel'), context: 'male', count: body.length })
            };

            // Redirect to datasets list page
            const locationPath = history.location.pathname.split('/');
            const reverseEngineeringGenericPage = history.location.pathname === '/data-center/reverse-engineering';
            const reverseEngineeringDatasetPage = locationPath[1] === 'data-center' && locationPath[2] === 'datasets' && locationPath[4] === 'reverse-engineering';
            if (reverseEngineeringGenericPage || reverseEngineeringDatasetPage) {
                history.push(`/data-center/datasets`);
            }
            break;
        case DATASET_UPDATE_SUCCESS:
            skeleton.payload = body;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('datasets.datasetLabel'), context: 'male', count: 1 })
            };
            break;
        case DATASET_CREATE_SUCCESS:
            skeleton.payload = body;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', { name: i18n.t('datasets.datasetLabel'), context: 'male', count: 1 })
            };
            break;
        case DATASET_DELETE_SUCCESS:
            skeleton.payload = body;
            skeleton.deleted = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', { name: i18n.t('datasets.datasetLabel'), context: 'male', count: 1 })
            };
            break;
        case DATASET_FETCH_SUCCESS:
        case DATASETS_FETCH_SUCCESS:
        case MANAGED_DATASET_EXECUTIONS_SUCCESS:
            skeleton.payload = body;
            break;
        case DATASET_QUERY_REQUEST:
            skeleton.dataset = extraArgs.dataset;
            skeleton.queryAlias = extraArgs.queryAlias;
            skeleton.source = extraArgs.source;

            break;
        case DATASET_QUERY_SUCCESS:
            skeleton.dataset = extraArgs.dataset;
            skeleton.queryAlias = extraArgs.queryAlias;
            skeleton.source = extraArgs.source;
            skeleton.dataWarnings = extraArgs.dataWarnings;
            skeleton.dataErrors = extraArgs.dataErrors;

            break;
        case DATASET_QUERY_ERROR:
            skeleton.dataset = extraArgs.dataset;
            skeleton.queryAlias = extraArgs.queryAlias;
            skeleton.source = extraArgs.source;

            break;
        case DATASET_CREATE_ERROR:
        case DATASET_UPDATE_ERROR:
        case DATASET_DELETE_ERROR:
        case DATASETS_FETCH_ERROR:
        case DATASET_IMPORT_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        // In this case does not show notifications
        case DATASET_FETCH_ERROR:
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    }
};

/**
 * Get Datasets action
 */

export function getDatasets(query = {}, context = defaultContext, gql = DATASETS_LIST_DETAIL, sort = {}) {

    return async (dispatch) => {
        try {
            const gqlQuery = {
                query: `query($query: QueryDatasetInput!, $sort: SortDatasetInput!) {
                    readDatasets(query: $query, sort: $sort) {
                        ${gql}
                    }
                }`,
                variables: {
                    query: query,
                    sort: sort
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(DATASETS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .set("app", Auth.getAuthApp())
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body && res.body.data && res.body.data.readDatasets) {
                    dispatch(generateSkeleton(DATASETS_FETCH_SUCCESS, res.body.data.readDatasets, context));
                }
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(DATASETS_FETCH_ERROR, res.body.errors[0], context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(DATASETS_FETCH_ERROR, err, context));
        }
    };
}

/**
 * Initialize Dataset
 */
export const initializeDataset = (context = defaultContext) => {
    return {
        type: DATASET_INITIALIZE,
        context: context
    };
};

/**
 * Initialize Dataset Detail
 */
export const initializeDatasetDetail = (context = defaultContext) => {
    return {
        type: DATASET_DETAIL_INITIALIZE,
        context: context
    };
};
/**
 * Initialize Dataset List
 */
export const initializeDatasetList = (context = defaultContext) => {
    return {
        type: DATASET_LIST_INITIALIZE,
        context: context
    };
};

/**
 * Get Dataset action
 */

export function getDataset(datasetId, context = defaultContext, gql = DATASETS_DETAIL) {

    return async (dispatch) => {

        try {
            dispatch(generateSkeleton(DATASET_FETCH_REQUEST, null, context));

            const dataset = await gqlRequest({
                queryName: "readDataset",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: Number(datasetId)
                }]
            });

            dispatch(generateSkeleton(DATASET_FETCH_SUCCESS, dataset, context));
        } catch (err) {
            dispatch(generateSkeleton(DATASET_FETCH_ERROR, err, context));
        }
    };
}

/**
 * Create dataset action
 * @param datasetData
 * @param context
 * @param gql
 */
export function createDataset(datasetData, context = defaultContext, gql = DATASETS_DETAIL) {

    return async (dispatch) => {

        try {

            const dataset = {
                name: datasetData.name,
                alias: datasetData.alias,
                desc: datasetData.desc,
                container: datasetData.container,
                data_connection: datasetData.data_connection,
                managed: datasetData.managed,
                managed_data_connection: datasetData.managed_data_connection,
                dataset_view: datasetData?.dataset_view || false
            };

            if (dataset.dataset_view) dataset.dataset_view_query = datasetData?.dataset_view_query || '';
            dispatch(generateSkeleton(DATASET_CREATE_REQUEST, null, context));

            const createdDataset = await gqlRequest({
                queryType: "mutation",
                queryName: "createDataset",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "DatasetInput!",
                    name: "dataset",
                    data: dataset
                }]
            });

            dispatch(generateSkeleton(DATASET_CREATE_SUCCESS, createdDataset, context, true));
        } catch (err) {
            dispatch(generateSkeleton(DATASET_CREATE_ERROR, err, context, true));
        }
    };
}

/**
 * Update dataset action
 * @param datasetData
 * @param context
 * @param gql
 */
export function updateDataset(datasetData, context, gql = DATASETS_DETAIL) {

    utilsLib.cleanCachedData();
    return async (dispatch) => {
        try {

            const dataset = {
                ...datasetData,
                _id: undefined
            };

            dispatch(generateSkeleton(DATASET_UPDATE_REQUEST, null, context));

            const updatedDataset = await gqlRequest({
                queryType: "mutation",
                queryName: "updateDataset",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: datasetData._id
                }, {
                    type: "DatasetInput!",
                    name: "dataset",
                    data: dataset
                }]
            });

            dispatch(generateSkeleton(DATASET_UPDATE_SUCCESS, updatedDataset, context, true));
        } catch (err) {
            dispatch(generateSkeleton(DATASET_UPDATE_ERROR, err, context));
        }
    };
}

/**
 * Delete dataset action
 * @param datasetId
 * @param context
 * @param gql
 */
export function deleteDataset(datasetId, context = defaultContext, gql = DATASETS_DETAIL) {

    return async (dispatch) => {
        try {
            dispatch(generateSkeleton(DATASET_DELETE_REQUEST, null, context));

            const deletedDataset = await gqlRequest({
                queryType: "mutation",
                queryName: "deleteDataset",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: Number(datasetId)
                }]
            });

            dispatch(generateSkeleton(DATASET_DELETE_SUCCESS, deletedDataset, context));
        } catch (err) {
            dispatch(generateSkeleton(DATASET_DELETE_ERROR, err, context));
        }
    };
}

export const importDatasets = (datasetsToCreate, datasetsToUpdate, context = defaultContext, gql = DATASETS_DETAIL) => {
    return async (dispatch) => {
        try {
            dispatch(generateSkeleton(DATASET_IMPORT_REQUEST, null, context));

            const datasets = await gqlRequest({
                queryType: "mutation",
                queryName: "importDatasets",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "[ImportDatasetInput!]!",
                    name: "toCreate",
                    data: datasetsToCreate
                }, {
                    type: "[ImportDatasetInput!]!",
                    name: "toUpdate",
                    data: datasetsToUpdate
                }]
            });

            dispatch(generateSkeleton(DATASET_IMPORT_SUCCESS, datasets, context));
        } catch (err) {
            dispatch(generateSkeleton(DATASET_IMPORT_ERROR, err, context));
        }
    };
};

/**
 * Test dataset view code, obtain sample data and column metadata
 */

export function datasetViewTest(viewCode, dataConnection, context = defaultContext, gql) {

    return async (dispatch) => {

        try {
            dispatch(generateSkeleton(DATASET_VIEW_TEST_REQUEST, null, context));

            const dataset = await gqlRequest({
                queryName: "datasetViewTest",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "String!",
                    name: "viewCode",
                    data: viewCode
                }, {
                    type: "Float!",
                    name: "dataConnection",
                    data: dataConnection
                }]
            });
            dispatch(generateSkeleton(DATASET_VIEW_TEST_SUCCESS, { payload: dataset }, context));
        } catch (err) {
            dispatch(generateSkeleton(DATASET_VIEW_TEST_ERROR, err, context));
        }
    };
}

/**
 * Get Managed Dataset executions action
 */
export function getManagedDatasetExecutions(datasetId, context = defaultContext, gql = MANAGED_DATASET_EXECUTIONS) {

    return async (dispatch) => {

        try {
            dispatch(generateSkeleton(MANAGED_DATASET_EXECUTIONS_REQUEST, null, context));

            const datasetExecutions = await gqlRequest({
                queryName: "readManagedDatasetExecutions",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: Number(datasetId)
                }]
            });

            dispatch(generateSkeleton(MANAGED_DATASET_EXECUTIONS_SUCCESS, datasetExecutions, context));
        } catch (err) {
            dispatch(generateSkeleton(MANAGED_DATASET_EXECUTIONS_ERROR), err, context);
        }
    };
}
