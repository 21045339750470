import { Map, fromJS } from 'immutable';

import {
    AGENTS_FETCH_REQUEST, AGENTS_FETCH_SUCCESS, AGENTS_FETCH_ERROR,
    AGENT_INITIALIZE, AGENTS_LIST_INITIALIZE, AGENT_DETAIL_INITIALIZE,
    AGENT_FETCH_REQUEST, AGENT_FETCH_SUCCESS, AGENT_FETCH_ERROR,
    AGENT_CREATE_REQUEST, AGENT_CREATE_SUCCESS, AGENT_CREATE_ERROR,
    AGENT_UPDATE_REQUEST, AGENT_UPDATE_SUCCESS, AGENT_UPDATE_ERROR,
    AGENT_DELETE_REQUEST, AGENT_DELETE_SUCCESS, AGENT_DELETE_ERROR
} from './agents-actions.js';

import { defaultContext } from "@biuwer/redux/src/config/constants";

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

/**
 * Agents Reducers
 */

export default (state = initialState, action) =>{

    let newState, index, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || defaultContext}`;
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch (action.type) {

        // Necessary to initialize a specific agent state
        case AGENT_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));

            return newState;
        case AGENT_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;
        case AGENTS_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;
        case AGENTS_FETCH_REQUEST:

            newState = state;

            // Agents fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case AGENTS_FETCH_SUCCESS:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case AGENTS_FETCH_ERROR:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;
        case AGENT_FETCH_REQUEST:

            newState = state;

            // Agent fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case AGENT_FETCH_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case AGENT_FETCH_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;
        case AGENT_CREATE_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case AGENT_CREATE_SUCCESS:

            // Update payload with new created agent
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case AGENT_CREATE_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;
        case AGENT_UPDATE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case AGENT_UPDATE_SUCCESS:

            // Update payload with updated user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the agent index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            error: action.error,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case AGENT_UPDATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;
        case AGENT_DELETE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case AGENT_DELETE_SUCCESS:

            // Update payload with deleted agent
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    isFetching: action.isFetching,
                    error: action.error,
                    deleted: action.deleted
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the agent index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                            isFetching: action.isFetching,
                            error: action.error,
                            deleted: action.deleted
                        });
                }
            }

            return newState;
        case AGENT_DELETE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;
        default:
            return state;
    }
}
