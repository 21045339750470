const datamodelQueryCardMatchFn = (datamodelQueryCard, filters) => {
    return String(datamodelQueryCard.collectionId) === String(filters.collectionId) &&
        String(datamodelQueryCard.pageId) === String(filters.pageId) &&
        String(datamodelQueryCard.cardId) === String(filters.cardId);
};

const getDatamodelQueryCard = (cards = [], filters = {}) => {
    return cards.find(card => datamodelQueryCardMatchFn(card, filters));
};

const getDatamodelQueryCardIndex = (cards = [], filters = {}) => {
    return cards.findIndex(card => datamodelQueryCardMatchFn(card, filters));
};

const datamodelQueryFilterMatchFn = (datamodelQueryFilter, filters) => {
    return datamodelQueryFilter.datasetId === filters.datasetId &&
        datamodelQueryFilter.fieldId === filters.fieldId &&
        datamodelQueryFilter.filterId === filters.filterId;
};

const getDatamodelQueryFilter = (cards = [], filters = {}) => {
    return cards.find(card => datamodelQueryFilterMatchFn(card, filters));
};

const getDatamodelQueryFilterIndex = (cards = [], filters = {}) => {
    return cards.findIndex(card => datamodelQueryFilterMatchFn(card, filters));
};

const datamodelQueryQueryMatchFn = (datamodelQueryQuery, filters) => {
    return String(datamodelQueryQuery.datamodel) === String(filters.datamodel) &&
        String(datamodelQueryQuery.fields) === String(filters.fields) &&
        String(datamodelQueryQuery.filters) === String(filters.filters) &&
        String(datamodelQueryQuery.options) === String(filters.options) &&
        String(datamodelQueryQuery.format) === String(filters.format) &&
        String(datamodelQueryQuery.dataCardQueries) === String(filters.dataCardQueries);
};

const getDatamodelQueryQuery = (queries = [], filters = {}) => {
    return queries.find(query => datamodelQueryQueryMatchFn(query, filters));
};

const getDatamodelQueryQueryIndex = (queries = [], filters = {}) => {
    return queries.findIndex(query => datamodelQueryQueryMatchFn(query, filters));
};

export {
    getDatamodelQueryCard,
    getDatamodelQueryCardIndex,
    getDatamodelQueryFilter,
    getDatamodelQueryFilterIndex,
    getDatamodelQueryQuery,
    getDatamodelQueryQueryIndex
}