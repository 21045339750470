import { ORGANIZATIONS_CUSTOM_STYLES } from "../organizations/organizations-gql";

// Default query for organization stats
export const ORGANIZATION_STATS = `
    organization
    basic_users
    advanced_users
    data_connections
    datasets
    datamodels
    spaces
    shared_spaces
    collections
    pages
    cards
    public_shared_resources
    private_shared_resources
`;

// Default query to save user data in Auth system
export const AUTH_USER = `
    _id
    name
    surnames
    full_name
    phone
    email
    type
    organization1
    status
    settings {
        language
        time_zone
    }
    favorites {
        cards
        pages
        collections
        spaces
    }
    default_organization {
        _id
    }
    organizations {
        status
        organization {
            _id
            name
            status
            accumulated_features
            automatic_billing
            apply_feature_wall
            trial {
                status
                start_at
                end_at
            }
            settings {
                language
                date_format
                time_zone
                currency
                number_format {
                    decimal
                    thousands
                }
                default_theme
                custom_styles {
                    ${ORGANIZATIONS_CUSTOM_STYLES}
                }
                show_intro_content
                show_demo_space
                multi_language {
                    enabled
                    use_browser_language
                    languages {
                        language
                        number_format {
                            decimal
                            thousands
                        }
                        date_format
                        time_zone
                        currency
                    }
                }
            }
        }
    }
`;

// Default query to save user data in Auth system for BIUWER-SHARE
export const AUTH_USER_SHARE = `
    _id
    settings {
        language
        time_zone
    }
    default_organization {
        _id
    }
    organizations {
        organization {
            _id
            name
            apply_feature_wall
            settings {
                language
                date_format
                time_zone
                currency
                number_format {
                    decimal
                    thousands
                }
                default_theme
                custom_styles {
                    ${ORGANIZATIONS_CUSTOM_STYLES}
                }
                show_demo_space
                multi_language {
                    enabled
                    use_browser_language
                    languages {
                        language
                        number_format {
                            decimal
                            thousands
                        }
                        date_format
                        time_zone
                        currency
                    }
                }
            }
        }
    }
`;
