export const PROFILES = `
    own {
        _id
        name
        full_name
    }
    admin
    edit
    view
    inherited
    inherited_from
`;

export const OWNER = `
    _id
    full_name
`;

export const PROFILES_FULL = `
    profiles {
        ${PROFILES}
    }
    owner {
        ${OWNER}
    }
    profile_level
`;