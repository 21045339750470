export default {
    "payments": {
        "declined": "Su tarjeta ha sido rechazada",
        "incomplete_card": "Por favor, introduce los datos de su tarjeta",
        "incomplete_cvc": "El código de seguridad de su tarjeta está incompleto",
        "incomplete_expiry": "La fecha de caducidad de su tarjeta está incompleta",
        "incomplete_number": "El número de su tarjeta está incompleto",
        "expired_card": "Su tarjeta ha caducado",
        "card_declined_card_not_supported": "No se admite esta tarjeta",
        "invalid_brand": "No se admite ese tipo de tarjeta",
        "incorrect_cvc": "El código de seguridad de su tarjeta es incorrecto",
        "incorrect_number": "El número de su tarjeta es incorrecto",
        "invalid_cvc": "El código de seguridad de su tarjeta no es válido",
        "invalid_expiry_month": "La fecha de caducidad de su tarjeta no es válida",
        "invalid_expiry_month_past": "La fecha de caducidad de su tarjeta ya ha pasado",
        "invalid_expiry_year": "El año de caducidad de su tarjeta no es válido",
        "invalid_expiry_year_past": "El año de caducidad de su tarjeta ya pasó",
        "invalid_number": "El número de su tarjeta no es válido",
        "unexpected": "Ha ocurrido un error inesperado",
        "authentication_failure": "No hemos podido autenticar su método de pago",
        "payment_intent_authentication_failure": "No hemos podido autenticar su método de pago",
        "setup_intent_authentication_failure": "No hemos podido autenticar su método de pago",
        "insufficient_funds": "La tarjeta no tiene fondos suficientes",
    },
    "unknownError": "Ha ocurrido un error. Contacta con soporte o con tu contacto principal en Biuwer",
    "errorMessage": "Mensaje de error",
    "5": "El correo electrónico solicitado no existe",
    "6": "La contraseña no es correcta",
    "7": "La información del usuario proporcionada es insuficiente. Contacte con el administrador",
    "8": "Error al generar el token. Contacte con el administrador",
    "120": "El usuario no está activo. Contacte con el administrador",
    "121": "El campo email es obligatorio",
    "122": "La solicitud de acceso ha expirado o no existe. Por favor vuelve a solicitar una nueva contraseña",
    "123": "El ID de usuario y el email deben de estar especificados para cambiar la contraseña",
    "124": "Las contraseñas no coinciden",
    "125": "El usuario no está logado",
    "126": "Por favor inserta una nueva contraseña",
    "127": "El usuario no ha sido encontrado",
    "128": "La contraseña actual es incorrecta",
    "130": "Error en el proceso. Contacte con el administrador",
    "11000": "El {{varName}} ya está siendo utilizado",
    "10000007": "La organización del usuario está inactiva o no existe",
    "queryDataError": "Error al consultar datos",
    "USER_EXISTS": "El usuario esta registrado. Contacte con el administrador",
    "SIGNUP_TOKEN_EXPIRED_NOT_EXIST": "Token no valido o ha expirado",
    "DATASET_FIELD_DEPENDENCIES": "Uno de los campos no seleccionados del Conjunto de Datos está siendo utilizado",
    "EMAIL_DOMAIN_NOT_VALID": "Dominio de email no válido",
    "REQUEST_TOO_RECENT": "Se ha realizado esta solicitud recientemente. Si quieres volver a realizarla, prueba en unos minutos",
    "ORDER_ALREADY_PAID": "Pedido pagado",
    "UNABLE_RECOVER_ORDER": "Pedido no encontrado",
    "ErrorBoundary": {
        "title": "¡Ooops! Algo no ha funcionado correctamente",
        "message": "Por favor inténtalo de nuevo. Si necesitas ayuda no dudes en contactar mediante el chat en línea."
    },
}