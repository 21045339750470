import { Map as ImmMap } from 'immutable';

// Libs
import i18n from "@biuwer/core/src/i18n";

// Options
const dataTypeOptions = [
    {_id: 0, name: i18n.t(`dataTypes.string`), value: 'string'},
    {_id: 1, name: i18n.t(`dataTypes.number`), value: 'number'},
    {_id: 2, name: i18n.t(`dataTypes.date`), value: 'date'},
    {_id: 3, name: i18n.t(`dataTypes.datetime`), value: 'datetime'},
    {_id: 4, name: i18n.t(`dataTypes.boolean`), value: 'boolean'}
];

const dataTypeOptionValues = dataTypeOptions.map(option => option.value);

const fieldTypeOptions = [
    {_id: 0, name: i18n.t(`common.dimension`), value: 'dimension'},
    {_id: 1, name: i18n.t(`common.measure`), value: 'measure'}
];

function getFilteredFieldTypeOptions(dataType) {
    if (dataType !== 'number') {
        return fieldTypeOptions.filter(option => option.value === 'dimension');
    }
    return fieldTypeOptions;
}

function getFilteredFieldTypeOptionValues(dataType) {
    const filteredTypeOptions = getFilteredFieldTypeOptions(dataType);
    return filteredTypeOptions.map(option => option.value);
}

function checkCalculatedField(calculatedField) {
    if (
        !ImmMap.isMap(calculatedField) ||
        !calculatedField.get('alias') ||
        !calculatedField.get('data_type') ||
        !dataTypeOptionValues.includes(calculatedField.get('data_type')) ||
        !calculatedField.get('field_type') ||
        !getFilteredFieldTypeOptionValues(calculatedField.get('data_type')).includes(calculatedField.get('field_type')) ||
        !calculatedField.get('field_type') ||
        !calculatedField.get('expression')
    ) return false;

    return true;
}

const functionTypeOptions = [
    { value: 'all', name: i18n.t('common.allLabel') },
    // { value: 'String', name: i18n.t('dataTypes.string') },
    { value: 'Number', name: i18n.t('dataTypes.number') },
    { value: 'Date', name: i18n.t('dataTypes.date') },
    { value: 'Logic', name: i18n.t('common.logicLabel') },
    { value: 'Aggregation', name: i18n.t('filters.aggregationLabel') }
];

const functionsOptions = [
    { value: 'ABS', help: 'ABS (num)', group: 'Number', exp: 'abs' },
    // { value: 'AND', help: '(expr) AND (expr)', group: 'Logic' },
    { value: 'AVG', help: 'AVG (metric field)', group: 'Aggregation', exp: 'avg' },
    // { value: 'CASE', help: 'CASE (expr) WHEN (comparison expr) THEN (expr) ELSE (expr) END', group: 'Logic' },
    { value: 'CEILING', help: 'CEILING(num, decimals)', group: 'Number', exp: 'ceiling' },
    { value: 'CONCAT', help: 'CONCAT (expression [,expression])', group: 'String', exp: 'concat' },
    // { value: 'CONTAINS', help: 'CONTAINS (str)', group: 'String' },
    { value: 'COUNT', help: 'COUNT (metric field)', group: 'Aggregation', exp: 'count' },
    { value: 'COUNT_DISTINCT', help: 'COUNT_DISTINCT (metric field)', group: 'Aggregation', exp: 'count_distinct' },
    { value: 'DATE', help: 'DATE(expr)', group: 'Date', exp: 'date' },
    { value: 'DATEPARSE', help: 'DATEPARSE(date_mask, expr)', group: 'Date', exp: 'dateparse' },
    { value: 'MAKEDATE', help: 'MAKEDATE(year, month, day)', group: 'Date', exp: 'makedate' },
    { value: 'DATEADD', help: 'DATEADD(date_level, interval, date)', group: 'Date', exp: 'dateadd' },
    { value: 'DATEDIFF', help: 'DATEDIFF(date_level, date1, date2)', group: 'Date', exp: 'datediff' },
    { value: 'DATENAME', help: 'DATENAME(date_part, date)', group: 'Date', exp: 'datename' },
    { value: 'DATEPART', help: 'DATEPART(iso_date_part, date)', group: 'Date', exp: 'datepart' },
    { value: 'DATETRUNC', help: 'DATETRUNC(date_level, date)', group: 'Date', exp: 'datetrunc' },
    { value: 'YEAR', help: 'YEAR(date)', group: 'Date', exp: 'year' },
    { value: 'QUARTER', help: 'QUARTER(date)', group: 'Date', exp: 'quarter' },
    { value: 'MONTH', help: 'MONTH(date)', group: 'Date', exp: 'month' },
    { value: 'WEEK', help: 'WEEK(date)', group: 'Date', exp: 'week' },
    { value: 'DAY', help: 'DAY(date)', group: 'Date', exp: 'day' },
    { value: 'ISOYEAR', help: 'ISOYEAR(date)', group: 'Date', exp: 'isoyear' },
    { value: 'ISOWEEK', help: 'ISOWEEK(date)', group: 'Date', exp: 'isoweek' },
    { value: 'TODAY', help: 'TODAY()', group: 'Date', exp: 'today' },
    { value: 'ISDATE', help: 'ISDATE(date)', group: 'Date', exp: 'isdate' },
    { value: "DIFF_FROM_FIRST", help: "DIFF_FROM_FIRST(metric field)", group: "Aggregation", exp: 'diff_from_first' },
    { value: "DIFF_FROM_LAST", help: "DIFF_FROM_LAST(metric field)", group: "Aggregation", exp: 'diff_from_last' },
    { value: "DIFF_FROM_NEXT", help: "DIFF_FROM_NEXT(metric field)", group: "Aggregation", exp: 'diff_from_next' },
    { value: "DIFF_FROM_PREV", help: "DIFF_FROM_PREV(metric field)", group: "Aggregation", exp: 'diff_from_prev' },
    { value: "DIV", help: "DIV(numerator expression, denominator expression)", group: "Number", exp: 'div' },
    // { value: 'END_WITH', help: 'END_WITH (str)', group: 'String' },
    { value: "EXP", help: "EXP(num)", group: "Number", exp: 'exp' },
    { value: "FLOOR", help: "FLOOR(num, decimals)", group: "Number", exp: 'floor' },
    { value: "IF", help: "IF(comparison expr, then expr, else expr)", group: "Logic", exp: 'if' },
    { value: "IFNULL", help: "IFNULL(expr, expr)", group: "Logic", exp: 'ifnull' },
    // { value: 'ISNULL', help: 'ISNULL (expr)', group: 'Logic' },
    // { value: 'LENGTH', help: 'LENGTH (str)', group: 'String' },
    { value: "LN", help: "LN(num)", group: "Number", exp: 'ln' },
    { value: "LOG", help: "LOG(num, base)", group: "Number", exp: 'log' },
    // { value: 'LOWERCASE', help: 'LOWERCASE (str)', group: 'String' },
    { value: "MAX_VALUE", help: "MAX_VALUE (expression, expression [,expression])", group: "Number", exp: 'max_value' },
    { value: "MIN_VALUE", help: "MIN_VALUE (expression, expression [,expression])", group: "Number", exp: 'min_value' },
    { value: "MAX", help: "MAX (metric field)", group: "Aggregation", exp: 'max' },
    { value: "MIN", help: "MIN (metric field)", group: "Aggregation", exp: 'min' },
    { value: "MOD", help: "MOD(numerator expression, denominator expression)", group: "Number", exp: 'mod' },
    { value: "MOVING_AVG", help: "MOVING_AVG(metric field, previous range, next range)", group: "Aggregation", exp: 'moving_avg' },
    { value: "MOVING_MAX", help: "MOVING_MAX(metric field, previous range, next range)", group: "Aggregation", exp: 'moving_max' },
    { value: "MOVING_MIN", help: "MOVING_MIN(metric field, previous range, next range)", group: "Aggregation", exp: 'moving_min' },
    { value: "MOVING_SUM", help: "MOVING_SUM(metric field, previous range, next range)", group: "Aggregation", exp: 'moving_sum' },
    // { value: 'NOT', help: 'NOT (expr)', group: 'Logic' },
    // { value: 'OR', help: '(expr) OR (expr)', group: 'Logic' },
    { value: "PCT_DIFF_FROM_FIRST", help: "PCT_DIFF_FROM_FIRST(metric field)", group: "Aggregation", exp: 'pct_diff_from_first' },
    { value: "PCT_DIFF_FROM_LAST", help: "PCT_DIFF_FROM_LAST(metric field)", group: "Aggregation", exp: 'pct_diff_from_last' },
    { value: "PCT_DIFF_FROM_NEXT", help: "PCT_DIFF_FROM_NEXT(metric field)", group: "Aggregation", exp: 'pct_diff_from_next' },
    { value: "PCT_DIFF_FROM_PREV", help: "PCT_DIFF_FROM_PREV(metric field)", group: "Aggregation", exp: 'pct_diff_from_prev' },
    { value: "PCT_FROM_FIRST", help: "PCT_FROM_FIRST(metric field)", group: "Aggregation", exp: 'pct_from_first' },
    { value: "PCT_FROM_LAST", help: "PCT_FROM_LAST(metric field)", group: "Aggregation", exp: 'pct_from_last' },
    { value: "PCT_FROM_NEXT", help: "PCT_FROM_NEXT(metric field)", group: "Aggregation", exp: 'pct_from_next' },
    { value: "PCT_FROM_PREV", help: "PCT_FROM_PREV(metric field)", group: "Aggregation", exp: 'pct_from_prev' },
    { value: "PCT_OVER_TOTAL", help: "PCT_OVER_TOTAL(metric field)", group: "Aggregation", exp: 'pct_over_total' },
    { value: "POWER", help: "POWER(num, power)", group: "Number", exp: 'power' },
    { value: "PREV_DAY", help: "PREV_DAY(metric field, date field)", group: "Date", exp: 'prev_day' },
    { value: "PREV_WEEK", help: "PREV_WEEK(metric field, date field)", group: "Date", exp: 'prev_week' },
    { value: "PREV_MONTH", help: "PREV_MONTH(metric field, date field)", group: "Date", exp: 'prev_month' },
    { value: "PREV_QUARTER", help: "PREV_QUARTER(metric field, date field)", group: "Date", exp: 'prev_quarter' },
    { value: "PREV_YEAR", help: "PREV_YEAR(metric field, date field)", group: "Date", exp: 'prev_year' },
    { value: "PREV_PERIOD", help: "PREV_PERIOD(metric field, date field)", group: "Date", exp: 'prev_period' },
    // { value: 'REPLACE', help: 'REPLACE (str, old_str, new_str)', group: 'String' },
    { value: "ROUND", help: "ROUND(num, decimals)", group: "Number", exp: 'round' },
    { value: "RUNNING_AVG", help: "RUNNING_AVG(metric field)", group: "Aggregation", exp: 'running_avg' },
    { value: "RUNNING_COUNT", help: "RUNNING_COUNT(metric field)", group: "Aggregation", exp: 'running_count' },
    { value: "RUNNING_MAX", help: "RUNNING_MAX(metric field)", group: "Aggregation", exp: 'running_max' },
    { value: "RUNNING_MIN", help: "RUNNING_MIN(metric field)", group: "Aggregation", exp: 'running_min' },
    { value: "RUNNING_SUM", help: "RUNNING_SUM(metric field)", group: "Aggregation", exp: 'running_sum' },
    { value: "SIGN", help: "SIGN(num)", group: "Number", exp: 'sign' },
    { value: "SQRT", help: "SQRT(num)", group: "Number", exp: 'sqrt' },
    { value: "SQUARE", help: "SQUARE(num)", group: "Number", exp: 'square' },
    // { value: 'START_WITH', help: 'START_WITH (str)', group: 'String' },
    // { value: 'SUBSTRING', help: 'SUBSTRING (str, start_index, end_index)', group: 'String' },
    { value: 'SUM', help: 'SUM (metric field)', group: 'Aggregation', exp: 'sum' },
    { value: "TOTAL_SUM", help: "TOTAL_SUM(metric field)", group: "Aggregation", exp: 'total_sum' },
    { value: "TOTAL_AVG", help: "TOTAL_AVG(metric field)", group: "Aggregation", exp: 'total_avg' },
    { value: "TOTAL_MIN", help: "TOTAL_MIN(metric field)", group: "Aggregation", exp: 'total_min' },
    { value: "TOTAL_MAX", help: "TOTAL_MAX(metric field)", group: "Aggregation", exp: 'total_max' },
    { value: "TOTAL_COUNT", help: "TOTAL_COUNT(metric field)", group: "Aggregation", exp: 'total_count' },
    { value: "TOTAL_COUNT_DISTINCT", help: "TOTAL_COUNT_DISTINCT(metric field)", group: "Aggregation", exp: 'total_count_distinct' },
    // { value: 'TRIM', help: 'TRIM (str)', group: 'String' },
    // { value: 'UPPERCASE', help: 'UPPERCASE (str)', group: 'String' },
];

export {
    dataTypeOptions,
    dataTypeOptionValues,
    fieldTypeOptions,
    getFilteredFieldTypeOptions,
    getFilteredFieldTypeOptionValues,
    functionTypeOptions,
    functionsOptions,
    checkCalculatedField
}
