import {createClient} from "graphql-ws";

// Auth
import Auth from "@biuwer/redux/src/system/auth/auth-lib";

export default createClient({
    url: () => {

        let protocol = "", hostname = "", port = "";

        if (process.env.NODE_ENV === "development") {
            protocol = process.env.REACT_APP_WS_DEVELOPMENT_PROTOCOL;
            hostname = window.location.hostname;
            port = process.env.REACT_APP_WS_DEVELOPMENT_PORT;
        }
        else {
            switch (process.env.REACT_APP_SEGMENT_ENV) {
                case "testing":
                    protocol = process.env.REACT_APP_WS_TESTING_PROTOCOL;
                    hostname = process.env.REACT_APP_WS_TESTING_HOSTNAME;
                    break;
                case "production":
                    protocol = process.env.REACT_APP_WS_PRODUCTION_PROTOCOL;
                    hostname = process.env.REACT_APP_WS_PRODUCTION_HOSTNAME;
                    break;
                case "demo":
                    protocol = process.env.REACT_APP_WS_DEMO_PROTOCOL;
                    hostname = process.env.REACT_APP_WS_DEMO_HOSTNAME;
                    break;
                default: break;
            }
        }

        return `${protocol}://${hostname}${port ? `:${port}` : ""}/api/subscriptions`;
    },
    connectionParams: async () => {
            return {
                token: Auth.getLocalJwt()
            };
        },
    on: {
        message: (message) => {
            !!message && !!message.payload && !!message.payload.token && Auth.setLocalJwt(message.payload.token);
        }
    }
});