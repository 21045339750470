export const THEMES_LIST = `
    _id
    name
    description
    type
    styles
    created
    updated
`;

export const THEME_DETAIL = `
    _id
    name
    description
    type
    styles
`;