import { Map, fromJS } from 'immutable';

import {
    SYSTEM_VARIABLES,
    SET_SYSTEM_VARIABLE,
    SET_SYSTEM_VARIABLES,
    DELETE_SYSTEM_VARIABLE
} from './system-variables-actions';

const initialState = Map({});

/**
 * SystemVariables Reducer
 */
export default (state = initialState, action) => {
    switch (action.type) {
        case SYSTEM_VARIABLES:
        case SET_SYSTEM_VARIABLES:
            return state.merge(fromJS(action.systemVariables));
        case SET_SYSTEM_VARIABLE:
            return state.set(action.name, action.value);
        case DELETE_SYSTEM_VARIABLE:
            return state.remove(action.name);
        default: return state;
    }
}