import { combineReducers } from 'redux';

// Biuwer Billing reducers
import countries from "./countries/countries-reducer";
import orders from "./orders/orders-reducer";
import payments from "./payments/payments-reducer";
import priceLists from "./price-lists/price-lists-reducer";
import products from "./products/products-reducer";
import vies from "./vies/vies-reducer";
import promoCodes from './promo-codes/promo-codes-reducer';

export default combineReducers({
    countries,
    orders,
    payments,
    priceLists,
    products,
    vies,
    promoCodes,
});