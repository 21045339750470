import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';

// Actions
import { createOauth, initializeOauth } from "@biuwer/redux/src/system/oauth/oauth-actions";

// Selectors
import { oauthSelector, oauthIssueSelector } from '@biuwer/redux/src/system/oauth/oauth-selectors';

// Actions dispatcher hook
const useReduxDispatch = (useDispatch) => {
    const dispatch = useDispatch();
    return {
        dispatch,
        initializeOauth: () => {
            dispatch(initializeOauth());
        },
        createOauth: (stateParamValue, codeParamValue) => {
            dispatch(createOauth(stateParamValue, codeParamValue));
        },
    };
};

// Oauth selector
const oauthRedirectComponentSelector = createSelector(
    oauthSelector,
    oauthIssueSelector,
    (oauth, oauthIssue) => ({ oauth, oauthIssue })
);

const OauthRedirect = (props) => {

    // i18n
    const { t } = useTranslation();

    // Redux
    const ReduxActions = useReduxDispatch(useDispatch);
    const { oauth, oauthIssue } = useSelector(oauthRedirectComponentSelector);

    // State
    const [message, setMessage] = React.useState("");

    React.useEffect(() => {
        if (props.location.search) {
            setTimeout(() => window.close(), 300000);
            const urlParams = new URLSearchParams(props.location.search);
            ReduxActions.createOauth(urlParams.get("state"), urlParams.get("code"));
        }
    }, []);

    React.useEffect(() => {
        if (oauth || oauthIssue) {
            if (oauthIssue) {
                ReduxActions.initializeOauth();
                setMessage(`${t('oauth.canceling')} ...`);
            } else if (oauth) {
                setMessage(`${t('oauth.authorizing')} ...`);
            }

            // Close for both
            setTimeout(() => window.close(), 1000);
        }
    }, [oauth, oauthIssue]);

    return (
        <div className="mt50 text-center">
            <h1>{message}</h1>
        </div>
    );

};

export default OauthRedirect;
