import { Map, fromJS } from 'immutable';

/**
 * Geography Reducer
 */
import {
    GEOGRAPHY_INITIALIZE,
    GEOGRAPHY_COUNTRIES_FETCH_REQUEST, GEOGRAPHY_COUNTRIES_FETCH_SUCCESS, GEOGRAPHY_COUNTRIES_FETCH_ERROR,
    GEOGRAPHY_PROVINCES_FETCH_REQUEST, GEOGRAPHY_PROVINCES_FETCH_SUCCESS, GEOGRAPHY_PROVINCES_FETCH_ERROR,
    GEOGRAPHY_TOWNS_FETCH_REQUEST, GEOGRAPHY_TOWNS_FETCH_SUCCESS, GEOGRAPHY_TOWNS_FETCH_ERROR
} from './geography-actions.js';

const initialState = Map({});

export default (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case GEOGRAPHY_INITIALIZE:
            newState = state
                .remove('countries')
                .remove('provinces')
                .remove('towns');
            return newState;
        case GEOGRAPHY_COUNTRIES_FETCH_REQUEST:
            newState = state
                .remove('countries')
                .merge({
                    isFetching: action.isFetching,
                    error: action.error
                });
            return newState;
        case GEOGRAPHY_COUNTRIES_FETCH_SUCCESS:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                countries: fromJS(action.payload)
            });
            return newState;
        case GEOGRAPHY_COUNTRIES_FETCH_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;

        case GEOGRAPHY_PROVINCES_FETCH_REQUEST:
            newState = state
                .remove('provinces')
                .merge({
                    isFetching: action.isFetching,
                    error: action.error
                });
            return newState;
        case GEOGRAPHY_PROVINCES_FETCH_SUCCESS:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                provinces: fromJS(action.payload)
            });
            return newState;
        case GEOGRAPHY_PROVINCES_FETCH_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;

        case GEOGRAPHY_TOWNS_FETCH_REQUEST:
            newState = state
                .remove('towns')
                .merge({
                    isFetching: action.isFetching,
                    error: action.error
                });
            return newState;
        case GEOGRAPHY_TOWNS_FETCH_SUCCESS:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                towns: fromJS(action.payload)
            });
            return newState;
        case GEOGRAPHY_TOWNS_FETCH_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;

        default:
            return state;
    }
};
