import { Map, fromJS } from 'immutable';

import {
    PRICE_LISTS_INITIALIZE, PRICE_LIST_DETAIL_INITIALIZE, PRICE_LIST_LIST_INITIALIZE,
    PRICE_LISTS_FETCH_REQUEST, PRICE_LISTS_FETCH_SUCCESS, PRICE_LISTS_FETCH_ERROR,
    PRICE_LIST_FETCH_REQUEST, PRICE_LIST_FETCH_SUCCESS, PRICE_LIST_FETCH_ERROR
} from './price-lists-actions';

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

/**
 * PriceLists Reducer
 */

const priceLists = (state = initialState, action) => {
    let newState;

    // Get list and detail context
    const list = `list_${(action && action.context) || `primary`}`;
    const detail = `detail_${(action && action.context) || `primary`}`;

    switch (action.type) {

        // Initialize
        case PRICE_LISTS_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));
            return newState;

        case PRICE_LIST_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;

        case PRICE_LIST_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;

        // PriceLists Fetch (list)
        case PRICE_LISTS_FETCH_REQUEST:

            newState = state;

            // PriceLists fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case PRICE_LISTS_FETCH_SUCCESS:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case PRICE_LISTS_FETCH_ERROR:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;


        // PriceList Fetch (detail)
        case PRICE_LIST_FETCH_REQUEST:

            newState = state;

            // PriceList fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case PRICE_LIST_FETCH_SUCCESS:

            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;

        case PRICE_LIST_FETCH_ERROR:

            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;

        default:
            return state;
    }
};

export default priceLists;
