import {
    getSystemVariablesFromLocalStorage,
    setSystemVariableToLocalStorage,
    setSystemVariablesToLocalStorage,
    deleteSystemVariableFromLocalStorage,
    jwtTokenToSessionToken,
    SESSION_TOKEN
} from './system-variables-lib';

export const SYSTEM_VARIABLES = 'SYSTEM_VARIABLES';
export const SET_SYSTEM_VARIABLE = 'SET_SYSTEM_VARIABLE';
export const SET_SYSTEM_VARIABLES = 'SET_SYSTEM_VARIABLES';
export const DELETE_SYSTEM_VARIABLE = 'DELETE_SYSTEM_VARIABLE';

/**
 * Action to get system variables from localStorage
 */
export const systemVariables = () => {
    return dispatch => {
        dispatch({
            type: SYSTEM_VARIABLES,
            systemVariables: getSystemVariablesFromLocalStorage()
        });
    };
};

/**
 * Action to set new value of a specified system variable
 * @param name (string) - Name of system variable to update
 * @param value (any) - New value of specified system variable
 */
export const setSystemVariable = (name, value) => {
    return dispatch => {
        if (name === SESSION_TOKEN) {
            value = jwtTokenToSessionToken(value);
        }
        setSystemVariableToLocalStorage(name, value);

        dispatch({
            type: SET_SYSTEM_VARIABLE,
            name,
            value
        });
    };
};

/**
 * Action to set new system variables
 * @param systemVariables (object) - Object with new system variables
 */
export const setSystemVariables = (systemVariables) => {
    return dispatch => {

        setSystemVariablesToLocalStorage(systemVariables);

        dispatch({
            type: SET_SYSTEM_VARIABLES,
            systemVariables
        });
    };
};

export const deleteSystemVariable = (name) => {
    return dispatch => {

        deleteSystemVariableFromLocalStorage(name);

        dispatch({
            type: DELETE_SYSTEM_VARIABLE,
            name
        });
    };
};