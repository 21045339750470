import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import i18n from '@biuwer/core/src/i18n';
import History from "@biuwer/core/src/history";
import { defaultContext } from "@biuwer/redux/src/config/constants";

// Actions
import {addNotification} from "@biuwer/redux/src/system/notifications/notifications-actions";
import { checkLanguage } from "../auth/session-actions";

// Spaces
import { initializeSpaces, getAllSpaces, newSpacesCreated } from "@biuwer/redux/src/system/spaces/spaces-actions";
import { NEW_SPACES_LIST } from "@biuwer/redux/src/system/spaces/spaces-gql";
import spacesLib from "@biuwer/biuwer/src/spaces/spaces-lib";

// GQL
import { ORGANIZATION_DETAIL, EXTENDED_ORGANIZATION_DETAIL } from './organizations-gql';
import gqlRequest from '@biuwer/core/src/graphql-request';

export const ORGANIZATIONS_INITIALIZE = 'ORGANIZATIONS_INITIALIZE';
export const ORGANIZATIONS_LIST_INITIALIZE = 'ORGANIZATIONS_LIST_INITIALIZE';
export const ORGANIZATIONS_DETAIL_INITIALIZE = 'ORGANIZATIONS_DETAIL_INITIALIZE';

export const ORGANIZATIONS_FETCH_REQUEST = 'ORGANIZATIONS_FETCH_REQUEST';
export const ORGANIZATIONS_FETCH_SUCCESS = 'ORGANIZATIONS_FETCH_SUCCESS';
export const ORGANIZATIONS_FETCH_ERROR = 'ORGANIZATIONS_FETCH_ERROR';

export const ORGANIZATION_FETCH_REQUEST = 'ORGANIZATION_FETCH_REQUEST';
export const ORGANIZATION_FETCH_SUCCESS = 'ORGANIZATION_FETCH_SUCCESS';
export const ORGANIZATION_FETCH_ERROR = 'ORGANIZATION_FETCH_ERROR';

export const ORGANIZATION_CREATE_REQUEST = 'ORGANIZATION_CREATE_REQUEST';
export const ORGANIZATION_CREATE_SUCCESS = 'ORGANIZATION_CREATE_SUCCESS';
export const ORGANIZATION_CREATE_ERROR = 'ORGANIZATION_CREATE_ERROR';

export const ORGANIZATION_UPDATE_REQUEST = 'ORGANIZATION_UPDATE_REQUEST';
export const ORGANIZATION_UPDATE_SUCCESS = 'ORGANIZATION_UPDATE_SUCCESS';
export const ORGANIZATION_UPDATE_ERROR = 'ORGANIZATION_UPDATE_ERROR';

export const ORGANIZATION_CHANGE_STATUS_REQUEST = 'ORGANIZATION_CHANGE_STATUS_REQUEST';
export const ORGANIZATION_CHANGE_STATUS_SUCCESS = 'ORGANIZATION_CHANGE_STATUS_SUCCESS';
export const ORGANIZATION_CHANGE_STATUS_ERROR = 'ORGANIZATION_CHANGE_STATUS_ERROR';

export const ORGANIZATION_DELETE_REQUEST = "ORGANIZATION_DELETE_REQUEST"
export const ORGANIZATION_DELETE_SUCCESS = "ORGANIZATION_DELETE_SUCCESS"
export const ORGANIZATION_DELETE_ERROR = "ORGANIZATION_DELETE_ERROR"

// Generic Skeleton for every action
const generateSkeleton = (type, body, context = defaultContext, showNotification = true) => {
    let skeleton = { type }, notification;
    const history = History.getHistory();

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false,
                context: context
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                context: context
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body,
                context: context
            };
            break;
        default:
            break;
    }
    switch (type) {
        case ORGANIZATION_CREATE_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', {
                    name: i18n.t('admin.organization.title'),
                    context: 'female',
                    count: 1
                })
            };
            if (history.location.pathname === '/instance-admin/organizations/new') {
                setTimeout(() => {
                    const locationState = history.location.state || {};
                    history.push(locationState.prev || '/instance-admin/organizations');
                }, 2000);
            }
            break;
        case ORGANIZATION_UPDATE_SUCCESS:
            skeleton.updated = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', {
                    name: i18n.t('admin.organization.title'),
                    context: 'female',
                    count: 1
                })
            };
            break;
        case ORGANIZATION_CHANGE_STATUS_SUCCESS:
            skeleton.updated = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', {
                    name: i18n.t('common.statusLabel'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        case ORGANIZATION_DELETE_SUCCESS:
            skeleton.deleted = true
            notification = {
                styleType: "success",
                message: i18n.t("notifications.deleteSuccess", {
                    name: i18n.t("admin.organization.title"),
                    context: "female",
                    count: 1
                })
            }
            break
        case ORGANIZATION_CREATE_ERROR:
        case ORGANIZATION_UPDATE_ERROR:
        case ORGANIZATION_CHANGE_STATUS_ERROR:
        case ORGANIZATIONS_FETCH_ERROR:
        case ORGANIZATION_FETCH_ERROR:
        case ORGANIZATION_DELETE_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (showNotification && notification) {
            dispatch(addNotification(notification));
        }
    }
};

/**
 * Initialize Organizations
 */
export function initializeOrganizations(context = defaultContext) {
    return {
        type: ORGANIZATIONS_INITIALIZE,
        context: context
    };
}
export function initializeOrganizationsList(context = defaultContext) {
    return {
        type: ORGANIZATIONS_LIST_INITIALIZE,
        context: context
    };
}
export function initializeOrganizationsDetail(context = defaultContext) {
    return {
        type: ORGANIZATIONS_DETAIL_INITIALIZE,
        context: context
    };
}

/**
 * Get organizations from server (read)
 *
 */
export function getOrganizations(query = {}, context = defaultContext, gql = ORGANIZATION_DETAIL) {
    return async dispatch => {
        try {
            const graphQuery = {
                query: `query($query: QueryOrganizationInput!) {
                    readOrganizations(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(ORGANIZATIONS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(graphQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ORGANIZATIONS_FETCH_ERROR, 'Error on getting organizations from server', context));
                } else if (res.body && res.body.data && res.body.data.readOrganizations) {
                    dispatch(generateSkeleton(ORGANIZATIONS_FETCH_SUCCESS, res.body.data.readOrganizations, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(ORGANIZATIONS_FETCH_ERROR, e, context));
        }
    };
}

/**
 * Get organization info from server (readOne)
 *
 */
export function getOrganizationInfo (context = defaultContext, gql = EXTENDED_ORGANIZATION_DETAIL, orgId) {
    return async dispatch => {

        try {

            const organizationId = orgId || Auth.getOrganizationId();
            const token = Auth.getLocalJwt();
            const query = {
                query: `query($id: Float!) {
                    readOrganization(_id: $id) {
                        ${gql}
                    }
                }`,
                variables: {
                    id: organizationId
                }
            };

            dispatch(generateSkeleton(ORGANIZATION_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ORGANIZATION_FETCH_ERROR, 'Error on getting organization from server', context));
                } else if (res.body && res.body.data && res.body.data.readOrganization) {
                    dispatch(generateSkeleton(ORGANIZATION_FETCH_SUCCESS, res.body.data.readOrganization, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(ORGANIZATION_FETCH_ERROR, e, context));
        }
    };
}

/**
 * Create organization action
 * @param organizationObject
 * @param context
 * @param gql
 */
export function createOrganization(organizationObject, context = defaultContext, gql = EXTENDED_ORGANIZATION_DETAIL) {
    return async dispatch => {

        try {
            const query = {
                query: `mutation($organizationObject: NewOrganizationInput!) {
                    createOrganization(organization: $organizationObject) {
                        ${gql}
                    }
                }`,
                variables: {
                    organizationObject
                }
            };

            const token = Auth.getLocalJwt();

            dispatch(generateSkeleton(ORGANIZATION_CREATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ORGANIZATION_CREATE_ERROR, 'Error creating the organization', context));
                } else if (res.body && res.body.data && res.body.data.createOrganization) {

                    dispatch(generateSkeleton(ORGANIZATION_CREATE_SUCCESS, res.body.data.createOrganization, context));

                    // Get the user from localStorage and updates it with the new organization data
                    let user = Auth.getUser();
                    let newOrganization = {};
                    newOrganization._id = res.body.data.createOrganization._id;
                    newOrganization.name = res.body.data.createOrganization.name;

                    user.organizations.push({organization: newOrganization, status: 'active'});
                    Auth.setUser(user);
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(ORGANIZATION_CREATE_ERROR, e, context));
        }
    };
}


/**
 * Update organization action
 * @param organization
 * @param context
 * @param gql
 */
export function updateOrganization(organization, context = defaultContext, gql = EXTENDED_ORGANIZATION_DETAIL, updateSpaces) {
    return async dispatch => {

        try {
            const token = Auth.getLocalJwt();

            const updatedOrganization = {
                ...organization
            };
            delete(updatedOrganization._id);

            const query = {
                query: `mutation($_id: Float!, $organization: UpdateOrganizationInput!) {
                    updateOrganization(_id: $_id, organization: $organization) {
                        ${gql}
                    }
                }`,
                variables: {
                    _id: organization._id,
                    organization: updatedOrganization
                }
            };

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            dispatch(generateSkeleton(ORGANIZATION_UPDATE_REQUEST, null, context));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ORGANIZATION_UPDATE_ERROR, 'Error creating the organization', context));
                } else if (res?.body?.data?.updateOrganization) {

                    let updatedOrganization = res.body.data.updateOrganization;

                    dispatch(generateSkeleton(ORGANIZATION_UPDATE_SUCCESS, updatedOrganization, context));

                    // Get the user from localStorage and updates it with the new organization data
                    // Also update interface language if user or organization settings has changed
                    let user = Auth.getUser();
                    checkLanguage(user.settings.language, updatedOrganization && updatedOrganization.settings && updatedOrganization.settings.language);
                    let newOrganization = user.organizations.find(organization => (organization.organization._id || organization.organization) === updatedOrganization._id);

                    if (newOrganization) {
                        newOrganization.organization._id = updatedOrganization._id;
                        newOrganization.organization.name = updatedOrganization.name;
                        newOrganization.organization.settings = updatedOrganization.settings;
                    } else {
                        newOrganization = {
                            _id: updatedOrganization._id,
                            name: updatedOrganization.name,
                            settings: updatedOrganization.settings
                        };
                        user.organizations.push({ organization: newOrganization, status: 'active' });
                    }

                    Auth.setUser(user);
                    Auth.setLanguage(user?.settings || {}, updatedOrganization?.settings || {});

                    if (updateSpaces) {
                        dispatch(initializeSpaces(spacesLib.SPACES_CONTEXT));
                        dispatch(getAllSpaces(spacesLib.SPACES_CONTEXT, NEW_SPACES_LIST));
                        dispatch(newSpacesCreated(spacesLib.SPACES_CONTEXT, NEW_SPACES_LIST));
                    }
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(ORGANIZATION_UPDATE_ERROR, e, context));
        }
    };
}

/**
 * Change organization status action
 * @param orgId Organization Id to be change
 * @param status The new status (active / inactive)
 * @param context
 * @param gql
 */
export function changeOrganizationStatus(orgId, status, context = defaultContext, gql = EXTENDED_ORGANIZATION_DETAIL) {
    return async dispatch => {
        try {

            const token = Auth.getLocalJwt();
            const query = {
                query: `mutation($orgId: Float!, $status: String!) {
                    changeOrganizationStatus(_id: $orgId, status: $status) {
                        ${gql}
                    }
                }`,
                variables: {
                    orgId,
                    status
                }
            };

            dispatch(generateSkeleton(ORGANIZATION_CHANGE_STATUS_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(ORGANIZATION_CHANGE_STATUS_ERROR, 'Error on changing status to organization', context));
                } else if (res.body && res.body.data && res.body.data.changeOrganizationStatus) {
                    dispatch(generateSkeleton(ORGANIZATION_CHANGE_STATUS_SUCCESS, res.body.data.changeOrganizationStatus, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(ORGANIZATION_CHANGE_STATUS_ERROR, e, context));
        }
    };
}

export function deleteOrganization(orgId, context = defaultContext, gql = EXTENDED_ORGANIZATION_DETAIL) {
    return async dispatch => {
        try {
            // const token = Auth.getLocalJwt()
            // const query = {
            //     query: `mutation($orgId: Float!) {
            //         deleteOrganization(_id: $orgId) {
            //             ${gql}
            //         }
            //     }`,
            //     variables: {
            //         orgId
            //     }
            // }
            dispatch(generateSkeleton(ORGANIZATION_DELETE_REQUEST, null, context))

            // const res = await request
            //     .post('/api/gql/')
            //     .send(query)
            //     .set('Authorization', `Bearer ${token}`)
            //     .on('response', (response) => Auth.checkResponse(response))

            const response = await gqlRequest({
                queryType: "mutation",
                queryName: "deleteOrganization",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: orgId
                }]
            })

            // if (res) {
            //     if (res?.body?.errors.length > 0) {
            //         dispatch(generateSkeleton(ORGANIZATION_DELETE_ERROR, res.body.errors[0], context));
            //     } else if (res?.body?.data?.deleteOrganization) {
            //         dispatch(generateSkeleton(ORGANIZATION_DELETE_SUCCESS, res.body.data.deleteOrganization, context));
            //     }
            // }
            dispatch(generateSkeleton(ORGANIZATION_DELETE_SUCCESS, response, context))
        } catch (err) {
            dispatch(generateSkeleton(ORGANIZATION_DELETE_ERROR, err, context))
        }
    }
}
