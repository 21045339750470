import React, { Component } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { withTranslation } from "react-i18next";

// Actions
import { forgotPassword, forgotPasswordCleanError, oneTimeLogin } from "@biuwer/redux/src/system/auth/password-actions";
import { signin, initLanguage, loginCleanError } from "@biuwer/redux/src/system/auth/session-actions";

// Components
import SigninForm from './signin-form.jsx';
import ForgotPasswordForm from './forgot-password-form';

// Libs
import { segmentPage } from "@biuwer/core/src/segment";

class Signin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            screen: 'signin'
        };

        this.signin = this.signin.bind(this);
        this.cleanErrorMessageSigninForm = this.cleanErrorMessageSigninForm.bind(this);
        this.toggleScreen = this.toggleScreen.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.oneTimeLogin = this.oneTimeLogin.bind(this);
        this.forgotPasswordCleanError = this.forgotPasswordCleanError.bind(this);
    }

    UNSAFE_componentWillMount() {
        initLanguage();
    }

    componentDidMount() {
        segmentPage('Signin');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        // Redirect to root path if user is logged in successfully
        if(nextProps.isLoggedIn) {
            this.props.history.push('/');
        }
    }

    signin(credentials) {
        this.props.dispatch(signin({
            username:  credentials.email,
            password: credentials.password
        }));
    }

    // Error Message component event for close/hide the error in Signin Form
    cleanErrorMessageSigninForm() {
        this.props.dispatch(loginCleanError());
    }

    toggleScreen(screen) {
        if(screen) {
            this.setState({
                screen: screen
            });
        }
    }

    // Pass action to component ForgotPasswordForm
    forgotPassword(email){
        if(email){
            this.props.dispatch(forgotPassword(email));
        }
    }

    // Pass action to component ForgotPasswordForm
    oneTimeLogin(email){
        if(email){
            this.props.dispatch(oneTimeLogin(email));
        }
    }

    forgotPasswordCleanError() {
        this.props.dispatch(forgotPasswordCleanError());
    }

    render() {
        let formBody;

        switch(this.state.screen){
            case 'signin':
                formBody = (
                    <SigninForm
                        toggleScreen={this.toggleScreen}
                        signin={this.signin}
                        error={this.props.signinErrorMessage}
                        cleanErrorMessageSigninForm={this.cleanErrorMessageSigninForm}
                        t={this.props.t}
                    />
                );

                break;
            case 'forgot-password':
            case 'one-time-login':
                formBody = (
                    <ForgotPasswordForm
                        toggleScreen={this.toggleScreen}
                        screen={this.state.screen}
                        forgotPassword={this.forgotPassword}
                        forgotPasswordIsFetching={this.props.forgotPasswordIsFetching}
                        forgotPasswordSuccess={this.props.forgotPasswordSuccess}
                        forgotPasswordError={this.props.forgotPasswordError}
                        forgotPasswordErrorMessage={this.props.forgotPasswordErrorMessage}
                        forgotPasswordCleanError={this.forgotPasswordCleanError}
                        oneTimeLogin={this.oneTimeLogin}
                        t={this.props.t}
                    />
                );

                break;
            default:
                formBody = null;

                break;
        }

        let showlogoRedes = process.env.REACT_APP_EVENT_LOG_ENV === "demo";

        return (
            <>
                {formBody}
                {showlogoRedes && (
                    <div className="mt20 text-center">
                        <img
                            style={{
                                maxWidth: '100%',
                                width: 360
                            }}
                            alt="Red.es - Una manera de hacer Europa"
                            src={'/img/logo-redes-europa.png'}/>
                    </div>
                )}
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const { t } = ownProps;

    // Reducers
    const sessionReducer = state.system.auth.session;
    const forgotPasswordReducer = state.system.auth.forgotPassword;


    // Session
    const isLoggedIn = sessionReducer.get('isLoggedIn'),
        errorSignin = sessionReducer.get('errorSignin') || Immutable.Map({});

    // Look for error code in 18n strings
    let signinErrorMessage = errorSignin.size > 0 ? t(`errors.${errorSignin.get('code') || "unknownError"}`) : '';


    // Forgot Password
    const forgotPasswordIsFetching = forgotPasswordReducer.get('isFetching'),
        forgotPasswordError = forgotPasswordReducer.get('error'),
        forgotPasswordErrorCode = forgotPasswordReducer.get('errorCode') || "unknownError",
        forgotPasswordSuccess = forgotPasswordReducer.get('success');

    let forgotPasswordErrorMessage = forgotPasswordError ? t(`errors.${forgotPasswordErrorCode}`) : '';

    return {
        isLoggedIn,
        signinErrorMessage,
        forgotPasswordIsFetching, forgotPasswordSuccess, forgotPasswordErrorMessage, forgotPasswordError
    }
}

export default withTranslation()(connect(mapStateToProps)(Signin));
