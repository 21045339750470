import GraphLib from "graphlib";

export default function getLocalDatasets(queries, queryRelations = [], queryCombinations = [], mainQuery) {

    if (queryRelations.length === 0 && queryCombinations.length === 0 && queries.every(query => query.fields.every(field => field.field_type === "measure"))) {
        return [{
            _id: `LQ1`,
            queries: queries.map(query => query.queryId),
        }];
    }
    
    // Set needed queries
    const neededQueries = new Set();
    queries.forEach(query => neededQueries.add(query));

    const aloneQueries = new Set();
    queries.forEach(query => aloneQueries.add(query.queryId));

    // Initialize needed set and graphs
    let g = new GraphLib.Graph();

    // Fill needless queries and graphs with all query relations
    queryRelations.forEach(queryRelation => {

        const query1 = queryRelation.query1;
        const query2 = queryRelation.query2;

        aloneQueries.delete(query1);
        aloneQueries.delete(query2);

        if (!g.hasNode(query1)) {
            g.setNode(query1);
        }

        if (!g.hasNode(query2)) {
            g.setNode(query2);
        }

        g.setEdge(query1, query2);

    });

    // Fill needless queries and graphs will all query combinations
    if (queryCombinations.length > 0) {
        aloneQueries.delete(mainQuery);
        if (!g.hasNode(mainQuery)) {
            g.setNode(mainQuery);
        }

        queryCombinations.forEach((combination) => {

            const query = combination.query;
            aloneQueries.delete(query);

            if (!g.hasNode(query)) {
                g.setNode(query);
            }

            g.setEdge(mainQuery, query);
        });
    }
    
    [...aloneQueries].forEach((query) => {
        g.setNode(query);
    });

    return GraphLib.alg.components(g).map((localDataset, index) => {
        return {
            _id: `LQ${index + 1}`,
            queries: localDataset,
        };
    });

}
