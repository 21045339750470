import React from 'react';
import { merge } from 'lodash';
import * as cellRendererLib from "@biuwer/common/src/libs/cell-renderer-lib";

export function getColumnWidth({ index }, grid, tableSettings, leftGridData, mainGridData, rightGridData) {

    // Initialize to tableSettings.columnWidth or default 75px
    let columnWidth = tableSettings.columnWidth || 75;

    // Obtain cell width
    switch(grid) {
        case 'leftGrid':
            if (leftGridData && leftGridData[0][index]){
                columnWidth = leftGridData[0][index].style.width || tableSettings.leftColumnWidth || columnWidth;
            }
            break;
        case 'mainGrid':
            if (mainGridData && mainGridData[0][index]){
                columnWidth = mainGridData[0][index].style.width || columnWidth;
            }
            break;
        case 'rightGrid':
            if (rightGridData && rightGridData[0][index]){
                columnWidth = rightGridData[0][index].style.width || tableSettings.rightColumnWidth || columnWidth;
            }
            break;
        default:
            break;
    }

    return columnWidth;
}

export function getRowHeight({ index }, grid, tableSettings, mainHeaderData, mainGridData, mainFooterData) {

    // Initialize to tableSettings.rowHeight or default 32px
    let rowHeight = tableSettings.rowHeight || 32;

    // Obtain cell height
    switch(grid) {
        case 'header':
            if (mainHeaderData && mainHeaderData[index] && mainHeaderData[index][0]){
                rowHeight = tableSettings.headerHeight || rowHeight;
                //rowHeight = mainHeaderData[index][0].style.height || tableSettings.headerHeight || rowHeight;
            }
            break;
        case 'mainGrid':
            if (mainGridData && mainGridData[index] && mainGridData[index][0]){
                rowHeight = mainGridData[index][0].style.height || rowHeight;
            }
            break;
        case 'footer':
            if (mainFooterData && mainFooterData[index] && mainFooterData[index][0]){
                rowHeight = mainFooterData[index][0].style.height || tableSettings.footerHeight || rowHeight;
            }
            break;
        default:
            break;
    }

    return rowHeight;
}

// Total header height (different rows can have different heights)
export function getHeaderHeight(mainHeaderData, tableSettings) {
    let headerHeight = 0;

    if (mainHeaderData && mainHeaderData.length > 0) {
        mainHeaderData.map((row) => {
            //headerHeight += row[0].style.height || tableSettings.headerHeight || 32;
            headerHeight += tableSettings?.headerHeight || 32;
            return headerHeight;
        });
    }

    return headerHeight;
}

// Total main grid height (different rows can have different heights)
export function getMainGridHeight(mainGridData, tableSettings) {
    let mainGridHeight = 0;

    if (mainGridData && mainGridData.length > 0) {
        mainGridData.map((row) => {
            mainGridHeight += row[0]?.style?.height || tableSettings?.rowHeight || 32;
            return mainGridHeight;
        });
    }

    return mainGridHeight;
}

// Total footer height (different rows can have different heights)
export function getFooterHeight(mainFooterData, tableSettings) {
    let footerHeight = 0;

    if (mainFooterData && mainFooterData.length > 0) {
        mainFooterData.map((row) => {
            footerHeight += row[0]?.style?.height || tableSettings?.footerHeight || 32;
            return footerHeight;
        });
    }

    return footerHeight;
}

// Header Grids Renderers

export function leftHeaderRenderer({ columnIndex, key, rowIndex, style }, leftHeaderData, onClickLeftHeader, onResizeLeftHeader) {

    if (leftHeaderData[rowIndex][columnIndex] && leftHeaderData[rowIndex][columnIndex].functions) {
        let renderer = leftHeaderData[rowIndex][columnIndex].functions.renderer;
        let parameters = {
            key: key,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
            style: merge(style, leftHeaderData[rowIndex][columnIndex].style),
            elements: leftHeaderData[rowIndex][columnIndex].elements,
            functions: leftHeaderData[rowIndex][columnIndex].functions,
            onClick: onClickLeftHeader,
            onResize: onResizeLeftHeader
        };
        return cellRendererLib[renderer](parameters);
    } else {
        return (
            <div
                key={key}
                style={style}
            >
            </div>
        );
    }
}

export function mainHeaderRenderer({ columnIndex, key, rowIndex, style }, mainHeaderData, onClickMainHeader, onChangeMainHeader, onBlurMainHeader, onFocusMainHeader, onResizeMainHeader) {

    if (mainHeaderData[rowIndex][columnIndex] && mainHeaderData[rowIndex][columnIndex].functions) {
        let renderer = mainHeaderData[rowIndex][columnIndex].functions.renderer;
        let parameters = {
            key: key,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
            style: merge(style, mainHeaderData[rowIndex][columnIndex].style),
            elements: mainHeaderData[rowIndex][columnIndex].elements,
            functions: mainHeaderData[rowIndex][columnIndex].functions,
            onClick: onClickMainHeader,
            onChange: onChangeMainHeader,
            onBlur: onBlurMainHeader,
            onFocus: onFocusMainHeader,
            onResize: onResizeMainHeader
        };
        return cellRendererLib[renderer](parameters);
    } else {
        return (
            <div
                key={key}
                style={style}
            >
            </div>
        );
    }
}

export function rightHeaderRenderer({ columnIndex, key, rowIndex, style }, rightHeaderData, onClickRightHeader, onResizeRightHeader) {

    if (rightHeaderData[rowIndex][columnIndex] && rightHeaderData[rowIndex][columnIndex].functions) {
        let renderer = rightHeaderData[rowIndex][columnIndex].functions.renderer;
        let parameters = {
            key: key,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
            style: merge(style, rightHeaderData[rowIndex][columnIndex].style),
            elements: rightHeaderData[rowIndex][columnIndex].elements,
            functions: rightHeaderData[rowIndex][columnIndex].functions,
            onClick: onClickRightHeader,
            onResize: onResizeRightHeader
        };
        return cellRendererLib[renderer](parameters);
    } else {
        return (
            <div
                key={key}
                style={style}
            >
            </div>
        );
    }
}

// Center Grids Renderers

export function leftGridRenderer({ columnIndex, key, rowIndex, style }, leftGridData, onClickLeftGrid, onChangeLeftGrid, onBlurLeftGrid, onFocusLeftGrid, moveCell) {

    if (leftGridData[rowIndex][columnIndex] && leftGridData[rowIndex][columnIndex].functions) {
        let renderer = leftGridData[rowIndex][columnIndex].functions.renderer;
        let parameters = {
            key: key,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
            style: merge(style, leftGridData[rowIndex][columnIndex].style),
            elements: leftGridData[rowIndex][columnIndex].elements,
            functions: leftGridData[rowIndex][columnIndex].functions,
            onClick: onClickLeftGrid,
            onChange: onChangeLeftGrid,
            onBlur: onBlurLeftGrid,
            onFocus: onFocusLeftGrid,
            moveCell: moveCell
        };

        return cellRendererLib[renderer](parameters);
    } else {
        return (
            <div
                key={key}
                style={style}
            >
            </div>
        );
    }
}

export function mainGridRenderer({ columnIndex, key, rowIndex, style }, mainGridData, onClickMainGrid, onChangeMainGrid, onBlurMainGrid, onFocusMainGrid) {

    if (mainGridData[rowIndex][columnIndex] && mainGridData[rowIndex][columnIndex].functions) {
        let renderer = mainGridData[rowIndex][columnIndex].functions.renderer;
        let parameters = {
            key: key,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
            style: merge(style, mainGridData[rowIndex][columnIndex].style),
            elements: mainGridData[rowIndex][columnIndex].elements,
            functions: mainGridData[rowIndex][columnIndex].functions,
            onClick: onClickMainGrid,
            onChange: onChangeMainGrid,
            onBlur: onBlurMainGrid,
            onFocus: onFocusMainGrid
        };

        return cellRendererLib[renderer](parameters);
    } else {
        return (
            <div key={key} style={style}></div>
        );
    }
}

export function rightGridRenderer({ columnIndex, key, rowIndex, style }, rightGridData) {

    if (rightGridData[rowIndex][columnIndex] && rightGridData[rowIndex][columnIndex].functions) {
        let renderer = rightGridData[rowIndex][columnIndex].functions.renderer;
        let parameters = {
            key: key,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
            style: merge(style, rightGridData[rowIndex][columnIndex].style),
            elements: rightGridData[rowIndex][columnIndex].elements,
            functions: rightGridData[rowIndex][columnIndex].functions
        };
        return cellRendererLib[renderer](parameters);
    } else {
        return (
            <div
                key={key}
                style={style}
            >
            </div>
        );
    }
}

// Footer Grids Renderers

export function leftFooterRenderer({ columnIndex, key, rowIndex, style }, leftFooterData) {

    if (leftFooterData[rowIndex][columnIndex] && leftFooterData[rowIndex][columnIndex].functions) {
        let renderer = leftFooterData[rowIndex][columnIndex].functions.renderer;
        let parameters = {
            key: key,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
            style: merge(style, leftFooterData[rowIndex][columnIndex].style),
            elements: leftFooterData[rowIndex][columnIndex].elements,
            functions: leftFooterData[rowIndex][columnIndex].functions
        };
        return cellRendererLib[renderer](parameters);
    } else {
        return (
            <div
                key={key}
                style={style}
            >
            </div>
        );
    }
}

export function mainFooterRenderer({ columnIndex, key, rowIndex, style }, mainFooterData) {

    if (mainFooterData[rowIndex][columnIndex] && mainFooterData[rowIndex][columnIndex].functions) {
        let renderer = mainFooterData[rowIndex][columnIndex].functions.renderer;
        let parameters = {
            key: key,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
            style: merge(style, mainFooterData[rowIndex][columnIndex].style),
            elements: mainFooterData[rowIndex][columnIndex].elements,
            functions: mainFooterData[rowIndex][columnIndex].functions
        };
        return cellRendererLib[renderer](parameters);
    } else {
        return (
            <div
                key={key}
                style={style}
            >
            </div>
        );
    }
}

export function rightFooterRenderer({ columnIndex, key, rowIndex, style }, rightFooterData) {

    if (rightFooterData[rowIndex][columnIndex] && rightFooterData[rowIndex][columnIndex].functions) {
        let renderer = rightFooterData[rowIndex][columnIndex].functions.renderer;
        let parameters = {
            key: key,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
            style: merge(style, rightFooterData[rowIndex][columnIndex].style),
            elements: rightFooterData[rowIndex][columnIndex].elements,
            functions: rightFooterData[rowIndex][columnIndex].functions
        };
        return cellRendererLib[renderer](parameters);
    } else {
        return (
            <div
                key={key}
                style={style}
            >
            </div>
        );
    }
}