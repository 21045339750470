export const STEPS_LIST_PAGE = `
    _id
    name
    status
    step_type {
        _id
        name
        needs_data_connection
    }
    data_connection {
        _id
        name
    }
    inputs {
        name
    }
    outputs {
        name
    }
`;