import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import initializeNumeral from './numeral';
import initializeMoment from './moment';
import translation_es from './locales/es/translation';
import translation_en from './locales/en/translation';
import pricing_es from './locales/es/pricing';
import pricing_en from './locales/en/pricing';
import billing_es from './locales/es/billing';
import billing_en from './locales/en/billing';
import richText_es from "./locales/es/richText";
import richText_en from "./locales/en/richText";
import numeral_es from "./locales/es/numeral";
import infiniteCalendar_en from "./locales/en/infiniteCalendar";
import infiniteCalendar_es from "./locales/es/infiniteCalendar";
import errors_es from "./locales/es/errors"
import errors_en from "./locales/en/errors"

// Set languages and name spaces arrays
export const languages = [ 'en', 'es' /*Add more languages here */];
const nameSpaces = [ 'translation', 'pricing', 'billing', 'richText', "errors" /*Add more namespaces here*/ ];
export const defaultLocale = 'en';
export const defaultNS = 'translation';
export const numeralFormats = [undefined, numeral_es];
export const infiniteCalendarConfig = [infiniteCalendar_en, infiniteCalendar_es];

initializeNumeral(defaultLocale, languages);
initializeMoment(defaultLocale, languages);

i18n
    .use(initReactI18next)
    .init({
        resources: {
            es: {
                translation: translation_es,
                pricing: pricing_es,
                billing: billing_es,
                richText: richText_es,
                errors: errors_es,
                /* Add more namespaces here */
            },
            en: {
                translation: translation_en,
                pricing: pricing_en,
                billing: billing_en,
                richText: richText_en,
                errors: errors_en,
                /* Add more namespaces here */
            },
            /* Add more languages here */
        },

        fallbackNS: defaultNS,

        fallbackLng: defaultLocale,

        lng: defaultLocale,

        whitelist: languages,

        preload: languages,

        debug: false,

        ns: nameSpaces,

        defaultNS: defaultNS,

        interpolation: {
            escapeValue: false // not needed for react!!
        },

        initImmediate: false,

    }).then(() => {});

export default i18n;
