import { PROFILES_FULL } from "../security/security-gql";

export const SPACES_LIST = `
    _id
    name
    desc
    status
    space_type
    theme
    cards {
        _id
        name
    }
    pages {
        _id
        name
    }
    collections {
        _id
        name
    }
    ${PROFILES_FULL}
    demo
    created
    updated
    i18n {
        language
        field
        value
    }
`;

export const SPACES_MINIMAL = `
    _id
    name
`;

export const SPACES_DETAIL = `
    _id
    name
    desc
    status
    space_type
    theme
    ${PROFILES_FULL}
    demo
    created
    updated
    i18n {
        language
        field
        value
    }
`;

export const NEW_SPACES_LIST = `
    _id
    name
    desc
    space_type
    theme
    i18n {
        language
        field
        value
    }
    ${PROFILES_FULL}
`;