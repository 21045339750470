import { Map, fromJS } from 'immutable';
import { defaultContext } from "@biuwer/redux/src/config/constants";

import { ORGANIZATIONS_INITIALIZE, ORGANIZATIONS_LIST_INITIALIZE, ORGANIZATIONS_DETAIL_INITIALIZE,
    ORGANIZATIONS_FETCH_REQUEST, ORGANIZATIONS_FETCH_SUCCESS, ORGANIZATIONS_FETCH_ERROR,
    ORGANIZATION_FETCH_REQUEST, ORGANIZATION_FETCH_SUCCESS, ORGANIZATION_FETCH_ERROR,
    ORGANIZATION_CREATE_REQUEST, ORGANIZATION_CREATE_SUCCESS, ORGANIZATION_CREATE_ERROR,
    ORGANIZATION_UPDATE_REQUEST, ORGANIZATION_UPDATE_SUCCESS, ORGANIZATION_UPDATE_ERROR,
    ORGANIZATION_CHANGE_STATUS_REQUEST, ORGANIZATION_CHANGE_STATUS_SUCCESS, ORGANIZATION_CHANGE_STATUS_ERROR,
    ORGANIZATION_DELETE_REQUEST, ORGANIZATION_DELETE_SUCCESS, ORGANIZATION_DELETE_ERROR
} from './organizations-actions';

import {
    PAYMENT_CREATE_SUCCESS, PAYMENT_UPDATE_SUCCESS, PAYMENT_DEFAULT_SUCCESS, PAYMENT_DELETE_SUCCESS
} from "@biuwer/redux/src/billing/payments/payments-actions";
import {ORDER_CREATE_SUCCESS} from "@biuwer/redux/src/billing/orders/orders-actions";

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

export default function organizationsReducer(state = initialState, action) {
    let newState, index = -1, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || defaultContext}`;
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch (action.type) {

        // Initialize
        case ORGANIZATIONS_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));
            return newState;
        case ORGANIZATIONS_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;
        case ORGANIZATIONS_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;

        // Organizations fetch (list)
        case ORGANIZATIONS_FETCH_REQUEST:

            newState = state;

            // Users fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case ORGANIZATIONS_FETCH_SUCCESS:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case ORGANIZATIONS_FETCH_ERROR:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;

        // Organization fetch (detail)
        case ORGANIZATION_FETCH_REQUEST:

            newState = state;

            // User fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case ORGANIZATION_FETCH_SUCCESS:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case ORGANIZATION_FETCH_ERROR:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;

        // Create organization
        case ORGANIZATION_CREATE_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case ORGANIZATION_CREATE_SUCCESS:

            // Update payload with new created organization
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case ORGANIZATION_CREATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;
        case ORGANIZATION_UPDATE_REQUEST:
        case ORGANIZATION_CHANGE_STATUS_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case ORGANIZATION_UPDATE_SUCCESS:
        case ORGANIZATION_CHANGE_STATUS_SUCCESS:

            // Update payload with updated user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            error: action.error,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case ORDER_CREATE_SUCCESS:
            newState = state;
            const updatedOrganization = action.payload.organization;
            detailUpdatedPayload = fromJS(updatedOrganization);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === updatedOrganization._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(updatedOrganization));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            error: action.error,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case ORGANIZATION_UPDATE_ERROR:
        case ORGANIZATION_CHANGE_STATUS_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;
        case PAYMENT_CREATE_SUCCESS:
        case PAYMENT_UPDATE_SUCCESS:
        case PAYMENT_DELETE_SUCCESS:
        case PAYMENT_DEFAULT_SUCCESS:
            newState = state;

            // Update payload with updated organization
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the organization index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            error: action.error,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case ORGANIZATION_DELETE_REQUEST:
            newState = state


            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete'])
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete'])

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated'])
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated'])

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))

            return newState
        case ORGANIZATION_DELETE_SUCCESS:

            // Update payload with deleted organization
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        deleted: action.deleted
                    })
                }))

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload'])
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id)

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index])

                    newState = newState
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                            delete: Map({
                                isFetching: action.isFetching,
                                error: action.error,
                                deleted: action.deleted
                            })
                        })
                }
            }
            return newState
        case ORGANIZATION_DELETE_ERROR:
            newState = state
            .mergeIn([detail], Map({
                delete: Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                })
            }))
            .mergeIn([list], Map({
                delete: Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                })
            }))
            return newState
        default:
            return state;
    }
}
