// Libs
import Auth from "@biuwer/redux/src/system/auth/auth-lib";

const applicationsMap = new Map();
applicationsMap.set("hubspot-crm", "HUBSPOT_CRM");
applicationsMap.set("woocommerce", "WOOCOMMERCE");
applicationsMap.set("prestashop", "PRESTASHOP");
applicationsMap.set("clockify", "CLOCKIFY");
applicationsMap.set("google-analytics-v3", "GOOGLE_ANALYTICS_V3");

const getOrganizationLanguage = () => {
    const currentOrganization = Auth.getCurrentOrganization();
    const orgLang = (!!currentOrganization.settings && currentOrganization.settings.language) || "en";
    return orgLang.toUpperCase();
};

export const getDefaultContentTemplateId = (application) => {
    return process.env[`REACT_APP_CONTENT_TEMPLATE_${applicationsMap.get(application)}_${getOrganizationLanguage()}`]
};

export const getContentRotue = (type, id) => `/${type}s/${id}`

export default {
    getDefaultContentTemplateId,
    getContentRotue,
};
