export const getDCEXAxisSectionTitle = (chartType) => ['scatter', 'bubbles', 'heatmap'].includes(chartType) ? 'common.x-axisLabel' : 'common.dimensionLabel';
export const getDCEYAxisSectionTitle = (chartType) => ['scatter', 'bubbles'].includes(chartType) ? 'common.y-axisLabel' : 'common.metricLabel_plural';
export const getDCEBreakSectionTitle = (chartType) => ['scatter', 'bubbles'].includes(chartType) ? 'common.colorBy' : 'common.breakByLabel';

export const getDroppableFromForXAxisContainer = (chartType) => ['scatter', 'bubbles', 'heatmap'].includes(chartType) ? ["available-fields", "calculated-fields", "chart-metrics", "chart-breakBy", "vertical-dimension"] : ["available-fields", "calculated-fields", "chart-breakBy"];
export const getDroppableFromForYAxisContainer = (chartType) => ['scatter', 'bubbles', 'heatmap'].includes(chartType) ? ["available-fields", "calculated-fields", "chart-dimension"] : ["available-fields", "calculated-fields"];

export const getDCEChartSections = (chartType) => {
    let availableSections = {
        dimensionSection: false,
        metricsSection: true,
        breakBySection: false,
        radiusSection: false,
        thresholdSection: false,
        heatmapSection: false
    };

    if (chartType.requiresDimension) availableSections.dimensionSection = true;
    if (chartType.breakable) availableSections.breakBySection = true;
    if (chartType.requiresRadius) availableSections.radiusSection = true;
    if (chartType.requiresThreshold) availableSections.thresholdSection = true;
    if (chartType.requiresHeatmap) availableSections.heatmapSection = true;

    return availableSections;
};

export default {
    getDCEXAxisSectionTitle,
    getDCEYAxisSectionTitle,
    getDCEBreakSectionTitle,
    getDroppableFromForXAxisContainer,
    getDroppableFromForYAxisContainer,
    getDCEChartSections
};