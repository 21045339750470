/**
 * Custom superagent default values.
 */

import defaults from 'superagent-defaults';
import nocache from 'superagent-no-cache';


let request = defaults();

// Set nocache for incompatibility with IE / Edge explorers (caching get petitions)
request.use(nocache);

export default request;