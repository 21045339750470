export const STEP_TYPES_LIST = `
    _id
    name
    status
    inputs {
        mandatory
        name
        type
    }
    outputs {
        name
        type
    }
    needs_data_connection
`;