import { combineReducers } from "redux";
import { cloneDeep } from "lodash";

/**
 * DatasetQueries filters Reducer
 */
import {
    DATASET_QUERIES_FETCH_REQUEST,
    DATASET_QUERIES_FETCH_SUCCESS,
    DATASET_QUERIES_FETCH_ERROR,
    DATASET_QUERIES_SET_ACTIVE
} from './dataset-queries-actions';

import { getDatasetQueryIndex } from "./dataset-queries-lib";

const initialState =[];

export default (state = initialState, action) =>{

    let newState;
    newState = cloneDeep(state);

    switch(action.type) {
        case DATASET_QUERIES_FETCH_REQUEST:
            return datasetQueryFetchRequest(newState, action, getDatasetQueryIndex);
        case DATASET_QUERIES_FETCH_SUCCESS:
            return datasetQueryFetchSuccess(newState, action, getDatasetQueryIndex);
        case DATASET_QUERIES_FETCH_ERROR:
            return datasetQueryFetchError(newState, action, getDatasetQueryIndex);
        case DATASET_QUERIES_SET_ACTIVE:
            return datasetQuerySetActive(newState, action, getDatasetQueryIndex);
        default:
            return state;
    }

}

const datasetQueryFetchRequest = (newState, action, getDatasetQueryIndex) => {
    let datasetQueryIndex, datasetQueryQueryIndex, newQueries;
    datasetQueryIndex = getDatasetQueryIndex(newState, action.datasetQueryFilters);
    if (datasetQueryIndex !== -1) {
        datasetQueryQueryIndex = getDatasetQueryIndex(newState[datasetQueryIndex].queries, action.datasetQueryQueryFilters);
        newQueries = newState[datasetQueryIndex].queries.map(query => ({ ...query, isActive: false }));
        if (datasetQueryQueryIndex !== -1) {
            newQueries[datasetQueryQueryIndex] = {
                ...newQueries[datasetQueryQueryIndex],
                isActive: true
            }
        }
        else {
            newQueries.push({
                ...action.datasetQueryQueryFilters,
                isActive: true,
                timestamp: Date.now()
            });
        }
        newState[datasetQueryIndex] = {
            ...newState[datasetQueryIndex],
            isFetching: true,
            queries: newQueries
        };
    }
    else {
        newState = [...newState, {
            ...action.datasetQueryFilters,
            isFetching: true,
            queries: [{
                ...action.datasetQueryQueryFilters,
                isActive: true,
                timestamp: Date.now()
            }]
        }];
    }
    return newState;
};

const datasetQueryFetchSuccess = (newState, action, getDatasetQueryIndex) => {
    let datasetQueryIndex, datasetQueryQueryIndex, newQueries;
    datasetQueryIndex = getDatasetQueryIndex(newState, action.datasetQueryFilters);
    datasetQueryQueryIndex = getDatasetQueryIndex(newState[datasetQueryIndex].queries, action.datasetQueryQueryFilters);
    newQueries = newState[datasetQueryIndex].queries.map(query => ({ ...query, isActive: false }));
    newQueries[datasetQueryQueryIndex] = {
        ...newQueries[datasetQueryQueryIndex],
        isActive: true,
        dataId: action.dataId,
        data: action.data,
        dataWarnings: action.dataWarnings,
        dataErrors: action.dataErrors
    }
    newState[datasetQueryIndex] = {
        ...newState[datasetQueryIndex],
        isFetching: false,
        queries: newQueries
    };
    return newState;
};

const datasetQueryFetchError = (newState, action, getDatasetQueryIndex) => {
    let datasetQueryIndex, datasetQueryQueryIndex, newQueries;
    datasetQueryIndex = getDatasetQueryIndex(newState, action.datasetQueryFilters);
    datasetQueryQueryIndex = getDatasetQueryIndex(newState[datasetQueryIndex].queries, action.datasetQueryQueryFilters);
    newQueries = newState[datasetQueryIndex].queries.map(query => ({ ...query, isActive: false }));
    newQueries[datasetQueryQueryIndex] = {
        ...newQueries[datasetQueryQueryIndex],
        isActive: true,
        error: action.error
    }
    newState[datasetQueryIndex] = {
        ...newState[datasetQueryIndex],
        isFetching: false,
        queries: newQueries
    };
    return newState;
};

const datasetQuerySetActive = (newState, action, getDatasetQueryIndex) => {
    let datasetQueryIndex, datasetQueryQueryIndex, newQueries;
    datasetQueryIndex = getDatasetQueryIndex(newState, action.datasetQueryFilters);
    datasetQueryQueryIndex = getDatasetQueryIndex(newState[datasetQueryIndex].queries, action.datasetQueryQueryFilters);
    newQueries = newState[datasetQueryIndex].queries.map(query => ({ ...query, isActive: false }));
    newQueries[datasetQueryQueryIndex] = {
        ...newQueries[datasetQueryQueryIndex],
        isActive: true
    }
    newState[datasetQueryIndex] = {
        ...newState[datasetQueryIndex],
        queries: newQueries
    };
    return newState;
};