
// Query for minimal data connections list
export const MINIMAL_DATA_CONNECTIONS_LIST = `
    _id
    name
    type
    provider
    driver
    data_connection_type {
        _id
        name
        category
    }
`;

// Default query for data connections list actions
export const DATA_CONNECTIONS_LIST_DETAIL = `
    _id
    name
    desc
    type
    status
    driver
    provider
    is_source
    is_destination
    category
    schedule {
        _id
        from
        to
        interval
    }
    details {
        provider
        type
        driver
        needs_container
        default_container
        authentication_schema
        fields
        oauth_token
    }
    ssh_tunnel {
        available
        enabled
        host
        port
        user
        auth_type
        auth_value
        passphrase
        requires_passphrase
    }
    data_connection_type {
        _id
        name
        category
        logo
        has_reverse_engineering
    }
    organization {
        _id
        name
        primary_contact {
            _id
            email
            full_name
        }
    }
    datasets {
        _id
    }
    datamodels {
        _id
    }
    created
    profiles {
        own {
            _id
            name
            full_name
        }
        admin
        edit
        view
    }
    profile_level
    owner {
        _id
        name
        full_name
        email
    }
    template
    managed_job
`;

// Default query for data connection detail actions
export const DATA_CONNECTIONS_DETAIL = `
    _id
    name
    desc
    is_source
    is_destination
    category
    schedule {
        _id
        from
        to
        interval
    }
    data_connection_type {
        _id
        name
        desc
        has_reverse_engineering
        logo
        category
        required_plan
        provider
        type
        driver
        status
        i18n {
            language
            name
            desc
        }
        details {
            provider
            type
            driver
            needs_container
            default_container
            authentication_schema
            fields
            oauth_token
            needs_mapping
            mapping_type
            needs_schedule
            needs_mapping
        }
    }
    type
    driver
    provider
    details {
        type
        driver
        provider
        needs_container
        default_container
        authentication_schema
        fields
        oauth_token
        mapping {
            header
            skip_rows
            rows_to_skip
            dataset
            data_load_mode
            date_format
            custom_date_format
            sheet
            number_format {
                thousands
                decimal
            }
            boolean_format {
                true
                false
            }
            range {
                from_column
                from_row
                to_column
                to_row
            }
            mapping_fields {
                name
                dataset_field
                original_field
                path
                data_type
                field_type
            }
        }
    }
    time_zone {
        available
        enabled
        value
    }
    ssh_tunnel {
        available
        enabled
        host
        port
        user
        auth_type
        auth_value
        passphrase
        requires_passphrase
    }
    organization {
        _id
        name
    }
    status
    created
    user_created {
        _id
        name
        email
    }
    updated
    user_updated {
        _id
        name
        email
    }
    datasets {
        _id
    }
    datamodels {
        _id
    }
    profiles {
        own {
            _id
            name
            full_name
        }
        admin
        edit
        view
    }
    profile_level
    owner {
        _id
        name
        full_name
        email
    }
    last_execution {
        _id
        status
        start_date
        end_date
    }
    next_execution {
        _id
        status
        start_date
        end_date
    }
`;

export const DATA_CONNECTION_REVERSE_ENGINEERING_LIST = `
    _id
    name
    details {
        type
        driver
        provider
        needs_container
        default_container
        authentication_schema
        oauth_token
        fields
    }
`;

export const DATASET_DIALOG = `
    _id
    name
    category
    details {
        needs_container
    }
`;

export const DATA_CONNECTION_MINIMAL_LIST = `
    _id
    name
    status
    profiles {
        own {
            _id
            name
            full_name
        }
        admin
        edit
        view
    }
    profile_level
    owner {
        _id
        name
        full_name
        email
    }
`;

export const TEST_DATA_CONNECTION = `
    payload
    message
`;

export const GOOGLE_ANALYTICS_METADATA = `
    analyticsAccounts
    properties
    views
`;

export const GOOGLE_SHEETS_METADATA = `
    spreadsheets
`;

export const GOOGLE_SHEET_METADATA = `
    id
    name
    sheets
`;

export const REVERSE_ENGINEERING = `
    name
    container
    metadata {
        name
        data_type
        field_type
        original_data_type
    }
`;