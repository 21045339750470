import request from "@biuwer/common/src/libs/superagent";

import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import { PRICE_LISTS_DETAIL } from './price-lists-gql';

/*
 * PriceLists action types
 */
export const PRICE_LISTS_INITIALIZE = 'PRICE_LISTS_INITIALIZE';
export const PRICE_LIST_DETAIL_INITIALIZE = 'PRICE_LIST_DETAIL_INITIALIZE';
export const PRICE_LIST_LIST_INITIALIZE = 'PRICE_LIST_LIST_INITIALIZE';

export const PRICE_LISTS_FETCH_REQUEST = 'PRICE_LISTS_FETCH_REQUEST';
export const PRICE_LISTS_FETCH_SUCCESS = 'PRICE_LISTS_FETCH_SUCCESS';
export const PRICE_LISTS_FETCH_ERROR = 'PRICE_LISTS_FETCH_ERROR';

export const PRICE_LIST_FETCH_REQUEST = 'PRICE_LIST_FETCH_REQUEST';
export const PRICE_LIST_FETCH_SUCCESS = 'PRICE_LIST_FETCH_SUCCESS';
export const PRICE_LIST_FETCH_ERROR = 'PRICE_LIST_FETCH_ERROR';

// Generic Returns Skeleton
const generateSkeleton = (type, body, context = 'primary') => {
    let skeleton = { type };

    switch (type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false,
                context: context
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                context: context
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body,
                context: context
            };
            break;
        default:
            break;
    }

    switch(type){
        case PRICE_LIST_FETCH_ERROR:
            skeleton.issuePayload = {
                ...skeleton.issuePayload || {},
                // code: extraArgs.code
            };
            break;
        default:
            break;
    }

    return skeleton;
};


/**
 * Initialize PriceLists
 */
export function initializePriceLists() {
    return {
        type: PRICE_LISTS_INITIALIZE
    };
}

/**
 * Initialize priceList detail action
 */
export function initializePriceListsDetail() {
    return {
        type: PRICE_LIST_DETAIL_INITIALIZE
    };
}

/**
 * Initialize priceList list action
 */
export function initializeUsersList() {
    return {
        type: PRICE_LIST_LIST_INITIALIZE
    };
}

/**
 * getPriceList: Reads the requested priceList
 * @param priceListId - Id belonging to the requested priceList
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function getPriceList(priceListId, context  = 'primary', gql = PRICE_LISTS_DETAIL) {

    return async dispatch => {
        try {

            const query = {
                query: `query($priceListId: String!) {
                    readPriceList(priceListId: $priceListId) {
                        ${gql}
                    }
                }`,
                variables: {
                    priceListId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(PRICE_LIST_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(PRICE_LIST_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readPriceList) {
                    dispatch(generateSkeleton(PRICE_LIST_FETCH_SUCCESS, res.body.data.readPriceList, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(PRICE_LIST_FETCH_ERROR, err, context));
        }
    }
}

/**
 * queryPriceList - Reads multiple priceLists that satisfies given dataQuery
 * @param query - Query to use as a filter when reading priceLists
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function queryPriceLists(query = {}, context  = 'primary', gql = PRICE_LISTS_DETAIL) {

    return async dispatch => {
        try {

            const graphQuery = {
                query: `query($query: QueryPriceListInput!) {
                    queryPriceLists(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(PRICE_LISTS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(graphQuery)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(PRICE_LISTS_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.queryPriceLists) {
                    dispatch(generateSkeleton(PRICE_LISTS_FETCH_SUCCESS, res.body.data.queryPriceLists, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(PRICE_LISTS_FETCH_ERROR, err, context));
        }
    };
}
