import React from 'react';
import moment from 'moment';

// Static images
import biuwerSigningImg from "@biuwer/core/src/assets/img/signing-img.jpg";
import biuwerLogo from "@biuwer/core/src/assets/img/logo.png";

const ExternalLayout = ({children}) => {
    return (
        <div className="login-wrapper">
            <div className="bg-pic">
                <img src={biuwerSigningImg} className="lazy" alt="Biuwer"/>
                <div className="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 m-b-20">
                    <h2 className="semi-bold text-white">
                        Powerful Business Analytics
                    </h2>
                    <p className="small">
                        {`© ${moment().year()} BIUWER`}
                    </p>
                </div>
            </div>
            <div className="login-container bg-white">
                <div className="p50 sm-p15">
                    <div className="text-center">
                        <img src={biuwerLogo} alt="logo"/>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ExternalLayout;