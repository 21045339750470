import { Map, fromJS } from 'immutable';

import {
    RESOURCE_TOKENS_INITIALIZE, RESOURCE_TOKENS_LIST_INITIALIZE, RESOURCE_TOKEN_DETAIL_INITIALIZE,
    RESOURCE_TOKENS_FETCH_REQUEST, RESOURCE_TOKENS_FETCH_SUCCESS, RESOURCE_TOKENS_FETCH_ERROR,
    RESOURCE_TOKEN_FETCH_REQUEST, RESOURCE_TOKEN_FETCH_SUCCESS, RESOURCE_TOKEN_FETCH_ERROR,
    RESOURCE_TOKEN_UPDATE_REQUEST, RESOURCE_TOKEN_UPDATE_SUCCESS, RESOURCE_TOKEN_UPDATE_ERROR,
    RESOURCE_TOKEN_CREATE_REQUEST, RESOURCE_TOKEN_CREATE_SUCCESS, RESOURCE_TOKEN_CREATE_ERROR,
    RESOURCE_TOKEN_DELETE_REQUEST, RESOURCE_TOKEN_DELETE_SUCCESS, RESOURCE_TOKEN_DELETE_ERROR
} from './resource-tokens-actions';

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

/**
 * Resource Tokens Reducer
 */

export default (state = initialState, action) => {
    let newState, index, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || `primary`}`;
    const detail = `detail_${(action && action.context) || `primary`}`;

    switch(action.type) {
        case RESOURCE_TOKENS_INITIALIZE:
            return initialState;
        case RESOURCE_TOKENS_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;
        case RESOURCE_TOKEN_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;
        case RESOURCE_TOKENS_FETCH_REQUEST:
            newState = state;

            // Resource tokens fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));
            return newState;
        case RESOURCE_TOKENS_FETCH_SUCCESS:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                issue: action.issue,
                payload: fromJS(action.payload)
            }));
            return newState;
        case RESOURCE_TOKENS_FETCH_ERROR:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                issue: action.issue,
                issuePayload: action.issuePayload
            }));
            return newState;
        case RESOURCE_TOKEN_FETCH_REQUEST:
            newState = state;

            // Resource token fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));
            return newState;
        case RESOURCE_TOKEN_FETCH_SUCCESS:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                issue: action.issue,
                payload: fromJS(action.payload)
            }));
            return newState;
        case RESOURCE_TOKEN_FETCH_ERROR:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                issue: action.issue,
                issuePayload: action.issuePayload
            }));
            return newState;
        case RESOURCE_TOKEN_CREATE_REQUEST:
            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            // Check if "created" key exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));
            return newState;
        case RESOURCE_TOKEN_CREATE_SUCCESS:

            // Update payload with new created resource token
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        issue: action.issue,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case RESOURCE_TOKEN_CREATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                }));
            return newState;
        case RESOURCE_TOKEN_UPDATE_REQUEST:
            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            // Check if "created" key exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case RESOURCE_TOKEN_UPDATE_SUCCESS:

            // Update payload with updated resource token
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the resource token index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            issue: action.issue,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case RESOURCE_TOKEN_UPDATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                }));
            return newState;
        case RESOURCE_TOKEN_DELETE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            // Check if "created" key exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        issue: action.issue
                    })
                }))
                .mergeIn([list], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        issue: action.issue
                    })
                }));
            return newState;
        case RESOURCE_TOKEN_DELETE_SUCCESS:
            newState = state
                .removeIn([detail, 'payload'])
                .mergeIn([detail], Map({
                    deleted: action.deleted
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the resource token index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => String(item.get('_id')) === String(action.payload._id));

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .removeIn([list, 'payload'])
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                            deleted: action.deleted
                        });
                }
            }
            return newState;
        case RESOURCE_TOKEN_DELETE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    deleted: action.deleted,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: action.issuePayload,
                    deleted: action.deleted
                }));
            return newState;
        default:
            return state;
    }
}