export const DATAMODEL_LIST = `
    _id
    name
    desc
    managed
    query_data_connection {
        _id
        name
    }
    datasets {
        _id
        name
    }
    relations {
        dataset1 {
            _id
            name
            alias
        }
        dataset2 {
            _id
            name
            alias
        }
        type
    }
    profile_level
    profiles {
        own {
            _id
            name
            full_name
            email
        }
        admin
        edit
        view
    }
    owner {
        _id
        full_name
        email
    }
`;

export const DATAMODEL_LIST_DATA_CARD_EDITOR_PAGE = `
    _id
    name
    desc
    managed
    query_data_connection {
        _id
        name
        data_connection_type {
            category
            details {
                type
            }
        }
    }
    datasets {
        _id
        name
        alias
        fields {    
            _id
            name
            desc
            alias
            hidden
            field_type
            aggregation
            data_type
            original_data_type
            calculated_field
            field_group
            primary_key
        }
    }
    relations {
        dataset1 {
            _id
            name
            alias
        }
        dataset2 {
            _id
            name
            alias
        }
        type
    }
    profile_level
    profiles {
        own {
            _id
            name
            full_name
            email
        }
        admin
        edit
        view
    }
    owner {
        _id
        full_name
        email
    }
`;

export const DATAMODEL_LIST_FILTERS_EDITOR_DIALOG = `
    _id
    name
    desc
    managed
    query_data_connection {
        _id
        name
    }
    datasets {
        _id
        name
        alias
        fields {    
            _id
            name
            desc
            alias
            hidden
            field_type
            aggregation
            data_type
            original_data_type
            calculated_field
            field_group
            primary_key
        }
    }
    relations {
        dataset1 {
            _id
            name
            alias
        }
        dataset2 {
            _id
            name
            alias
        }
        type
    }
    profile_level
    profiles {
        own {
            _id
            name
            full_name
            email
        }
        admin
        edit
        view
    }
    owner {
        _id
        full_name
        email
    }
`;

export const DATAMODEL_DETAIL = `
    _id
    name
    desc
    query_data_connection {
        _id
        name
        data_connection_type {
            _id
            details {
                type
            }
        }
    }
    managed
    multi_connection_enabled
    multi_connections {
        name
        users_groups
        connection
    }
    datasets {
        _id
        name
        desc
        fields {
            _id
            name
            desc
            alias
            hidden
            field_type
            aggregation
            data_type
            original_data_type
            calculated_field
            field_group
            primary_key
        }
    }
    relations {
        _id
        dataset1 {
            _id
            name
            alias
        }
        dataset2 {
            _id
            name
            alias
        }
        type
        fields {
            field1 {
                _id
                name
            }
            field2 {
                _id
                name
            }
            operation
        }
    }
    profile_level
    profiles {
        own {
            _id
            name
            full_name
            email
        }
        admin
        edit
        view
    }
    owner {
        _id
        full_name
        email
    }
    created
    updated
`;

export const DATAMODEL_TOP_BOTTOM_FILTER = `
    _id
    name
    datasets {
        _id
        name
        fields {
            _id
            name
            field_type
        }
    }
`;

export const QUERY_STRING  = `
    query_string
`;

export const DATAMODELS_LIST_FILTERS_EDITOR_DIALOG = `
    _id
    name
    datasets {
        _id
    }
`;