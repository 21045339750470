import { useDispatch } from "react-redux";

/*
 * Data card editor action types
 */

export const INITIALIZE_DCE = "INITIALIZE_DCE";
export const DCE_SET_CARD = 'DCE_SET_CARD';
export const DCE_SET_SAVED_CHANGES = 'DCE_SET_SAVED_CHANGES';
export const DCE_ON_CHANGE_TAB = 'DCE_ON_CHANGE_TAB';
export const DCE_SET_SELECTED_PAGE_ID = 'DCE_SET_SELECTED_PAGE_ID';

// Queries Tab actions
export const DCE_ON_CHANGE_SELECTED_QUERY_INDEX = "DCE_ON_CHANGE_SELECTED_QUERY_INDEX";

// Card metadata field actions

// Queries
export const DCE_ADD_CARD_QUERY = "DCE_ADD_CARD_QUERY";
export const DCE_DELETE_CARD_QUERY = "DCE_DELETE_CARD_QUERY";
export const DCE_SET_QUERY_DATAMODEL = "DCE_SET_QUERY_DATAMODEL";
export const DCE_SET_QUERY_FIELDS = "DCE_SET_QUERY_FIELDS";
export const DCE_SET_QUERY_SORT = "DCE_SET_QUERY_SORT";
export const DCE_SET_QUERY_LIMIT = "DCE_SET_QUERY_LIMIT";
export const DCE_SET_QUERY_SHOW_EMPTY_ROWS = "DCE_SET_QUERY_SHOW_EMPTY_ROWS";
export const DCE_SET_NO_GROUP_BY = "DCE_SET_NO_GROUP_BY";

// Query fields
export const DCE_SET_CARD_QUERY_FIELD = "DCE_SET_CARD_QUERY_FIELD";
export const DCE_DELETE_CARD_QUERY_FIELD = "DCE_DELETE_CARD_QUERY_FIELD";

// Query sort fields
export const DCE_ADD_CARD_QUERY_SORT_FIELD = "DCE_ADD_CARD_QUERY_SORT_FIELD";
export const DCE_SET_CARD_QUERY_SORT_FIELD = "DCE_SET_CARD_QUERY_SORT_FIELD";
export const DCE_DELETE_CARD_QUERY_SORT_FIELD = "DCE_DELETE_CARD_QUERY_SORT_FIELD";

// Card query relations
export const DCE_SET_CARD_QUERY_RELATIONS_TYPE = "DCE_SET_CARD_QUERY_RELATIONS_TYPE";
export const DCE_SET_CARD_QUERY_RELATIONS = "DCE_SET_CARD_QUERY_RELATIONS";

// Card query combinations
export const DCE_SET_CARD_QUERY_COMBINATIONS_MAIN_QUERY = "DCE_SET_CARD_QUERY_COMBINATIONS_MAIN_QUERY";
export const DCE_SET_CARD_QUERY_COMBINATIONS = "DCE_SET_CARD_QUERY_COMBINATIONS";

// Calculated fields
export const DCE_SET_CALCULATED_FIELDS = "DCE_SET_CALCULATED_FIELDS";
export const DCE_DELETE_CALCULATED_FIELD = "DCE_DELETE_CALCULATED_FIELD";

// Card visualization actions
export const DCE_SET_CARD_VISUALIZATION = "DCE_SET_CARD_VISUALIZATION";
export const DCE_DELETE_CARD_VISUALIZATION_FIELD = "DCE_DELETE_CARD_VISUALIZATION_FIELD";

// KPI visualization actions
export const DCE_SET_KPI_GROUP_ITEMS = "DCE_SET_KPI_GROUP_ITEMS";

// VerticalTable visualization actions
export const DCE_SET_TABLE_COLUMNS = "DCE_SET_TABLE_COLUMNS";
export const DCE_SET_INTERACTIVE_COLUMNS = "DCE_SET_INTERACTIVE_COLUMNS";

// CrossTable visualization actions
export const DCE_SET_CT_ROWS = "DCE_SET_CT_ROWS";
export const DCE_SET_CT_COLUMNS = "DCE_SET_CT_COLUMNS";
export const DCE_SET_CT_METRICS = "DCE_SET_CT_METRICS";

// Chart visualization actions
export const DCE_SET_CHART_DIMENSION = "DCE_SET_CHART_DIMENSION";
export const DCE_SET_CHART_BREAK_BY = "DCE_SET_CHART_BREAK_BY";
export const DCE_SET_CHART_METRICS = "DCE_SET_CHART_METRICS";
export const DCE_SET_CHART_RADIUS = "DCE_SET_CHART_RADIUS";
export const DCE_SET_CHART_HEATMAP = "DCE_SET_CHART_HEATMAP";
export const DCE_SET_CHART_GRAPH_CONFIG = "DCE_SET_CHART_GRAPH_CONFIG"

// Bullet visualization actions
export const DCE_SET_CHART_THRESHOLD = "DCE_SET_CHART_THRESHOLD";
export const DCE_SET_CHART_MIN_VALUE_FIELD = "DCE_SET_CHART_MIN_VALUE_FIELD";
export const DCE_SET_CHART_MAX_VALUE_FIELD = "DCE_SET_CHART_MAX_VALUE_FIELD";

// Map visualization actions
export const DCE_SET_MAP_LAYER_MATCHING_FIELD = "DCE_SET_MAP_LAYER_MATCHING_FIELD";
export const DCE_SET_MAP_LAYER_LAT_FIELD = "DCE_SET_MAP_LAYER_LAT_FIELD";
export const DCE_SET_MAP_LAYER_LNG_FIELD = "DCE_SET_MAP_LAYER_LNG_FIELD";
export const DCE_SET_MAP_LAYER_SYMBOL_SIZE = "DCE_SET_MAP_LAYER_SYMBOL_SIZE";
export const DCE_SET_MAP_LAYER_COLOR = "DCE_SET_MAP_LAYER_COLOR";
export const DCE_SET_MAP_LAYER_TOOLTIP_FIELDS = "DCE_SET_MAP_LAYER_TOOLTIP_FIELDS";
export const DCE_SET_MAP_LAYER_POPUP_FIELDS = "DCE_SET_MAP_LAYER_POPUP_FIELDS";
export const DCE_SET_LAYER_INDEX = "DCE_SET_LAYER_INDEX";

// Card actions actions
export const DCE_SET_CARD_ACTIONS = "DCE_SET_CARD_ACTIONS";

// Card translations actions
export const DCE_SET_CARD_TRANSLATIONS = "DCE_SET_CARD_TRANSLATIONS";

// General data card editor actions
const initializeDCE = () => dispatch => dispatch({ type: INITIALIZE_DCE });
const setCard = (card, forceChanges) => dispatch => dispatch({ type: DCE_SET_CARD, card, forceChanges });
const setSavedChanges = savedChanges => dispatch => dispatch({ type: DCE_SET_SAVED_CHANGES, savedChanges });
const onChangeTab = selectedTab => dispatch => dispatch({ type: DCE_ON_CHANGE_TAB, selectedTab });
const setSelectedPageId = newSelectedPageId => dispatch => dispatch({ type: DCE_SET_SELECTED_PAGE_ID, newSelectedPageId });

// Queries Tab actions
const onChangeSelectedQueryIndex = selectedQueryIndex => dispatch => dispatch({ type: DCE_ON_CHANGE_SELECTED_QUERY_INDEX, selectedQueryIndex });

// Card queries metadata actions
export const addCardQuery = cardQuery => (dispatch) => dispatch({ type: DCE_ADD_CARD_QUERY, cardQuery });
export const deleteCardQuery = cardQueryIndex => (dispatch) => dispatch({ type: DCE_DELETE_CARD_QUERY, cardQueryIndex });
export const setQueryDatamodel = datamodelId => (dispatch) => dispatch({ type: DCE_SET_QUERY_DATAMODEL, datamodelId });
export const setQueryFields = fields => (dispatch) => dispatch({ type: DCE_SET_QUERY_FIELDS, fields });
export const setQuerySort = sort => (dispatch) => dispatch({ type: DCE_SET_QUERY_SORT, sort });
export const setQueryLimit = limit => (dispatch) => dispatch({ type: DCE_SET_QUERY_LIMIT, limit });
export const setQueryShowEmptyRows = showEmptyRows => (dispatch) => dispatch({ type: DCE_SET_QUERY_SHOW_EMPTY_ROWS, showEmptyRows });
export const setNoGroupBy = noGroupBy => (dispatch) => dispatch({ type: DCE_SET_NO_GROUP_BY, noGroupBy });

// Card query field actions
export const setCardQueryField = (fieldIndex, newField) => (dispatch) => dispatch({ type: DCE_SET_CARD_QUERY_FIELD, fieldIndex, newField });
export const deleteCardQueryField = fieldIndex => (dispatch) => dispatch({ type: DCE_DELETE_CARD_QUERY_FIELD, fieldIndex });

// Card query sort field actions
export const addCardQuerySortField = newSortField => (dispatch) => dispatch({ type: DCE_ADD_CARD_QUERY_SORT_FIELD, newSortField });
export const setCardQuerySortField = (sortFieldIndex, newSortField) => (dispatch) => dispatch({ type: DCE_SET_CARD_QUERY_SORT_FIELD, sortFieldIndex, newSortField });
export const deleteCardQuerySortField = sortFieldIndex => (dispatch) => dispatch({ type: DCE_DELETE_CARD_QUERY_SORT_FIELD, sortFieldIndex });

// Card query relations actions
export const setCardQueryRelationsType = newCardQueryRelationsType => (dispatch) => dispatch({ type: DCE_SET_CARD_QUERY_RELATIONS_TYPE, newCardQueryRelationsType });
export const setCardQueryRelations = newCardQueryRelations => (dispatch) => dispatch({ type: DCE_SET_CARD_QUERY_RELATIONS, newCardQueryRelations });

// Card query combinations actions
export const setCardQueryCombinationsMainQuery = newCardQueryCombinationsMainQuery => (dispatch) => dispatch({ type: DCE_SET_CARD_QUERY_COMBINATIONS_MAIN_QUERY, newCardQueryCombinationsMainQuery });
export const setCardQueryCombinations = newCardQueryCombinations => (dispatch) => dispatch({ type: DCE_SET_CARD_QUERY_COMBINATIONS, newCardQueryCombinations });

// Calculated fields actions
export const setCalculatedFields = newCalculatedFields => (dispatch) => dispatch({ type: DCE_SET_CALCULATED_FIELDS, newCalculatedFields });
export const deleteCalculatedField = calculatedFieldIndex => (dispatch) => dispatch({ type: DCE_DELETE_CALCULATED_FIELD, calculatedFieldIndex });

// Card visualization actions
export const setCardVisualization = newCardVisualization => (dispatch) => dispatch({ type: DCE_SET_CARD_VISUALIZATION, newCardVisualization });
export const deleteCardVisualizationField = deleteFieldMetaData => (dispatch) => dispatch({ type: DCE_DELETE_CARD_VISUALIZATION_FIELD, deleteFieldMetaData });

// KPI Visualization actions
export const setKpiGroupItems = (kpiGroupIndex, newKpiGroupItems) => dispatch => dispatch({ type: DCE_SET_KPI_GROUP_ITEMS, kpiGroupIndex, newKpiGroupItems });

// VerticalTable Visualization actions
export const setTableColumns = newTableColumns => dispatch => dispatch({ type: DCE_SET_TABLE_COLUMNS, newTableColumns });
export const setInteractiveColumns = newInteractiveColumns => dispatch => dispatch({ type: DCE_SET_INTERACTIVE_COLUMNS, newInteractiveColumns });

// CrossTable Visualization actions
export const setCTRows = newRows => dispatch => dispatch({ type: DCE_SET_CT_ROWS, newRows });
export const setCTColumns = newColumns => dispatch => dispatch({ type: DCE_SET_CT_COLUMNS, newColumns });
export const setCTMetrics = newMetrics => dispatch => dispatch({ type: DCE_SET_CT_METRICS, newMetrics });

// Chart Visualization actions
export const setChartDimension = newDimension => dispatch => dispatch({ type: DCE_SET_CHART_DIMENSION, newDimension });
export const setChartBreakBy = newBreakBy => dispatch => dispatch({ type: DCE_SET_CHART_BREAK_BY, newBreakBy });
export const setChartMetrics = newMetrics => dispatch => dispatch({ type: DCE_SET_CHART_METRICS, newMetrics });
export const setChartRadius = newRadius => dispatch => dispatch({ type: DCE_SET_CHART_RADIUS, newRadius });
export const setChartHeatmap = newHeatmap => dispatch => dispatch({ type: DCE_SET_CHART_HEATMAP, newHeatmap });
export const setChartGraphConfig = newGraphConfig => dispatch => dispatch({ type: DCE_SET_CHART_GRAPH_CONFIG, newGraphConfig })

// Bullet Visualization actions
export const setThreshold = newThreshold => dispatch => dispatch({ type: DCE_SET_CHART_THRESHOLD, newThreshold });
export const setMinValueField = newMinValueField => dispatch => dispatch({ type: DCE_SET_CHART_MIN_VALUE_FIELD, newMinValueField });
export const setMaxValueField = newMaxValueField => dispatch => dispatch({ type: DCE_SET_CHART_MAX_VALUE_FIELD, newMaxValueField });

// Map visualization actions
export const setMapLayerMatchingField = (mapLayerIndex, newMatchingField) => dispatch => dispatch({ type: DCE_SET_MAP_LAYER_MATCHING_FIELD, mapLayerIndex, newMatchingField });
export const setMapLayerLatField = (mapLayerIndex, newLatField) => dispatch => dispatch({ type: DCE_SET_MAP_LAYER_LAT_FIELD, mapLayerIndex, newLatField });
export const setMapLayerLngField = (mapLayerIndex, newLngField) => dispatch => dispatch({ type: DCE_SET_MAP_LAYER_LNG_FIELD, mapLayerIndex, newLngField });
export const setMapLayerSymbolSize = (mapLayerIndex, newSymbolSize) => dispatch => dispatch({ type: DCE_SET_MAP_LAYER_SYMBOL_SIZE, mapLayerIndex, newSymbolSize });
export const setMapLayerColor = (mapLayerIndex, newColor) => dispatch => dispatch({ type: DCE_SET_MAP_LAYER_COLOR, mapLayerIndex, newColor });
export const setMapLayerTooltipFields = (mapLayerIndex, newTooltipFields) => dispatch => dispatch({ type: DCE_SET_MAP_LAYER_TOOLTIP_FIELDS, mapLayerIndex, newTooltipFields });
export const setMapLayerPopupFields = (mapLayerIndex, newPopupFields) => dispatch => dispatch({ type: DCE_SET_MAP_LAYER_POPUP_FIELDS, mapLayerIndex, newPopupFields });
export const setLayerIndex = newLayerIndex => (dispatch) => dispatch({ type: DCE_SET_LAYER_INDEX, newLayerIndex });

// Card visualization actions
export const setCardActions = newCardActions => (dispatch) => dispatch({ type: DCE_SET_CARD_ACTIONS, newCardActions });

// Card translations actions
export const setCardTranslations = newCardTranslations => (dispatch) => dispatch({ type: DCE_SET_CARD_TRANSLATIONS, newCardTranslations });

export function useDCEActions() {

    const dispatch = useDispatch();

    return {
        initializeDCE: () => dispatch(initializeDCE()),
        setCard: (card, forceChanges) => dispatch(setCard(card, forceChanges)),
        setSavedChanges: savedChanges => dispatch(setSavedChanges(savedChanges)),
        onChangeTab: selectedTab => dispatch(onChangeTab(selectedTab)),
        setSelectedPageId: newSelectedPageId => dispatch(setSelectedPageId(newSelectedPageId)),
        onChangeSelectedQueryIndex: selectedQueryIndex => dispatch(onChangeSelectedQueryIndex(selectedQueryIndex)),
        // Card queries
        addCardQuery: cardQuery => dispatch(addCardQuery(cardQuery)),
        deleteCardQuery: cardQueryIndex => dispatch(deleteCardQuery(cardQueryIndex)),
        setQueryDatamodel: datamodelId => dispatch(setQueryDatamodel(datamodelId)),
        setQueryFields: fields => dispatch(setQueryFields(fields)),
        setQuerySort: sort => dispatch(setQuerySort(sort)),
        setQueryLimit: limit => dispatch(setQueryLimit(limit)),
        setQueryShowEmptyRows: showEmptyRows => dispatch(setQueryShowEmptyRows(showEmptyRows)),
        setNoGroupBy: noGroupBy => dispatch(setNoGroupBy(noGroupBy)),
        // Card query fields
        setCardQueryField: (fieldIndex, newField) => dispatch(setCardQueryField(fieldIndex, newField)),
        deleteCardQueryField: fieldIndex => dispatch(deleteCardQueryField(fieldIndex)),
        // Card query sort fields
        addCardQuerySortField: newSortField => dispatch(addCardQuerySortField(newSortField)),
        setCardQuerySortField: (sortFieldIndex, newSortField) => dispatch(setCardQuerySortField(sortFieldIndex, newSortField)),
        deleteCardQuerySortField: sortFieldIndex => dispatch(deleteCardQuerySortField(sortFieldIndex)),
        // Card query relations
        setCardQueryRelationsType: newCardQueryRelationsType => dispatch(setCardQueryRelationsType(newCardQueryRelationsType)),
        setCardQueryRelations: newCardQueryRelations => dispatch(setCardQueryRelations(newCardQueryRelations)),
         // Card query combinations
         setCardQueryCombinationsMainQuery: newCardQueryCombinationsMainQuery => dispatch(setCardQueryCombinationsMainQuery(newCardQueryCombinationsMainQuery)),
         setCardQueryCombinations: newCardQueryCombinations => dispatch(setCardQueryCombinations(newCardQueryCombinations)),
        // Calculated fields
        setCalculatedFields: newCalculatedFields => dispatch(setCalculatedFields(newCalculatedFields)),
        deleteCalculatedField: calculatedFieldIndex => dispatch(deleteCalculatedField(calculatedFieldIndex)),
        // Card visualization
        setCardVisualization: newCardVisualization => dispatch(setCardVisualization(newCardVisualization)),
        deleteCardVisualizationField: deleteFieldMetaData => dispatch(deleteCardVisualizationField(deleteFieldMetaData)),
        // Card KPI visualization
        setKpiGroupItems: (kpiGroupIndex, newKpiGroupItems) => dispatch(setKpiGroupItems(kpiGroupIndex, newKpiGroupItems)),
        // Card VerticalTable visualization
        setTableColumns: newTableColumns => dispatch(setTableColumns(newTableColumns)),
        setInteractiveColumns: newInteractiveColumns => dispatch(setInteractiveColumns(newInteractiveColumns)),
        // Card CrossTable visualization
        setCTRows: newRows => dispatch(setCTRows(newRows)),
        setCTColumns: newColumns => dispatch(setCTColumns(newColumns)),
        setCTMetrics: newMetrics => dispatch(setCTMetrics(newMetrics)),
        // Card Chart visualization
        setChartDimension: newDimension => dispatch(setChartDimension(newDimension)),
        setChartBreakBy: newBreakBy => dispatch(setChartBreakBy(newBreakBy)),
        setChartMetrics: newMetrics => dispatch(setChartMetrics(newMetrics)),
        setChartRadius: newRadius => dispatch(setChartRadius(newRadius)),
        setChartHeatmap: newHeatmap => dispatch(setChartHeatmap(newHeatmap)),
        setChartGraphConfig: newGraphConfig => dispatch(setChartGraphConfig(newGraphConfig)),
        // Bullet Chart visualization
        setThreshold: newThreshold => dispatch(setThreshold(newThreshold)),
        setMinValueField: newMinValueField => dispatch(setMinValueField(newMinValueField)),
        setMaxValueField: newMaxValueField => dispatch(setMaxValueField(newMaxValueField)),
        // Card Map visualization
        setMapLayerMatchingField: (mapLayerIndex, newMatchingField) => dispatch(setMapLayerMatchingField(mapLayerIndex, newMatchingField)),
        setMapLayerLatField: (mapLayerIndex, newLatField) => dispatch(setMapLayerLatField(mapLayerIndex, newLatField)),
        setMapLayerLngField: (mapLayerIndex, newLngField) => dispatch(setMapLayerLngField(mapLayerIndex, newLngField)),
        setMapLayerSymbolSize: (mapLayerIndex, newSymbolSize) => dispatch(setMapLayerSymbolSize(mapLayerIndex, newSymbolSize)),
        setMapLayerColor: (mapLayerIndex, newColor) => dispatch(setMapLayerColor(mapLayerIndex, newColor)),
        setMapLayerTooltipFields: (mapLayerIndex, newTooltipFields) => dispatch(setMapLayerTooltipFields(mapLayerIndex, newTooltipFields)),
        setMapLayerPopupFields: (mapLayerIndex, newPopupFields) => dispatch(setMapLayerPopupFields(mapLayerIndex, newPopupFields)),
        setLayerIndex: newLayerIndex => dispatch(setLayerIndex(newLayerIndex)),
        // Card actions
        setCardActions: newCardActions => dispatch(setCardActions(newCardActions)),
        // Card translations
        setCardTranslations: newCardTranslations => dispatch(setCardTranslations(newCardTranslations))
    };

}