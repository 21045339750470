import {List, Map} from "immutable";
import {createSelector} from 'reselect';

// Orders redux state
const ordersStateSelector = state => state.billing.orders;

// Order reducer by redux context
const orderReducerSelector = createSelector(
    ordersStateSelector,
    (_, reduxContext) => reduxContext,
    (ordersState, reduxContext) => (ordersState && ordersState.get(`detail_${reduxContext}`)) || Map({})
);

// Orders reducer by redux context
const ordersReducerSelector = createSelector(
    ordersStateSelector,
    (_, reduxContext) => reduxContext,
    (ordersState, reduxContext) => (ordersState && ordersState.get(`list_${reduxContext}`)) || Map({})
);

// Order Detail
const orderSelector = createSelector(
    orderReducerSelector,
    orderReducer => orderReducer.get('payload') || Map({})
);

// Orders List
const ordersSelector = createSelector(
    ordersReducerSelector,
    ordersReducer => ordersReducer.get('payload') || List([])
);

const orderIsFetchingSelector = createSelector(
    orderReducerSelector,
    orderReducer => orderReducer.get('isFetching')
);

const ordersIsFetchingSelector = createSelector(
    ordersReducerSelector,
    ordersReducer => ordersReducer.get('isFetching')
);

const orderCreatedSelector = createSelector(
    orderReducerSelector,
    orderReducer => orderReducer.get('created')
);

const ordersCreatedSelector = createSelector(
    ordersReducerSelector,
    ordersReducer => ordersReducer.get('created')
);

const orderPaymentIntentSelector = createSelector(
    orderReducerSelector,
    orderReducer => orderReducer.get("paymentIntent")
)

const ordersPaymentIntentSelector = createSelector(
    ordersReducerSelector,
    ordersReducer => ordersReducer.get("paymentIntent")
)

const orderErrorSelector = createSelector(
    orderReducerSelector,
    orderReducer => orderReducer.get("error")
);

const ordersErrorSelector = createSelector(
    ordersReducerSelector,
    ordersReducer => ordersReducer.get("created")
);

const orderErrorMessageSelector = createSelector(
    orderReducerSelector,
    orderReducer => orderReducer.get("errorMessage")
)

const ordersErrorMessageSelector = createSelector(
    ordersReducerSelector,
    ordersReducer => ordersReducer.get("errorMessage")
)

export {
    orderSelector,
    ordersSelector,
    orderIsFetchingSelector,
    ordersIsFetchingSelector,
    orderCreatedSelector,
    ordersCreatedSelector,
    orderErrorSelector,
    ordersErrorSelector,
    orderErrorMessageSelector,
    ordersErrorMessageSelector,
    orderPaymentIntentSelector,
    ordersPaymentIntentSelector
};