import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { isObject, isEmpty, cloneDeep } from 'lodash';
// import { Grid } from 'react-virtualized';
import Grid from "react-virtualized/dist/commonjs/Grid"

import * as GridLib from "@biuwer/common/src/libs/grid-lib";

export default class  HtmlDataGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableData: []
        };

        this.getColumnWidth = this.getColumnWidth.bind(this);
        this.getRowHeight = this.getRowHeight.bind(this);
        this.getHeaderHeight = this.getHeaderHeight.bind(this);
        this.getMainGridHeight = this.getMainGridHeight.bind(this);
        this.getFooterHeight = this.getFooterHeight.bind(this);
        this.leftHeaderRenderer = this.leftHeaderRenderer.bind(this);
        this.mainHeaderRenderer = this.mainHeaderRenderer.bind(this);
        this.rightHeaderRenderer = this.rightHeaderRenderer.bind(this);
        this.leftGridRenderer = this.leftGridRenderer.bind(this);
        this.mainGridRenderer = this.mainGridRenderer.bind(this);
        this.rightGridRenderer = this.rightGridRenderer.bind(this);
        this.leftFooterRenderer = this.leftFooterRenderer.bind(this);
        this.mainFooterRenderer = this.mainFooterRenderer.bind(this);
        this.rightFooterRenderer = this.rightFooterRenderer.bind(this);

    }

    componentWillMount() {
        let initialTableData = [], mode;
        if (this.props.tableData && isObject(this.props.tableData) && !isEmpty(this.props.tableData)) {
            initialTableData = cloneDeep(this.props.tableData);
            mode = 'grid';
        }

        this.setState({
            tableData: initialTableData,
            mode: mode
        });
    }

    // Local function to obtain deep object value based on path ('field.subfield')
    deepValue(obj, path) {
        const deepPath = path.split('.'),
            deepPathLen = deepPath.length;
        for (let i = 0; i < deepPathLen; i++) {
            obj = obj[deepPath[i]];
        }
        return obj;
    }

    // Set grid refs to allow recomputeGridSize

    setLeftHeader(ref) {
        this.leftHeader = ref;
    }

    setMainHeader(ref) {
        this.mainHeader = ref;
    }

    setRightHeader(ref) {
        this.rightHeader = ref;
    }

    setLeftGrid(ref) {
        this.leftGrid = ref;
    }

    setMainGrid(ref) {
        this.mainGrid = ref;
    }

    setRightGrid(ref) {
        this.rightGrid = ref;
    }

    setLeftFooter(ref) {
        this.leftFooter = ref;
    }

    setMainFooter(ref) {
        this.mainFooter = ref;
    }

    setRightFooter(ref) {
        this.rightFooter = ref;
    }

    getColumnWidth({ index }, grid) {
        return GridLib.getColumnWidth({ index }, grid, this.props.tableSettings, this.props.tableData.leftGridData, this.props.tableData.mainGridData, this.props.tableData.rightGridData);
    }

    getRowHeight({ index }, grid) {
        return GridLib.getRowHeight({ index }, grid, this.props.tableSettings, this.props.tableData.mainHeaderData, this.props.tableData.mainGridData, this.props.tableData.mainFooterData);
    }

    // Total header height (different rows can have different heights)
    getHeaderHeight() {
        return GridLib.getHeaderHeight(this.props.tableData.mainHeaderData, this.props.tableSettings);
    }

    // Total main grid height (different rows can have different heights)
    getMainGridHeight() {
        return GridLib.getMainGridHeight(this.props.tableData.mainGridData, this.props.tableSettings);
    }

    // Total footer height (different rows can have different heights)
    getFooterHeight(){
        return GridLib.getFooterHeight(this.props.tableData.mainFooterData, this.props.tableSettings);
    }

    // Header Grids Renderers

    leftHeaderRenderer({ columnIndex, key, rowIndex, style }) {
        return GridLib.leftHeaderRenderer({ columnIndex, key, rowIndex, style }, this.props.tableData.leftHeaderData);
    }

    mainHeaderRenderer({ columnIndex, key, rowIndex, style }) {
        return GridLib.mainHeaderRenderer({ columnIndex, key, rowIndex, style }, this.props.tableData.mainHeaderData);
    }

    rightHeaderRenderer({ columnIndex, key, rowIndex, style }) {
        return GridLib.rightHeaderRenderer({ columnIndex, key, rowIndex, style }, this.props.tableData.rightHeaderData);
    }

    // Center Grids Renderers

    leftGridRenderer({ columnIndex, key, rowIndex, style }) {
        return GridLib.leftGridRenderer({ columnIndex, key, rowIndex, style }, this.props.tableData.leftGridData);
    }

    mainGridRenderer({ columnIndex, key, rowIndex, style }) {
        return GridLib.mainGridRenderer({ columnIndex, key, rowIndex, style }, this.props.tableData.mainGridData);
    }

    rightGridRenderer({ columnIndex, key, rowIndex, style }) {
        return GridLib.rightGridRenderer({ columnIndex, key, rowIndex, style }, this.props.tableData.rightGridData);
    }

    // Footer Grids Renderers

    leftFooterRenderer({ columnIndex, key, rowIndex, style }) {
        return GridLib.leftFooterRenderer({ columnIndex, key, rowIndex, style }, this.props.tableData.leftFooterData);
    }

    mainFooterRenderer({ columnIndex, key, rowIndex, style }) {
        return GridLib.mainFooterRenderer({ columnIndex, key, rowIndex, style }, this.props.tableData.mainFooterData);
    }

    rightFooterRenderer({ columnIndex, key, rowIndex, style }) {
        return GridLib.rightFooterRenderer({ columnIndex, key, rowIndex, style }, this.props.tableData.rightFooterData);
    }

    render() {
        let { classes, tableSettings } = this.props;
        let { leftHeaderData, mainHeaderData, rightHeaderData, leftGridData, mainGridData, rightGridData, leftFooterData, mainFooterData, rightFooterData } = this.props.tableData;
        let { mode } = this.state;

        // Grid sizes variables
        let leftWidth = 0, mainWidth = 0, rightWidth = 0,
            headerHeight, mainHeight, footerHeight;

        // Calculated grid sizes variables
        let calculatedMainWidth, calculatedHeaderWidth, calculatedFooterWidth,
            calculatedMainHeight, calculatedLeftHeight, calculatedRightHeight;

        // Default grid styles
        let leftHeaderStyle = {...tableSettings.leftHeaderStyle, fontSize: 12};
        let leftGridStyle = {...tableSettings.leftGridStyle, fontSize: 12};
        let leftFooterStyle = {...tableSettings.leftFooterStyle, fontSize: 12, fontWeight: 600};
        let mainHeaderStyle = {...tableSettings.mainHeaderStyle, fontSize: 12};
        let mainGridStyle = {...tableSettings.mainGridStyle, fontSize: 12};
        let mainFooterStyle = {...tableSettings.mainFooterStyle, fontSize: 12, fontWeight: 600};
        let rightHeaderStyle = {...tableSettings.rightHeaderStyle, fontSize: 12};
        let rightGridStyle = {...tableSettings.rightGridStyle, fontSize: 12};
        let rightFooterStyle = {...tableSettings.rightFooterStyle, fontSize: 12, fontWeight: 600};

        if (mode === 'grid') {

            // Left grid width
            if (leftGridData && leftGridData[0] && leftGridData[0].length > 0) {
                leftGridData[0].forEach((columnData, index) => {
                    leftWidth += this.getColumnWidth({ index }, 'leftGrid');
                });
            }

            // Main grid width
            if (mainGridData && mainGridData[0] && mainGridData[0].length > 0) {
                mainGridData[0].forEach((columnData, index) => {
                    mainWidth += this.getColumnWidth({ index }, 'mainGrid');
                });
            }

            // Right grid width
            if (rightGridData && rightGridData[0] && rightGridData[0].length > 0) {
                rightGridData[0].forEach((columnData, index) => {
                    rightWidth += this.getColumnWidth({ index }, 'rightGrid');
                });
            }

            headerHeight = tableSettings.showMainHeader ? this.getHeaderHeight() : 0;
            mainHeight = this.getMainGridHeight();
            footerHeight = tableSettings.showMainFooter ? this.getFooterHeight() : 0;

            if (!tableSettings.showLeftHeader && !tableSettings.showLeftGrid && !tableSettings.showLeftFooter) {
                leftWidth = 0;
            }

            if (!tableSettings.showRightHeader && !tableSettings.showRightGrid && !tableSettings.showRightFooter) {
                rightWidth = 0;
            }

            // No scroll bars
            calculatedMainWidth = mainWidth;
            calculatedHeaderWidth = mainWidth;
            calculatedFooterWidth = mainWidth;
            calculatedMainHeight = mainHeight;
            calculatedLeftHeight = mainHeight;
            calculatedRightHeight = mainHeight;

            rightFooterStyle.marginTop = 10;
            leftFooterStyle.marginTop = 10;
        }

        let bodyContent;
        switch(mode) {
            case 'grid':
                bodyContent = (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div key="leftGrids" className="pull-left">
                            { tableSettings.showLeftHeader ? (
                                <Grid
                                    ref={(ref) => this.setLeftHeader(ref)}
                                    cellRenderer={this.leftHeaderRenderer}
                                    columnWidth={(data) => this.getColumnWidth(data, 'leftGrid')}
                                    columnCount={leftHeaderData[0] ? leftHeaderData[0].length : 0}
                                    rowHeight={(data) => this.getRowHeight(data, 'header')}
                                    rowCount={leftHeaderData.length}
                                    height={headerHeight}
                                    width={leftWidth}
                                    style={leftHeaderStyle}
                                />
                            ) : null }
                            { tableSettings.showLeftGrid ? (
                                <Grid
                                    ref={(ref) => this.setLeftGrid(ref)}
                                    cellRenderer={this.leftGridRenderer}
                                    columnWidth={(data) => this.getColumnWidth(data, 'leftGrid')}
                                    columnCount={leftGridData[0] ? leftGridData[0].length : 0}
                                    rowHeight={(data) => this.getRowHeight(data, 'mainGrid')}
                                    rowCount={leftGridData.length}
                                    overscanRowCount={tableSettings.overscanRowCount}
                                    height={calculatedLeftHeight}
                                    width={leftWidth}
                                    style={leftGridStyle}
                                />
                            ) : null }
                            { tableSettings.showLeftFooter ? (
                                <Grid
                                    ref={(ref) => this.setLeftFooter(ref)}
                                    cellRenderer={this.leftFooterRenderer}
                                    columnWidth={(data) => this.getColumnWidth(data, 'leftGrid')}
                                    columnCount={leftFooterData[0] ? leftFooterData[0].length : 0}
                                    rowHeight={(data) => this.getRowHeight(data, 'footer')}
                                    rowCount={leftFooterData.length}
                                    height={footerHeight}
                                    width={leftWidth}
                                    style={leftFooterStyle}
                                />
                            ) : null }
                        </div>
                        <div key="mainGrids" className="pull-left">
                            { tableSettings.showMainHeader ? (
                                <Grid
                                    ref={(ref) => this.setMainHeader(ref)}
                                    cellRenderer={this.mainHeaderRenderer}
                                    columnWidth={(data) => this.getColumnWidth(data, 'mainGrid')}
                                    columnCount={mainHeaderData[0] ? mainHeaderData[0].length : 0}
                                    overscanColumnCount={tableSettings.overscanColumnCount}
                                    rowHeight={(data) => this.getRowHeight(data, 'header')}
                                    rowCount={mainHeaderData.length}
                                    height={headerHeight}
                                    width={calculatedHeaderWidth}
                                    style={mainHeaderStyle}
                                />
                            ) : null }
                            <Grid
                                ref={(ref) => this.setMainGrid(ref)}
                                cellRenderer={this.mainGridRenderer}
                                columnWidth={(data) => this.getColumnWidth(data, 'mainGrid')}
                                columnCount={mainGridData[0] ? mainGridData[0].length : 0}
                                overscanColumnCount={tableSettings.overscanColumnCount}
                                rowHeight={(data) => this.getRowHeight(data, 'mainGrid')}
                                rowCount={mainGridData.length}
                                overscanRowCount={tableSettings.overscanRowCount}
                                height={calculatedMainHeight}
                                width={calculatedMainWidth}
                                style={mainGridStyle}
                            />
                            { tableSettings.showMainFooter ? (
                                <Grid
                                    ref={(ref) => this.setMainFooter(ref)}
                                    cellRenderer={this.mainFooterRenderer}
                                    columnWidth={(data) => this.getColumnWidth(data, 'mainGrid')}
                                    columnCount={mainFooterData[0] ? mainFooterData[0].length : 0}
                                    overscanColumnCount={tableSettings.overscanColumnCount}
                                    rowHeight={(data) => this.getRowHeight(data, 'footer')}
                                    rowCount={mainFooterData.length}
                                    height={footerHeight}
                                    width={calculatedFooterWidth}
                                    style={mainFooterStyle}
                                />
                            ) : null }
                        </div>
                        <div key="rightGrids" className="pull-left">
                            { tableSettings.showRightHeader ? (
                                <Grid
                                    ref={(ref) => this.setRightHeader(ref)}
                                    cellRenderer={this.rightHeaderRenderer}
                                    columnWidth={(data) => this.getColumnWidth(data, 'rightGrid')}
                                    columnCount={rightHeaderData[0] ? rightHeaderData[0].length : 0}
                                    rowHeight={(data) => this.getRowHeight(data, 'header')}
                                    rowCount={rightHeaderData.length}
                                    height={headerHeight}
                                    width={rightWidth}
                                    style={rightHeaderStyle}
                                />
                            ) : null }
                            { tableSettings.showRightGrid ? (
                                <Grid
                                    ref={(ref) => this.setRightGrid(ref)}
                                    cellRenderer={this.rightGridRenderer}
                                    columnWidth={(data) => this.getColumnWidth(data, 'rightGrid')}
                                    columnCount={rightGridData[0] ? rightGridData[0].length : 0}
                                    rowHeight={(data) => this.getRowHeight(data, 'mainGrid')}
                                    rowCount={rightGridData.length}
                                    overscanRowCount={tableSettings.overscanRowCount}
                                    height={calculatedRightHeight}
                                    width={rightWidth}
                                    style={rightGridStyle}
                                />
                            ) : null }
                            { tableSettings.showRightFooter ? (
                                <Grid
                                    ref={(ref) => this.setRightFooter(ref)}
                                    cellRenderer={this.rightFooterRenderer}
                                    columnWidth={(data) => this.getColumnWidth(data, 'rightGrid')}
                                    columnCount={rightFooterData[0] ? rightFooterData[0].length : 0}
                                    rowHeight={(data) => this.getRowHeight(data, 'footer')}
                                    rowCount={rightFooterData.length}
                                    height={footerHeight}
                                    width={rightWidth}
                                    style={rightFooterStyle}
                                />
                            ) : null }
                        </div>
                    </div>
                );
                break;
            default:
                bodyContent = null;
                break;
        }

        return (
            <div className={classes} style={{marginTop: 10, marginButton: 10}}>
                {bodyContent}
            </div>
        );
    }
}

HtmlDataGrid.propTypes = {
    classes: PropTypes.string,
    tableSettings: PropTypes.shape({
        showHeader: PropTypes.bool,
        showFooter: PropTypes.bool,
        defaultSortField: PropTypes.string,
        headerStyle: PropTypes.object,
        bodyStyle: PropTypes.object,
        footerStyle: PropTypes.object
    }).isRequired,
    tableData: PropTypes.shape({
        leftHeaderData: PropTypes.array,
        leftGridData: PropTypes.array,
        leftFooterData: PropTypes.array,
        mainHeaderData: PropTypes.array,
        mainGridData: PropTypes.array,
        mainFooterData: PropTypes.array,
        rightHeaderData: PropTypes.array,
        rightGridData: PropTypes.array,
        rightFooterData: PropTypes.array
    }).isRequired
};
