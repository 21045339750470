export const FILTERS_DETAIL = `
    _id
    name
    filter_type
    expression
    default_expression
    data_type
    stage
    use_custom_list
    custom_list
    visible
    editable
    mandatory
    multi_value
    order {
        type
        custom_order
        order_field {
            data_field {
                _id
                name
                alias
                data_type
                field_type
            }
            aggregation
            type
        }
        order_aggregation
    }
    url_param {
        enabled
        param_field
    }
    affected_filters
    affected_filters_auto_apply
    time_format
    time_level
    time_metric
    cards {
        _id
        query
        datamodel
        dataset
        data_field
    }
    queries {
        query
        datamodel
        dataset
        data_field
    }
    datamodel {
        _id
    }
    dataset {
        _id
    }
    data_field {
        _id
        name
        alias
        aggregation
        field_type
        data_type
        translations_enabled
        translations_type
        translated_by_fields {
            language
            field
        }
    }
    filter_use {
        selection_tab {
            show
            date_level
        }
        expression_tab {
            show
            date_level
            operations_allowed
        }
        predefined_tab {
            show
            date_level
        }
        top_bottom_tab {
            show
        }
    }
    i18n {
        language
        field
        value
    }
`;

export const FILTERS_LIST_BASIC = `
    _id
    name
    filter_type
`;

export const DATAFILTERS = `
    object_type
    object_id
    filter_id
    expression
`;
