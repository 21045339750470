export const DATASETS_LIST_BASIC = `
    _id
    name
    alias
    status
    container
    fields {
        _id
        name
        desc
        alias
        hidden
        editable
        field_type
        aggregation
        data_type
        original_data_type
        calculated_field
        field_group
        primary_key
        calculated_expression
    }
    owner {
        _id
        name
        email
        full_name
    }
    profile_level
    profiles {
        own {
            _id
            name
            full_name
            email
        }
        admin
        edit
        view
        inherited
        inherited_from
    }
    dataset_view
`;

export const DATASETS_LIST_DETAIL = `
    _id
    name
    alias
    status
    fields {
        _id
        name
        desc
        alias
        hidden
        editable
        field_type
        aggregation
        data_type
        original_data_type
        calculated_field
        field_group
        primary_key
    }
    container
    data_connection {
        _id
        name
    }
    statistics {
        cards
        datamodels
    }
    owner {
        _id
        name
        email
        full_name
    }
    profile_level
    profiles {
        own {
            _id
            name
            full_name
            email
        }
        admin
        edit
        view
        inherited
        inherited_from
    }
    managed
    editable
    dataset_view
    dataset_view_query
`;

export const DATASETS_DETAIL = `
    _id
    name
    alias
    desc
    status
    fields {
        _id
        name
        desc
        alias
        hidden
        editable
        field_type
        aggregation
        data_type
        original_data_type
        calculated_field
        field_group
        primary_key
    }
    container
    data_connection {
        _id
        name
        details {
            type
        }
    }
    owner {
        _id
        name
        email
        full_name
    }
    profile_level
    profiles {
        own {
            _id
            name
            full_name
            email
        }
        admin
        edit
        view
        inherited
        inherited_from
    }
    dataset_view
    dataset_view_query
`;

export const DATASET_REVERSE_ENGINEERING_LIST = `
    _id
    name
    alias
    desc
    container
    fields {
        _id
        name
        desc
        alias
        hidden
        editable
        field_type
        aggregation
        data_type
        original_data_type
        calculated_field
        calculated_expression
        field_group
        primary_key
    }
    status
    data_connection {
        _id
    }
`;

export const DATASETS_LIST = `
    _id
    name
    alias
    container
    data_connection {
        _id
        name
    }
    data_policies_enabled
    fields {
        _id
        name
        desc
        alias
        hidden
        editable
        field_type
        aggregation
        data_type
        original_data_type
        calculated_field
        field_group
        primary_key
    }
    statistics {
        cards
        datamodels
    }
    owner {
        _id
        name
        email
        full_name
    }
    profile_level
    profiles {
        own {
            _id
            name
            full_name
            email
        }
        admin
        edit
        view
        inherited
        inherited_from
    }
    managed
    updated
    editable
    dataset_view
`;

export const DATASET_DETAIL_PAGE = `
    _id
    name
    alias
    desc
    data_connection {
        _id
        name
        details {
            type
        }
        category
        time_zone {
            enabled
            value
        }
        data_connection_type {
            name
            logo
            required_plan
        }
    }
    container
    fields {
        _id
        name
        desc
        alias
        hidden
        editable
        field_type
        aggregation
        data_type
        original_data_type
        calculated_field
        calculated_expression
        field_group
        primary_key
        translations_enabled
        translations_type
        translated_by_fields {
            field
            language
        }
    }
    data_policies_enabled
    data_policies_mode
    data_policies {
        _id
        name
        users
        groups
        apply_always
        status
        filters {
            _id
            data_field
            data_type
            expression
        }
    }
    statistics {
        cards
        datamodels
    }
    owner {
        _id
        name
        email
        full_name
    }
    profile_level
    profiles {
        own {
            _id
            name
            full_name
            email
        }
        admin
        edit
        view
        inherited
        inherited_from
    }
    managed
    created
    updated
    editable
    dataset_view
    dataset_view_query
    user_created {
        _id
        full_name
    }
    user_updated {
        _id
        full_name
    }
`;

export const DATASET_DIALOG = `
    _id
    name
    alias
    desc
    container
    data_connection {
        _id
        name
        details {
            needs_container
        }
    }
    managed
    managed_data_connection {
        _id
        name
    }
    dataset_view
`;

export const DATASETS_LIST_FILTERS_EDITOR_DIALOG = `
    _id
    alias
    fields {
        _id
        name
        desc
        alias
        hidden
        editable
        field_type
        aggregation
        data_type
        original_data_type
        calculated_field
        calculated_expression
        field_group
        primary_key
    }
`;

export const DATASET_VIEW_CODE_TEST = `
    data
    metadata
`;

export const MANAGED_DATASET_EXECUTIONS = `
    last_execution {
        _id
        status
        start_date
        end_date
    }
    next_execution {
        _id
        status
        start_date
        end_date
    }
`;
