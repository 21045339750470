import { combineReducers } from 'redux';

// Biuwer System reducers
import auth from "./auth/auth-reducers";
import geography from "./geography/geography-reducer";
import groups from "./groups/groups-reducer";
import menus from "./menus/menus-reducer";
import organizations from "./organizations/organizations-reducer";
import roles from "./roles/roles-reducer";
import users from "./users/users-reducer";
import pages from "./pages/pages-reducers";
import spaces from "./spaces/spaces-reducers";
import pagesStructures from "./page-structures/page-structures-reducers";
import cards from "./cards/cards-reducers";
import security from "./security/security-reducer";
import oauth from "./oauth/oauth-reducers";
import customFields from "./custom-fields/custom-fields-reducer";
import systemVariables from "./system-variables/system-variables-reducers";
import notificationArea from "./notification-area/notification-area-reducer";
import notifications from "./notifications/notifications-reducer";
import collections from "./collections/collections-reducers";
import filtersEditorDialog from "./filters-editor-dialog/filters-editor-dialog-reducers";
import templates from './templates/templates-reducers';
// import dndContext from "./dnd-system/dnd-system-reducers";
import dataCardEditor from './data-card-editor/data-card-editor-reducers';
import apiKeys from './api-keys/api-keys-reducer';
import themes from "./themes/themes-reducers";
import colorPalettes from "./color-palettes/color-palettes-reducers";
import customMessages from "./organization-custom-messages/custom-messages-reducers";

export default combineReducers({
    auth,
    geography,
    groups,
    menus,
    organizations,
    roles,
    users,
    pages,
    spaces,
    pagesStructures,
    cards,
    security,
    oauth,
    customFields,
    systemVariables,
    notificationArea,
    notifications,
    collections,
    filtersEditorDialog,
    templates,
    // dndContext,
    dataCardEditor,
    apiKeys,
    themes,
    colorPalettes,
    customMessages
});