// Libs
import gqlRequest from "@biuwer/core/src/graphql-request";
import i18n from "@biuwer/core/src/i18n";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import { defaultContext } from "@biuwer/redux/src/config/constants";
import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

export const DATASET_FIELD_TRANSLATIONS_DETAIL_INITIALIZE = 'DATASET_FIELD_TRANSLATIONS_DETAIL_INITIALIZE';

export const DATASET_FIELD_TRANSLATIONS_FETCH_REQUEST = 'DATASET_FIELD_TRANSLATIONS_FETCH_REQUEST';
export const DATASET_FIELD_TRANSLATIONS_FETCH_SUCCESS = 'DATASET_FIELD_TRANSLATIONS_FETCH_SUCCESS';
export const DATASET_FIELD_TRANSLATIONS_FETCH_ERROR = 'DATASET_FIELD_TRANSLATIONS_FETCH_ERROR';

export const DATASET_FIELD_TRANSLATIONS_CREATE_REQUEST = 'DATASET_FIELD_TRANSLATIONS_CREATE_REQUEST';
export const DATASET_FIELD_TRANSLATIONS_CREATE_SUCCESS = 'DATASET_FIELD_TRANSLATIONS_CREATE_SUCCESS';
export const DATASET_FIELD_TRANSLATIONS_CREATE_ERROR = 'DATASET_FIELD_TRANSLATIONS_CREATE_ERROR';

export const DATASET_FIELD_TRANSLATIONS_UPDATE_REQUEST = 'DATASET_FIELD_TRANSLATIONS_UPDATE_REQUEST';
export const DATASET_FIELD_TRANSLATIONS_UPDATE_SUCCESS = 'DATASET_FIELD_TRANSLATIONS_UPDATE_SUCCESS';
export const DATASET_FIELD_TRANSLATIONS_UPDATE_ERROR = 'DATASET_FIELD_TRANSLATIONS_UPDATE_ERROR';

const DATASET_FIELD_GQL = `
    _id
    original_values
    translations
`;

const DATASET_FIELD_DETAIL_GQL = `
    _id
    field
    original_values
    translations
`;

// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, showNotification = false) => {
    let skeleton = { type, context }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.statusCode,
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body
            };

            if(body.status === 2) {
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:
            break;
    }

    switch (type) {
        case DATASET_FIELD_TRANSLATIONS_CREATE_SUCCESS:
        case DATASET_FIELD_TRANSLATIONS_UPDATE_SUCCESS:
            skeleton.updated = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('datasets.datasetLabel'), context: 'male', count: 1 })
            };
            break;
        case DATASET_FIELD_TRANSLATIONS_FETCH_ERROR:
            skeleton.issuePayload = {
                ...skeleton.issuePayload
            };
            break;
        case DATASET_FIELD_TRANSLATIONS_CREATE_ERROR:
        case DATASET_FIELD_TRANSLATIONS_UPDATE_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            }
            break;
        default:
            break;
    }

    return (dispatch) => {
        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    }
};

/**
 * Initialize dataset field translations
 */
export const initializeDatasetFieldTanslationDetail = (context = defaultContext) => ({
    type: DATASET_FIELD_TRANSLATIONS_DETAIL_INITIALIZE,
    context
});

export const getDatasetFieldTranslation = (dataset, field, context = defaultContext, gql = DATASET_FIELD_GQL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(DATASET_FIELD_TRANSLATIONS_FETCH_REQUEST, null, context));

        try {
            const datasetFieldTranslations = await gqlRequest({
                queryName: "readDatasetFieldTranslation",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "dataset",
                    data: dataset
                }, {
                    type: "String!",
                    name: "field",
                    data: field
                }]
            });

            dispatch(generateSkeleton(DATASET_FIELD_TRANSLATIONS_FETCH_SUCCESS, datasetFieldTranslations, context));
        } catch (err) {
            dispatch(generateSkeleton(DATASET_FIELD_TRANSLATIONS_FETCH_ERROR, err, context))
        }
    }
};

export const createDatasetFieldTranslation = (datasetFieldTranslation, context = defaultContext, gql = DATASET_FIELD_DETAIL_GQL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(DATASET_FIELD_TRANSLATIONS_CREATE_REQUEST, null, context));

        try {
            const newDatasetFieldTranslation = await gqlRequest({
                queryType: "mutation",
                queryName: "createDatasetFieldTranslation",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "newQueryDatasetFieldTranslationsInput!",
                    name: "datasetFieldTranslation",
                    data: datasetFieldTranslation
                }]
            });

            dispatch(generateSkeleton(DATASET_FIELD_TRANSLATIONS_CREATE_SUCCESS, newDatasetFieldTranslation, context));
        } catch (err) {
            dispatch(generateSkeleton(DATASET_FIELD_TRANSLATIONS_CREATE_ERROR, err, context, false));
        }
    }
};

export const updateDatasetFieldTranslation = (datasetFieldTranslation, context = defaultContext, gql = DATASET_FIELD_DETAIL_GQL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(DATASET_FIELD_TRANSLATIONS_UPDATE_REQUEST, null, context));

        try {
            const updatedDatasetFieldTranslation = await gqlRequest({
                queryType: "mutation",
                queryName: "updateDatasetFieldTranslation",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "QueryDatasetFieldTranslationsInput!",
                    name: "datasetFieldTranslation",
                    data: datasetFieldTranslation
                }]
            });

            dispatch(generateSkeleton(DATASET_FIELD_TRANSLATIONS_UPDATE_SUCCESS, updatedDatasetFieldTranslation, context));
        } catch (err) {
            dispatch(generateSkeleton(DATASET_FIELD_TRANSLATIONS_UPDATE_ERROR, err, context, false));
        }
    }
};
