import {createSelector} from 'reselect';

// Oauth redux state
const oauthStateSelector = state => state.system.oauth;

// Oauth reducer
const oauthReducerSelector = oauthStateSelector;

// Oauth
const oauthSelector = createSelector(
    oauthReducerSelector,
    oauthReducer => oauthReducer.get('oauth')
);

// Oauth issue
const oauthIssueSelector = createSelector(
    oauthReducerSelector,
    oauthReducer => oauthReducer.get('createIssuePayload')
);

// Oauth url
const oauthUrlSelector = createSelector(
    oauthReducerSelector,
    oauthReducer => oauthReducer.get('url')
);

// Oauth hash
const oauthHashSelector = createSelector(
    oauthReducerSelector,
    oauthReducer => oauthReducer.get('oauthHash')
);

export {
    oauthSelector,
    oauthIssueSelector,
    oauthUrlSelector,
    oauthHashSelector
};