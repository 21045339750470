import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import  History from "@biuwer/core/src/history";
import i18n from "@biuwer/core/src/i18n";
import { defaultContext } from "@biuwer/redux/src/config/constants";

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { JOBS_LIST_PAGE, JOB_DETAIL_PAGE } from './jobs-gql';
import { JOB_EXECUTION } from '../executions/executions-gql';

/*
 * Jobs action types
 */

export const JOB_INITIALIZE = 'JOB_INITIALIZE';
export const JOB_DETAIL_INITIALIZE = 'JOB_DETAIL_INITIALIZE';
export const JOBS_LIST_INITIALIZE = 'JOBS_LIST_INITIALIZE';

export const JOBS_FETCH_REQUEST = 'JOBS_FETCH_REQUEST';
export const JOBS_FETCH_SUCCESS = 'JOBS_FETCH_SUCCESS';
export const JOBS_FETCH_ERROR = 'JOBS_FETCH_ERROR';

export const JOB_FETCH_REQUEST = 'JOB_FETCH_REQUEST';
export const JOB_FETCH_SUCCESS = 'JOB_FETCH_SUCCESS';
export const JOB_FETCH_ERROR = 'JOB_FETCH_ERROR';

export const JOB_CREATE_REQUEST = 'JOB_CREATE_REQUEST';
export const JOB_CREATE_SUCCESS = 'JOB_CREATE_SUCCESS';
export const JOB_CREATE_ERROR = 'JOB_CREATE_ERROR';

export const JOB_UPDATE_REQUEST = 'JOB_UPDATE_REQUEST';
export const JOB_UPDATE_SUCCESS = 'JOB_UPDATE_SUCCESS';
export const JOB_UPDATE_ERROR = 'JOB_UPDATE_ERROR';

export const JOB_DELETE_REQUEST = 'JOB_DELETE_REQUEST';
export const JOB_DELETE_SUCCESS = 'JOB_DELETE_SUCCESS';
export const JOB_DELETE_ERROR = 'JOB_DELETE_ERROR';

export const JOB_DUPLICATE_REQUEST = 'JOB_DUPLICATE_REQUEST';
export const JOB_DUPLICATE_SUCCESS = 'JOB_DUPLICATE_SUCCESS';
export const JOB_DUPLICATE_ERROR = 'JOB_DUPLICATE_ERROR';

export const JOB_EXECUTE_REQUEST = 'JOB_EXECUTE_REQUEST';
export const JOB_EXECUTE_SUCCESS = 'JOB_EXECUTE_SUCCESS';
export const JOB_EXECUTE_ERROR = 'JOB_EXECUTE_ERROR';

export const SCHEDULE_CREATE_REQUEST = 'SCHEDULE_CREATE_REQUEST';
export const SCHEDULE_CREATE_SUCCESS = 'SCHEDULE_CREATE_SUCCESS';
export const SCHEDULE_CREATE_ERROR = 'SCHEDULE_CREATE_ERROR';

export const SCHEDULE_UPDATE_REQUEST = 'SCHEDULE_UPDATE_REQUEST';
export const SCHEDULE_UPDATE_SUCCESS = 'SCHEDULE_UPDATE_SUCCESS';
export const SCHEDULE_UPDATE_ERROR = 'SCHEDULE_UPDATE_ERROR';

export const SCHEDULE_DELETE_REQUEST = 'SCHEDULE_DELETE_REQUEST';
export const SCHEDULE_DELETE_SUCCESS = 'SCHEDULE_DELETE_SUCCESS';
export const SCHEDULE_DELETE_ERROR = 'SCHEDULE_DELETE_ERROR';


// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, showNotification = true, organization) => {
    let skeleton = { type, context }, notification;
    const history = History.getHistory();

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)){
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.code,
                    code: body.statusCode,
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body.payload
            };

            if (type === 'JOB_UPDATE_SUCCESS') {
                skeleton.updated = true;
            }

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.code,
                    code: body.statusCode,
                    message: body.message
                }
            }

            break;
        default:

            break;
    }

    switch(type){
        case JOB_UPDATE_SUCCESS:
            skeleton.payload = body;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('dataPrep.jobs.jobLabel'), context: 'male', count: 1 })
            };
            break;
        case JOB_CREATE_SUCCESS:
            skeleton.payload = body;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', { name: i18n.t('dataPrep.jobs.jobLabel'), context: 'male', count: 1 })
            };
            break;
        case JOB_DELETE_SUCCESS:
            skeleton.payload = body;
            skeleton.deleted = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', { name: i18n.t('dataPrep.jobs.jobLabel'), context: 'male', count: 1 })
            };
            break;
        case JOB_DUPLICATE_SUCCESS:
            skeleton.payload = body;
            skeleton.duplicated = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.duplicateSuccess', { name: i18n.t('dataPrep.jobs.jobLabel'), context: 'male', count: 1 })
            };
            break;
        case JOB_EXECUTE_SUCCESS:
            skeleton.payload = body;
            skeleton.executed = true;
            notification = {
                styleType: 'success',
                message: i18n.t('dataPrep.jobs.executionSuccess'),
                action: {
                    onClick: () => history.push(`/${organization === 1 ? "instance-admin" : "data-center"}/executions/${body._id}`),
                    text: i18n.t('dataPrep.executions.seeExecutionDetails')
                }
            };
            break;
        case SCHEDULE_CREATE_SUCCESS:
            skeleton.payload = body;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', { name: i18n.t('dataPrep.schedules.scheduleLabel'), context: 'female', count: 1 })
            };
            break;
        case SCHEDULE_UPDATE_SUCCESS:
            skeleton.payload = body;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('dataPrep.schedules.scheduleLabel'), context: 'female', count: 1 })
            };
            break;
        case SCHEDULE_DELETE_SUCCESS:
            skeleton.payload = body;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', { name: i18n.t('dataPrep.schedules.scheduleLabel'), context: 'female', count: 1 })
            };
            break;
        case JOB_FETCH_SUCCESS:
        case JOBS_FETCH_SUCCESS:
            skeleton.payload = body;
            break;
        case JOB_CREATE_ERROR:
        case JOB_UPDATE_ERROR:
        case JOB_DELETE_ERROR:
        case JOB_DUPLICATE_ERROR:
        case SCHEDULE_CREATE_ERROR:
        case SCHEDULE_UPDATE_ERROR:
        case SCHEDULE_DELETE_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    }
};

/**
 * Get Jobs action
 */

export function getJobs(query = {}, context = defaultContext, gql = JOBS_LIST_PAGE) {

    return async (dispatch) => {
        try {
            const gqlQuery = {
                query: `query($query: QueryJobsInput!) {
                    queryJobs(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query: query
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(JOBS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(JOBS_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.queryJobs) {
                    dispatch(generateSkeleton(JOBS_FETCH_SUCCESS, res.body.data.queryJobs, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(JOBS_FETCH_ERROR, err, context));
        }
    };
}

/**
 * Get Job action
 */

export function getJob(jobId, context = defaultContext, gql = JOB_DETAIL_PAGE) {

    return async (dispatch) => {

        try {

            const gqlQuery = {
                query: `query($_id: Float!) {
                    readJob(_id: $_id) {
                        ${gql}
                    }
                }`,
                variables: {
                    _id: jobId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(JOB_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(JOB_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readJob) {
                    dispatch(generateSkeleton(JOB_FETCH_SUCCESS, res.body.data.readJob, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(JOB_FETCH_ERROR, err, context));
        }
    };
}

/**
 * Initialize Job
 */
export const initializeJob = (context = defaultContext) => {
    return {
        type: JOB_INITIALIZE,
        context: context
    };
};

/**
 * Initialize Jobs List
 */
export const initializeJobsList = (context = defaultContext) => {
    return {
        type: JOBS_LIST_INITIALIZE,
        context: context
    };
};

/**
 * Initialize Job Detail
 */
export const initializeJobDetail = (context = defaultContext) => {
    return {
        type: JOB_DETAIL_INITIALIZE,
        context: context
    };
};

/**
 * Create job action
 * @param job
 * @param context
 * @param gql
 * @param organization
 */
export function createJob(job, context = defaultContext, gql = JOBS_LIST_PAGE, organization) {

    return async (dispatch) => {

        try {

            const gqlQuery = {
                query: `mutation($job: JobInput!, $organization: Float) {
                    createJob(job: $job, organization: $organization) {
                        ${gql}
                    }
                }`,
                variables: {
                    job: job,
                    organization: organization
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(JOB_CREATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(JOB_CREATE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.createJob) {
                    dispatch(generateSkeleton(JOB_CREATE_SUCCESS, res.body.data.createJob, context, true));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(JOB_CREATE_ERROR, err, context, true));
        }
    };
}

/**
 * Update job action
 * @param job
 * @param context
 * @param gql
 * @param organization
 */
export function updateJob(job, context = defaultContext, gql = JOBS_LIST_PAGE, organization) {

    return async (dispatch) => {
        try {

            const gqlQuery = {
                query: `mutation($_id: Float!, $job: JobInput!, $organization: Float) {
                    updateJob(_id: $_id, job: $job, organization: $organization) {
                        ${gql}
                    }
                }`,
                variables: {
                    _id: job._id,
                    job: job,
                    organization: organization
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(JOB_UPDATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));


            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(JOB_UPDATE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.updateJob) {
                    dispatch(generateSkeleton(JOB_UPDATE_SUCCESS, res.body.data.updateJob, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(JOB_UPDATE_ERROR, err, context));
        }
    };
}

/**
 * Delete job action
 * @param jobId
 * @param context
 * @param gql
 * @param organization
 */
export function deleteJob(jobId, context = defaultContext, gql = JOBS_LIST_PAGE, organization) {

    return async (dispatch) => {
        try {

            const gqlQuery = {
                query: `mutation($_id: Float!, $organization: Float) {
                    deleteJob(_id: $_id, organization: $organization) {
                        ${gql}
                    }
                }`,
                variables: {
                    _id: jobId,
                    organization: organization
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(JOB_DELETE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));


            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(JOB_DELETE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.deleteJob) {
                    dispatch(generateSkeleton(JOB_DELETE_SUCCESS, res.body.data.deleteJob, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(JOB_DELETE_ERROR, err, context));
        }
    };
}

/**
 * Duplicate job action
 * @param jobId
 * @param context
 * @param gql
 * @param organization
 */
export function duplicateJob(jobId, context = defaultContext, gql = JOBS_LIST_PAGE, organization) {

    return async (dispatch) => {
        try {

            const gqlQuery = {
                query: `mutation($_id: Float!, $organization: Float) {
                    duplicateJob(_id: $_id, organization: $organization) {
                        ${gql}
                    }
                }`,
                variables: {
                    _id: jobId,
                    organization: organization
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(JOB_DUPLICATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));


            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(JOB_DUPLICATE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.duplicateJob) {
                    dispatch(generateSkeleton(JOB_DUPLICATE_SUCCESS, res.body.data.duplicateJob, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(JOB_DUPLICATE_ERROR, err, context));
        }
    };
}


/**
 * Execute Job action
 */

export function executeJob(jobId, context = defaultContext, gql = JOB_EXECUTION, organization) {

    return async (dispatch) => {
        try {
            const gqlQuery = {
                query: `query($job: Float!, $organization: Float) {
                    executeJob(job: $job, organization: $organization) {
                        ${gql}
                    }
                }`,
                variables: {
                    job: jobId,
                    organization: organization
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(JOB_EXECUTE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(JOB_EXECUTE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.executeJob) {
                    dispatch(generateSkeleton(JOB_EXECUTE_SUCCESS, res.body.data.executeJob, context, true, organization));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(JOB_EXECUTE_ERROR, err, context));
        }
    };
}

/**
 * Create Schedule action
 * @param jobId
 * @param schedule
 * @param context
 * @param gql
 * @param organization
 */

export function createSchedule(jobId, schedule, context = defaultContext, gql = JOB_DETAIL_PAGE, organization) {

    return async (dispatch) => {
        try {
            const gqlQuery = {
                query: `mutation($job: Float!, $schedule: JobScheduleInput!, $organization: Float) {
                    createSchedule(job: $job, schedule: $schedule, organization: $organization) {
                        ${gql}
                    }
                }`,
                variables: {
                    job: jobId,
                    schedule: schedule,
                    organization: organization
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(SCHEDULE_CREATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(SCHEDULE_CREATE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.createSchedule) {
                    dispatch(generateSkeleton(SCHEDULE_CREATE_SUCCESS, res.body.data.createSchedule, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(SCHEDULE_CREATE_ERROR, err, context));
        }
    };
}

/**
 * Update schedule action
 * @param jobId
 * @param oldSchedule
 * @param newSchedule
 * @param context
 * @param gql
 * @param organization
 */
export function updateSchedule(jobId, oldSchedule, newSchedule, context = defaultContext, gql = JOB_DETAIL_PAGE, organization) {

    return async (dispatch) => {
        try {

            const gqlQuery = {
                query: `mutation($job: Float!, $oldSchedule: JobScheduleInput!, $newSchedule: JobScheduleInput!, $organization: Float) {
                    updateSchedule(job: $job, oldSchedule: $oldSchedule, newSchedule: $newSchedule, organization: $organization) {
                        ${gql}
                    }
                }`,
                variables: {
                    job: jobId,
                    oldSchedule: oldSchedule,
                    newSchedule: newSchedule,
                    organization: organization
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(SCHEDULE_UPDATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));


            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(SCHEDULE_UPDATE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.updateSchedule) {
                    dispatch(generateSkeleton(SCHEDULE_UPDATE_SUCCESS, res.body.data.updateSchedule, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(SCHEDULE_UPDATE_ERROR, err, context));
        }
    };
}

/**
 * Delete schedule action
 * @param jobId
 * @param schedule
 * @param context
 * @param gql
 * @param organization
 */
export function deleteSchedule(jobId, schedule, context = defaultContext, gql = JOB_DETAIL_PAGE, organization) {

    return async (dispatch) => {
        try {

            const gqlQuery = {
                query: `mutation($job: Float!, $schedule: JobScheduleInput!, $organization: Float) {
                    deleteSchedule(job: $job, schedule: $schedule, organization: $organization) {
                        ${gql}
                    }
                }`,
                variables: {
                    job: jobId,
                    schedule: schedule,
                    organization: organization
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(SCHEDULE_DELETE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));


            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(SCHEDULE_DELETE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.deleteSchedule) {
                    dispatch(generateSkeleton(SCHEDULE_DELETE_SUCCESS, res.body.data.deleteSchedule, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(SCHEDULE_DELETE_ERROR, err, context));
        }
    };
}