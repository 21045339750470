import { getDefaultDateLevel } from "@biuwer/common/src/libs/dates-lib";

export default function getQueryField(field) {
    let queryField = { cfId: field.get('_id') };

    // Dataset
    if (field && !!field.get('dataset')) {
        queryField.dataset = field.get('dataset');
    }

    // DataField
    if (field && !!field.getIn(['dataField', '_id'])) {
        queryField._id = field.getIn(['dataField', '_id']);
    }

    if (field && !!field.getIn(["dataField", "calculated_field"])) {
        queryField.calculated_field = field.getIn(["dataField", "calculated_field"]);
    }

    // Add aggregation if needed
    if (field && !!field.get('aggregation')) {
        queryField.aggregation = field.get('aggregation');
    }

    // Add date_level if needed
    if (field && !!field.get('date_level')) {
        queryField.date_level = field.get('date_level');
    }

    // Add field_type if needed
    if (field && !!field.get('field_type')) {
        queryField.field_type = field.get('field_type');
    }

    if (queryField.field_type === "measure" && ["date", "datetime"].includes(field.getIn(["dataField", "data_type"]))) {
        queryField.date_level = getDefaultDateLevel(field.getIn(["dataField", "data_type"]));
    }

    return queryField;

}
