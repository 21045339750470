import { Map, fromJS } from "immutable";
import { defaultContext } from "@biuwer/redux/src/config/constants";

import {
    DATASET_FIELD_TRANSLATIONS_DETAIL_INITIALIZE,
    DATASET_FIELD_TRANSLATIONS_FETCH_REQUEST, DATASET_FIELD_TRANSLATIONS_FETCH_SUCCESS, DATASET_FIELD_TRANSLATIONS_FETCH_ERROR,
    DATASET_FIELD_TRANSLATIONS_CREATE_REQUEST, DATASET_FIELD_TRANSLATIONS_CREATE_SUCCESS, DATASET_FIELD_TRANSLATIONS_CREATE_ERROR,
    DATASET_FIELD_TRANSLATIONS_UPDATE_REQUEST, DATASET_FIELD_TRANSLATIONS_UPDATE_SUCCESS, DATASET_FIELD_TRANSLATIONS_UPDATE_ERROR
} from "./datasets-field-translations-actions";

const initialState = fromJS({
    detail_primary: {}
});

export default function DatasetsFieldTranslationsReducer(state = initialState, action) {
    let newState, detailUpdatedPayload;

    // Get dataset field translation context
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch (action.type) {
        case DATASET_FIELD_TRANSLATIONS_DETAIL_INITIALIZE:
            newState = state.setIn([detail], Map({}));

            return newState;
        case DATASET_FIELD_TRANSLATIONS_FETCH_REQUEST:
            newState = state;

            // Delete created and update keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);

            newState = newState.mergeIn([detail], Map({
                isFetching: action.isFetching,
                issue: action.issue
            }));

            return newState;
        case DATASET_FIELD_TRANSLATIONS_FETCH_SUCCESS:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                issue: action.issue,
                payload: fromJS(action.payload)
            }));

            return newState;
        case DATASET_FIELD_TRANSLATIONS_CREATE_REQUEST:
            newState = state;

            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);

            newState = newState.mergeIn([detail], Map({
                isFetching: action.isFetching,
                issue: action.issue
            }));

            return newState;
        case DATASET_FIELD_TRANSLATIONS_CREATE_SUCCESS:
            detailUpdatedPayload = fromJS(action.payload);

            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                issue: action.issue,
                created: action.created,
                payload: detailUpdatedPayload
            }));

            return newState;
        case DATASET_FIELD_TRANSLATIONS_UPDATE_REQUEST:
            newState = state;

            if (newState.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);

            newState = newState.mergeIn([detail], Map({
                isFetching: action.isFetching,
                issue: action.issue
            }));

            return newState;
        case DATASET_FIELD_TRANSLATIONS_UPDATE_SUCCESS:
            detailUpdatedPayload = fromJS(action.payload);

            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                issue: action.issue,
                updated: action.updated,
                payload: detailUpdatedPayload
            }));

            return newState;
        case DATASET_FIELD_TRANSLATIONS_FETCH_ERROR:
        case DATASET_FIELD_TRANSLATIONS_CREATE_ERROR:
        case DATASET_FIELD_TRANSLATIONS_UPDATE_ERROR:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                issue: action.issue,
                issuePayload: fromJS(action.issuePayload)
            }));

            return newState;
        default:
            return state;
    }
}
