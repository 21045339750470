// App names
export const BIUWER_APP_NAME = "biuwer";
export const BIUWER_SHARE_APP_NAME = "share";
// LocalStorage app prefixes
export const BIUWER_APP_PREFIX = "bw";
export const BIUWER_SHARE_APP_PREFIX = "bws";
// LoalStorage variable names
export const AUTH_TOKEN_NAME = "authToken";
export const LANGUAGE = "language";
export const USER_NAME = "user";
export const MASQUERADE_USER_AUTH_TOKEN_NAME = "masqueradeUserAuthToken";
export const MASQUERADE_USER_NAME = "masqueradeUser";
export const MASQUERADE_USER_PATH_NAME = "masqueradeUserPath";
// Menu types
export const MENU_TYPE_SIDEBAR_LEFT = "sidebar-left";
export const MENU_TYPE_DRAWER = "drawer";
// User types
export const ADVANCED_USERS_LABEL = "admin.users.advancedUsersLabel";
export const BASIC_USERS_LABEL = "admin.users.basicUsersLabel";
export const SWITCH_TO_ADVANCE_LABEL = "admin.users.switchToAdvancedLabel";
export const USERS_LABEL = "admin.users.title_plural";
// Data types
export const DATE_AND_DATETIME_DATA_TYPES = ['date', 'datetime'];
export const BOOLEAN_DATA_TYPES = ['boolean'];
export const NUMERIC_DATA_TYPES = ['number'];