import { combineReducers } from 'redux';
import { isEmpty } from 'lodash';

// Reducers
import data from "../data/data-reducers";
import dataPrep from "../data-prep/data-prep-reducers";
import system from '../system/system-reducers';
import billing from '../billing/billing-reducers';
import maps from "../maps/maps-reducers";
import share from "../share/share-reducers";
import ui from "../ui/ui-reducers"

export default function createReducer(asyncReducers) {
    return combineReducers({
        system,
        maps,
        share,
        billing,
        data,
        dataPrep,
        ui,
        // Inject async reducers
        ...(!isEmpty(asyncReducers) ? { custom: combineReducers({ ...asyncReducers }) } : {})
    });
}