export const JOBS_LIST_PAGE = `
    _id
    name
    status
    schedules {
        name
    }
    agents {
        _id
        name
    }
    dag {
        nodes {
            v
            value {
                isJob
            }
        }
        edges {
            v
        }
    }
    max_timeout
    is_container
    error_notifications
    email_recipients {
        recipient_type
        values
    }
    organization {
        _id
        name
    }
`;

export const JOB_DETAIL_PAGE = `
    _id
    name
    status
    schedules {
        _id
        name
        type
        value
        status
    }
    agents {
        _id
        name
    }
    dag {
        nodes {
            v
            value {
                _id
                isJob
            }
        }
        edges {
            v
        }
    }
    created
    executions
    max_timeout
    is_container
    error_notifications
    email_recipients {
        recipient_type
        values
    }
    organization {
        _id
        name
    }
`;

export const MINIMAL_JOBS_LIST = `
    _id
    name
`;