import uniq from "lodash/uniq";
import cloneDeep from "lodash/cloneDeep";
import { fromJS, Iterable } from "immutable";
import moment from "moment";

// Libs
import getQueryFilters from './get-query-filters';
import getLocalQueries from "./get-local-queries";
import getQueryField from "./get-query-field";
import getNeededRelations from "./get-needed-relations";
import getDataCardFields from "./get-data-card-fields";
import getLocalQueryFieldsTree from "./get-local-query-fields-tree";
import { parsePredefinedOperations } from '@biuwer/filters/src/filters-lib';
import operationsLib from "./calculated-fields-lib/operations-lib";
import getFieldType from "./get-field-type";
import { getDefaultDateLevel } from "@biuwer/common/src/libs/dates-lib";

/**
 * Check field type and side effects for server query.
 * With this function we can set as dimension fields with aggregation "none".
 * Also check for side effects of aggregation "none" in date and datetime fields
 * @param {Array<Object>} queryFields
 * @returns {Array<Object>} Returns queryFields array with fields updated if proceed
 */
export function checkQueryFieldTypes(queryFields) {
    return queryFields.map(field => {
        const originalFieldType = field.field_type;
        const calculatedField = field.dataField?.calculated_field || field.calculated_field;
        field.field_type = getFieldType({ fieldType: field.field_type, aggregation: field.aggregation, calculatedField: calculatedField });
        if (field.field_type !== "measure") field.aggregation = "";
        if (originalFieldType !== field.field_type && ["date", "datetime"].includes(field.data_type)) {
            field.date_level = getDefaultDateLevel(field.data_type);
        }
        return field;
    });
}

function getDataCardQueries(cardMetaData = {}, cardFilters, pageFilters, interactiveTableColumns, popupFilters) {

    // Transform card metadata to JS object if needed
    const card = Iterable.isIterable(cardMetaData) ? cardMetaData.toJS() : cardMetaData;

    // Destructure card metadata
    const { queries: cardQueries } = card;

    // Initialize data card queries metadata array
    const dataCardQueriesMetadata = [];

    // Get data card fields
    let dataCardFields = getDataCardFields(card.queries, card.calculated_fields);
    dataCardFields = checkQueryFieldTypes(dataCardFields);

    // Get local queries
    const localQueries = getLocalQueries({ cardType: card.card_type, visualization: card.visualization, interactiveTableColumns });

    // Loop through each local query to prepare final data card query metadata
    for (let localQuery of localQueries) {

        // Destructure local query metadata
        const localQueryId = localQuery._id;
        let localQueryFields = localQuery.fields || [];
        localQueryFields = localQueryFields.map(field => {
            const fieldQuery = (field.queryField && field.queryField.query) || field.query;
            const fieldPosition = (field.queryField && field.queryField.position) || field.position;
            const queryField = dataCardFields.find(qf => qf.query === fieldQuery && qf.position === fieldPosition);
            field.field_type = queryField && queryField.field_type;
            return field;
        });
        const numDimensions = localQueryFields.filter(f => f.field_type === "dimension").length;

        // Get local query fields tree
        const fieldsTree = getLocalQueryFieldsTree({
            startFieldsArray: localQueryFields,
            queryFields: dataCardFields,
            calculatedFields: card.calculated_fields
        });

        // Get all raw fields
        const rawFields = fieldsTree.reduce((acc, next) => {
            if (next.query === "CF" || (next.field_type === "dimension" && numDimensions === 0) || acc.some(f => f.query === next.query && f.position === next.position)) {
                return acc;
            }
            else {
                acc.push({
                    query: next.query,
                    position: next.position,
                });
                return acc;
            }
        }, []);

        // Replace local query fields for obtained raw fields
        localQueryFields = rawFields.map(rf => dataCardFields.find(dcf => dcf.query === rf.query && dcf.position === rf.position));

        // Check if need relations and add needed join fields
        let queryIds = uniq(localQueryFields.map(field => field.query));
        // const numDimensions = localQueryFields.filter(field => field.field_type === "dimension").length;
        if (queryIds.length > 1 && numDimensions > 0) {

            // Loop through each needed relation and check for each operation if need to add any of operation fields
            const neededRelations = getNeededRelations(queryIds, card.query_relations);
            for (let relation of neededRelations) {
                for (let operation of relation.operations) {

                    // Check if need to add join field to local query fields array
                    const addJoinField = (joinField) => {
                        const joinFieldIndex = localQueryFields.findIndex(lqf => {
                            return lqf.query === joinField.query && lqf.cfId === joinField.field;
                        });
                        if (joinFieldIndex === -1) {
                            localQueryFields.push(dataCardFields.find(dcf => {
                                return dcf.query === joinField.query && dcf.cfId === joinField.field;
                            }));
                        }
                    };

                    addJoinField({ query: relation.query1, field: operation.field1 });
                    addJoinField({ query: relation.query2, field: operation.field2 });

                }
            }

        }

        // Obtain all query ids and loop through each query and prepare all needed metadata
        let queries = [];
        queryIds = uniq(localQueryFields.map(field => field.query));
        for (let queryId of queryIds) {

            // Get query metadata
            const queryMetadata = (cardQueries && cardQueries.find(cardQuery => cardQuery.queryId === queryId)) || {};

            // Prepare fields
            let queryFields = queryMetadata.fields || [];
            queryFields = checkQueryFieldTypes(queryFields);

            const fields = [];
            localQueryFields
                .filter(f => f.query === queryId)
                .forEach(f => fields.push(getQueryField(fromJS(queryFields.find(qf => qf.position === f.position)))))
                ;

            // Prepare filters
            let filters = [];
            const queryPageFilters = getQueryFilters(queryMetadata.uuid, pageFilters || [], ["cards"], card._id);
            filters = filters.concat(queryPageFilters);
            const queryCardFilters = getQueryFilters(queryMetadata.uuid, cardFilters, ["queries"], null, popupFilters);
            filters = filters.concat(queryCardFilters);

            const sort = queryMetadata?.sort?.map((sortField => {
                let queryField = queryFields.find((field) => field?.dataField?._id === sortField.fieldId)
                sortField.date_level = queryField.date_level || "year";
                return sortField;
            }));

            // Add query metadata
            queries.push({
                _id: queryId,
                uuid: queryMetadata.uuid,
                datamodel: queryMetadata.datamodel,
                fields: fields,
                filters: filters,
                sort: sort,
                limit: queryMetadata.limit,
                show_empty_rows: queryMetadata.show_empty_rows,
                no_group_by: queryMetadata.no_group_by || false
            });
        }

        // Get time window fields
        const timeWindowFields = fieldsTree.reduce((acc, next) => {
            if (next.query !== "CF" || !next.parsedExpression) return acc;
            function checkParsedExpression(parsedExpression) {
                if (parsedExpression.type === 'operation') {
                    const peOperation = parsedExpression.operation;
                    operationsLib.operationFunctions({
                        operation: peOperation,
                        fn: checkParsedExpression,
                        timeWindowFn: extraArgs => {
                            acc.push({
                                refFieldId: next.refFieldId,
                                type: peOperation.type,
                                timeField: extraArgs.dateField,
                                metricField: extraArgs.metricField,
                            });
                        },
                    });
                }
            }
            checkParsedExpression(next.parsedExpression);
            return acc;
        }, []);

        // Loop through each time window field to create needed extra queries
        let timeWindowQueries = [];
        for (let timeWindowField of timeWindowFields) {

            // Get time fields
            const originTimeField = dataCardFields.find(f => f.cfId === timeWindowField.timeField);
            const timeFields = originTimeField ? [originTimeField] : [];
            if (queries.length > 1) {
                const neededRelations = getNeededRelations(queryIds, card.query_relations);
                function getTimeFields(timeField) {
                    const relations = neededRelations.filter(r => r.operations.some(o => o.field1 === timeField.cfId || o.field2 === timeField.cfId));
                    for (let relation of relations) {
                        const isLeft = relation.query1 === timeField.query;
                        const fieldToAdd = relation.operations
                            .find(o => isLeft ? o.field1 === timeField.cfId : o.field2 === timeField.cfId)
                            [isLeft ? "field2" : "field1"]
                        ;
                        const newTimeField = dataCardFields.find(f => f.cfId === fieldToAdd);
                        if (newTimeField && timeFields.findIndex(f => f.position === newTimeField.position && f.query === newTimeField.query) === -1) {
                        // if (newTimeField && timeFields.findIndex(f => f._id === newTimeField._id && f.query === newTimeField.query) === -1) {
                            timeFields.push(newTimeField);
                            getTimeFields(newTimeField);
                        }
                    }
                }
                getTimeFields(originTimeField);
            }

            // Get needed fields
            const neededFields = [];
            function getNeededFields(refFieldId) {
                const fieldTree = fieldsTree.find(ft => ft.refFieldId === refFieldId);
                if (fieldTree.query === "CF") {
                    fieldTree.childs.forEach(child => getNeededFields(child.fieldId));
                }
                else {
                    neededFields.push(fieldTree.refFieldId);
                }
            }
            getNeededFields(timeWindowField.metricField);

            // Filter needed queries and map with time window metadata needed changes
            const newTimeWindowQueries = queries
                .filter(query => {
                    const queryTimeField = timeFields.find(f => f.query === query?._id);
                    const timeFilterIndex = query.filters.findIndex(f => `_${f.data_field}` === queryTimeField?._id);
                    return (
                        query.fields.some(f => neededFields.some(nf => nf === f?.cfId)) &&
                        timeFilterIndex !== -1
                    );
                })
                .map((timeWindowQuery, twqIndex) => { // eslint-disable-line

                    const queryId = `TWQ${timeWindowQueries.length + twqIndex + 1}`;
                    const twqFields = timeWindowQuery.fields.filter(f => f.field_type === "dimension" || neededFields.some(nf => nf === f?.cfId));
                    const twqFilters = cloneDeep(timeWindowQuery.filters);
                    const queryTimeField = timeFields.find(f => f.query === timeWindowQuery._id);
                    const timeFilterIndex = twqFilters.findIndex(f => `_${f.data_field}` === queryTimeField._id);
                    let filterDateLevel;

                    if (twqFields.reduce((acc, next) => {
                        if (next.field_type === "dimension") acc++;
                        return acc;
                    }, 0) === 0) {

                        let oldFilterValues = cloneDeep(twqFilters[timeFilterIndex].expression.values);
                        filterDateLevel = cloneDeep(twqFilters[timeFilterIndex].expression.date_level);
                        let filterOperation = twqFilters[timeFilterIndex].expression.operation;
                        let newFilterValues = [];

                        oldFilterValues.forEach((filterValue) => {
                            let timeWindow = getTimeWindow(timeWindowField.type.toUpperCase(), filterDateLevel);
                            let dateMask = getFilterDateMask(filterDateLevel);

                            if (filterOperation === 'predefinedRange' || filterOperation === 'notPredefinedRange') {

                                let parsedFilterValues = parsePredefinedOperations(filterOperation, filterValue);
                                newFilterValues = parsedFilterValues.value;
                                twqFilters[timeFilterIndex].expression.operation = parsedFilterValues.operation;

                            } else {
                                newFilterValues.push(moment(filterValue, dateMask).subtract(1, timeWindow).format(dateMask));
                            }
                        });

                        twqFilters[timeFilterIndex].expression.values = newFilterValues;

                    }
                    else {
                        twqFilters.splice(timeFilterIndex, 1);
                    }

                    return {
                        ...timeWindowQuery,
                        _id: queryId,
                        fields: twqFields,
                        filters: twqFilters,
                        refQueryId: timeWindowQuery._id,
                        // refFieldId: timeWindowField.refFieldId,
                        timeField: queryTimeField.cfId,
                        limit: undefined,
                        dateLevel: filterDateLevel
                    };

                });
            ;
            timeWindowQueries = timeWindowQueries.concat(newTimeWindowQueries);
        }

        // Add time window queries to queries array
        queries = queries.concat(timeWindowQueries);

        // Add data card query metadata
        dataCardQueriesMetadata.push({
            _id: localQueryId,
            queries: queries
        });

    }

    return dataCardQueriesMetadata;

}

export default getDataCardQueries;

/**
 * Returns date mask for a given date level but only taking into account filter date levels
 * @param dateLevel (string)
 * @return (string)
 * */
 export const getFilterDateMask = (dateLevel) => {
    switch (dateLevel) {
        case 'year':
            return 'YYYY';
        case 'quarter':
            return 'YYYY-Q';
        case 'month':
            return 'YYYY-MM';
        case 'week':
            return 'YYYY-WW';
        case 'day':
            return 'YYYY-MM-DD';
        case 'hour_of_day':
            return 'HH';
        default:
            return 'YYYY-MM-DD';

    }
}

/**
 * Returns time window given a time window function
 * @param timeWindowFunction (string)
 * @param filterDateLevel (string)
 * @return (string)
 * */
export const getTimeWindow = (timeWindowFunction, filterDateLevel) => {
    switch (timeWindowFunction) {
        case 'PREV_PERIOD':
            return filterDateLevel;
        case 'PREV_YEAR':
            return 'year';
        case 'PREV_QUARTER':
            return 'quarter';
        case 'PREV_MONTH':
            return 'month';
        case 'PREV_WEEK':
            return 'week';
        case 'PREV_DAY':
        default:
            return 'day';
    }
}
