import React from "react";
import { useTranslation, Trans } from 'react-i18next';

// Libs
import chatLib from "@biuwer/common/src/libs/chat-lib";
import History from "@biuwer/core/src/history";

// Components
import CustomButton from "./custom-button";

const FeatureWallContent = ({ extraAction = () => {}, showTitle = true, isLimitType, limit, current, entity, context }) => {

    const {t} = useTranslation();

    if (isLimitType) {
        return (
            <>
                {showTitle && (<div className="h4 fs22 bold mb15">{t("common.featureWall.limitP1")}</div>)}
                <div>
                    <Trans
                        i18nKey={'common.featureWall.limitP2'}
                        values={{ count: limit, limit, current, entity: t(entity, { count: current }).toLowerCase() }}
                        components={[<b>placeholder</b>]}
                    />
                </div>
                <FeatureWallImage alt={t("common.featureWall.limitP1")}/>
                <div className="mb15">{t("common.featureWall.limitP3")}</div>
                <div className="d-flex">
                    <PlanButton extraAction={extraAction} />
                    <span style={{ width: 15 }}/>
                    <ChatButton extraAction={extraAction} />
                </div>
            </>
        );
    }

    return (
        <>
            {showTitle && (<div className="h4 fs22 bold mb15">{t("common.featureWall.p1")}</div>)}
            <div>{t("common.featureWall.p2")}</div>
            <FeatureWallImage alt={t("common.featureWall.p1")} />
            <div className="mb15">{t("common.featureWall.p3")}</div>
            <PlanButton extraAction={extraAction} />
        </>
    );
};

export default FeatureWallContent;

const FeatureWallImage = ({ alt }) => (
    <img
        src={"img/biuwer-feature-wall.svg"}
        alt={alt}
        height={200}
        style={{ margin: "45px 0" }}
    />
);

const PlanButton = ({ extraAction }) => {

    const {t} = useTranslation();

    return (
        <CustomButton
            text={t("common.featureWall.seePlans")}
            intent={"info"}
            onClick={() => {
                History.push("/admin/usage-and-billing/plans");
                extraAction();
            }}
        />
    );
};

const ChatButton = ({ extraAction }) => {

    const {t} = useTranslation();

    return (
        <CustomButton
            text={t("common.featureWall.chatWithUs")}
            intent={"info"}
            onClick={() => {
                chatLib.openChat();
                chatLib.showChat();
                extraAction();
            }}
        />
    );
};