import request from "@biuwer/common/src/libs/superagent";

import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import i18n from "@biuwer/core/src/i18n";
import { defaultContext } from "@biuwer/redux/src/config/constants";
import { RESOURCE_TOKENS_DETAIL } from './resource-tokens-gql';

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

/*
 * Resource Tokens action types
 */
export const RESOURCE_TOKENS_INITIALIZE = 'RESOURCE_TOKENS_INITIALIZE';
export const RESOURCE_TOKENS_LIST_INITIALIZE = 'RESOURCE_TOKENS_LIST_INITIALIZE';
export const RESOURCE_TOKEN_DETAIL_INITIALIZE = 'RESOURCE_TOKEN_DETAIL_INITIALIZE';

export const RESOURCE_TOKENS_FETCH_REQUEST = 'RESOURCE_TOKENS_FETCH_REQUEST';
export const RESOURCE_TOKENS_FETCH_SUCCESS = 'RESOURCE_TOKENS_FETCH_SUCCESS';
export const RESOURCE_TOKENS_FETCH_ERROR = 'RESOURCE_TOKENS_FETCH_ERROR';

export const RESOURCE_TOKEN_FETCH_REQUEST = 'RESOURCE_TOKEN_FETCH_REQUEST';
export const RESOURCE_TOKEN_FETCH_SUCCESS = 'RESOURCE_TOKEN_FETCH_SUCCESS';
export const RESOURCE_TOKEN_FETCH_ERROR = 'RESOURCE_TOKEN_FETCH_ERROR';

export const RESOURCE_TOKEN_UPDATE_REQUEST = 'RESOURCE_TOKEN_UPDATE_REQUEST';
export const RESOURCE_TOKEN_UPDATE_SUCCESS = 'RESOURCE_TOKEN_UPDATE_SUCCESS';
export const RESOURCE_TOKEN_UPDATE_ERROR = 'RESOURCE_TOKEN_UPDATE_ERROR';

export const RESOURCE_TOKEN_CREATE_REQUEST = 'RESOURCE_TOKEN_CREATE_REQUEST';
export const RESOURCE_TOKEN_CREATE_SUCCESS = 'RESOURCE_TOKEN_CREATE_SUCCESS';
export const RESOURCE_TOKEN_CREATE_ERROR = 'RESOURCE_TOKEN_CREATE_ERROR';

export const RESOURCE_TOKEN_DELETE_REQUEST = 'RESOURCE_TOKEN_DELETE_REQUEST';
export const RESOURCE_TOKEN_DELETE_SUCCESS = 'RESOURCE_TOKEN_DELETE_SUCCESS';
export const RESOURCE_TOKEN_DELETE_ERROR = 'RESOURCE_TOKEN_DELETE_ERROR';

const generateSkeleton = (type, body, context = defaultContext, extraArgs, showNotification = true) => {
    let skeleton = { type, context }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)){
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                // issuePayload: {
                //     status: body.status,
                //     code: body.issuePayload.code,
                //     message: body.message
                // }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body
            };

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }
            break;
        default:
            break;
    }

    switch(type){
        case RESOURCE_TOKEN_CREATE_SUCCESS:
            skeleton.created = true;
            skeleton.resourceType = extraArgs.resourceType;
            skeleton.resourceId = extraArgs.resourceId;

            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', { name: i18n.t('content.share.sharedResource'), context: 'male', count: 1 })
            };

            break;
        case RESOURCE_TOKEN_UPDATE_SUCCESS:
            skeleton.updated = true;

            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('content.share.sharedResource'), context: 'male', count: 1 })
            };
            break;
        case RESOURCE_TOKEN_DELETE_SUCCESS:
            skeleton.deleted = true;
            skeleton.resourceType = extraArgs.resourceType;
            skeleton.resourceId = extraArgs.resourceId;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', { name: i18n.t('content.share.sharedResource'), context: 'male', count: 1 })
            };
            break;
        case RESOURCE_TOKEN_FETCH_SUCCESS:
            if (extraArgs && extraArgs.queryToken) {
                skeleton.payload = (skeleton.payload && skeleton.payload[0]) || undefined;
            }
            break;
        case RESOURCE_TOKEN_CREATE_ERROR:
        case RESOURCE_TOKEN_UPDATE_ERROR:
        case RESOURCE_TOKEN_DELETE_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
        default:
            break;
    }

    return (dispatch) => {
        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    };
};


/**
 * Initialize Resource Tokens Reducer
 */
export function initializeResourceTokens(context = defaultContext) {
    return {
        type: RESOURCE_TOKENS_INITIALIZE,
        context
    };
}
export function initializeResourceTokensList(context = defaultContext) {
    return {
        type: RESOURCE_TOKENS_LIST_INITIALIZE,
        context
    };
}
export function initializeResourceTokenDetail(context = defaultContext) {
    return {
        type: RESOURCE_TOKEN_DETAIL_INITIALIZE,
        context
    };
}

export function queryResourceTokens(query, context = defaultContext, gql = RESOURCE_TOKENS_DETAIL) {
    return async dispatch => {

        try {
            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(RESOURCE_TOKENS_FETCH_REQUEST, null, context));

            const graphQuery = {
                query: `query($query: QueryResourceTokenInput!) {
                queryResourceTokens(query: $query) {
                    ${gql}
                }
            }`,
                variables: {
                    query: query
                }
            };

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(graphQuery)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(RESOURCE_TOKENS_FETCH_ERROR, 'Error getting resource tokens data from server', context));
                } else if (res.body && res.body.data && res.body.data.queryResourceTokens) {
                    dispatch(generateSkeleton(RESOURCE_TOKENS_FETCH_SUCCESS, res.body.data.queryResourceTokens, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(RESOURCE_TOKENS_FETCH_ERROR, err, context));
        }
    };
}

export function getResourceToken(resourceId, context = defaultContext, gql = RESOURCE_TOKENS_DETAIL) {
    return async dispatch => {

        try {
            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(RESOURCE_TOKEN_FETCH_REQUEST, null, context));

            const graphQuery = {
                query: `query($resourceTokenId: String!) {
                readResourceToken(resourceTokenId: $resourceTokenId) {
                    ${gql}
                }
            }`,
                variables: {
                    resourceTokenId: resourceId
                }
            };

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(graphQuery)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(RESOURCE_TOKEN_FETCH_ERROR, 'Error getting resource token from server', context));
                } else if (res.body && res.body.data && res.body.data.readResourceToken) {
                    dispatch(generateSkeleton(RESOURCE_TOKEN_FETCH_SUCCESS, res.body.data.readResourceToken, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(RESOURCE_TOKEN_FETCH_ERROR, err, context));
        }
    };
}

export function createResourceToken(resource, context = defaultContext, gql = RESOURCE_TOKENS_DETAIL) {
    return async dispatch => {
        try {
            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(RESOURCE_TOKEN_CREATE_REQUEST, null, context));

            const query = {
                query: `mutation($resourceToken: ResourceTokenInput!) {
                    createResourceToken(resourceToken: $resourceToken) {
                        ${gql}
                    }
                }`,
                variables: {
                    resourceToken: resource
                }
            };

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(RESOURCE_TOKEN_CREATE_ERROR, 'error creating resource token', context));
                } else if (res.body && res.body.data && res.body.data.createResourceToken) {
                    dispatch(generateSkeleton(RESOURCE_TOKEN_CREATE_SUCCESS, res.body.data.createResourceToken, context, { resourceType: resource.resource_type, resourceId: resource.resource_id }));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(RESOURCE_TOKEN_CREATE_ERROR, err, context));
        }
    };
}
export function updateResourceToken(resourceId, resource, context = defaultContext, gql = RESOURCE_TOKENS_DETAIL) {
    return async dispatch => {
        try {
            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(RESOURCE_TOKEN_UPDATE_REQUEST, null, context));

            const query = {
                query: `mutation($resourceTokenId: String!, $resourceToken: ResourceTokenInput!) {
                    updateResourceToken(resourceTokenId: $resourceTokenId, resourceToken: $resourceToken) {
                        ${gql}
                    }
                }`,
                variables: {
                    resourceToken: resource,
                    resourceTokenId: resourceId
                }
            };

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(RESOURCE_TOKEN_UPDATE_ERROR, 'error updating resource token', context));
                } else if (res.body && res.body.data && res.body.data.updateResourceToken) {
                    dispatch(generateSkeleton(RESOURCE_TOKEN_UPDATE_SUCCESS, res.body.data.updateResourceToken, context, { resourceType: resource.resource_type, resourceId: resource.resource_id }));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(RESOURCE_TOKEN_UPDATE_ERROR, err, context));
        }
    }
}
export function deleteResourceToken(resourceId, context = defaultContext, gql = RESOURCE_TOKENS_DETAIL) {
    return async dispatch => {
        try {
            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(RESOURCE_TOKEN_DELETE_REQUEST, null, context));

            const query = {
                query: `mutation($resourceTokenId: String!) {
                    deleteResourceToken(resourceTokenId: $resourceTokenId) {
                        ${gql}
                    }
                }`,
                variables: {
                    resourceTokenId: resourceId
                }
            };

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));


            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(RESOURCE_TOKEN_DELETE_ERROR, 'Error deleting resource token', context));
                } else if (res.body && res.body.data && res.body.data.deleteResourceToken) {
                    let resourceToken = res.body.data.deleteResourceToken;
                    dispatch(generateSkeleton(RESOURCE_TOKEN_DELETE_SUCCESS, res.body.data.deleteResourceToken, context, { resourceType: resourceToken.resource_type, resourceId: resourceToken.resource_id }));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(RESOURCE_TOKEN_DELETE_ERROR, err, context));
        }
    };
}