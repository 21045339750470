// Libs
import gqlRequest from '@biuwer/core/src/graphql-request';

// External Actions
export const SIGNUP_REQUEST_SUCCESS = 'SIGNUP_REQUEST_SUCCESS';
export const SIGNUP_REQUEST_ERROR = 'SIGNUP_REQUEST_ERROR';
export const SIGNUP_CLEAN_ERROR = 'SIGNUP_CLEAN_ERROR';

export const SIGNUP_CONFIRMATION_REQUEST = 'SIGNUP_CONFIRMATION_REQUEST';
export const SIGNUP_CONFIRMATION_SUCCESS = 'SIGNUP_CONFIRMATION_SUCCESS';
export const SIGNUP_CONFIRMATION_ERROR = 'SIGNUP_CONFIRMATION_ERROR';

/**
 * Signup request action, send signup data and returns user email
 * @param {Object} signup Signup request input object
 * @returns {Promise<Function>}
 */

export function signupRequest(signup) {
    return async dispatch => {
        try {

            // Signup request return user email
            const userEmail = await gqlRequest({
                queryType: "mutation",
                queryName: "signupRequest",
                variables: [{
                    type: "SignupRequestInput!",
                    name: "signupRequestInput",
                    data: signup
                }]
            });

            // Dispatch signup request success with user email
            dispatch({
                type: SIGNUP_REQUEST_SUCCESS,
                userEmail: userEmail
            });

        } catch (err) {
            dispatch({
                type: SIGNUP_REQUEST_ERROR,
                error: err
            });
        }
    };
}

// Function to clean signup error
export function signupCleanError() {
    return {
        type: SIGNUP_CLEAN_ERROR
    };
}

/**
 * Signup confirmation, send token and password and returns authToken to login
 * @param {String} token signup token
 * @param {String} password new user password
 * @returns
 */
export function signupConfirmation(token, password) {
    return async dispatch => {
        try {

            dispatch({
                type: SIGNUP_CONFIRMATION_REQUEST
            });

            // Signup confirmation return auto login token
            const autoLogInToken = await gqlRequest({
                queryType: "mutation",
                queryName: "signupConfirmation",
                variables: [{
                    type: "SignupConfirmationInput!",
                    name: "signupConfirmationInput",
                    data: { token, password }
                }]
            });

            // Dispatch signup confirmation success with auto login token
            dispatch({
                type: SIGNUP_CONFIRMATION_SUCCESS,
                autoLogInToken: autoLogInToken
            });

        } catch (err) {
            dispatch({
                type: SIGNUP_CONFIRMATION_ERROR,
                error: err
            });
        }
    };
}