import { CARD_SCREEN_BODY_ID, PAGE_BODY_ID } from "./content-constants"
import { printElement, onCloneCallback } from "./exports-lib"

/**
 *
 * @param {Object} options
 * @param {Document} options.content
 * @param {String} options.entityType
 * @param {Number} options.entityId
 * @param {Boolean} options.fromPage
 * @param {Boolean} options.unrollCardContent
 * @param {String} options.cardType
 * @returns {Promise<Blob>}
 */
export async function getPngFromVisualization({ content, entityType, entityId, fromPage, unrollCardContent, cardType, contentType }) {
    let contentElement
    if (entityType === "card") {
        contentElement = fromPage ? content.getElementById(`card-${entityId}`) : content.getElementById(CARD_SCREEN_BODY_ID)
    } else if (entityType === "page") {
        contentElement = document.getElementById(PAGE_BODY_ID)
    } else if (entityType === "collection") {
        contentElement = contentType === "card" ? content.getElementById(CARD_SCREEN_BODY_ID) : document.getElementById(PAGE_BODY_ID)
    } else {
        throw new Error("UNKNOWN_EXPORT_ENTITY_TYPE")
    }

    if (unrollCardContent) {
        if (cardType === "Custom") contentElement = contentElement.children[0].children[0].children[1].children[0]
    }

    const result = await printElement(
        contentElement,
        (document, element) => onCloneCallback(document, element, { entityType, entityId, fromPage, itemToRender: "content", contentType })
    )

    return result.blob
}