import { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox, Radio } from "@blueprintjs/core"

// Base styles
let styles = {
    root: {}
};

export default function CheckInput({
    type = "checkbox", rootClassName, className, style,
    labelText, name = "", value = "", colorType = "primary",
    checked = false, disabled = false, title = false,
    onChange = () => {}, children
}) {

    const rootStyles = { ...(styles.root ?? {}), ...(style ?? {}) }
    const color = useMemo(() => `${colorType === 'none' ? '' : (type === 'checkbox' ? 'check' : 'radio') + '-' + colorType}`, [colorType, type])

    return (
        <div
            className={rootClassName}
            style={rootStyles}
        >
            {type === "radio" ? (
                <Radio
                    name={name}
                    checked={checked}
                    disabled={disabled}
                    className={classNames([
                        className,
                        color,
                        { "radio": type === "radio" },
                        { "checkbox": type === "checkbox" },
                        { "checked": checked },
                        { "disabled": disabled }
                    ])}
                    onChange={() => onChange(value)}
                    label={labelText}
                    title={title ? labelText : undefined}
                >
                    {children}
                </Radio>
            ) : (
                <Checkbox
                    name={name}
                    checked={checked}
                    disabled={disabled}
                    className={classNames([
                        className,
                        color,
                        { "radio": type === "radio" },
                        { "checkbox": type === "checkbox" },
                        { "checked": checked },
                        { "disabled": disabled }
                    ])}
                    onChange={() => onChange(value)}
                    label={labelText}
                    title={title ? labelText : undefined}
                >
                    {children}
                </Checkbox>
            )}
        </div>
    );
}

CheckInput.propTypes = {
    type:  PropTypes.oneOf(["checkbox", "radio"]), // The input type (checkbox or radio).
    rootClassName: PropTypes.string, // The css class name of the root element.
    className: PropTypes.string, // The css class name of the input element.
    style:  PropTypes.object, // Override the inline-styles of the root element.
    labelText: PropTypes.string, // The content to use for label text.
    onChange: PropTypes.func, // Callback function that is fired when the input's value changes.
    name: PropTypes.string, // The name of the input.
    value: PropTypes.string, // The value of the input.
    checked: PropTypes.bool, // Enable / disable the check of the input.
    disabled: PropTypes.bool, // Disable the the input.
    colorType: PropTypes.oneOf(["none", "primary", "success", "info", "warning", "danger"]), // Color palette. Default: primary
    title: PropTypes.bool, // Show title in label. Default: false
};