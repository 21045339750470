import { Map, fromJS } from 'immutable';
import { defaultContext } from "@biuwer/redux/src/config/constants";

/**
 * Actions affecting a page: Page Metadata, Page Filters, Page Data
 */

import {
    SPACES_INITIALIZE, REMOVE_ALL_SPACES,
    SPACES_FETCH_REQUEST, SPACES_FETCH_SUCCESS, SPACES_FETCH_ERROR,
    SPACE_FETCH_REQUEST, SPACE_FETCH_SUCCESS, SPACE_FETCH_ERROR,
    MY_SPACE_FETCH_REQUEST, MY_SPACE_FETCH_SUCCESS, MY_SPACE_FETCH_ERROR,
    SPACE_CREATE_REQUEST, SPACE_CREATE_SUCCESS, SPACES_CREATE_SUCCESS, SPACE_CREATE_ERROR,
    SPACE_UPDATE_REQUEST, SPACE_UPDATE_SUCCESS, SPACE_UPDATE_ERROR,
    SPACE_DELETE_REQUEST, SPACE_DELETE_SUCCESS, SPACE_DELETE_ERROR, SPACE_DETAIL_INITIALIZE, SPACE_LIST_INITIALIZE,
} from './spaces-actions.js';

import {
    UPDATE_PROFILE_SUCCESS, CHANGE_OWNER_SUCCESS
} from "@biuwer/redux/src/system/security/security-actions";

// Actions of create and delete space contents
// import { COLLECTION_CREATE_SUCCESS, COLLECTION_DELETE_SUCCESS } from "@biuwer/redux/src/system/collections/collections-actions";

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

export default (state = initialState, action) => {
    let newState, index, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || defaultContext}`;
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch (action.type) {
        case SPACES_INITIALIZE:
        case REMOVE_ALL_SPACES:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));

            return newState;
        case SPACE_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;
        case SPACE_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;
        case SPACE_CREATE_REQUEST:

            newState = state;

            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case SPACE_CREATE_SUCCESS:

            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case SPACES_CREATE_SUCCESS:

            newState = state;

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.concat(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case SPACE_CREATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case SPACE_UPDATE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case SPACE_UPDATE_SUCCESS:

            // Update payload with updated user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            issue: action.issue,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case SPACE_UPDATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case SPACES_FETCH_REQUEST:

            newState = state;

            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    loaded: false
                }));

            return newState;
        case SPACES_FETCH_SUCCESS:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    payload: fromJS(action.payload),
                    loaded: true
                }));

            return newState;
        case SPACES_FETCH_ERROR:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload),
                    loaded: true
                }));

            return newState;
        case SPACE_FETCH_REQUEST:
        case MY_SPACE_FETCH_REQUEST:

            newState = state;

            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    loaded: false
                }));

            return newState;

        //TODO: modify when page metadata comes from API call
        case SPACE_FETCH_SUCCESS:
        case MY_SPACE_FETCH_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    payload: fromJS(action.payload),
                    loaded: true
                }));

            return newState;
        case SPACE_FETCH_ERROR:
        case MY_SPACE_FETCH_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload),
                    loaded: true
                }));

            return newState;
        case SPACE_DELETE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case SPACE_DELETE_SUCCESS:

            // Update payload with deleted data connection
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    isFetching: action.isFetching,
                    issue: action.issue,
                    deleted: action.deleted
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                            isFetching: action.isFetching,
                            issue: action.issue,
                            deleted: action.deleted
                        });
                }
            }

            return newState;
        case SPACE_DELETE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case UPDATE_PROFILE_SUCCESS:
        case CHANGE_OWNER_SUCCESS:

            newState = state;

            // Check objectType before to check if proceed to update reducer data
            if (action.objectType === 'space' && action.payload && action.payload.length > 0) {
                action.payload.forEach(object => {

                    if (String(state.getIn([detail, 'payload', '_id'])) === String(object._id)) {

                        // If payload is an Immutable Map and has the same _id that the action given object info
                        // Check permissions (platform and object profile_level) before update
                        if (object.readOneAuthorization || (!!object.profile_level && object.profile_level <= 4)) {

                            //Enough permissions to read, object updated
                            newState = newState.setIn([detail, 'payload', 'profiles'], fromJS(object.profiles));
                            newState = newState.setIn([detail, 'payload', 'profile_level'], object.profile_level);
                            newState = newState.setIn([detail, 'payload', 'owner'], fromJS(object.owner));
                        } else {

                            // Not enough permissions, delete payload from reducer
                            newState = state.setIn([detail, 'payload'], null);
                        }
                    }

                    if (state.getIn([list, 'payload'])) {
                        let index = state.getIn([list, 'payload']).findIndex(connection => String(connection.get('_id')) === String(object._id));
                        if (object.changed && index !== -1) {

                            // Check permissions of the object profiles updated and platform permissions
                            if (object.readAuthorization || (!!object.profile_level && object.profile_level <= 4)) {

                                // Enough permissions to read, profiles and profile_level is updated
                                newState = newState.setIn([list, 'payload', index, 'profiles'], fromJS(object.profiles));
                                newState = newState.setIn([list, 'payload', index, 'profile_level'], object.profile_level);
                                newState = newState.setIn([list, 'payload', index, 'owner'], fromJS(object.owner));
                            } else {

                                // Not enough permissions after profiles update, exclude object from List
                                newState = state.setIn([list, 'payload'], newState.getIn([list, 'payload']).filter(connection => String(connection.get('_id')) !== String(object._id)));
                            }
                        }
                    }
                });
            }
            return newState;

        default:
            return state;
    }
}
