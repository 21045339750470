import { getSessionData, checkLanguage, switchContentLanguage } from "../../system/auth/session-actions";

// Libs
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import gqlRequest from "@biuwer/core/src/graphql-request";
import { getLanguageToTranslate } from "@biuwer/common/src/libs/i18n-lib"

// GQL Schemas
import { AUTH_USER_SHARE } from "@biuwer/redux/src/system/auth/auth-gql";

/*
 * Resource action types
 */
export const RESOURCE_INITIALIZE = 'RESOURCE_INITIALIZE';

export const RESOURCE_FETCH_REQUEST = 'RESOURCE_FETCH_REQUEST';
export const RESOURCE_FETCH_SUCCESS = 'RESOURCE_FETCH_SUCCESS';
export const RESOURCE_FETCH_ERROR = 'RESOURCE_FETCH_ERROR';

// Generic Returns Skeleton
const generateSkeleton = (type, body, extraArgs) => {
    let skeleton = {
        type
    };

    switch (type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.issuePayload ? body.issuePayload.code : body.payload, // Backward compatibility with old error handling
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body
            };

            if (body.status === 2) {
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:

            break;
    }

    return skeleton;
};

/**
 * Initialize Resource
 */
export function initializeResource() {
    return {
        type: RESOURCE_INITIALIZE
    };
}

/**
 * Get resource action
 */
export function getResource(query = {}) {
    return async dispatch => {
        try {

            dispatch(generateSkeleton(RESOURCE_FETCH_REQUEST));

            // Get user of authToken and resource from server
            const { user, resource } = await gqlRequest({
                authToken: query.authToken,
                queryGql: `
                    user {
                        ${AUTH_USER_SHARE}
                    }
                    resource {
                        resourceId
                        resourceType
                    }
                `,
                queryType: "mutation",
                queryName: "getSharedResource",
                variables: [{
                    type: "GetSharedResourceInput!",
                    name: "getSharedResourceInput",
                    data: {
                        resourceTokenId: query.resourceToken
                    }
                }]
            });

            // Save user in auth
            Auth.setUser(user);

            // Dispatch getSessionData after signin
            dispatch(getSessionData(AUTH_USER_SHARE));

            dispatch(switchContentLanguage(getLanguageToTranslate()))

            // Check language from user and current organization
            const currentOrganization = Auth.getCurrentOrganization();
            checkLanguage(user.settings.language, !!currentOrganization.settings && currentOrganization.settings.language);

            dispatch(generateSkeleton(RESOURCE_FETCH_SUCCESS, resource));

        } catch (err) {
            dispatch(generateSkeleton(RESOURCE_FETCH_ERROR, err));
        }
    }
}
