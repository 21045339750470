import Auth from "@biuwer/redux/src/system/auth/auth-lib";

/**
 * Chat widget library
 * This library contains methods for Crisp widget chat
 * Crisp widget documnetation can be found at https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/dollar-crisp/
 *
 * */

/**
 * Initialization of Crisp chat widget.
 * Starting point is Hidden (not showing the widget) and Opened.
 */
const initializeWidget = () => {

    // Install Crisp script
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "fc505a68-b536-4bf7-b6af-ab072a01d806";

    (function() {
        let d = document;
        let s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
    })();


    // Set crisp safe mode in production environment
    setSafeMode()

    // Hide crisp at the begining.
    hideChat();
    // openChat();

    // Add events on chatbox widget
    addEventsOnChat();
}

/**
 * Function that check te status of the widget and returns it
 * @returns string with 2 possible values: opened or closed
 */
const getWidgetStatus = () => {
    let status = null;

    if (window.$crisp) {
        if (window.$crisp.is("chat:opened")) {
            status = 'opened';
        }

        if (window.$crisp.is("chat:closed")) {
            status = 'closed';
        }

    }

    return status;
}

/**
 * Function that check te visibility of the widget and returns it
 * @returns string with 2 possible values: visible or hidden
 */
const getWidgetVisibility = () => {
    let status = null;

    if (window.$crisp) {
        if (window.$crisp.is("chat:visible")) {
            status = 'visible';
        }

        if (window.$crisp.is("chat:hidden")) {
            status = 'hidden';
        }
    }

    return status;
}

/**
 * Open the chat widget (it can be visible or not)
 */
const openChat = () => {
    if (window.$crisp) {
        window.$crisp.push(["do", "chat:open"]);
    }
};

/**
 * Close the chat widget (it can be visible or not)
 */
const closeChat = () => {
    if (window.$crisp) {
        window.$crisp.push(["do", "chat:close"]);
    }
};

/**
 * Hide the chat widget
 */
const hideChat = () => {
    if (window.$crisp) {
        window.$crisp.push(["do", "chat:hide"]);
    }
};

/**
 * Show the chat widget
 */
const showChat = () => {
    if (window.$crisp) {
        window.$crisp.push(["do", "chat:show"]);
    }
};

/**
 * Toggle chat status taking care if chat widget is hidden
 */
 const toggleChat = () => {
    if (window.$crisp) {

        // Open & Show the widget if is hidden
        if (getWidgetVisibility() === 'hidden') {
            setTimeout(() => {
                openChat();
                showChat();
            }, 50)
        }

        // Hide the widget if is visible
        if (getWidgetVisibility() === 'visible') {
            setTimeout(() => {
                hideChat();
                openChat();
            }, 50)

        }
    }
};

/**
 * Identify user in crisp chat with user data (organization, orgId, userId, etc.)
 */
const identifyUserInCrisp = () => {
    const user = Auth.getUser(),
        masqueradeUser = Auth.getMasqueradeUser(),
        currentOrganization = Auth.getOrganizationInfo(),
        environment = process.env.NODE_ENV || "development",
        organizationName = currentOrganization.name,
        organizationId = currentOrganization._id,
        userName = masqueradeUser ? masqueradeUser.full_name : user.full_name,
        userId = masqueradeUser ? masqueradeUser._id : user._id,
        userEmail = masqueradeUser ? masqueradeUser.email : user.email,
        masqueradeUserName = masqueradeUser ? user.full_name : "",
        masqueradeUserId = masqueradeUser ? user._id : ""

    if (window.$crisp) {
        window.$crisp.push(["set", "user:email", [userEmail] ]);
        window.$crisp.push(["set", "user:nickname", [userName] ]);
        window.$crisp.push(["set", "user:company", [organizationName] ]);
        window.$crisp.push(["set", "user:avatar", ["https://image.crisp.chat/avatar/visitor/default/120/?avatar=default"]])
        window.$crisp.push(["set", "session:data", [[
            ["environment", environment],
            ["organizationName", organizationName],
            ["organizationId", organizationId],
            ["userName", userName],
            ["userId", userId],
            ["masqueradeUserName", masqueradeUserName],
            ["masqueradeUserId", masqueradeUserId]
        ]]]);
    }
}

/**
 * Add events to the widget chat
 */
const addEventsOnChat = () => {
    if (window.$crisp) {

        // Hide chat widget when the user closes it
        window.$crisp.push(["on", "chat:closed", () => {
                hideChat();
            }]);

        // Show chat widget when the user receives a new message on the chat
        window.$crisp.push(["on", "message:received", () => {
                showChat();
                openChat();
            }])
    }
};

/**
 * Set safe mode to crisp chat when we are not in development environment
 */
const setSafeMode = () => {
    if (window.$crisp && process.env.NODE_ENV !== 'development') {
        window.$crisp.push(["safe", true]);
    }
}

export default {
    initializeWidget,
    getWidgetStatus,
    getWidgetVisibility,
    openChat,
    closeChat,
    hideChat,
    showChat,
    toggleChat,
    identifyUserInCrisp
};