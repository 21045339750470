export default {
    "image": {
        "insert": "Insert image:",
        "url": "Image URL",
        "alt": "Alt text",
        "tooltip": "Insert image"
    },
    "align": {
        "title": "Align",
        "left": "Left align",
        "center": "Center align",
        "right": "Right align",
        "justify": "Justify",
        "outdent": "Outdent",
        "indent": "Indent"
    },
    "block": {
        "title": "Text block",
        "normal": "Normal",
        "large": "Large heading",
        "small": "Small heading",
        "bullet": "Bullet list",
        "numbered": "Numbered list",
        "quote": "Quote",
        "code": "Code",
        "codeLanguages": "Code languages"
    },
    "format": {
        "title": "Format text",
        "subscript": "Subscript",
        "superscript": "Superscript",
        "underline": "Underline",
        "strikethrough": "Strikethrough",
        "bold": "Bold",
        "italic": "Italic"
    },
    "fontFamily": "Font family",
    "fontSize": "Font size",
    "title": "Rich text",
    "placeholder": "Enter some text...",
    "link": "Insert link",
    "undo": "Undo",
    "redo": "Redo",
    "fontColor": "Font color",
    "backgroundColor": "Highlight color"
};