import React, { Component } from 'react';

//Components
import TextInput from '@biuwer/common/src/components/text-input';
import PasswordStrength from '@biuwer/common/src/components/password-strength';

// Libs
import Auth from "@biuwer/redux/src/system/auth/auth-lib";

class ResetPasswordForm extends Component {
    constructor(props) {
        super(props);

        this.state= {
            newPassword: '',
            newPasswordErrorMessage: '',
            repeatPasswordErrorMessage: '',
            repeatPassword: ''
        };

        this.onChangeTextField = this.onChangeTextField.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.validatePasswords = this.validatePasswords.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
        this.goTo = this.goTo.bind(this);
    }

    //Validate passwords
    validatePasswords(passwordA, passwordB){
        // const { t } = this.props;
        if(!passwordA || !Auth.passwordRegExp.complete.exec(passwordA) || !passwordB) {
            // this.setState({errorMessage: t('admin.users.incorrectLengthPasswordMessage', {count: 7}) });
            return false;
        }
        return passwordA === passwordB;
    }

    onChangeTextField(e) {
        const { t } = this.props;

        let newState = {
            ...this.state,
            [e.target.name]: e.target.value
        };

        if (e.target.name === 'newPassword') {
            newState.validPassword = !!Auth.passwordRegExp.complete.exec(e.target.value);
        }

        this.setState({
            ...newState
        }, () => {
            let { newPassword, repeatPassword } = this.state;

            if (newPassword && !newState.validPassword) {
                this.setState({newPasswordErrorMessage: t('admin.users.checkPasswordRequirementsMessage')});
            } else if (newPassword !== repeatPassword) {
                this.setState({
                    newPasswordErrorMessage: '',
                    repeatPasswordErrorMessage: t('admin.users.notMatchingPasswordsMessage')
                });
            } else {
                this.setState({
                    newPasswordErrorMessage: '',
                    repeatPasswordErrorMessage: ''
                });
            }
        });
    }

    resetPassword() {
        let { newPassword, repeatPassword } = this.state;

        if(this.canSubmit() && this.props.user.get('_id') && this.props.user.get('email')) {
            this.props.resetPassword(newPassword, repeatPassword, this.props.token, this.props.user.get('_id'), this.props.user.get('email'));
        }
    }

    canSubmit() {
        return this.validatePasswords(this.state.newPassword, this.state.repeatPassword);
    }

    goTo(path) {
        this.props.goTo(path);
    }

    render() {
        const { t } = this.props;
        return (
            <div className="animated fadeIn">
                <h4 className="p-t-25 text-center">{t('auth.resetPassword.headerTitle')}</h4>
                <div id="form-login" className="p-t-15">
                    <TextInput
                        formStyling="default"
                        type="password"
                        name="newPassword"
                        labelText={t('auth.resetPassword.passwordLabel')}
                        value={this.state.newPassword}
                        onChange={this.onChangeTextField}
                        errorText={this.state.newPasswordErrorMessage}
                    />

                    <TextInput
                        formStyling="default"
                        type="password"
                        name="repeatPassword"
                        labelText={t('auth.resetPassword.reEnterPasswordLabel')}
                        value={this.state.repeatPassword}
                        onChange={this.onChangeTextField}
                        errorText={this.state.repeatPasswordErrorMessage}
                    />

                    <PasswordStrength password={this.state.newPassword} validPassword={this.state.validPassword} t={t} />

                    <div>
                        <button
                            key='cancel-button'
                            type="button"
                            className="btn btn-sm btn-white mt5"
                            onClick={this.goTo.bind(this, '/signin')}
                        >
                            {t('common.cancelLabel').toUpperCase()}
                        </button>
                        <button
                            key="submit-button"
                            type="button"
                            className="btn btn-sm btn-info  ml15 mt5"
                            onClick={this.resetPassword}
                            disabled={!this.canSubmit()}
                        >
                            {t('auth.signinForm.submitButton').toUpperCase()}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResetPasswordForm;
