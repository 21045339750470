import datesLib from "@biuwer/common/src/libs/dates-lib";
import getDataType from "./get-data-type";

// WARNING!!!!! THIS FUNCTION HAS A COPY IN THE SERVER APP, MAKE SURE BOTH FUNCTIONS ARE UP TO DATE
export default function getDataCardFields(queries, calculatedFields, datasets) {

    const dataCardFields = [];

    if (!Array.isArray(queries) || !Array.isArray(calculatedFields)) return dataCardFields;

    // Add query fields
    queries.forEach((query) => {
        const queryFields = (query && query.fields) ? query.fields : [];
        queryFields.forEach(queryField => {
            const dataset = datasets && datasets.find(d => d._id === queryField.dataset);
            const datasetAlias = dataset && dataset.alias;
            dataCardFields.push({
                ...queryField,
                _id: `_${queryField.dataField._id}`,
                // Populated from dataField object
                dataField: queryField.dataField._id,
                calculated_field: queryField.dataField.calculated_field,
                alias: queryField.dataField.alias,
                ...datasetAlias && { datasetAlias },
                data_type: getDataType({ originalDataType: queryField.dataField.data_type, aggregation: queryField.aggregation }),
                original_data_type: queryField?.dataField?.original_data_type || queryField?.dataField?.data_type,
                //
                cfId: queryField._id,
                query: query.queryId,
                datamodel: query.datamodel,
                ...queryField.date_level && {
                    date_mask: datesLib.defaultDateFormats[queryField.date_level].date_mask
                }
            });
        });
    });

    // Add calculated fields
    calculatedFields.forEach(cf => {
        dataCardFields.push({
            ...cf,
            query: "CF",
            cfId: cf._id,
        });
    });

    return dataCardFields;

}
