
// Default query for groups actions
export const USERS_ROLES_GROUP_DETAIL = `
    _id
    name
    desc
    status
    group_type
    created
    updated
    users {
        _id
        name
        organization1
    }
    roles {
        _id
        name
    }
`;

// Default query for group detail page
export const GROUP_DETAIL_PAGE = `
    _id
    name
    desc
    status
    group_type
    created
    updated
    users {
        _id
        name
        full_name
        email
        type
        status
        org_status
        organization1
    }
    roles {
        _id
        name
        desc
        status
        role_type
    }
`;