// Libs
import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";

/**
 * Function to get data from server with graphQL
 * ignoreErrors -> prevent error throwing
 * queryGql -> GQL string with required schema fields
 * queryType -> GQL query type, only need on "mutation" type
 * queryName -> Resolver name
 * variables -> Array of resolver input variables
 *  variables.type -> Variable input type name
 *  variables.name -> Variable name
 *  variables.data -> Variable data
 * token -> JWT Token, only for override default token
 * authToken -> AuthToken id to login with it, only used by biuwer-share
 * onProgress -> Function if need do something on progress response event
 * @param {{ queryGql: String, queryType: "mutation" | "query", queryName: String, variables: Array, token: String, authToken: String, onProgress: Function }}
 * @returns {Promise}
 */
 export default async function gqlRequest({ ignoreErrors = false, queryGql, queryType = "query", queryName, variables = [], token, authToken, onProgress = () => {} } = {}) {
    try {

        // Sanity checks
        if (!queryName) throw new Error("Query name is mandatory");
        if (!Array.isArray(variables)) throw new Error("Variables must be an array");

        const getQueryTypeInputs = (variables) => variables.map(nextVar => `$${nextVar.name}: ${nextVar.type}`).join(", ");
        const getQueryNameInputs = (variables) => variables.map(nextVar => `${nextVar.name}: $${nextVar.name}`).join(", ");
        const getVariablesObject = (variables) => variables.reduce((acc, nextVar) => ({ ...acc, [nextVar.name]: nextVar.data }), {});

        const res = await request
            .post('/api/gql/')
            .send({
                query: `${queryType}${variables.length > 0 ? `(${getQueryTypeInputs(variables)})` : ""} {
                    ${queryName}${variables.length > 0 ? `(${getQueryNameInputs(variables)})` : ""} ${!!queryGql ? `{
                        ${queryGql}
                    }` : ""}
                }`,
                variables: getVariablesObject(variables)
            })
            .set('Authorization', `Bearer ${token ? token : Auth.getLocalJwt()}`)
            .set('auth-token', authToken)
            .set("app", Auth.getAuthApp())
            .on('response', (response) => Auth.checkResponse(response))
            .on('progress', onProgress);

        // If respones have errors return it
        if (!ignoreErrors && res?.body?.errors?.length > 0) throw res.body.errors[0];

        // Return data
        return res.body.data[queryName];
    } catch (err) {
        throw err;
    }
}