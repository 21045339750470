import { useEffect, useState } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from '@biuwer/core/src/i18n';
import moment from "moment";
import numeral from "numeral";
import { Helmet } from "react-helmet";

// Actions
import { validateResetPassword as checkToken, resetPassword, welcomeUser } from "@biuwer/redux/src/system/auth/password-actions";

// Components
import WelcomeForm from'./welcome-form.jsx';
import CustomButton from '@biuwer/common/src/components/custom-button.jsx';
import NotificationMessage from "@biuwer/common/src/components/notification-message.jsx"

// Libs
import HelmetLib from "@biuwer/common/src/libs/helmet-lib";

const Welcome = ({ location, history, match, user, checkToken, welcomeUser, checkTokenIsFetching, checkTokenSuccess, checkTokenError, checkTokenErrorMessage, resetPasswordError, resetPasswordErrorMessage, ...rest }) => {

    const { t, i18n } = useTranslation();

    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [orgName, setOrgName] = useState('');
    const [orgId, setOrgId] = useState(null);


    // Get token and language
    useEffect(() => {

        const welcomeParams = new URLSearchParams(location.search);
        onChangeLanguage(welcomeParams.get('language') || 'en');
        setOrgName(welcomeParams.get('orgName') || '');
        setOrgId(welcomeParams.get('orgId') || null);

        const token = (match.params && match.params.welcomeToken) || null;
        if (token) {
            checkToken(token);
        }
    }, []);

    useEffect(() => {
        if (user.get('_id') && user.get('status') === 'pending') {
            setShowPasswordForm(true);
        }
    }, [user]);

    const onChangeLanguage = (language) => {
        if (language === 'en' || language === 'es') {
            if (language !== i18n.language) {
                i18n.changeLanguage(language);
                moment.locale(language);
                numeral.locale(language);
            }
        }
        return language;
    };

    const submitAccess = () => {
        const token = (match.params && match.params.welcomeToken) || null;
        welcomeUser(token, Number(orgId), password, repeatPassword);
    };

    /**
     * render submit button with or without password form, based on user status
     */
    const submitRenderer = () => {
        if (!user || !user.get('_id')) return null;
        if (!showPasswordForm) {
            return (
                <div style={{ textAlign: 'center', marginTop: 10 }}>
                    <CustomButton intent="info" text={t('auth.resetPassword.accessButton')} onClick={submitAccess}/>
                </div>
            );
        } else {
            return (
            <div className="animated fadeIn">
                <p>{t('auth.welcomeUser.completeUserData')}</p>
                <WelcomeForm
                    password={password}
                    repeatPassword={repeatPassword}
                    setPassword={setPassword}
                    setRepeatPassword={setRepeatPassword}
                    onSubmit={submitAccess}
                />
            </div>
        )}
    };

    if (checkTokenError) {
        return (
            <NotificationMessage
                message={checkTokenErrorMessage}
                messageType="error"
                welcome={true}
                t={t}
                goTo={(path) => { history.push(path) }}
                access={() => {}}
            />
        );
    }

    if (resetPasswordError) {
        return (
            <NotificationMessage
                message={resetPasswordErrorMessage}
                messageType="error"
                welcome={true}
                t={t}
                goTo={(path) => { history.push(path) }}
                access={() => {}}
            />
        );
    }

    return (
        <div style={{ alignItems: 'center' }}>
            <Helmet
                title={HelmetLib.generatePageTitle([t('common.welcome')])}
            />
            <div className="animated fadeIn">
                <h3 style={{ textAlign: 'center' }}>{t('auth.welcomeUser.headerTitle')}</h3>
                <p className="text-center">{`${t('auth.welcomeUser.adminAddedYou')}`}</p>
                <h4 className="text-center" style={{ fontWeight: 'bold', margin: '15px 0px' }}>{orgName}</h4>
                {submitRenderer()}
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        checkToken: (token) => {
            dispatch(checkToken(token));
        },
        resetPassword: (newPassword, verifyPassword, token, userId, userEmail) => {
            dispatch(resetPassword(newPassword, verifyPassword, token, userId, userEmail));
        },
        welcomeUser: (token, orgId, password = null, repeatPassword = null) => {
            dispatch(welcomeUser(token, orgId, password, repeatPassword));
        }
    };
};

function mapStateToProps(state, ownProps) {

    // Can't access ownProps??
    // const { t } = ownProps;

    // Reducers
    const sessionReducer = state.system.auth.session;
    const checkTokenReducer = state.system.auth.checkToken;
    const welcomeUserReducer = state.system.auth.welcomeUser;

    // Session
    const isLoggedIn = sessionReducer.get('isLoggedIn');
    const userLoggedIn = sessionReducer.get('user') || Map({});


    // Check Token
    const checkTokenIsFetching = checkTokenReducer.get('isFetching'),
        checkTokenError = checkTokenReducer.get('error'),
        checkTokenErrorCode = checkTokenReducer.get('errorCode'),
        checkTokenSuccess = checkTokenReducer.get('success'),
        checkTokenPayload = checkTokenReducer.get('payload') || Map({});

    // Look for error code in 18n strings
    let checkTokenErrorMessage = checkTokenError ? i18n.t(`errors.${checkTokenErrorCode}`) : '';

    // Reset Password
    const welcomeUserIsFetching = welcomeUserReducer.get('isFetching'),
        welcomeUserError = welcomeUserReducer.get('error'),
        welcomeUserErrorCode = welcomeUserReducer.get('errorCode'),
        welcomeUserSuccess = welcomeUserReducer.get('success');

    // Look for error code in 18n strings
    let welcomeUserErrorMessage = welcomeUserError ? i18n.t(`errors.${welcomeUserErrorCode}`) : '';


    // Return via props
    let user = isLoggedIn ?  userLoggedIn : checkTokenPayload;

    return {
        isLoggedIn, user,
        checkTokenIsFetching, checkTokenSuccess, checkTokenError, checkTokenErrorMessage,
        welcomeUserIsFetching, welcomeUserSuccess, welcomeUserError, welcomeUserErrorMessage
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);