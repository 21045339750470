import {List, Map} from "immutable";
import {createSelector} from 'reselect';

// Payments redux state
const paymentsStateSelector = state => state.billing.payments;

const paymentReducerSelector = createSelector(
    paymentsStateSelector,
    (_, reduxContext) => reduxContext,
    (paymentsState, reduxContext) => (paymentsState && paymentsState.get(`detail_${reduxContext}`)) || Map({})
);

const paymentsReducerSelector = createSelector(
    paymentsStateSelector,
    (_, reduxContext) => reduxContext,
    (paymentsState, reduxContext) => (paymentsState && paymentsState.get(`list_${reduxContext}`)) || Map({})
);

const paymentSelector = createSelector(
    paymentReducerSelector,
    paymentReducer => paymentReducer.get('payload') || Map({})
);

const paymentsSelector = createSelector(
    paymentsReducerSelector,
    paymentReducer => paymentReducer.get('payload') || List([])
);

const paymentIsFetchingSelector = createSelector(
    paymentReducerSelector,
    paymentReducer => paymentReducer.get('isFetching')
);

const paymentsIsFetchingSelector = createSelector(
    paymentsReducerSelector,
    paymentReducer => paymentReducer.get('isFetching')
);

const paymentErrorSelector = createSelector(
    paymentReducerSelector,
    paymentReducer => paymentReducer.get('error')
);

const paymentsErrorSelector = createSelector(
    paymentsReducerSelector,
    paymentReducer => paymentReducer.get('error')
);

const paymentsCreatedSelector = createSelector(
    paymentsReducerSelector,
    paymentReducer => paymentReducer.get('created')
);

const paymentCreatedSelector = createSelector(
    paymentReducerSelector,
    paymentReducer => paymentReducer.get('created')
);

const paymentsUpdatedSelector = createSelector(
    paymentsReducerSelector,
    paymentReducer => paymentReducer.get('updated')
);

const paymentUpdatedSelector = createSelector(
    paymentReducerSelector,
    paymentReducer => paymentReducer.get('updated')
);


export {
    paymentSelector,
    paymentsSelector,
    paymentIsFetchingSelector,
    paymentsIsFetchingSelector,
    paymentErrorSelector,
    paymentsErrorSelector,
    paymentCreatedSelector,
    paymentsCreatedSelector,
    paymentUpdatedSelector,
    paymentsUpdatedSelector
}