import { Map, fromJS } from 'immutable';

import {
    AUTH_TOKENS_INITIALIZE, AUTH_TOKEN_DETAIL_INITIALIZE, AUTH_TOKEN_LIST_INITIALIZE,
    AUTH_TOKENS_FETCH_REQUEST, AUTH_TOKENS_FETCH_SUCCESS, AUTH_TOKENS_FETCH_ERROR,
    AUTH_TOKEN_FETCH_REQUEST, AUTH_TOKEN_FETCH_SUCCESS, AUTH_TOKEN_FETCH_ERROR,
    AUTH_TOKEN_UPDATE_REQUEST, AUTH_TOKEN_UPDATE_SUCCESS, AUTH_TOKEN_UPDATE_ERROR,
    AUTH_TOKEN_CREATE_REQUEST, AUTH_TOKEN_CREATE_SUCCESS, AUTH_TOKEN_CREATE_ERROR,
    AUTH_TOKEN_DELETE_REQUEST, AUTH_TOKEN_DELETE_SUCCESS, AUTH_TOKEN_DELETE_ERROR
} from './auth-tokens-actions';

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

/**
 * Auth Tokens Reducer
 */

const authTokens = (state = initialState, action) => {
    let newState, index = -1, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || `primary`}`;
    const detail = `detail_${(action && action.context) || `primary`}`;

    switch (action.type) {

        // Initialize
        case AUTH_TOKENS_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));
            return newState;

        case AUTH_TOKEN_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;

        case AUTH_TOKEN_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;

        // Auth Tokens Fetch (list)
        case AUTH_TOKENS_FETCH_REQUEST:

            newState = state;

            // Auth tokens fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case AUTH_TOKENS_FETCH_SUCCESS:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case AUTH_TOKENS_FETCH_ERROR:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;


        // Auth Token Fetch (detail)
        case AUTH_TOKEN_FETCH_REQUEST:

            newState = state;

            // Auth token fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case AUTH_TOKEN_FETCH_SUCCESS:

            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;

        case AUTH_TOKEN_FETCH_ERROR:

            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;


        // Create Auth Token
        case AUTH_TOKEN_CREATE_REQUEST:

            newState = state;

            // Delete created key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;

        case AUTH_TOKEN_CREATE_SUCCESS:

            // Update payload with new created user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;

        case AUTH_TOKEN_CREATE_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;


        // Update auth token
        case AUTH_TOKEN_UPDATE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;

        case AUTH_TOKEN_UPDATE_SUCCESS:

            // Update payload with updated user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            error: action.error,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;

        case AUTH_TOKEN_UPDATE_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;


        // Delete auth token
        case AUTH_TOKEN_DELETE_REQUEST:

            newState = state
                .mergeIn([detail], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;

        case AUTH_TOKEN_DELETE_SUCCESS:

            // Update payload with deleted user
            detailUpdatedPayload = fromJS(action.payload);
            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        deleted: action.deleted
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                            delete: Map({
                                isFetching: action.isFetching,
                                error: action.error,
                                deleted: action.deleted
                            })
                        });
                }
            }
            return newState;

        case AUTH_TOKEN_DELETE_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        default:
            return state;
    }
};

export default authTokens;