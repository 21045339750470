/**
 * Main jsx file - Biuwer
 *
 */
import "./init"
import React from "react"
import ReactDOM from "react-dom/client"

import { Provider } from 'react-redux'
import { configureStore } from "@biuwer/redux/src/config/store"
import indexedDB from "@biuwer/core/src/indexed-db"
import Auth from "@biuwer/redux/src/system/auth/auth-lib"

import moment from "moment"
import "moment/dist/locale/es"
import numeral from "numeral"
import { defaultLocale, languages, numeralFormats } from "@biuwer/core/src/i18n"

import "@biuwer/core/src/i18n"
import "@stripe/stripe-js"
import TagManager from "react-gtm-module"
import "@biuwer/core/src/init-segment"
import App from "./app"

// CSS
import "react-virtualized/styles.css"
import "@biuwer/core/src/theme/css/pages-icons.css"
import "react-tooltip/dist/react-tooltip.css"

// SASS / SCSS
import "@biuwer/core/src/theme/scss/index.scss"
import "@biuwer/core/src/theme/scss/_var.scss"
import "@biuwer/core/src/theme/scss/modules/_z_index.scss"

// Blueprintjs focus style manager
import { FocusStyleManager } from "@blueprintjs/core"

// Actions
import { getSessionData } from "@biuwer/redux/src/system/auth/session-actions"
import { systemVariables } from "@biuwer/redux/src/system/system-variables/system-variables-actions"

// Contexts
import { SystemVariablesContext } from "@biuwer/redux/src/system/system-variables/system-variables-context"
import { ProvideFeatureWallDialog } from "@biuwer/common/src/components/feature-wall-dialog"

import History from "@biuwer/core/src/history"
import { BIUWER_APP_NAME } from "@biuwer/core/src/constants"

// Prevent outline borders when click on blueprint components
FocusStyleManager.onlyShowFocusOnTabs()

// Locales for numeral and moment
languages.forEach((lang, index) => {
    if (!!numeralFormats[index]) {
        numeral.register("locale", lang, numeralFormats[index])
    }
})
moment.locale(defaultLocale)
numeral.locale(defaultLocale)

export const store = configureStore()

// Init Auth
Auth.initApp(BIUWER_APP_NAME)
Auth.init(store)

// App history initialization
History.init(BIUWER_APP_NAME)

const root = ReactDOM.createRoot(document.getElementById("root"))

indexedDB.initDB().then(() => {
    // Dispatch getSessionData
    store.dispatch(getSessionData())

    // Dispatch systemVariables
    store.dispatch(systemVariables())

    // Init GTM
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_KEY
    }
    TagManager.initialize(tagManagerArgs)

    root.render(
        <Provider store={store}>
            <SystemVariablesContext.Provider value={store && store.getState() && store.getState().system.systemVariables}>
                <ProvideFeatureWallDialog>
                    <App />
                </ProvideFeatureWallDialog>
            </SystemVariablesContext.Provider>
        </Provider>
    )
})
