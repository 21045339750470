import request from "@biuwer/common/src/libs/superagent";
import i18n from '@biuwer/core/src/i18n';

import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";
import { defaultContext } from "@biuwer/redux/src/config/constants";

import { API_KEYS_DETAIL } from "./api-keys-gql";

/*
 * API Keys action types
 */
export const API_KEYS_INITIALIZE = "API_KEYS_INITIALIZE";
export const API_KEY_DETAIL_INITIALIZE = "API_KEY_DETAIL_INITIALIZE";
export const API_KEY_LIST_INITIALIZE = "API_KEY_LIST_INITIALIZE";

export const API_KEYS_FETCH_REQUEST = "API_KEYS_FETCH_REQUEST";
export const API_KEYS_FETCH_SUCCESS = "API_KEYS_FETCH_SUCCESS";
export const API_KEYS_FETCH_ERROR = "API_KEYS_FETCH_ERROR";

export const API_KEY_FETCH_REQUEST = "API_KEY_FETCH_REQUEST";
export const API_KEY_FETCH_SUCCESS = "API_KEY_FETCH_SUCCESS";
export const API_KEY_FETCH_ERROR = "API_KEY_FETCH_ERROR";

export const API_KEY_CREATE_REQUEST = "API_KEY_CREATE_REQUEST";
export const API_KEY_CREATE_SUCCESS = "API_KEY_CREATE_SUCCESS";
export const API_KEY_CREATE_ERROR = "API_KEY_CREATE_ERROR";

export const API_KEY_UPDATE_REQUEST = "API_KEY_UPDATE_REQUEST";
export const API_KEY_UPDATE_SUCCESS = "API_KEY_UPDATE_SUCCESS";
export const API_KEY_UPDATE_ERROR = "API_KEY_UPDATE_ERROR";

export const API_KEY_DELETE_REQUEST = "API_KEY_DELETE_REQUEST";
export const API_KEY_DELETE_SUCCESS = "API_KEY_DELETE_SUCCESS";
export const API_KEY_DELETE_ERROR = "API_KEY_DELETE_ERROR";

// Generic Returns Skeleton
function generateSkeleton(type, body, context = defaultContext, showNotification = true) {
    let skeleton = { type }, notification;

    switch (type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false,
                context: context
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                context: context
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body,
                context: context
            };
            break;
        default:
            break;
    }

    switch(type) {
        case API_KEY_CREATE_SUCCESS:
            skeleton.created = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', {
                    name: i18n.t('apiKeys.title'),
                    context: 'female',
                    count: 1
                })
            };
            break;
        case API_KEY_UPDATE_SUCCESS:
            skeleton.updated = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', {
                    name: i18n.t('apiKeys.title'),
                    context: 'female',
                    count: 1
                })
            };
            break;
        case API_KEY_DELETE_SUCCESS:
            skeleton.deleted = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', {
                    name: i18n.t('apiKeys.title'),
                    context: 'female',
                    count: 1
                })
            };
            break;
        case API_KEY_FETCH_ERROR:
        case API_KEYS_FETCH_ERROR:
        case API_KEY_CREATE_ERROR:
        case API_KEY_UPDATE_ERROR:
        case API_KEY_DELETE_ERROR:
            skeleton.issuePayload = {
                ...skeleton.issuePayload || {},
                // code: extraArgs.code
            };
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    }
}

/**
 * Initialize API Keys
 */
export function initializeApiKeys() {
    return {
        type: API_KEYS_INITIALIZE
    };
}

/**
 * Initialize auth token detail action
 */
export function initializeApiKeysDetail() {
    return {
        type: API_KEY_DETAIL_INITIALIZE
    };
}

/**
 * Initialize auth token list action
 */
export function initializeApiKeysList() {
    return {
        type: API_KEY_LIST_INITIALIZE
    };
}

/**
 * Read the requested API Key
 * @param {String} apiKeyId
 * @param {String} context
 * @param {String} gql
 * @returns
 */
export function getApiKey(apiKeyId, context = defaultContext, gql = API_KEYS_DETAIL) {
    return async dispatch => {
        try {
            // TODO: API Call for one API Key
        } catch (err) {
            dispatch(generateSkeleton(API_KEY_FETCH_ERROR, err, context));
        }
    }
}

/**
 * Recover a list of API Keys based on given query object
 * @param {Object} query
 * @param {String} context
 * @param {String} gql
 * @returns
 */
export function queryApiKeys(context = defaultContext, gql = API_KEYS_DETAIL) {
    return async dispatch => {
        try {
            const graphQuery = {
                query: `query {
                    readApiTokens {
                        ${gql}
                    }
                }`
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(API_KEYS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(graphQuery)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(API_KEYS_FETCH_ERROR, 'Error getting api tokens data from server', context));
                } else if (res.body && res.body.data && res.body.data.readApiTokens) {
                    dispatch(generateSkeleton(API_KEYS_FETCH_SUCCESS, res.body.data.readApiTokens, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(API_KEYS_FETCH_ERROR, err, context));
        }
    }
}

/**
 * Create a new API Key with given data
 * @param {Object} apiKey
 * @param {String} context
 * @param {String} gql
 * @returns
 */
export function createApiKey(apiKey, context = defaultContext, gql = API_KEYS_DETAIL) {
    return async dispatch => {
        try {
            const query = {
                query: `mutation($apiKey: CreateApiTokenInput!) {
                    createApiToken(createApiTokenInput: $apiKey) {
                        ${gql}
                    }
                }`,
                variables: {
                    apiKey
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(API_KEY_CREATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(API_KEY_CREATE_ERROR, 'Error creating api token', context));
                } else if (res.body && res.body.data && res.body.data.createApiToken) {
                    dispatch(generateSkeleton(API_KEY_CREATE_SUCCESS, res.body.data.createApiToken, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(API_KEY_CREATE_ERROR, err, context));
        }
    }
}

/**
 * Updates existing API Key with given data
 * @param {Object} apiKey
 * @param {String} context
 * @param {String} gql
 * @returns
 */
export function updateApiKey(apiKey, context = defaultContext, gql = API_KEYS_DETAIL) {
    return async dispatch => {
        try {
            const query = {
                query: `mutation($apiKey: UpdateApiTokenInput!) {
                    updateApiToken(updateApiTokenInput: $apiKey) {
                        ${gql}
                    }
                }`,
                variables: {
                    apiKey
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(API_KEY_UPDATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(API_KEY_UPDATE_ERROR, 'Error creating api token', context));
                } else if (res.body && res.body.data && res.body.data.updateApiToken) {
                    dispatch(generateSkeleton(API_KEY_UPDATE_SUCCESS, res.body.data.updateApiToken, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(API_KEY_UPDATE_ERROR, err, context));
        }
    }
}

/**
 * Delete API Key with given id
 * @param {String} apiKeyId
 * @param {String} context
 * @param {String} gql
 * @returns
 */
export function deleteApiKey(apiKeyId, context = defaultContext, gql = API_KEYS_DETAIL) {
    return async dispatch => {
        try {

            const query = {
                query: `mutation($apiTokenId: String!) {
                    deleteApiToken(apiTokenId: $apiTokenId) {
                        ${gql}
                    }
                }`,
                variables: {
                    apiTokenId: apiKeyId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(API_KEY_DELETE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(API_KEY_DELETE_ERROR, 'Error deleting api token', context));
                } else if (res.body && res.body.data && res.body.data.deleteApiToken) {
                    dispatch(generateSkeleton(API_KEY_DELETE_SUCCESS, res.body.data.deleteApiToken, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(API_KEY_DELETE_ERROR, err, context));
        }
    };
}