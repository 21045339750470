import { combineReducers } from 'redux';

// Signup Reducer
import signup from "@biuwer/redux/src/system/auth/signup-reducers"

// Session Reducer
import session from "@biuwer/redux/src/system/auth/session-reducers";

// Password reducers
import { checkToken, resetPassword, forgotPassword, changePassword, welcomeUser } from "@biuwer/redux/src/system/auth/password-reducers";

export default combineReducers({
    signup,
    session,
    changePassword,
    forgotPassword,
    checkToken,
    resetPassword,
    welcomeUser
});
