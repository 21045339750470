import React from 'react';
import PropTypes from 'prop-types';
import { Resizable } from 'react-resizable';
// eslint-disable-next-line
// import styles from 'react-resizable/css/styles.css';

class resizableCell extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            width: props.width,
            height: props.height
        };

        this.onResize = this.onResize.bind(this);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.width !== this.props.width || nextProps.height !== this.props.height) {
            this.setState({
                width: nextProps.width,
                height: nextProps.height
            });
        }
    }

    onResize(e, data) {

        let { size } = data;

        if (this.props.onResize) {
            e.persist && e.persist();
            this.setState(size, ()=> this.props.onResize && this.props.onResize(e, data));
        } else {
            this.setState(size);
        }
    }

    render() {

        let { cellContent, handleSize, onResizeStart, onResizeStop, draggableOpts, minConstraints,
            maxConstraints, lockAspectRatio, axis } = this.props;

        return (
            <Resizable
                handleSize={handleSize}
                width={this.state.width}
                height={this.state.height}
                onResizeStart={onResizeStart}
                onResize={this.onResize}
                onResizeStop={onResizeStop}
                draggableOpts={draggableOpts}
                minConstraints={minConstraints}
                maxConstraints={maxConstraints}
                lockAspectRatio={lockAspectRatio}
                axis={axis}
                style={{ position: 'absolute', top: this.props.top, left: this.props.left }}
            >
                <div style={{ width: this.state.width, height: this.state.height }}>
                    {cellContent}
                </div>
            </Resizable>
        );
    }

}

resizableCell.propTypes = {
    top: PropTypes.number,
    left: PropTypes.number,
    handleSize: PropTypes.array,
    width: PropTypes.number,
    heigth: PropTypes.number,
    axis: PropTypes.string,
    onResize: PropTypes.func,
    cellContent: PropTypes.object
};

export default resizableCell;