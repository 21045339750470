import { decodeJwt } from "jose"

// System variables
export const SESSION_TOKEN = 'SESSION_TOKEN';

/**
 * Get system variables from localStorage
 */
export const getSystemVariablesFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem('bw.systemVariables'));
};

/**
 * Set new system variables in localStorage
 * @param systemVariables (object) - Object with new system variables
 */
export const setSystemVariablesToLocalStorage = (systemVariables) => {
    localStorage.setItem('bw.systemVariables', JSON.stringify(systemVariables));
};

/**
 * Set new value of system variable in localStorage
 * @param name (string) - Name of system variable to update
 * @param value (any) - New value of specified system variable
 */
export const setSystemVariableToLocalStorage = (name, value) => {
    localStorage.setItem('bw.systemVariables', JSON.stringify({
        ...getSystemVariablesFromLocalStorage(),
        [name]: value
    }));
};

/**
 * Delete system variable from localStorage
 * @param name (string) - Name of system variable to delete
 */
export const deleteSystemVariableFromLocalStorage = (name) => {
    let systemVariables = getSystemVariablesFromLocalStorage();
    delete(systemVariables[name]);
    setSystemVariablesToLocalStorage(systemVariables);
};

/**
 * Return base64 sessionToken based in user jwt token
 * @param jwtToken (string) - jwt token
 */
export const jwtTokenToSessionToken = (jwtToken) => {
    // const decoded = jwt.decode(jwtToken);
    const decoded = decodeJwt(jwtToken);

    const sessionToken = decoded && {
        user: decoded.user,
        organization: decoded.organization,
        iat: decoded.iat
    };

    return window.btoa(JSON.stringify(sessionToken));

};