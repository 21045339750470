import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import { defaultContext } from "@biuwer/redux/src/config/constants";

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { STEP_TYPES_LIST } from './step-types-gql';

/*
 * Step types action types
 */

export const STEP_TYPE_INITIALIZE = 'STEP_TYPE_INITIALIZE';
export const STEP_TYPE_DETAIL_INITIALIZE = 'STEP_TYPE_DETAIL_INITIALIZE';
export const STEP_TYPES_LIST_INITIALIZE = 'STEP_TYPES_LIST_INITIALIZE';

export const STEP_TYPES_FETCH_REQUEST = 'STEP_TYPES_FETCH_REQUEST';
export const STEP_TYPES_FETCH_SUCCESS = 'STEP_TYPES_FETCH_SUCCESS';
export const STEP_TYPES_FETCH_ERROR = 'STEP_TYPES_FETCH_ERROR';

export const STEP_TYPE_FETCH_REQUEST = 'STEP_TYPE_FETCH_REQUEST';
export const STEP_TYPE_FETCH_SUCCESS = 'STEP_TYPE_FETCH_SUCCESS';
export const STEP_TYPE_FETCH_ERROR = 'STEP_TYPE_FETCH_ERROR';

// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, showNotification = true, extraArgs) => {
    let skeleton = { type, context }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)){
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.issuePayload ? body.issuePayload.code : body.payload, // Backward compatibility with old error handling
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body.payload
            };

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:
            break;
    }

    switch(type){
        case STEP_TYPE_FETCH_SUCCESS:
        case STEP_TYPES_FETCH_SUCCESS:
            skeleton.payload = body;
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    }
};

/**
 * Get Step Types action
 */

export function getStepTypes(query = {}, context = defaultContext, gql = STEP_TYPES_LIST) {

    return async (dispatch) => {
        try {
            const gqlQuery = {
                query: `query($query: QueryStepTypesInput!) {
                    queryStepTypes(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query: query
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(STEP_TYPES_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(STEP_TYPES_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.queryStepTypes) {
                    dispatch(generateSkeleton(STEP_TYPES_FETCH_SUCCESS, res.body.data.queryStepTypes, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(STEP_TYPES_FETCH_ERROR, err, context));
        }
    };
}

/**
 * Get Step Type action
 */

export function getStepType(stepTypeId, context = defaultContext, gql = STEP_TYPES_LIST) {

    return async (dispatch) => {

        try {

            const gqlQuery = {
                query: `query($_id: Float!) {
                    readStepType(_id: $_id) {
                        ${gql}
                    }
                }`,
                variables: {
                    _id: stepTypeId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(STEP_TYPE_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(STEP_TYPE_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readStepType) {
                    dispatch(generateSkeleton(STEP_TYPE_FETCH_SUCCESS, res.body.data.readStepType, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(STEP_TYPE_FETCH_ERROR), err, context);
        }
    };
}

/**
 * Initialize Step Type
 */
export const initializeStepType = (context = defaultContext) => {
    return {
        type: STEP_TYPE_INITIALIZE,
        context: context
    };
};

/**
 * Initialize Step Types List
 */
export const initializeStepTypesList = (context = defaultContext) => {
    return {
        type: STEP_TYPES_LIST_INITIALIZE,
        context: context
    };
};

/**
 * Initialize Step Type Detail
 */
export const initializeStepTypeDetail = (context = defaultContext) => {
    return {
        type: STEP_TYPE_DETAIL_INITIALIZE,
        context: context
    };
};