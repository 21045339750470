import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import createReducer from './reducers.js';
import Immutable from 'immutable';

// TODO: Remove logger when unused due to ReduxDevTools installation
const logger = createLogger({
    collapsed: true,

    // Transform immutable objects into JSON
    transformer: (state) => {
        state = Immutable.fromJS(state);
        return state.toJS();
    }
});

// Set redux middleware based on environment
let middleware = [thunkMiddleware];
let composeEnhancers;
if (process.env.NODE_ENV !== 'production') {
    middleware = [...middleware, logger];

    // Activating ReduxDevTools
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
    middleware = [...middleware];
    composeEnhancers = compose;
}

/**
 * Creates a new redux sore
 * @param initialState
 * @returns {Store<any> | (Store<any, Action> & *)}
 */
export function configureStore(initialState) {
    const store = createStore(createReducer(), initialState, composeEnhancers(
        applyMiddleware(...middleware)
    ));
    store.asyncReducers = {};
    return store;
}

/**
 * Injects new reducer to redux
 * Used by custom cards
 * @param store
 * @param name
 * @param asyncReducer
 */
export function injectAsyncReducer(store, name, asyncReducer) {
    store.asyncReducers[name] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
}