import moment from "moment"
import floor from "lodash/floor"
import { jsPDF } from "jspdf"

import { getPrintPageSizeInPixels, onCloneCallback, printElement } from "./exports-lib"
import { CARD_SCREEN_BODY_ID, COLLECTION_TABS_CLASS, FILTERS_AREA_CLASS, PAGE_BODY_ID, PAGE_CONTAINER_CLASS, PAGE_HEADER_ID } from "./content-constants"

const CONTENT_MARGIN_MULTIPLIER = 15

/**
 *
 * @param {"A4" | "A3" | "A2" | "A1"} pageSize
 * @param {"portrait" | "landscape"} orientation
 * @param {Boolean} showHeader
 * @returns {{ width: Number, height: Number }}
 */
export function getPageHeaderSize(pageSize = "A4", orientation = "portrait", showHeader = false) {

    const pdfSize = getPrintPageSizeInPixels(pageSize, orientation)
    return {
        width: pdfSize.width,
        height: showHeader ? 18 : 0
    }
}

/**
 *
 * @param {"A4" | "A3" | "A2" | "A1"} pageSize
 * @param {"portrait" | "landscape"} orientation
 * @param {Boolean} showFooter
 * @returns {{ width: Number, height: Number }}
 */
export function getPageFooterSize(pageSize = "A4", orientation = "portrait", showFooter = false) {

    const pdfSize = getPrintPageSizeInPixels(pageSize, orientation)
    return {
        width: pdfSize.width,
        height: showFooter ? 24 : 15
    }
}

/**
 *
 * @param {Object} options
 * @param {"A4" | "A3" | "A2" | "A1"} options.pageSize
 * @param {"portrait" | "landscape"} options.orientation
 * @param {Document} options.content
 * @param {String} options.entityType
 * @param {Number} options.entityId
 * @param {Boolean} options.fromPage
 * @param {Array<String>} options.margins
 * @param {Boolean} options.showHeader
 * @param {{ left: String, center: String, right: String }} options.headerContent
 * @param {Boolean} options.showFooter
 * @param {{ left: String, center: String, right: String }} options.footerContent
 * @param {Boolean} options.includeHeader
 * @param {Boolean} options.includeFilters
 * @param {Boolean} options.unrollCardContent
 * @param {String} options.cardType
 * @returns {Promise<Blob>}
 */
export async function getPdfFromVisualization({
    pageSize = "A4", orientation = "portrait",
    content,
    entityType, entityId, entityName, fromPage,
    margins = [1, 1, 1, 1],
    showHeader = false, headerContent = {}, showFooter = false, footerContent = {},
    includeHeader = false, includeFilters = false, unrollCardContent = false, cardType, contentType
}) {
    try {

        const pdfPageSize = getPrintPageSizeInPixels(pageSize, orientation)
        const pageAvailableContent = calculateAvailableContentSize({ pageSize, orientation, showHeader, showFooter, margins })

        const elementsToPrint = []
        const pageContainer = content.getElementsByClassName(PAGE_CONTAINER_CLASS)[0]
        let contentElement = document.getElementById(PAGE_BODY_ID)
        if (entityType === "card") {
            contentElement = fromPage ? document.getElementById(`card-${entityId}`) : document.getElementById(CARD_SCREEN_BODY_ID)
        }
        if (entityType === "collection") {
            contentElement = contentType === "card" ? content.getElementById(CARD_SCREEN_BODY_ID) : document.getElementById(PAGE_BODY_ID)
        }
        const headerElement = document.getElementById(PAGE_HEADER_ID)
        const filtersElements = document.getElementsByClassName(FILTERS_AREA_CLASS)

        if (entityType === "card") {
            if (includeHeader && !fromPage && headerElement) {
                const header = await printElement(
                    pageContainer,
                    (document, element) => onCloneCallback(document, element, { entityType, entityId, fromPage, itemToRender: "header" })
                )

                elementsToPrint.push(header)
            }
            if (includeFilters && !fromPage && filtersElements?.length > 0) {
                for(let filtersElement of filtersElements) {
                    const filters = await printElement(
                        filtersElement,
                        (document, element) => onCloneCallback(document, element, { entityType, entityId, fromPage, itemToRender: "filters" })
                    )
                    elementsToPrint.push(filters)
                }
            }

            if (unrollCardContent) {
                if (cardType === "Custom") contentElement = contentElement.children[0].children[0].children[1].children[0]
            }

            const result = await printElement(
                contentElement,
                (document, element) => onCloneCallback(document, element, { entityType, entityId, fromPage, itemToRender: "content" })
            )

            elementsToPrint.push(result)
        } else if (entityType === "page") {
            if (includeHeader && headerElement) {
                const header = await printElement(
                    pageContainer,
                    (document, element) => onCloneCallback(document, element, { entityType, entityId, fromPage, itemToRender: "header" })
                )

                elementsToPrint.push(header)
            }
            if (includeFilters && filtersElements[0]) {
                const filters = await printElement(
                    filtersElements[0],
                    (document, element) => onCloneCallback(document, element, { entityType, entityId, fromPage, itemToRender: "filters" })
                )

                elementsToPrint.push(filters)
            }

            let contentRowIndex = 0
            for (let rowElement of contentElement.children) {
                if (rowElement.clientHeight > pageAvailableContent.height) {
                    let subRowCards = [], subRowsStructure = [], prevCardsWidth = 0, rowCardIndex = 0
                    const rowCards = rowElement.children[0]?.children[0]?.children
                    for (let rowCard of rowCards) {
                        const updatedSubRowWidth = rowCard.clientWidth + prevCardsWidth
                        if (updatedSubRowWidth > (rowElement.clientWidth % 2 === 1 ? rowElement.clientWidth + 1 : rowElement.clientWidth)) {
                            if (subRowCards.length > 0) subRowsStructure.push(subRowCards)
                            subRowCards = [rowCardIndex]
                            prevCardsWidth = rowCard.clientWidth
                        } else {
                            subRowCards.push(rowCardIndex)
                            prevCardsWidth += rowCard.clientWidth
                        }
                        if (rowCards.length - 1 === rowCardIndex) {
                            if (subRowCards.length > 0) subRowsStructure.push(subRowCards)
                        } else {
                            rowCardIndex++
                        }
                    }

                    for (const subRowStructure of subRowsStructure) {
                        const subRow = await printElement(
                            rowElement,
                            (document, element) => onCloneCallback(document, element, { entityType, entityId, fromPage, itemToRender: "content-row", contentRowIndex, subRowStructure }) // eslint-disable-line
                        )
                        elementsToPrint.push(subRow)
                    }
                    contentRowIndex++
                } else {
                    const row = await printElement(
                        rowElement,
                        (document, element) => onCloneCallback(document, element, { entityType, entityId, fromPage, itemToRender: "content-row", contentRowIndex }) // eslint-disable-line
                    )
                    elementsToPrint.push(row)
                    contentRowIndex++
                }
            }
        } else if (entityType === "collection") {
            if (includeHeader && headerElement) {
                const header = await printElement(
                    headerElement,
                    (document, element) => onCloneCallback(document, element, { entityType, entityId, fromPage, itemToRender: "header", contentType })
                )

                elementsToPrint.push(header)

                const collectionTabsElement = document.querySelector(`.${COLLECTION_TABS_CLASS}`)
                const collectionTabs = await printElement(
                    collectionTabsElement,
                    (document, element) => onCloneCallback(document, element, { entityType, entityId, fromPage, itemToRender: "collection-tabs", contentType })
                )
                elementsToPrint.push(collectionTabs)
            }

            if (includeFilters && filtersElements?.length > 0) {
                for(let filtersElement of filtersElements) {
                    const filters = await printElement(
                        filtersElement,
                        (document, element) => onCloneCallback(document, element, { entityType, entityId, fromPage, itemToRender: "filters", contentType })
                    )
                    elementsToPrint.push(filters)
                }
            }
            if (contentType === "card") {
                const result = await printElement(
                    contentElement,
                    (document, element) => onCloneCallback(document, element, { entityType, entityId, fromPage, itemToRender: "content", contentType })
                )
                elementsToPrint.push(result)
            } else {
                let contentRowIndex = 0
                for (let rowElement of contentElement.children) {
                    if (rowElement.clientHeight > pageAvailableContent.height) {
                        let subRowCards = [], subRowsStructure = [], prevCardsWidth = 0, rowCardIndex = 0
                        const rowCards = rowElement.children[0]?.children[0]?.children
                        for (let rowCard of rowCards) {
                            const updatedSubRowWidth = rowCard.clientWidth + prevCardsWidth
                            if (updatedSubRowWidth > (rowElement.clientWidth % 2 === 1 ? rowElement.clientWidth + 1 : rowElement.clientWidth)) {
                                if (subRowCards.length > 0) subRowsStructure.push(subRowCards)
                                subRowCards = [rowCardIndex]
                                prevCardsWidth = rowCard.clientWidth
                            } else {
                                subRowCards.push(rowCardIndex)
                                prevCardsWidth += rowCard.clientWidth
                            }
                            if (rowCards.length - 1 === rowCardIndex) {
                                if (subRowCards.length > 0) subRowsStructure.push(subRowCards)
                            } else {
                                rowCardIndex++
                            }
                        }

                        for (const subRowStructure of subRowsStructure) {
                            const subRow = await printElement(
                                rowElement,
                                (document, element) => onCloneCallback(document, element, { entityType, entityId, fromPage, itemToRender: "content-row", contentRowIndex, subRowStructure, contentType }) // eslint-disable-line
                            )
                            elementsToPrint.push(subRow)
                        }
                        contentRowIndex++
                    } else {
                        const row = await printElement(
                            rowElement,
                            (document, element) => onCloneCallback(document, element, { entityType, entityId, fromPage, itemToRender: "content-row", contentRowIndex, contentType }) // eslint-disable-line
                        )
                        elementsToPrint.push(row)
                        contentRowIndex++
                    }
                }
            }
        } else {
            throw new Error("UNKOWN_EXPORT_ENTITY_TYPE")
        }

        const pdfInstance = new jsPDF({
            orientation: orientation,
            format: pageSize,
            unit: "px",
            compress: true,
            hotfixes: ["px_scaling"]
        })

        const pageContentMargins = {
            top: margins[0] * CONTENT_MARGIN_MULTIPLIER,
            bottom: margins[2] * CONTENT_MARGIN_MULTIPLIER,
            left: margins[3] * CONTENT_MARGIN_MULTIPLIER,
            right: margins[1] * CONTENT_MARGIN_MULTIPLIER
        }

        const pageHeaderSize = getPageHeaderSize(pageSize, orientation, showHeader)
        const pageFooterSize = getPageFooterSize(pageSize, orientation, showFooter)

        let currentContentSize = {
            width: pageAvailableContent.width,
            height: pageContentMargins.top + pageHeaderSize.height + pageFooterSize.height
        }

        for (const [index, elementToPrint] of elementsToPrint.entries()) {
            let elementWidth = elementToPrint.width,
                elementHeight = elementToPrint.height

            // Check if element size has to be downscaled to fit on available space for content
            if (elementWidth > currentContentSize.width/*  && !unrollCardContent */) {
                elementWidth = (elementWidth * floor(currentContentSize.width/elementToPrint.width, 2)) - pageContentMargins.left - pageContentMargins.right
                const aspectRatioCorrection = elementWidth / elementToPrint.width
                elementHeight = elementHeight * aspectRatioCorrection
            }

            // Add page to document if element cannot fit entirely in current page
            let mustAddPage = index > 0 && (currentContentSize.height + elementHeight + pageContentMargins.bottom) * 1.05 > pdfPageSize.height
            let mustSplitElement = entityType === "card" && unrollCardContent
            let position = 0, pageOfCurrentNode = 1
            if (mustSplitElement) {
                let leftHeight = elementHeight
                while(leftHeight > 0) {
                    pdfInstance.addImage(
                        elementToPrint.canvas.toDataURL("image/png", 1),
                        "PNG",
                        pageContentMargins.left,
                        position +
                            ((pageContentMargins.top + pageHeaderSize.height) * pageOfCurrentNode) +
                            ((pageContentMargins.bottom + pageFooterSize.height) * (pageOfCurrentNode - 1)),
                        elementWidth,
                        elementHeight
                    )

                    pdfInstance.setFillColor(255, 255, 255);
                    pdfInstance.rect(0, 0, pdfInstance.internal.pageSize.width, (pageContentMargins.top + pageHeaderSize.height), 'F');
                    pdfInstance.rect(0, pdfInstance.internal.pageSize.height - (pageContentMargins.bottom + pageFooterSize.height), pdfInstance.internal.pageSize.width, (pageContentMargins.bottom + pageFooterSize.height), 'F');

                    if (leftHeight < pageAvailableContent.height) {
                        position -= leftHeight
                        break;
                    } else {
                        leftHeight -= pageAvailableContent.height
                        position -= pdfPageSize.height
                        pdfInstance.addPage({
                            format: pageSize,
                            orientation: orientation
                        })
                        currentContentSize.height = pageHeaderSize.height + pageFooterSize.height + pageContentMargins.top
                        pageOfCurrentNode++
                    }
                }
            } else {
                if (mustAddPage) {
                    pdfInstance.addPage({
                        format: pageSize,
                        orientation: orientation
                    })
                    currentContentSize.height = pageHeaderSize.height + pageFooterSize.height + pageContentMargins.top
                }

                pdfInstance.addImage(
                    elementToPrint.canvas.toDataURL("image/png", 1),
                    "PNG",
                    pageContentMargins.left,
                    currentContentSize.height,
                    elementWidth,
                    elementHeight
                )
            }
            currentContentSize.height += elementHeight
        }

        if (showHeader || showFooter || footerContent.generatedBy) {
            pdfInstance.setFontSize(8)
            const totalPages = pdfInstance.internal.getNumberOfPages()

            const pdfInfo = {
                name: entityName,
                numTotalPages: totalPages,
                date: moment().format("DD/MM/YYYY, HH:mm")
            }

            for (let numPage = 1; numPage <= totalPages; numPage++) {
                pdfInstance.setPage(numPage)
                const pageSize = pdfInstance.internal.pageSize;
                const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
                const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

                pdfInfo.numPage = numPage

                if (showHeader) {
                    const left = headerContent.left(pdfInfo)
                    const center = headerContent.center(pdfInfo), centerWidth = Number(pdfInstance.getTextWidth(center).toFixed(2))
                    const right = headerContent.right(pdfInfo), rightWidth = Number(pdfInstance.getTextWidth(right).toFixed(2))
                    pdfInstance.text(left, 15, 15, { baseline: "top" })
                    pdfInstance.text(center, pageWidth / 2 - (centerWidth / 2), 15, { baseline: "top" })
                    pdfInstance.text(right, pageWidth - rightWidth - 15, 15, { baseline: "top" })
                }
                if (showFooter) {
                    const left = footerContent.left(pdfInfo)
                    const center = footerContent.center(pdfInfo), centerWidth = Number(pdfInstance.getTextWidth(center).toFixed(2))
                    const right = footerContent.right(pdfInfo), rightWidth = Number(pdfInstance.getTextWidth(right).toFixed(2))
                    pdfInstance.text(left, 15, pageHeight - 20, { baseline: "bottom" })
                    pdfInstance.text(center, pageWidth / 2 - (centerWidth / 2), pageHeight - 20, { baseline: 'bottom' })
                    pdfInstance.text(right, pageWidth - rightWidth - 15, pageHeight - 20, { baseline: "bottom" })
                }
                if (footerContent.generatedBy) {
                    const generatedBy = footerContent.generatedBy, generatedByWidth = Number(pdfInstance.getTextWidth(generatedBy).toFixed(2))
                    pdfInstance.text(generatedBy, pageWidth / 2 - (generatedByWidth / 2), pageHeight - 5, { baseline: "bottom" })
                }
            }
        }

        return pdfInstance.output("blob")
    } catch (err) {
        throw err
    }
}

/**
 *
 * @param {Object} params
 * @param {String} params.pageSize
 * @param {String} params.orientation
 * @param {Boolean} params.showHeader
 * @param {Boolean} params.showFooter
 * @param {Array<Number>} params.margins
 * @returns {{ height: Number, width: Number }}
 */
export function calculateAvailableContentSize({ pageSize, orientation, showHeader, showFooter, margins }) {
    const pageSizeInPixels = getPrintPageSizeInPixels(pageSize, orientation)

    const pageHeaderSize = getPageHeaderSize(pageSize, orientation, showHeader)
    const pageFooterSize = getPageFooterSize(pageSize, orientation, showFooter)


    const availableHeight = pageSizeInPixels.height - margins[0] - margins[2] - pageHeaderSize.height - pageFooterSize.height
    const availableWidth = pageSizeInPixels.width - margins[1] - margins[3]

    return {
        height: availableHeight,
        width: availableWidth
    }
}
