import { useState, useMemo } from 'react';
import { Icon } from "@blueprintjs/core";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
    colorInfo, colorInfoLight,
    colorMasterDark, colorMasterLight, colorMasterLighter,
    colorSuccess, colorSuccessLight,
    colorWarning, colorWarningLight,
    colorDanger, colorDangerLight,
    colorWhite
} from '@biuwer/core/src/theme/scss/_var.scss?export';

// Components
import CustomTooltip from './custom-tooltip';

const defaultTooltipOptions = {
    position: 'bottom',
    contentSize: 'customButton',
    fitChildrenContent: true
};

// Function to set colors
const onChangeColors = (intent, color, hoverColor, bgColor, hoverBgColor, borderColor, hoverBorderColor, isHoverable, isDisabled, isActive, isHover) => {

    if (intent && intents[intent]) {
        if (isDisabled || isActive || isHover) {
            return {
                color: intents[intent].hoverColor,
                backgroundColor: intents[intent].hoverBgColor,
                borderColor: intents[intent].hoverBorderColor,
            };
        }

        return {
            color: intents[intent].color,
            backgroundColor: intents[intent].bgColor,
            borderColor: intents[intent].borderColor,
        };

    }

    if (isDisabled || (isHoverable && (isActive || isHover))) {
        return {
            color: hoverColor || color,
            backgroundColor: hoverBgColor || bgColor,
            borderColor: hoverBorderColor || borderColor || hoverBgColor || bgColor
        };
    }

    return {
        color: color,
        backgroundColor: bgColor,
        borderColor: borderColor || bgColor
    };

};

// Predefined sets of colors
const intents = {
    info: {
        color: colorWhite,
        bgColor: colorInfo,
        borderColor: colorInfo,
        hoverColor: colorWhite,
        hoverBgColor: colorInfoLight,
        hoverBorderColor: colorInfoLight
    },
    cancel: {
        color: '#5e5e5e',
        bgColor: colorWhite,
        borderColor: colorMasterLighter,
        hoverColor: colorMasterDark,
        hoverBgColor: colorMasterLighter,
        hoverBorderColor: colorMasterLight
    },
    success: {
        color: colorWhite,
        bgColor: colorSuccess,
        borderColor: colorSuccess,
        hoverColor: colorWhite,
        hoverBgColor: colorSuccessLight,
        hoverBorderColor: colorSuccessLight
    },
    warning: {
        color: colorWhite,
        bgColor: colorWarning,
        borderColor: colorWarning,
        hoverColor: colorWhite,
        hoverBgColor: colorWarningLight,
        hoverBorderColor: colorWarningLight
    },
    danger: {
        color: colorWhite,
        bgColor: colorDanger,
        borderColor: colorDanger,
        hoverColor: colorWhite,
        hoverBgColor: colorDangerLight,
        hoverBorderColor: colorDangerLight
    }
};

const CustomButton = ({
    text, isBold,
    icon, rightIcon, iconSize, iconTitle, iconStyles,
    intent, color = '#5e5e5e', bgColor = 'white', borderColor,
    isHoverable, isActive, onClick,
    hoverColor, hoverBgColor = colorMasterLighter, hoverBorderColor,
    style, isDisabled,
    tooltip, tooltipOptions = defaultTooltipOptions,
    className, children
}) => {

    // Component state
    const [isHover, setIsHover] = useState(false);
    const buttonColors = useMemo(() => {
        return onChangeColors(intent, color, hoverColor, bgColor, hoverBgColor, borderColor, hoverBorderColor, isHoverable, isDisabled, isActive, isHover)
    }, [intent, color, hoverColor, bgColor, hoverBgColor, borderColor, hoverBorderColor, isHoverable, isDisabled, isActive, isHover])

    const buttonBody = (
        <div
            className={classnames([
                "custom-button",
                { [`custom-button-${intent}`]: intent != null },
                className ?? ""
            ])}
            style={{
                display: 'inline-flex',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                padding: '6px 12px',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                border: '1px solid',
                borderRadius: 3,
                transition: 'all 0.4s',
                color: buttonColors.color,
                backgroundColor: buttonColors.backgroundColor,
                borderColor: buttonColors.borderColor,
                ...style
            }}
            onClick={(event) => isDisabled ? null : onClick?.(event)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            {icon && (
                <Icon
                    icon={icon}
                    size={iconSize}
                    color={buttonColors.color}
                    title={iconTitle}
                    style={iconStyles}
                />
            )}
            {text && <span style={{
                ...isBold ? { fontWeight: 'bold' } : null,
                ...icon ? { marginLeft: 5 } : null,
                ...rightIcon ? { marginRight: 5 } : null
            }}>{typeof text === 'function' ? text() : text}</span>}
            {!text && children && (
                <span style={{
                    ...isBold ? { fontWeight: 'bold' } : null,
                    ...icon ? { marginLeft: 5 } : null,
                    ...rightIcon ? { marginRight: 5 } : null
                }}>{children}</span>
            )}
            {rightIcon && (
                <Icon
                    icon={rightIcon}
                    size={iconSize}
                    color={buttonColors.color}
                    style={iconStyles}
                />
            )}
        </div>
    );

    return (
        tooltip ? (
            <CustomTooltip
                content={tooltip}
                { ...defaultTooltipOptions }
                { ...tooltipOptions }
            >
                {buttonBody}
            </CustomTooltip>
        ) : buttonBody
    );
};

CustomButton.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]), // Button text to show
    isBold: PropTypes.bool, // Indicates if text is bold or not
    icon: PropTypes.string, // Blueprintjs icon name, left position
    rightIcon: PropTypes.string, // Blueprintjs icon name, right position
    iconTitle: PropTypes.string,
    iconStyles: PropTypes.object,
    intent: PropTypes.string, // Class name colors
    color: PropTypes.string, // Text and icons color
    bgColor: PropTypes.string, // Background color
    borderColor: PropTypes.string, // Border color
    isHoverable: PropTypes.bool, // Indicates if color can change on hover
    isActive: PropTypes.bool, // Indicates if change color on external prop
    hoverColor: PropTypes.string, // Text and icons hover color
    hoverBgColor: PropTypes.string, // Background hover color
    hoverBorderColor: PropTypes.string, // Border hover color
    onClick: PropTypes.func, // onClick function
    style: PropTypes.object, // Custom button style
    isDisabled: PropTypes.bool, // Indicates if button is disabled
    tooltip: PropTypes.node, // Content of tooltip, can be rendered: numbers, strings, elements or an array
    tooltipOptions: PropTypes.shape({ // Tooltip props options
        position: PropTypes.string,
        contentSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        fitChildrenContent: PropTypes.bool,
        className: PropTypes.string,
        contentStyle: PropTypes.object,
        theme: PropTypes.string,
        usePortal: PropTypes.bool,
        intent: PropTypes.string,
        isOpen: PropTypes.bool
    })
};

export default CustomButton;
