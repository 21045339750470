import { isNil } from 'lodash';
import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import History from "@biuwer/core/src/history";
import gqlRequest from '@biuwer/core/src/graphql-request';
import i18n from "@biuwer/core/src/i18n";

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { defaultContext } from "@biuwer/redux/src/config/constants";
import {DATAMODEL_LIST, DATAMODEL_DETAIL, QUERY_STRING} from './datamodels-gql';

/*
 * Datamodel action types
 */

export const DATAMODELS_FETCH_REQUEST = 'DATAMODELS_FETCH_REQUEST';
export const DATAMODELS_FETCH_SUCCESS = 'DATAMODELS_FETCH_SUCCESS';
export const DATAMODELS_FETCH_ERROR = 'DATAMODELS_FETCH_ERROR';

export const DATAMODELS_INITIALIZE = 'DATAMODELS_INITIALIZE';
export const DATAMODELS_INITIALIZE_LIST = 'DATAMODELS_INITIALIZE_LIST';
export const DATAMODELS_INITIALIZE_DETAIL = 'DATAMODELS_INITIALIZE_DETAIL';

export const DATAMODEL_FETCH_REQUEST = 'DATAMODEL_FETCH_REQUEST';
export const DATAMODEL_FETCH_SUCCESS = 'DATAMODEL_FETCH_SUCCESS';
export const DATAMODEL_FETCH_ERROR = 'DATAMODEL_FETCH_ERROR';

export const DATAMODEL_CREATE_REQUEST = 'DATAMODEL_CREATE_REQUEST';
export const DATAMODEL_CREATE_SUCCESS = 'DATAMODEL_CREATE_SUCCESS';
export const DATAMODEL_CREATE_ERROR = 'DATAMODEL_CREATE_ERROR';

export const DATAMODEL_UPDATE_REQUEST = 'DATAMODEL_UPDATE_REQUEST';
export const DATAMODEL_UPDATE_SUCCESS = 'DATAMODEL_UPDATE_SUCCESS';
export const DATAMODEL_UPDATE_ERROR = 'DATAMODEL_UPDATE_ERROR';

export const DATAMODEL_DELETE_REQUEST = 'DATAMODEL_DELETE_REQUEST';
export const DATAMODEL_DELETE_SUCCESS = 'DATAMODEL_DELETE_SUCCESS';
export const DATAMODEL_DELETE_ERROR = 'DATAMODEL_DELETE_ERROR';

export const DATAMODEL_QUERY_FETCH_REQUEST = 'DATAMODEL_QUERY_FETCH_REQUEST';
export const DATAMODEL_QUERY_FETCH_SUCCESS = 'DATAMODEL_QUERY_FETCH_SUCCESS';
export const DATAMODEL_QUERY_FETCH_ERROR = 'DATAMODEL_QUERY_FETCH_ERROR';

export const DATAMODEL_GET_CARD_QUERY_FETCH_REQUEST = 'DATAMODEL_GET_CARD_QUERY_FETCH_REQUEST';
export const DATAMODEL_GET_CARD_QUERY_FETCH_SUCCESS = 'DATAMODEL_GET_CARD_QUERY_FETCH_SUCCESS';
export const DATAMODEL_GET_CARD_QUERY_FETCH_ERROR = 'DATAMODEL_GET_CARD_QUERY_FETCH_ERROR';

// Generic Returns Skeleton
const generateSkeleton = (type, body, extraArgs, context = defaultContext, showNotification = true) => {
    let skeleton = { type, context }, notification;
    const history = History.getHistory();

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)){
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.issuePayload ? body.issuePayload.code : body.statusCode, // Backward compatibility with old error handling
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body.payload
            };

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:
            break;
    }

    switch(type){
        case DATAMODELS_FETCH_SUCCESS:
        case DATAMODEL_FETCH_SUCCESS:
        case DATAMODEL_GET_CARD_QUERY_FETCH_SUCCESS:
            skeleton.payload = body;

            break;
        case DATAMODEL_CREATE_SUCCESS:
            skeleton.created = true;
            skeleton.payload = body;

            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', { name: i18n.t('datamodels.datamodelLabel'), context: 'male', count: 1 })
            };
            break;
        case DATAMODEL_UPDATE_SUCCESS:
            skeleton.updated = true;
            skeleton.payload = body;

            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('datamodels.datamodelLabel'), context: 'male', count: 1 })
            };
            break;
        case DATAMODEL_DELETE_SUCCESS:
            skeleton.deleted = true;
            skeleton.payload = body;

            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', { name: i18n.t('datamodels.datamodelLabel'), context: 'male', count: 1 })
            };

            if (history.location.pathname === `/data-center/datamodels/${body._id}`) {
                const locationState = history.location.state || {};
                history.push(locationState.prev || '/data-center/datamodels');
            }

            break;
        case DATAMODEL_CREATE_ERROR:
        case DATAMODEL_UPDATE_ERROR:
        case DATAMODEL_DELETE_ERROR:
        case DATAMODELS_FETCH_ERROR:

            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        // In this case does not show notifications
        case DATAMODEL_FETCH_ERROR:
            break;
        case DATAMODEL_QUERY_FETCH_REQUEST:
            skeleton.datamodelId = extraArgs.datamodelId;
            skeleton.queryAlias = extraArgs.queryAlias;
            skeleton.source = extraArgs.source;

            break;
        case DATAMODEL_QUERY_FETCH_SUCCESS:
            skeleton.datamodelId = extraArgs.datamodelId;
            skeleton.queryAlias = extraArgs.queryAlias;
            skeleton.payload = body.payload;
            skeleton.source = extraArgs.source;
            skeleton.dataWarnings = extraArgs.dataWarnings;
            skeleton.dataErrors = extraArgs.dataErrors;

            break;
        case DATAMODEL_QUERY_FETCH_ERROR:
            skeleton.datamodelId = extraArgs.datamodelId;
            skeleton.queryAlias = extraArgs.queryAlias;
            skeleton.source = extraArgs.source;

            break;
        default:
            break;
    }

    return (dispatch) => {
        dispatch(skeleton);

        if (showNotification && notification) {
            dispatch(addNotification(notification));
        }
    };
};

/*
 * Action creators
 */

/**
 * Initialize datamodels
 */
export function initializeDatamodels(context = defaultContext) {
    return {
        type: DATAMODELS_INITIALIZE,
        context: context
    };
}

/**
 * Initialize Datamodel Detail
 */
 export const initializeDatamodelDetail = (context = defaultContext) => {
    return {
        type: DATAMODELS_INITIALIZE_DETAIL,
        context: context
    };
};
/**
 * Initialize Datamodels List
 */
export const initializeDatamodelsList = (context = defaultContext) => {
    return {
        type: DATAMODELS_INITIALIZE_LIST,
        context: context
    };
};

/**
 * Get datamodels action
 * @param query
 * @param context
 * @param gql
 * @param sort
 */
export function getDatamodels(query = {}, context = defaultContext, gql = DATAMODEL_LIST, sort = {}) {

    return async (dispatch) => {
        try {

            const gqlQuery = {
                query: `query($query: QueryDatamodelInput!, $sort: SortDatamodelInput){
                    readDatamodels(query: $query, sort: $sort) {
                        ${gql}
                    }
                }`,
                variables: {
                    query: query,
                    sort: sort
                }
            };
            dispatch(generateSkeleton(DATAMODELS_FETCH_REQUEST, null, null, context));
            const token = Auth.getLocalJwt();

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .set("app", Auth.getAuthApp())
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body && res.body.data && res.body.data.readDatamodels) {
                    dispatch(generateSkeleton(DATAMODELS_FETCH_SUCCESS, res.body.data.readDatamodels, null, context));
                }
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(DATAMODELS_FETCH_ERROR, res.body.errors[0], null, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(DATAMODELS_FETCH_ERROR, err, null, context));
        }
    };
}

/**
 * Get datamodel action
 * @param datamodelId
 * @param context
 * @param gql
 */
export function getDatamodel(datamodelId, context = defaultContext, gql = DATAMODEL_DETAIL) {

    return async (dispatch) => {
        try {
            dispatch(generateSkeleton(DATAMODEL_FETCH_REQUEST, null, null, context));

            const datamodel = await gqlRequest({
                queryName: "readDatamodel",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: Number(datamodelId)
                }]
            });

            dispatch(generateSkeleton(DATAMODEL_FETCH_SUCCESS, datamodel, null, context));
        } catch (err) {
            dispatch(generateSkeleton(DATAMODEL_FETCH_ERROR, err, null, context));
        }
    };
}

/**
 * Create datamodel action
 * @param datamodelData
 * @param context
 * @param gql
 */
export function createDatamodel(datamodelData, context = defaultContext, gql = DATAMODEL_DETAIL) {

    return async (dispatch) => {
        try {

            const datamodel = {
                managed: datamodelData.managed,
                name: datamodelData.name,
                desc: datamodelData.desc,
                query_data_connection: datamodelData.query_data_connection && (datamodelData.query_data_connection._id || datamodelData.query_data_connection)
            };

            dispatch(generateSkeleton(DATAMODEL_CREATE_REQUEST, null, null, context));

            const createdDatamodel = await gqlRequest({
                queryType: "mutation",
                queryName: "createDatamodel",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "DatamodelInput!",
                    name: "datamodel",
                    data: datamodel
                }]
            });

            dispatch(generateSkeleton(DATAMODEL_CREATE_SUCCESS, createdDatamodel, null, context));
        } catch (err) {
            dispatch(generateSkeleton(DATAMODEL_CREATE_ERROR, err, null, context));
        }
    };
}

/**
 * Update datamodel action
 * @param datamodelData
 * @param context
 * @param gql
 */
export function updateDatamodel(datamodelData, context = defaultContext, gql = DATAMODEL_DETAIL) {

    return async (dispatch) => {
        try {

            let datamodel = {
                name: datamodelData.name,
                desc: datamodelData.desc
            };

            if (datamodelData.query_data_connection) {
                datamodel.query_data_connection = datamodelData.query_data_connection && (datamodelData.query_data_connection._id || datamodelData.query_data_connection);
            }
            if (datamodelData.datasets) {
                datamodel.datasets = datamodelData.datasets && datamodelData.datasets.map(dataset => dataset._id || dataset);
            }
            if (datamodelData.relations) {
                datamodel.relations = datamodelData.relations.map(relation => {
                    return {
                        dataset1: relation.dataset1._id || relation.dataset1,
                        dataset2: relation.dataset2._id || relation.dataset2,
                        type: relation.type,
                        fields: relation.fields.map(field => {
                            return {
                                field1: { name: field.field1.name, _id: field.field1._id },
                                field2: { name: field.field2.name, _id: field.field2._id },
                                operation: field.operation
                            };
                        })
                    };
                });
            }

            if (!isNil(datamodelData.multi_connection_enabled)) {
                datamodel.multi_connection_enabled = datamodelData.multi_connection_enabled;
            }

            if (datamodelData.multi_connections) {
                datamodel.multi_connections = datamodelData.multi_connections;
            }

            dispatch(generateSkeleton(DATAMODEL_UPDATE_REQUEST, null, null, context));

            const updatedDatamodel = await gqlRequest({
                queryType: "mutation",
                queryName: "updateDatamodel",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: datamodelData._id
                }, {
                    type: "DatamodelInput!",
                    name: "datamodel",
                    data: datamodel
                }]
            });

            dispatch(generateSkeleton(DATAMODEL_UPDATE_SUCCESS, updatedDatamodel, null, context));
        } catch (err) {
            dispatch(generateSkeleton(DATAMODEL_UPDATE_ERROR, err, null, context));
        }
    };
}

/**
 * Delete datamodel action
 * @param datamodelId
 * @param context
 * @param gql
 */
export function deleteDatamodel(datamodelId, context = defaultContext, gql = DATAMODEL_DETAIL) {

    return async (dispatch) => {
        try {
            dispatch(generateSkeleton(DATAMODEL_DELETE_REQUEST, null, null, context));

            const deletedDatamodel = await gqlRequest({
                queryType: "mutation",
                queryName: "deleteDatamodel",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: datamodelId
                }]
            });

            dispatch(generateSkeleton(DATAMODEL_DELETE_SUCCESS, deletedDatamodel, null, context));
        } catch (err) {
            dispatch(generateSkeleton(DATAMODEL_DELETE_ERROR, err, null, context));
        }
    };
}

/**
 * Get datamodel action
 * @param datamodelId
 * @param cardId
 * @param query
 * @param context
 * @param gql
 */
export function getQueryString(datamodelId, cardId, query, context = defaultContext, gql = QUERY_STRING) {

    return async (dispatch) => {

        try {

            if (!datamodelId) {
                dispatch(generateSkeleton(DATAMODEL_GET_CARD_QUERY_FETCH_ERROR, { message: 'datamodel is mandatory' }, null, context));
                return;
            }

            dispatch(generateSkeleton(DATAMODEL_GET_CARD_QUERY_FETCH_REQUEST, null, null, context));

            const queryString = await gqlRequest({
                queryName: "getQueryString",
                queryGql: `query_string`,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "datamodel",
                    data: datamodelId
                }, {
                    type: "Float",
                    name: "card",
                    data: cardId
                }, {
                    type: "DatamodelQueryInput!",
                    name: "query",
                    data: query || {}
                }]
            });

            dispatch(generateSkeleton(DATAMODEL_GET_CARD_QUERY_FETCH_SUCCESS, queryString, null, context));
        } catch (err) {
            dispatch(generateSkeleton(DATAMODEL_GET_CARD_QUERY_FETCH_ERROR, err, null, context));
        }
    };
}