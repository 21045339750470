import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import i18n from "@biuwer/core/src/i18n";
import { defaultContext } from "@biuwer/redux/src/config/constants";

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { EXECUTIONS_LIST_PAGE, EXECUTION_DETAIL_PAGE } from './executions-gql';

/*
 * Execution action types
 */

export const EXECUTION_INITIALIZE = 'EXECUTION_INITIALIZE';
export const EXECUTION_DETAIL_INITIALIZE = 'EXECUTION_DETAIL_INITIALIZE';
export const EXECUTIONS_LIST_INITIALIZE = 'EXECUTIONS_LIST_INITIALIZE';

export const EXECUTIONS_FETCH_REQUEST = 'EXECUTIONS_FETCH_REQUEST';
export const EXECUTIONS_FETCH_SUCCESS = 'EXECUTIONS_FETCH_SUCCESS';
export const EXECUTIONS_FETCH_ERROR = 'EXECUTIONS_FETCH_ERROR';

export const EXECUTION_FETCH_REQUEST = 'EXECUTION_FETCH_REQUEST';
export const EXECUTION_FETCH_SUCCESS = 'EXECUTION_FETCH_SUCCESS';
export const EXECUTION_FETCH_ERROR = 'EXECUTION_FETCH_ERROR';

export const EXECUTION_CANCEL_REQUEST = 'EXECUTION_CANCEL_REQUEST';
export const EXECUTION_CANCEL_SUCCESS = 'EXECUTION_CANCEL_SUCCESS';
export const EXECUTION_CANCEL_ERROR = 'EXECUTION_CANCEL_ERROR';

export const EXECUTION_DELETE_REQUEST = 'EXECUTION_DELETE_REQUEST';
export const EXECUTION_DELETE_SUCCESS = 'EXECUTION_DELETE_SUCCESS';
export const EXECUTION_DELETE_ERROR = 'EXECUTION_DELETE_ERROR';


// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, showNotification = true, extraArgs) => {
    let skeleton = { type, context }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)){
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.code,
                    code: body.statusCode,
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body.payload
            };

            if (type === 'EXECUTION_CANCEL_SUCCESS') {
                skeleton.updated = true;
            }

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:

            break;
    }

    switch(type){
        case EXECUTION_CANCEL_SUCCESS:
            skeleton.payload = body;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.cancelSuccess', { name: i18n.t('dataPrep.executions.executionLabel'), context: 'female', count: 1 })
            };
            break;
        case EXECUTION_DELETE_SUCCESS:
            skeleton.payload = body;
            skeleton.deleted = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', { name: i18n.t('dataPrep.executions.executionLabel'), context: 'female', count: 1 })
            };
            break;
        case EXECUTION_FETCH_SUCCESS:
        case EXECUTIONS_FETCH_SUCCESS:
            skeleton.payload = body;
            break;
        case EXECUTION_CANCEL_ERROR:
        case EXECUTION_DELETE_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    }
};

/**
 * Get Executions action
 */

export function getExecutions(query = {}, context = defaultContext, gql = EXECUTIONS_LIST_PAGE) {

    return async (dispatch) => {
        try {
            const gqlQuery = {
                query: `query($query: QueryExecutionsInput!) {
                    readExecutions(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query: query
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(EXECUTIONS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(EXECUTIONS_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readExecutions) {
                    dispatch(generateSkeleton(EXECUTIONS_FETCH_SUCCESS, res.body.data.readExecutions, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(EXECUTIONS_FETCH_ERROR, err, context));
        }
    };


}/**
 * Get Executions stats action
 */

export function getExecutionStats(jobId, context = defaultContext, gql = EXECUTIONS_LIST_PAGE) {

    return async (dispatch) => {
        try {
            const gqlQuery = {
                query: `query($jobId: Float!) {
                    readExecutionStats(jobId: $jobId) {
                        ${gql}
                    }
                }`,
                variables: {
                    jobId: jobId
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(EXECUTIONS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(EXECUTIONS_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readExecutionStats) {
                    dispatch(generateSkeleton(EXECUTIONS_FETCH_SUCCESS, res.body.data.readExecutionStats, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(EXECUTIONS_FETCH_ERROR, err, context));
        }
    };
}

/**
 * Initialize Execution
 */
export const initializeExecution = (context = defaultContext) => {
    return {
        type: EXECUTION_INITIALIZE,
        context: context
    };
};

/**
 * Initialize Execution Detail
 */
export const initializeExecutionDetail = (context = defaultContext) => {
    return {
        type: EXECUTION_DETAIL_INITIALIZE,
        context: context
    };
};
/**
 * Initialize Executions List
 */
export const initializeExecutionsList = (context = defaultContext) => {
    return {
        type: EXECUTIONS_LIST_INITIALIZE,
        context: context
    };
};

/**
 * Get Execution action
 */

export function getExecution(executionId, context = defaultContext, gql = EXECUTION_DETAIL_PAGE) {

    return async (dispatch) => {

        try {

            const gqlQuery = {
                query: `query($_id: ID!) {
                    readExecution(_id: $_id) {
                        ${gql}
                    }
                }`,
                variables: {
                    _id: executionId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(EXECUTION_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(EXECUTION_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readExecution) {
                    dispatch(generateSkeleton(EXECUTION_FETCH_SUCCESS, res.body.data.readExecution, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(EXECUTION_FETCH_ERROR), err, context);
        }
    };
}

/**
 * Cancel execution action
 * @param executionId
 * @param context
 * @param gql
 */
export function cancelExecution(executionId, context, gql = EXECUTIONS_LIST_PAGE) {

    return async (dispatch) => {
        try {

            const gqlQuery = {
                query: `mutation($_id: ID!) {
                    cancelExecution(_id: $_id) {
                        ${gql}
                    }
                }`,
                variables: {
                    _id: executionId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(EXECUTION_CANCEL_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(EXECUTION_CANCEL_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.cancelExecution) {
                    dispatch(generateSkeleton(EXECUTION_CANCEL_SUCCESS, res.body.data.cancelExecution, context, true));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(EXECUTION_CANCEL_ERROR, err, context));
        }
    };
}

/**
 * Delete execution action
 * @param executionId
 * @param context
 * @param gql
 */
export function deleteExecution(executionId, context = defaultContext, gql = EXECUTIONS_LIST_PAGE) {

    return async (dispatch) => {
        try {

            const gqlQuery = {
                query: `mutation($_id: ID!) {
                    deleteExecution(_id: $_id) {
                        ${gql}
                    }
                }`,
                variables: {
                    _id: executionId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(EXECUTION_DELETE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(EXECUTION_DELETE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.deleteExecution) {
                    dispatch(generateSkeleton(EXECUTION_DELETE_SUCCESS, res.body.data.deleteExecution, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(EXECUTION_DELETE_ERROR, err, context));
        }
    };
}
