// Libs
import gqlRequest from "@biuwer/core/src/graphql-request";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";

/*
 * Datamodel queries action types
 */

// Cards
export const DATAMODEL_QUERIES_CARDS_FETCH_REQUEST = 'DATAMODEL_QUERIES_CARDS_FETCH_REQUEST';
export const DATAMODEL_QUERIES_CARDS_FETCH_SUCCESS = 'DATAMODEL_QUERIES_CARDS_FETCH_SUCCESS';
export const DATAMODEL_QUERIES_CARDS_FETCH_ERROR = 'DATAMODEL_QUERIES_CARDS_FETCH_ERROR';
export const DATAMODEL_QUERIES_CARDS_SET_ACTIVE = 'DATAMODEL_QUERIES_CARDS_SET_ACTIVE';

// Filters
export const DATAMODEL_QUERIES_FILTERS_FETCH_REQUEST = 'DATAMODEL_QUERIES_FILTERS_FETCH_REQUEST';
export const DATAMODEL_QUERIES_FILTERS_FETCH_SUCCESS = 'DATAMODEL_QUERIES_FILTERS_FETCH_SUCCESS';
export const DATAMODEL_QUERIES_FILTERS_FETCH_ERROR = 'DATAMODEL_QUERIES_FILTERS_FETCH_ERROR';
export const DATAMODEL_QUERIES_FILTERS_SET_ACTIVE = 'DATAMODEL_QUERIES_FILTERS_SET_ACTIVE';

/*
 * Action creators
 */

// Datamodel query card actions
export function setDatamodelQueryCardActive(datamodelQueryCardFilters, datamodelQueryCardQueryFilters) {
    return {
        type: DATAMODEL_QUERIES_CARDS_SET_ACTIVE,
        datamodelQueryFilters: datamodelQueryCardFilters,
        datamodelQueryQueryFilters: datamodelQueryCardQueryFilters
    };
};

// Datamodel query filter actions
export function fetchDatamodelQueryFilter(datamodelQueryFilterFilters = {}, datamodelQueryFilterQueryFilters = {}, datamodelQueryFilterOrigin = {}) {
    return async (dispatch) => {

        try {

            dispatch({
                type: DATAMODEL_QUERIES_FILTERS_FETCH_REQUEST,
                datamodelQueryFilters: datamodelQueryFilterFilters,
                datamodelQueryQueryFilters: datamodelQueryFilterQueryFilters
            });

            const queryResult = await gqlRequest({
                queryName: "queryDatamodelFilter",
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "DataQueryDatamodelFilterInput!",
                    name: "datamodelFilterInput",
                    data: {
                        ...datamodelQueryFilterFilters,
                        ...datamodelQueryFilterQueryFilters,
                        origin: datamodelQueryFilterOrigin
                    }
                }]
            });

            dispatch({
                type: DATAMODEL_QUERIES_FILTERS_FETCH_SUCCESS,
                data: queryResult.data,
                dataWarnings: queryResult.dataWarnings,
                dataErrors: queryResult.dataErrors,
                datamodelQueryFilters: datamodelQueryFilterFilters,
                datamodelQueryQueryFilters: datamodelQueryFilterQueryFilters
            });
        } catch(err) {
            dispatch({
                type: DATAMODEL_QUERIES_FILTERS_FETCH_ERROR,
                error: err,
                datamodelQueryFilters: datamodelQueryFilterFilters,
                datamodelQueryQueryFilters: datamodelQueryFilterQueryFilters
            });
        }
    };
};
export function setDatamodelQueryFilterActive(datamodelQueryFilterFilters, datamodelQueryFilterQueryFilters) {
    return {
        type: DATAMODEL_QUERIES_FILTERS_SET_ACTIVE,
        datamodelQueryFilters: datamodelQueryFilterFilters,
        datamodelQueryQueryFilters: datamodelQueryFilterQueryFilters
    };
};