import moment from 'moment';
import i18n from "@biuwer/core/src/i18n";
import { padStart, words, isNil, isNaN } from 'lodash';

// All available levels
const availableLevels = [
    'second',
    'minute',
    'hour',
    'day',
    'week',
    'month',
    'quarter',
    'year'
];

// Default levels
const defaultLevels = [
    'second',
    'minute',
    'hour',
    'day',
    'week',
    'month',
    'year'
];

/**
 * Get time in specific level
 * @param ms
 * @param {String<second | minute | hour | day | week | month | quarter | year>} level
 * @returns {Number}
 */
export const getTimeFromMs = (ms, level) => {
    switch (level) {
        case 'second':
            return Math.floor(moment.duration(ms).asSeconds());
        case 'minute':
            return Math.floor(moment.duration(ms).asMinutes());
        case 'hour':
            return Math.floor(moment.duration(ms).asHours());
        case 'day':
            return Math.floor(moment.duration(ms).asDays());
        case 'week':
            return Math.floor(moment.duration(ms).asWeeks());
        case 'month':
            return Math.floor(moment.duration(ms).asMonths());
        case 'quarter':
            return Math.floor(moment.duration(ms).asQuarters());
        case 'year':
            return Math.floor(moment.duration(ms).asYears());
        default:
            return Math.floor(moment.duration(ms).asSeconds());
    }
};

export const getElapsedTime = (t, date, options = {}) => {

    if (!date || !(date instanceof Date) || isNaN(date.getTime())) return '';
    if (!(options instanceof Object)) return '';

    // Initialize and sanitize Options params
    const format = options.format && typeof options.format === 'string'
        ? options.format
        : `DD/MM/YYYY, HH:mm`
    ;
    const explicitLevel = options.explicitLevel && availableLevels.find(level => level === options.explicitLevel)
        ? options.explicitLevel
        : undefined
    ;
    const startLevel = options.startLevel && availableLevels.find(level => level === options.startLevel)
        ? options.startLevel
        : `second`
    ;
    const endLevel = options.endLevel && availableLevels.find(level => level === options.endLevel)
        ? options.endLevel
        : `year`
    ;
    const maxEndLevelQty = typeof options.maxEndLevelQty === 'number'
        ? options.maxEndLevelQty
        : undefined
    ;
    const customLevels = options.customLevels
        && options.customLevels instanceof Array
        && options.customLevels.every(cLevel => typeof cLevel === 'string')
        ? options.customLevels
        : undefined
    ;
    const compareWith = options.compareWith && options.compareWith instanceof Date
        ? options.compareWith
        : new Date()
    ;
    const fullResponse = !!options.fullResponse;

    // Get diff in ms
    const formatDate = moment(date).format(format);
    const mDate = moment(date);
    const mCompare = moment(compareWith);
    const mdiff = mCompare.diff(mDate);
    const startLevelIndex = defaultLevels.findIndex(lv => lv === startLevel);
    const endLevelIndex = defaultLevels.findIndex(lv => lv === endLevel);

    let qty, unit, levelsToApply = [...defaultLevels];

    // Explicit Level
    if (explicitLevel || startLevel === endLevel) {
        unit = explicitLevel;
        qty = getTimeFromMs(mdiff, explicitLevel || startLevel);
        levelsToApply = [unit]
    }

    // Levels to apply
    else {
        if (customLevels) {
            levelsToApply = availableLevels.filter(lv => customLevels.some(cLv => lv === cLv));
        } else {
            if (startLevelIndex < endLevelIndex) {
                levelsToApply = defaultLevels.slice(startLevelIndex, endLevelIndex + 1);
            } else {
                levelsToApply = defaultLevels.slice(endLevelIndex, startLevelIndex + 1);
            }

        }
    }

    for (let level of [...levelsToApply].reverse()) {
        qty = getTimeFromMs(mdiff, level);
        unit = level;
        if (qty > 0) break;
    }

    const trueEndLevel = levelsToApply[levelsToApply.length -1];
    if (maxEndLevelQty !== undefined && unit === trueEndLevel && qty > maxEndLevelQty) {
        return formatDate;
    }

    const unitQty = t(`measure.timeUnits.${unit}`, {count: Number(qty)});
    const ago =  (t('filters.queryUpdatedTimeLabel', {qty: qty, unit: unitQty}));
    const value = qty;

    if (fullResponse) {
        return {
            ago,
            unit,
            value,
            unitQty,
            date,
            formatDate
        }
    } else {
        return ago;
    }
};

/**
 * Function that returns separators from a time format
 * @param {String} time_format Format to extract separators
 * @param {String} needsTimePart
 */
 export const getTimeSeparators = (time_format = '', needsTimePart) => {

    let separators = [];
    let timeFormatArray = time_format.split(' ');
    timeFormatArray.forEach((timeSplit, timeIndex) => {

        // Years
        if (timeSplit.startsWith('y')) {
            if (timeSplit.indexOf('years') > -1) {
                separators.push(i18n.t('timeUnits.year_long_plural'));
            } else if (timeSplit.indexOf('y') > -1) {
                separators.push(i18n.t('timeUnits.year_short'));
            }
        }

        // Months
        if (timeSplit.startsWith('M')) {
            if (timeSplit.indexOf('months') > -1) {
                separators.push(i18n.t('timeUnits.month_long_plural'));
            } else if (timeSplit.indexOf('M') > -1) {
                separators.push(i18n.t('timeUnits.month_short'));
            }
        }

        // Days
        if (timeSplit.startsWith('d')) {
            if (timeSplit.indexOf('days') > -1) {
                separators.push(i18n.t('timeUnits.day_long_plural'));
            } else if (timeSplit.indexOf('d') > -1) {
                separators.push(i18n.t('timeUnits.day_short'));
            }
        }

        // Hours
        if (timeSplit.startsWith('h')) {
            if (timeSplit.indexOf('hours') > -1) {
                separators.push(i18n.t('timeUnits.hour_long_plural'));
            } else if (needsTimePart && timeSplit.indexOf('h') > -1) {
                separators.push(i18n.t('timeUnits.hour_short'));
            } else if (timeSplit.indexOf('h:') > -1 && timeFormatArray.length - 1 !== timeIndex) {
                separators.push(':');
            } else if (timeSplit.indexOf('h') > -1 && timeSplit.indexOf('h:') < 0) {
                separators.push(i18n.t('timeUnits.hour_short'));
            }
        }

        // Minutes
        if (timeSplit.startsWith('m')) {
            if (timeSplit.indexOf('minutes') > -1) {
                separators.push(i18n.t('timeUnits.minute_long_plural'));
            } else if (needsTimePart && timeSplit.indexOf('m') > -1) {
                separators.push(i18n.t('timeUnits.minute_short'));
            } else if (timeSplit.indexOf('m:') > -1 && timeFormatArray.length - 1 !== timeIndex) {
                separators.push(':');
            } else if (timeSplit.indexOf('m') > -1 && timeSplit.indexOf('m:') < 0) {
                separators.push(i18n.t('timeUnits.minute_short'));
            }
        }

        // Seconds
        if (timeSplit.startsWith('s')) {
            if (timeSplit.indexOf('seconds') > -1) {
                separators.push(i18n.t('timeUnits.second_long_plural'));
            } else if (needsTimePart && timeSplit.indexOf('s') > -1) {
                separators.push(i18n.t('timeUnits.second_short'));
            } else if (timeSplit.indexOf('s:') > -1 && timeFormatArray.length - 1 !== timeIndex) {
                separators.push(':');
            } else if (timeSplit.indexOf('s') > -1 && timeSplit.indexOf('s:') < 0) {
                separators.push(i18n.t('timeUnits.second_short'));
            }
        }
    });

    return separators;
};

/**
 * Function that transforms a number into time
 * @param {Number} value Value to convert to time format
 * @param {String} time_level Time level
 * @param {String} time_format Format to apply
 * @param {Boolean} hide_zeroes Hide left zeroes
 */
export const formatTimeMetric = (value, time_level, time_format, hide_zeroes = false) => {

    if (isNil(value) || isNaN(value)) return undefined;

    // Needed metadata of each time level
    const timeLevelSteps = [{
        longMatch: "years",
        shortMatch: "y",
        getDuration: (duration) => Math.floor(duration.years()),
    }, {
        longMatch: "months",
        shortMatch: "M",
        getDuration: (duration, timeIndex) => Math.floor(timeIndex === 0 ? duration.asMonths() : duration.months()),
    }, {
        longMatch: "days",
        shortMatch: "d",
        getDuration: (duration, timeIndex) => Math.floor(timeIndex === 0 ? duration.asDays() : duration.days()),
    }, {
        longMatch: "hours",
        shortMatch: "h",
        colonMatch: true,
        getDuration: (duration, timeIndex) => Math.floor(timeIndex === 0 ? duration.asHours() : duration.hours()),
    }, {
        longMatch: "minutes",
        shortMatch: "m",
        colonMatch: true,
        getDuration: (duration, timeIndex) => Math.floor(timeIndex === 0 ? duration.asMinutes() : duration.minutes()),
    }, {
        longMatch: "seconds",
        shortMatch: "s",
        colonMatch: true,
        getDuration: (duration, timeIndex) => Math.floor(timeIndex === 0 ? duration.asSeconds() : duration.seconds()),
    }];

    // Duration function does not work with decimals at day/month/year level, so a duration of 0.5 days is 0.5 days instead of 12 hours
    // To work around this issue, we transform the duration to milliseconds and then apply another duration
    let duration = moment.duration(moment.duration(value, time_level).asMilliseconds());
    let splits = [], result = '', timeLabel = '', timeLevelDuration;

    // Iterate each specified time level
    time_format.split(' ').forEach((timeSplit, timeIndex) => {

        // Iterate each time level
        timeLevelSteps.forEach((timeLevelStep, stepIndex) => {

            // Match time level
            if (timeSplit.startsWith(timeLevelStep.shortMatch)) {

                // Get time level duration
                timeLevelDuration = timeLevelStep.getDuration(duration, timeIndex);

                // Prevent render zero duration in left time levels
                if (!hide_zeroes || timeLevelDuration !== 0 || splits.length > 0) {

                    // Add time duration in splits array with specified format
                    if (timeSplit.indexOf(timeLevelStep.longMatch) > -1) {
                        timeLabel = i18n.t(`timeUnits.${timeLevelStep.longMatch.slice(0, -1)}_long`, { count: timeLevelDuration });
                        splits.push(`${timeLevelDuration} ${timeLabel}`);
                    }
                    else if (timeLevelStep.colonMatch && timeSplit.indexOf(`${timeLevelStep.shortMatch}:`) > -1) {
                        splits.push(timeIndex === 0 || timeLevelStep.shortMatch === "h" ? `${timeLevelDuration}` : `:${padStart(String(timeLevelDuration), 2, '0')}`);
                    }
                    else if (timeSplit.indexOf(timeLevelStep.shortMatch) > -1) {
                        timeLabel = i18n.t(`timeUnits.${timeLevelStep.longMatch.slice(0, -1)}_short`);
                        splits.push(`${timeLevelDuration}${timeLabel}`);
                    }
                    else {
                        splits.push(`${timeLevelDuration}`);
                    }
                }
            }
        });
    });

    // Join all splits into one string
    if (splits && splits.length > 0) {
        splits.forEach((split) => {
            result += split.startsWith(':') || result === '' ? split : ` ${split}`;
        });
    }

    return result;
};


/**
 * Function that transforms a custom time string into a number
 * @param {String} value The time string
 * @param {String} time_level Time level
 * @param {String} time_format Format applied
 */
export const getTimeMetricValue = (value, time_level, time_format) => {

    let result = 0, durationObject = {}, splits;

    switch (time_format) {
        case 'm(m) s(s)':
        case 'm(minutes) s(seconds)':

            // Examples:
            // 'm(m) s(s)' -> 35mi 15s
            // 'm(minutes) s(seconds)' -> 35 minutes 15 seconds
            // Get rid of all non-numeric values (but keep spaces to split)
            words(value.replace(/[^0-9\s]+/gi, '')).forEach((split, index) => {
                switch (index) {
                    case 0:
                        durationObject.minutes = split;
                        break;
                    case 1:
                        durationObject.seconds = split;
                        break;
                    default:
                        break;
                }
            });

            result = moment.duration(durationObject).as(time_level);
            break;

        case 'm: s:':

            // Examples:
            // 'm: s:' -> 35:15
            // Get rid of all non-numeric values (but keep spaces to split)
            value.split(':').forEach((split, index) => {
                switch (index) {
                    case 0:
                        durationObject.minutes = split;
                        break;
                    case 1:
                        durationObject.seconds = split;
                        break;
                    default:
                        break;
                }
            });

            result = moment.duration(durationObject).as(time_level);

            break;


        case 'h(h) m(m) s(s)':
        case 'h(hours) m(minutes) s(seconds)':

            // Examples:
            // 'h(h) m(m) s(s)' -> 2h 35mi 15s
            // 'h(hours) m(minutes) s(seconds)' -> 2 hours 35 minutes 15 seconds
            // Get rid of all non-numeric values (but keep spaces to split)
            words(value.replace(/[^0-9\s]+/gi, '')).forEach((split, index) => {
                switch (index) {
                    case 0:
                        durationObject.hours = split;
                        break;
                    case 1:
                        durationObject.minutes = split;
                        break;
                    case 2:
                        durationObject.seconds = split;
                        break;
                    default:
                        break;
                }
            });

            result = moment.duration(durationObject).as(time_level);
            break;

        case 'h: m: s:':

            // Examples:
            // 'h: m: s:' -> 2:35:15
            // Get rid of all non-numeric values (but keep spaces to split)
            value.split(':').forEach((split, index) => {
                switch (index) {
                    case 0:
                        durationObject.hours = split;
                        break;
                    case 1:
                        durationObject.minutes = split;
                        break;
                    case 2:
                        durationObject.seconds = split;
                        break;
                    default:
                        break;
                }
            });

            result = moment.duration(durationObject).as(time_level);
            break;

        case 'h(h) m(m)':
        case 'h(hours) m(minutes)':

            // Examples:
            // 'h(h) m(m)' -> 2h 35m
            // 'h(hours) m(minutes)' -> 2 hours 35 minutes
            // Get rid of all non-numeric values (but keep spaces to split)
            words(value.replace(/[^0-9\s]+/gi, '')).forEach((split, index) => {

                switch (index) {
                    case 0:
                        durationObject.hours = split;
                        break;
                    case 1:
                        durationObject.minutes = split;
                        break;
                    default:
                        break;
                }
            });

            result = moment.duration(durationObject).as(time_level);
            break;

        case 'h: m:':

            // Examples:
            // 'h: m:' -> 2:35
            // Get rid of all non-numeric values (but keep spaces to split)
            value.split(':').forEach((split, index) => {
                switch (index) {
                    case 0:
                        durationObject.hours = split;
                        break;
                    case 1:
                        durationObject.minutes = split;
                        break;
                    default:
                        break;
                }
            });

            result = moment.duration(durationObject).as(time_level);
            break;

        case 'd(d) h(h) m(m)':
        case 'd(days) h(hours) m(minutes)':

            // Examples:
            // 'd(d) h(h) m(m)' -> 3d 2h 35m
            // 'd(days) h(hours) m(minutes)' -> 3 days 2 hours 35 minutes
            // Get rid of all non-numeric values (but keep spaces to split)
            words(value.replace(/[^0-9\s]+/gi, '')).forEach((split, index) => {

                switch (index) {
                    case 0:
                        durationObject.days = split;
                        break;
                    case 1:
                        durationObject.hours = split;
                        break;
                    case 2:
                        durationObject.minutes = split;
                        break;
                    default:
                        break;
                }
            });

            result = moment.duration(durationObject).as(time_level);
            break;

        case 'd(d) h: m:':

            // Examples:
            // 'd(d) h: m:' -> 3d 2:35
            // Get rid of all non-numeric values (but keep spaces to split)
            splits = value.split(' ');
            words(splits[0].replace(/[^0-9\s]+/gi, '')).forEach((split, index) => {
                durationObject.days = split;
            });

            splits[1].split(':').forEach((split, index) => {
                switch (index) {
                    case 0:
                        durationObject.hours = split;
                        break;
                    case 1:
                        durationObject.minutes = split;
                        break;
                    default:
                        break;
                }
            });

            result = moment.duration(durationObject).as(time_level);

            break;

        case 'd(days) h: m:':

            // Examples:
            // 'd(days) h :m' -> 3 days 2:35
            // Get rid of all non-numeric values (but keep spaces to split)
            splits = value.split(' ');
            words(splits[0].replace(/[^0-9\s]+/gi, '')).forEach((split, index) => {
                durationObject.days = split;
            });

            splits[2].split(':').forEach((split, index) => {
                switch (index) {
                    case 0:
                        durationObject.hours = split;
                        break;
                    case 1:
                        durationObject.minutes = split;
                        break;
                    default:
                        break;
                }
            });

            result = moment.duration(durationObject).as(time_level);
            break;

        case 'd(d) h(h)':
        case 'd(days) h(hours)':

            // Examples:
            // 'd(d) h(h)' -> 3d 2h
            // 'd(days) h(hours)' -> 3 days 2 hours
            // Get rid of all non-numeric values (but keep spaces to split)
            words(value.replace(/[^0-9\s]+/gi, '')).forEach((split, index) => {

                switch (index) {
                    case 0:
                        durationObject.days = split;
                        break;
                    case 1:
                        durationObject.hours = split;
                        break;
                    default:
                        break;
                }
            });

            result = moment.duration(durationObject).as(time_level);
            break;

        case 'M(M) d(d) h(h)':
        case 'M(months) d(days) h(hours)':

            // Examples:
            // 'M(M) d(d) h(h)' -> 3m 2d 4h
            // 'M(months) d(days) h(hours)' -> 3 months 2 days 4 hours
            // Get rid of all non-numeric values (but keep spaces to split)
            words(value.replace(/[^0-9\s]+/gi, '')).forEach((split, index) => {

                switch (index) {
                    case 0:
                        durationObject.months = split;
                        break;
                    case 1:
                        durationObject.days = split;
                        break;
                    case 2:
                        durationObject.hours = split;
                        break;
                    default:
                        break;
                }
            });

            result = moment.duration(durationObject).as(time_level);
            break;

        case 'M(M) d(d)':
        case 'M(months) d(days)':

            // Examples:
            // 'M(M) d(d)' -> 3m 2d
            // 'M(months) d(days)' -> 3 months 2 days
            // Get rid of all non-numeric values (but keep spaces to split)
            words(value.replace(/[^0-9\s]+/gi, '')).forEach((split, index) => {

                switch (index) {
                    case 0:
                        durationObject.months = split;
                        break;
                    case 1:
                        durationObject.days = split;
                        break;
                    default:
                        break;
                }
            });

            result = moment.duration(durationObject).as(time_level);
            break;

        case 'y(y) M(M) d(d)':
        case 'y(years) M(months) d(days)':

            // Examples:
            // 'y(y) M(M) d(d)' -> 2y 3m 2d
            // 'y(years) M(months) d(days)' -> 2 years 3 months 2 days
            // Get rid of all non-numeric values (but keep spaces to split)
            words(value.replace(/[^0-9\s]+/gi, '')).forEach((split, index) => {

                switch (index) {
                    case 0:
                        durationObject.years = split;
                        break;
                    case 1:
                        durationObject.months = split;
                        break;
                    case 2:
                        durationObject.days = split;
                        break;
                    default:
                        break;
                }
            });

            result = moment.duration(durationObject).as(time_level);
            break;

        case 'y(y) M(M)':
        case 'y(years) M(months)':

            // Examples:
            // 'y(y) M(M)' -> 2y 3m
            // 'y(years) M(months)' -> 2 years 3 months 2
            // Get rid of all non-numeric values (but keep spaces to split)
            words(value.replace(/[^0-9\s]+/gi, '')).forEach((split, index) => {

                switch (index) {
                    case 0:
                        durationObject.years = split;
                        break;
                    case 1:
                        durationObject.months = split;
                        break;
                    default:
                        break;
                }
            });

            result = moment.duration(durationObject).as(time_level);
            break;

        case 'y(y)':
        case 'y(years)':
        case 'M(M)':
        case 'M(months)':
        case 'd(d)':
        case 'd(days)':
        case 'h(h)':
        case 'h(hours)':
        case 'm(m)':
        case 'm(minutes)':
        case 's(s)':
        case 's(seconds)':

            let timeLevel;
            if (time_format.startsWith('y')) {
                timeLevel = 'years';
            } else if (time_format.startsWith('M')) {
                timeLevel = 'months';
            } else if (time_format.startsWith('d')) {
                timeLevel = 'days';
            } else if (time_format.startsWith('h')) {
                timeLevel = 'hours';
            } else if (time_format.startsWith('m')) {
                timeLevel = 'minutes';
            } else if (time_format.startsWith('s')) {
                timeLevel = 'seconds';
            }

            // Get rid of all non-numeric values (but keep spaces to split)
            words(value.replace(/[^0-9\s]+/gi, '')).forEach((split, index) => {

                switch (index) {
                    case 0:
                        durationObject[timeLevel] = split;
                        break;
                    default:
                        break;
                }
            });

            result = moment.duration(durationObject).as(time_level);
            break;

        default:
            break;
    }

    return result;
};


/**
 * Function that transforms time expression in several parts into one string
 * @param {Array} value The array with every part
 * @param {String} time_format Format applied
 */
export const getTimeExpressionFromParts = (value, time_format) => {

    let result = '';
    let separators = getTimeSeparators(time_format);

    if (separators && separators.length > 0) {

        separators.forEach((separator, index) => {

            switch (index) {
                case 0:
                    result += value[0] || '0';
                    break;
                case 1:
                    result += value[1] || '0';
                    break;
                case 2:
                    result += value[2] || '0';
                    break;
                default:
                    break;
            }

            if (separator === ':') {
                result += separator;
            } else if (separator.length === 1 || separator === 'mi') {
                result += `${separator} `;
            } else {
                result += ` ${separator} `;
            }
        });
    }

    // Add last value if there is only one separator
    if (separators.length < 2 && value.length === 2) {
        result += value[1] || '0';
    }

    // Add last value if there are two separators
    if (separators.length < 3 && value.length === 3) {
        result += value[2] || '0';
    }

    return result;
};

export default getElapsedTime;