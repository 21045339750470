export const tooltipContentSizes = {
    nano: {
        minWidth: 50,
        maxWidth: 100
    },
    small: {
        minWidth: 100,
        maxWidth: 300
    },
    medium: {
        minWidth: 300,
        maxWidth: 500
    },
    large: {
        minWidth: 500,
        maxWidth: 700
    },
    filterBox: {
        minWidth: 130,
        maxWidth: 250
    },
    infoSmall: {
        minWidth: 100,
        maxWidth: 150
    },
    infoMedium: {
        minWidth: 250,
        maxWidth: 400
    },
    infoLarge: {
        minWidth: 500,
        maxWidth: 650
    },
    draggableElement: {
        minWidth: 50,
        maxWidth: 700
    },
    importDatasetInfo: {
        minWidth: 200,
        maxWidth: 500
    },
    customButton: {
        minWidth: 100,
        maxWidth: 500
    },
    executionError: {
        minWidth: 100,
        maxWidth: 650
    },
    chartCatalog: {
        minWidth: 10,
        maxWidth: 150
    }
};

export default {
    tooltipContentSizes
};