export const SETUP_PAYMENT = `
    id
    client_secret
    next_action
    status
    payment_method
`;

export const PAYMENT_INTENT = `
    id
    client_secret
    next_action
    status
    amount
    currency
    payment_method
`;