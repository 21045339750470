import {fromJS, Map} from 'immutable';

/**
 * Check VIES Reducer
 */

import {
    CHECK_VIES_REQUEST,
    CHECK_VIES_SUCCESS,
    CHECK_VIES_ERROR
} from './vies-actions.js';

const initialState = Map({});

const vies = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_VIES_REQUEST:
            return initialState;
        case CHECK_VIES_SUCCESS:
            return Map({
                payload: fromJS(action.payload)
            });
        case CHECK_VIES_ERROR:
            return Map({
                payload: null,
                error: fromJS(action.payload)
            });
        default:
            return state;
    }
};

export default vies;
