import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import i18n from '@biuwer/core/src/i18n';

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";
import {MAP_CATALOG_DETAIL, MAP_CATALOG_LIST} from './map-catalog-gql';
import { defaultContext } from "@biuwer/redux/src/config/constants";

/*
 * System MapCatalog action types
 */
export const MAP_CATALOG_LIST_FETCH_REQUEST = 'MAP_CATALOG_LIST_FETCH_REQUEST';
export const MAP_CATALOG_LIST_FETCH_SUCCESS = 'MAP_CATALOG_LIST_FETCH_SUCCESS';
export const MAP_CATALOG_LIST_FETCH_ERROR = 'MAP_CATALOG_LIST_FETCH_ERROR';

export const MAP_CATALOG_INITIALIZE = 'MAP_CATALOG_INITIALIZE';
export const MAP_CATALOG_DETAIL_INITIALIZE = 'MAP_CATALOG_DETAIL_INITIALIZE';
export const MAP_CATALOG_LIST_INITIALIZE = 'MAP_CATALOG_LIST_INITIALIZE';

export const MAP_CATALOG_DETAIL_FETCH_REQUEST = 'MAP_CATALOG_DETAIL_FETCH_REQUEST';
export const MAP_CATALOG_DETAIL_FETCH_SUCCESS = 'MAP_CATALOG_DETAIL_FETCH_SUCCESS';
export const MAP_CATALOG_DETAIL_FETCH_ERROR = 'MAP_CATALOG_DETAIL_FETCH_ERROR';

export const MAP_CATALOG_CREATE_REQUEST = 'MAP_CATALOG_CREATE_REQUEST';
export const MAP_CATALOG_CREATE_SUCCESS = 'MAP_CATALOG_CREATE_SUCCESS';
export const MAP_CATALOG_CREATE_ERROR = 'MAP_CATALOG_CREATE_ERROR';

// Generic Skeleton for every action
const generateSkeleton = (type, body, context = defaultContext, showNotification = true) => {
    let skeleton = { type }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false,
                context: context
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                context: context
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body,
                context: context
            };
            break;
        default:
            break;
    }
    switch(type) {
        case MAP_CATALOG_CREATE_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', {
                    // TODO 788
                    name: i18n.t('admin.roles.title'),
                    context: 'male',
                    count: 1
                })
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (showNotification && notification) {
            dispatch(addNotification(notification));
        }
    }
};

/**
 * System MapCatalog: Initialize map catalog action
 */
export function initializeMapCatalog(context = defaultContext) {
    return {
        type: MAP_CATALOG_INITIALIZE,
        context
    };
}

/**
 * System Role: Initialize map catalog detail action
 */
export function initializeMapCatalogDetail(context = defaultContext) {
    return {
        type: MAP_CATALOG_DETAIL_INITIALIZE,
        context
    };
}

/**
 * System MapCatalog: Initialize map catalog list action
 */
export function initializeMapCatalogList(context = defaultContext) {
    return {
        type: MAP_CATALOG_LIST_INITIALIZE,
        context
    };
}

/**
 * System MapCatalog: Get MapCatalog List for the requester's organization
 * @param query
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function getMapCatalogList(query = {}, context = defaultContext, gql = MAP_CATALOG_LIST) {
    return async dispatch => {
        try {
            const graphQuery = {
                query: `query($query: QueryMapCatalogInput!) {
                    queryMapCatalog(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(MAP_CATALOG_LIST_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(graphQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(MAP_CATALOG_LIST_FETCH_ERROR, 'Error getting System MapCatalog data from server', context));
                } else if (res.body && res.body.data && res.body.data.queryMapCatalog) {
                    dispatch(generateSkeleton(MAP_CATALOG_LIST_FETCH_SUCCESS, res.body.data.queryMapCatalog, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(MAP_CATALOG_LIST_FETCH_ERROR, e, context));
        }
    };
}

/**
 * System MapCatalog: Create map catalog action
 * @param mapCatalog - object with all needed info to create a map catalog
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function createMapCatalog(mapCatalog, context = defaultContext, gql = MAP_CATALOG_DETAIL) {
    return async dispatch => {

        try {
            const query = {
                query: `mutation($mapCatalog: MapCatalogInput!) {
                    createMapCatalog (mapCatalog: $mapCatalog){
                        ${gql}
                    }
                }`,
                variables: {
                    mapCatalog: mapCatalog
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(MAP_CATALOG_CREATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(MAP_CATALOG_CREATE_ERROR, 'Error creating map catalog', context));
                } else if (res.body && res.body.data && res.body.data.createMapCatalog) {
                    dispatch(generateSkeleton(MAP_CATALOG_CREATE_SUCCESS, res.body.data.createMapCatalog, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(MAP_CATALOG_CREATE_ERROR, e, context));
        }
    };
}

/**
 * System MapCatalog: Get map catalog action
 * @param mapCatalogId - map catalog to obtain
 * @param context - structure to store data in redux
 * @param gql - query to execute
 */
export function getMapCatalogDetail(mapCatalogId, context = defaultContext, gql = MAP_CATALOG_DETAIL) {
    return async dispatch => {
        try {

            const query = {
                query: `query($mapCatalogId: ID!) {
                    readMapCatalog(_id: $mapCatalogId) {
                        ${gql}
                    }
                }`,
                variables: {
                    mapCatalogId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(MAP_CATALOG_DETAIL_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(query)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(MAP_CATALOG_DETAIL_FETCH_ERROR, 'Error getting System Map Catalog data from server', context));
                } else if (res.body && res.body.data && res.body.data.readMapCatalog) {
                    dispatch(generateSkeleton(MAP_CATALOG_DETAIL_FETCH_SUCCESS, res.body.data.readMapCatalog, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(MAP_CATALOG_DETAIL_FETCH_ERROR, e, context));
        }
    };
}
