import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { isEmail } from 'validator';

// Components
import TextInput from "@biuwer/common/src/components/text-input"
import InfoMessage from "@biuwer/common/src/components/info-message"
import Helmet from "react-helmet";

// Libs
import HelmetLib from "@biuwer/common/src/libs/helmet-lib"
import chatLib from "@biuwer/common/src/libs/chat-lib"

class SigninForm extends Component {
    constructor(props) {
        super(props);

        this.state= {
            email: '',
            password: '',
            canSubmit: false
        };

        this.signin = this.signin.bind(this);
        this.onCloseErrorMessage = this.onCloseErrorMessage.bind(this);
        this.onChangeTextField = this.onChangeTextField.bind(this);
        this.handlePressEnter = this.handlePressEnter.bind(this);
    }

    UNSAFE_componentWillMount() {

        // Clean errors before mount the component
        this.props.cleanErrorMessageSigninForm();
    }

    signin() {
        this.props.signin({email: this.state.email, password: this.state.password});
    }

    // Error Message component event for close/hide the error
    onCloseErrorMessage() {
        this.props.cleanErrorMessageSigninForm();
    }

    static canSubmit(state) {
        return isEmail(state.email, { allow_utf8_local_part: false }) && state.password !== '';
    }

    onChangeTextField(e) {
        let tempStateVar = {
            ...this.state,
            [e.target.name]: e.target.value
        };

        this.setState({
            [e.target.name]: e.target.value,
            canSubmit: SigninForm.canSubmit(tempStateVar)
        });
    }

    handlePressEnter(e) {
        const ENTER = 13;
        if(e.keyCode === ENTER && SigninForm.canSubmit(this.state)) {
           this.signin();
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="animated fadeIn">
                <Helmet
                    title={HelmetLib.generatePageTitle(t('auth.signinForm.signin'))}
                />
                <h4 className="p-t-25 text-center">{t('auth.signinForm.headerTitle')}</h4>
                <div id="form-login" className="pv15">
                        <TextInput
                            formStyling="default"
                            type="text"
                            name="email"
                            labelText="Login"
                            placeholder={t('auth.emailLabel')}
                            value={this.state.email}
                            onChange={this.onChangeTextField}
                            onKeyDown={this.handlePressEnter}
                        />

                        <TextInput
                            formStyling="default"
                            type="password"
                            name="password"
                            labelText="Password"
                            placeholder={t('auth.passwordLabel')}
                            value={this.state.password}
                            onChange={this.onChangeTextField}
                            onKeyDown={this.handlePressEnter}
                        />
                    <div className="row">
                        <div className="col-md-12">
                            <InfoMessage
                                onCloseErrorMessage={this.onCloseErrorMessage}
                                message={this.props.error}
                                type="danger"
                            />
                        </div>
                    </div>
                    <button
                        className="btn btn-info btn-sm"
                        type="button"
                        onClick={this.signin}
                        disabled={!this.state.canSubmit}
                    >
                        {t('auth.signinForm.submitButton').toUpperCase()}
                    </button>
                    <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
                        <div className="col-12">
                            <span onClick={() => this.props.toggleScreen('forgot-password')} className="text-info small span-link">
                                {t('auth.signinForm.forgotPasswordLabel')}
                            </span>
                        </div>
                    </div>
                    <div className="row mb10">
                        <div className="col-12">
                            <Link to="/signup" className="span-link text-info small">
                                {t('auth.signinForm.signupLabel')}
                            </Link>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="row mb10">
                        <div className="col-12">
                            <span onClick={() => this.props.toggleScreen('one-time-login')} className="span-link text-info small">
                                {t('auth.signinForm.oneTimeAccessLabel')}
                            </span>
                        </div>
                    </div>
                    <div className="row mb10">
                        <div className="col-12">
                            <span className="span-link text-info small" onClick={chatLib.toggleChat}>
                                {t('auth.signinForm.helpChat')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SigninForm;
