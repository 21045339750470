import { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import Helmet from "react-helmet";

// Libs
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import HelmetLib from "@biuwer/common/src/libs/helmet-lib";
import chatLib from '@biuwer/common/src/libs/chat-lib';
import History from "@biuwer/core/src/history";

// Components
import CustomRoute from './custom-route';
import Signup from '../signup/signup';
import SignupConfirmation from '../signup/signup-confirmation';
import Signin from '../signin/signin';
import ResetPassword from '../reset-password/reset-password';
import Welcome from '../welcome/welcome';
import OauthRedirect from './oauth-redirect';
import PaymentLinkPage from '../admin/payment/payment-link-page';

class Routes extends Component {
    state = {
        mainComponents: {
            App: null,
        }
    };

    UNSAFE_componentWillMount(){
        if(Auth.isLoggedIn()) {
            this.restrictedUnlocked();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.isLoggedIn === false && nextProps.isLoggedIn === true){
            this.restrictedUnlocked();
        }
    }

    componentDidMount() {

        // Initalize Crisp Chat
        chatLib.initializeWidget();

        if(Auth.isLoggedIn()) {

            // Identify user in crisp
            chatLib.identifyUserInCrisp();
        }
    }

    /**
     * Function that lazy loads main-layout (which is the main Biuwer app file) and stores it into the state
     */
    restrictedUnlocked() {

        // TODO: convert logic to work properly with Vite and React 17/18
        import(/* webpackChunkName: "App" */ '../layouts/main-layout')
            .then(module => {
                let mainComponents = cloneDeep(this.state.mainComponents);
                mainComponents.mainApp = module.default;

                this.setState({
                    mainComponents
                })
            });
    }

    render() {
        let { mainComponents } = this.state;
        let isLoggedIn = Auth.isLoggedIn();

        const history = History.getHistory();

        // This is the first React-Router 4 Switch in whole Biuwer
        // Here we use a customized component of the original RR4's Route
        // With it, we can handle more situations just like redirecting or forbidding access to a specific page
        return(
            <>
                <Helmet
                    title={HelmetLib.defaultAppTitle}
                />
                <Router history={history}>
                    <Switch>
                        <CustomRoute
                            path="/payment-link/:paymentLinkId"
                            component={PaymentLinkPage}
                            failRedirect="/"
                        />
                        <CustomRoute
                            exact
                            path="/signup"
                            component={Signup}
                            externalComponent={true}
                            failRedirect="/"
                            isLoggedIn={isLoggedIn}
                        />
                        <CustomRoute
                            path="/signup/:signupToken"
                            component={SignupConfirmation}
                            externalComponent={true}
                            failRedirect="/"
                            isLoggedIn={isLoggedIn}
                        />
                        <CustomRoute
                            exact
                            path="/signin"
                            component={Signin}
                            failRedirect="/"
                            isLoggedIn={isLoggedIn}
                        />
                        <CustomRoute
                            path="/reset/:resetToken"
                            component={ResetPassword}
                            failRedirect="/"
                            isLoggedIn={isLoggedIn}
                        />
                        <CustomRoute
                            path="/welcome/:welcomeToken"
                            component={Welcome}
                            failRedirect="/"
                            isLoggedIn={isLoggedIn}
                        />
                        <CustomRoute
                            path="/otp/:resetToken"
                            component={ResetPassword}
                            failRedirect="/"
                            isLoggedIn={isLoggedIn}
                        />
                        <CustomRoute
                            restricted
                            path="/oauth-redirect"
                            component={OauthRedirect}
                            failRedirect="/signin"
                            isLoggedIn={isLoggedIn}
                        />
                        <CustomRoute
                            path="/share"
                            component={() => null}
                            loading={true}
                            isLoggedIn={false}
                        />
                        <CustomRoute
                            restricted
                            path="/"
                            component={mainComponents.mainApp}
                            failRedirect="/signin"
                            loading={isLoggedIn && !mainComponents.mainApp}
                            isLoggedIn={isLoggedIn}
                        />
                    </Switch>
                </Router>
            </>
        );
    }
}

function mapStateToProps(state) {

    // Reducers
    const sessionReducer = state.system.auth.session;

    // Session
    const isLoggedIn = sessionReducer.get('isLoggedIn');

    return {
        isLoggedIn
    }
}

export default connect(mapStateToProps)(Routes);
