/**
 * Numeral Initialization
 *
 */

import numeral from 'numeral';

export default (defaultLocale) => {
    numeral.locale(defaultLocale);
};
