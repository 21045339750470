import { Map, List, fromJS } from 'immutable';
import { defaultContext } from "@biuwer/redux/src/config/constants";

/**
 * System Users Reducer
 */

import {
    USER_INITIALIZE, USER_DETAIL_INITIALIZE, USERS_LIST_INITIALIZE,
    USERS_FETCH_REQUEST, USERS_FETCH_SUCCESS, USERS_FETCH_ERROR,
    USER_FETCH_REQUEST, USER_FETCH_SUCCESS, USER_FETCH_ERROR,
    USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_ERROR,
    USER_SET_DEFAULT_ORGANIZATION_REQUEST, USER_SET_DEFAULT_ORGANIZATION_SUCCESS, USER_SET_DEFAULT_ORGANIZATION_ERROR,
    USER_CHANGE_STATUS_REQUEST, USER_CHANGE_STATUS_SUCCESS, USER_CHANGE_STATUS_ERROR,
    USER_CREATE_REQUEST, USER_CREATE_SUCCESS, USER_CREATE_ERROR,
    USER_DELETE_FROM_ORGANIZATION_REQUEST, USER_DELETE_FROM_ORGANIZATION_SUCCESS, USER_DELETE_FROM_ORGANIZATION_ERROR,
    SEND_WELCOME_EMAIL_REQUEST, SEND_WELCOME_EMAIL_SUCCESS, SEND_WELCOME_EMAIL_ERROR,
    CHECK_USER_EXIST_INITIALIZE, CHECK_USER_EXIST_REQUEST, CHECK_USER_EXIST_SUCCESS, CHECK_USER_EXIST_ERROR,
    USER_ADD_ORGANIZATION_REQUEST, USER_ADD_ORGANIZATION_SUCCESS, USER_ADD_ORGANIZATION_ERROR,
    USER_ADD_GROUPS_REQUEST, USER_ADD_GROUPS_SUCCESS, USER_ADD_GROUPS_ERROR,
    USER_DELETE_GROUPS_REQUEST, USER_DELETE_GROUPS_SUCCESS, USER_DELETE_GROUPS_ERROR,
    USER_ADD_ROLES_REQUEST, USER_ADD_ROLES_SUCCESS, USER_ADD_ROLES_ERROR,
    USER_DELETE_ROLES_REQUEST, USER_DELETE_ROLES_SUCCESS, USER_DELETE_ROLES_ERROR,
    USER_CHANGE_ORG_STATUS_REQUEST, USER_CHANGE_ORG_STATUS_SUCCESS, USER_CHANGE_ORG_STATUS_ERROR,
    SWITCH_USER_TO_ADVANCED_REQUEST, SWITCH_USER_TO_ADVANCED_SUCCESS, SWITCH_USER_TO_ADVANCED_ERROR,
    SWITCH_USER_TO_BASIC_REQUEST, SWITCH_USER_TO_BASIC_SUCCESS, SWITCH_USER_TO_BASIC_ERROR,
    USER_DELETE_REQUEST, USER_DELETE_SUCCESS, USER_DELETE_ERROR
} from './users-actions';

import {
    AUTH_TOKEN_CREATE_SUCCESS, AUTH_TOKEN_UPDATE_SUCCESS, AUTH_TOKEN_DELETE_SUCCESS
} from "@biuwer/redux/src/share/auth-tokens/auth-tokens-actions";

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

const users = (state = initialState, action) => {
    let newState, index = -1, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || defaultContext}`;
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch (action.type) {

        // Initialize
        case USER_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));
            return newState;

        case USER_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;

        case USERS_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;

        // Users Fetch (list)
        case USERS_FETCH_REQUEST:

            newState = state;

            // Users fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case USERS_FETCH_SUCCESS:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case USERS_FETCH_ERROR:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;


        // User Fetch (detail)
        case USER_FETCH_REQUEST:

            newState = state;

            // User fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case USER_FETCH_SUCCESS:

            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;

        case USER_FETCH_ERROR:

            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage,
                issue: action.issue,
                issuePayload: fromJS(action.issuePayload)
            }));
            return newState;

        // Create user
        case USER_CREATE_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;

        case USER_CREATE_SUCCESS:

            // Update payload with new created user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;

        case USER_CREATE_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;

        // Update user, change user status and change user status in a organization
        case USER_UPDATE_REQUEST:
        case USER_SET_DEFAULT_ORGANIZATION_REQUEST:
        case USER_CHANGE_STATUS_REQUEST:
        case USER_CHANGE_ORG_STATUS_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;

        case USER_UPDATE_SUCCESS:
        case USER_SET_DEFAULT_ORGANIZATION_SUCCESS:
        case USER_CHANGE_STATUS_SUCCESS:
        case USER_CHANGE_ORG_STATUS_SUCCESS:

            // Update payload with updated user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            error: action.error,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;

        case USER_UPDATE_ERROR:
        case USER_SET_DEFAULT_ORGANIZATION_ERROR:
        case USER_CHANGE_STATUS_ERROR:
        case USER_CHANGE_ORG_STATUS_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;

        // Delete user from organization
        case USER_DELETE_FROM_ORGANIZATION_REQUEST:

            newState = state
                .mergeIn([detail], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;

        case USER_DELETE_FROM_ORGANIZATION_SUCCESS:

            // Update payload with deleted user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        deleted: action.deleted
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                            delete: Map({
                                isFetching: action.isFetching,
                                error: action.error,
                                deleted: action.deleted
                            })
                        });
                }
            }
            return newState;

        case USER_DELETE_FROM_ORGANIZATION_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        // SEND WELCOME EMAIL
        case SEND_WELCOME_EMAIL_REQUEST:

            newState = state;

            // Check if "sendWelcomeEmail" key exists
            if (state.getIn([detail, 'sendWelcomeEmail'])) newState = newState.removeIn([detail, 'sendWelcomeEmail']);
            if (state.getIn([list, 'sendWelcomeEmail'])) newState = newState.removeIn([list, 'sendWelcomeEmail']);

            newState = newState
                .mergeIn([detail], Map({
                    sendWelcomeEmail: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    sendWelcomeEmail: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        case SEND_WELCOME_EMAIL_SUCCESS:

            newState = state
                .mergeIn([detail], Map({
                    sendWelcomeEmail: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        payload: fromJS(action.payload)
                    })
                }))
                .mergeIn([list], Map({
                    sendWelcomeEmail: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        payload: fromJS(action.payload)
                    })
                }));
            return newState;

        case SEND_WELCOME_EMAIL_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    sendWelcomeEmail: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    sendWelcomeEmail: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        // Check user exits
        case CHECK_USER_EXIST_INITIALIZE:
        case CHECK_USER_EXIST_REQUEST:

            newState = state;

            if (state.getIn([detail, 'checkUserExist'])) newState = newState.removeIn([detail, 'checkUserExist']);
            if (state.getIn([list, 'checkUserExist'])) newState = newState.removeIn([list, 'checkUserExist']);

            newState = newState
                .mergeIn([detail], Map({
                    checkUserExist: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    checkUserExist: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        case CHECK_USER_EXIST_SUCCESS:

            newState = state
                .mergeIn([detail], Map({
                    checkUserExist: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        payload: fromJS(action.payload)
                    })
                }))
                .mergeIn([list], Map({
                    checkUserExist: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        payload: fromJS(action.payload)
                    })
                }));
            return newState;

        case CHECK_USER_EXIST_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    checkUserExist: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    checkUserExist: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        // Add organization to user
        case USER_ADD_ORGANIZATION_REQUEST:

            newState = state;

            if (state.getIn([detail, 'addOrganizationToUser'])) newState = newState.removeIn([detail, 'addOrganizationToUser']);
            if (state.getIn([list, 'addOrganizationToUser'])) newState = newState.removeIn([list, 'addOrganizationToUser']);

            newState = newState
                .mergeIn([detail], Map({
                    addOrganizationToUser: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    addOrganizationToUser: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;

        case USER_ADD_ORGANIZATION_SUCCESS:

            // Add org
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    addOrganizationToUser: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        payload: detailUpdatedPayload
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));
                listUpdatedPayload = listUpdatedPayload.sortBy( item => item.get('created') );

                newState = newState
                    .mergeIn([list], Map({
                        addOrganizationToUser: Map({
                            isFetching: action.isFetching,
                            error: action.error,
                            payload: listUpdatedPayload
                        })
                    }));
            }

            return newState;

        case USER_ADD_ORGANIZATION_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    addOrganizationToUser: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    addOrganizationToUser: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        // Add groups to user
        case USER_ADD_GROUPS_REQUEST:

            newState = state;

            if (state.getIn([detail, 'addGroupsToUser'])) newState = newState.removeIn([detail, 'addGroupsToUser']);
            if (state.getIn([list, 'addGroupsToUser'])) newState = newState.removeIn([list, 'addGroupsToUser']);

            newState = newState
                .mergeIn([detail], Map({
                    addGroupsToUser: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    addGroupsToUser: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;

        case USER_ADD_GROUPS_SUCCESS:

            // Add groups
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    addGroupsToUser: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            payload: listUpdatedPayload,
                            addGroupsToUser: Map({
                                isFetching: action.isFetching,
                                error: action.error
                            })
                        }));
                }
            }

            return newState;

        case USER_ADD_GROUPS_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    addGroupsToUser: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    addGroupsToUser: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        // Delete groups from user
        case USER_DELETE_GROUPS_REQUEST:

            newState = state;

            if (state.getIn([detail, 'deleteGroupsFromUser'])) newState = newState.removeIn([detail, 'deleteGroupsFromUser']);
            if (state.getIn([list, 'deleteGroupsFromUser'])) newState = newState.removeIn([list, 'deleteGroupsFromUser']);

            newState = newState
                .mergeIn([detail], Map({
                    deleteGroupsFromUser: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    deleteGroupsFromUser: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;

        case USER_DELETE_GROUPS_SUCCESS:

            // Delete groups
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    deleteGroupsFromUser: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], Map({
                            payload: listUpdatedPayload,
                            deleteGroupsFromUser: Map({
                                isFetching: action.isFetching,
                                error: action.error
                            })
                        }));
                }
            }

            return newState;

        case USER_DELETE_GROUPS_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    deleteGroupsFromUser: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    deleteGroupsFromUser: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        // Add roles to user
        case USER_ADD_ROLES_REQUEST:

            newState = state;

            if (state.getIn([detail, 'addRolesToUser'])) newState = newState.removeIn([detail, 'addRolesToUser']);
            if (state.getIn([list, 'addRolesToUser'])) newState = newState.removeIn([list, 'addRolesToUser']);

            newState = newState
                .mergeIn([detail], Map({
                    addRolesToUser: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    addRolesToUser: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;

        case USER_ADD_ROLES_SUCCESS:

            // Add roles
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    addRolesToUser: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            payload: listUpdatedPayload,
                            addRolesToUser: Map({
                                isFetching: action.isFetching,
                                error: action.error
                            })
                        }));
                }
            }

            return newState;

        case USER_ADD_ROLES_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    addRolesToUser: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    addRolesToUser: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        // Delete roles from user
        case USER_DELETE_ROLES_REQUEST:

            newState = state;

            if (state.getIn([detail, 'deleteUsersFromRole'])) newState = newState.removeIn([detail, 'deleteUsersFromRole']);
            if (state.getIn([list, 'deleteUsersFromRole'])) newState = newState.removeIn([list, 'deleteUsersFromRole']);

            newState = newState
                .mergeIn([detail], Map({
                    deleteUsersFromRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    deleteUsersFromRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;

        case USER_DELETE_ROLES_SUCCESS:

            // Delete roles
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    deleteUsersFromRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);
                    newState = newState
                        .mergeIn([list], Map({
                            payload: listUpdatedPayload,
                            deleteUsersFromRole: Map({
                                isFetching: action.isFetching,
                                error: action.error
                            })
                        }));
                }
            }

            return newState;

        case USER_DELETE_ROLES_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    deleteUsersFromRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    deleteUsersFromRole: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;

        // Switch to advanced
        case SWITCH_USER_TO_ADVANCED_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'switchUserToAdvanced'])) newState = newState.removeIn([detail, 'switchUserToAdvanced']);
            if (state.getIn([list, 'switchUserToAdvanced'])) newState = newState.removeIn([list, 'switchUserToAdvanced']);

            newState = newState
                .mergeIn([detail], Map({
                    switchUserToAdvanced: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    switchUserToAdvanced: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;

        case SWITCH_USER_TO_ADVANCED_SUCCESS:

            // Switch user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    switchUserToAdvanced: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            payload: listUpdatedPayload,
                            switchUserToAdvanced: Map({
                                isFetching: action.isFetching,
                                error: action.error
                            })
                        }));
                }

            }

            return newState;

        case SWITCH_USER_TO_ADVANCED_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    switchUserToAdvanced: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    switchUserToAdvanced: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        // Switch to basic
        case SWITCH_USER_TO_BASIC_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'switchUserToBasic'])) newState = newState.removeIn([detail, 'switchUserToBasic']);
            if (state.getIn([list, 'switchUserToBasic'])) newState = newState.removeIn([list, 'switchUserToBasic']);

            newState = newState
                .mergeIn([detail], Map({
                    switchUserToBasic: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    switchUserToBasic: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;

        case SWITCH_USER_TO_BASIC_SUCCESS:

            // Switch user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    switchUserToBasic: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            payload: listUpdatedPayload,
                            switchUserToBasic: Map({
                                isFetching: action.isFetching,
                                error: action.error
                            })
                        }));
                }

            }

            return newState;

        case SWITCH_USER_TO_BASIC_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    switchUserToBasic: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    switchUserToBasic: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        case AUTH_TOKEN_CREATE_SUCCESS:
            newState = state;
            const authTokenUser = String(action.payload.user._id ||action.payload.user);

            // Check if user in detail section is the same user linked to the created auth token
            detailUpdatedPayload = state.getIn([detail, 'payload']);
            if (!!detailUpdatedPayload) {
                if (String(detailUpdatedPayload.get('_id')) === authTokenUser) {
                    let authTokens = detailUpdatedPayload.get('auth_tokens') || List([]);
                    authTokens = authTokens.push(Map(action.payload));
                    detailUpdatedPayload = detailUpdatedPayload.set('auth_tokens', authTokens);
                    newState = newState
                        .mergeIn([detail], Map({ payload: detailUpdatedPayload }));
                }
            }

            // Check if some user in the list section is the same user linked to the created auth token
            listUpdatedPayload = state.getIn([list, 'payload']);
            if (!!listUpdatedPayload) {
                listUpdatedPayload = listUpdatedPayload.map(elem => {
                    if (elem.get('_id') === authTokenUser) {
                        let authTokens = elem.get('auth_tokens') || List([]);
                        authTokens = authTokens.push(Map(action.payload));
                        elem = elem.set('auth_tokens', authTokens);
                    }
                    return elem;
                });
                newState = newState
                    .mergeIn([list], Map({ payload: listUpdatedPayload }));
            }

            return newState;
        case AUTH_TOKEN_UPDATE_SUCCESS:
            newState = state;

            // Check if user in detail section has the updated auth token
            detailUpdatedPayload = state.getIn([detail, 'payload']);
            if (!!detailUpdatedPayload) {
                index = detailUpdatedPayload.get('auth_tokens') && detailUpdatedPayload.get('auth_tokens').findIndex(token => String(token.get('_id')) === String(action.payload._id));
                if (index >= 0) {
                    detailUpdatedPayload = detailUpdatedPayload.setIn(['auth_tokens', index], Map(action.payload));
                    newState = newState
                        .mergeIn([detail], Map({ payload: detailUpdatedPayload }));
                }
            }

            // Check if some user in the list section has the updated auth token
            listUpdatedPayload = state.getIn([list, 'payload']);
            if (!!listUpdatedPayload) {
                listUpdatedPayload = listUpdatedPayload.map(elem => {
                    let index = elem.get('aut_tokens') && elem.get('auth_tokens').findIndex(token => String(token.get('_id')) === String(action.payload._id));
                    if (index >= 0) {
                        elem = elem.setIn(['auth_tokens', index], Map(action.payload));
                    }
                    return elem;
                });
                newState = newState
                    .mergeIn([list], Map({ payload: listUpdatedPayload }));
            }

            return newState;
        case AUTH_TOKEN_DELETE_SUCCESS:
            newState = state;

            // Check if user in detail section has the deleted auth token
            detailUpdatedPayload = state.getIn([detail, 'payload']);
            if (!!detailUpdatedPayload) {
                index = detailUpdatedPayload.get('auth_tokens') && detailUpdatedPayload.get('auth_tokens').findIndex(token => String(token.get('_id')) === String(action.payload._id));
                if (index >= 0) {
                    detailUpdatedPayload = detailUpdatedPayload.removeIn(['auth_tokens', index]);
                    newState = newState
                        .mergeIn([detail], Map({ payload: detailUpdatedPayload }));
                }
            }

            // Check if some user in the list section has the deleted auth token
            listUpdatedPayload = state.getIn([list, 'payload']);
            if (!!listUpdatedPayload) {
                listUpdatedPayload = listUpdatedPayload.map(elem => {
                    let index = elem.get('aut_tokens') && elem.get('auth_tokens').findIndex(token => String(token.get('_id')) === String(action.payload._id));
                    if (index >= 0) {
                        elem = elem.removeIn(['auth_tokens', index]);
                    }
                    return elem;
                });
                newState = newState
                    .mergeIn([list], Map({ payload: listUpdatedPayload }));
            }

            return newState;
        case USER_DELETE_REQUEST:
            newState = state;

            // Check if "delete" key exists
            // if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            // if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                // .mergeIn([detail], Map({
                //     isFetching: action.isFetching,
                //     error: action.error
                // }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case USER_DELETE_SUCCESS:
            newState = state
                .mergeIn([list], {
                    isFetching: action.isFetching,
                    error: action.error
                });

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                        });
                }
            }

            return newState;
        case USER_DELETE_ERROR:
            newState = state
                // .mergeIn([detail], Map({
                //     isFetching: action.isFetching,
                //     error: action.error,
                //     errorMessage: action.errorMessage
                // }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;
        default:
            return state;
    }
};

export default users;