import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useRegisterSW } from "virtual:pwa-register/react"

// App main routes
import { default as Routes } from "./config/routes.jsx"

// Actions
import { addNotificationArea } from "@biuwer/redux/src/system/notification-area/notification-area-actions"

function useReduxDispatch() {
    const dispatch = useDispatch()
    return {
        dispatch,
        addNotificationArea: (notification) => {
            dispatch(addNotificationArea(notification));
        },
    }
}

export default function App() {

    const { addNotificationArea } = useReduxDispatch()

    const reloadSW = "__RELOAD_SW__"

    const isProduction = process.env.NODE_ENV !== "development" && window.location.hostname !== "localhost"

    const {
        offlineReady: [offlineReady],
        needRefresh: [needRefresh],
        updateServiceWorker,
    } = useRegisterSW({
        onRegisteredSW(swUrl, r) {
            !isProduction && console.log(`Service Worker at: ${swUrl}`)
            // @ts-expect-error just ignore
            if (reloadSW === "true") {
                !isProduction && console.log("Checking for sw update")
                r?.update()
            } else {
                !isProduction && console.log("SW Registered")
            }
        },
        onRegisterError(error) {
            !isProduction && console.log('SW registration error', error)
        },
    })

    useEffect(() => {
        if (needRefresh) {
            !isProduction && console.log("New content is available and will be used when all tabs for this page are closed of after click on the in-app notification")

            addNotificationArea({
                id: "updateAvailable",
                priority: -5,
                closeIcon: false,
                contentProps: {
                    onUpdateApp: updateServiceWorker
                }
            })
        }
    }, [needRefresh])

    useEffect(() => {
        if (offlineReady) {
            !isProduction && console.log("Content is cached for offline use.")
        }
    }, [offlineReady])

    return (
        <Routes />
    )
}