import { Map, List, fromJS } from 'immutable';

import {
    DATASETS_FETCH_REQUEST, DATASETS_FETCH_SUCCESS, DATASETS_FETCH_ERROR,
    DATASET_INITIALIZE, DATASET_LIST_INITIALIZE,DATASET_DETAIL_INITIALIZE,
    DATASET_FETCH_REQUEST, DATASET_FETCH_SUCCESS, DATASET_FETCH_ERROR,
    DATASET_CREATE_REQUEST, DATASET_CREATE_SUCCESS, DATASET_CREATE_ERROR,
    DATASET_UPDATE_REQUEST, DATASET_UPDATE_SUCCESS, DATASET_UPDATE_ERROR,
    DATASET_DELETE_REQUEST, DATASET_DELETE_SUCCESS, DATASET_DELETE_ERROR,
    DATASET_IMPORT_REQUEST, DATASET_IMPORT_SUCCESS, DATASET_IMPORT_ERROR,
    DATASET_QUERY_REQUEST, DATASET_QUERY_SUCCESS, DATASET_QUERY_ERROR,
    DATASET_VIEW_TEST_REQUEST, DATASET_VIEW_TEST_SUCCESS, DATASET_VIEW_TEST_ERROR,
    MANAGED_DATASET_EXECUTIONS_REQUEST, MANAGED_DATASET_EXECUTIONS_SUCCESS, MANAGED_DATASET_EXECUTIONS_ERROR
} from './datasets-actions.js';

import {
    UPDATE_PROFILE_SUCCESS, CHANGE_OWNER_SUCCESS
} from "@biuwer/redux/src/system/security/security-actions";

import {
    FILE_IMPORT_REQUEST, FILE_IMPORT_SUCCESS
} from '../content/content-actions';

// Libs
import { defaultContext } from "@biuwer/redux/src/config/constants";

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

/**
 * Datasets Reducers
 */

export default (state = initialState, action) =>{

    let newState, dataset, index, updatedPayload, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || defaultContext}`;
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch (action.type) {

        // Necessary to initialize a specific dataset state
        case DATASET_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));

            return newState;
        case DATASET_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;
        case DATASET_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;
        case DATASETS_FETCH_REQUEST:

            newState = state;

            // Datasets fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case DATASETS_FETCH_SUCCESS:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case DATASETS_FETCH_ERROR:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;
        case DATASET_FETCH_REQUEST:

            newState = state;

            // Dataset fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case DATASET_FETCH_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case DATASET_FETCH_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case DATASET_CREATE_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case DATASET_CREATE_SUCCESS:

            // Update payload with new created dataset
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case DATASET_CREATE_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;
        case DATASET_UPDATE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case DATASET_UPDATE_SUCCESS:

            // Update payload with updated user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            error: action.error,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case DATASET_UPDATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;
        case FILE_IMPORT_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case FILE_IMPORT_SUCCESS:

            // Check if payload is a List (Array)
            if (List.isList(state.get('payload'))) {

                // Find the index of the group to be deleted inside the List
                index = state.get('payload').findIndex(item => item.get('_id') === action.payload._id);

                // Update the payload list
                if (index !== -1) {
                    updatedPayload = state.get('payload').update(index, item => fromJS(action.payload));
                } else {
                    updatedPayload = state.get('payload').push(fromJS(action.payload));
                }

            // If payload is a Map the payload to be updated is action.payload itself
            } else if (Map.isMap(state.get('payload')) && state.getIn(['payload', '_id']) === action.payload._id) {
                updatedPayload = fromJS(action.payload);
            } else {
                updatedPayload = state.get('payload');
            }

            newState = state
                .remove('deleted')
                .remove('created')
                .remove('updated')
                .remove('issuePayload')
                .merge({
                    isFetching: action.isLoading,
                    payload: updatedPayload
                });
            return newState;
        case DATASET_IMPORT_REQUEST:
            newState = state
                .mergeIn([list], {
                    isFetching: action.isFetching,
                    issue: action.issue
                });
            return newState;
        case DATASET_IMPORT_SUCCESS:
            newState = state
                .mergeIn([list], {
                    isFetching: action.isFetching,
                    issue: action.issue
                });
            return newState;
        case DATASET_IMPORT_ERROR:
            newState = state
                .mergeIn([list], {
                    isFetching: action.isFetching,
                    issue: action.issue
                });
            return newState;
        case DATASET_DELETE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case DATASET_DELETE_SUCCESS:

            // Update payload with deleted data connection
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    isFetching: action.isFetching,
                    error: action.error,
                    deleted: action.deleted
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                            isFetching: action.isFetching,
                            error: action.error,
                            deleted: action.deleted
                        });
                }
            }

            return newState;
        case DATASET_DELETE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;
        case DATASET_QUERY_REQUEST:
            dataset = Map().set('isFetching', action.isFetching).set('error', action.error);
            newState = state.setIn([action.source, action.queryAlias], dataset);
            return newState;

        case DATASET_QUERY_SUCCESS:
            dataset = Map().set('isFetching', action.isFetching)
                .set('error', action.error)
                .set('dataWarnings', fromJS(action.dataWarnings))
                .set('dataErrors', fromJS(action.dataErrors));
            newState = state.setIn([action.source, action.queryAlias], dataset);
            return newState;
        case DATASET_QUERY_ERROR:
            dataset = Map(fromJS(action.payload)).set('isFetching', action.isFetching).set('error', action.error);
            newState = state.setIn([action.source, action.queryAlias], dataset);
            return newState;

        case DATASET_VIEW_TEST_REQUEST:
            newState = state;

            if (state.getIn([detail, 'issue'])) newState = newState.removeIn([detail, 'issue']);
            if (state.getIn([detail, 'issuePayload'])) newState = newState.removeIn([detail, 'issuePayload']);
            if (state.getIn([detail, 'errorMessage'])) newState = newState.removeIn([detail, 'errorMessage']);
            if (state.getIn([detail, 'error'])) newState = newState.removeIn([detail, 'error']);
            if (state.getIn([detail, 'errorMessage'])) newState = newState.removeIn([detail, 'errorMessage']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;

        case DATASET_VIEW_TEST_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    payload: fromJS(action.payload)
                }));
            return newState;
        case DATASET_VIEW_TEST_ERROR:
        case MANAGED_DATASET_EXECUTIONS_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));
            return newState;

        case MANAGED_DATASET_EXECUTIONS_REQUEST:
                newState = state;
                newState = newState
                    .mergeIn([detail], Map({
                        isFetching: action.isFetching,
                        error: action.error
                    }));
                return newState;
    
        case MANAGED_DATASET_EXECUTIONS_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    payload: state.get(detail).get('payload').set('executions', fromJS(action.payload))
                }));
            return newState;

        case UPDATE_PROFILE_SUCCESS:
        case CHANGE_OWNER_SUCCESS:

            newState = state;

            // Check objectType before to check if proceed to update reducer data
            if (action.objectType === 'dataset' && action.payload && action.payload.length > 0) {
                action.payload.forEach(object => {

                    if (String(state.getIn([detail, 'payload', '_id'])) === String(object._id)) {

                        // If payload is an Immutable Map and has the same _id that the action given object info
                        // Check permissions (platform and object profile_level) before update
                        if (object.readOneAuthorization || (!!object.profile_level && object.profile_level <= 4)) {

                            //Enough permissions to read, object updated
                            newState = newState.setIn([detail, 'payload', 'profiles'], fromJS(object.profiles));
                            newState = newState.setIn([detail, 'payload', 'profile_level'], object.profile_level);
                            newState = newState.setIn([detail, 'payload', 'owner'], fromJS(object.owner));
                        } else {

                            // Not enough permissions, delete payload from reducer
                            newState = state.setIn([detail, 'payload'], null);
                        }
                    }

                    if (state.getIn([list, 'payload'])) {
                        let index = state.getIn([list, 'payload']).findIndex(connection => String(connection.get('_id')) === String(object._id));
                        if (object.changed && index !== -1) {

                            // Check permissions of the object profiles updated and platform permissions
                            if (object.readAuthorization || (!!object.profile_level && object.profile_level <= 4)) {

                                // Enough permissions to read, profiles and profile_level is updated
                                newState = newState.setIn([list, 'payload', index, 'profiles'], fromJS(object.profiles));
                                newState = newState.setIn([list, 'payload', index, 'profile_level'], object.profile_level);
                                newState = newState.setIn([list, 'payload', index, 'owner'], fromJS(object.owner));
                            } else {

                                // Not enough permissions after profiles update, exclude object from List
                                newState = state.setIn([list, 'payload'], newState.getIn([list, 'payload']).filter(connection => String(connection.get('_id')) !== String(object._id)));
                            }
                        }
                    }
                });
            }

            // // Check objectType before to check if proceed to update reducer data
            // if (action.objectType === 'dataset' && action.payload && action.payload.length > 0) {
            //     action.payload.forEach(object => {
            //
            //         // Check if payload is an Immutable List (array of values)
            //         if (List.isList(state.get('payload'))) {
            //             let index = state.get('payload').findIndex(page => page.get('_id') === object._id);
            //             if (object.changed && index !== -1) {
            //
            //                 // Check permissions of the object profiles updated and platform permissions
            //                 if (Auth.isAuthorized('core.dataset.query') || (!!object.profile_level && object.profile_level <= 4)) {
            //
            //                     // Enough permissions to read, profiles and profile_level is updated
            //                     newState = state.setIn(['payload', index, 'profiles'], fromJS(object.profiles));
            //                     newState = newState.setIn(['payload', index, 'profile_level'], object.profile_level);
            //                     newState = newState.setIn(['payload', index, 'owner'], fromJS(object.owner));
            //                 } else {
            //
            //                     // Not enough permissions after profiles update, exclude object from List
            //                     newState = state.set('payload', state.get('payload').filter(page => page.get('_id') !== object._id));
            //                 }
            //             }
            //         } else if (Map.isMap(state.get('payload')) && state.getIn(['payload', '_id']) === object._id) {
            //
            //             // If payload is an Immutable Map and has the same _id that the action given object info
            //             // Check permissions (platform and object profile_level) before update
            //             if (Auth.isAuthorized('core.dataset.readOne') || (!!object.profile_level && object.profile_level <= 4)) {
            //
            //                 //Enough permissions to read, object updated
            //                 newState = state.setIn(['payload', 'profiles'], fromJS(object.profiles));
            //                 newState = newState.setIn(['payload', 'profile_level'], object.profile_level);
            //                 newState = newState.setIn(['payload', 'owner'], fromJS(object.owner));
            //             } else {
            //
            //                 // Not enough permissions, delete payload from reducer
            //                 newState = state.set('payload', null);
            //             }
            //         } else {
            //             newState = state;
            //         }
            //     });
            // } else {
            //     newState = state;
            // }
            return newState;
        default:
            return state;
    }
}
