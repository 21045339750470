export const PROMO_CODE_DETAIL = `
    _id
    code
    coupon {
        amount_off
        percent_off
        duration
        num_repetitions
        interval
        desc_i18n {
            lang
            value
        }
    }
`;
