import moment from "moment";

import paymentsErrorsCatalog from "./payments-errors-catalog"

export const stripeKey = process.env.NODE_ENV === 'production' && process.env.REACT_APP_SEGMENT_ENV === 'production' ?
    process.env.REACT_APP_STRIPE_LIVE_KEY : process.env.REACT_APP_STRIPE_TEST_KEY;

export const paymentMethods = {
    creditCard: 'creditCard',
    directDebit: 'directDebit',
    wireTransfer: 'wireTransfer'
};

export const defaultPaymentMethod = paymentMethods.creditCard;

export const cardElementStyle = {
    base: {
        fontSize: '16px',
        color: '#424770',
        // fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
            color: '#aab7c4',
        },
    },
    complete: {},
    empty: {},
    invalid: {}
};

export const DEFAULT_PAYMENT_ERROR_KEY = "unexpected"

export const checkCardExpiryDate = (cardData) => {

    if (!cardData) return false;

    const { exp_month, exp_year } = cardData;
    const expiryDate = moment(`01/${exp_month}/${exp_year}`, `DD/MM/YYYY`).valueOf();

    return expiryDate > Date.now();
};

export function getPaymentError(error) {
    const errorCode = error?.code
    const errorDeclineCode = error?.decline_code ?? null

    const code = `${errorCode}${errorDeclineCode ? `_${errorDeclineCode}` : ""}`

    return paymentsErrorsCatalog[code] ?? paymentsErrorsCatalog[DEFAULT_PAYMENT_ERROR_KEY]
}