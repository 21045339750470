import request from 'superagent';
import i18n from '@biuwer/core/src/i18n';

// Libs
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import { defaultContext } from "@biuwer/redux/src/config/constants";
import { PAYMENT_INTENT, SETUP_PAYMENT } from "./payments-gql";
import { EXTENDED_ORGANIZATION_DETAIL } from "@biuwer/redux/src/system/organizations/organizations-gql";

// Notifications
import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

export const PAYMENTS_INITIALIZE = 'PAYMENTS_INITIALIZE';
export const PAYMENT_DETAIL_INITIALIZE = 'PAYMENT_DETAIL_INITIALIZE';
export const PAYMENT_LIST_INITIALIZE = 'PAYMENT_LIST_INITIALIZE';

export const PAYMENT_CREATE_REQUEST = 'PAYMENT_CREATE_REQUEST';
export const PAYMENT_CREATE_SUCCESS = 'PAYMENT_CREATE_SUCCESS';
export const PAYMENT_CREATE_ERROR = 'PAYMENT_CREATE_ERROR';

export const PAYMENT_UPDATE_REQUEST = 'PAYMENT_UPDATE_REQUEST';
export const PAYMENT_UPDATE_SUCCESS = 'PAYMENT_UPDATE_SUCCESS';
export const PAYMENT_UPDATE_ERROR = 'PAYMENT_UPDATE_ERROR';

export const PAYMENT_DEFAULT_REQUEST = 'PAYMENT_DEFAULT_REQUEST';
export const PAYMENT_DEFAULT_SUCCESS = 'PAYMENT_DEFAULT_SUCCESS';
export const PAYMENT_DEFAULT_ERROR = 'PAYMENT_DEFAULT_ERROR';

export const PAYMENT_DELETE_REQUEST = 'PAYMENT_DELETE_REQUEST';
export const PAYMENT_DELETE_SUCCESS = 'PAYMENT_DELETE_SUCCESS';
export const PAYMENT_DELETE_ERROR = 'PAYMENT_DELETE_ERROR';

export const PAYMENT_SETUP_REQUEST = 'PAYMENT_SETUP_REQUEST';
export const PAYMENT_SETUP_SUCCESS = 'PAYMENT_SETUP_SUCCESS';
export const PAYMENT_SETUP_ERROR = 'PAYMENT_SETUP_ERROR';

export const PAYMENT_PROCESS_REQUEST = 'PAYMENT_PROCESS_REQUEST';
export const PAYMENT_PROCESS_SUCCESS = 'PAYMENT_PROCESS_SUCCESS';
export const PAYMENT_PROCESS_ERROR = 'PAYMENT_PROCESS_ERROR';

// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, showNotification = true) => {
    let skeleton = { type }, notification;

    switch (type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false,
                context: context
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                context: context
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body,
                context: context
            };
            break;
        default:
            break;
    }

    switch(type) {
        case PAYMENT_CREATE_ERROR:
        case PAYMENT_UPDATE_ERROR:
        case PAYMENT_DELETE_ERROR:
        case PAYMENT_DEFAULT_ERROR:
            skeleton.issuePayload = {
                ...skeleton.issuePayload || {},
            };
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        case PAYMENT_CREATE_SUCCESS:
            skeleton.created = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.addSuccess', {
                    name: i18n.t('payment.creditCards.title'),
                    context: 'female',
                    count: 1
                })
            };
            break;
        case PAYMENT_UPDATE_SUCCESS:
        case PAYMENT_DEFAULT_SUCCESS:
            skeleton.updated = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', {
                    name: i18n.t('payment.creditCards.title'),
                    context: 'female',
                    count: 1
                })
            };
            break;
        case PAYMENT_DELETE_SUCCESS:
            skeleton.deleted = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', {
                    name: i18n.t('payment.creditCards.title'),
                    context: 'female',
                    count: 1
                })
            };
            break;
        case PAYMENT_SETUP_SUCCESS:
            skeleton.created = true;
            break;
        case PAYMENT_SETUP_REQUEST:
        case PAYMENT_SETUP_ERROR:
            break;
        case PAYMENT_PROCESS_SUCCESS:
            skeleton.created = true;
            break;
        case PAYMENT_PROCESS_REQUEST:
        case PAYMENT_PROCESS_ERROR:
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    };
};

export const initializePayments = () => {
    return {
        type: PAYMENTS_INITIALIZE
    }
};

export const initializeDetailPayments = (context = defaultContext) => {
    return {
        type: PAYMENT_DETAIL_INITIALIZE,
        context
    }
};

export const initializeListPayments = (context = defaultContext) => {
    return {
        type: PAYMENT_LIST_INITIALIZE,
        context
    }
};

/**
 * Payment config actions
 * */
export const addPaymentCard = (paymentData, context, gql = EXTENDED_ORGANIZATION_DETAIL) => {
    return async dispatch => {
        const token = Auth.getLocalJwt();

        try {

            dispatch(generateSkeleton(PAYMENT_CREATE_REQUEST, null, context));

            const query = {
                query: `mutation($cardData: PaymentMethodInput) {
                    addPaymentCard(cardData: $cardData) {
                        ${gql}
                    }
                }`,
                variables: {
                    cardData: paymentData
                }
            };

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(PAYMENT_CREATE_ERROR, 'Error adding payment card to org', context));
                } else if (res.body && res.body.data && res.body.data.addPaymentCard) {
                    dispatch(generateSkeleton(PAYMENT_CREATE_SUCCESS, res.body.data.addPaymentCard, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(PAYMENT_CREATE_ERROR, e, context));
        }
    };
};

export const updatePaymentCard = (cardData, cardId, context = defaultContext, gql = EXTENDED_ORGANIZATION_DETAIL) => {
    return async dispatch => {
        const token = Auth.getLocalJwt();

        try {

            dispatch(generateSkeleton(PAYMENT_UPDATE_REQUEST, null, context));

            const query = {
                query: `mutation($cardId: String!, $cardData: PaymentMethodInput!) {
                    updatePaymentCard(cardId: $cardId, cardData: $cardData) {
                        ${gql}
                    }
                }`,
                variables: {
                    cardId: cardId,
                    cardData: cardData
                }
            };

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(PAYMENT_UPDATE_ERROR, 'Error updating payment card in org', context));
                } else if (res.body && res.body.data && res.body.data.updatePaymentCard) {
                    dispatch(generateSkeleton(PAYMENT_UPDATE_SUCCESS, res.body.data.updatePaymentCard, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(PAYMENT_UPDATE_ERROR, e, context));
        }
    };
};

export const deletePaymentCard = (cardId, context = defaultContext, gql = EXTENDED_ORGANIZATION_DETAIL) => {
    return async dispatch => {

        const token = Auth.getLocalJwt();

        try {
            dispatch(generateSkeleton(PAYMENT_DELETE_REQUEST, null, context));

            const query = {
                query: `mutation($cardId: String!) {
                    deletePaymentCard(cardId: $cardId) {
                        ${gql}
                    }
                }`,
                variables: {
                    cardId: cardId
                }
            }

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(PAYMENT_DELETE_ERROR, 'Error deleting payment card from org', context));
                } else if (res.body && res.body.data && res.body.data.deletePaymentCard) {
                    dispatch(generateSkeleton(PAYMENT_DELETE_SUCCESS, res.body.data.deletePaymentCard, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(PAYMENT_DELETE_ERROR, e, context));
        }
    };
};

export const setDefaultPaymentCard = (cardId, context = defaultContext, gql = EXTENDED_ORGANIZATION_DETAIL) => {
    return async dispatch => {
        const token = Auth.getLocalJwt();

        try {

            dispatch(generateSkeleton(PAYMENT_DEFAULT_REQUEST, null, context));

            const query = {
                query: `mutation($cardId: String!) {
                    setDefaultPaymentCard(cardId: $cardId) {
                        ${gql}
                    }
                }`,
                variables: {
                    cardId: cardId
                }
            };

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(PAYMENT_DEFAULT_ERROR, 'Error setting default payment card', context));
                } else if (res.body && res.body.data && res.body.data.setDefaultPaymentCard) {
                    dispatch(generateSkeleton(PAYMENT_DEFAULT_SUCCESS, res.body.data.setDefaultPaymentCard, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(PAYMENT_DEFAULT_ERROR, e, context));
        }
    }
};

/**
 * Payment actions.
* */
/**
 * Setup payment method for future usages
 * @param {String} paymentId Payment method Id
 * @param {String} context redux context
 * @param {String} gql GraphQL query
 * @returns
 */
export const setupPayment = (paymentId, context = defaultContext, gql = SETUP_PAYMENT) => {
    return async dispatch => {
        const token = Auth.getLocalJwt();

        try {

            dispatch(generateSkeleton(PAYMENT_SETUP_REQUEST, null, context));

            const query = {
                query: `mutation($paymentId: String!) {
                    setupPayment(paymentId: $paymentId) {
                        ${gql}
                    }
                }`,
                variables: {
                    paymentId: paymentId
                }
            };

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(PAYMENT_SETUP_ERROR, 'Error creating setup payment', context));
                } else if (res.body && res.body.data && res.body.data.setupPayment) {
                    dispatch(generateSkeleton(PAYMENT_SETUP_SUCCESS, res.body.data.setupPayment, context));
                }
            }
        } catch (e) {
            dispatch(generateSkeleton(PAYMENT_SETUP_ERROR, e, context));
        }
    };
};

/**
 * Generate a Payment Intent for given order metadata
 * @param {Object} orderInfo order metadata object
 * @param {String} context redux context
 * @param {String} gql GraphQL query
 * @returns
 */
export const payNow = (orderInfo, context = defaultContext, gql = PAYMENT_INTENT) => {
    return async dispatch => {
        const token = Auth.getLocalJwt();

        try {
            dispatch(generateSkeleton(PAYMENT_PROCESS_REQUEST, null, context));

            const query = {
                query: `mutation($orderId: Float!) {
                    payNow(orderId: $orderId) {
                        ${gql}
                    }
                }`,
                variables: {
                    orderId: Number(orderInfo._id)
                }
            };

            const res = await request
                .post('/api/gql/')
                .set('Authorization', `Bearer ${token}`)
                .send(query)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(PAYMENT_PROCESS_ERROR, 'Error processing payment intent', context));
                } else if (res.body && res.body.data && res.body.data.payNow) {
                    dispatch(generateSkeleton(PAYMENT_PROCESS_SUCCESS, res.body.data.payNow, context));
                }
            }

        } catch (e) {
            dispatch(generateSkeleton(PAYMENT_PROCESS_ERROR,e, context));
        }
    };
};

/**
 * Generate a Payment Intent for order linked to given payment link
 * @param {String} paymentLinkId encoded payment link obtained from URL params
 * @param {String} context redux context
 * @param {String} gql GraphQL query
 * @returns
 */
export function payOrderByPaymentLink(paymentLinkId, context = defaultContext, gql = PAYMENT_INTENT) {
    return async dispatch => {
        try {
            dispatch(generateSkeleton(PAYMENT_PROCESS_REQUEST, null, context))

            const query = {
                query: `mutation($id: String!) {
                    payOrderByPaymentLink(paymentLinkId: $id) {
                        ${gql}
                    }
                }`,
                variables: {
                    id: paymentLinkId
                }
            }

            dispatch(generateSkeleton(PAYMENT_PROCESS_REQUEST, null, context))

            const res = await request
                .post("/api/gql/")
                .send(query)
                .on("response", (response) => Auth.checkResponse(response))

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(PAYMENT_PROCESS_ERROR, "Error processing payment intent", context))
                } else if (res.body && res.body.data && res.body.data.payOrderByPaymentLink) {
                    dispatch(generateSkeleton(PAYMENT_PROCESS_SUCCESS, res.body.data.payOrderByPaymentLink, context))
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(PAYMENT_PROCESS_ERROR, err, context))
        }
    }
}
