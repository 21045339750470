import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Custom
import Loading from '@biuwer/common/src/components/loading';
import ExternalLayout from "../layouts/external-layout";

/**
 * Custom component wrapping an actual Route/Redirect in order to handle security
 * https://reacttraining.com/react-router/web/example/auth-workflow
 *
 * @param Component [Component to render in the Route]
 * @param failRedirect [Custom, not a react-router param - Path that will be used to redirect the app into]
 * @param restricted [Custom, not a react-router param - Bool set to know if the component is private or not]
 * @param loading [Custom, boolean]
 * @param isLoggedIn [Custom, boolean]
 * @param rest [React-Router props]
 * @returns {XML}
 * @constructor
 */
const CustomRoute =  ({ component: Component, failRedirect, restricted, loading, isLoggedIn, ...rest  }) => {

    const checkRedirectPath = (props, failRedirect) => {
        if (props.location.state && props.location.state.from) {
            return props.location.state.from.pathname;
        } else return failRedirect;
    };

    return (
        <Route
            {...rest}
            render={ props => (
                loading ? (
                    <Loading
                        show={loading}
                        applyElapse={true}
                    />
                ) : (
                    // Render component if we are logged and it's restricted OR if we aren't logged and it's not restricted
                    (isLoggedIn && restricted) || (!isLoggedIn && !restricted) ? (
                        (!isLoggedIn && !restricted) ? (
                            <ExternalLayout>
                                <Component {...props} />
                            </ExternalLayout>
                        ) : (
                            <Component {...props} />
                        )
                    ) : (
                        <Redirect to={{
                            pathname: checkRedirectPath(props, failRedirect),
                            state: {from: props.location}
                        }}
                        />
                    )
                )
            )}
        />
    );
};

export default CustomRoute;