import React, { Component } from 'react';
import { isEmail } from 'validator';
import Helmet from "react-helmet";

// Components
import TextInput from "@biuwer/common/src/components/text-input"
import InfoMessage from "@biuwer/common/src/components/info-message"
import HelmetLib from "@biuwer/common/src/libs/helmet-lib"

class ForgotPasswordForm extends Component {
    constructor(props) {
        super(props);

        this.state= {
            email: ''
        };

        this.handlePressEnter = this.handlePressEnter.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.oneTimeLogin = this.oneTimeLogin.bind(this);
        this.toggleScreen = this.toggleScreen.bind(this);
        this.onCloseErrorMessage = this.onCloseErrorMessage.bind(this);
        this.onChangeTextField = this.onChangeTextField.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
    }

    handlePressEnter(e) {
        const ENTER = 13;
        if(e.keyCode === ENTER && this.canSubmit(this.state)) {
            this.props.screen === 'forgot-password' ? this.forgotPassword() : this.oneTimeLogin();
        }
    }

    forgotPassword() {
        this.props.forgotPassword(this.state.email);
        this.setState({email: ''});
    }

    oneTimeLogin() {
        this.props.oneTimeLogin(this.state.email);
        this.setState({email: ''});
    }

    toggleScreen() {
        this.setState({
            email: ''
        });
        this.props.forgotPasswordCleanError();
        this.props.toggleScreen("signin");
    }

    // Error Message component event for close/hide the error
    onCloseErrorMessage() {
        this.props.forgotPasswordCleanError();
    }

    onChangeTextField(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    canSubmit(state) {
      return isEmail(state.email);
    }

    render() {
        const { t } = this.props;
        let bodyForm, message = null;

        if(this.props.forgotPasswordSuccess){
            message = (
                <InfoMessage
                    onCloseErrorMessage={this.onCloseErrorMessage}
                    message={this.props.screen === 'forgot-password' ? t('auth.forgotForm.confirmationMessage') : t('auth.oneTimeForm.confirmationMessage')}
                    type="success"
                />
            );
        } else if (this.props.forgotPasswordError){
            message = (
                <InfoMessage
                    onCloseErrorMessage={this.onCloseErrorMessage}
                    message={this.props.forgotPasswordErrorMessage}
                    type="danger"
                />
            );
        } else if (this.props.forgotPasswordIsFetching){
            message = (
                <div className="progress">
                    <div className="progress-bar-indeterminate" />
                </div>
            );
        }

        bodyForm = (
            <div>
                <Helmet
                    title={HelmetLib.generatePageTitle(t('auth.forgotForm.headerTitle'))}
                />
                <h4 className="p-t-35 text-center">{this.props.screen === 'forgot-password' ? t('auth.forgotForm.headerTitle') : t('auth.oneTimeForm.headerTitle')}</h4>
                <div id="form-login" className="p-t-15">
                    <TextInput
                        formStyling="default"
                        type="text"
                        name="email"
                        labelText="Login"
                        placeholder={t('auth.emailLabel')}
                        value={this.state.email}
                        onChange={this.onChangeTextField}
                        onKeyDown={this.handlePressEnter}
                    />

                    <div className="row">
                        <div className="col-md-12">
                            {message}
                        </div>
                    </div>

                    <button
                        key='cancel-button'
                        type="button"
                        className="btn btn-default btn-cons m-t-10"
                        onClick={this.toggleScreen}
                    >
                        {t('common.goBackLabel')}
                    </button>
                    <button
                        className="btn btn-info btn-cons m-t-10"
                        type="button"
                        onClick={this.props.screen === 'forgot-password' ? this.forgotPassword : this.oneTimeLogin}
                        disabled={!this.canSubmit(this.state)}
                    >
                        {this.props.screen === 'forgot-password' ? t('auth.forgotForm.submitButton') : t('auth.oneTimeForm.submitButton')}
                    </button>
                </div>
            </div>
        );

        return (
            <div className="animated fadeIn">
                {bodyForm}
            </div>
        );
    }
}

export default ForgotPasswordForm;
