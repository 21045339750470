import i18n from "@biuwer/core/src/i18n";

// Null values options
export const NULL_VALUES_NOTHING = 'empty';
export const NULL_VALUES_NULL = 'null';
export const NULL_VALUES_CUSTOM = 'custom';
export const NULL_VALUES_DEFAULT = NULL_VALUES_NOTHING;

/**
 *
 * @param t i18n translate function
 * @returns {Array<Object>}
 */
export const getNullOptions = (t = i18n.t) => {
    return [
        { _id: 1, name: t('cards.nullOptionShowNothing'), value: NULL_VALUES_NOTHING },
        { _id: 2, name: t('cards.nullOptionShowNull'), value: NULL_VALUES_NULL },
        { _id: 3, name: t('cards.nullOptionCustomValue'), value: NULL_VALUES_CUSTOM }
    ];
};

/**
 * Function to format null values based on column configuration
 * @param {String} nullValues null values treatment type
 * @param {String} nullCustomValue custom value to apply when null treatment type is "custom"
 */
export const formatNullValue = (nullValues, nullCustomValue) => {
    switch (nullValues) {
        case NULL_VALUES_NULL:
            return i18n.t('common.nullLabel');
        case NULL_VALUES_CUSTOM:
            return nullCustomValue || undefined;
        case NULL_VALUES_NOTHING:
        default:
            return undefined;
    }
};

export default {
    NULL_VALUES_NOTHING,
    NULL_VALUES_NULL,
    NULL_VALUES_CUSTOM,
    NULL_VALUES_DEFAULT,
    getNullOptions,
    formatNullValue
};