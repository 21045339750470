import { Map, fromJS } from 'immutable';
import { defaultContext } from "@biuwer/redux/src/config/constants";

/**
 * System Groups Reducer
 */

import {
    GROUPS_FETCH_REQUEST, GROUPS_FETCH_SUCCESS, GROUPS_FETCH_ERROR,
    GROUPS_INITIALIZE, GROUPS_LIST_INITIALIZE, GROUP_DETAIL_INITIALIZE,
    GROUP_FETCH_REQUEST, GROUP_FETCH_SUCCESS, GROUP_FETCH_ERROR,
    GROUP_UPDATE_REQUEST, GROUP_UPDATE_SUCCESS, GROUP_UPDATE_ERROR,
    GROUP_CHANGE_STATUS_REQUEST, GROUP_CHANGE_STATUS_SUCCESS, GROUP_CHANGE_STATUS_ERROR,
    GROUP_CREATE_REQUEST, GROUP_CREATE_SUCCESS, GROUP_CREATE_ERROR,
    GROUP_DELETE_REQUEST, GROUP_DELETE_SUCCESS, GROUP_DELETE_ERROR,
    GROUP_ADD_USERS_REQUEST, GROUP_ADD_USERS_SUCCESS, GROUP_ADD_USERS_ERROR,
    GROUP_ADD_ROLES_REQUEST, GROUP_ADD_ROLES_SUCCESS, GROUP_ADD_ROLES_ERROR,
    GROUP_DELETE_USERS_REQUEST, GROUP_DELETE_USERS_SUCCESS, GROUP_DELETE_USERS_ERROR,
    GROUP_DELETE_ROLES_REQUEST, GROUP_DELETE_ROLES_SUCCESS, GROUP_DELETE_ROLES_ERROR
} from './groups-actions.js';

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

const groups = (state = initialState, action) => {
    let newState, index = -1, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || defaultContext}`;
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch (action.type) {

        // Necessary to initialize groups state prior to create a new groups
        case GROUPS_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));
            return newState;
        case GROUP_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;
        case GROUPS_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;

        // Groups fetch (list)
        case GROUPS_FETCH_REQUEST:

            newState = state;

            // Groups fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case GROUPS_FETCH_SUCCESS:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case GROUPS_FETCH_ERROR:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;

        // Group fetch (detail)
        case GROUP_FETCH_REQUEST:

            newState = state;

            // Group fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case GROUP_FETCH_SUCCESS:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case GROUP_FETCH_ERROR:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage,
                issue: action.issue,
                issuePayload: fromJS(action.issuePayload),
            }));
            return newState;
        case GROUP_CREATE_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case GROUP_CREATE_SUCCESS:

            // Update payload with new created group
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case GROUP_CREATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;

        // Update group and change group status
        case GROUP_UPDATE_REQUEST:
        case GROUP_CHANGE_STATUS_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case GROUP_UPDATE_SUCCESS:
        case GROUP_CHANGE_STATUS_SUCCESS:

            // Update payload with updated user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            error: action.error,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case GROUP_UPDATE_ERROR:
        case GROUP_CHANGE_STATUS_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;

        // Delete group
        case GROUP_DELETE_REQUEST:
            newState = state
                .mergeIn([detail], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;
        case GROUP_DELETE_SUCCESS:

            // Update payload with deleted user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        deleted: action.deleted
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                            delete: Map({
                                isFetching: action.isFetching,
                                error: action.error,
                                deleted: action.deleted
                            })
                        });
                }
            }
            return newState;
        case GROUP_DELETE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        // Add users to group
        case GROUP_ADD_USERS_REQUEST:

            newState = state;

            if (state.getIn([detail, 'addUsersToGroup'])) newState = newState.removeIn([detail, 'addUsersToGroup']);
            if (state.getIn([list, 'addUsersToGroup'])) newState = newState.removeIn([list, 'addUsersToGroup']);

            newState = newState
                .mergeIn([detail], Map({
                    addUsersToGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    addUsersToGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;
        case GROUP_ADD_USERS_SUCCESS:

            // Add groups
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    addUsersToGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            payload: listUpdatedPayload,
                            addUsersToGroup: Map({
                                isFetching: action.isFetching,
                                error: action.error
                            })
                        }));
                }
            }
            return newState;
        case GROUP_ADD_USERS_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    addUsersToGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    addUsersToGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        // Delete users from group
        case GROUP_DELETE_USERS_REQUEST:

            newState = state;

            if (state.getIn([detail, 'deleteUsersFromGroup'])) newState = newState.removeIn([detail, 'deleteUsersFromGroup']);
            if (state.getIn([list, 'deleteUsersFromGroup'])) newState = newState.removeIn([list, 'deleteUsersFromGroup']);

            newState = newState
                .mergeIn([detail], Map({
                    deleteUsersFromGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    deleteUsersFromGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;
        case GROUP_DELETE_USERS_SUCCESS:

            // Delete groups
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    deleteUsersFromGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], Map({
                            payload: listUpdatedPayload,
                            deleteUsersFromGroup: Map({
                                isFetching: action.isFetching,
                                error: action.error
                            })
                        }));
                }
            }

            return newState;
        case GROUP_DELETE_USERS_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    deleteUsersFromGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    deleteUsersFromGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        // Add role to group
        case GROUP_ADD_ROLES_REQUEST:

            newState = state;

            if (state.getIn([detail, 'addRolesToGroup'])) newState = newState.removeIn([detail, 'addRolesToGroup']);
            if (state.getIn([list, 'addRolesToGroup'])) newState = newState.removeIn([list, 'addRolesToGroup']);

            newState = newState
                .mergeIn([detail], Map({
                    addRolesToGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    addRolesToGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;
        case GROUP_ADD_ROLES_SUCCESS:

            // Add roles
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    addRolesToGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            payload: listUpdatedPayload,
                            addRolesToGroup: Map({
                                isFetching: action.isFetching,
                                error: action.error
                            })
                        }));
                }
            }

            return newState;
        case GROUP_ADD_ROLES_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    addRolesToGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }))
                .mergeIn([list], Map({
                    addRolesToGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        errorMessage: action.errorMessage
                    })
                }));
            return newState;

        // Delete roles from group
        case GROUP_DELETE_ROLES_REQUEST:

            newState = state;

            if (state.getIn([detail, 'deleteRolesFromGroup'])) newState = newState.removeIn([detail, 'deleteRolesFromGroup']);
            if (state.getIn([list, 'deleteRolesFromGroup'])) newState = newState.removeIn([list, 'deleteRolesFromGroup']);

            newState = newState
                .mergeIn([detail], Map({
                    deleteRolesFromGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    deleteRolesFromGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;
        case GROUP_DELETE_ROLES_SUCCESS:

            // Delete roles
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    deleteRolesFromGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);
                    newState = newState
                        .mergeIn([list], Map({
                            payload: listUpdatedPayload,
                            deleteRolesFromGroup: Map({
                                isFetching: action.isFetching,
                                error: action.error
                            })
                        }));
                }
            }

            return newState;
        case GROUP_DELETE_ROLES_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    deleteRolesFromGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }))
                .mergeIn([list], Map({
                    deleteRolesFromGroup: Map({
                        isFetching: action.isFetching,
                        error: action.error
                    })
                }));
            return newState;

        default:
            return state;
    }
};

export default groups;