import { Map, fromJS } from 'immutable';

import { 
    API_KEYS_INITIALIZE, API_KEY_LIST_INITIALIZE, API_KEY_DETAIL_INITIALIZE,
    API_KEYS_FETCH_REQUEST, API_KEYS_FETCH_SUCCESS, API_KEYS_FETCH_ERROR,
    API_KEY_FETCH_REQUEST, API_KEY_FETCH_SUCCESS, API_KEY_FETCH_ERROR,
    API_KEY_CREATE_REQUEST, API_KEY_CREATE_SUCCESS, API_KEY_CREATE_ERROR,
    API_KEY_UPDATE_REQUEST, API_KEY_UPDATE_SUCCESS, API_KEY_UPDATE_ERROR,
    API_KEY_DELETE_REQUEST, API_KEY_DELETE_SUCCESS, API_KEY_DELETE_ERROR
} from './api-keys-actions';

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

/**
 * API Keys Reducer
 */

export default function(state = initialState, action) {
    let newState, index = -1, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || `primary`}`;
    const detail = `detail_${(action && action.context) || `primary`}`;

    switch (action.type) {
        case API_KEYS_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));
            return newState;
        case API_KEY_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;
        case API_KEY_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;
        case API_KEYS_FETCH_REQUEST:
            newState = state;

            // API Keys fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case API_KEYS_FETCH_SUCCESS:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case API_KEYS_FETCH_ERROR:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;
        case API_KEY_FETCH_REQUEST:
            newState = state;

            // API Key fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case API_KEY_FETCH_SUCCESS:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case API_KEY_FETCH_ERROR:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;
        case API_KEY_CREATE_REQUEST:
            newState = state;

            // Delete created key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case API_KEY_CREATE_SUCCESS:

            // Update payload with new created user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case API_KEY_CREATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;
        case API_KEY_UPDATE_REQUEST:
            newState = state;

            // Check if "deleted" key exists
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case API_KEY_UPDATE_SUCCESS:

            // Update payload with updated user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            error: action.error,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case API_KEY_UPDATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;
        case API_KEY_DELETE_REQUEST:
            newState = state;

            // Check if "deleted" key exists
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);
            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case API_KEY_DELETE_SUCCESS:

            // Update payload with deleted API Key
            detailUpdatedPayload = fromJS(action.payload);
            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    isFetching: action.isFetching,
                    error: action.error,
                    deleted: action.deleted
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the API Key index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex((item) => {
                    return item.get('_id') === action.payload._id;
                });

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                            isFetching: action.isFetching,
                            error: action.error,
                            deleted: action.deleted
                        });
                }
            }
            return newState;
            
        case API_KEY_DELETE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;
        default:
            return state;
    }
}