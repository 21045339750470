import { Map, fromJS } from "immutable";
import { defaultContext } from "@biuwer/redux/src/config/constants";

import {
    CUSTOM_MESSAGE_DETAIL_INITIALIZE,
    CUSTOM_MESSAGE_FETCH_REQUEST, CUSTOM_MESSAGE_FETCH_SUCCESS, CUSTOM_MESSAGE_FETCH_ERROR,
    CUSTOM_MESSAGE_CREATE_REQUEST, CUSTOM_MESSAGE_CREATE_SUCCESS, CUSTOM_MESSAGE_CREATE_ERROR,
    CUSTOM_MESSAGE_UPDATE_REQUEST, CUSTOM_MESSAGE_UPDATE_SUCCESS, CUSTOM_MESSAGE_UPDATE_ERROR
} from "./custom-messages-actions";

const initialState = fromJS({
    detail_primary: {}
});

export default function OrganizationCustomMessageReducer(state = initialState, action) {
    let newState, detailUpdatedPayload;

    // Get custom message context
    const detail = `detail_${action?.context}` || defaultContext;

    switch(action.type) {
        case CUSTOM_MESSAGE_DETAIL_INITIALIZE:
            newState = state.setIn([detail], Map({}));
            return newState;
        case CUSTOM_MESSAGE_FETCH_REQUEST:
            newState = state;

            // Delete created and updated keys if exists
            if (state.getIn([detail, "created"])) newState = newState.removeIn([detail, "created"]);
            if (state.getIn([detail, "updated"])) newState = newState.removeIn([detail, "updated"]);

            newState = newState.mergeIn([detail], Map({
                isFetching: action.isFetching,
                issue: action.issue
            }));

            return newState;
        case CUSTOM_MESSAGE_FETCH_SUCCESS:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                issue: action.issue,
                payload: fromJS(action.payload)
            }));

            return newState;
        case CUSTOM_MESSAGE_CREATE_REQUEST:
            newState = state;

            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);

            newState = newState.mergeIn([detail], Map({
                isFetching: action.isFetching,
                issue: action.issue
            }));

            return newState;
        case CUSTOM_MESSAGE_CREATE_SUCCESS:
            detailUpdatedPayload = fromJS(action.payload);

            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                issue: action.issue,
                created: action.created,
                payload: detailUpdatedPayload
            }));

            return newState;
            case CUSTOM_MESSAGE_UPDATE_REQUEST:
                newState = state;
    
                if (newState.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
    
                newState = newState.mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));
    
                return newState;
            case CUSTOM_MESSAGE_UPDATE_SUCCESS:
                detailUpdatedPayload = fromJS(action.payload);
    
                newState = state.mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));
    
                return newState;
            case CUSTOM_MESSAGE_FETCH_ERROR:
            case CUSTOM_MESSAGE_CREATE_ERROR:
            case CUSTOM_MESSAGE_UPDATE_ERROR:
                newState = state.mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));
    
                return newState;
        default:
            return state;
    }
}
