import { Map, fromJS } from 'immutable';

import {
    STEP_TYPES_FETCH_REQUEST, STEP_TYPES_FETCH_SUCCESS, STEP_TYPES_FETCH_ERROR,
    STEP_TYPE_INITIALIZE, STEP_TYPES_LIST_INITIALIZE, STEP_TYPE_DETAIL_INITIALIZE,
    STEP_TYPE_FETCH_REQUEST, STEP_TYPE_FETCH_SUCCESS, STEP_TYPE_FETCH_ERROR
} from './step-types-actions.js';

import { defaultContext } from "@biuwer/redux/src/config/constants";

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

/**
 * Step Types Reducers
 */

export default (state = initialState, action) =>{

    let newState;

    // Get list and detail context
    const list = `list_${(action && action.context) || defaultContext}`;
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch (action.type) {

        // Necessary to initialize a specific agent state
        case STEP_TYPE_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));

            return newState;
        case STEP_TYPE_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;
        case STEP_TYPES_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;
        case STEP_TYPES_FETCH_REQUEST:

            newState = state;

            // Step types fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case STEP_TYPES_FETCH_SUCCESS:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case STEP_TYPES_FETCH_ERROR:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;
        case STEP_TYPE_FETCH_REQUEST:

            newState = state;

            // Step type fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case STEP_TYPE_FETCH_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case STEP_TYPE_FETCH_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;
        default:
            return state;
    }
}
