import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { findIndex, orderBy, sum, min, max, isNil } from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import { grey } from "material-colors";
import * as styles from "@biuwer/common/src/components/virtualized-table-styles";
import { withTranslation } from 'react-i18next';
import { formatTimeMetric } from "@biuwer/common/src/libs/time-lib";
import { formatNullValue } from "../libs/null-values-lib";

class  HtmlDataTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableData: []
        };
    }

    componentWillMount() {
        let initialTableData = [];

        let { tableColumns } = this.props;

        if (this.props.tableData) {

            // Initial sort
            Object.assign(initialTableData, this.props.tableData);
        }

        let sortFields = [];
        const iterations = this.props.tableSettings.multiSortable
            ? this.props.tableSettings.defaultSortFields.length
            : this.props.tableSettings.defaultSortFields.length > 0
                ? 1
                : 0
        ;

        for (let i = 0; i < iterations; i++) {

            let fieldName = this.props.tableSettings.defaultSortFields[i];
            let dataField, dataType, fieldIndex = findIndex(tableColumns, { dataField: fieldName });

            if (fieldIndex >= 0) {
                dataField = tableColumns[fieldIndex].dataField instanceof Array ? tableColumns[fieldIndex].dataField[tableColumns[fieldIndex].sortableIndex] : tableColumns[fieldIndex].dataField;
                dataType = (tableColumns[fieldIndex].columnType) || 'string';
            } else {
                dataField = fieldName;
                dataType = '';
            }

            let sortFieldValue;
            switch(dataType) {
                case 'string':
                    sortFieldValue = row => this.deepValue(row, dataField) ? this.deepValue(row, dataField).toLowerCase() : dataField;
                    break;
                case 'date':
                case 'datetime':
                    sortFieldValue = row => moment(this.deepValue(row, dataField)).format('YYYYMMDD');
                    break;
                default:
                    sortFieldValue = dataField;
            }

            sortFields.push(sortFieldValue);
        }


        this.setState({
            tableData: orderBy(initialTableData, sortFields, this.props.tableSettings.multiSortable ? this.props.tableSettings.defaultSortDirections : this.props.tableSettings.defaultSortDirections[0]),
        });
    }

    // Local function to obtain deep object value based on path ('field.subfield')
    deepValue(obj, path) {
        const deepPath = path.split('.'),
            deepPathLen = deepPath.length;
        for (let i = 0; i < deepPathLen; i++) {
            obj = obj[deepPath[i]];
        }
        return obj;
    }

    calculateFooterOperation(dataField, operation){
        let result = 0;
        if (this.state.tableData.length > 0) {
            if (operation === 'count') {
                result = this.state.tableData.length;
            } else {
                let values = [];
                this.state.tableData.forEach(dataRow => {
                    values.push(Number(this.deepValue(dataRow, dataField)));
                });

                switch (operation) {
                    case 'sum':
                        result = sum(values);
                        break;
                    case 'mean':
                    case 'avg':
                        result = sum(values) / values.length;
                        break;
                    case 'min':
                        result = min(values);
                        break;
                    case 'max':
                        result = max(values);
                        break;
                    default:
                        break;
                }
            }
        }
        return result;
    }

    render() {
        const { t } = this.props;
        let { classes, tableClasses, tableSettings, tableColumns } = this.props;
        let { tableData } = this.state;

        let headerColumns = null,
            footerColumns = null,
            tableRows = [];

        let headerColumnStyles = {
            wrapper: {
                position: 'relative'
            },
            icon: {
                position: 'absolute',
                top: -5,
                right: -25
            }
        };

        headerColumns = tableColumns.map(column => {

            if (column.hidden) { return null }

            return (
                <th key={column} style={{
                    width: column.width || 100,
                    height: column.height || tableSettings.headerHeight,
                    textAlign: (!!column.style && column.style.textAlign) || tableSettings.headerStyle.textAlign,
                    verticalAlign: column.verticalAlign || tableSettings.headerStyle.verticalAlign,
                    color: column.color || tableSettings.headerStyle.color,
                    fontWeight: column.fontWeight || tableSettings.headerStyle.fontWeight || 600,
                    fontSize: /*column.fontSize || tableSettings.headerStyle.fontSize*/ 12,
                    paddingLeft: typeof column.paddingLeft !== 'undefined' ? column.paddingLeft : tableSettings.headerStyle.paddingLeft,
                    paddingRight: typeof column.paddingRight !== 'undefined' ? column.paddingRight : tableSettings.headerStyle.paddingRight,
                    borderBottom: '1px Solid',
                    borderColor: grey[300],
                    pageBreakInside: 'avoid'
                }}>
                        <span style={headerColumnStyles.wrapper}>
                            {column.title + (column.unit ? ' (' + column.unit + ')' : '')}
                        </span>
                </th>
            );
        });

        if (tableSettings.showFooter) {
            footerColumns = tableColumns.map(column => {
                let dataField = column.dataField.constructor === Array ? column.dataField[0] : column.dataField;

                if (column.hidden) { return null }

                let operationFooter;
                if (column.footer && column.footer.type === 'operation') {
                    operationFooter = this.calculateFooterOperation(dataField, column.footer.value);
                    if (column.time_metric) {
                        operationFooter = formatTimeMetric(operationFooter, column.time_level, column.time_format);
                    } else {
                        operationFooter = numeral(operationFooter).format(column.format);
                    }
                }

                return (
                    <th key={column} style={{
                        width: column.width || 100,
                        height: column.height || tableSettings.footerHeight,
                        textAlign: (!!column.style && column.style.textAlign) || tableSettings.footerStyle.textAlign,
                        color: column.color || tableSettings.footerStyle.color,
                        fontWeight: column.fontWeight || tableSettings.footerStyle.fontWeight || 600,
                        fontSize: /*column.fontSize || tableSettings.footerStyle.fontSize*/ 12,
                        pageBreakInside: 'avoid',
                        paddingLeft: (typeof column.paddingLeft !== 'undefined') ? column.paddingLeft : tableSettings.footerStyle.paddingLeft,
                        paddingRight: (typeof column.paddingRight !== 'undefined') ? column.paddingRight : tableSettings.footerStyle.paddingRight
                    }}>
                        <span style={headerColumnStyles.wrapper}>
                            {column.footer && column.footer.type === 'text' ? column.footer.value : null}
                            {column.footer && column.footer.type === 'number' ? numeral(column.footer.value).format(column.format) : null}
                            {column.footer && column.footer.type === 'operation' ? operationFooter : null}
                        </span>
                    </th>
                );
            });
        }

        if (tableData.length > 0) {
            tableData.forEach((row, key) => {
                let rowColumns = [];
                tableColumns.forEach((column) => {

                    if (column.hidden) { return null }

                    let dataValue;
                    let columnStyle = {
                        width: column.width || tableSettings.rowStyle.width,
                        height: column.height || tableSettings.rowHeight,
                        textAlign: (!!column.style && column.style.textAlign) || tableSettings.rowStyle.textAlign,
                        color: column.color || tableSettings.footerStyle.color,
                        backgroundColor: column.backgroundColor || tableSettings.footerStyle.backgroundColor,
                        fontWeight: column.fontWeight || tableSettings.rowStyle.fontWeight,
                        fontSize: /*column.fontSize || tableSettings.rowStyle.fontSize*/ 12,
                        paddingLeft: (typeof column.paddingLeft !== 'undefined') ? column.paddingLeft : tableSettings.rowStyle.paddingLeft,
                        paddingRight: (typeof column.paddingRight !== 'undefined') ? column.paddingRight : tableSettings.rowStyle.paddingRight,
                        borderBottom: tableSettings.rowStyle.borderBottom || '1px solid',
                        borderColor: tableSettings.rowStyle.borderBottomColor || grey[300],
                        pageBreakInside: 'avoid'
                    };

                    if (column.dataField && column.dataField.constructor === Array) {
                        dataValue = [];
                        for (let i = 0; i < column.dataField.length; i++) {
                            dataValue[i] = this.deepValue(row, column.dataField[i]);
                        }
                    } else {
                        dataValue = this.deepValue(row, column.dataField);
                    }

                    switch (column.columnType) {
                        case 'date':
                        case 'datetime':
                            rowColumns.push(
                                <td
                                    key={column}
                                    style={columnStyle}
                                    title={dataValue ? moment(dataValue).format(column.format || '') : ''}>
                                    {isNil(dataValue) ? formatNullValue(column.null_values, column.null_custom_value) : moment(dataValue).format(column.format || '')}
                                </td>
                            );
                            break;
                        case 'string':
                            if (column?.imageUrl) {
                                rowColumns.push(
                                    <td
                                        key={column}
                                        style={columnStyle}
                                        title={dataValue}>
                                        <img 
                                            src={isNil(dataValue) ? formatNullValue(column.null_values, column.null_custom_value) : dataValue}
                                            style={{maxWidth: '100%', maxHeight: '100%'}}
                                        />
                                    </td>
                                );
                            } else {
                                rowColumns.push(
                                    <td
                                        key={column}
                                        style={columnStyle}
                                        title={dataValue}>
                                        {isNil(dataValue) ? formatNullValue(column.null_values, column.null_custom_value) : dataValue}
                                    </td>
                                );
                            }
                            break;
                        case 'number':

                            // Time metrics
                            let numberValue;
                            if (column && column.time_metric) {
                                numberValue = isNil(dataValue) ? formatNullValue(column.null_values, column.null_custom_value) : formatTimeMetric(dataValue, column.time_level, column.time_format);
                            } else {
                                numberValue = isNil(dataValue) ? formatNullValue(column.null_values, column.null_custom_value) : numeral(dataValue).format(column.format || '');
                            }

                            rowColumns.push(
                                <td
                                    key={column}
                                    style={columnStyle}
                                    title={numberValue}
                                >
                                    {numberValue}
                                </td>
                            );
                            break;
                        case 'boolean':
                            rowColumns.push(
                                <td
                                    key={column}
                                    style={columnStyle}
                                    title={t(`common.${!!dataValue ? 'yes' : 'no'}`)}>
                                    {isNil(dataValue) ? formatNullValue(column.null_values, column.null_custom_value) : t(`common.${!!dataValue ? 'yes' : 'no'}`)}
                                </td>
                            );
                            break;
                        case 'function':
                            rowColumns.push(
                                <td
                                    key={column}
                                    style={columnStyle}>
                                    {column.nodeContent(dataValue)}
                                </td>
                            );
                            break;
                        default:
                            break;
                    }
                });

                tableRows.push(
                    <tr key={key}
                        style={{pageBreakInside: 'avoid'}}
                    >
                        {rowColumns}
                    </tr>
                );
            });
        } else {
            tableRows.push(
                <tr key={'noDataRow'}
                    style={{ pageBreakInside: 'avoid' }}
                >
                    <td
                        key={'noDataColumn'}
                        colSpan={tableColumns.length}
                        style={{ width: '100%', textAlign: 'center', fontSize: 12, pageBreakInside: 'avoid' }}
                        title={t('common.noDataForAppliedFiltersMessage')}>
                        {t('common.noDataForAppliedFiltersMessage')}
                    </td>
                </tr>
            );
        }

        return (
            <div className={classes} style={{marginTop: 10, marginButton: 10}}>
                <table className={tableClasses}
                       style={{ position: 'relative', tableLayout: 'fixed', width: '100%', borderSpacing: 0, borderCollapse: 'collapse' }}>
                    {tableSettings.showHeader ? (
                        <thead style={tableSettings.headerStyle}>
                        <tr>
                            {headerColumns}
                        </tr>
                        </thead>
                    ) : null}
                    <tbody>
                    {tableRows}
                    </tbody>
                    {tableSettings.showFooter ? (
                        <tfoot
                            style={tableSettings.footerStyle}>
                        <tr style={tableSettings.footerStyle}>
                            {footerColumns}
                        </tr>
                        </tfoot>
                    ) : null}
                </table>
            </div>
        );
    }
}

HtmlDataTable.defaultProps = {
    tableSettings: {
        showHeader: true,
        showFooter: false,
        sortable: true,
        filterable: true,
        defaultSortFields: [],
        defaultSortDirections: [],
        multiSortable: false,
        tableSortRowLimit: 25000,
        headerStyle: styles.defaultHeaderStyle,
        filterRowStyle: styles.defaultFilterRowStyle,
        inputStyle: styles.defaultInputStyle,
        rowStyle: styles.defaultRowStyle,
        footerStyle: styles.defaultFooterStyle,
        headerHeight: 38,
        filterRowHeight: 38,
        rowHeight: 38,
        footerHeight: 48,
        useDynamicHeaderRowHeight: true,
        useDynamicFilterRowHeight: true,
        useDynamicRowHeight: true,
        height: 320,
        overscanRowCount: 10,
        overscanColumnCount: 10,
        strippedRows: true,
        isDraggable: false
    },
    onUpdate: () => {}
};

HtmlDataTable.propTypes = {
    classes: PropTypes.string,
    tableClasses: PropTypes.string,
    tableSettings: PropTypes.shape({
        showHeader: PropTypes.bool,
        showFooter: PropTypes.bool,
        // defaultSortField: PropTypes.string,
        headerStyle: PropTypes.object,
        bodyStyle: PropTypes.object,
        footerStyle: PropTypes.object
    }).isRequired,
    // Table Columns. In each field, dataField can be a string or array of strings. If array, the first field is the one to sort by and columnType must be 'function'.
    tableColumns: PropTypes.array.isRequired,
    tableData: PropTypes.array.isRequired
};

export default withTranslation()(HtmlDataTable);