import { useDispatch } from 'react-redux';

// Libs
import request from "@biuwer/common/src/libs/superagent";
import subscriptionsClient from '@biuwer/core/src/subscriptions-client';
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import i18n from '@biuwer/core/src/i18n';
import History from '@biuwer/core/src/history';
import { defaultContext } from "@biuwer/redux/src/config/constants";

// Actions
import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

// GQL
import { SPACES_LIST, SPACES_DETAIL, NEW_SPACES_LIST } from "./spaces-gql";

/*
 * Spaces action types
 */
export const SPACES_INITIALIZE = 'SPACES_INITIALIZE';
export const SPACE_DETAIL_INITIALIZE = 'SPACE_DETAIL_INITIALIZE';
export const SPACE_LIST_INITIALIZE = 'SPACE_LIST_INITIALIZE';

export const SPACES_FETCH_REQUEST = 'SPACES_FETCH_REQUEST';
export const SPACES_FETCH_SUCCESS = 'SPACES_FETCH_SUCCESS';
export const SPACES_FETCH_ERROR = 'SPACES_FETCH_ERROR';

export const SPACE_FETCH_REQUEST = 'SPACE_FETCH_REQUEST';
export const SPACE_FETCH_SUCCESS = 'SPACE_FETCH_SUCCESS';
export const SPACE_FETCH_ERROR = 'SPACE_FETCH_ERROR';

export const SPACE_UPDATE_REQUEST = 'SPACE_UPDATE_REQUEST';
export const SPACE_UPDATE_SUCCESS = 'SPACE_UPDATE_SUCCESS';
export const SPACE_UPDATE_ERROR = 'SPACE_UPDATE_ERROR';

export const SPACE_CREATE_REQUEST = 'SPACE_CREATE_REQUEST';
export const SPACE_CREATE_SUCCESS = 'SPACE_CREATE_SUCCESS';
export const SPACES_CREATE_SUCCESS = 'SPACES_CREATE_SUCCESS';
export const SPACE_CREATE_ERROR = 'SPACE_CREATE_ERROR';

export const SPACE_DELETE_REQUEST = 'SPACE_DELETE_REQUEST';
export const SPACE_DELETE_SUCCESS = 'SPACE_DELETE_SUCCESS';
export const SPACE_DELETE_ERROR = 'SPACE_DELETE_ERROR';

export const MY_SPACE_FETCH_REQUEST = 'MY_SPACE_FETCH_REQUEST';
export const MY_SPACE_FETCH_SUCCESS = 'MY_SPACE_FETCH_SUCCESS';
export const MY_SPACE_FETCH_ERROR = 'MY_SPACE_FETCH_ERROR';

export const REMOVE_ALL_SPACES = 'REMOVE_ALL_SPACES';

// Variable function to unsubscribe all spaces
let unsubscribeAllSpaces = () => {};

// Unsuscrible all spaces and initialize session reducer
export function removeAllSpaces() {
    unsubscribeAllSpaces();
    return {
        type: REMOVE_ALL_SPACES
    };
}

// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, extraArgs, showNotification = true) => {
    let skeleton = { type, context }, notification;

    const history = History.getHistory();

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.statusCode,
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body
            };

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:
            break;
    }

    switch(type) {
        case SPACE_CREATE_SUCCESS:
            skeleton.created = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', { name: i18n.t('spaces.title'), context: 'male', count: 1 })
            };

            break;
        case SPACE_UPDATE_SUCCESS:
            skeleton.updated = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('spaces.title'), context: 'male', count: 1 })
            };

            break;
        case SPACE_DELETE_SUCCESS:
            skeleton.deleted = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', { name: i18n.t('spaces.title'), context: 'male', count: 1 })
            };

            // If actual route is deleted space push to default space
            if (Number(history.location.pathname.split("/")[2]) === Number(body._id)) {
                history.push("/spaces/");
            }

            break;
        case SPACE_FETCH_ERROR:
            skeleton.issuePayload = {
                ...skeleton.issuePayload,
                // code: extraArgs.code
            };
            break;
        case SPACE_CREATE_ERROR:
        case SPACE_UPDATE_ERROR:
        case SPACE_DELETE_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    };
};

/*
 * Action creators
 */

/**
 * Initialize spaces
 */
export const initializeSpaces = (context = defaultContext) => {
    return {
        type: SPACES_INITIALIZE,
        context
    };
};

export const initializeSpaceDetail = (context = defaultContext) => {
    return {
        type: SPACE_DETAIL_INITIALIZE,
        context
    }
};

export const initializeSpaceList = (context = defaultContext) => {
    return {
        type: SPACE_LIST_INITIALIZE,
        context
    }
};

/**
 * Get Spaces action
 */
export const querySpaces = (query, context = defaultContext, gql = SPACES_LIST) => {
    return async dispatch => {
        try {

            const gqlQuery = {
                query: `query($query: QuerySpaceInput!) {
                    readSpaces(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query: query
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(SPACES_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(SPACES_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readSpaces) {
                    dispatch(generateSkeleton(SPACES_FETCH_SUCCESS, res.body.data.readSpaces, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(SPACES_FETCH_ERROR, err, context));
        }
    };
};

/**
 * Get All Spaces action
 */
 export const getAllSpaces = (context = defaultContext, gql = SPACES_LIST) => {
    return async dispatch => {
        try {

            const gqlQuery = {
                query: `query {
                    readAllSpaces {
                        ${gql}
                    }
                }`,
                variables: {}
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(SPACES_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(SPACES_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readAllSpaces) {
                    dispatch(generateSkeleton(SPACES_FETCH_SUCCESS, res.body.data.readAllSpaces, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(SPACES_FETCH_ERROR, err, context));
        }
    };
};

/**
 * All spaces subscription
 * @param {context} redux context
 * @param {gql} gql
 * @returns {Function}
 */
export function newSpacesCreated(context = defaultContext, gql = SPACES_LIST) {
    return async dispatch => {
        await new Promise((resolve, reject) => {
            unsubscribeAllSpaces = subscriptionsClient.subscribe({
                query: `subscription {
                    newSpacesCreated {
                            ${gql}
                        }
                    }`,
                variables: {}
            }, {
                next: (data) => {
                    if (!data || !data.data || !data.data.newSpacesCreated) return;
                    dispatch({
                        type: SPACES_CREATE_SUCCESS,
                        context,
                        payload: data.data.newSpacesCreated
                    });
                },
                error: reject,
                complete: resolve,
            });
        });
    }
}

/**
 * Get space action
 * @param spaceId
 * @param context
 * @param gql
 */

export const getSpace = (spaceId, context = defaultContext, gql = SPACES_DETAIL) => {
    return async dispatch => {
        try {

            const gqlQuery = {
                query: `query($_id: Float!) {
                    readSpace(_id: $_id) {
                        ${gql}
                    }
                }`,
                variables: {
                    _id: spaceId
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(SPACE_FETCH_REQUEST, null));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(SPACE_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readSpace) {
                    dispatch(generateSkeleton(SPACE_FETCH_SUCCESS, res.body.data.readSpace, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(SPACE_FETCH_ERROR, err, context));
        }
    };
};

/**
 * Get personal space action
 */

export const getMySpace =(context = defaultContext, gql = SPACES_DETAIL) => {
    return async dispatch => {

        try {

            const gqlQuery = {
                query: `query {
                    readMySpace{
                        ${gql}
                    }
                }`
            };
            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(MY_SPACE_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(MY_SPACE_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readMySpace) {
                    dispatch(generateSkeleton(MY_SPACE_FETCH_SUCCESS, res.body.data.readMySpace, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(MY_SPACE_FETCH_ERROR, err, context));
        }
    };
};

/**
 * Create space action
 */
export const createSpace = (space, context = defaultContext, gql = SPACES_LIST) => {
    return async dispatch => {

        try {

            const gqlQuery = {
                query: `mutation($space: SpaceInput!) {
                    createSpace(space: $space) {
                        ${gql}
                    }
                }`,
                variables: {
                    space: space
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(SPACE_CREATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(SPACE_CREATE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.createSpace) {
                    dispatch(generateSkeleton(SPACE_CREATE_SUCCESS, res.body.data.createSpace, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(SPACE_CREATE_ERROR, err, context));
        }
    };
};

/**
 * Update space action
 */
export const updateSpace = (space, context = defaultContext, gql = SPACES_LIST) => {
    return async dispatch => {

        try {

            const spaceId = Number(space._id);

            const spaceData = {
                ...space
            };

            delete(spaceData._id);

            const gqlQuery = {
                query: `mutation($_id: Float!, $space: SpaceInput!) {
                    updateSpace(_id: $_id, space: $space) {
                        ${gql}
                    }
                }`,
                variables: {
                    _id: spaceId,
                    space: spaceData
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(SPACE_UPDATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(SPACE_UPDATE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.updateSpace) {
                    dispatch(generateSkeleton(SPACE_UPDATE_SUCCESS, res.body.data.updateSpace, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(SPACE_UPDATE_ERROR, err, context));
        }
    };
};

/**
 * Delete space action
 */
export const deleteSpace = (spaceId, context = defaultContext, gql = SPACES_LIST) => {
    return async dispatch => {

        try {

            const gqlQuery = {
                query: `mutation($_id: Float!) {
                deleteSpace(_id: $_id) {
                    ${gql}
                }
            }`,
                variables: {
                    _id: spaceId
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(SPACE_DELETE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(SPACE_DELETE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.deleteSpace) {
                    dispatch(generateSkeleton(SPACE_DELETE_SUCCESS, res.body.data.deleteSpace, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(SPACE_DELETE_ERROR, err, context));
        }
    };
};

export const useSpacesActions = () => {

    const dispatch = useDispatch();

    return {
        getAllSpaces: (context, gql = NEW_SPACES_LIST) => dispatch(getAllSpaces(context, gql)),
        createSpace: (space, context, gql = NEW_SPACES_LIST) => dispatch(createSpace(space, context, gql)),
        updateSpace: (space, context, gql = NEW_SPACES_LIST) => dispatch(updateSpace(space, context, gql)),
        deleteSpace: (spaceId, context, gql) => dispatch(deleteSpace(spaceId, context, gql)),
    };

};