import { useDispatch } from 'react-redux';

import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import i18n from "@biuwer/core/src/i18n";
import { defaultContext } from "@biuwer/redux/src/config/constants";

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { COLLECTIONS_LIST_PAGE, SPACE_COLLECTIONS_LIST } from './collections-gql';
import gqlRequest from '@biuwer/core/src/graphql-request';

/*
 * Collection action types
 */

export const COLLECTION_INITIALIZE = 'COLLECTION_INITIALIZE';
export const COLLECTION_DETAIL_INITIALIZE = 'COLLECTION_DETAIL_INITIALIZE';
export const COLLECTIONS_LIST_INITIALIZE = 'COLLECTIONS_LIST_INITIALIZE';

export const COLLECTIONS_FETCH_REQUEST = 'COLLECTIONS_FETCH_REQUEST';
export const COLLECTIONS_FETCH_SUCCESS = 'COLLECTIONS_FETCH_SUCCESS';
export const COLLECTIONS_FETCH_ERROR = 'COLLECTIONS_FETCH_ERROR';

export const COLLECTION_FETCH_REQUEST = 'COLLECTION_FETCH_REQUEST';
export const COLLECTION_FETCH_SUCCESS = 'COLLECTION_FETCH_SUCCESS';
export const COLLECTION_FETCH_ERROR = 'COLLECTION_FETCH_ERROR';

export const COLLECTION_CREATE_REQUEST = 'COLLECTION_CREATE_REQUEST';
export const COLLECTION_CREATE_SUCCESS = 'COLLECTION_CREATE_SUCCESS';
export const COLLECTION_CREATE_ERROR = 'COLLECTION_CREATE_ERROR';

export const COLLECTION_UPDATE_REQUEST = 'COLLECTION_UPDATE_REQUEST';
export const COLLECTION_UPDATE_SUCCESS = 'COLLECTION_UPDATE_SUCCESS';
export const COLLECTION_UPDATE_ERROR = 'COLLECTION_UPDATE_ERROR';

export const COLLECTION_DELETE_REQUEST = 'COLLECTION_DELETE_REQUEST';
export const COLLECTION_DELETE_SUCCESS = 'COLLECTION_DELETE_SUCCESS';
export const COLLECTION_DELETE_ERROR = 'COLLECTION_DELETE_ERROR';


// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, showNotification = true, extraArgs) => {
    let skeleton = { type, context }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)){
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.code,
                    code: body.statusCode,
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body.payload
            };

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:

            break;
    }

    switch(type){
        case COLLECTION_UPDATE_SUCCESS:
            skeleton.payload = body;
            skeleton.updated = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('collections.collectionsLabel'), context: 'female', count: 1 })
            };
            break;
        case COLLECTION_CREATE_SUCCESS:
            skeleton.payload = body;
            skeleton.created = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', { name: i18n.t('collections.collectionsLabel'), context: 'female', count: 1 })
            };
            break;
        case COLLECTION_DELETE_SUCCESS:
            skeleton.payload = body;
            skeleton.deleted = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', { name: i18n.t('collections.collectionsLabel'), context: 'female', count: 1 })
            };
            break;
        case COLLECTION_FETCH_SUCCESS:
        case COLLECTIONS_FETCH_SUCCESS:
            skeleton.payload = body;
            break;
        case COLLECTION_CREATE_ERROR:
        case COLLECTION_UPDATE_ERROR:
        case COLLECTION_DELETE_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    }
};

/**
 * Query Collections action
 */

export function queryCollections(query = {}, context = defaultContext, gql = COLLECTIONS_LIST_PAGE) {

    return async (dispatch) => {
        try {
            const gqlQuery = {
                query: `query($query: QueryCollectionInput!) {
                    readCollections(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query: query
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(COLLECTIONS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .set("app", Auth.getAuthApp())
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body && res.body.data && res.body.data.readCollections) {
                    dispatch(generateSkeleton(COLLECTIONS_FETCH_SUCCESS, res.body.data.readCollections, context));
                }
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(COLLECTIONS_FETCH_ERROR, res.body.errors[0], context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(COLLECTIONS_FETCH_ERROR, err, context));
        }
    };
}

/**
 * Read Collection action
 */

export function readCollection(collectionId, context = defaultContext, gql = COLLECTIONS_LIST_PAGE) {

    return async (dispatch) => {

        try {
            dispatch(generateSkeleton(COLLECTION_FETCH_REQUEST, null, context));

            const collection = await gqlRequest({
                queryName: "readCollection",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: collectionId
                }]
            });

            dispatch(generateSkeleton(COLLECTION_FETCH_SUCCESS, collection, context));
        } catch (err) {
            dispatch(generateSkeleton(COLLECTION_FETCH_ERROR, err, context));
        }
    };
}

/**
 * Initialize Collection
 */
export const initializeCollection = (context = defaultContext, baseContext) => {
    return {
        type: COLLECTION_INITIALIZE,
        context: context,
        baseContext
    };
};

/**
 * Initialize Collection List
 */
export const initializeCollectionsList = (context = defaultContext) => {
    return {
        type: COLLECTIONS_LIST_INITIALIZE,
        context: context
    };
};

/**
 * Initialize Collection Detail
 */
export const initializeCollectionDetail = (context = defaultContext) => {
    return {
        type: COLLECTION_DETAIL_INITIALIZE,
        context: context
    };
};

/**
 * Create collection action
 * @param collection
 * @param context
 * @param gql
 */
export function createCollection(collection, context = defaultContext, gql = COLLECTIONS_LIST_PAGE) {

    return async (dispatch) => {

        try {
            dispatch(generateSkeleton(COLLECTION_CREATE_REQUEST, null, context));

            const createdCollection = await gqlRequest({
                queryType: "mutation",
                queryName: "createCollection",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "NewCollectionInput!",
                    name: "collection",
                    data: collection
                }]
            });

            dispatch(generateSkeleton(COLLECTION_CREATE_SUCCESS, createdCollection, context, true));
        } catch (err) {
            dispatch(generateSkeleton(COLLECTION_CREATE_ERROR, err, context, true));
        }
    };
}

/**
 * Update collection action
 * @param collection
 * @param context
 * @param gql
 */
export function updateCollection(collection, context = defaultContext, gql = COLLECTIONS_LIST_PAGE) {

    return async (dispatch) => {

        try {
            dispatch(generateSkeleton(COLLECTION_UPDATE_REQUEST, null, context));

            const updatedCollection = await gqlRequest({
                queryType: "mutation",
                queryName: "updateCollection",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: collection._id
                }, {
                    type: "UpdateCollectionInput!",
                    name: "collection",
                    data: collection
                }]
            });

            dispatch(generateSkeleton(COLLECTION_UPDATE_SUCCESS, updatedCollection, context, true));
        } catch (err) {
            dispatch(generateSkeleton(COLLECTION_UPDATE_ERROR, err, context, true));
        }
    };
}


/**
 * Delete collection action
 * @param collectionId
 * @param context
 * @param gql
 */
export function deleteCollection(collectionId, context = defaultContext, gql = COLLECTIONS_LIST_PAGE) {

    return async (dispatch) => {
        try {
            dispatch(generateSkeleton(COLLECTION_DELETE_REQUEST, null, context));

            const deletedCollection = await gqlRequest({
                queryType: "mutation",
                queryName: "deleteCollection",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: collectionId
                }]
            });

            dispatch(generateSkeleton(COLLECTION_DELETE_SUCCESS, deletedCollection, context));
        } catch (err) {
            dispatch(generateSkeleton(COLLECTION_DELETE_ERROR, err, context));
        }
    };
}

export const useCollectionsActions = () => {

    const dispatch = useDispatch();

    return {
        initializeCollections: async (context, baseContext) => dispatch(initializeCollection(context, baseContext)),
        getSpaceCollections: async (spaceId, context, gql = SPACE_COLLECTIONS_LIST) => dispatch(queryCollections({ space_id: spaceId }, context, gql)),
        createCollection: async (collection, context, gql = SPACE_COLLECTIONS_LIST) => dispatch(createCollection(collection, context, gql)),
        updateCollection: async (collection, context, gql = SPACE_COLLECTIONS_LIST) => dispatch(updateCollection(collection, context, gql)),
        deleteCollection: async (collectionId, context, gql = SPACE_COLLECTIONS_LIST) => dispatch(deleteCollection(collectionId, context, gql)),
    };

};
