export default {
    "image": {
        "insert": "Insertar imagen:",
        "url": "URL imagen",
        "alt": "Texto alternativo",
        "tooltip": "Insertar imagen"
    },
    "align": {
        "title": "Alinear",
        "left": "Alinear a la izquierda",
        "center": "Alinear al centro",
        "right": "Alinear a la derecha",
        "justify": "Justificado",
        "outdent": "Indentar a la izquierda",
        "indent": "Indentar a la derecha"
    },
    "block": {
        "title": "Bloque de texto",
        "normal": "Normal",
        "large": "Encabezado grande",
        "small": "Encabezado pequeño",
        "bullet": "Lista de viñetas",
        "numbered": "Lista numerada",
        "quote": "Cita",
        "code": "Código",
        "codeLanguages": "Lenguajes de código"
    },
    "format": {
        "title": "Formato de texto",
        "subscript": "Subíndice",
        "superscript": "Superíndice",
        "underline": "Subrayado",
        "strikethrough": "Tachado",
        "bold": "Negrita",
        "italic": "Itálica"
    },
    "fontFamily": "Fuente de texto",
    "fontSize": "Tamaño",
    "title": "Texto enriquecido",
    "placeholder": "Introduce un texto...",
    "link": "Insertar link",
    "undo": "Deshacer",
    "redo": "Rehacer",
    "fontColor": "Color de letra",
    "backgroundColor": "Color de resalte"
};