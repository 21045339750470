export const RESOURCE_TOKENS_DETAIL = `
    _id
    valid_from
    valid_to
    access_type
    height
    width
    status
    resource_type
    resource_id
    resource_data {
        _id
        name
    }
`;

export const RESOURCE_TOKENS_BASIC = `
    _id
    valid_from
    valid_to
    access_type
    height
    width
    status
    resource_type
    resource_id
`;