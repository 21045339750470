import GraphLib from "graphlib";

export default function getNeededRelations(queries, queryRelations) {

    // Set needed queries
    const neededQueries = new Set();
    queries.forEach(query => neededQueries.add(query));

    // Initialize needed set and graphs
    const needlessQueries = new Set();
    let g = new GraphLib.Graph();
    let g2 = new GraphLib.Graph();

    // Fill needless queries and graphs with all query relations
    queryRelations.forEach(queryRelation => {

        const query1 = queryRelation.query1;
        const query2 = queryRelation.query2;

        if (!needlessQueries.has(query1)) {
            needlessQueries.add(query1);
        }

        if (!needlessQueries.has(query2)) {
            needlessQueries.add(query2);
        }

        if (!g.hasNode(query1)) {
            g.setNode(query1);
            g2.setNode(query1);
        }

        if (!g.hasNode(query2)) {
            g.setNode(query2);
            g2.setNode(query2);
        }

        g.setEdge(query1, query2);
        g2.setEdge(query1, query2);

    });

    // Remove needless nodes
    [...needlessQueries].forEach(needlessQuery => {

        g2.removeNode(needlessQuery);

        const components = GraphLib.alg.components(g2);
        const remove = components.find(component => {
            return [...neededQueries].every(neededQuery => {
                return component.includes(neededQuery);
            });
        });

        if(remove) {
            g = GraphLib.json.read((GraphLib.json.write(g2)));
        }
        else {
            g2 = GraphLib.json.read((GraphLib.json.write(g)));
        }

    });

    // Remove needless query relations
    queryRelations = queryRelations.filter(queryRelation => {
        return g.edges().find((edge) => {
           return (
                (edge.v === queryRelation.query1 && edge.w === queryRelation.query2) ||
                (edge.v === queryRelation.query2 && edge.w === queryRelation.query1)
           );
        });
    });

    // Initialize needed relations
    const neededRelations = [];
    
    // Iterate while there are unjoined relations (limit by Cartesian product from query relations)
    const relationsLength = queryRelations.length;
    const joinedQueries = new Set([queries[0]]);
    for (let i = 0; queryRelations.length > 0 && i < (relationsLength * relationsLength); i++) {
        queryRelations.forEach((relation, relationIndex) => {

            // Get if relation queries are joined
            const joinedQuery1 = joinedQueries.has(relation.query1);
            const joinedQuery2 = joinedQueries.has(relation.query2);

            // Only add unjoined queries
            if ((joinedQuery1 || joinedQuery2) && !(joinedQuery1 && joinedQuery2)) {

                // Add join query to joined queries
                joinedQueries.add(joinedQuery1 ? relation.query2 : relation.query1);
                
                // Delete relation from needed relations
                queryRelations.splice(relationIndex, 1);
                
                // Add needed relation
                neededRelations.push(relation);

            }

        });
    }

    // Return only needed query relations sorted for easy joins
    return neededRelations;

}
