import i18n from "@biuwer/core/src/i18n";
import History from "@biuwer/core/src/history";
import ExportFileWorker from "@biuwer/biuwer/src/workers/exportFile.worker?worker";
import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";
import gqlRequest from "@biuwer/core/src/graphql-request";
import { CAN_DELETE_GQL, TRACEABILITY_GQL  } from "@biuwer/redux/src/data/content/content-gql";
import { saveFile } from "@biuwer/common/src/libs/content/exports-lib";
import { getPdfFromVisualization } from "@biuwer/common/src/libs/content/pdf-exports-lib";
import { getPngFromVisualization } from "@biuwer/common/src/libs/content/png-exports-lib";

/*
 * Content action types
 */

export const EXPORT_DATA_CARD_FETCH_REQUEST = 'EXPORT_DATA_CARD_FETCH_REQUEST';
export const EXPORT_DATA_CARD_FETCH_SUCCESS = 'EXPORT_DATA_CARD_FETCH_SUCCESS';
export const EXPORT_DATA_CARD_FETCH_ERROR = 'EXPORT_DATA_CARD_FETCH_ERROR';

export const PDF_EXPORT_REQUEST = 'PDF_EXPORT_REQUEST';
export const PDF_EXPORT_SUCCESS = 'PDF_EXPORT_SUCCESS';
export const PDF_EXPORT_ERROR = 'PDF_EXPORT_ERROR';

export const EXPORT_VISUALIZATION_REQUEST = "EXPORT_VISUALIZATION_REQUEST"
export const EXPORT_VISUALIZATION_SUCCESS = "EXPORT_VISUALIZATION_SUCCESS"
export const EXPORT_VISUALIZATION_ERROR = "EXPORT_VISUALIZATION_ERROR"

export const FILE_IMPORT_REQUEST = 'FILE_IMPORT_REQUEST';
export const FILE_IMPORT_SUCCESS = 'FILE_IMPORT_SUCCESS';
export const FILE_IMPORT_ERROR = 'FILE_IMPORT_ERROR';

export const CAN_DELETE_REQUEST = 'CAN_DELETE_REQUEST';
export const CAN_DELETE_SUCCESS = 'CAN_DELETE_SUCCESS';
export const CAN_DELETE_ERROR = 'CAN_DELETE_ERROR';

export const GET_TRACEABILITY_REQUEST = 'GET_TRACEABILITY_REQUEST';
export const GET_TRACEABILITY_SUCCESS = 'GET_TRACEABILITY_SUCCESS';
export const GET_TRACEABILITY_ERROR = 'GET_TRACEABILITY_ERROR';

export const CONTENT_UPLOAD = 'CONTENT_UPLOAD';
export const CONTENT_DOWNLOAD = 'CONTENT_DOWNLOAD';

export const CONTENT_INITIALIZE = 'CONTENT_INITIALIZE';

const generateSkeleton = (type, body, extraArgs) => {
    let skeleton = {
        type
    };
    const history = History.getHistory();

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)){
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isLoading: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isLoading: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.issuePayload ? body.issuePayload.code : body.payload, // Backward compatibility with old error handling
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isLoading: false,
                issue: !!body && body.status === 2,
                payload: (body && body.payload) || {}
            };

            if(!!body && body.status === 2) {
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:

            break;
    }

    switch(type) {
        case EXPORT_DATA_CARD_FETCH_REQUEST:
            break;
        case EXPORT_DATA_CARD_FETCH_SUCCESS:
            break;
        case EXPORT_DATA_CARD_FETCH_ERROR:
            break;
        case PDF_EXPORT_REQUEST:
            skeleton.objectType = extraArgs.objectType;
            break;
        case PDF_EXPORT_SUCCESS:
            break;
        case PDF_EXPORT_ERROR:
            break;
        case CONTENT_UPLOAD:
        case CONTENT_DOWNLOAD:
            skeleton.info = extraArgs;
            break;
        case FILE_IMPORT_REQUEST:
            break;
        case FILE_IMPORT_SUCCESS:
            const datasetId = skeleton.payload && skeleton.payload._id;
            const locationPath = history.location.pathname.split('/');
            const importNewDataset = history.location.pathname === '/data-center/import';
            const importFromExistingDataset = locationPath[1] === 'data-center' && locationPath[2] === 'datasets' && locationPath[4] === 'import';
            if (importNewDataset || importFromExistingDataset) {
                history.push(`/data-center/datasets/${datasetId}`);
            }
            break;
        case FILE_IMPORT_ERROR:
            break;
        case CAN_DELETE_SUCCESS:
            break;
        default:
            break;
    }

    return skeleton;
};

export const initializeContent = () => {
    return {
        type: CONTENT_INITIALIZE
    }
};

export const exportDataCard = (data = [], format, fields, fileName, language) => {

    return async dispatch => {

        try {
            dispatch(generateSkeleton(EXPORT_DATA_CARD_FETCH_REQUEST));

            try {

                dispatch(addNotification({
                    styleType: 'custom',
                    style: {
                        color: 'blue'
                    },
                    message: i18n.t('notifications.downloadingFile'),
                    timeout: 0,
                    notificationKey: 'file-export'
                }));

                const myWorker = new ExportFileWorker();
                myWorker.postMessage({
                    action: "printCardData",
                    data: data,
                    fields: fields,
                    format: format,
                    fileName: fileName,
                    language: language
                });

                myWorker.addEventListener("message", async (e) => {

                    if (e.data.error) {
                        dispatch(generateSkeleton(EXPORT_DATA_CARD_FETCH_ERROR, { message: e.data.error }));
                        dispatch(addNotification({
                            styleType: 'error',
                            message: i18n.t('notifications.errorPrintFile'),
                            notificationKey: 'file-export'
                        }));
                    } else {

                        dispatch(generateSkeleton(EXPORT_DATA_CARD_FETCH_SUCCESS, {}));
                        dispatch(addNotification({
                            styleType: 'success',
                            message: i18n.t('notifications.downloadFileSuccessMessage'),
                            notificationKey: 'file-export'
                        }));

                        if (e.data.action === "printCardData") {
                            saveFile(new Blob([e.data.buffer]), fileName, format)
                        }
                    }

                });
            } catch (err) {
                dispatch(generateSkeleton(EXPORT_DATA_CARD_FETCH_ERROR, err));
                dispatch(addNotification({
                    styleType: 'error',
                    message: i18n.t('notifications.errorPrintFile'),
                    notificationKey: 'file-export'
                }));
            }
        } catch (err) {
            dispatch(generateSkeleton(EXPORT_DATA_CARD_FETCH_ERROR, err));
        }
    }
};

export function exportVisualization(content, options = {}) {
    return async dispatch => {
        dispatch(generateSkeleton(EXPORT_VISUALIZATION_REQUEST))

        dispatch(addNotification({
            styleType: "custom",
            style: { color: "blue" },
            message: i18n.t("notifications.downloadingFile"),
            timeout: 0,
            notificationKey: "file-export"
        }))

        const { format, entityType, entityId, entityName, fromPage, includeHeader, includeFilters, fileName, margins, showHeader, headerContent, showFooter, footerContent, pageSize, orientation, unrollCardContent, cardType, contentType } = options

        try {

            let fileBlob
            if (format === "png") {
                fileBlob = await getPngFromVisualization({ content, entityType, entityId, fromPage, unrollCardContent, cardType, contentType })
            } else if (format === "pdf") {
                fileBlob = await getPdfFromVisualization({ pageSize, orientation, content, entityType, entityId, entityName, fromPage, margins, showHeader, headerContent, includeHeader, includeFilters, showFooter, footerContent, unrollCardContent, cardType, contentType })
            } else {
                throw new Error("UNKNOWN_EXPORT_FORMAT")
            }

            if (fileBlob) {
                saveFile(fileBlob, fileName, format)
                dispatch(generateSkeleton(EXPORT_VISUALIZATION_SUCCESS))
                dispatch(addNotification({
                    styleType: "success",
                    message: i18n.t("notifications.downloadFileSuccessMessage"),
                    notificationKey: "file-export"
                }))
            } else {
                throw new Error("UNKNOWN_ERROR")
            }
        } catch (err) {
            dispatch(generateSkeleton(EXPORT_VISUALIZATION_ERROR, err))
            dispatch(addNotification({
                styleType: "error",
                message: i18n.t("notifications.errorPrintFile"),
                notificationKey: "file-export"
            }))
        }
    }
}

export const importFile = (file, dataset) => {
    return async dispatch => {
        let resBody;
        try {

            dispatch(generateSkeleton(FILE_IMPORT_REQUEST));

            resBody = await gqlRequest({
                queryType: "mutation",
                queryName: "importFile",
                variables: [{
                    type: "ImportFileInput!",
                    name: "importFileInput",
                    data: {
                        file: file,
                        dataset: dataset
                    }
                }],
                onProgress: (e) => {
                    switch (e.direction) {
                        case 'upload':
                            dispatch(generateSkeleton(CONTENT_UPLOAD, null, {direction: e.direction, total: e.total, loaded: e.loaded, percent: e.percent}));
                            break;
                        default:
                            break;
                    }
                }
            });

            dispatch(generateSkeleton(FILE_IMPORT_SUCCESS, resBody, { datasetId: dataset._id }));
            let notificationMessage = !!dataset._id ? 'notifications.updateSuccess' : 'notifications.createSuccess';
            dispatch(addNotification({
                styleType: 'success',
                message: i18n.t(notificationMessage, { name: i18n.t('datasets.datasetLabel'), context: 'male', count: 1 })
            }));

        } catch (err) {
            // TODO: Improve this when updating errors logic
            const errorMessage = resBody && resBody.message === 'DATASET_FIELD_DEPENDENCIES' ? 'errors.DATASET_FIELD_DEPENDENCIES': 'notifications.error';
            dispatch(generateSkeleton(FILE_IMPORT_ERROR, err));
            dispatch(addNotification({
                styleType: 'error',
                message: i18n.t(errorMessage)
            }));
        }
    }
};

export const canDelete = (objectType, objectIds, extraArgs) => {
    return async dispatch => {
        try {

            dispatch(generateSkeleton(CAN_DELETE_REQUEST));

            const resBody = await gqlRequest({
                queryGql: CAN_DELETE_GQL,
                queryName: "canDeleteContent",
                variables: [{
                    type: "CanDeleteContentInput!",
                    name: "canDeleteContentInput",
                    data: {
                        objectType: objectType,
                        objectIds: objectIds,
                        extraArgs: extraArgs
                    }
                }]
            });

            dispatch(generateSkeleton(CAN_DELETE_SUCCESS, { payload: resBody }));

        } catch (err) {
            dispatch(generateSkeleton(CAN_DELETE_ERROR, err));
        }
    };
};

export const getTraceability = (objectType, objectId) => {
    return async dispatch => {
        try {
            dispatch(generateSkeleton(GET_TRACEABILITY_REQUEST));
            const resBody = await gqlRequest({
                queryGql: TRACEABILITY_GQL,
                queryName: "getTraceability",
                variables: [{
                    type: "TraceabilityInput!",
                    name: "TraceabilityInput",
                    data: {
                        objectType: objectType,
                        objectId: objectId
                    }
                }]
            });
            dispatch(generateSkeleton(GET_TRACEABILITY_SUCCESS, { payload: resBody }));
        } catch (err) {
            dispatch(generateSkeleton(GET_TRACEABILITY_ERROR, err));
        }
    };
};