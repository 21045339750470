export const COLOR_PALETTES_LIST = `
    _id
    name
    type
    palette_type
    colors
    default
    in_use
    repeat {
        enabled
        num_repeats
        pct_degrade
    }
    sequential {
        initial
        end
        items
    }
    diverging {
        initial
        middle
        end
        items
    }
    created
    updated
    status
`

export const COLOR_PALETTE_DETAIL = `
    _id
    name
    type
    palette_type
    colors
    default
    in_use
    repeat {
        enabled
        num_repeats
        pct_degrade
    }
    sequential {
        initial
        end
        items
    }
    diverging {
        initial
        middle
        end
        items
    }
    created
    updated
    status
`

export const COLOR_PALETTE_SELECTOR = `
    _id
    name
    type
    palette_type
    colors
    default
    repeat {
        enabled
        num_repeats
        pct_degrade
    }
    sequential {
        initial
        end
        items
    }
    diverging {
        initial
        middle
        end
        items
    }
    created
`