import gqlRequest from '@biuwer/core/src/graphql-request';
import i18n from '@biuwer/core/src/i18n';
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import { defaultContext } from "@biuwer/redux/src/config/constants";

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { COLOR_PALETTES_LIST, COLOR_PALETTE_DETAIL, COLOR_PALETTE_SELECTOR } from "./color-palettes-gql"

/**
 * Color Palettes action types
 */

export const COLOR_PALETTES_INITIALIZE = "COLOR_PALETTES_INITIALIZE";
export const COLOR_PALETTE_DETAIL_INITIALIZE = "COLOR_PALETTE_DETAIL_INITIALIZE";
export const COLOR_PALETTE_LIST_INITIALIZE = "COLOR_PALETTE_LIST_INITIALIZE";

export const COLOR_PALETTES_FETCH_REQUEST = "COLOR_PALETTES_FETCH_REQUEST";
export const COLOR_PALETTES_FETCH_SUCCESS = "COLOR_PALETTES_FETCH_SUCCESS";
export const COLOR_PALETTES_FETCH_ERROR = "COLOR_PALETTES_FETCH_ERROR";

export const COLOR_PALETTE_FETCH_REQUEST = "COLOR_PALETTE_FETCH_REQUEST";
export const COLOR_PALETTE_FETCH_SUCCESS = "COLOR_PALETTE_FETCH_SUCCESS";
export const COLOR_PALETTE_FETCH_ERROR = "COLOR_PALETTE_FETCH_ERROR";

export const COLOR_PALETTE_UPDATE_REQUEST = "COLOR_PALETTE_UPDATE_REQUEST";
export const COLOR_PALETTE_UPDATE_SUCCESS = "COLOR_PALETTE_UPDATE_SUCCESS";
export const COLOR_PALETTE_UPDATE_ERROR = "COLOR_PALETTE_UPDATE_ERROR";

export const COLOR_PALETTE_CREATE_REQUEST = "COLOR_PALETTE_CREATE_REQUEST";
export const COLOR_PALETTE_CREATE_SUCCESS = "COLOR_PALETTE_CREATE_SUCCESS";
export const COLOR_PALETTES_CREATE_SUCCESS = "COLOR_PALETTES_CREATE_SUCCESS";
export const COLOR_PALETTE_CREATE_ERROR = "COLOR_PALETTE_CREATE_ERROR";

export const COLOR_PALETTE_DELETE_REQUEST = "COLOR_PALETTE_DELETE_REQUEST";
export const COLOR_PALETTE_DELETE_SUCCESS = "COLOR_PALETTE_DELETE_SUCCESS";
export const COLOR_PALETTE_DELETE_ERROR = "COLOR_PALETTE_DELETE_ERROR";

export const COLOR_PALETTE_DEFAULT_REQUEST = "COLOR_PALETTE_DEFAULT_REQUEST"
export const COLOR_PALETTE_DEFAULT_SUCCESS = "COLOR_PALETTE_DEFAULT_SUCCESS"
export const COLOR_PALETTE_DEFAULT_ERROR = "COLOR_PALETTE_DEFAULT_ERROR"

// Generic Returns Skeleton
function generateSkeleton(type, body, context = defaultContext, showNotification = true) {
    let skeleton = { type, context }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case "REQUEST":
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case "ERROR":
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.statusCode,
                    message: body.message
                }
            };

            break;
        case "SUCCESS":
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body
            };

            if(body.status === 2) {
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:
            break;
    }

    switch(type) {
        case COLOR_PALETTE_CREATE_SUCCESS:
            skeleton.created = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', { name: i18n.t('admin.organization.colorPalettes.title'), context: 'female', count: 1 })
            };

            break;
        case COLOR_PALETTE_UPDATE_SUCCESS:
        case COLOR_PALETTE_DEFAULT_SUCCESS:
            skeleton.updated = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('admin.organization.colorPalettes.title'), context: 'female', count: 1 })
            };

            break;
        case COLOR_PALETTE_DELETE_SUCCESS:
            skeleton.deleted = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', { name: i18n.t('admin.organization.colorPalettes.title'), context: 'female', count: 1 })
            };

            break;
        case COLOR_PALETTE_FETCH_ERROR:
            skeleton.issuePayload = {
                ...skeleton.issuePayload,
                // code: extraArgs.code
            };
            break;
        case COLOR_PALETTE_CREATE_ERROR:
        case COLOR_PALETTE_UPDATE_ERROR:
        case COLOR_PALETTE_DELETE_ERROR:
        case COLOR_PALETTE_DEFAULT_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    }
}

/*
 * Action creators
 */

/**
 * Initialize color palettes
 */
export const initializeColorPalettes = (context = defaultContext) => ({
    type: COLOR_PALETTES_INITIALIZE,
    context
})

export const initializeColorPaletteDetail = (context = defaultContext) => ({
    type: COLOR_PALETTE_DETAIL_INITIALIZE,
    context
})

export const initializeColorPaletteList = (context = defaultContext) => ({
    type: COLOR_PALETTE_LIST_INITIALIZE,
    context
})

/**
 * Color palettes CRUD
 */

/**
 *
 * @param {String} context
 * @param {String} gql
 * @returns {Promise<void>}
 */
export const getColorPalettes = (context = defaultContext, gql = COLOR_PALETTES_LIST) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(COLOR_PALETTES_FETCH_REQUEST, null, context));

        try {
            const colorPalettes = await gqlRequest({
                queryName: "readColorPalettes",
                queryGql: gql,
                token: Auth.getLocalJwt()
            });

            dispatch(generateSkeleton(COLOR_PALETTES_FETCH_SUCCESS, colorPalettes, context));
        } catch (err) {
            dispatch(generateSkeleton(COLOR_PALETTES_FETCH_ERROR, err, context));
        }
    }
}

/**
 *
 * @param {String} colorPaletteId
 * @param {String} context
 * @param {String} gql
 * @returns {Promise<void>}
 */
export const getColorPalette = (colorPaletteId, context = defaultContext, gql = COLOR_PALETTE_DETAIL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(COLOR_PALETTE_FETCH_REQUEST, null, context));

        try {
            const colorPalette = await gqlRequest({
                queryName: "readColorPalette",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "String!",
                    name: "_id",
                    data: colorPaletteId
                }]
            });

            dispatch(generateSkeleton(COLOR_PALETTE_FETCH_SUCCESS, colorPalette, context));
        } catch (err) {
            dispatch(generateSkeleton(COLOR_PALETTE_FETCH_ERROR, err, context));
        }
    }
}

/**
 *
 * @param {Object} colorPalette
 * @param {String} context
 * @param {String} gql
 * @returns {Promise<void>}
 */
export const createColorPalette = (colorPalette, context = defaultContext, gql = COLOR_PALETTE_DETAIL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(COLOR_PALETTE_CREATE_REQUEST, null, context));

        try {
            const newColorPalette = await gqlRequest({
                queryType: "mutation",
                queryName: "createColorPalette",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "NewColorPaletteInput!",
                    name: "colorPalette",
                    data: colorPalette
                }]
            });
            dispatch(generateSkeleton(COLOR_PALETTE_CREATE_SUCCESS, newColorPalette, context));
        } catch (err) {
            dispatch(generateSkeleton(COLOR_PALETTE_CREATE_ERROR, err, context));
        }
    }
}

/**
 *
 * @param {Object} colorPalette
 * @param {String} context
 * @param {String} gql
 * @returns {Promise<void>}
 */
export const updateColorPalette = (colorPalette, context = defaultContext, gql = COLOR_PALETTE_DETAIL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(COLOR_PALETTE_UPDATE_REQUEST, null, context));

        try {
            const updatedColorPalette = await gqlRequest({
                queryType: "mutation",
                queryName: "updateColorPalette",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "ColorPaletteInput!",
                    name: "colorPalette",
                    data: colorPalette
                }]
            });
            dispatch(generateSkeleton(COLOR_PALETTE_UPDATE_SUCCESS, updatedColorPalette, context));
        } catch (err) {
            dispatch(generateSkeleton(COLOR_PALETTE_UPDATE_ERROR, err, context));
        }
    }
}

/**
 *
 * @param {String} colorPaletteId
 * @param {String} context
 * @param {String} gql
 * @returns {Promise<void>}
 */
export const deleteColorPalette = (colorPaletteId, context = defaultContext, gql = COLOR_PALETTE_DETAIL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(COLOR_PALETTE_DELETE_REQUEST, null, context));

        try {
            const deletedColorPalette = await gqlRequest({
                queryType: "mutation",
                queryName: "deleteColorPalette",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "String!",
                    name: "_id",
                    data: colorPaletteId
                }]
            });
            dispatch(generateSkeleton(COLOR_PALETTE_DELETE_SUCCESS, deletedColorPalette, context));
        } catch (err) {
            dispatch(generateSkeleton(COLOR_PALETTE_DELETE_ERROR, err, context));
        }
    }
}

/**
 *
 * @param {String} colorPaletteId
 * @param {String} status
 * @param {String} context
 * @param {String} gql
 * @returns {Promise<void>}
 */
export const changeColorPaletteStatus = (colorPaletteId, status, context = defaultContext, gql = COLOR_PALETTE_DETAIL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(COLOR_PALETTE_UPDATE_REQUEST, null, context))

        try {
            const updatedColorPalette = await gqlRequest({
                queryType: "mutation",
                queryName: "changeColorPaletteStatus",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "String!",
                    name: "_id",
                    data: colorPaletteId
                }, {
                    type: "String!",
                    name: "status",
                    data: status
                }]
            });
            dispatch(generateSkeleton(COLOR_PALETTE_UPDATE_SUCCESS, updatedColorPalette, context))
        } catch (err) {
            dispatch(generateSkeleton(COLOR_PALETTE_UPDATE_ERROR, err, context))
        }
    }
}

export const setDefaultColorPalette = (colorPaletteId, context = defaultContext, gql = COLOR_PALETTE_DETAIL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(COLOR_PALETTE_DEFAULT_REQUEST, null , context))

        try {
            const updatedColorPalette = await gqlRequest({
                queryType: "mutation",
                queryName: "setDefaultColorPalette",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "String!",
                    name: "_id",
                    data: colorPaletteId
                }]
            })

            dispatch(generateSkeleton(COLOR_PALETTE_DEFAULT_SUCCESS, updatedColorPalette, context))
        } catch (err) {
            dispatch(generateSkeleton(COLOR_PALETTE_DEFAULT_ERROR, err, context))
        }
    }
}

/**
 *
 * @param {String} context
 * @param {String} gql
 * @returns {Promise<void>}
 */
export const getAvailableColorPalettes = (context = defaultContext, gql = COLOR_PALETTE_SELECTOR) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(COLOR_PALETTES_FETCH_REQUEST, null, context));

        try {
            const colorPalettes = await gqlRequest({
                queryName: "readAvailableColorPalettes",
                queryGql: gql,
                token: Auth.getLocalJwt()
            });

            dispatch(generateSkeleton(COLOR_PALETTES_FETCH_SUCCESS, colorPalettes, context));
        } catch (err) {
            dispatch(generateSkeleton(COLOR_PALETTES_FETCH_ERROR, err, context));
        }
    }
}