import { Map, fromJS } from 'immutable';
import { defaultContext } from "@biuwer/redux/src/config/constants";

import {
    THEMES_INITIALIZE, THEME_LIST_INITIALIZE, THEME_DETAIL_INITIALIZE,
    THEMES_FETCH_REQUEST, THEMES_FETCH_SUCCESS, THEMES_FETCH_ERROR,
    THEME_FETCH_REQUEST, THEME_FETCH_SUCCESS, THEME_FETCH_ERROR,
    THEME_CREATE_REQUEST, THEME_CREATE_SUCCESS, THEME_CREATE_ERROR,
    THEME_UPDATE_REQUEST, THEME_UPDATE_SUCCESS, THEME_UPDATE_ERROR,
    THEME_DELETE_REQUEST, THEME_DELETE_SUCCESS, THEME_DELETE_ERROR
} from './themes-actions';

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

export default function ThemesReducer(state = initialState, action) {
    let newState, index, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || defaultContext}`;
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch (action.type) {
        case THEMES_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));

            return newState;
        case THEME_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));

            return newState;
        case THEME_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));

            return newState;
        case THEMES_FETCH_REQUEST:
            newState = state;

            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case THEMES_FETCH_SUCCESS:
            newState = state
            .mergeIn([list], Map({
                isFetching: action.isFetching,
                issue: action.issue,
                payload: fromJS(action.payload),
                loaded: true
            }));

            return newState;
        case THEME_FETCH_REQUEST:
            newState = state;

            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case THEME_FETCH_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case THEME_CREATE_REQUEST:
            newState = state;

            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case THEME_CREATE_SUCCESS:
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case THEME_UPDATE_REQUEST:
            newState = state;

            // Check if "delete" key exists
            if (newState.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (newState.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (newState.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (newState.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case THEME_UPDATE_SUCCESS:
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the theme index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            issue: action.issue,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case THEME_DELETE_REQUEST:
            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case THEME_DELETE_SUCCESS:
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    isFetching: action.isFetching,
                    issue: action.issue,
                    deleted: action.deleted
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the theme index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                            isFetching: action.isFetching,
                            issue: action.issue,
                            deleted: action.deleted
                        });
                }
            }

            return newState;
        case THEMES_FETCH_ERROR:
            newState = state
            .mergeIn([list], Map({
                isFetching: action.isFetching,
                issue: action.issue,
                issuePayload: fromJS(action.issuePayload),
                loaded: true
            }));

            return newState;
        case THEME_FETCH_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case THEME_CREATE_ERROR:
        case THEME_UPDATE_ERROR:
        case THEME_DELETE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        default:
            return state;
    }
}