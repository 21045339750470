import { sum, mean, min, max, isNil } from 'lodash';
import alasql from "alasql";

// Libs
import operationsLib from './operations-lib';

/**
 * Aux function to evaluate if an expression has an aggregation on it or not
 * @param {String} expression Calculated field expression
 * @param {Array} calculatedFields Array of calculated fields
 * @returns {Boolean}
 */
export const isAggregation = (expression, calculatedFields) => {

    let aggregationCounter = 0;
    let expressions = [expression?.replaceAll(/\s/g,'')];

    if (expression?.includes("CF.", 0)) {
        if (calculatedFields?.length > 0) {
            calculatedFields.forEach((cf, index) => {
                if (expression?.includes(`CF.${index+1}`)) {
                    expressions.push(cf.expression);
                }
            });
        }
    }

    if (expressions?.length > 0) {
        expressions.forEach((exp) => {
            exp = exp?.replaceAll(/\s/g,'');
            if (
                (exp?.includes("SUM(", 0) && !exp?.includes("_SUM(", 0)) ||
                (exp?.includes("AVG(", 0) && !exp?.includes("_AVG(", 0)) ||
                (exp?.includes("COUNT(", 0) && !exp?.includes("_COUNT(", 0)) ||
                (exp?.includes("COUNT_DISTINCT(", 0) && !exp?.includes("_COUNT_DISTINCT(", 0)) ||
                (exp?.includes("MAX(", 0) && !exp?.includes("_MAX(", 0)) ||
                (exp?.includes("MIN(", 0) && !exp?.includes("_MIN(", 0))
            ) {
                aggregationCounter++;
            }
        })
    }

    return aggregationCounter > 0;
}

/**
 * Function to apply an aggregation function on a field for a given array of data.
 * @param {Array} data Data from query.
 * @param {Array} operationParams   Array with operation result from given expression. For a SUM(field1) expression,
 * first element of the array will be an object with field1 and its type.
 * @param {Object} extraArgs Object with group by field information
 * @param {String} operation  Aggregation operation
 * @returns {Array}
 */
const aggregationOperation = ({ data, operationParams, extraArgs, operation } = {}) => {

    let select;
    switch (operation) {
        case "COUNT_DISTINCT":
            select = `COUNT (DISTINCT FQ.${operationParams.field}) as ${operationParams.field}`;
            break;
        default:
            select = `${operation}(FQ.${operationParams.field}) as ${operationParams.field}`;
            break;
    }

    const query = `
        SELECT ${select}
        FROM ? as FQ
        ${extraArgs?.groupByFields?.length > 0 ? `GROUP BY ${extraArgs.groupByFields.join(",")}` : ""}
    `;

    let localQueryResult;
    try {
        localQueryResult = alasql(query, [data]);
    } catch(error) {
        localQueryResult = [];
    }

    return localQueryResult.reduce((acc, next) => {
        acc.push(next[operationParams.field]);
        return acc;
    }, []);
};

const sumOperation = (operationProps) => aggregationOperation({ ...operationProps, operation: 'SUM' });
const avgOperation = (operationProps) => aggregationOperation({ ...operationProps, operation: 'AVG' });
const countOperation = (operationProps) => aggregationOperation({ ...operationProps, operation: 'COUNT' });
const countDistinctOperation = (operationProps) => aggregationOperation({ ...operationProps, operation: 'COUNT_DISTINCT' });
const maxOperation = (operationProps) => aggregationOperation({ ...operationProps, operation: 'MAX' });
const minOperation = (operationProps) => aggregationOperation({ ...operationProps, operation: 'MIN' });

const runningSumOperation = ({ data, operationParams } = {}) => {
    let runningValue = 0;
    return data.reduce((acc, next) => {
        const value = next[operationParams.field];
        const isNumber = operationsLib.isNumber(value);
        if (isNumber) {
            runningValue += value;
        }
        acc.push(runningValue);
        return acc;
    }, []);
};

const runningAvgOperation = ({ data, operationParams } = {}) => {
    let runningValue = 0;
    let validRows = 0;
    return data.reduce((acc, next) => {
        const value = next[operationParams.field];
        const isNumber = operationsLib.isNumber(value);
        if (isNumber) {
            runningValue += value;
            validRows++;
        }
        acc.push(runningValue / validRows);
        return acc;
    }, []);
};

const runningMinOperation = (operationProps) => runingMinMaxOperation({ ...operationProps, minMaxFn: min });
const runningMaxOperation = (operationProps) => runingMinMaxOperation({ ...operationProps, minMaxFn: max });
function runingMinMaxOperation({ data, operationParams, minMaxFn }) {
    let runningValue = null;
    return data.map(row => {
        const value = row[operationParams.field];
        runningValue = minMaxFn([runningValue, value].filter(v => operationsLib.isNumber(v))) || null;
        return runningValue;
    });
}

// TODO BIUWER-1316 Ver con JM si aquí aplica el que el valor sea válido
const runningCountOperation = ({ data } = {}) => {
    return data.map((row, index) => index + 1);
};

const movingSumOperation = (operationProps) => resolveMovingOperation({ ...operationProps, aggregationFn: sum });
const movingAvgOperation = (operationProps) => resolveMovingOperation({ ...operationProps, aggregationFn: mean });
const movingMinOperation = (operationProps) => resolveMovingOperation({ ...operationProps, aggregationFn: min });
const movingMaxOperation = (operationProps) => resolveMovingOperation({ ...operationProps, aggregationFn: max });
const resolveMovingOperation = ({ data, operationParams, aggregationFn } = {}) => {
    const dataLength = data.length;
    return data.map((row, rowIndex) => {
        const dataRange = getDataRange({
            data,
            dataLength,
            field: operationParams.field,
            rowIndex,
            prevRange: operationParams.prevRange,
            nextRange: operationParams.nextRange
        });
        return aggregationFn(dataRange);
    });
};

// Difference from operations
const diffFromFirstOperation = (operationProps) => resolveDiffFromOperation({ operationProps, fromOpFn: resolveFromFirstOperation });
const diffFromPrevOperation = (operationProps) => resolveDiffFromOperation({ operationProps, fromOpFn: resolveFromPrevOperation });
const diffFromNextOperation = (operationProps) => resolveDiffFromOperation({ operationProps, fromOpFn: resolveFromNextOperation });
const diffFromLastOperation = (operationProps) => resolveDiffFromOperation({ operationProps, fromOpFn: resolveFromLastOperation });
function resolveDiffFromOperation({ operationProps, fromOpFn }) {
    return fromOpFn({
        ...operationProps,
        rowFn: (fromValue, rowValue) => operationsLib.isNumber(fromValue) && operationsLib.isNumber(rowValue) ? rowValue - fromValue : null
    });
}

// Percent difference from operations
const pctDiffFromFirstOperation = (operationProps) => resolvePctDiffFromOperation({ operationProps, fromOpFn: resolveFromFirstOperation });
const pctDiffFromPrevOperation = (operationProps) => resolvePctDiffFromOperation({ operationProps, fromOpFn: resolveFromPrevOperation });
const pctDiffFromNextOperation = (operationProps) => resolvePctDiffFromOperation({ operationProps, fromOpFn: resolveFromNextOperation });
const pctDiffFromLastOperation = (operationProps) => resolvePctDiffFromOperation({ operationProps, fromOpFn: resolveFromLastOperation });
function resolvePctDiffFromOperation({ operationProps, fromOpFn }) {
    return fromOpFn({
        ...operationProps,
        rowFn: (fromValue, rowValue) => operationsLib.isNumber(fromValue) && fromValue !== 0 && operationsLib.isNumber(rowValue) ? (rowValue - fromValue) / fromValue : null
    });
}

// Percent from operations
const pctFromFirstOperation = (operationProps) => resolvePctFromOperation({ operationProps, fromOpFn: resolveFromFirstOperation });
const pctFromPrevOperation = (operationProps) => resolvePctFromOperation({ operationProps, fromOpFn: resolveFromPrevOperation });
const pctFromNextOperation = (operationProps) => resolvePctFromOperation({ operationProps, fromOpFn: resolveFromNextOperation });
const pctFromLastOperation = (operationProps) => resolvePctFromOperation({ operationProps, fromOpFn: resolveFromLastOperation });
function resolvePctFromOperation({ operationProps, fromOpFn }) {
    return fromOpFn({
        ...operationProps,
        rowFn: (fromValue, rowValue) => operationsLib.isNumber(fromValue) && fromValue !== 0 && operationsLib.isNumber(rowValue) ? rowValue / fromValue : null
    });
}

function resolveFromFirstOperation({ data, operationParams, rowFn }) {
    const firstValue = data[0][operationParams.field];
    const result = data.map(row => {
        const rowValue = row[operationParams.field];
        return rowFn(firstValue, rowValue);
    });
    result[0] = null;
    return result;
}

function resolveFromPrevOperation({ data, operationParams, rowFn }) {
    const result = [null];
    for (let rowIndex = 1; rowIndex < data.length; rowIndex++) {
        const prevRowValue = data[rowIndex - 1][operationParams.field];
        const rowValue = data[rowIndex][operationParams.field];
        result.push(rowFn(prevRowValue, rowValue));
    }
    return result;
}

function resolveFromNextOperation({ data, operationParams, rowFn }) {
    const result = [];
    for (let rowIndex = 0; rowIndex < data.length - 1; rowIndex++) {
        const nextRowValue = data[rowIndex + 1][operationParams.field];
        const rowValue = data[rowIndex][operationParams.field];
        result.push(rowFn(nextRowValue, rowValue));
    }
    result[data.length - 1] = null;
    return result;
}

function resolveFromLastOperation({ data, operationParams, rowFn }) {
    const lastValue = data[data.length - 1][operationParams.field];
    const result = data.map(row => {
        const rowValue = row[operationParams.field];
        return rowFn(lastValue, rowValue);
    });
    result[data.length - 1] = null;
    return result;
}

// Generate an array with specified field of data range
function getDataRange({ data, dataLength, field, rowIndex, prevRange = 0, nextRange = 0 }) {
    const dataRange = [];
    let index = rowIndex - prevRange;
    for (let reverseCount = prevRange + nextRange; reverseCount >= 0 ; reverseCount--) {
        // Only get data of existing rows and his value is number
        if (index >= 0 && index < dataLength) {
            const value = data[index][field];
            operationsLib.isNumber(value) && dataRange.push(value);
        }
        index++;
    }
    return dataRange;
}

export default {
    runningSumOperation,
    runningAvgOperation,
    runningMinOperation,
    runningMaxOperation,
    runningCountOperation,
    movingSumOperation,
    movingAvgOperation,
    movingMinOperation,
    movingMaxOperation,
    diffFromFirstOperation,
    diffFromPrevOperation,
    diffFromNextOperation,
    diffFromLastOperation,
    pctDiffFromFirstOperation,
    pctDiffFromPrevOperation,
    pctDiffFromNextOperation,
    pctDiffFromLastOperation,
    pctFromFirstOperation,
    pctFromPrevOperation,
    pctFromNextOperation,
    pctFromLastOperation,
    sumOperation,
    avgOperation,
    countOperation,
    countDistinctOperation,
    maxOperation,
    minOperation
};