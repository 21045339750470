import { Map, fromJS } from 'immutable';


import {
    DATAMODELS_FETCH_REQUEST, DATAMODELS_FETCH_SUCCESS, DATAMODELS_FETCH_ERROR,
    DATAMODEL_FETCH_REQUEST, DATAMODEL_FETCH_SUCCESS, DATAMODEL_FETCH_ERROR,
    DATAMODEL_CREATE_REQUEST, DATAMODEL_CREATE_SUCCESS, DATAMODEL_CREATE_ERROR,
    DATAMODEL_UPDATE_REQUEST, DATAMODEL_UPDATE_SUCCESS, DATAMODEL_UPDATE_ERROR,
    DATAMODEL_DELETE_REQUEST, DATAMODEL_DELETE_SUCCESS, DATAMODEL_DELETE_ERROR,
    DATAMODELS_INITIALIZE, DATAMODELS_INITIALIZE_DETAIL, DATAMODELS_INITIALIZE_LIST,
    DATAMODEL_QUERY_FETCH_REQUEST, DATAMODEL_QUERY_FETCH_SUCCESS, DATAMODEL_QUERY_FETCH_ERROR,
    DATAMODEL_GET_CARD_QUERY_FETCH_REQUEST, DATAMODEL_GET_CARD_QUERY_FETCH_SUCCESS, DATAMODEL_GET_CARD_QUERY_FETCH_ERROR
} from './datamodels-actions.js';

import {
    UPDATE_PROFILE_SUCCESS, CHANGE_OWNER_SUCCESS
} from "@biuwer/redux/src/system/security/security-actions";

// Libs
import { defaultContext } from "@biuwer/redux/src/config/constants";

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

/**
 * Datamodels Reducers
 */

export default function datamodels(state = initialState, action) {
    let newState, datamodel, index, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || defaultContext}`;
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch(action.type) {
        case DATAMODELS_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));

            return newState;
        case DATAMODELS_INITIALIZE_DETAIL:
            newState = state
                .setIn([detail], Map({}));
            return newState;
        case DATAMODELS_INITIALIZE_LIST:
            newState = state
                .setIn([list], Map({}));
            return newState;
        case DATAMODELS_FETCH_REQUEST:

            newState = state;

            // Datamodels fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case DATAMODELS_FETCH_SUCCESS:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case DATAMODELS_FETCH_ERROR:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    errorMessage: action.errorMessage
                }));

            return newState;
        case DATAMODEL_FETCH_REQUEST:

            newState = state;

            // Datamodel fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case DATAMODEL_FETCH_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case DATAMODEL_FETCH_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case DATAMODEL_CREATE_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case DATAMODEL_CREATE_SUCCESS:

            // Update payload with new created dataset
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        issue: action.issue,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case DATAMODEL_CREATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                }));

            return newState;
        case DATAMODEL_UPDATE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case DATAMODEL_UPDATE_SUCCESS:

            // Update payload with updated user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            issue: action.issue,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case DATAMODEL_UPDATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                }));

            return newState;
        case DATAMODEL_DELETE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case DATAMODEL_DELETE_SUCCESS:

            // Update payload with deleted data connection
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    isFetching: action.isFetching,
                    issue: action.issue,
                    deleted: action.deleted
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                            isFetching: action.isFetching,
                            issue: action.issue,
                            deleted: action.deleted
                        });
                }
            }
            return newState;
        case DATAMODEL_DELETE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        issue: action.issue,
                        issuePayload: action.issuePayload
                    })
                }))
                .mergeIn([list], Map({
                    delete: Map({
                        isFetching: action.isFetching,
                        issue: action.issue,
                        issuePayload: action.issuePayload
                    })
                }));
            return newState;


        case DATAMODEL_QUERY_FETCH_REQUEST:
            datamodel = Map().set('isFetching', action.isFetching).set('error', action.error);
            newState = state.setIn([action.source, action.queryAlias], datamodel);
            return newState;
        case DATAMODEL_QUERY_FETCH_SUCCESS:
            datamodel = Map().set('isFetching', action.isFetching)
                .set('error', action.error)
                .set('dataWarnings', fromJS(action.dataWarnings))
                .set('dataErrors', fromJS(action.dataErrors));
            newState = state.setIn([action.source, action.queryAlias], datamodel);
            return newState;
        case DATAMODEL_QUERY_FETCH_ERROR:
            datamodel = Map(fromJS(action.payload)).set('isFetching', action.isFetching).set('error', action.error);
            newState = state.setIn([action.source, action.queryAlias], datamodel);
            return newState;

        case DATAMODEL_GET_CARD_QUERY_FETCH_REQUEST:

            newState = state;
            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case DATAMODEL_GET_CARD_QUERY_FETCH_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case DATAMODEL_GET_CARD_QUERY_FETCH_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    payload: null,
                    errorMessage: action.errorMessage
                }));

            return newState;

        case UPDATE_PROFILE_SUCCESS:
        case CHANGE_OWNER_SUCCESS:

            newState = state;

            // Check objectType before to check if proceed to update reducer data
            if (action.objectType === 'datamodel' && action.payload && action.payload.length > 0) {
                action.payload.forEach(object => {

                    if (String(state.getIn([detail, 'payload', '_id'])) === String(object._id)) {

                        // If payload is an Immutable Map and has the same _id that the action given object info
                        // Check permissions (platform and object profile_level) before update
                        if (object.readOneAuthorization || (!!object.profile_level && object.profile_level <= 4)) {

                            //Enough permissions to read, object updated
                            newState = newState.setIn([detail, 'payload', 'profiles'], fromJS(object.profiles));
                            newState = newState.setIn([detail, 'payload', 'profile_level'], object.profile_level);
                            newState = newState.setIn([detail, 'payload', 'owner'], fromJS(object.owner));
                        } else {

                            // Not enough permissions, delete payload from reducer
                            newState = state.setIn([detail, 'payload'], null);
                        }
                    }

                    if (state.getIn([list, 'payload'])) {
                        let index = state.getIn([list, 'payload']).findIndex(connection => String(connection.get('_id')) === String(object._id));
                        if (object.changed && index !== -1) {

                            // Check permissions of the object profiles updated and platform permissions
                            if (object.readAuthorization || (!!object.profile_level && object.profile_level <= 4)) {

                                // Enough permissions to read, profiles and profile_level is updated
                                newState = newState.setIn([list, 'payload', index, 'profiles'], fromJS(object.profiles));
                                newState = newState.setIn([list, 'payload', index, 'profile_level'], object.profile_level);
                                newState = newState.setIn([list, 'payload', index, 'owner'], fromJS(object.owner));
                            } else {

                                // Not enough permissions after profiles update, exclude object from List
                                newState = state.setIn([list, 'payload'], newState.getIn([list, 'payload']).filter(connection => String(connection.get('_id')) !== String(object._id)));
                            }
                        }
                    }
                });
            }

            // // Check objectType before to check if proceed to update reducer data
            // if (action.objectType === 'datamodel' && action.payload && action.payload.length > 0) {
            //     action.payload.forEach(object => {
            //
            //         // Check if payload is an Immutable List (array of values)
            //         if (List.isList(state.get('payload'))) {
            //             let index = state.get('payload').findIndex(page => page.get('_id') === object._id);
            //             if (object.changed && index !== -1) {
            //
            //                 // Check permissions of the object profiles updated and platform permissions
            //                 if (Auth.isAuthorized('core.datamodel.query') || (!!object.profile_level && object.profile_level <= 4)) {
            //
            //                     // Enough permissions to read, profiles and profile_level is updated
            //                     newState = state.setIn(['payload', index, 'profiles'], fromJS(object.profiles));
            //                     newState = newState.setIn(['payload', index, 'profile_level'], object.profile_level);
            //                     newState = newState.setIn(['payload', index, 'owner'], fromJS(object.owner));
            //                 } else {
            //
            //                     // Not enough permissions after profiles update, exclude object from List
            //                     newState = state.set('payload', state.get('payload').filter(page => page.get('_id') !== object._id));
            //                 }
            //             }
            //         } else if (Map.isMap(state.get('payload')) && state.getIn(['payload', '_id']) === object._id) {
            //
            //             // If payload is an Immutable Map and has the same _id that the action given object info
            //             // Check permissions (platform and object profile_level) before update
            //             if (Auth.isAuthorized('core.datamodel.readOne') || (!!object.profile_level && object.profile_level <= 4)) {
            //
            //                 //Enough permissions to read, object updated
            //                 newState = state.setIn(['payload', 'profiles'], fromJS(object.profiles));
            //                 newState = newState.setIn(['payload', 'profile_level'], object.profile_level);
            //                 newState = newState.setIn(['payload', 'owner'], fromJS(object.owner));
            //             } else {
            //
            //                 // Not enough permissions, delete payload from reducer
            //                 newState = state.set('payload', null);
            //             }
            //         } else {
            //             newState = state;
            //         }
            //     });
            // } else {
            //     newState = state;
            // }
            return newState;
        default:
            return state;
    }
}