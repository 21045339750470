export default {
    "orders": {
        "title": "Order",
        "title_plural": "Orders"
    },
    "plans": {
        "currentPlan": "Current plan",
        "currentBalance": "Current balance",
        "balanceWarning": "Your current balance is negative, you must catch up on payments to be able to place another order",
        "legacyPlanWarning": {
            "text1": "Your organization is on a legacy plan.",
            "text2": "You can still use it but you’ll have to change to one of the following plans in order to use new features and have updated limits."
        },
        "billed": "Billed",
        "monthly": "Monthly",
        "yearly": "Yearly",
        "purchase": "Purchase",
        "perMonth": "per month",
        "perYear": "per year"
    },
    "checkout": {
        "title": "Checkout",
        "organizationDetailsBlock": {
            "title": "Organization details"
        },
        "paymentMethodBlock": {
            "title": "Payment method",
            "generalDescription1": "As soon as you update your subscription, we will create an order document that will be sent by email to the main contact of the organization and to the additional email addresses indicated in this order.",
            "generalDescription2": "You can also check the order data in the Orders and Invoices section of the administration area.",
            "creditCard": {
                "title": "Card",
                "notAllowedDescription": "You need to select a monthly billing period to pay by card.",
                "notAllowedButton": "Change to Monthly"
            },
            "directDebit": {
                "title": "Direct debit",
                "description": "We will also send you the completed SEPA Mandate document so that you can indicate the bank details with which to make the monthly direct debits. Please complete this document and return it to us following the instructions we will give you.",
                "notAllowedDescription": "You need to select a monthly billing period to pay by direct debit.",
                "notAllowedButton": "Change to Monthly"
            },
            "wireTransfer": {
                "title": "Wire transfer",
                "description": "The bank account, the total amount, the applicable taxes and the terms to carry out the bank transfer, appear in the generated order document itself.",
                "notAllowedDescription": "You need to select an annual billing period to pay by bank transfer.",
                "notAllowedButton": "Change to Annual"
            }
        },
        "orderAdditionalDetailsBlock": {
            "title": "Order additional details",
            "sendNotificationEmailTo": "Also send notification email to",
            "orderAdditionalNotes": "Order additional notes"
        },
        "subscriptionBlock": {
            "chooseBillingPeriod": "Choose your billing period",
            "billingPeriod": "Billing period",
            "monthly": "Monthly",
            "annual": "Annual",
            "month": "month",
            "year": "year",
            "currentPeriod": "Current Period",
            "currentSubscription": "Current subscription",
            "totalCurrentSubscription": "Total current subscription",
            "subscriptionUpdates": "Subscription updates",
            "totalSubscriptionUpdates": "Total now",
            "currentBalance": "Current balance",
            "currentBalanceTooltip": {
                "p1": "The amount shown indicates the current financial balance of your Organization with Biuwer. Most commonly, the balance is 0.",
                "p2": "A negative quantity implies that there is at least one order pending payment.",
                "p3": "A positive amount implies that there is a balance in favor of your Organization that will be used in the next order."
            },
            "dueTodayTooltip": {
                "p1": "The amount shown is calculated as a prorate, based on the current date in the current billing period and the purchased products.",
                "p2": "A positive amount implies a decrease in the balance of your Organization and the immediate payment of the amount shown.",
                "p3": "A negative amount implies an increase in the balance of your Organization."
            },
            "newBalance": "New Balance",
            "newBalanceTooltip": {
                "p1": "The amount shown is calculated by adding the total amount due today to the current Balance.",
                "p2": "A positive amount implies that there is a balance in favor of your Organization that will be used in the next order.",
                "p3": "A negative amount implies a balance to be paid for the purchased products."
            },
            "newSubscription": "New subscription",
            "totalNewSubscription": "New subscription Total",
            "createSubscription": "Create subscription",
            "updateSubscription": "Update subscription",
            "units": "Units",
            "taxBase": "Tax base"
        },
        "promoCode": "Promo code",
        "promoCodeError": "Invalid promo code",
        "intervalError": "This promo code can't be applied with current billing interval",
    },
    "orderHistoryPage": {
        "orderOptions": {
            "payOrder": "Pay order",
            "downloadOrder": "Download order (PDF)",
            "downloadInvoice": "Download invoice (PDF)",
            "changeStatusToPaid": "Change status to paid",
            "cancelOrder": "Cancel order",
            "payNow": "Pay now"
        },
        "sortOptions": {
            "created": "Order date",
            "status": "Order status"
        },
        "orderCard": {
            "orderNumber": "Order # <0>{{orderNumber}}</0>",
            "orderAmount": "Total: <0>{{amount}}</0>",
            "orderCreated": "Order date: <0>{{created}}</0>",
            "orderStatus": "Order {{status}}",
            "invoiceStatus": "Invoice {{status}}",
            "paymentMethod": "Payment method: <0>{{method}}</0>",
            "orderPeriod": "Period: <0>{{period}}</0>",
            "amount": "Amount: <0>{{amount}}</0>",
            "currency": "Currency: <0>{{currency}}</0>",
            "orderNumberLabel": "Order number: <0>{{orderNumber}}</0>"
        },
        "manualPaymentWarning": "Manual payment required",
        "orderNumber": "Order number",
        "paymentMethod": "Payment method",
        "orderCreated": "Order date",
        "amount": "Total",
        "taxAmount": "Tax",
        "taxBase": "Base"
    }
};