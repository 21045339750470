import Auth from "@biuwer/redux/src/system/auth/auth-lib";

// Set segment environment (set as the env variable when creating a build
const env = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SEGMENT_ENV : 'development';

const itParams = () => ({
    source: Auth.getAuthApp() === "biuwer" ? 'webapp' : "biuwer-share",
    environment: env
});

/**
 * Get global params
 */
const getGlobalParams = () => {
    const user = Auth.getUser();

    return {
        userId: (user && user._id) || null,
        orgId: Auth.getOrganizationId()
    };
};

/**
 * Identify a page with segment page method
 * @param {String} name Page name
 */
export const segmentPage = (name) => {
    const properties = {
        ...itParams(),
        ...getGlobalParams()
    };

    window.analytics.page(name, properties);
};

/**
 * Identify a user with segment identify method
 * @param {Object} user User object
 */
export const segmentIdentify = (user) => {
    const userId = user._id;
    const traits = {
        ...itParams(),
        ...getGlobalParams(),
        firstName: user.name,
        lastName: user.surnames,
        email: user.email,
        organizations: user.organizations
    };

    window.analytics.identify(userId, traits);
};

/**
 * Track a event with segment track method
 * @param {String} event Event name
 * @param {Object} eventParams JSON Object with event params
 */
export const segmentTrack = (event, eventParams) => {
    const properties = {
        ...itParams(),
        ...getGlobalParams(),
        ...eventParams
    };

    window.analytics.track(event, properties);
};
