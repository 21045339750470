
// Action Types
export const SET_DRAWER_IS_OPEN = 'SET_DRAWER_IS_OPEN';
export const SET_CHANGE_ORG_DIALOG = 'SET_CHANGE_ORG_DIALOG';

export const setDrawerIsOpen = (sidebarsData) => {
    return {
        type: SET_DRAWER_IS_OPEN,
        isOpen: sidebarsData
    };
};

export const setChangeOrgDialog = (sidebarsData) => {
    return {
        type: SET_CHANGE_ORG_DIALOG,
        isOpen: sidebarsData
    };
};
