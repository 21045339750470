import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SignupEmailSent extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { t } = this.props;

        return (
            <div className="animated fadeIn">
                <div>
                    <h4 className="p-t-25 text-center">{t('auth.signupForm.headerTitle')}</h4>
                    <h3 className="p-t-25 text-center" style={{ fontWeight: 'bold' }}>{t('auth.signupEmailSent.almostThereMessage')}</h3>
                    <h3 className="text-center">{t('auth.signupEmailSent.verifyEmailMessage')}</h3>
                    <h5 className="p-t-25 m-l-15">{t('auth.signupEmailSent.infoSafeMessage')}</h5>
                    <h4 className="text-center" style={{ fontWeight: 'bold' }}>{this.props.userEmail}</h4>
                </div>
                <div className="pull-bottom sm-pull-bottom">
                    <div className="m-b-30 p-r-80 sm-m-t-20 sm-p-r-15 sm-p-b-20 clearfix">
                        <div className="col m-t-10">
                            <p>
                                <Link to="/signin" className="span-link text-info small">
                                    {t('auth.signupForm.signinLabel')}
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SignupEmailSent;
