import React from 'react';
import { Map as ImmMap, List, fromJS } from 'immutable';
import { isEmpty, toLower, toUpper } from 'lodash';
import i18n from '@biuwer/core/src/i18n';

// Libs
import { aggregationNumberEmptyFilterOptions, aggregationTextEmptyFilterOptions } from "@biuwer/filters/src/filters-lib";
import { getDateLevelOptions } from "@biuwer/common/src/libs/dates-lib";
import { getChartFromCatalog } from "@biuwer/common/src/libs/charts/core/charts-constants";
import operationsLib from '@biuwer/core/src/query-system/calculated-fields-lib/operations-lib';
import { getDCEXAxisSectionTitle, getDCEYAxisSectionTitle, getDCEBreakSectionTitle } from "@biuwer/biuwer/src/cards/data-card-editor/dce-visualization-tab/dce-chart-config/dce-chart-config-lib";

function checkFieldInCalculatedFields({ calculatedFields, fieldQueryPosition, fieldsToDelete }) {

    if (fieldsToDelete && fieldsToDelete.findIndex(field => field.query === fieldQueryPosition.query && field.position === fieldQueryPosition.position) === -1) {
        fieldsToDelete.push(fieldQueryPosition);
    }

    const cFieldsToDelete = [];
    const cFields = (calculatedFields && calculatedFields.toJS()) || [];
    for (let calculatedField of cFields) {

        function checkFieldInParsedExpression(parsedExpression) {

            function addcFieldToDelete(field) {
                if (field.query === fieldQueryPosition.query && field.position === fieldQueryPosition.position) {
                    cFieldsToDelete.push({
                        query: "CF",
                        position: calculatedField.position
                    });
                }
            }

            if (parsedExpression?.type === "field") {
                addcFieldToDelete(parsedExpression.field);
            }
            else if (parsedExpression?.type === "operation") {
                let peOperation = parsedExpression.operation;
                operationsLib.operationFunctions({
                    operation: peOperation,
                    fn: checkFieldInParsedExpression,
                    timeWindowFn: extraArgs => {
                        addcFieldToDelete(extraArgs.metricField);
                        addcFieldToDelete(extraArgs.dateField);
                    },
                });
            }
            return parsedExpression;
        }

        checkFieldInParsedExpression(calculatedField.parsedExpression);

    }

    for (let cFieldToDelete of cFieldsToDelete) {
        checkFieldInCalculatedFields({ calculatedFields, fieldQueryPosition: cFieldToDelete, fieldsToDelete });
    }

}

function checkLocalQueryFieldElement(localQueryFieldElement, query, position) {
    return (
        localQueryFieldElement.getIn(["local_query_field", "queryField", "query"]) === query &&
        localQueryFieldElement.getIn(["local_query_field", "queryField", "position"]) === position
    );
}

function deleteActionsWithDeletedFields(actions, deletedFieldIds) {
    return actions
        .filter(action => !deletedFieldIds.includes(action.getIn(['config', 'field_link'])))
        .map(action => {
            // Delete filters with card field mapping of deleted local query fields
            if (action.get('action_type') === 'card_navigation' || action.get('action_type') === 'page_navigation') {
                action = action.updateIn(['config', 'filters'], filters => filters.filter(filter => {
                    if (filter.getIn(['mappings', 'mapping_type']) === 'card_field') {
                        return !deletedFieldIds.includes(filter.getIn(['mappings', 'card_field']))
                    }
                    return true;
                }));
            }
            return action;
        });
}

function checkFieldInActions(actions, fieldId) {
    for (let action of actions) {
        if (action.getIn(['config', 'field_link']) === fieldId) return true;
        if (action.get('action_type') === 'card_navigation' || action.get('action_type') === 'page_navigation') {
            for (let actionFilter of action.getIn(['config', 'filters'])) {
                if (actionFilter.getIn(['mappings', 'mapping_type']) === 'card_field') {
                    return actionFilter.getIn(['mappings', 'card_field']) === fieldId;
                }
            }
        }
    }
    return false;
}

function filterItemConditionalStyles({ item, deletedFieldIds, path = 'conditional_style' }) {
    return item.update(path, cStyles => cStyles.filter(cStyle => {
        return !deletedFieldIds.includes(cStyle.getIn(['condition_config', 'local_query_field', 'fieldId']));
    }));
}

// Function to delete conditional styles of deleted field ids
function deleteConditionalStylesWithDeletedFields({ dceState, deletedFieldIds = [] } = {}) {
    let visualization = dceState.getIn(['card', 'visualization']);
    switch (dceState.getIn(['card', 'card_type'])) {
        case CARD_TYPE_KEYS.KPI:
            visualization = visualization.update('kpiGroups', kpiGroups => kpiGroups.map(kpiGroup => {
                return kpiGroup.update('kpiItems', kpiItems => kpiItems.map(kpiItem => {
                    return filterItemConditionalStyles({ item: kpiItem, deletedFieldIds });
                }));
            }));
            break;
        case CARD_TYPE_KEYS.VERTICAL_TABLE:
            // Columns
            const columnTypes = ['table_columns', 'interactive_columns'];
            for (let columnType of columnTypes) {
                visualization = visualization.update(columnType, columns => columns.map(column => {
                    return filterItemConditionalStyles({ item: column, deletedFieldIds });
                }));
            }
            // Rows
            visualization = visualization.update('table_settings', tableSettings => {
                return filterItemConditionalStyles({ item: tableSettings, deletedFieldIds, path: 'conditional_row_style' });
            });
            break;
        case CARD_TYPE_KEYS.CROSS_TABLE:
            // Sections
            const ctSections = ['columns', 'rows', 'metrics'];
            for (let ctSection of ctSections) {
                visualization = visualization.update(ctSection, section => section.map(ctItem => {
                    return filterItemConditionalStyles({ item: ctItem, deletedFieldIds });
                }));
            }
            break;
        default:
            break;
    }
    return dceState.setIn(['card', 'visualization'], visualization);
}

const CARD_TYPE_KEYS = {
    KPI: "KPI",
    VERTICAL_TABLE: "VerticalTable",
    CROSS_TABLE: "CrossTable",
    CHART: "Chart",
    MAP: "Map",
    CUSTOM: "Custom"
};

const cardPropertiesMatrix = {
    checkActions: {
        [CARD_TYPE_KEYS.KPI]: true,
        [CARD_TYPE_KEYS.VERTICAL_TABLE]: true,
        [CARD_TYPE_KEYS.CROSS_TABLE]: true,
        [CARD_TYPE_KEYS.CHART]: true,
        [CARD_TYPE_KEYS.MAP]: false,
        [CARD_TYPE_KEYS.CUSTOM]: false,
    },
    deleteVisualizationField: {
        [CARD_TYPE_KEYS.KPI]: (dceState, { fieldId, kpiGroupIndex, kpiIndex } = {}) => {
            // Delete related actions
            const newActions = deleteActionsWithDeletedFields(dceState.getIn(['card', 'actions']), [fieldId]);
            dceState = dceState.setIn(['card', 'actions'], newActions);
            // Delete field
            dceState = dceState.updateIn(['card', 'visualization', 'kpiGroups', kpiGroupIndex, 'kpiItems'], kpiItems => kpiItems.delete(kpiIndex));
            // Delete related conditional styles
            dceState = deleteConditionalStylesWithDeletedFields({ dceState, deletedFieldIds: [fieldId] });
            // Return dce state
            return dceState;
        },
        [CARD_TYPE_KEYS.VERTICAL_TABLE]: (dceState, { fieldId, columnType, columnIndex } = {}) => {
            // Delete related actions
            const newActions = deleteActionsWithDeletedFields(dceState.getIn(['card', 'actions']), [fieldId]);
            dceState = dceState.setIn(['card', 'actions'], newActions);
            // Delete field
            dceState = dceState.updateIn(['card', 'visualization', columnType], columns => columns.delete(columnIndex));
            // Delete related sort fields
            const sortFields = dceState.getIn(["card", "visualization", "table_settings", "default_sort_fields"]);
            let newSortFields = dceState.getIn(["card", "visualization", "table_settings", "default_sort_fields"]);
            let newSortDirections = dceState.getIn(["card", "visualization", "table_settings", "default_sort_directions"]);
            for (let sortField of sortFields) {
                if (fieldId === sortField) {
                    const sortFieldIndex = newSortFields.findIndex(nsf => nsf === sortField);
                    newSortFields = newSortFields.delete(sortFieldIndex);
                    newSortDirections = newSortDirections.delete(sortFieldIndex);
                }
            }
            dceState = dceState.setIn(["card", "visualization", "table_settings", "default_sort_fields"], newSortFields);
            dceState = dceState.setIn(["card", "visualization", "table_settings", "default_sort_directions"], newSortDirections);
            // Delete related conditional styles
            dceState = deleteConditionalStylesWithDeletedFields({ dceState, deletedFieldIds: [fieldId] });

            // If table_columns and interactive_columns are empty, delete selected local_dataset
            if (dceState.getIn(["card", "visualization", "table_columns"]).size === 0 && dceState.getIn(["card", "visualization", "interactive_columns"]).size === 0) {
                dceState = dceState.deleteIn(["card", "visualization", "table_settings", "local_dataset"]);
            }

            // Return dce state
            return dceState;
        },
        [CARD_TYPE_KEYS.CROSS_TABLE]: (dceState, { fieldId, vFieldType, fieldIndex } = {}) => {
            // Delete related actions
            const newActions = deleteActionsWithDeletedFields(dceState.getIn(['card', 'actions']), [fieldId]);
            dceState = dceState.setIn(['card', 'actions'], newActions);
            // Delete field
            dceState = dceState.updateIn(['card', 'visualization', vFieldType], fields => fields.delete(fieldIndex));
            // Delete related conditional styles
            dceState = deleteConditionalStylesWithDeletedFields({ dceState, deletedFieldIds: [fieldId] });

            // If columns, rows and metrics are empty, delete selected local_dataset
            if (dceState.getIn(["card", "visualization", "columns"]).size === 0 && dceState.getIn(["card", "visualization", "rows"]).size === 0 && dceState.getIn(["card", "visualization", "metrics"]).size === 0) {
                dceState = dceState.deleteIn(["card", "visualization", "table_settings", "local_dataset"]);
            }

            // Return dce state
            return dceState;
        },
        [CARD_TYPE_KEYS.CHART]: (dceState, { fieldId, vFieldType, fieldIndex } = {}) => {
            // Delete related actions
            const newActions = deleteActionsWithDeletedFields(dceState.getIn(['card', 'actions']), [fieldId]);
            dceState = dceState.setIn(['card', 'actions'], newActions);
            // Delete field
            dceState = dceState.updateIn(['card', 'visualization', vFieldType], fields => {
                return vFieldType === 'metrics' ? fields.delete(fieldIndex) : ImmMap({});
            });

            const dimension = dceState.getIn(["card", "visualization", "dimension"]) && dceState.getIn(["card", "visualization", "dimension"]).size > 0;
            const breakBy = dceState.getIn(["card", "visualization", "breakBy"]) && dceState.getIn(["card", "visualization", "breakBy"]).size > 0;

            // If dimension, breakBy and metrics are empty, delete selected local_dataset
            if (!dimension && !breakBy && dceState.getIn(["card", "visualization", "metrics"]).size === 0) {
                dceState = dceState.deleteIn(["card", "visualization", "graph_config", "local_dataset"]);
            }

            // Return dce state
            return dceState;
        },
        [CARD_TYPE_KEYS.MAP]: (dceState, { mapLayerIndex, source, fieldIndex } = {}) => {
            // Delete field
            dceState = dceState.updateIn(['card', 'visualization', 'map_layers', mapLayerIndex, ...(source.split('.'))], item => {
                switch (source) {
                    case 'color':
                    case 'symbol.size':
                        item = item.delete('local_query_field');
                        break;
                    case 'matching_field':
                    case 'lat_field':
                    case 'lng_field':
                        item = undefined;
                        break;
                    case 'tooltip':
                    case 'popup':
                        item = item.update('fields', fields => fields.delete(fieldIndex));
                        break;
                    default:
                        break;
                }
                return item;
            });
            // Return dce state
            return dceState;
        },
    }
};

const FAIL_CHECK_CODES = {
    QUERY_ID_NO_EXISTS: 'QUERY_ID_NO_EXISTS',
    QUERY_ID_MALFORMED: 'QUERY_ID_MALFORMED',
    QUERY_DATAMODEL_NO_EXISTS: 'QUERY_DATAMODEL_NO_EXISTS',
    QUERY_FIELD_MALFORMED: 'QUERY_FIELD_MALFORMED',
    QUERY_SORT_FIELD_MALFORMED: 'QUERY_SORT_FIELD_MALFORMED',
    KPI_NO_FIELDS: 'KPI_NO_FIELDS',
    VERTICAL_TABLE_NO_COLUMNS: 'VERTICAL_TABLE_NO_COLUMNS',
    CROSS_TABLE_NO_METRICS: 'CROSS_TABLE_NO_METRICS',
    CHART_TYPE_NO_EXISTS: 'CHART_TYPE_NO_EXISTS',
    CHART_CONFIG_INVALID: 'CHART_CONFIG_INVALID',
    MAP_CONFIG_INVALID: 'MAP_CONFIG_INVALID'
};

const NUMBER = 'number';
const STRING = 'string';
const BOOLEAN = 'boolean';
const DATE = 'date';
const DATETIME = 'datetime';
const DATE_DATA_TYPES = [DATE, DATETIME];
const DATA_TYPES = [NUMBER, STRING, BOOLEAN, ...DATE_DATA_TYPES];

const MEASURE = 'measure';
const DIMENSION = 'dimension';
const FIELD_TYPES = [MEASURE, DIMENSION];

function checkCanSubmit(dceState) {
    const failChecks = [];

     // Check errors
    if (dceState.getIn(["card", "error"])) {
        dceState = dceState.set("canSubmit", false);
        return dceState;
    }

    // Check card queries
    if (dceState.getIn(["card", "queries"])) {
        for (let cardQuery of dceState.getIn(["card", "queries"])) {
            // Check query id
            if (!cardQuery.get('queryId')) {
                failChecks.push({ code: FAIL_CHECK_CODES.QUERY_ID_NO_EXISTS });
                continue;
            }
            if (
                typeof cardQuery.get('queryId') !== 'string' ||
                !cardQuery.get('queryId').startsWith('Q')
            ) {
                failChecks.push({ code: FAIL_CHECK_CODES.QUERY_ID_MALFORMED });
            }
            // Check datamodel
            if (!cardQuery.get('datamodel')) {
                failChecks.push({
                    code: FAIL_CHECK_CODES.QUERY_DATAMODEL_NO_EXISTS,
                    query: cardQuery.get('queryId')
                });
            }
            // Check fields
            cardQuery.get('fields').forEach((field, fieldIndex) => {
                if (
                    typeof field.get('_id') !== 'string' ||
                    !field.get('_id').startsWith('cqf') ||
                    typeof field.get('position') !== 'number' ||
                    field.get('position') !== (fieldIndex + 1) ||
                    typeof field.get('dataset') !== 'number' ||
                    !ImmMap.isMap(field.get('dataField')) ||
                    typeof field.getIn(['dataField', '_id']) !== 'string' ||
                    typeof field.getIn(['dataField', 'alias']) !== 'string' ||
                    typeof field.getIn(['dataField', 'data_type']) !== 'string' ||
                    typeof field.getIn(['dataField', 'calculated_field']) !== 'boolean' ||
                    !DATA_TYPES.includes(field.getIn(['dataField', 'data_type'])) ||
                    typeof field.get('field_type') !== 'string' ||
                    !FIELD_TYPES.includes(field.get('field_type')) ||
                    (
                        field.get('field_type') === MEASURE &&
                        (field.getIn(['dataField', 'data_type']) === NUMBER
                            ? !aggregationNumberEmptyFilterOptions.map(option => option.value).includes(field.get('aggregation'))
                            : !aggregationTextEmptyFilterOptions.map(option => option.value).includes(field.get('aggregation')))
                    ) ||
                    (
                        field.get('field_type') === DIMENSION &&
                        DATE_DATA_TYPES.includes(field.getIn(['dataField', 'data_type'])) &&
                        !getDateLevelOptions().map(option => option.value).includes(field.get('date_level'))
                    )
                ) {
                    failChecks.push({ code: FAIL_CHECK_CODES.QUERY_FIELD_MALFORMED });
                }
            });
            // Check sort fields
            for (let sortField of cardQuery.get('sort')) {
                if (
                    typeof sortField.get('dataset') !== 'number' ||
                    typeof sortField.get('fieldId') !== 'string' ||
                    !cardQuery.get('fields').map(f => f.getIn(['dataField', '_id'])).includes(sortField.get('fieldId')) ||
                    typeof sortField.get('order') !== 'number' ||
                    (sortField.get('order') !== 1 && sortField.get('order') !== -1)
                ) {
                    failChecks.push({ code: FAIL_CHECK_CODES.QUERY_SORT_FIELD_MALFORMED });
                }
            }
        }
    }

    // Check query relations --> Check in query relations dialog
    // Check calculated fields --> Check in calculated fields dialog
    // Check visualization
    const visualization = dceState.getIn(["card", "visualization"]);
    switch (dceState.getIn(["card", "card_type"])) {
        // TODO JORDI ¿Se deberían de limpiar los kpi de grupos superiores cuando se cambie a una estructura de menos grupos?
        // Lo mismo cuando cambiamos tipo de gráfico.
        case CARD_TYPE_KEYS.KPI:
            let totalFields = 0;
            visualization.get('kpiGroups').forEach(kpiGroup => totalFields += kpiGroup.get('kpiItems').size);
            if (totalFields === 0) {
                failChecks.push({ code: FAIL_CHECK_CODES.KPI_NO_FIELDS });
            }
            break;
        case CARD_TYPE_KEYS.VERTICAL_TABLE:
            if (visualization.get('table_columns').size < 1) {
                failChecks.push({ code: FAIL_CHECK_CODES.VERTICAL_TABLE_NO_COLUMNS });
            }
            break;
        case CARD_TYPE_KEYS.CROSS_TABLE:
            if (visualization.get('metrics').size < 1) {
                failChecks.push({ code: FAIL_CHECK_CODES.CROSS_TABLE_NO_METRICS });
            }
            break;

        case CARD_TYPE_KEYS.CHART:
            if (!visualization.getIn(['graph_config', 'type'])) {
                failChecks.push({ code: FAIL_CHECK_CODES.CHART_TYPE_NO_EXISTS });
                break;
            }
            const chart = getChartFromCatalog(visualization.getIn(['graph_config', 'type']));
            let dimCheck = false, metricsCheck = false, breakerCheck = true, radiusCheck = true, heatmapCheck = true;
            if (chart && chart.conditions && !isEmpty(chart.conditions)) {
                if (chart.requiresDimension) {
                    if (chart.conditions.dimension.min && chart.conditions.dimension.min === 1) {
                        dimCheck = !isEmpty((visualization.get('dimension') || ImmMap({})).toJS());
                    } else dimCheck = true;
                } else dimCheck = true;
                if (chart.conditions.metrics) {
                    if (chart.conditions.metrics.max && chart.conditions.metrics.max >= 1) {
                        metricsCheck = List.isList(visualization.get('metrics')) && visualization.get('metrics').size <= chart.conditions.metrics.max;
                    } else metricsCheck = true;
                    if (chart.conditions.metrics.min && chart.conditions.metrics.min >= 0) {
                        metricsCheck = metricsCheck && List.isList(visualization.get('metrics')) && visualization.get('metrics').size >= chart.conditions.metrics.min;
                    }
                }
                if (chart.requiresBreakBy) {
                    if (chart.conditions.breaker.min && chart.conditions.breaker.min >= 1) {
                        breakerCheck = !isEmpty((visualization.get('break_by') || ImmMap({})).toJS());
                    }
                } else breakerCheck = true;
                if (chart.requiresRadius) {
                    if (chart.conditions.radius.min && chart.conditions.radius.min >= 1) {
                        radiusCheck = !isEmpty((visualization.get('radius') || ImmMap({})).toJS());
                    }
                } else radiusCheck = true;
                if (chart.requiresHeatmap) {
                    heatmapCheck = !isEmpty((visualization.get('heatmap') || ImmMap({})).toJS());
                }
            }
            if (!(dimCheck && metricsCheck && breakerCheck && radiusCheck && heatmapCheck)) {
                failChecks.push({ code: FAIL_CHECK_CODES.CHART_CONFIG_INVALID, extraArgs: { chart, dimCheck, metricsCheck, breakerCheck, radiusCheck, heatmapCheck } });
            }
            break;
        case CARD_TYPE_KEYS.MAP:
            const mapLayers = [];
            visualization.get('map_layers').toJS().forEach(mapLayer => {
                const layerErrors = [];
                // Required lat and lng fields
                if (mapLayer.location_defined_by === "lat_lng_fields") {
                    if (isEmpty(mapLayer.lat_field)) {
                        layerErrors.push(i18n.t("cards.mapConditionsMessages.singleMessage", { fieldType: i18n.t("cards.mapStructure.latitude").toLowerCase() }));
                    }
                    if (isEmpty(mapLayer.lng_field)) {
                        layerErrors.push(i18n.t("cards.mapConditionsMessages.singleMessage", { fieldType: i18n.t("cards.mapStructure.longitude").toLowerCase() }));
                    }
                }
                // Required map catalog, map catalog matching field and layer matching field fields
                if (mapLayer.location_defined_by === "administrative_regions") {
                    if (!mapLayer.map_catalog || !mapLayer.map_catalog.map_catalog_id) {
                        layerErrors.push(i18n.t("cards.mapConditionsMessages.selectOptionMessage_male", { option: i18n.t("cards.mapCatalog.title").toLowerCase() }));
                    }
                    if (!mapLayer.map_catalog || !mapLayer.map_catalog.map_catalog_matching_field) {
                        layerErrors.push(i18n.t("cards.mapConditionsMessages.selectOptionMessage_male", { option: i18n.t("cards.mapCatalog.matchingFieldMeaning").toLowerCase() }));
                    }
                    if (isEmpty(mapLayer.matching_field)) {
                        layerErrors.push(i18n.t("cards.mapConditionsMessages.singleMessage", { fieldType: i18n.t("cards.mapCatalog.matchingField").toLowerCase() }));
                    }
                }
                // If not fixed color, color_field is required
                if (!mapLayer.color.fixed && isEmpty(mapLayer.color.local_query_field)) {
                    layerErrors.push(i18n.t("cards.mapConditionsMessages.singleMessage", { fieldType: i18n.t("common.colorLabel").toLowerCase() }));
                }
                // If show tooltip, tooltip_fields can't be empty
                if (mapLayer.tooltip.show && (!mapLayer.tooltip.fields || mapLayer.tooltip.fields.length <= 0)) {
                    layerErrors.push(i18n.t("cards.mapConditionsMessages.multipleMessage", { fieldType: i18n.t("cards.mapLayerSymbolStructure.tooltip").toLowerCase() }));
                }
                // If show popup, popup_fields can't be empty
                if (mapLayer.popup.show && (!mapLayer.popup.fields || mapLayer.popup.fields.length <= 0)) {
                    layerErrors.push(i18n.t("cards.mapConditionsMessages.multipleMessage", { fieldType: i18n.t("cards.mapLayerSymbolStructure.tooltip").toLowerCase() }));
                }
                switch (mapLayer.layer_type) {
                    case "symbol":
                        // If not fixed size, size_field is required
                        if (!mapLayer.symbol.size.fixed && isEmpty(mapLayer.symbol.size.local_query_field)) {
                            layerErrors.push(i18n.t("cards.mapConditionsMessages.singleMessage", { fieldType: i18n.t("common.sizeLabel").toLowerCase() }));
                        }
                        break;
                    case "choropleth":
                        break;
                    default: break;
                }
                layerErrors.length > 0 && mapLayers.push({
                    name: mapLayer.name,
                    layerErrors: layerErrors
                });
            });
            if (mapLayers.length > 0) {
                failChecks.push({ code: FAIL_CHECK_CODES.MAP_CONFIG_INVALID, extraArgs: { mapLayers } });
            }
            break;
        default:
            break;
    }
    dceState = dceState.set("canSubmit", failChecks.length === 0);
    dceState = dceState.set("failChecks", fromJS(failChecks));
    return dceState;
}

function getChartVisualizationErrorMessage({ chart = null, section, extraArgs }) {
    return {
        dimension: () => {
            const fieldType = ["scatter", "bubbles"].includes(chart.type) ? i18n.t("cards.chartConditionsMessages.twoOptionsMessage", { firstOption: i18n.t("common.dimensionLabel"), secondOption: i18n.t("common.metricLabel") }) : i18n.t("common.dimensionLabel");
            let message = i18n.t('cards.chartConditionsMessages.singleMessage', { fieldType: toLower(i18n.t(fieldType)), section: toUpper(i18n.t(getDCEXAxisSectionTitle(chart.type))) });
            return message;
        },
        metrics: () => {
            const fieldType = i18n.t("common.metricLabel");
            let message = i18n.t('cards.chartConditionsMessages.singleMessage', { fieldType: toLower(fieldType), section: toUpper(i18n.t(getDCEYAxisSectionTitle(chart.type))) });
            if (chart?.conditions?.metrics?.max > 1) {
                message = i18n.t('cards.chartConditionsMessages.multipleMessage', { fieldType: toLower(fieldType), section: toUpper(i18n.t(getDCEYAxisSectionTitle(chart.type))) });
            }
            return message;
        },
        breakBy: () => {
            const fieldType = i18n.t("common.dimensionLabel");
            let message = i18n.t('cards.chartConditionsMessages.singleMessage', { fieldType: toLower(fieldType), section: toUpper(i18n.t(getDCEBreakSectionTitle(chart.type))) });
            return message;
        },
        radius: () => {
            const fieldType = i18n.t("common.metricLabel");
            let message = i18n.t('cards.chartConditionsMessages.singleMessage', { fieldType: toLower(fieldType), section: toUpper(i18n.t("cards.chartConfig.radius")) });
            return message;
        },
        heatmap: () => {
            const fieldType = i18n.t("common.dimensionLabel");
            let message = i18n.t('cards.chartConditionsMessages.singleMessage', { fieldType: toLower(fieldType), section: toUpper(i18n.t("common.y-axisLabel")) });
            return message;
        }
    }[section]();
}

function getCanSubmitErrorMessage({ code, mode, extraArgs }) {
    return {
        [FAIL_CHECK_CODES.KPI_NO_FIELDS]: () => mode === 'save' ? i18n.t('cards.kpiMessageSave') : i18n.t('cards.kpiMessagePreview'),
        [FAIL_CHECK_CODES.VERTICAL_TABLE_NO_COLUMNS]: () => mode === 'save' ? i18n.t('cards.verticalTableMessageSave') : i18n.t('cards.verticalTableMessagePreview'),
        [FAIL_CHECK_CODES.CROSS_TABLE_NO_METRICS]: () => mode === 'save' ? i18n.t('cards.crossTableMessageSave') : i18n.t('cards.crossTableMessagePreview'),
        [FAIL_CHECK_CODES.CHART_TYPE_NO_EXISTS]: () => (
            <div>
                <div>{i18n.t('cards.chartMessage', { mode: mode === 'save' ? toLower(i18n.t('common.saveLabel')) : toLower(i18n.t('common.previewLabel')) }) + ':'}</div>
                <ul>
                    <li>{getChartVisualizationErrorMessage({ extraArgs, section: "dimension" })}</li>
                    <li>{getChartVisualizationErrorMessage({ extraArgs, section: "metrics" })}</li>
                </ul>
            </div>
        ),
        [FAIL_CHECK_CODES.CHART_CONFIG_INVALID]: () => (
            <div>
                <div>{i18n.t('cards.chartMessage', { mode: mode === 'save' ? toLower(i18n.t('common.saveLabel')) : toLower(i18n.t('common.previewLabel')) }) + ':'}</div>
                <ul>
                    {!extraArgs.chart ? (
                        <li>{i18n.t('cards.chartConditionsMessages.typeMessage')}</li>
                    ) : null}
                    {!extraArgs.dimCheck ? (
                        <li>{getChartVisualizationErrorMessage({ chart: extraArgs.chart, section: "dimension", extraArgs })}</li>
                    ) : null}
                    {!extraArgs.metricsCheck ? (
                        <li>{getChartVisualizationErrorMessage({ chart: extraArgs.chart, section: "metrics", extraArgs })}</li>
                    ) : null}
                    {!extraArgs.breakerCheck ? (
                        <li>{getChartVisualizationErrorMessage({ chart: extraArgs.chart, section: "breakBy", extraArgs })}</li>
                    ) : null}
                    {!extraArgs.radiusCheck ? (
                        <li>{getChartVisualizationErrorMessage({ chart: extraArgs.chart, section: "radius", extraArgs })}</li>
                    ) : null}
                    {!extraArgs.heatmapCheck ? (
                        <li>{getChartVisualizationErrorMessage({ chart: extraArgs.chart, section: "heatmap", extraArgs })}</li>
                    ) : null}
                </ul>
            </div>
        ),
        [FAIL_CHECK_CODES.MAP_CONFIG_INVALID]: () => (
            <div>
                <span>{i18n.t('cards.chartMessage', { mode: mode === 'save' ? toLower(i18n.t('common.saveLabel')) : toLower(i18n.t('common.previewLabel')) }) + ':'}</span>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {extraArgs.mapLayers.map((mapLayer, mapLayerIndex) => {
                        return (
                            <div key={mapLayerIndex}>
                                <div style={{ padding: "20px 0 10px" }}>{i18n.t("cards.mapConditionsMessages.layerMessage", { name: mapLayer.name })}</div>
                                <ul style={{ paddingLeft: 50 }}>
                                    {mapLayer.layerErrors.map((error, layerErrorIndex) => {
                                        return (
                                            <li key={layerErrorIndex}>
                                                <span>{error}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </div>
            </div>
        )
    }[code] || (() => null);
}

export default {
    checkFieldInCalculatedFields,
    checkLocalQueryFieldElement,
    checkFieldInActions,
    deleteActionsWithDeletedFields,
    deleteConditionalStylesWithDeletedFields,
    cardPropertiesMatrix,
    checkCanSubmit,
    getCanSubmitErrorMessage
};