import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import i18n from "@biuwer/core/src/i18n";
import { defaultContext } from "@biuwer/redux/src/config/constants";

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { STEPS_LIST_PAGE } from './steps-gql';

/*
 * Steps action types
 */

export const STEP_INITIALIZE = 'STEP_INITIALIZE';
export const STEP_DETAIL_INITIALIZE = 'STEP_DETAIL_INITIALIZE';
export const STEPS_LIST_INITIALIZE = 'STEPS_LIST_INITIALIZE';

export const STEPS_FETCH_REQUEST = 'STEPS_FETCH_REQUEST';
export const STEPS_FETCH_SUCCESS = 'STEPS_FETCH_SUCCESS';
export const STEPS_FETCH_ERROR = 'STEPS_FETCH_ERROR';

export const STEP_FETCH_REQUEST = 'STEP_FETCH_REQUEST';
export const STEP_FETCH_SUCCESS = 'STEP_FETCH_SUCCESS';
export const STEP_FETCH_ERROR = 'STEP_FETCH_ERROR';

export const STEP_CREATE_REQUEST = 'STEP_CREATE_REQUEST';
export const STEP_CREATE_SUCCESS = 'STEP_CREATE_SUCCESS';
export const STEP_CREATE_ERROR = 'STEP_CREATE_ERROR';

export const STEP_UPDATE_REQUEST = 'STEP_UPDATE_REQUEST';
export const STEP_UPDATE_SUCCESS = 'STEP_UPDATE_SUCCESS';
export const STEP_UPDATE_ERROR = 'STEP_UPDATE_ERROR';

export const STEP_DELETE_REQUEST = 'STEP_DELETE_REQUEST';
export const STEP_DELETE_SUCCESS = 'STEP_DELETE_SUCCESS';
export const STEP_DELETE_ERROR = 'STEP_DELETE_ERROR';


// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, showNotification = true, extraArgs) => {
    let skeleton = { type, context }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)){
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.issuePayload ? body.issuePayload.code : body.payload, // Backward compatibility with old error handling
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body.payload
            };

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:

            break;
    }

    switch(type){
        case STEP_UPDATE_SUCCESS:
            skeleton.payload = body;
            skeleton.updated = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('dataPrep.steps.stepLabel'), context: 'male', count: 1 })
            };
            break;
        case STEP_CREATE_SUCCESS:
            skeleton.payload = body;
            skeleton.created = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', { name: i18n.t('dataPrep.steps.stepLabel'), context: 'male', count: 1 })
            };
            break;
        case STEP_DELETE_SUCCESS:
            skeleton.payload = body;
            skeleton.deleted = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', { name: i18n.t('dataPrep.steps.stepLabel'), context: 'male', count: 1 })
            };
            break;
        case STEP_FETCH_SUCCESS:
        case STEPS_FETCH_SUCCESS:
            skeleton.payload = body;
            break;
        case STEP_CREATE_ERROR:
        case STEP_UPDATE_ERROR:
        case STEP_DELETE_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    }
};

/**
 * Get Steps action
 */

export function getSteps(query = {}, context = defaultContext, gql = STEPS_LIST_PAGE) {

    return async (dispatch) => {
        try {
            const gqlQuery = {
                query: `query($query: QueryStepsInput!) {
                    querySteps(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query: query
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(STEPS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(STEPS_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.querySteps) {
                    dispatch(generateSkeleton(STEPS_FETCH_SUCCESS, res.body.data.querySteps, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(STEPS_FETCH_ERROR, err, context));
        }
    };
}

/**
 * Get Step action
 */

export function getStep(stepId, context = defaultContext, gql = STEPS_LIST_PAGE) {

    return async (dispatch) => {

        try {

            const gqlQuery = {
                query: `query($_id: Float!) {
                    readStep(_id: $_id) {
                        ${gql}
                    }
                }`,
                variables: {
                    _id: stepId
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(STEP_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(STEP_FETCH_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.readStep) {
                    dispatch(generateSkeleton(STEP_FETCH_SUCCESS, res.body.data.readStep, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(STEP_FETCH_ERROR), err, context);
        }
    };
}

/**
 * Initialize Step
 */
export const initializeStep = (context = defaultContext) => {
    return {
        type: STEP_INITIALIZE,
        context: context
    };
};

/**
 * Initialize Steps List
 */
export const initializeStepsList = (context = defaultContext) => {
    return {
        type: STEPS_LIST_INITIALIZE,
        context: context
    };
};

/**
 * Initialize Step Detail
 */
export const initializeStepDetail = (context = defaultContext) => {
    return {
        type: STEP_DETAIL_INITIALIZE,
        context: context
    };
};

/**
 * Create step action
 * @param query
 * @param context
 * @param gql
 * @param organization
 */
export function createStep(query, context = defaultContext, gql = STEPS_LIST_PAGE, organization) {

    return async (dispatch) => {

        try {

            const gqlQuery = {
                query: `mutation($step: StepInput!, $job: Float!, $organization: Float) {
                    createStep(step: $step, job: $job, organization: $organization) {
                        ${gql}
                    }
                }`,
                variables: {
                    step: query.step,
                    job: query.jobId,
                    organization: organization
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(STEP_CREATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(STEP_CREATE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.createStep) {
                    dispatch(generateSkeleton(STEP_CREATE_SUCCESS, res.body.data.createStep, context, true));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(STEP_CREATE_ERROR, err, context, true));
        }
    };
}

/**
 * Update step action
 * @param step
 * @param context
 * @param gql
 * @param organization
 */
export function updateStep(step, context = defaultContext, gql = STEPS_LIST_PAGE, organization) {

    return async (dispatch) => {

        try {

            const gqlQuery = {
                query: `mutation($_id: Float!, $step: StepInput!, $organization: Float) {
                    updateStep(_id: $_id, step: $step, organization: $organization) {
                        ${gql}
                    }
                }`,
                variables: {
                    step: step,
                    _id: step._id,
                    organization: organization
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(STEP_UPDATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(STEP_UPDATE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.updateStep) {
                    dispatch(generateSkeleton(STEP_UPDATE_SUCCESS, res.body.data.updateStep, context, true));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(STEP_UPDATE_ERROR, err, context, true));
        }
    };
}


/**
 * Delete step action
 * @param query
 * @param context
 * @param gql
 * @param organization
 */
export function deleteStep(query, context = defaultContext, gql = STEPS_LIST_PAGE, organization) {

    return async (dispatch) => {
        try {

            const gqlQuery = {
                query: `mutation($_id: Float!, $job: Float!, $organization: Float) {
                    deleteStep(_id: $_id, job: $job, organization: $organization) {
                        ${gql}
                    }
                }`,
                variables: {
                    _id: query.stepId,
                    job: query.jobId,
                    organization: organization
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(STEP_DELETE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));


            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(STEP_DELETE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.deleteStep) {
                    dispatch(generateSkeleton(STEP_DELETE_SUCCESS, res.body.data.deleteStep, context));
                }
            }

        } catch (err) {
            dispatch(generateSkeleton(STEP_DELETE_ERROR, err, context));
        }
    };
}