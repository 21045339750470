import React from "react";
import { useDispatch } from 'react-redux';
import request from "@biuwer/common/src/libs/superagent";

import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import History from "@biuwer/core/src/history";
import i18n from "@biuwer/core/src/i18n";

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { defaultContext } from "@biuwer/redux/src/config/constants";

import { getContentRotue } from "./templates-lib";

/*
 * Templates action types
 */
export const TEMPLATE_CREATE_REQUEST = 'TEMPLATE_CREATE_REQUEST';
export const TEMPLATE_CREATE_SUCCESS = 'TEMPLATE_CREATE_SUCCESS';
export const TEMPLATE_CREATE_ERROR = 'TEMPLATE_CREATE_ERROR';

export const CREATE_CONTENT_FROM_TEMPLATE_REQUEST = 'CREATE_CONTENT_FROM_TEMPLATE_REQUEST';
export const CREATE_CONTENT_FROM_TEMPLATE_SUCCESS = 'CREATE_CONTENT_FROM_TEMPLATE_SUCCESS';
export const CREATE_CONTENT_FROM_TEMPLATE_ERROR = 'CREATE_CONTENT_FROM_TEMPLATE_ERROR';

const createContentNotification = (type, id) => {

    const history = History.getHistory();

    return (
        <div key={"notification"} style={{display: "flex", flexDirection: "column"}}>
            <div key={"content-created"} style={{
                display: "flex",
                alignItems: "center",
            }}>
                <span>{i18n.t('notifications.createSuccess', { name: i18n.t('templates.content'), context: 'male', count: 1 })}</span>
            </div>
            <div key={"spaceMessage"} style={{display: "flex", alignItems: "center"}}>
                <div key={"button"} className={"bp5-button-group bp5-minimal"}>
                    <button
                        className={"bp5-button"}
                        onClick={() => history.push(getContentRotue(type, id))}
                    >
                        <span
                            className={"bp5-button-text"}
                            style={{fontSize: "13px"}}>
                            {i18n.t('notifications.accessContent')}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, extraArgs, showNotification = true) => {
    let skeleton = { type, context }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)){
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.statusCode,
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body
            };

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    message: body.message
                }
            }
            break;
        default:
            break;
    }

    switch(type){
        case TEMPLATE_CREATE_SUCCESS:
            skeleton.created = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', { name: i18n.t('templates.templateLabel'), context: 'female', count: 1 })
            };
            break;
        case CREATE_CONTENT_FROM_TEMPLATE_SUCCESS:
            skeleton.created = true;
            notification = {
                styleType: 'success',
                message: createContentNotification(body.type, body._id),
                timeout: 0
            };
            break;
        case TEMPLATE_CREATE_ERROR:
        case CREATE_CONTENT_FROM_TEMPLATE_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {
        dispatch(skeleton);
        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    };
};

/**
 * Create template action
 */
export const createTemplateFromContent = (contentType, contentId, name, language, context = defaultContext, gql = "") => {
    return async dispatch => {

        try {
            const gqlQuery = {
                query: `mutation($contentType: String!, $contentId: Float!, $name: String!, $language: String!) {
                    createTemplateFromContent(contentType: $contentType, contentId: $contentId, name: $name, language: $language)
                }`,
                variables: {
                    contentType,
                    contentId: Number(contentId),
                    name,
                    language
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(TEMPLATE_CREATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(TEMPLATE_CREATE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.createTemplateFromContent) {
                    dispatch(generateSkeleton(TEMPLATE_CREATE_SUCCESS, res.body.data.createTemplateFromContent, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(TEMPLATE_CREATE_ERROR, err, context));
        }
    };
};

/**
 * Create content from template action
 */
 export const createContentFromTemplate = (templateId, context = defaultContext, gql = "") => {
    return async dispatch => {

        try {
            const gqlQuery = {
                query: `mutation($templateId: ID!) {
                    createContentFromTemplate(templateId: $templateId) {
                        _id
                        type
                    }
                }`,
                variables: {
                    templateId
                }
            };

            let token = Auth.getLocalJwt();
            dispatch(generateSkeleton(CREATE_CONTENT_FROM_TEMPLATE_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(CREATE_CONTENT_FROM_TEMPLATE_ERROR, res.body.errors[0], context));
                } else if (res.body && res.body.data && res.body.data.createContentFromTemplate) {
                    dispatch(generateSkeleton(CREATE_CONTENT_FROM_TEMPLATE_SUCCESS, res.body.data.createContentFromTemplate, context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(CREATE_CONTENT_FROM_TEMPLATE_ERROR, err, context));
        }
    };
};

export const useTemplatesActions = () => {

    const dispatch = useDispatch();

    return {
        createTemplateFromContent: async (contentType, contentId, name, language, context, gql) => dispatch(createTemplateFromContent(contentType, contentId, name, language, context, gql)),
        createContentFromTemplate: async (templateId, context, gql) => dispatch(createContentFromTemplate(templateId, context, gql)),
    };

};