import { isNaN } from 'lodash';

function operationFunctions({
    operation = {},
    fn = () => {},
    timeWindowFn = () => {},
} = {}) {
    let index = 0;
    switch(operation.type) {
        // Arithmetic operations
        case 'add':
        case 'subtract':
        case 'product':
        case 'divide':
            fn(operation.extraArgs.operator1, 'operator1');
            fn(operation.extraArgs.operator2, 'operator2');
            break;
        // Time operations
        case 'prev_year':
        case 'prev_quarter':
        case 'prev_month':
        case 'prev_week':
        case 'prev_day':
        case 'prev_period':
            timeWindowFn(operation.extraArgs);
            break;
        // Logical operations
        case 'if':
            fn(operation.extraArgs.condition.expression1, 'expression1');
            fn(operation.extraArgs.condition.expression2, 'expression2');
            fn(operation.extraArgs.trueExpression, 'trueExpression');
            fn(operation.extraArgs.falseExpression, 'falseExpression');
            break;
        // Aggregation operations
        case 'sum':
        case 'avg':
        case 'count':
        case 'count_distinct':
        case 'max':
        case 'min':
        case 'running_sum':
        case 'running_avg':
        case 'running_min':
        case 'running_max':
        case 'running_count':
        case 'moving_sum':
        case 'moving_avg':
        case 'moving_min':
        case 'moving_max':
        case 'diff_from_first':
        case 'diff_from_prev':
        case 'diff_from_next':
        case 'diff_from_last':
        case 'pct_diff_from_first':
        case 'pct_diff_from_prev':
        case 'pct_diff_from_next':
        case 'pct_diff_from_last':
        case 'pct_from_first':
        case 'pct_from_prev':
        case 'pct_from_next':
        case 'pct_from_last':
            fn(operation.extraArgs);
            break;
        // Numeric operations
        case 'mod':
            fn(operation.extraArgs.numeratorExpression, 'numeratorExpression');
            fn(operation.extraArgs.denominatorExpression, 'denominatorExpression');
            break;
        case 'max_value':
        case 'min_value':
            for (let expression of operation.extraArgs.operationsArray) {
                fn(expression, index);
                index++;
            }
            break;
        case 'power':
        case 'sign':
        case 'sqrt':
        case 'abs':
        case 'square':
        case 'ceiling':
        case 'floor':
        case 'round':
        case 'exp':
        case 'ln':
        case 'log':
            fn(operation.extraArgs.valueExpression, 'valueExpression');
            break;
        // String operations
        case 'concat':
            for (let expression of operation.extraArgs.stringsArray) {
                fn(expression, index);
                index++;
            }
            break;
        // Date operations
        case 'date':
        case 'dateparse':
        case 'dateadd':
        case 'datename':
        case 'datepart':
        case 'datetrunc':
        case 'year':
        case 'quarter':
        case 'month':
        case 'week':
        case 'day':
        case 'isoyear':
        case 'isoweek':
        case 'isoweekday':
        case 'isdate':
            fn(operation.extraArgs.dateExpression, 'dateExpression');
            break;            
        case 'makedate':
            fn(operation.extraArgs.yearExpression, 'yearExpression');
            fn(operation.extraArgs.monthExpression, 'monthExpression');
            fn(operation.extraArgs.dayExpression, 'dayExpression');
            break;            
        case 'datediff':
            fn(operation.extraArgs.dateExpression1, 'dateExpression1');
            fn(operation.extraArgs.dateExpression2, 'dateExpression2');
            break;
        // General operations
        case 'total_sum':
        case 'pct_over_total':
        case 'total_avg':
        case 'total_min':
        case 'total_max':
        case 'total_count':
        case 'total_count_distinct':
            fn(operation.extraArgs);
            break;
        case 'ifnull':
            fn(operation.extraArgs.expression1, 'expression1');
            fn(operation.extraArgs.expression2, 'expression2');
            break;
        default:
            break;
    }
    return operation;
}

function isNumber(value) {
    return !Array.isArray(value) && !isNaN(parseFloat(value))
}

export default {
    operationFunctions,
    isNumber
};
