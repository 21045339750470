import { Map, fromJS } from 'immutable';

import {
    FILTERS_UPSERT_REQUEST, FILTERS_UPSERT_SUCCESS, FILTERS_UPSERT_ERROR,
    FILTERS_INITIALIZE, FILTERS_DETAIL_INITIALIZE, FILTERS_LIST_INITIALIZE,
    FILTER_FETCH_REQUEST, FILTER_FETCH_SUCCESS, FILTER_FETCH_ERROR,
    FILTERS_FETCH_REQUEST, FILTERS_FETCH_SUCCESS, FILTERS_FETCH_ERROR,
    FILTERS_CLEAN_REQUEST, FILTERS_CLEAN_SUCCESS, FILTERS_CLEAN_ERROR
} from './filters-actions';

// Libs
import { defaultContext } from "@biuwer/redux/src/config/constants";

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

const filters = (state = initialState, action) => {
    let newState;

    // Get list and detail context
    const list = `list_${(action && action.context) || defaultContext}`;
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch(action.type) {
        case FILTERS_INITIALIZE:
            return initialState;
        case FILTERS_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));

            return newState;
        case FILTERS_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));

            return newState;

        case FILTER_FETCH_REQUEST:

            newState = state;

            // Filter fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case FILTER_FETCH_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case FILTER_FETCH_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;


        case FILTERS_FETCH_REQUEST:

            newState = state;

            // Filters fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case FILTERS_FETCH_SUCCESS:

            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case FILTERS_FETCH_ERROR:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));

            return newState;

        case FILTERS_UPSERT_REQUEST:
        case FILTERS_CLEAN_REQUEST:
            newState = state
                .removeIn([list, 'updated'])
                .removeIn([detail, 'updated'])
                .removeIn([list, 'isFetching'])
                .removeIn([detail, 'isFetching'])
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case FILTERS_UPSERT_SUCCESS:
        case FILTERS_CLEAN_SUCCESS:

            let filterPayload = state.getIn([list, 'payload']);
            if (!!filterPayload && state.getIn([list, 'payload'])) {
                if (Array.isArray(action.payload)) {

                    if (action.source && action.source === 'dataCardEditor') {
                        filterPayload = fromJS(action.payload);
                    } else {
                        action.payload.forEach(actionPayload => {

                            let filterIndex = filterPayload.findIndex(item => item.get('_id') === actionPayload.filter_id);
                            // Update filter in filters array
                            if (filterIndex !== -1) {
                                if (actionPayload.expression.all_values) {
                                    actionPayload.expression.values = [];
                                }
                                filterPayload = filterPayload.setIn([filterIndex, 'expression'], fromJS(actionPayload.expression));
                            }
                        });
                    }
                }
            }

            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    updated: action.updated,
                    error: action.error,
                    payload: filterPayload
                }))
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    updated: action.updated,
                    error: action.error
                }));
            return newState;

        case FILTERS_UPSERT_ERROR:
        case FILTERS_CLEAN_ERROR:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    updated: action.updated,
                    error: action.error,
                    errorMessage: action.errorMessage
                }))
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    updated: action.updated,
                    error: action.error,
                    errorMessage: action.errorMessage
                }));
            return newState;
        default:
            return state;
    }
};

export default filters;