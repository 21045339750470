export const PRODUCTS_DETAIL = `
    _id
    name
    desc
    i18n {
        language
        name
        desc
        best_for
    }
    status
    created
    user_created
    updated
    user_updated
    product_type
    features {
        feature
        has_quantity
        quantity
    }
    most_popular
    is_private
    show_to_organizations
    has_trial
    trial_duration
    related_products {
        product_id
        quantity
    }
`;