import React from 'react';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

// Libs
import Auth from "@biuwer/redux/src/system/auth/auth-lib";

export default function PasswordStrength({ password = '', validPassword = false }) {

    const { t } = useTranslation();

    const checkPasswordStrength = (password) => {
        let result = { length: true, minus: true, mayus: true, number: true, special: true };
        if (validPassword) {
            return result;
        }
        const checks = Auth.passwordRegExp.specific;
        Object.keys(checks).forEach(key => {
            result[key] = !!checks[key].exec(password);
        });
        return result;
    };

    const passwordChecks = checkPasswordStrength(password);
    const checksTexts = {
        minus: t('auth.passwordStrength.minusLabel'),
        mayus: t('auth.passwordStrength.mayusLabel'),
        number: t('auth.passwordStrength.numberLabel'),
        special: t('auth.passwordStrength.specialLabel'),
        length: t('auth.passwordStrength.lengthLabel')
    };

    return (
        <div>
            <p style={{ fontSize: 13, marginBottom: 5 }}>{`${t('auth.passwordStrength.passwordRequirementsMessage')}:`}</p>
            <ul style={{ width: 200 }}>
                {Object.keys(passwordChecks).map(key => {
                    return (
                        <li key={key}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <span>{`${checksTexts[key]} `}</span>
                                <Icon
                                    icon={passwordChecks[key] ? 'small-tick' : 'cross'}
                                    color={passwordChecks[key] ? 'green' : 'red'}
                                />
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    )

};
