import { Map, fromJS } from 'immutable';

import {
    COLLECTIONS_FETCH_REQUEST, COLLECTIONS_FETCH_SUCCESS, COLLECTIONS_FETCH_ERROR,
    COLLECTION_INITIALIZE, COLLECTIONS_LIST_INITIALIZE, COLLECTION_DETAIL_INITIALIZE,
    COLLECTION_FETCH_REQUEST, COLLECTION_FETCH_SUCCESS, COLLECTION_FETCH_ERROR,
    COLLECTION_CREATE_REQUEST, COLLECTION_CREATE_SUCCESS, COLLECTION_CREATE_ERROR,
    COLLECTION_UPDATE_REQUEST, COLLECTION_UPDATE_SUCCESS, COLLECTION_UPDATE_ERROR,
    COLLECTION_DELETE_REQUEST, COLLECTION_DELETE_SUCCESS, COLLECTION_DELETE_ERROR
} from './collections-actions.js';

import {
    UPDATE_PROFILE_SUCCESS, CHANGE_OWNER_SUCCESS
} from "@biuwer/redux/src/system/security/security-actions";

import {
    RESOURCE_TOKEN_CREATE_SUCCESS, RESOURCE_TOKEN_DELETE_SUCCESS
} from "@biuwer/redux/src/share/resource-tokens/resource-tokens-actions";

import { defaultContext } from "@biuwer/redux/src/config/constants";

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

/**
 * Collections Reducers
 */

export default (state = initialState, action) =>{

    let newState, index, listUpdatedPayload, detailUpdatedPayload;

    // Get list and detail context
    const list = `list_${(action && action.context) || defaultContext}`;
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch (action.type) {

        // Necessary to initialize a specific collection state
        case COLLECTION_INITIALIZE:
            // Initialize all matching contexts
            if (action.baseContext) {
                newState = state;
                state.mapKeys(key => {
                    if (key.indexOf(action.baseContext) !== -1) newState = newState.setIn([key], Map({}));
                });
                return newState;
            }
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));

            return newState;
        case COLLECTION_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;
        case COLLECTIONS_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;
        case COLLECTIONS_FETCH_REQUEST:

            newState = state;

            // Collections fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    loaded: false
                }));

            return newState;
        case COLLECTIONS_FETCH_SUCCESS:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    payload: fromJS(action.payload),
                    loaded: true
                }));

            return newState;
        case COLLECTIONS_FETCH_ERROR:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload),
                    error: action.error,
                    errorMessage: action.errorMessage,
                    loaded: true
                }));

            return newState;
        case COLLECTION_FETCH_REQUEST:

            newState = state;

            // Collection fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case COLLECTION_FETCH_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case COLLECTION_FETCH_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case COLLECTION_CREATE_REQUEST:

            newState = state;

            // Delete create key if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case COLLECTION_CREATE_SUCCESS:

            // Update payload with new created collection
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case COLLECTION_CREATE_ERROR:

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case COLLECTION_UPDATE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case COLLECTION_UPDATE_SUCCESS:

            // Update payload with updated user
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the agent index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            error: action.error,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case COLLECTION_UPDATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case COLLECTION_DELETE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));

            return newState;
        case COLLECTION_DELETE_SUCCESS:

            // Update payload with deleted collection
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    isFetching: action.isFetching,
                    error: action.error,
                    deleted: action.deleted
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the agent index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                            isFetching: action.isFetching,
                            error: action.error,
                            deleted: action.deleted
                        });
                }
            }

            return newState;
        case COLLECTION_DELETE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;

        case UPDATE_PROFILE_SUCCESS:
        case CHANGE_OWNER_SUCCESS:

            newState = state;

            // Check objectType before to check if proceed to update reducer data
            if (action.objectType === 'collection' && action.payload && action.payload.length > 0) {
                action.payload.forEach(object => {

                    if (String(state.getIn([detail, 'payload', '_id'])) === String(object._id)) {

                        // If payload is an Immutable Map and has the same _id that the action given object info
                        // Check permissions (platform and object profile_level) before update
                        if (object.readOneAuthorization || (!!object.profile_level && object.profile_level <= 4)) {

                            //Enough permissions to read, object updated
                            newState = newState.setIn([detail, 'payload', 'profiles'], fromJS(object.profiles));
                            newState = newState.setIn([detail, 'payload', 'profile_level'], object.profile_level);
                            newState = newState.setIn([detail, 'payload', 'owner'], fromJS(object.owner));
                        } else {

                            // Not enough permissions, delete payload from reducer
                            newState = state.setIn([detail, 'payload'], null);
                        }
                    }

                    if (state.getIn([list, 'payload'])) {
                        let index = state.getIn([list, 'payload']).findIndex(connection => String(connection.get('_id')) === String(object._id));
                        if (object.changed && index !== -1) {

                            // Check permissions of the object profiles updated and platform permissions
                            if (object.readAuthorization || (!!object.profile_level && object.profile_level <= 4)) {

                                // Enough permissions to read, profiles and profile_level is updated
                                newState = newState.setIn([list, 'payload', index, 'profiles'], fromJS(object.profiles));
                                newState = newState.setIn([list, 'payload', index, 'profile_level'], object.profile_level);
                                newState = newState.setIn([list, 'payload', index, 'owner'], fromJS(object.owner));
                            } else {

                                // Not enough permissions after profiles update, exclude object from List
                                newState = state.setIn([list, 'payload'], newState.getIn([list, 'payload']).filter(connection => String(connection.get('_id')) !== String(object._id)));
                            }
                        }
                    }
                });
            }

            return newState;

        case RESOURCE_TOKEN_CREATE_SUCCESS:
            newState = state;
            if (action.resourceType === 'collection') {
                detailUpdatedPayload = state.getIn([detail, 'payload']);
                if (detailUpdatedPayload && detailUpdatedPayload.get('_id') === action.resourceId && action.created) {
                    detailUpdatedPayload = detailUpdatedPayload.setIn(['resource_token'], action.payload._id);
                    newState = newState.setIn([detail, 'payload'], detailUpdatedPayload);
                }

                listUpdatedPayload = state.getIn([list, 'payload']);
                if (listUpdatedPayload && listUpdatedPayload.size > 0) {
                    index = listUpdatedPayload.findIndex(page => page.get('_id') === action.resourceId);
                    if (action.created && index !== -1) {
                        listUpdatedPayload = listUpdatedPayload.setIn([index, 'resource_token'], action.payload._id);
                        newState = newState.setIn([list, 'payload'], listUpdatedPayload);
                    }
                }
            }
            return newState;
        case RESOURCE_TOKEN_DELETE_SUCCESS:
            newState = state;
            if (action.resourceType === 'collection') {
                detailUpdatedPayload = newState.getIn([detail, 'payload']);
                if (detailUpdatedPayload && detailUpdatedPayload.get('_id') === action.resourceId && action.deleted) {
                    detailUpdatedPayload = detailUpdatedPayload.setIn(['resource_token'], null);
                    newState = newState.setIn([detail, 'payload'], detailUpdatedPayload);
                }

                listUpdatedPayload = newState.getIn([list, 'payload']);
                if (listUpdatedPayload && listUpdatedPayload.size > 0) {
                    index = listUpdatedPayload.findIndex(page => page.get('_id') === action.resourceId);
                    if (action.deleted && index !== -1) {
                        listUpdatedPayload = listUpdatedPayload.setIn([index, 'resource_token'], null);
                        newState = newState.setIn([list, 'payload'], listUpdatedPayload);
                    }
                }
            }
            return newState;
        default:
            return state;
    }
}
