import { Map, List, fromJS } from 'immutable';

/**
 * Actions affecting a page: Page Metadata, Page Filters, Page Data
 */

import {
    PAGE_STRUCTURES_INITIALIZE,
    PAGE_STRUCTURES_FETCH_REQUEST, PAGE_STRUCTURES_FETCH_SUCCESS, PAGE_STRUCTURES_FETCH_ERROR,
    PAGE_STRUCTURE_FETCH_REQUEST, PAGE_STRUCTURE_FETCH_SUCCESS, PAGE_STRUCTURE_FETCH_ERROR,
    PAGE_STRUCTURE_CREATE_REQUEST, PAGE_STRUCTURE_CREATE_SUCCESS, PAGE_STRUCTURE_CREATE_ERROR,
    PAGE_STRUCTURE_UPDATE_REQUEST, PAGE_STRUCTURE_UPDATE_SUCCESS, PAGE_STRUCTURE_UPDATE_ERROR
} from './page-structures-actions.js';

const initialState = Map({});

export default function pagesStructures(state = initialState, action) {
    let newState, updatedPayload, index;

    switch (action.type) {
        case PAGE_STRUCTURES_INITIALIZE:
            newState = state
                .remove('deleted')
                .remove('payload')
                .remove('created')
                .remove('updated')
                .remove('issuePayload')
                .merge({
                    isFetching: action.isFetching,
                    issue: action.issue
                });

            return newState;
        case PAGE_STRUCTURE_CREATE_REQUEST:
            newState = state
                .remove('deleted')
                .remove('created')
                .remove('updated')
                .remove('issuePayload')
                .merge({
                    isFetching: action.isFetching,
                    issue: action.issue
                });

            return newState;
        case PAGE_STRUCTURE_CREATE_SUCCESS:

            // Check if payload is a List (Array)
            if(List.isList(state.get('payload'))) {
                updatedPayload = state.get('payload').push(fromJS(action.payload));

                // If payload is a Map the payload to be updated is action.payload itself
            } else if(Map.isMap(state.get('payload'))) {
                updatedPayload = fromJS(action.payload);
            }

            newState = state.merge({
                isFetching: action.isFetching,
                issue: action.issue,
                created: action.created,
                payload: updatedPayload
            });

            if(action.issue && action.issuePayload.status === 2){
                newState = newState.merge({
                    issuePayload: action.issuePayload
                });
            }

            return newState;
        case PAGE_STRUCTURE_CREATE_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                issue: action.issue,
                issuePayload: action.issuePayload
            });

            return newState;
        case PAGE_STRUCTURE_UPDATE_REQUEST:
            newState = state
                .remove('deleted')
                .remove('created')
                .remove('updated')
                .remove('issuePayload')
                .merge({
                    isFetching: action.isFetching,
                    issue: action.issue
                });

            return newState;
        case PAGE_STRUCTURE_UPDATE_SUCCESS:

            // Check if payload is a List (Array)
            if (List.isList(state.get('payload'))) {

                // Find the index of the user to be updated inside the List
                index = state.get('payload').findIndex(item => item.get('_id') === action.payload._id);

                // Update the payload list
                if (index !== -1) {
                    updatedPayload = state.get('payload').update(index, item => fromJS(action.payload));
                }

                // If payload is a Map the payload to be updated is action.payload itself
            } else if (Map.isMap(state.get('payload'))) {
                updatedPayload = fromJS(action.payload);
            }

            newState = state
                .merge({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    updated: action.updated,
                    payload: updatedPayload
                });

            if(action.issue && action.issuePayload.status === 2){
                newState = newState.merge({
                    issuePayload: action.issuePayload
                });
            }

            return newState;
        case PAGE_STRUCTURE_UPDATE_ERROR:
            newState = state
                .merge({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                });

            return newState;
        case PAGE_STRUCTURES_FETCH_REQUEST:
            newState = state
                .remove('deleted')
                .remove('payload')
                .remove('created')
                .remove('updated')
                .remove('issuePayload')
                .merge({
                    isFetching: action.isFetching,
                    issue: action.issue
                });

            return newState;
        case PAGE_STRUCTURES_FETCH_SUCCESS:
            newState = state.merge({
                isFetching: action.isFetching,
                issue: action.issue,
                payload: fromJS(action.payload)
            });

            if(action.issue && action.issuePayload.status === 2){
                newState = newState.merge({
                    issuePayload: action.issuePayload
                });
            }

            return newState;
        case PAGE_STRUCTURES_FETCH_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                issue: action.issue,
                issuePayload: action.issuePayload
            });

            return newState;
        case PAGE_STRUCTURE_FETCH_REQUEST:
            newState = state
                .remove('deleted')
                .remove('created')
                .remove('updated')
                .remove('issuePayload')
                .merge({
                    isFetching: action.isFetching,
                    issue: action.issue
                });
            return newState;

        //TODO: modify when page metadata comes from API call
        case PAGE_STRUCTURE_FETCH_SUCCESS:
            newState = newState.merge({
                isFetching: action.isFetching,
                issue: action.issue,
                payload: fromJS(action.payload)
            });

            if(action.issue && action.issuePayload.status === 2){
                newState = newState.merge({
                    issuePayload: action.issuePayload
                });
            }

            return newState;
        case PAGE_STRUCTURE_FETCH_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                issue: action.issue,
                issuePayload: action.issuePayload
            });

            return newState;
        default:

            return state;
    }
}
