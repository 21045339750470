import { Map, fromJS } from 'immutable';

import {
    RESOURCE_INITIALIZE,
    RESOURCE_FETCH_REQUEST, RESOURCE_FETCH_SUCCESS, RESOURCE_FETCH_ERROR
} from './resource-actions.js';

const initialState = Map({});

/**
 * Resource Reducers
 */

export default function resource(state = initialState, action) {
    let newState;

    switch (action.type) {
        case RESOURCE_INITIALIZE:

            return Map({});

        case RESOURCE_FETCH_REQUEST:
            newState = state
                .remove('payload')
                .remove('issuePayload')
                .merge({
                    isFetching: action.isFetching,
                    issue: action.issue
                });

            return newState;
        case RESOURCE_FETCH_SUCCESS:
            newState = state.merge({
                isFetching: action.isFetching,
                issue: action.issue,
                payload: fromJS(action.payload)
            });

            if(action.issue && action.issuePayload.status === 2){
                newState = newState.merge({
                    issuePayload: action.issuePayload
                });
            }

            return newState;
        case RESOURCE_FETCH_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                issue: action.issue,
                issuePayload: action.issuePayload,
                payload: { resourceType: 'forbidden' }
            });

            return newState;
        default:
            return state;
    }
}
