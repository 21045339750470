import { useDispatch } from 'react-redux';

export const SET_DND_CONTEXT = 'SET_DND_CONTEXT';
export const SET_IS_DRAG_ACTIVE = 'SET_IS_DRAG_ACTIVE';
export const SET_ORIGIN_CONTAINER_ID = 'SET_ORIGIN_CONTAINER_ID';
export const SET_CONTAINER = 'SET_CONTAINER';

/**
 * Action to set new dnd context
 * @param dndContext (object) - Dnd Context immutable object
 */
export const setDndContext = (dndContext) => {
    return dispatch => {
        dispatch({
            type: SET_DND_CONTEXT,
            dndContext
        });
    };
};

const setIsDragActive = newIsDragActive => dispatch => dispatch({ type: SET_IS_DRAG_ACTIVE, newIsDragActive });
const setOriginContainerId = newOriginContainerId => dispatch => dispatch({ type: SET_ORIGIN_CONTAINER_ID, newOriginContainerId });
const setContainer = (containerId, newContainer) => dispatch => dispatch({ type: SET_CONTAINER, containerId, newContainer });

export const useDndContextActions = () => {

    const dispatch = useDispatch();

    return {
        setDndContext: (newDndContext) => dispatch(setDndContext(newDndContext)),
        setIsDragActive: newIsDragActive => dispatch(setIsDragActive(newIsDragActive)),
        setOriginContainerId: newOriginContainerId => dispatch(setOriginContainerId(newOriginContainerId)),
        setContainer: (containerId, newContainer) => dispatch(setContainer(containerId, newContainer)),
    };

};