import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from '@blueprintjs/core';

// Libs
import { tooltipContentSizes } from "@biuwer/common/src/libs/tooltip-lib";

/**
 * Custom Tooltip component. Can render a given children or a Blueprint Icon with given props
 * @returns {*}
 * @constructor
 */
const CustomTooltip = (props) => {

    const { children, content, className, popoverClassName, modifiers } = useCustomTooltip(props);
    const { isOpen = null, position = "auto", usePortal = true } = props;

    // If no tooltip content provided just render children element
    if (!content && children) return children;

    return children && content && (
        <Tooltip
            boundary="viewport"
            className={className}
            targetClassName={className}
            popoverClassName={popoverClassName}
            isOpen={isOpen}
            position={position}
            content={content || ''}
            usePortal={usePortal}
            modifiers={modifiers}
        >
            {children}
        </Tooltip>
    );
};

CustomTooltip.propTypes = {
    className: PropTypes.string,
    position: PropTypes.oneOf(['auto', 'left', 'bottom', 'top', 'right', 'left-bottom', 'left-top', 'right-top', 'right-bottom', 'top-left', 'top-right', 'bottom-left', 'bottom-right']),
    // content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    contentSize: PropTypes.oneOfType([PropTypes.oneOf(['small', 'medium', 'large']), PropTypes.string, PropTypes.number]),
    contentStyle: PropTypes.object,
    theme: PropTypes.oneOf(['dark', 'light', 'warning']),
    /**
     * Disables width 100% from default style and set width to fit-content in children wrapper.
    * */
    fitChildrenContent: PropTypes.bool,
    usePortal: PropTypes.bool,
    intent: PropTypes.string,
    isOpen: PropTypes.bool,
    /**
     * Icon props. All comes directly to blueprint's icon component
     */
    iconType: PropTypes.string,
    iconSize: PropTypes.number,
    iconColor: PropTypes.string,
    iconStyle: PropTypes.object,
    iconTitle: PropTypes.string,
};

/**
 * Custom Tooltip hook to generate children and content to render in Custom Tooltip component
 * @param {Object} params
 * @param {String} params.className ClassName to apply on tooltip target wrapper
 * @param {String} params.popoverClassName ClassName to apply on tooltip target wrapper
 * @param {String} params.theme theme for the tooltip
 * @param {*} params.content tooltip content. Can be string or JSX
 * @param {String} params.contentClassName Root className to apply on content
 * @param {String|Number} params.contentSize Defines content max width
 * @param {Object} params.contentStyle Root style to apply on content
 * @param {*} params.children Children element. Must be JSX
 * @param {Boolean} params.fitChildrenContent If true disable width 100% in tooltip target wrapper
 * @param {String|null} params.iconType Icon type from blueprint icons catalog
 * @param {Number} params.iconSize Icon font size, default is 15
 * @param {String} params.iconColor Default is 'black'
 * @param {Object} params.iconStyle CSS style to apply on icon
 * @returns {{children: *, content: *, className: string, popoverClassName: string, modifiers: *}}
 */
const useCustomTooltip = ({ className, popoverClassName, theme = "dark", content, contentClassName, contentSize = "medium", contentStyle, children, fitChildrenContent = false, iconType = null, iconSize = 16, iconColor, iconStyle, iconTitle }) => {

    // Generate children based on given children and iconType props
    let childrenRender = null;
    if (children) {
        childrenRender = children;
    } else if (iconType) {
        childrenRender = (
            <Icon icon={iconType} size={iconSize} color={iconColor} style={iconStyle} title={iconTitle} />
        );
    }

    const wrapContent = {
        whiteSpace: 'normal',
        wordBreak: 'break-word'
    };

    // Generate min and max width style param based on content size prop
    let minWidth = tooltipContentSizes.medium.minWidth || 300,
        maxWidth = tooltipContentSizes.medium.maxWidth || 500;
    if (typeof contentSize === 'string' && !!tooltipContentSizes[contentSize]) {
        minWidth = tooltipContentSizes[contentSize].minWidth;
        maxWidth = tooltipContentSizes[contentSize].maxWidth;
    }
    if (typeof contentSize === 'number') {
        minWidth = contentSize;
        maxWidth = contentSize;
    }

    // wrap the given content into a div with content style and className
    const contentRender = (content && (
        <div className={contentClassName} style={{ minWidth, maxWidth, ...wrapContent, ...contentStyle, maxHeight: 350 }}>
            {typeof content === 'string' ? (<p className="mb-0">{content.length > 600 ? content.substring(0, 600).concat(' ...') : content}</p>) : content}
        </div>
    )) || null;

    // Generate classNames string for tooltip target wrapper
    let classNameRender = 'CustomTooltip';
    if (fitChildrenContent) classNameRender+= ` fit-content`;
    if (className) classNameRender+= ` ${className}`;

    let popoverClassNameRender = 'CustomTooltip';
    if (theme) popoverClassNameRender += `-${theme}`;
    if (popoverClassName) popoverClassNameRender += ` ${popoverClassName}`

    // Generate modifiers object for popper.js
    let modifiers = {};

    return {
        children: childrenRender,
        content: contentRender,
        className: classNameRender,
        popoverClassName: popoverClassNameRender,
        modifiers: modifiers
    };
};

export default CustomTooltip;