import gqlRequest from '@biuwer/core/src/graphql-request';
import i18n from '@biuwer/core/src/i18n';
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import { defaultContext } from "@biuwer/redux/src/config/constants";

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { CUSTOM_MESSAGE_DETAIL } from './custom-messages-gql';

export const CUSTOM_MESSAGE_DETAIL_INITIALIZE = "CUSTOM_MESSAGE_DETAIL_INITIALIZE";

export const CUSTOM_MESSAGE_FETCH_REQUEST = "CUSTOM_MESSAGE_FETCH_REQUEST";
export const CUSTOM_MESSAGE_FETCH_SUCCESS = "CUSTOM_MESSAGE_FETCH_SUCCESS";
export const CUSTOM_MESSAGE_FETCH_ERROR = "CUSTOM_MESSAGE_FETCH_ERROR";

export const CUSTOM_MESSAGE_CREATE_REQUEST = "CUSTOM_MESSAGE_CREATE_REQUEST";
export const CUSTOM_MESSAGE_CREATE_SUCCESS = "CUSTOM_MESSAGE_CREATE_SUCCESS";
export const CUSTOM_MESSAGE_CREATE_ERROR = "CUSTOM_MESSAGE_CREATE_ERROR";

export const CUSTOM_MESSAGE_UPDATE_REQUEST = "CUSTOM_MESSAGE_UPDATE_REQUEST";
export const CUSTOM_MESSAGE_UPDATE_SUCCESS = "CUSTOM_MESSAGE_UPDATE_SUCCESS";
export const CUSTOM_MESSAGE_UPDATE_ERROR = "CUSTOM_MESSAGE_UPDATE_ERROR";

export const CUSTOM_MESSAGE_DEFAULT_REQUEST = "CUSTOM_MESSAGE_DEFAULT_REQUEST";
export const CUSTOM_MESSAGE_DEFAULT_SUCCESS = "CUSTOM_MESSAGE_DEFAULT_SUCCESS";
export const CUSTOM_MESSAGE_DEFAULT_ERROR = "CUSTOM_MESSAGE_DEFAULT_ERROR";

function generateSkeleton(type, body, context = defaultContext, showNotification = true) {
    let skeleton = { type, context }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case "REQUEST":
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case "ERROR":
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.statusCode,
                    message: body.message
                }
            };

            break;
        case "SUCCESS":
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body
            };

            if(body.status === 2) {
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }

            break;
        default:
            break;
    }

    switch(type) {
        case CUSTOM_MESSAGE_CREATE_SUCCESS:
            skeleton.created = true;
            notification = {
                styleType: "success",
                message: i18n.t("notifications.createSuccess", { name: i18n.t("admin.organization.customMessages.title"), context: "male", count: 1 })
            };
            break;
        case CUSTOM_MESSAGE_UPDATE_SUCCESS:
        case CUSTOM_MESSAGE_DEFAULT_SUCCESS:
            skeleton.updated = true;
            notification = {
                styleType: "success",
                message: i18n.t("notifications.updateSuccess", { name: i18n.t("admin.organization.customMessages.title"), context: "male", count: 1 })
            };
            break;
        case CUSTOM_MESSAGE_FETCH_ERROR:
            skeleton.issuePayload = {
                ...skeleton.issuePayload,
            };
            break;
        case CUSTOM_MESSAGE_CREATE_ERROR:
        case CUSTOM_MESSAGE_UPDATE_ERROR:
        case CUSTOM_MESSAGE_DEFAULT_ERROR:
            notification = {
                styleType: "error",
                message: i18n.t("notifications.error")
            };
            break;
        default:
            break;
    }

    return (dispatch) => {
        dispatch(skeleton);

        if (notification && showNotification) dispatch(addNotification(notification));
    }
}

/**
 * Initialize custom message
 */
export const initializeCustomMessageDetail = (context = defaultContext) => ({
    type: CUSTOM_MESSAGE_DETAIL_INITIALIZE,
    context
});

/**
 * Function to get organization custom message
 * @param {String} context 
 * @param {String} gql 
 * @returns {Promise<void>}
 */
export const getCustomMessage = (context = defaultContext, gql = CUSTOM_MESSAGE_DETAIL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(CUSTOM_MESSAGE_FETCH_REQUEST, null, context));

        const organizationId = Auth.getOrganizationId();

        try {
            const customMessage = await gqlRequest({
                queryName: "readCustomMessage",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "organization",
                    data: organizationId
                }]
            });

            dispatch(generateSkeleton(CUSTOM_MESSAGE_FETCH_SUCCESS, customMessage, context));
        } catch (err) {
            dispatch(generateSkeleton(CUSTOM_MESSAGE_FETCH_ERROR, err, context));
        }
    }
}

/**
 * Function to create organization custom message
 * @param {Object} customMessage 
 * @param {String} context 
 * @param {String} gql 
 * @returns 
 */
export const createCustomMessage = (customMessage, context = defaultContext, gql = CUSTOM_MESSAGE_DETAIL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(CUSTOM_MESSAGE_CREATE_REQUEST, null, context));

        try {
            const newCustomMessage = await gqlRequest({
                queryType: "mutation",
                queryName: "createCustomMessage",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "CustomMessageInput!",
                    name: "customMessage",
                    data: customMessage
                }]
            });

            dispatch(generateSkeleton(CUSTOM_MESSAGE_CREATE_SUCCESS, newCustomMessage, context));
        } catch (err) {
            dispatch(generateSkeleton(CUSTOM_MESSAGE_CREATE_ERROR, err, context));
        }
    }
}

/**
 * Function to update organization custom message
 * @param {Object} customMessage 
 * @param {String} context 
 * @param {String} gql 
 * @returns 
 */
export const updateCustomMessage = (customMessage, context = defaultContext, gql = CUSTOM_MESSAGE_DETAIL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(CUSTOM_MESSAGE_UPDATE_REQUEST, null, context));

        try {
            const updatedCustomMessage = await gqlRequest({
                queryType: "mutation",
                queryName: "updateCustomMessage",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "CustomMessageInput!",
                    name: "customMessage",
                    data: customMessage
                }]
            });

            dispatch(generateSkeleton(CUSTOM_MESSAGE_UPDATE_SUCCESS, updatedCustomMessage, context));
        } catch (err) {
            dispatch(generateSkeleton(CUSTOM_MESSAGE_UPDATE_ERROR, err, context));
        }
    }
}

export const setDefaultCustomMessage = (customMessage, context = defaultContext, gql = CUSTOM_MESSAGE_DETAIL) => {
    return async (dispatch) => {
        dispatch(generateSkeleton(CUSTOM_MESSAGE_DEFAULT_REQUEST, null, context));

        try {
            const updatedCustomMessage = await gqlRequest({
                queryType: "mutation",
                queryName: "updateCustomMessage",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "CustomMessageInput!",
                    name: "customMessage",
                    data: customMessage
                }]
            });

            dispatch(generateSkeleton(CUSTOM_MESSAGE_DEFAULT_SUCCESS, updatedCustomMessage, context));
        } catch (err) {
            dispatch(generateSkeleton(CUSTOM_MESSAGE_DEFAULT_ERROR, err, context));
        }
    }
}
