import { fromJS, List } from 'immutable';
import { isNil } from 'lodash';

import { ADD_NOTIFICATION_AREA, DELETE_NOTIFICATION_AREA, INITIALIZE_NOTIFICATION_AREA } from './notification-area-actions';

const initialState = List([]);

export default function notificationArea(state = initialState, action) {
    let newState, index;
    switch (action.type) {
        case ADD_NOTIFICATION_AREA:
            if (isNil(action.notification.id)) {
                action.notification.id = `${action.notification.position}${Math.random().toString(36).substr(2, 16)}`;
            }
            let newNotification = fromJS(action.notification);
            index = state.findIndex(notification => notification.get('id') === action.notification.id);
            if (index >= 0) {
                newState = state.setIn([index], newNotification);
            } else {
                newState = state.push(newNotification);
            }
            return newState;
        case DELETE_NOTIFICATION_AREA:

            // Delete the notification by filtering based on notificationId
            newState = state.filter(item => item.get('id') !== action.notificationId);
            return newState;
        case INITIALIZE_NOTIFICATION_AREA:
            return initialState;
        default:
            return state;
    }
}