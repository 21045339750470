import {createSelector} from 'reselect';
import {Map} from "immutable";

/**
 * Session Selectors
 * */

// Session redux state
const sessionStateSelector = state => state.system.auth.session;

// Current organization
const currentOrganizationSelector = createSelector(
    sessionStateSelector,
    session => session.get('currentOrganization') || Map({})
);

// Is logged in
const isLoggedInSelector = createSelector(
    sessionStateSelector,
    session => session.get('isLoggedIn') || Map({})
);

// Session user
const sessionUserSelector = createSelector(
    sessionStateSelector,
    session => session.get('user') || Map({})
);

// Masquerade user
const masqueradeUserSelector = createSelector(
    sessionStateSelector,
    session => session.get('masqueradeUser') || Map({})
);

// Masquerading
const masqueradingSelector = createSelector(
    masqueradeUserSelector,
    session => session.get('masquerading') || false
);

// Current language
const currentLanguageSelector = createSelector(
    sessionStateSelector,
    sessionReducer => {
        return sessionReducer.getIn(['user', 'settings', 'language']) && sessionReducer.getIn(['user', 'settings', 'language']) !== '*'
            ? sessionReducer.getIn(['user', 'settings', 'language'])
            : sessionReducer.getIn(['currentOrganization', 'settings', 'language'])
    }
);

// Current content language
const currentContentLanguageSelector = createSelector(
    sessionStateSelector,
    sessionReducer => {
        return sessionReducer.get('currentContentLanguage') || "";
    }
)

// Organization stats
const organizationStatsSelector = createSelector(
    sessionStateSelector,
    session => session.getIn(['organizationStats', 'stats']) || Map({})
);

// User favorites
const userFavoritesSelector = createSelector(
    sessionUserSelector,
    user => user.get("favorites") || Map({})
);

// User type
const userTypeSelector = createSelector(
    sessionUserSelector,
    user => user.get("type") || Map({})
);

export {
    currentOrganizationSelector,
    isLoggedInSelector,
    sessionUserSelector,
    masqueradingSelector,
    currentLanguageSelector,
    currentContentLanguageSelector,
    organizationStatsSelector,
    userFavoritesSelector,
    userTypeSelector
};