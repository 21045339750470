
// Default query for data connection types list actions
export const DATA_CONNECTIONS_TYPES_LIST_DETAIL = `
    _id
    name
    desc
    logo
    category
    required_plan
    provider
    type
    driver
    status
    has_reverse_engineering
    i18n {
        language
        name
        desc
    }
    details {
        provider
        type
        driver
        needs_container
        default_container
        authentication_schema
        fields
        oauth_token
        needs_mapping
        mapping_type
        needs_schedule
    }
    ssh_tunnel_available
    time_zone_available
    template
`;