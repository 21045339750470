import { Map, fromJS } from 'immutable';

import {
    SET_DND_CONTEXT,
    SET_IS_DRAG_ACTIVE,
    SET_ORIGIN_CONTAINER_ID,
    SET_CONTAINER,
} from './dnd-system-actions';

const initialState = fromJS({
    isDragActive: -1,
    originContainerId: "",
    containers: {}
});

/**
 * Dnd System Reducer
 */
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_DND_CONTEXT:
            return state.merge(action.dndContext);
        case SET_IS_DRAG_ACTIVE:
            return state.set("isDragActive", action.newIsDragActive);
        case SET_ORIGIN_CONTAINER_ID:
            return state.set("originContainerId", action.newOriginContainerId);
        case SET_CONTAINER:
            return state.setIn(["containers", action.containerId], action.newContainer);
        default: return state;
    }
}
