import { Map, fromJS } from 'immutable';
import { defaultContext } from "@biuwer/redux/src/config/constants";

import { COUNTRIES_INITIALIZE, COUNTRIES_LIST_INITIALIZE, COUNTRIES_DETAIL_INITIALIZE,
    COUNTRIES_FETCH_REQUEST, COUNTRIES_FETCH_SUCCESS, COUNTRIES_FETCH_ERROR,
    COUNTRY_FETCH_REQUEST, COUNTRY_FETCH_SUCCESS, COUNTRY_FETCH_ERROR } from './countries-actions';

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

export default function countriesReducer(state = initialState, action) {
    let newState;

    // Get list and detail context
    const list = `list_${(action && action.context) || defaultContext}`;
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch (action.type) {
        case COUNTRIES_INITIALIZE:
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));
            return newState;
        case COUNTRIES_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;
        case COUNTRIES_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;
        case COUNTRIES_FETCH_REQUEST:

            newState = state;

            // Countries fetch request only use "list" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case COUNTRIES_FETCH_SUCCESS:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case COUNTRIES_FETCH_ERROR:
            newState = state.mergeIn([list], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;
        case COUNTRY_FETCH_REQUEST:

            newState = state;

            // Country fetch request only use "detail" options
            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    error: action.error
                }));
            return newState;
        case COUNTRY_FETCH_SUCCESS:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            }));
            return newState;
        case COUNTRY_FETCH_ERROR:
            newState = state.mergeIn([detail], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;
        default:
            return state;
    }
};
