export const PRICE_LISTS_DETAIL = `
    _id
    name
    desc
    status
    created
    user_created
    updated
    product {
        _id
    }
    duration
    currency
    interval
    billing_scheme
    amount
    tiers {
        flat_amount
        unit_amount
        up_to
    }
    tiers_mode
`;