/*
 * Notification area action
 */
export const ADD_NOTIFICATION_AREA = 'ADD_NOTIFICATION_AREA';
export const DELETE_NOTIFICATION_AREA = 'DELETE_NOTIFICATION_AREA';
export const INITIALIZE_NOTIFICATION_AREA = 'INITIALIZE_NOTIFICATION_AREA';

const defaultNotificationValues = {
    position: 'top',
    priority: 1,
    closeIcon: true
};

export const initializeNotificationArea = () => {
    return { type: INITIALIZE_NOTIFICATION_AREA };
};

export const addNotificationArea = (notification) => {
    return {
        type: ADD_NOTIFICATION_AREA,
        notification: {
            ...defaultNotificationValues,
            ...notification
        }
    };
};

export const deleteNotificationArea = (notificationId) => {
    return {
        type: DELETE_NOTIFICATION_AREA,
        notificationId
    };
};