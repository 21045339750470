import Rollbar from 'rollbar';
import moment from 'moment';

// Libs
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import History from "@biuwer/core/src/history";

const { REACT_APP_EVENT_LOG_TOKEN, REACT_APP_EVENT_LOG_ENV, NODE_ENV } = process.env;

const rollbar = new Rollbar({
    accessToken: REACT_APP_EVENT_LOG_TOKEN,
    environment: REACT_APP_EVENT_LOG_ENV || NODE_ENV || 'development',
    captureUncaught: true,
    captureUnhandledRejections: true
});

/**
 * Function to log an message into Rollbar
 * @param {Object} eventObject
 */
function RollbarLogger(eventObject) {

    let concatEventObject = '';
    for (let [key, value] of Object.entries(eventObject)) {
        concatEventObject += `${key.toUpperCase()}: ${typeof value === 'object' ? JSON.stringify(value) : value}; `;
    }

    if (eventObject.type === 'error') {
        rollbar.log(concatEventObject);
    } else {
        rollbar.info(concatEventObject);
    }
}

/**
 * Function to log an event
 * @param {String} eventType error | event
 * @param {Object} module
 * @param {Object} scope
 * @param {String} eventName card_created | query_executed
 * @param {Object} eventDetail
 */
export function EventLogger(eventType, module, scope, eventName, eventDetail) {

    const user = Auth.getUser(),
        location = History.getLocation(),
        currentOrganizationId = Auth.getOrganizationId(),
        environment = NODE_ENV || 'development';

    const eventObject = {
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
        type: eventType,
        module: module,
        scope: scope,
        org: currentOrganizationId,
        user: user._id,
        env: environment,
        app: 'biuwer',
        eventName: eventName,
        eventDetail: {...eventDetail, path: location.pathname},
    };

    if (environment !== 'development') RollbarLogger(eventObject);
}