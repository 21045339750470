import { fromJS, Map } from 'immutable';

import {
    CONTENT_INITIALIZE,
    EXPORT_DATA_CARD_FETCH_REQUEST, EXPORT_DATA_CARD_FETCH_SUCCESS, EXPORT_DATA_CARD_FETCH_ERROR,
    EXPORT_VISUALIZATION_REQUEST, EXPORT_VISUALIZATION_SUCCESS, EXPORT_VISUALIZATION_ERROR,
    PDF_EXPORT_REQUEST, PDF_EXPORT_SUCCESS, PDF_EXPORT_ERROR,
    FILE_IMPORT_REQUEST, FILE_IMPORT_SUCCESS, FILE_IMPORT_ERROR,
    CAN_DELETE_REQUEST, CAN_DELETE_SUCCESS, CAN_DELETE_ERROR,
    CONTENT_UPLOAD, CONTENT_DOWNLOAD,
    GET_TRACEABILITY_REQUEST, GET_TRACEABILITY_SUCCESS, GET_TRACEABILITY_ERROR
} from './content-actions';

const initialState = Map({});

/**
 * Content Reducers
 */

export default (state = initialState, action) => {
    let newState;

    switch (action.type) {
        case CONTENT_INITIALIZE:
            return initialState;
        case EXPORT_DATA_CARD_FETCH_REQUEST:
            newState = initialState
                .merge({
                    isLoading: action.isLoading
                });
            return newState;
        case EXPORT_DATA_CARD_FETCH_SUCCESS:
            newState = state
                .merge({
                    isLoading: action.isLoading,
                    issue: action.issue
                });
            return newState;
        case EXPORT_DATA_CARD_FETCH_ERROR:
            newState = state
                .merge({
                    isLoading: action.isLoading,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                });
            return newState;
        case EXPORT_VISUALIZATION_REQUEST:
            return initialState
                .merge({
                    isLoading: action.isLoading,
                    issue: action.issue
                })
        case EXPORT_VISUALIZATION_SUCCESS:
            newState = state
                .merge({
                    isLoading: action.isLoading,
                    issue: action.issue
                })
            return newState
        case EXPORT_VISUALIZATION_ERROR:
            newState = state
                .merge({
                    isLoading: action.isLoading,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                })
            return newState
        case PDF_EXPORT_REQUEST:
            newState = initialState
                .merge({
                    isLoading: action.isLoading,
                    objectType: action.objectType
                });
            return newState;
        case PDF_EXPORT_SUCCESS:
            newState = state
                .merge({
                    isLoading: action.isLoading,
                    issue: action.issue
                });
            return newState;
        case PDF_EXPORT_ERROR:
            newState = state
                .merge({
                    isLoading: action.isLoading,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                });
            return newState;
        case FILE_IMPORT_REQUEST:
            newState = initialState
                .merge({
                    isLoading: action.isLoading
                });
            return newState;
        case FILE_IMPORT_SUCCESS:
            newState = state
                .merge({
                    isLoading: action.isLoading,
                    successLoading: true,
                    issue: action.issue
                });
            return newState;
        case FILE_IMPORT_ERROR:
            newState = state
                .merge({
                    isLoading: action.isLoading,
                    successLoading: false,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                });
            return newState;
        case CAN_DELETE_REQUEST:
            newState = initialState
                .merge({
                    isLoading: action.isLoading
                });
            return newState;
        case CAN_DELETE_SUCCESS:
            newState = state
                .merge({
                    isLoading: action.isLoading,
                    issue: action.issue,
                    payload: fromJS(action.payload)
                });
            return newState;
        case CAN_DELETE_ERROR:
            newState = state
                .merge({
                    isLoading: action.isLoading,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                });
            return newState;
        case CONTENT_UPLOAD:
            newState = state
                .remove('info')
                .merge({
                    info: action.info
                });
            return newState;
        case CONTENT_DOWNLOAD:
            newState = state
                .remove('info')
                .merge({
                    info: action.info
                });
            return newState;

        case GET_TRACEABILITY_REQUEST:
            newState = initialState
                .merge({
                    isLoading: action.isLoading
                });
            return newState;
        case GET_TRACEABILITY_SUCCESS:
            newState = state
                .merge({
                    isLoading: action.isLoading,
                    issue: action.issue,
                    payload: fromJS(action.payload)
                });
            return newState;
        case GET_TRACEABILITY_ERROR:
            newState = state
                .merge({
                    isLoading: action.isLoading,
                    issue: action.issue,
                    issuePayload: action.issuePayload
                });
            return newState;

        default:
            return state;
    }
}