import {combineReducers} from "redux";
import {cloneDeep} from "lodash";

/**
 * DatamodelQueries cards Reducer
 */
import {
    DATAMODEL_QUERIES_CARDS_FETCH_REQUEST,
    DATAMODEL_QUERIES_CARDS_FETCH_SUCCESS,
    DATAMODEL_QUERIES_CARDS_FETCH_ERROR,
    DATAMODEL_QUERIES_CARDS_SET_ACTIVE
} from './datamodel-queries-actions';

/**
 * DatamodelQueries filters Reducer
 */
import {
    DATAMODEL_QUERIES_FILTERS_FETCH_REQUEST,
    DATAMODEL_QUERIES_FILTERS_FETCH_SUCCESS,
    DATAMODEL_QUERIES_FILTERS_FETCH_ERROR,
    DATAMODEL_QUERIES_FILTERS_SET_ACTIVE
} from './datamodel-queries-actions';

import {getDatamodelQueryCardIndex, getDatamodelQueryFilterIndex, getDatamodelQueryQueryIndex} from "./datamodel-queries-lib";

const initialState =[];

function cards(state = initialState, action) {
    let newState;
    newState = cloneDeep(state);

    switch(action.type) {
        case DATAMODEL_QUERIES_CARDS_FETCH_REQUEST:
            return datamodelQueryFetchRequest(newState, action, getDatamodelQueryCardIndex);
        case DATAMODEL_QUERIES_CARDS_FETCH_SUCCESS:
            return datamodelQueryFetchSuccess(newState, action, getDatamodelQueryCardIndex);
        case DATAMODEL_QUERIES_CARDS_FETCH_ERROR:
            return datamodelQueryFetchError(newState, action, getDatamodelQueryCardIndex);
        case DATAMODEL_QUERIES_CARDS_SET_ACTIVE:
            return datamodelQuerySetActive(newState, action, getDatamodelQueryCardIndex);
        default:
            return state;
    }
}

function filters(state = initialState, action) {
    let newState;
    newState = cloneDeep(state);

    switch(action.type) {
        case DATAMODEL_QUERIES_FILTERS_FETCH_REQUEST:
            return datamodelQueryFetchRequest(newState, action, getDatamodelQueryFilterIndex);
        case DATAMODEL_QUERIES_FILTERS_FETCH_SUCCESS:
            return datamodelQueryFetchSuccess(newState, action, getDatamodelQueryFilterIndex);
        case DATAMODEL_QUERIES_FILTERS_FETCH_ERROR:
            return datamodelQueryFetchError(newState, action, getDatamodelQueryFilterIndex);
        case DATAMODEL_QUERIES_FILTERS_SET_ACTIVE:
            return datamodelQuerySetActive(newState, action, getDatamodelQueryFilterIndex);
        default:
            return state;
    }
}

const datamodelQueryFetchRequest = (newState, action, getDatamodelQueryIndex) => {
    let datamodelQueryIndex, datamodelQueryQueryIndex, newQueries;
    datamodelQueryIndex = getDatamodelQueryIndex(newState, action.datamodelQueryFilters);
    if (datamodelQueryIndex !== -1) {
        datamodelQueryQueryIndex = getDatamodelQueryQueryIndex(newState[datamodelQueryIndex].queries, action.datamodelQueryQueryFilters);
        newQueries = newState[datamodelQueryIndex].queries.map(query => ({ ...query, isActive: false }));
        if (datamodelQueryQueryIndex !== -1) {
            newQueries[datamodelQueryQueryIndex] = {
                ...newQueries[datamodelQueryQueryIndex],
                isActive: true
            }
        }
        else {
            newQueries.push({
                ...action.datamodelQueryQueryFilters,
                isActive: true,
                timestamp: Date.now()
            });
        }
        newState[datamodelQueryIndex] = {
            ...newState[datamodelQueryIndex],
            isFetching: true,
            queries: newQueries
        };
    }
    else {
        newState = [...newState, {
            ...action.datamodelQueryFilters,
            isFetching: true,
            queries: [{
                ...action.datamodelQueryQueryFilters,
                isActive: true,
                timestamp: Date.now()
            }]
        }];
    }
    return newState;
};

const datamodelQueryFetchSuccess = (newState, action, getDatamodelQueryIndex) => {
    let datamodelQueryIndex, datamodelQueryQueryIndex, newQueries;
    datamodelQueryIndex = getDatamodelQueryIndex(newState, action.datamodelQueryFilters);
    datamodelQueryQueryIndex = getDatamodelQueryQueryIndex(newState[datamodelQueryIndex].queries, action.datamodelQueryQueryFilters);
    newQueries = newState[datamodelQueryIndex].queries.map(query => ({ ...query, isActive: false }));
    newQueries[datamodelQueryQueryIndex] = {
        ...newQueries[datamodelQueryQueryIndex],
        isActive: true,
        dataId: action.dataId,
        data: action.data,
        dataWarnings: action.dataWarnings,
        dataErrors: action.dataErrors
    }
    newState[datamodelQueryIndex] = {
        ...newState[datamodelQueryIndex],
        isFetching: false,
        queries: newQueries
    };
    return newState;
};

const datamodelQueryFetchError = (newState, action, getDatamodelQueryIndex) => {
    let datamodelQueryIndex, datamodelQueryQueryIndex, newQueries;
    datamodelQueryIndex = getDatamodelQueryIndex(newState, action.datamodelQueryFilters);
    datamodelQueryQueryIndex = getDatamodelQueryQueryIndex(newState[datamodelQueryIndex].queries, action.datamodelQueryQueryFilters);
    newQueries = newState[datamodelQueryIndex].queries.map(query => ({ ...query, isActive: false }));
    newQueries[datamodelQueryQueryIndex] = {
        ...newQueries[datamodelQueryQueryIndex],
        isActive: true,
        error: action.error
    }
    newState[datamodelQueryIndex] = {
        ...newState[datamodelQueryIndex],
        isFetching: false,
        queries: newQueries
    };
    return newState;
};

const datamodelQuerySetActive = (newState, action, getDatamodelQueryIndex) => {
    let datamodelQueryIndex, datamodelQueryQueryIndex, newQueries;
    datamodelQueryIndex = getDatamodelQueryIndex(newState, action.datamodelQueryFilters);
    datamodelQueryQueryIndex = getDatamodelQueryQueryIndex(newState[datamodelQueryIndex].queries, action.datamodelQueryQueryFilters);
    newQueries = newState[datamodelQueryIndex].queries.map(query => ({ ...query, isActive: false }));
    newQueries[datamodelQueryQueryIndex] = {
        ...newQueries[datamodelQueryQueryIndex],
        isActive: true
    }
    newState[datamodelQueryIndex] = {
        ...newState[datamodelQueryIndex],
        queries: newQueries
    };
    return newState;
};

export default combineReducers({
    cards,
    filters
});