/**
 * defaultAppTitle: default app title for all pages that has not an specific title
 * pathSeparator: separator for page path in titles
 * titleSeparator: separator between page path and default app title
* */
export const defaultAppTitle = 'Biuwer App';
export const shareAppTitle = 'Biuwer Share';
export const pathSeparator = ' - ';
export const titleSeparator = ' | ';

/**
 * Generate page path with given array
 * @param path
 * @returns {string|null}
 */
export const generatePagePath = (path = []) => {
    if (path.length === 0) return null;
    return `${path.filter(p => !!p).join(pathSeparator)}`;
};

/**
 * Generate page title with page path and app title
 * @param path {String|Array<String>}
 * @param appTitle {String}
 * @return {string}
 * */
export const generatePageTitle = (path = [], appTitle = defaultAppTitle) => {
    if (typeof path === 'string') path = [path];
    const pagePath = generatePagePath(path);
    if (!pagePath) return appTitle;
    return `${pagePath}${titleSeparator}${appTitle}`;
};

export default {
    defaultAppTitle,
    shareAppTitle,
    pathSeparator,
    titleSeparator,
    generatePagePath,
    generatePageTitle
};