import { Map, List, fromJS } from 'immutable';

/**
 * System CustomFields Reducer
 */

import {
    CUSTOM_FIELDS_FETCH_REQUEST, CUSTOM_FIELDS_FETCH_SUCCESS, CUSTOM_FIELDS_FETCH_ERROR,
    CUSTOM_FIELD_INITIALIZE,
    CUSTOM_FIELD_FETCH_REQUEST, CUSTOM_FIELD_FETCH_SUCCESS, CUSTOM_FIELD_FETCH_ERROR,
    CUSTOM_FIELD_UPDATE_REQUEST, CUSTOM_FIELD_UPDATE_SUCCESS, CUSTOM_FIELD_UPDATE_ERROR,
    CUSTOM_FIELD_CHANGE_STATUS_REQUEST, CUSTOM_FIELD_CHANGE_STATUS_SUCCESS, CUSTOM_FIELD_CHANGE_STATUS_ERROR,
    CUSTOM_FIELD_CREATE_REQUEST, CUSTOM_FIELD_CREATE_SUCCESS, CUSTOM_FIELD_CREATE_ERROR,
    CUSTOM_FIELD_DELETE_ERROR, CUSTOM_FIELD_DELETE_REQUEST, CUSTOM_FIELD_DELETE_SUCCESS
    } from './custom-fields-actions.js';

export default function customFields(state = Map({}), action) {
    let newState, index = -1, updatedPayload;

    switch (action.type) {

        // Necessary to initialize customFields state prior to create a new customFields
        case CUSTOM_FIELD_INITIALIZE:
            newState = state
                .remove('created')
                .remove('updated')
                .remove('delete')
                .remove('isFetching')
                .remove('error')
                .remove('payload')
            return newState;

        case CUSTOM_FIELDS_FETCH_REQUEST:
        case CUSTOM_FIELD_FETCH_REQUEST:
            newState = state
                .remove('created')
                .remove('updated')
                .remove('delete')
                .merge({
                    isFetching: action.isFetching,
                    error: action.error
                });
            return newState;
        case CUSTOM_FIELDS_FETCH_SUCCESS:
        case CUSTOM_FIELD_FETCH_SUCCESS:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                payload: fromJS(action.payload)
            });
            return newState;
        case CUSTOM_FIELDS_FETCH_ERROR:
        case CUSTOM_FIELD_FETCH_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;

        case CUSTOM_FIELD_CREATE_REQUEST:
            newState = state
                .remove('created')
                .merge({
                    isFetching: action.isFetching,
                    error: action.error
                });
            return newState;
        case CUSTOM_FIELD_CREATE_SUCCESS:

            // Check if payload is a List (Array)
            if(List.isList(state.get('payload'))) {
                updatedPayload = state.get('payload').push(fromJS(action.payload));

                // If payload is a Map the payload to be updated is action.payload itself
            } else if(Map.isMap(state.get('payload'))) {
                updatedPayload = fromJS(action.payload);
            }

            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                created: action.created,
                payload: updatedPayload
            });
            return newState;

        case CUSTOM_FIELD_CREATE_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;
        case CUSTOM_FIELD_CHANGE_STATUS_REQUEST:
        case CUSTOM_FIELD_UPDATE_REQUEST:
            newState = state
                .remove('created')
                .remove('updated')
                .remove('delete')
                .remove('error')
                .merge({
                    isFetching: action.isFetching,
                    error: action.error
                });
            return newState;
        case CUSTOM_FIELD_UPDATE_SUCCESS:
        case CUSTOM_FIELD_CHANGE_STATUS_SUCCESS:
            // Check if payload is a List (Array)
            if(List.isList(state.get('payload'))) {

                // Find the index of the customField to be updated inside the List
                index = state.get('payload').findIndex(function(item) {
                    return item.get('_id') === action.payload._id;
                });

                // Update the payload list
                if(index !== -1) {
                    updatedPayload = state.get('payload').update(index, function(item) {
                        return fromJS(action.payload);
                    });
                }

                // If payload is a Map the payload to be updated is action.payload itself
            } else {
                updatedPayload = fromJS(action.payload);
            }

            newState = state
                .merge({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated,
                    payload: updatedPayload
                });
            return newState;
        case CUSTOM_FIELD_UPDATE_ERROR:
        case CUSTOM_FIELD_CHANGE_STATUS_ERROR:
            newState = state
                .merge({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                });
            return newState;
        case CUSTOM_FIELD_DELETE_REQUEST:
            newState = state.merge({
                delete: Map({isFetching: action.isFetching})
            });
            return newState;
        case CUSTOM_FIELD_DELETE_SUCCESS:

            // Check if payload is a List (Array)
            if(List.isList(state.get('payload'))) {

                // Find the index of the custom field to be deleted inside the List
                index = state.get('payload').findIndex(function(item) {
                    return item.get('_id') === action.payload._id;
                });

                // Update the payload list
                if(index !== -1) {
                    updatedPayload = state.get('payload').filter(function(item) {
                        return item.get('_id') !== action.payload._id;
                    });
                }

                // If payload is a Map the payload to be updated is action.payload itself
            } else if(Map.isMap(state.get('payload'))) {
                updatedPayload = fromJS(action.payload);
            }

            newState = state.merge({
                payload: updatedPayload,
                delete: Map({
                    isFetching: action.isFetching,
                    error: action.error,
                    deleted: action.deleted
                })
            });
            return newState;
        case CUSTOM_FIELD_DELETE_ERROR:
            newState = state.merge({
                delete: Map({
                    isFetching: action.isFetching,
                    error: action.error
                })
            });
            return newState;
        default:
            return state;
    }
}
