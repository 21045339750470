
import request from "@biuwer/common/src/libs/superagent";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import i18n from '@biuwer/core/src/i18n';

import {addNotification} from "@biuwer/redux/src/system/notifications/notifications-actions";

/*
 * System CustomFields action types
 */

export const CUSTOM_FIELD_INITIALIZE = 'CUSTOM_FIELD_INITIALIZE';

export const CUSTOM_FIELDS_FETCH_REQUEST = 'CUSTOM_FIELDS_FETCH_REQUEST';
export const CUSTOM_FIELDS_FETCH_SUCCESS = 'CUSTOM_FIELDS_FETCH_SUCCESS';
export const CUSTOM_FIELDS_FETCH_ERROR = 'CUSTOM_FIELDS_FETCH_ERROR';

export const CUSTOM_FIELD_FETCH_REQUEST = 'CUSTOM_FIELD_FETCH_REQUEST';
export const CUSTOM_FIELD_FETCH_SUCCESS = 'CUSTOM_FIELD_FETCH_SUCCESS';
export const CUSTOM_FIELD_FETCH_ERROR = 'CUSTOM_FIELD_FETCH_ERROR';

export const CUSTOM_FIELD_UPDATE_REQUEST = 'CUSTOM_FIELD_UPDATE_REQUEST';
export const CUSTOM_FIELD_UPDATE_SUCCESS = 'CUSTOM_FIELD_UPDATE_SUCCESS';
export const CUSTOM_FIELD_UPDATE_ERROR = 'CUSTOM_FIELD_UPDATE_ERROR';

export const CUSTOM_FIELD_CHANGE_STATUS_REQUEST = 'CUSTOM_FIELD_CHANGE_STATUS_REQUEST';
export const CUSTOM_FIELD_CHANGE_STATUS_SUCCESS = 'CUSTOM_FIELD_CHANGE_STATUS_SUCCESS';
export const CUSTOM_FIELD_CHANGE_STATUS_ERROR = 'CUSTOM_FIELD_CHANGE_STATUS_ERROR';

export const CUSTOM_FIELD_CREATE_REQUEST = 'CUSTOM_FIELD_CREATE_REQUEST';
export const CUSTOM_FIELD_CREATE_SUCCESS = 'CUSTOM_FIELD_CREATE_SUCCESS';
export const CUSTOM_FIELD_CREATE_ERROR = 'CUSTOM_FIELD_CREATE_ERROR';

export const CUSTOM_FIELD_DELETE_REQUEST = 'CUSTOM_FIELD_DELETE_REQUEST';
export const CUSTOM_FIELD_DELETE_SUCCESS = 'CUSTOM_FIELD_DELETE_SUCCESS';
export const CUSTOM_FIELD_DELETE_ERROR = 'CUSTOM_FIELD_DELETE_ERROR';

/**
 * System CustomFields: Initialize customFields action
 */
export function initializeCustomFields() {
    return {
        type: CUSTOM_FIELD_INITIALIZE
    };
}

/**
 * System CustomFields: Get CustomFields List for the requester's organization
 */
export function getCustomFieldsList() {
    return dispatch => {
        let token = Auth.getLocalJwt();
        dispatch(customFieldsFetchRequest());

        return request
            .get('/api/custom-fields/')
            .set('Authorization', 'Bearer ' + token)
            .on('response', (response) => Auth.checkResponse(response))
            .end(function(err, res) {
                if (err) {
                    dispatch(customFieldsFetchError('Error getting System CustomFields data from server'));
                } else if(res.body && res.body.status === 1) {
                    dispatch(customFieldsFetchSuccess(res.body.payload));
                }
            });
    };
}

function customFieldsFetchRequest() {
    return {
        type: CUSTOM_FIELDS_FETCH_REQUEST,
        isFetching: true,
        error: false
    };
}

function customFieldsFetchSuccess (payload) {
    return {
        type: CUSTOM_FIELDS_FETCH_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function customFieldsFetchError(errorMessage) {
    return {
        type: CUSTOM_FIELDS_FETCH_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

/**
 * System CustomFields: Create customFields action
 * @param customFieldObject
 */
export function createCustomFields(customFieldObject) {
    return dispatch => {
        let token = Auth.getLocalJwt();
        dispatch(createCustomFieldsRequest());

        if(customFieldObject) {
            return request
                .post('/api/custom-fields/')
                .set('Authorization', 'Bearer ' + token)
                .send(customFieldObject)
                .on('response', (response) => Auth.checkResponse(response))
                .end(function(err, res) {
                    if (err) {
                        let message = (res && res.body && res.body.payload && res.body.payload.message) || null;
                        dispatch(createCustomFieldsError(message));
                        dispatch(addNotification({
                            styleType: 'error',
                            message: i18n.t('notifications.error')
                        }));
                    } else if(res.body && res.body.status === 1) {
                        dispatch(createCustomFieldsSuccess(res.body.payload));
                        dispatch(addNotification({
                            styleType: 'success',
                            message: i18n.t('notifications.createSuccess', {
                                name: i18n.t('admin.customFields.title'),
                                context: 'male',
                                count: 1
                            })
                        }));
                    }
                });
        } else {
            dispatch(createCustomFieldsError('Error creating System CustomFields: customFields object not provided'));
            dispatch(addNotification({
                styleType: 'error',
                message: i18n.t('notifications.error')
            }));
        }
    };
}

function createCustomFieldsRequest() {
    return {
        type: CUSTOM_FIELD_CREATE_REQUEST,
        isFetching: true,
        error: false
    };
}

function createCustomFieldsSuccess (payload) {
    return {
        type: CUSTOM_FIELD_CREATE_SUCCESS,
        isFetching: false,
        error: false,
        created: true,
        payload
    };
}

function createCustomFieldsError(errorMessage) {
    return {
        type: CUSTOM_FIELD_CREATE_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

/**
 * System CustomFields: Get customFields action
 * @param customFieldId
 */
export function getCustomFields(customFieldId) {
    return dispatch => {
        let token = Auth.getLocalJwt();
        dispatch(customFieldFetchRequest());

        return request
            .get('/api/customFields/' + customFieldId)
            .set('Authorization', 'Bearer ' + token)
            .on('response', (response) => Auth.checkResponse(response))
            .end(function(err, res) {
                if (err) {
                    dispatch(customFieldFetchError('Error getting System CustomFields data from server'));
                } else if(res.body && res.body.status === 1) {
                    dispatch(customFieldFetchSuccess(res.body.payload));
                }
            });
    };
}

function customFieldFetchRequest() {
    return {
        type: CUSTOM_FIELD_FETCH_REQUEST,
        isFetching: true,
        error: false
    };
}

function customFieldFetchSuccess (payload) {
    return {
        type: CUSTOM_FIELD_FETCH_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function customFieldFetchError(errorMessage) {
    return {
        type: CUSTOM_FIELD_FETCH_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

/**
 * System CustomFields: Update customFields action
 * @param customField
 * @param showNotification
 */
export function updateCustomFields(customField, showNotification = true) {
    return (dispatch) => {
        let token = Auth.getLocalJwt();

        dispatch(customFieldUpdateRequest(customField));

        if(customField) {
            return request
                .put('/api/custom-fields/' + customField._id)
                .set('Authorization', 'Bearer ' + token)
                .send(customField)
                .on('response', (response) => Auth.checkResponse(response))
                .end(function(err, res) {
                    if (err) {
                        let message = (res && res.body && res.body.payload && res.body.payload.message) || null;
                        dispatch(customFieldUpdateError(message));
                        dispatch(addNotification({
                            styleType: 'error',
                            message: i18n.t('notifications.error')
                        }));
                    } else if(res.body && res.body.status === 1) {
                        dispatch(customFieldUpdateSuccess(res.body.payload));

                        if (showNotification) {
                            dispatch(addNotification({
                                styleType: 'success',
                                message: i18n.t('notifications.updateSuccess', {
                                    name: i18n.t('admin.customFields.title'),
                                    context: 'male',
                                    count: 1
                                })
                            }));
                        }
                    }
                });
        } else {
            dispatch(createCustomFieldsError('Error updating customField: customField object not provided'));
            dispatch(addNotification({
                styleType: 'error',
                message: i18n.t('notifications.error')
            }));
        }

    };
}

function customFieldUpdateRequest(payload) {
    return {
        type: CUSTOM_FIELD_UPDATE_REQUEST,
        isFetching: true,
        error: false,
        payload
    };
}

function customFieldUpdateSuccess (payload) {
    return {
        type: CUSTOM_FIELD_UPDATE_SUCCESS,
        isFetching: false,
        error: false,
        updated: true,
        payload
    };
}

function customFieldUpdateError(errorMessage) {
    return {
        type: CUSTOM_FIELD_UPDATE_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

/**
 * System CustomFields: Change customFields status action
 * @param customFieldId
 * @param status
 */
export function changeCustomFieldsStatus(customFieldId, status) {
    return dispatch => {
        let token = Auth.getLocalJwt();
        dispatch(changeCustomFieldsStatusRequest());

        return request
            .put('/api/customFields/' + customFieldId + '/status')
            .set('Authorization', 'Bearer ' + token)
            .send({status:status})
            .on('response', (response) => Auth.checkResponse(response))
            .end(function(err, res) {
                if (err) {
                    dispatch(changeCustomFieldsStatusError('Error on getting System CustomFields data from server'));
                    dispatch(addNotification({
                        styleType: 'error',
                        message: i18n.t('notifications.error')
                    }));
                } else if(res.body && res.body.status === 1) {
                    dispatch(changeCustomFieldsStatusSuccess(res.body.payload));
                    dispatch(addNotification({
                        styleType: 'success',
                        message: i18n.t('notifications.updateSuccess', {
                            name: i18n.t('common.statusLabel'),
                            context: 'male',
                            count: 1
                        })
                    }));
                }
            });
    };
}

function changeCustomFieldsStatusRequest() {
    return {
        type: CUSTOM_FIELD_CHANGE_STATUS_REQUEST,
        isFetching: true,
        error: false
    };
}

function changeCustomFieldsStatusSuccess (payload) {
    return {
        type: CUSTOM_FIELD_CHANGE_STATUS_SUCCESS,
        isFetching: false,
        error: false,
        updated: true,
        payload
    };
}

function changeCustomFieldsStatusError(errorMessage) {
    return {
        type: CUSTOM_FIELD_CHANGE_STATUS_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

/**
 * System CustomFields: Delete customFields action
 * @param customFieldId
 */
export function deleteCustomField(customFieldId) {
    return dispatch => {
        let token = Auth.getLocalJwt();
        dispatch(deleteCustomFieldRequest());

        if(customFieldId) {
            return request
                .delete('/api/custom-fields/' + customFieldId)
                .set('Authorization', 'Bearer ' + token)
                .on('response', (response) => Auth.checkResponse(response))
                .end(function(err, res) {
                    if (err) {
                        dispatch(deleteCustomFieldError('Error deleting System Custom Field'));
                        dispatch(addNotification({
                            styleType: 'error',
                            message: i18n.t('notifications.error')
                        }));
                    } else if(res.body && res.body.status === 1) {
                        dispatch(deleteCustomFieldSuccess(res.body.payload));
                        dispatch(addNotification({
                            styleType: 'success',
                            message: i18n.t('notifications.deleteSuccess', {
                                name: i18n.t('admin.customFields.title'),
                                context: 'male',
                                count: 1
                            })
                        }));
                    }
                });
        } else {
            dispatch(deleteCustomFieldError('Error deleting customField: customFieldId not provided'));
            dispatch(addNotification({
                styleType: 'error',
                message: i18n.t('notifications.error')
            }));

        }
    };
}

function deleteCustomFieldRequest() {
    return {
        type: CUSTOM_FIELD_DELETE_REQUEST,
        isFetching: true,
        error: false
    };
}

function deleteCustomFieldSuccess(payload) {
    return {
        type: CUSTOM_FIELD_DELETE_SUCCESS,
        isFetching: false,
        error: false,
        deleted: true,
        payload
    };
}

function deleteCustomFieldError(errorMessage) {
    return {
        type: CUSTOM_FIELD_DELETE_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}