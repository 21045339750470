import React, { createContext, useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Icon } from "@blueprintjs/core";

// Components
import ModalDialog from "./modal-dialog";
import FeatureWallContent from "./feature-wall-content";

const FeatureWallDialog = ({ isOpen, onClose, featureWallProps }) => {

    const { t } = useTranslation();

    const { isLimitType } = featureWallProps;

    return (
        <ModalDialog
            isOpen={isOpen}
            components={{
                Header: () => {
                    return (
                        <div
                            className="d-flex flex-row align-items-start justify-content-between mt-0 w-100 pl20"
                            style={{ marginBottom: -35 }}
                        >
                            <div className="h4 fs22 bold">{t(isLimitType ? "common.featureWall.limitP1" : "common.featureWall.p1")}</div>
                            <Icon
                                icon={"cross"}
                                color="#5c7080"
                                size={20}
                                onClick={onClose}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    );
                }
            }}
            width={650}
            footerHeight={0}
            onClose={onClose}
        >
            <div className="d-flex flex-column align-items-center justify-content-center pv30 ph10">
                <FeatureWallContent
                    showTitle={false}
                    extraAction={onClose}
                    {...featureWallProps}
                />
            </div>
        </ModalDialog>
    );
};

const useProvideFeatureWallDialog = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [featureWallProps, setFeatureWallProps] = useState(false);
    return {
        isOpen,
        featureWallProps,
        onOpen: (featureWallProps) => {
            setIsOpen(true);
            setFeatureWallProps(featureWallProps);
        },
        onClose: () => setIsOpen(false)
    };
};

const featureWallDialogContext = createContext();
const useFeatureWallDialog = () => {
    return useContext(featureWallDialogContext);
};

const ProvideFeatureWallDialog = ({ children }) => {
    const featureWallDialog = useProvideFeatureWallDialog();
    return (
        <featureWallDialogContext.Provider value={featureWallDialog}>
            {children}
        </featureWallDialogContext.Provider>
    );
};

export {
    ProvideFeatureWallDialog,
    useFeatureWallDialog,
    FeatureWallDialog
}