import { combineReducers } from 'redux';

// Biuwer Data Prep reducers
import agents from "./agents/agents-reducers";
import executions from "./executions/executions-reducers";
import jobs from "./jobs/jobs-reducers";
import stepTypes from "./step-types/step-types-reducers";
import steps from "./steps/steps-reducers";

export default combineReducers({
    agents,
    executions,
    jobs,
    stepTypes,
    steps
});