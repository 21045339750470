import nearley from "nearley";
import { deburr } from "lodash";

// Grammar
import grammar from "./grammar.js";

function parseExpression (expression) {

    expression = deburr(expression);

    // Create a Parser object from our grammar.
    const parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammar));

    // Parse something!
    try {
        parser.feed(expression);
    } catch (err) {
        process.env.NODE_ENV !== 'production' && console.log(err);
    }

    // parser.results is an array of possible parsings.
    return parser.results && parser.results[0];
}

export default parseExpression;
