// Libs
import gqlRequest from "@biuwer/core/src/graphql-request";
import Auth from "@biuwer/redux/src/system/auth/auth-lib";

/*
 * Dataset queries action types
 */

// Dataset queries
export const DATASET_QUERIES_FETCH_REQUEST = 'DATASET_QUERIES_FETCH_REQUEST';
export const DATASET_QUERIES_FETCH_SUCCESS = 'DATASET_QUERIES_FETCH_SUCCESS';
export const DATASET_QUERIES_FETCH_ERROR = 'DATASET_QUERIES__FETCH_ERROR';
export const DATASET_QUERIES_SET_ACTIVE = 'DATASET_QUERIES__SET_ACTIVE';

/*
 * Action creators
 */

export const fetchDatasetQuery = (datasetQueryFilters = {}, datasetQueryQueryFilters = {}, datasetQueryOrigin) => {
    return async (dispatch) => {

        try {

            dispatch({
                type: DATASET_QUERIES_FETCH_REQUEST,
                datasetQueryFilters: datasetQueryFilters,
                datasetQueryQueryFilters: datasetQueryQueryFilters
            });

            const queryResult = await gqlRequest({
                queryName: "queryDataset",
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "datasetInput!",
                    name: "datasetInput",
                    data: {
                        ...datasetQueryFilters,
                        ...datasetQueryQueryFilters,
                        origin: datasetQueryOrigin
                    }
                }]
            });

            dispatch({
                type: DATASET_QUERIES_FETCH_SUCCESS,
                data: queryResult.data,
                dataWarnings: queryResult.dataWarnings,
                dataErrors: queryResult.dataErrors,
                datasetQueryFilters: datasetQueryFilters,
                datasetQueryQueryFilters: datasetQueryQueryFilters
            });
        } catch(err) {
            dispatch({
                type: DATASET_QUERIES_FETCH_ERROR,
                error: err,
                datasetQueryFilters: datasetQueryFilters,
                datasetQueryQueryFilters: datasetQueryQueryFilters
            });
        }
    };
};

export const setDatasetQueryActive = (datasetQueryFilters, datasetQueryQueryFilters) => {
    return {
        type: DATASET_QUERIES_SET_ACTIVE,
        datasetQueryFilters: datasetQueryFilters,
        datasetQueryQueryFilters: datasetQueryQueryFilters
    };
};